@charset "utf-8";

/*----------------------------------------*\
  || Basic Select
\*----------------------------------------*/

.basic-select-wrap {
  @include flex($gap: 10px);
  position: relative;
  width: 100%;
  min-width: fit-content;

  & > div {
    flex-basis: 330px;

    &:last-child {
      flex-grow: 1;
    }
  }

  .select-wrap {
    flex: 1;
  }

  .basic-inp-wrap {
    margin-top: 0;
  }
}

.basic-select-btn {
  position: relative;
  width: 100%;
  padding: 13px 20px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--line-clr3);
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  color: var(--tit-clr);
  text-align: left;
  transition: background-color .3s ease;

  &[aria-pressed="true"] {
    border-color: var(--main-clr);
    font-weight: $medium;

    &::after {
      transform: translateY(-50%) scaleY(-1);
    }
  }

  &::after {
    @include size(16px);
    @include bgImg($url: '/img/common/icon/icon_arrow_down.png', $size: 12px);
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    display: block;
    transform: translateY(-50%);
    transition: all .3s ease;
  }
}

.basic-select-list {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  max-height: 300px;
  padding: 20px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--main-clr);
  border-radius: 10px;
  overflow: auto;
  scrollbar-gutter: stable;
  z-index: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    width: 32px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--line-clr);
    background-clip: padding-box;
    border: 12px solid transparent;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #f6fafa;
    background-clip: padding-box;
    border: 12px solid transparent;
    border-radius: 16px;
  }

  .title {
    font-size: 18px;
    font-weight: $bold;
    line-height: 21px;
    color: var(--tit-clr);
  }

  .list + .title {
    margin-top: 40px;
  }

  .list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(186px, auto));
    grid-gap: 20px 30px;

    &.type-long {
      grid-template-columns: repeat(auto-fill, minmax(300px, auto));
    }
  }

  .item {
    @include size(100%);
  }

  .basic-select {
    @include flex($gap: 10px);
    @include size(100%);

    .ico-bi {
      @include size(36px);
      min-width: 36px;

      &::before {
        @include size(36px);
        background-size: 36px;
      }
    }

    .name {
      font-size: 16px;
      line-height: 19px;
    }

    &[aria-selected="true"] {
      .name {
        font-weight: $bold;
        color: var(--main-clr);
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }
}

ul.basic-select-list {
  @include flex($direction: column, $align: normal);
  padding: 0;

  li {
    position: relative;
    background-color: var(--bg-clr2);

    &:hover {
      background-color: #e5f5f5;

      &::before,
      & + li::before {
        left: 0;
        right: 0;
        background-color: #e5f5f5;
      }
    }

    & + li::before {
      content: "";
      position: absolute;
      top: 0;
      left: 20px;
      right: 20px;
      display: block;
      height: 1px;
      background-color: var(--line-clr3);
      transition: all 0.2s ease;
    }
  }

  .basic-select-item {
    width: 100%;
    padding: 14px 20px;
    font-size: 16px;
    line-height: 19px;
    color: var(--tit-clr);
    text-align: left;
    transition: background-color 0.3s ease;
    outline-offset: -2px;

    &[aria-selected="true"] {
      font-weight: $bold;
      color: var(--main-clr);
    }
  }
}

/*----------------------------------------*\
  || Acco Select Wrap
\*----------------------------------------*/

.acco-select-wrap {
  position: relative;
}

.acco-select-btn {
  @include flex($direction: column, $align: normal, $gap: 4px);
  position: relative;
  width: 100%;
  padding: 14px 20px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--line-clr2);
  border-radius: 10px;
  text-align: left;
  transition: background-color .3s ease;
  outline-offset: -2px;

  &[aria-pressed="true"] {
    border-color: var(--main-clr);

    &::after {
      transform: translateY(-50%) scaleY(-1);
      top: calc(50% - 1px);
    }
  }

  &:disabled {
    background-color: #f2f4f6;

    &::after {
      display: none;
    }

    .name {
      color: var(--cap-clr);
    }
  }

  &::after {
    @include size(24px);
    @include bgImg($url: '/img/common/icon/icon_arrow_down.png', $size: 14px);
    content: '';
    position: absolute;
    top: 50%;
    right: 18px;
    display: block;
    transform: translateY(-50%);
    transition: all .3s ease;
  }

  .name {
    font-size: 16px;
    font-weight: $medium;
    line-height: 120%;
    color: var(--tit-clr);
  }

  .num {
    font-size: 14px;
    color: var(--body-clr2);
  }
}

.acco-select-list {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  max-height: 300px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--main-clr);
  border-radius: 10px;
  overflow: auto;
  z-index: 10;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    display: none;
  }
}

.acco-select-item {
  position: relative;
  transition: background-color .2s ease;

  &:hover {
    background-color: #e5f5f5;

    &::before,
    & + li::before {
      left: 0;
      right: 0;
      background-color: #e5f5f5;
    }
  }

  & + li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    display: block;
    height: 1px;
    background-color: var(--line-clr3);
    transition: all .2s ease;
  }

  .acco-select-btn {
    border: 0;
    background-color: transparent;
    border-radius: 0;

    &[aria-selected="true"] * {
      color: var(--main-clr);
    }

    &::after {
      display: none;
    }
  }
}

.acco-info-wrap {
  @include flex($gap: 10px);
  margin-top: 14px;

  .basic-inp-wrap {
    max-width: 290px;
  }
}

.acco-balance-box {
  @include flex($justify: flex-end, $gap: 18px);
  @include slide($distance: -10px);
  margin-left: auto;
  opacity: 0;
}

.acco-balance-box {
  .balance,
  .possible {
    @include flex($justify: space-between, $gap: 10px);
    font-size: 12px;
    line-height: 14px;
    color: var(--body-clr2);

    strong {
      font-size: 16px;
      line-height: 16px;
      color: var(--tit-clr);
      text-align: right;
    }
  }

  .possible::before,
  .num + .balance::before {
    @include size(1px, 14px);
    content: '';
    display: block;
    margin-right: 8px;
    background-color: var(--line-clr3);
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

// @include respond-to ("huge") {
//   html.responsive {}
// }
