@charset "utf-8";

/*----------------------------------------*\
  || Basic Tab
\*----------------------------------------*/

.basic-tab-list {
  @include flex;
  width: 100%;
  margin-bottom: 20px;

  .basic-tab {
    flex: 1;
    padding-bottom: 20px;
    border-bottom: 3px solid var(--disable-clr);
    font-size: 20px;
    font-weight: $bold;
    line-height: 24px;
    color: var(--cap-clr);
    text-align: center;

    &[aria-selected="true"] {
      border-color: var(--main-clr);
      color: var(--main-clr);
    }

    span {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
    }
  }

  &.type-m {
    .basic-tab {
      padding: 13px 0;
      border-width: 2px;
      font-size: 15px;
      font-weight: $medium;
      line-height: 19px;

      &[aria-selected="true"] {
        font-weight: $bold;
      }
    }
  }

  &.type-inset {
    .basic-tab {
      &[aria-selected="true"] {
        box-shadow: 0 -1px 0 0 inset var(--main-clr);
      }
    }
  }
}

@include respond-to ("medium") {
  .basic-tab-list .basic-tab {
    padding-bottom: 10px;
    font-size: 18px;
  }
}

/*----------------------------------------*\
  || Check Tab
\*----------------------------------------*/

.check-tab-list {
  @include flex($gap: 14px);

  .check-tab {
    @include flex($gap: 14px);
    font-size: 16px;
    font-weight: $regular;
    line-height: 19px;
    color: #666;

    &[aria-selected="true"] {
      @include bgImg($url: '/img/common/icon/icon_check_mint.png', $x: left, $size: 14px);
      padding-left: 20px;
      font-weight: $bold;
      color: var(--tit-clr);
    }
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.sub-tab-txt {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

/*----------------------------------------*\
  || Tab Basic
\*----------------------------------------*/

.tab-basic {
  margin-top: 40px;
  padding-top: 0 !important;

  &.type01 {
    margin-top: 20px;

    .tab-basic-contents {
      padding-top: 40px;
    }
  }

  &.type02,
  &.margin-above {
    margin-top: 50px;
  }

  .icon-arrow {
    display: inline-block;
    width: 28px;
    height: 10px;
    margin-top: -2px;
    background: url(/img/common/icon_rtarrow.png) no-repeat 50% 50%;
    text-indent: -10000px;
    white-space: nowrap;
    vertical-align: middle;
    overflow: hidden;
  }

  .active-tab .icon-arrow {
    background-image: url(/img/common/icon_rtarrow_fff.png);
  }
}

.tab-basic-contents.type01 {
  padding-top: 40px;
}

.popup-body02 .tab-basic:first-child {
  margin-top: 0;
}

.sub-title-area + .tab-basic {
  margin-top: 0;
}

.tab-basic-buttons {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;

  &.type02 {
    width: 135px;
  }
}

.tab-basic-buttons-list {
  @include flex;
  width: 100%;

  &.type-m {
    .tab-basic-buttons-item {
      background: transparent;
      border: 0;
      padding: 0;

      &.js-tabpanel-active {
        color: var(--main-clr);
      }

      & + .tab-basic-buttons-item {
        border-left: 1px solid var(--line-clr3);
      }
    }
  }
}

.tab-basic-buttons-item {
  flex: 1;
  min-width: fit-content;
  padding-bottom: 20px;
  border-bottom: 3px solid var(--disable-clr);
  font-size: 20px;
  line-height: 24px;
  color: var(--cap-clr);
  text-align: center;
  cursor: pointer;

  > a {
    outline-offset: -1px;
  }

  &.type02 {
    min-width: 135px;
  }

  &.js-tabpanel-active,
  &.is-tab-basic-active {
    background: none !important; // 임시 important
    border-color: var(--main-clr) !important; // 임시 important
    font-weight: $bold;
    color: var(--main-clr) !important;

    span {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
    }
  }

  &.disable {
    // background: linear-gradient(-45deg, #eeeff1 25%, #fff0, var(--bg-clr2) 50%, #eeeff1 0, #eeeff1 75%, var(--bg-clr2) 0);
    background-size: 20px 20px;
  }
}

.tab-basic-buttons-item-link {
  &:hover {
    text-decoration: none;
  }

  &::before {
    /* content: ''; */
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
  }
}

.tab-basic-contents {
  .bbs-top,
  .slide-box-area:has(.bbs-top) {
    margin-top: 0;
    border-top: 0;
  }
}

.tab-basic-panel[data-tab] {
  display: none;

  &.js-tabpanel-opened {
    display: block;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .tab-basic {
      &.type02 {
        margin-top: 20px;
      }

      &.margin-above {
        margin-top: 0
      }

      &.m-tab {
        .tab-basic-buttons {
          margin-bottom: 20px;
        }

        .tab-basic-buttons-list {
          display: inherit;
          overflow: hidden;
          width: 100%;
          padding: 0;
        }

        .tab-basic-buttons-item {
          display: inherit;
          width: 40%;
          float: left;
          padding: 0;
          margin-left: 0;

          &:last-child {
            width: 20%;
          }

          .icon-arrow {
            width: 10px;
          }
        }

        .notice-list li > span {
          display: block;
        }

        .notice-list li .arrow {
          float: inherit;
          line-height: 20px;
        }

        .img-list {
          overflow: hidden;
        }

        .step-list-content {
          .img-list li .txt-style01 {
            padding-top: 270px;
          }

          .img-list li,
          .img-type2 li,
          .img-type4 li {
            width: calc(33.334% - 14px);
            width: -webkit-calc(33.334% - 14px);
            width: -moz-calc(33.334% - 14px);
            width: -o-calc(33.334% - 14px);
            height: 250px;
            /* background: url(/img/common/cap_img_01.png);*/
            float: left;
            margin-right: 20px;
            background-size: 100% 280px;
          }

          .img-title {
            font-size: 14px;
          }

          .img-style03,
          .img-style09,
          .img-style12,
          .img-style22 {
            margin-right: 0 !important;
          }
        }

        .btn-area {
          text-align: center;

          .btn-arrow {
            display: inline-block;
          }
        }
      }
    }

    .tab-basic-buttons {
      overflow-y: hidden;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      font-size: 0;
      line-height: 0;
      white-space: nowrap;
      text-align: center;
      border-bottom: 1px solid var(--line-clr3);
    }

    .tab-basic-buttons-list {
      display: inline-block;
      vertical-align: top;
      padding: 0 15px;
      width: auto;
    }

    .tab-basic-buttons-item {
      position: relative;
      display: inline-block;
      vertical-align: top;
      padding: 0 6px;
      border: 0;
      font-size: 13px;
      line-height: 35px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 2px solid var(--main-clr);
        -webkit-transform: scale(1, 0);
        transform: scale(1, 0);
        -webkit-transition: transform 0.3s;
        transition: transform 0.3s;
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
      }

      &.js-tabpanel-active,
      &.is-tab-basic-active {
        background: none;
        color: var(--main-clr);

        &::after {
          -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
        }
      }

      & + .tab-basic-buttons-item {
        margin-left: 12px;

        &::before {
          display: none;
        }
      }
    }
  }
}
