@charset "utf-8";

/*----------------------------------------*\
  || Basic Input
\*----------------------------------------*/

.basic-inp-wrap {
  $inpBrdr: 10px; // border-radius 값

  @include flex($gap: 4px);
  position: relative;
  width: 100%;
  padding: 0 10px 0 15px;
  min-width: fit-content;
  background-color: var(--bg-clr2);
  border-color: var(--line-clr3);
  border-radius: $inpBrdr;

  /* Pseudo */
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    display: block;
    border: 1px solid ;
    border-color: inherit;
    border-radius: $inpBrdr;
    transition: border-color .15s ease;
    pointer-events: none;
  }

  &:focus-within {
    border-color: var(--main-clr);

    &::before {
      border-width: 2px;
    }

    label {
      color: var(--tit-clr);
    }

    // .basic-inp,
    // .vitualKeyCheck {
    //   max-height: 43px;
    // }
  }

  &:has(input:disabled),
  &:has(input[readonly]) {
    background-color: var(--disable-clr2);
    border-color: var(--disable-clr2);
    color: var(--cap-clr);
    cursor: default;
  }

  &:has(:autofill) {
    background-color: #e8f0fe;
    border-color: var(--pos-clr);
  }

  &:has(.error) {
    border-color: var(--neg-clr);
    background-color: #faeaea;
  }

  &:has([style*=background-color][nppfs-keypad-uuid]) {
    background-color: #e8f0fe;
    border-color: var(--pos-clr);
    pointer-events: auto;

    input {
      background-color: transparent !important;
    }
  }

/* Child */
  label {
    min-width: fit-content;
    margin-right: 4px;
    font-size: 16px;
    font-weight: $regular;
    line-height: 19px;
    color: var(--cap-clr);
  }

  .basic-inp {
    width: 100%;
    height: 45px;
    padding: 0;
    font-size: 16px;
    font-weight: $bold;
    line-height: 19px;

    &:focus,
    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      font-weight: $regular;
    }

    &:has(~.calendar-btn) {
      margin-right: 6px;
      text-align: right;
    }

    &.type-calendar {
      @include bgImg($url: "/img/common/icon/icon_calendar.png", $size: 19px, $x: right);
      padding-right: 30px;
      text-align: right;
    }
  }

  .txt {
    min-width: fit-content;
    font-size: 16px;
    font-weight: $medium;
    line-height: 19px;
    color: var(--tit-clr);
  }

  .clearbtn {
    width: 16px;
    height: 16px;
  }

  .inner {
    @include flex($justify: flex-end, $gap: 4px);
  }

  .time-counter {
    gap: 8px;
    color: var(--neg-clr);

    &::before {
      @include size(14px);
      opacity: .8;
    }
  }
}

/* Pseudo */
.basic-inp-wrap {
  &:has(.txt) {
    justify-content: flex-end;

    label {
      margin-right: auto;
    }

    .basic-inp {
      text-align: right;
    }
  }

  &:has(.inner) {
    flex-direction: column;
    gap: 1px;
    padding-top: 0 !important;

    &:has(.txt) {
      align-items: flex-end;
    }
  }

  // &:has(.vitualKeyCheck) {
  //   padding-right: 10px;
  // }

  & + & {
    margin-top: 10px;
  }
}

/* Single */
.basic-inp-wrap.single {
  width: 45px;
  min-width: 45px;
  padding: 0 !important;

  .basic-inp {
    text-align: center;
  }
}

/* Type */
.basic-inp-wrap.line {
  padding: 0;
  background-color: var(--bg-clr2);

  &::before {
    border: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid var(--line-clr3);
    border-radius: 0;
  }

  &:not(:has(input[readonly], input:disabled)):focus-within {
    padding: 0;

    &::before {
      border-left: 0;
      border-right: 0;
      border-top-color: transparent;
    }
  }

  &:has(input:disabled),
  &:has(input[readonly]) {
    background-color: var(--bg-clr2);

    // &:focus-within {
    //   border-top-color: transparent;
    //   border-left: none;
    //   border-right: none;

    //   .basic-inp {
    //     height: 42px;
    //   }
    // }
  }

  .basic-inp {
    height: 42px;
  }

  .txt {
    font-size: 12px;
    line-height: 16px;
    color: var(--cap-clr);
  }
}

.basic-inp-wrap.type-s {
  padding: 0 10px;

  // &:not(:has(input[readonly], input:disabled)):focus-within {
  //   padding: 0 9px;

  //   .basic-inp {
  //     height: 36px;
  //   }
  // }

  .basic-inp {
    height: 38px;
    font-size: 14px;
    line-height: 16px;
  }
}

/* Variable */
.basic-inp-wrap {
  .basic-btn {
    @include size(auto);
    min-width: fit-content;
    // margin-right: -12px;
    padding: 7px 16px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 10px;
  }

  .vitualKeyCheck {
    height: auto;
    max-height: 43px;
    margin: 0;
  }

  .btn-mouse,
  .icon-mouse,
  .icon-mouse02 {
    @include size(25px, 36px);
    margin: 0;
    background-position: center !important;
  }
}

/*----------------------------------------*\
  || Basic Label Wrap
\*----------------------------------------*/

.basic-label-wrap {
  @include flex($gap: 10px 15px);
  width: 100%;
  max-width: 910px;
  margin: auto;

  & + & {
    margin-top: 16px;
  }

  .basic-label {
    @include flex;
    min-width: 100px;
    height: 45px;
    font-size: 16px;
    line-height: 19px;
    color: var(--cap-clr);
  }
}

.basic-label {
  min-width: 120px;
  font-size: 14px;
  line-height: 18px;
  line-height: var(--tit-clr);
}

@include respond-to ("medium") {
  .basic-label-wrap {
    flex-wrap: wrap;

    .basic-label {
      height: auto;
    }
  }

  .basic-inp-wrap {
    min-width: auto;
  }
}

/*----------------------------------------*\
  || Basic Inp Dash / Dot
\*----------------------------------------*/

.basic-inp-wrap:has(+.basic-inp-dash) {
  min-width: auto;
}

.basic-inp-dash {
  @include size(12px, 2px);
  min-width: 12px;
  margin: 0 10px;
  background-color: var(--line-clr3);
}

.basic-inp-dot-wrap {
  @include flex($gap: 30px);
  margin: 0 50px 0 10px;

  &.type-s {
    gap: 16px;
    margin-left: 0;

    .basic-inp-dot {
      @include size(12px);
      min-width: 12px
    }
  }

  .basic-inp-dot {
    @include size(16px);
    min-width: 16px;
    background-color: var(--line-clr3);
    border-radius: 50%;
  }
}


/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.chk-out {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* form required 체크시 사용 */
.formRequired {
  border: 1px solid var(--neg-clr) !important;
}

/*----------------------------------------*\
  || Input Area
\*----------------------------------------*/

.i-wid .input-area,
.i-cwid .input-area {
  width: 285px;
}

.i-inpW .input-area {
  width: 300px;
}

/*----------------------------------------*\
  || Input Basic
\*----------------------------------------*/

input[type="password"] {
  padding: 1px 40px 0 10px;
  height: 45px;
  letter-spacing: 1px;
  font-size: 16px;
}

.input-box {
  width: 100%;
}

.input-basic-box {
  display: flex;
  border: 1px solid var(--disable-clr);
  max-width: 400px;
  height: 45px;
  align-items: center;
  padding: 10px;

  label {
    font-size: 12px;
    width: 65px;
    margin-right: 10px;
  }

  input {
    flex: 1;
  }
}

.input-basic,
.select-basic {
  width: 100%;
  max-width: 400px;
  height: 45px;
  line-height: 45px;
  border: 1px solid var(--line-clr3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: var(--bg-clr2);
  padding: 0 10px;
  font-size: 16px;
  border-radius: 10px;

  &.disable,
  &.readonly {
    background-color: var(--disable-clr2);
    border-color: var(--disable-clr2);
    color: var(--cap-clr);
    cursor: default;
  }

  &.xxxs {
    max-width: 70px;
  }

  &.xxss {
    max-width: 90px;
  }

  &.xxs {
    max-width: 124px;
  }

  &.xs {
    max-width: 150px;
  }

  &.sm {
    max-width: 200px;
  }

  &.md {
    max-width: 300px;
  }

  &.w167 {
    max-width: 167px !important;
  }

  &.w170 {
    max-width: 170px !important;
  }

  &.max170 {
    max-width: 170px;
  }

  &.max185 {
    max-width: 185px;
  }

  &.w227 {
    max-width: 227px !important;
  }

  &.w270 {
    max-width: 270px !important;
  }

  &.w285 {
    max-width: 285px;
  }

  &.w340 {
    max-width: 340px;
  }

  &.w410 {
    max-width: 410px;
  }

  &.w195 {
    max-width: 195px;
  }

  &.w290 {
    max-width: 290px !important;
  }

  &.w525 {
    max-width: 525px !important;
  }

  &.w115 {
    max-width: 115px !important;
  }

  &.w930 {
    max-width: 930px !important;
  }

  &.w380 {
    max-width: 380px;
  }

  &.w245 {
    max-width: 245px;
  }

  &.w400 {
    max-width: 400px !important;
  }

  &.w-auto {
    max-width: 50%;
  }
}

.input-basic:has(+.vitualKeyCheck) {
  padding-right: 40px;
}

/* 보안키패드 컬러 적용 */
.input-basic[style*=background-color][nppfs-keypad-uuid] {
  background-color: #e9f2ff !important;
  border-color: var(--pos-clr);
  pointer-events: auto;
}

.input-basic {

  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::-moz-placeholder {
    color: var(--cap-clr);
  }

  &.w-jumin {
    max-width: 270px;
    margin-right: 10px;
  }

  &.w-jumin2 {
    max-width: 187px;
  }

  &.w-bnum {
    max-width: 175px;
  }

  &.w-lifetime01 {
    max-width: 60px;
    margin-right: 0
  }

  &.w-lifetime02 {
    max-width: 314px;
  }

  &.pass.merge-icon {
    margin-right: 0;

    & + .vitualKeyCheck .icon-mouse {
      margin-left: -30px;
    }
  }

  &.input-won {
    padding-right: 33px;
    text-align: right;

    &::placeholder {
      text-align: left
    }

    &.style01 {
      margin-left: -20px;
    }
  }

  &.phone {
    max-width: 327px;
  }

  &.payinfo {
    width: 78px;
    height: 30px;
    margin: 0 5px;
    background: #efefef;
    border: 1px solid #e60012;
    font-size: 16px;
    text-align: center;
  }
}

.input-active {
  border-color: var(--body-clr);
}

.input-won ~ .txt {
  margin-left: -42px;
  line-height: 45px;
  top: 0;
  right: 10px;
  color: var(--cap-clr);
  font-size: 16px;

  &.type02 {
    margin-left: -52px;
  }
}

.select-basic {
  color: var(--body-clr);
  background: var(--bg-clr2) url(/img/common/icon_select_333.png) no-repeat right 10px center / 12px;

  &.w180 {
    max-width: 180px;
  }

  &.phone {
    max-width: 155px;
    margin: 0 0 0 9px;
  }

}

.select-active {
  color: var(--body-clr);
  border-color: var(--body-clr);
}

.input-mouse {
  background: var(--bg-clr2) url(/img/common/icon_mouse.png) no-repeat right 10px center / 16px;
}

.vitualKeyCheck {
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 45px;
  margin: 0 !important;
  padding: 0 !important;
  vertical-align: middle;

  @at-root .lon-item-list ~ .vitualKeyCheck {
    vertical-align: top;
  }
}

.space-modi {
  .vitualKeyCheck ~ .trn-notice-i-list {
    line-height: 1.2;
  }

  .radio-box-wrap .radio-box {
    margin-top: 0;
  }
}

/*----------------------------------------*\
  || Input www
\*----------------------------------------*/

.input-www {
  background: var(--bg-clr2) url(/img/common/icon_www.png) no-repeat right 10px center / 13px;

  &::placeholder {
    text-align: left;
  }

  &.input-basic {
    padding-right: 30px;
    text-align: right;
  }
}

/*----------------------------------------*\
  || Btn mouse / Icon mouse
\*----------------------------------------*/

.btn-mouse {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 45px;
  margin-left: -40px;
  vertical-align: bottom;
  border: 1px solid red;
  opacity: 0;
  cursor: pointer;

  &:checked {
    background: url(/img/common/icon_home.png) 0/16px 24px no-repeat;

    & + i {
      background: url(/img/common/icon_keyboard.png) 0/24px 19px no-repeat;
    }
  }
}

.chk-basic:focus ~ .icon-chk,
.btn-mouse:focus + i {
  outline: 1px dashed #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.icon-mouse,
.icon-mouse02 {
  @include size(24px);
  margin-left: -40px;
  vertical-align: bottom;
  opacity: 1;
  background: url('/img/common/icon/icon_mouse.png') no-repeat center / 24px;
  cursor: pointer;
}

.icon-mouse02 {
  margin-left: -30px;
}

.btn-mouse-checkbox {
  width: 30px;
  height: 45px;
  margin-left: -40px;
  position: absolute;
  background: url(/img/common/icon_mouse.png) 0/16px 24px no-repeat;
}

/*----------------------------------------*\
  || Date Basic
\*----------------------------------------*/

.date-box2 {
  display: inline-flex;
  flex-wrap: wrap;
}

.date-basic2 {
  width: 186px;
  background: var(--bg-clr2) url(/img/common/icon_date.png) no-repeat right 10px center / 19px;
}

.datemid {
  display: inline-block;
  min-width: 26px;
  line-height: 45px;
  text-align: center;
  font-weight: $bold;
  font-size: 18px;
  color: var(--body-clr);
  vertical-align: middle;
}

.datemid0 {
  display: inline-block;
  width: 0;
  line-height: 45px;
  text-align: center;
  font-weight: $bold;
  font-size: 18px;
  color: var(--body-clr);
  vertical-align: middle;
}

.datemid02 {
  display: inline-block;
  width: 27px;
  line-height: 45px;
  text-align: center;
  font-weight: $bold;
  font-size: 18px;
  color: var(--body-clr);
}

.accmid {
  float: left;
  width: 26px;
  line-height: 45px;
  text-align: center;
  font-weight: $regular;
  font-size: 18px;
  color: var(--body-clr);
  vertical-align: middle;
}

/*----------------------------------------*\
  || Input Timer
\*----------------------------------------*/

.input-timer-wrap {
  position: relative;
  width: 525px;
  margin-right: 10px;

  .timer {
    position: absolute;
    top: 0;
    right: -10px;
  }
}

/*----------------------------------------*\
  || Input Phone
\*----------------------------------------*/

.input-phone {
  @include flex($gap: 10px, $wrap: wrap);

  &:has(+ .notice-i-list) {
    margin-bottom: 10px;
  }

  .input-basic {
    flex: 2;
  }

  .timer {
    min-width: 76px;
    margin-left: -87px;
    padding: 0 0 0 22px;
    font-size: 16px;
    color: var(--neg-clr);
  }

  .btn-sm-wh {
    flex: 1;
  }

  // &.ty01 span.timer {
  //   font-size: 16px;
  //   right: 0;
  // }

  // &.ty01 .input-basic {
  //   padding: 0 95px 0 10px !important;
  // }
}

/*----------------------------------------*\
  || 자동납부 변경신청
\*----------------------------------------*/

.input-formbox {
  margin-top: 28px;
  color: #666;
  font-size: 16px;
  line-height: 36px;
}

/*----------------------------------------*\
  || Input State
\*----------------------------------------*/

.text-basic {
  width: 100%;
  height: auto;
  min-height: 100px;
  max-width: 400px;
  padding: 11px 10px;
  border: 1px solid var(--disable-clr);
  background-color: var(--bg-clr2);
  resize: vertical;
  font-size: 14px;
  line-height: 1.2em;
  text-align: left;

  &.readonly,
  &.disable,
  &.error {
    resize: none;
  }
}

.algo {
  padding: 170px 0 40px;
}

.success {
  border-color: var(--main-clr);
}

.error {
  background-color: #faeaea;
  border-color: var(--neg-clr);
  color: var(--neg-clr);

  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::-moz-placeholder {
    color: var(--neg-clr);
  }
}

input.disable,
input.readonly {
  background-color: var(--disable-clr2) !important;
  border-color: var(--disable-clr2) !important;
  color: var(--cap-clr) !important;
  cursor: default;
}

.input-date {
  position: relative;

  span {
    position: absolute;
  }
}

.in-small {
  width: 90px;
  margin-left: 20px;
  text-align: right;

  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::-moz-placeholder {
    color: var(--cap-clr);
  }
}

/*----------------------------------------*\
  || Editor
\*----------------------------------------*/

.editor {
  margin-top: 20px;
  padding: 23px 40px 0;
  border-top: 1px solid var(--disable-clr);
  font-size: 16px;
  line-height: 23px;

  * {
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: 0.2px;
  }

  th,
  td {
    border: 1px solid #000 !important;
  }

  .non-tbl th,
  .non-tbl td {
    border: 0 !important;
  }

  &.type02 {
    margin-top: 35px;
    padding-top: 40px;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .input-basic {
      padding: 0 13px !important;
      line-height: 45px;

      &.input-won {
        padding-right: 47px !important;
        line-height: 39px;
      }
    }

    .input-basic,
    .select-basic {
      max-width: none;
      font-size: 13px;
      padding: 0 13px;
      margin-right: 0;
    }

    .select-basic.phone {
      max-width: 155px;
    }

    .text-basic {
      font-size: 16px;
      max-width: none;
      padding: 10px 3.7%;
      resize: none;
    }

    .date-box2 {
      // max-width: none;

      .input-area {
        max-width: 83%;
      }

      // .datemid + .input-area {
      //   display: block;
      //   margin-top: 7px;
      // }

      // .datemid0 + .input-area {
      //   display: block;
      //   margin-top: 7px;
      // }
    }

    .date-inq {
      .date-back-wrap {
        width: 100%;

        .date-basic2 {
          width: calc(100% - 24px);
          margin-left: 0px;
        }
      }

      .date-basic2 {
        width: calc(100% - 24px);
        margin-left: 24px;
        margin-bottom: 7px;
      }
    }

    .datemid {
      display: inline-block;
      min-width: 24px;
      line-height: 45px;
      font-size: 16px;
    }

    .datemid0 {
      display: inline-block;
      width: 24px;
      line-height: 45px;
      font-size: 16px;
    }

    .btn-show-list {
      display: inline-block;
      border: 1px solid var(--body-clr);
      padding: 8px 8.52%;
      color: var(--body-clr);
      font-size: 15px;
      width: auto;
      height: auto;

      &.option {
        width: 100%;
        color: #363636;
        border: 1px solid #bbb;

        span {
          display: inline-block;
          vertical-align: middle;
        }

        .icon-list {
          display: inline-block;
          vertical-align: middle;
          width: 10px;
          height: 10px;
          background: url(/img/common/icon_list.png) no-repeat center / contain;
        }
      }

      &::before,
      &::after {
        display: none;
      }
    }

    .m-input span,
    .m-input02 span {
      margin-left: 9px;
    }

    .m-input {
      .input-basic {
        max-width: calc(50% - 16px);
      }

      .pass {
        margin-right: 0;
      }

      .btn-sm-wh {
        width: 100%;
        margin-top: 10px;
      }
    }

    .m-input02 {

      .input-basic,
      .select-basic {
        max-width: 26%;
      }
    }
  }

  .input-won ~ .txt {
    font-size: 16px;
  }
}

@include respond-to ("medium") {
  html.responsive .select-basic.phone {
    max-width: none;
  }

  .select-basic {
    &.w-auto {
      width: 100% !important;
      max-width: 100%;
      margin: 0 0 8px 0 !important;
    }
  }
}
