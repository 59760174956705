@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || 랜딩페이지
\*----------------------------------------*/

.table-type01.ods {
  td {
    padding: 30px 30px 27px !important;
    text-align: left;
  }

  .product-title {
    padding-top: 26px;
  }

  .product-txt02 {
    display: block;
    margin-top: 10px;
    font-weight: $bold;
    line-height: 20px;
  }

  .ico-internet {
    letter-spacing: 1px;
  }
}

.ods-boxstyle01 {
  width: 100%;
  margin-top: 40px;
  padding: 20px 20px 18px;
  border: 1px solid var(--line-clr2);
  font-size: 16px;
  line-height: 24px;
  border-radius: 14px;
}

.ods-text {
  margin-top: 10px;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

// @include respond-to ("huge") {
//   html.responsive {}
// }
