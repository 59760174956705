@charset "utf-8";

/*----------------------------------------*\
  || Size
\*----------------------------------------*/

.common-headline {
  font-size: 25px !important;
  line-height: 150% !important;
}

.common-headline02 {
  font-size: 23px !important;
  line-height: 150% !important;
}

.common-headline03 {
  font-size: 20px !important;
  line-height: 150% !important;
}

.common-tit01 {
  font-size: 18px !important;
  line-height: 150% !important;
}

.common-tit02 {
  font-size: 17px !important;
  line-height: 150% !important;
}

.common-txt01 {
  font-size: 16px !important;
  line-height: 150% !important;
}

.common-txt02 {
  font-size: 15px !important;
  line-height: 150% !important;
}

.common-txt03 {
  font-size: 14px !important;
  line-height: 150% !important;
}

.common-cap01 {
  font-size: 13px !important;
  line-height: 150% !important;
}

.common-cap02 {
  font-size: 12px !important;
  line-height: 150% !important;
}

.common-cap03 {
  font-size: 11px !important;
  line-height: 150% !important;
}

.ft_24 {
  font-size: 24px;
  line-height: 28px;
}

.ft_32 {
  font-size: 32px;
}

.ft_36 {
  font-size: 36px;
}

.font01 {
  font-size: 16px;
}

.font-1rem {
  font-size: 16px !important;
}

.ft-reset {
  font-size: 0 !important;
}

.txt-inh * {
  font-size: inherit !important;
}

/*----------------------------------------*\
  || Align
\*----------------------------------------*/

.tal,
.al-l {
  text-align: left !important;
}

.tac,
.al-c {
  text-align: center !important;
}

.tar,
.al-r {
  text-align: right !important;
}

.lsp {
  letter-spacing: -1px !important;
}

/*----------------------------------------*\
  || Weight
\*----------------------------------------*/

.fw-t {
  font-weight: $thin !important;
}

.fw-l {
  font-weight: $light !important;
}

.fw-n {
  font-weight: $regular !important;
}

.fw-m {
  font-weight: $medium !important;
}

.fw-b,
.txt-bold,
.font-type01 {
  font-weight: $bold !important;
}

/*----------------------------------------*\
  || Color
\*----------------------------------------*/

:root {
  @each $name, $color in $color {
    #{$name}: #{$color};
  }
}

@each $name, $color in $colorName {
  .bg-#{$name} {
    background-color: var(#{$color}) !important;
  }
}

@each $name, $color in $colorName {
  .color-#{$name} {
    color: var(#{$color}) !important;
  }
}

@each $name, $color in $colorName {
  .bor-#{$name} {
    border: 2px solid var(#{$color}) !important;
  }
}

.all-color01,
.bg-color01 {
  border-color: var(--main-clr) !important;
  background-color: var(--main-clr) !important;
}

.txt-color01 {
  color: var(--main-clr) !important;
}

.all-color02,
.bg-color02 {
  border-color: var(--neg-clr) !important;
  background-color: var(--neg-clr) !important;
}

.txt-color02,
.color-active {
  color: var(--neg-clr) !important;
}


.txt-white {
  color: var(--bg-clr2) !important;
}

.txt-black {
  color: var(--tit-clr) !important;
}

.txt-red {
  color: var(--neg-clr) !important;
}

.txt-blue {
  color: var(--pos-clr) !important;
}

.b-font,
.txt-def,
.txt-333 {
  color: var(--body-clr) !important;
}

.txt-666 {
  color: #666 !important;
}

.txt-999 {
  color: var(--cap-clr) !important;
}

.f-color07 {
  color: var(--main-clr) !important;
}

.f-color08 {
  color: red !important;
}

[style="background: #e2e2e2"] [style="color: #ff6262"] {
  color: #fb4a4a !important;
}

/*----------------------------------------*\
  || Etc
\*----------------------------------------*/

.txt-underline {
  text-decoration: underline !important;
}

.tool-number {
  text-decoration: underline;
}

.line-h {
  line-height: 1.4;
}

.txt-lh {
  line-height: 1.5 !important;
}

.txt- {
  &over {
    @include ellipsis;
  }

  &multi {
    @include ellipsis(2);
  }
}

.break-word {
  word-break: break-word;
}

.underline {
  text-decoration: underline !important
}

.txt-up {
  display: inline-block;
  margin-bottom: 10px;
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

.fz88 {
  font-size: 14px !important;
}

.fz100 {
  font-size: 16px !important;
}

.fz112 {
  font-size: 18px !important;
}

.fz125 {
  font-size: 20px !important;
}

.fz225 {
  font-size: 36px !important;
}

.lh120 {
  line-height: 20px !important;
}

.lh150 {
  line-height: 24px !important;
}

.lh170 {
  line-height: 28px !important;
}

.lh130p {
  line-height: 130% !important;
}

/* etc */

.big-num-wrap {
  margin: 10px auto 20px;
}

.big-num {
  font-size: 22px;
  font-weight: $bold;
  line-height: 150%;
  color: var(--main-clr);
}

.big-num-txt {
  margin-left: 3px;
  font-size: 30px;
  line-height: 36px;
  color: #666;
}
