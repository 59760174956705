@charset "utf-8";

/*----------------------------------------*\
  || Login Wrap
\*----------------------------------------*/

.login-wrap {
  position: relative;
  max-width: 640px;
  margin: auto;
  padding: 40px 0 100px;

  .page-main-title {
    margin: 30px auto 20px;
    text-align: center;
  }

  .basic-frame {
    @include flex($direction: column, $justify: center);
    height: 583px;
    padding-top: 140px;
    padding-bottom: 0;
  }

  .basic-frame-top {
    position: absolute;
    inset: 0;
    bottom: initial;
    background-color: var(--bg-clr2);
    border-radius: 20px 20px 0 0;

    .basic-tab-list {
      margin-bottom: 0;
    }
  }

  [role="tabpanel"] {
    @include flex($justify: flex-end, $direction: column);
    @include size(100%);
    padding-bottom: 40px;

    &:has(.login-qr-box) {
      justify-content: center;
    }
  }

  .toggle-checkbox-wrap {
    margin-top: 40px;
  }

  .simple-link-list {
    width: 100%;
    margin-top: 85px;
  }

  .basic-btn {
    width: 100%;
    margin-top: 10px;
  }

  .login-inp-wrap {
    width: 500px;
  }

  .basic-inp-wrap {
    height: 54px;
  }

  .login-btn-list {
    @include flex($justify: space-between);
    width: 100%;
    margin-top: 30px;
  }

  .private-list {
    justify-content: center;
    margin-bottom: 45px;

    & + .private-list {
      margin-top: 10px;
    }
  }

  .private-item {
    height: 145px;
    min-width: 150px;
    max-width: 150px;

    &:nth-child(1) a {
      margin-left: auto;
    }

    &:nth-child(1),
    &:nth-child(2) {
      min-width: 205px;

      a {
        max-width: 150px;
      }
    }
  }

  .login-qr-box {
    @include flex($direction: column, $gap: 20px, $justify: center);
    @include size(240px, 180px);
    margin-bottom: 30px;
    background-color: var(--bg-clr2);
    border: 1px solid var(--line-clr3);
    border-radius: 14px;
    transition: all .15s ease;

    &:hover {
      border-color: var(--main-clr);
    }

    .img {
      @include size(66px);
    }

    .text {
      font-size: 20px;
      line-height: 24px;
      color: var(--tit-clr);
      text-align: center;
    }
  }
}

@include respond-to ("medium") {
  .login-wrap {
    padding: 0;

    .basic-frame-top {
      position: static;
      background-color: transparent;
    }

    .page-main-title {
      margin: 0 auto 30px
    }

    .basic-frame {
      height: auto;
      padding: 30px 20px;
    }

    .basic-tab-list,
    [role=tabpanel]:not(#tabPanel2) {
      display: none !important;
    }

    #tabPanel2 {
      display: flex !important;
      padding-bottom: 0;

      .login-btn-list,
      .simple-link-list {
        display: none;
      }
    }

    // .square-btn {
    //   width: calc(100vw - 70px);
    //   height: 160px;
    // }

    .login-inp-wrap {
      width: 100%;
      padding-bottom: 0;
    }

    // .private-list {
    //   justify-content: flex-start;
    //   margin-bottom: 20px;

    //   .img {
    //     @include size(48px);
    //   }

    //   .text {
    //     font-size: 14px;
    //     line-height: 16px;
    //   }
    // }

    // .private-item:nth-child(n) {
    //   min-width: calc((100% - 20px) / 3);
    //   height: 100px;
    //   flex: 0;

    //   a {
    //     gap: 10px;
    //   }
    // }
  }
}

/*----------------------------------------*\
  || QR 로그인
\*----------------------------------------*/

.time-counter {
  @include flex($justify: space-between);
  width: 70px;
  margin: auto;
  font-size: 16px;
  line-height: 25px;

  &::before {
    @include size(21px);
    @include bgImg($url: '/img/common/icon/icon_time.png');
    content: '';
  }
}

.qr-img-wrap {
  @include size(158px, auto);
  margin: 30px auto;
  padding: 4px;
  background-color: var(--main-clr);

  img {
    @include size(150px);
    padding: 14px;
    background-color: var(--bg-clr2);
  }

  .time-counter {
    width: 66px;
    margin-top: 6px;
    margin-bottom: 5px;
    line-height: 19px;
    color: var(--bg-clr2);

    &::before {
      @include size(16px);
      background-image: url("/img/common/icon/icon_time_w.png");
    }
  }
}

.qr-num-wrap {
  @include flex($justify: center, $gap: 10px);

  .qr-num {
    @include flex($justify: center);
    @include size(130px, 54px);
    border: 1px solid var(--line-clr3);
    border-radius: 10px;
    font-size: 24px;
  }
}

.qr-logo-wrap {
  @include size(70px);
  @include flex($justify: center);
  position: absolute;
  right: 30px;
  bottom: 0;
  padding-bottom: 3px;
  border: 1px solid var(--line-clr3);
  border-radius: 20px;

  img {
    width: 30px;
  }
}

@include respond-to ("medium") {
  .qr-logo-wrap {
    display: none;
  }
}


/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.log-radio .radio-box-wrap {
  float: right;
}

/*----------------------------------------*\
  || Login
\*----------------------------------------*/


.login-box-wrap {
  border-top: 1px solid var(--body-clr);
}

.login-box {
  border-right: 1px solid var(--disable-clr);
  float: left;
  width: 50%;
  padding: 44px 71px 40px;
  display: table;

  &:last-child {
    border-right: none;
  }
}

.login-sub-title {
  margin-bottom: 33px;
  font-weight: $regular;
  font-size: 20px;
}

.login-center {
  height: 193px;

  &.type01 {
    width: 406px;

    .input-basic {
      width: 99%;
      margin-bottom: 10px;
    }

    .btn-log {
      width: 400px;
    }

    .vitualKeyCheck {
      float: right;
    }

    // .icon-mouse {
    //   margin-left: -34px;
    //   vertical-align: top;
    // }

    // .btn-mouse {
    //   top: -15px;
    //   width: 30px;
    //   height: 40px;
    //   margin-left: -30px;
    // }
  }
}

.btn-log {
  width: 100%;
  height: 51px;
  font-weight: $regular;
  font-size: 20px;
  box-sizing: border-box;
  border-radius: 10px;

  &.bg-color01 {
    background-color: var(--main-clr);
    color: var(--bg-clr2);
  }

  &.large {
    height: 70px;
    margin-bottom: 13px;
  }

  &.wh {
    border: 1px solid var(--disable-clr);
    border-bottom-width: 2px;
  }

  .log-icon {
    width: 20px;
    height: 27px;
    vertical-align: middle;
    background: url("/img/common/login_icon.png") no-repeat left center;
    background-size: cover;
    margin-right: 10px;
  }
}

.log-radio-title {
  font-weight: $regular;
  font-size: 14px;
  margin: 3px 0 22px;
}

.log-link-wrap {
  text-align: left;

  li {
    padding-right: 10px;
    background: url("/img/common/arrow_icon_black.png") no-repeat right center;
    margin-right: 27px;
    font-size: 14px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }

  &.ty01 {
    margin: 4px 0 21px;
    text-align: center;

    li {
      margin-right: 17px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.ty02 {
    margin-top: 1px;

    li {
      margin-right: 10px;
    }
  }
}

.login-bottom {
  border-top: 1px solid var(--disable-clr);
  border-bottom: 1px solid var(--disable-clr);
  padding: 40px 79px 40px 71px;
}

.log-bottom-title {
  font-weight: $regular;
  font-size: 18px;
  margin-bottom: 10px;
}

.log-bottom-txt {
  font-size: 14px;
}

.log-btn-wrap {
  width: 250px;

  .btn-sm-wh {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.bg-color01 {
      border: 0;
      background-color: var(--main-clr);
      color: var(--bg-clr2);
    }
  }
}

/*----------------------------------------*\
  || 아이디 찾기
\*----------------------------------------*/

.link-box-wrap {
  margin-top: 50px;
  border-top: 1px solid var(--disable-clr);
}

.link-box {
  width: 50%;
  float: left;
  padding: 40px;

  .box-title {
    font-size: 18px;
    font-weight: $medium;
    margin-bottom: 10px;
  }

  .box-txt {
    font-size: 14px;
    line-height: 22px;
    word-break: keep-all;
  }
}

.link-txt-box {
  width: 350px;
  float: left;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .login-box-wrap {
      margin-top: 0;
      border-top: none;
    }

    .m-none {
      display: none;
    }

    .login-box.login-box-m {
      width: 100%;
      background: var(--bg-clr2);
      padding: 0 15px;

      .login-sub-title {
        display: none;
      }
    }

    .login-center {
      width: 100%;
      margin-bottom: 10px;

      .btn-log {
        width: 100%;
        margin-top: 30px;
      }

      button,
      input {
        margin-top: 20px;
      }
    }
  }
}

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  .log-radio {
    .radio-box-wrap {
      float: left;
      margin-bottom: 10px;
    }

    .log-radio-title {
      margin-bottom: 15px;
    }
  }
}
