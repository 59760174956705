@charset "utf-8";

/*----------------------------------------*\
  || Basic Btn Wrap
\*----------------------------------------*/

.basic-btn-wrap {
  @include flex($justify: center, $gap: 10px);
  width: 100%;
  margin: 40px auto;

  &.justify-content-start {
    justify-content: start;
  }
}

.page-result-wrap + .basic-btn-wrap {
  margin-top: 60px;
}

/*----------------------------------------*\
  || Basic Button
\*----------------------------------------*/

.basic-btn {
  @include flex($justify: center);
  @include size(230px, 54px);
  background-color: var(--main-clr);
  border-radius: 14px;
  font-size: 18px;
  font-weight: $medium;
  line-height: 21px;
  color: var(--bg-clr2);
  transition: all .2s ease;

  &:hover {
    @include darken($mainClr, 3%);
  }

  &:disabled {
    background-color: var(--cap-clr);
    cursor: default;
    pointer-events: none;
  }

  &.type-s {
    @include size(74px, 30px);
    background-color: var(--bg-clr2);
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    font-size: 12px;
    font-weight: $medium;
    line-height: 14px;
    color: #666;

    // &:hover {
    //   filter: brightness(.98)
    // }
  }

  &.type-sm {
    @include size(auto, 40px);
    min-width: fit-content;
    padding: 12px 15px;
    background-color: var(--bg-clr2);
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    font-size: 14px;
    font-weight: $medium;
    line-height: 16px;
    color: var(--body-clr);

    // &:hover {
    //   filter: brightness(.98)
    // }
  }

  &.type-inp {
    @include flex;
    @include size(auto, 45px);
    min-width: 108px;
    padding: 0 15px;
    background-color: var(--bg-clr2);
    border: 1px solid var(--line-clr3);
    border-radius: 10px;
    font-size: 14px;
    font-weight: $bold;
    line-height: 16px;
    color: var(--tit-clr);

    // &:hover {
    //   filter: brightness(.98)
    // }
  }

  &.border {
    @include size(auto);
    padding: 11px 24px;
    background-color: var(--bg-clr2);
    border: 1px solid #d9d9d9;
    border-radius: 40px;
    font-size: 16px;
    line-height: 19px;
    color: var(--cap-clr);

    &[aria-pressed="true"] {
      background-color: var(--main-clr);
      border-color: var(--main-clr);
      color: var(--bg-clr2);
    }
  }

  &.grey {
    background-color: var(--cap-clr);

    &:hover {
      @include darken($capClr, 3%);
    }
  }

  &.white {
    background-color: var(--bg-clr2);
    border: 1px solid var(--line-clr3);
    color: var(--tit-clr);

    &:hover {
      background-color: var(--bg-clr1);
    }
  }

  &.reverse {
    background-color: var(--bg-clr2);
    border: 1px solid var(--main-clr);
    color: var(--main-clr);

    // &:hover {
    //   filter: brightness(.98)
    // }
  }
}

@include respond-to ("medium") {
  .basic-btn {
    width: 100%;

    &.border {
      padding: 6px 14px;
      font-size: 15px;
    }
  }
}

/*----------------------------------------*\
  || Text Type
\*----------------------------------------*/

.btn-line {
  font-size: 12px;
  font-weight: $bold;
  color: var(--tit-clr);
  line-height: 14px;
  text-decoration: underline;
  text-underline-offset: 3px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.btn-mini {
  padding: 6px 8px;
  background-color: var(--line-clr);
  border-radius: 6px;
  font-size: 12px;
  font-weight: $medium;
  line-height: 14px;
  transition: all .15s ease;

  &:hover {
    @include darken($lineClr);
  }

  &.white {
    background-color: var(--bg-clr2);
    border: 1px solid #dedede;
    font-size: 14px;
    font-weight: $regular;
    line-height: 16px;
    color: var(--tit-clr);

    &:hover {
      @include darken($bgClr2);
    }
  }

  &.mint {
    background-color: #0c7474;
    border: 0;
    font-weight: $regular;
    line-height: 16px;
    color: var(--bg-clr2);

    &:hover {
      @include darken(#0c7474);
    }
  }
}

.more-btn {
  @include flex($justify: center);
  @include size(100%, 51px);
  background-color: #f2f4f6;
  border-radius: 12px;
  font-size: 16px;
  font-weight: $bold;
  line-height: 19px;
  color: var(--tit-clr);
  transition: all .2s ease;

  &:hover {
    @include darken(#f2f4f6);
  }
}

/*----------------------------------------*\
  || Icon Type
\*----------------------------------------*/

.basic-btn--edit {
  @include bgImg($url: "/img/common/icon/icon_arrow_back.png", $size: 17px);
}

.clearbtn {
  @include bgImg($url: "/img/content/main/icon_delete01.png");
  display: none;
  width: 24px;
  height: 24px;
  opacity: 0;
}

.search-btn {
  @include bgImg($url: "/img/content/main/icon_search.png");
  display: inline-block;
  min-width: 36px;
  height: 36px;
  margin-right: 20px;
}

.mouse-btn {
  @include size(24px);
  @include bgImg($url: "/img/common/icon/icon_mouse.png");
}

.calendar-btn {
  @include size(19px);
  @include bgImg($url: "/img/common/icon/icon_calendar.png");
}

.del-btn {
  border-radius: 4px;
  transition: all .15s ease;

  &:not(.icon) {
    @include size(auto, 24px);
    border: 1px solid var(--neg-clr);
    color: var(--neg-clr);
    padding: 0 8px;
    position: relative;

    &:hover::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--neg-clr);
      opacity: .05;
    }
  }

  &.icon {
    @include bgImg($url: "/img/common/icon/icon_close_s.png", $size: 10px);
    @include size(24px);

    &:hover {
      background-color: var(--line-clr);
    }
  }
}

.menu-kebab {
  @include size(12px, 22px);
  @include bgImg($url: "/img/common/icon/icon_kebab.png", $x: right);
  display: block;
}

.btn-copy {
  @include size(23px);
  @include bgImg($url: "/img/common/icon/icon_paste.png", $size: 16px);
  display: inline-block;
  border-radius: 4px;

  &:hover {
    background-color: var(--line-clr);
  }
}

.acco-util-btn {
  @include size(23px);
  @include bgImg($url: null, $size: 18px);
  transition: all .15s ease;

  &:hover {
    background-color: var(--line-clr);
  }

  &.doc {
    background-image: url('/img/common/icon/icon_doc.png');
  }

  &.msg {
    background-image: url('/img/common/icon/icon_msg.png');

    &.off {
      background-image: url('/img/common/icon/icon_msg_on.png');
    }
  }

  &.fav {
    background-image: url('/img/common/icon/icon_star.png');

    &.on {
      background-image: url('/img/common/icon/icon_star_on.png');
    }
  }
}

/*----------------------------------------*\
  || Mix Type
\*----------------------------------------*/

.btn {
  &--chevron-down {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    line-height: 14px;
    color: var(--cap-clr);

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      background: url(/img/common/icon/icon_chevron_down.png) no-repeat center / contain;
    }

    .active,
    &[aria-expanded="true"] {
      &::after {
        transform: scaleY(-1);
      }
    }
  }
}

.btn-agree {
  @include flex($gap: 8px);
  font-size: 16px;
  font-weight: $medium;
  line-height: 19px;
  color: var(--tit-clr);

  &::before {
    @include size(24px);
    @include bgImg($url: "/img/common/icon/icon_again.png", $x: left, $size: 24px);
    content: '';
    display: block;
    transition: all .3s ease;
  }

  &:hover::before {
    transform: rotate(-45deg);
  }
}

.down-btn {
  @include flex($justify: center, $gap: 10px);
  padding: 10px 16px;
  background-color: #f0f0f0;
  border-radius: 12px;
  font-size: 12px;
  font-weight: $bold;
  line-height: 14px;

  &::before {
    @include size(14px, 13px);
    @include bgImg($url: '/img/common/icon/icon_down.png');
    content: '';
    display: block;
  }

  // &:hover {
  //   filter: brightness(.98)
  // }
}

.add-btn {
  @include flex($justify: center, $gap: 4px);
  font-size: 16px;
  font-weight: $medium;
  color: var(--tit-clr);

  &::before {
    content: '+';
    position: relative;
    top: -1px;
  }

  small {
    font-size: .67em;
    color: var(--cap-clr);
  }
}

.util-btn {
  @include flex($gap: 4px);
  font-size: 14px;
  line-height: 16px;
  color: var(--tit-clr);

  &::before {
    @include size(24px);
    @include bgImg;
    content: "";
    display: block;
  }

  &.excel::before {
    background-image: url("/img/common/icon/icon_excel.png");
  }

  &.print::before {
    background-image: url("/img/common/icon/icon_print.png");
  }

  &:hover {
    @include mintify(1);
    font-weight: $bold;
  }
}

.square-btn {
  @include flex($direction: column, $justify: center, $gap: 20px);
  @include size(240px, 180px);
  border-radius: 14px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--line-clr3);
  transition: all .15s ease;

  .img {
    @include size(66px);
  }

  .text {
    font-size: 20px;
    font-weight: $bold;
    line-height: 24px;
    color: var(--tit-clr);
  }

  &:hover {
    border-color: var(--main-clr);
  }
}

.btn-icon {
  @include flex($gap: 8px);
  font-size: 16px;
  line-height: 19px;
  color: var(--tit-clr);

  &:hover {
    text-decoration: underline;
  }

  &::before {
    @include bgImg($x: 0);
    content: '';
  }

  &.id::before {
    @include size(14px);
    background-image: url('/img/common/icon/icon_user.png');
  }

  &.pw::before {
    @include size(10px, 14px);
    background-image: url('/img/common/icon/icon_lock.png');
  }
}

/*----------------------------------------*\
  || Floating Button
\*----------------------------------------*/

.floating-box {
  @include flex($direction: column, $align: flex-end);
  position: fixed;
  top: 30%;
  right: 50px;
  z-index: 1;
  gap: 20px;

  .btn-floating {
    position: relative;
    padding: 20px 85px 20px 18px;
    background-color: var(--bg-clr2);
    border: 1px solid var(--line-clr2);
    border-radius: 30px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
    transition: padding .3s ease;

    &:hover {
      padding-right: 100px;
    }

    .txt {
      font-size: 16px;
      font-weight: $bold;
      line-height: 20px;
      color: #666;
    }

    .img {
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }
}

@include respond-to ("huge") {
  .floating-box {
    top: initial;
    bottom: 50px;
    right: 20px;
  }
}

@include respond-to ("medium") {
  .floating-box {
    bottom: 20px;
    gap: 15px;

    .btn-floating {
      padding: 10px 52px 10px 15px;
      border-radius: 30px;
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);

      &:hover {
        padding-right: 60px;
      }

      .txt {
        font-size: 14px;
        line-height: 18px;
      }

      .img {
        @include size(50px);
        top: -4px;
        right: -3px;
      }
    }
  }
}



/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.btn-downlode {
  border-right: 1px solid var(--disable-clr);
  border-left: 1px solid var(--disable-clr);
  min-width: 140px;
  font-size: 14px;
  background: url("/img/common/btn_down_icon.jpg") no-repeat left 18px center;
  padding: 24px 80px 24px 40px;
}

.btn-slide-active {
  .btn-slide {
    background: url("/img/common/icon_select_fff.png") no-repeat center;
    width: 60px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #999999;
    transform: rotate(180deg);
  }

  .sub-title {
    color: var(--body-clr);
  }
}

.btn-slide {
  background: url("/img/common/icon_select_fff.png") no-repeat center var(--body-clr);
  width: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.btn-right-wrap {
  float: right;
  position: relative;

  .btn-choice:first-child {
    border-left: 1px solid #f7f7f7;
  }
}

.btn-choice {
  width: 50px;
  background: url(/img/common/icon_select_333.png) no-repeat center;
  border-right: 1px solid #f7f7f7;
  padding: 29px 0;
  float: left;

  &:focus {
    background-color: #999;
    background-image: url(/img/common/icon_select_fff.png);
  }

  .close {
    background-image: url(/img/common/btn_close_333.png);

    &:focus {
      background-color: #999;
      background-image: url(/img/common/btn_close_fff.png)
    }
  }
}

.btn-arrow:after {
  content: '';
  float: right;
  width: 32px;
  height: 18px;
  background: url(/img/common/icon_rtarrow_fff.png) no-repeat center center;
}

.btn-size {
  min-width: 200px !important;
}

/*----------------------------------------*\
  || Btn Area
\*----------------------------------------*/

.btn-area {
  @include flex($justify: center, $gap: 10px);
  width: 100%;
  margin: 40px auto;
  padding: 0 !important;

  &.style01 {
    margin-top: -10px;
    padding-top: 0;
  }

  &.retouch-m {
    padding: 30px 20px !important;
    background: #f7f7f7;
    border: 1px solid #efefef;
    text-align: left;

    &.info .btn-sm-wh {
      max-width: 256px;
    }

    &.info .btn-sm-wh + .btn-sm-wh {
      margin-left: 5px;
      margin-right: 0;
    }
  }
}

/*----------------------------------------*\
  || Btn Basic
\*----------------------------------------*/

.btn-basic,
.btn-print,
.btn-down,
.btn-add,
a.btn-basic {
  @include flex($justify: center);
  @include size(auto, 54px);
  min-width: 230px;
  border-radius: 14px;
  font-size: 18px;
  font-weight: $medium;
  line-height: 21px;
  color: var(--bg-clr2);
  transition: all .2s ease;
}

.btn-basic,
a.btn-basic {
  background: var(--main-clr);

  // &.bg-color01:hover {
  //   background-color: #00938d !important;
  // }
}

.btn-basic {

  &.btn-cer01,
  &.btn-cer02 {
    background: #666 url(/img/content/cer/icon_chce_01.png) no-repeat 45px center;
    padding-left: 35px;
  }

  &.btn-cer02 {
    background-image: url(/img/content/cer/icon_chce_02.png);
  }

  &.margin-r-b {
    margin-right: 8px;
  }

  &.btn-kdic {
    border: 1px solid var(--disable-clr);
    background: var(--bg-clr2);

    span {
      font-size: 14px;
      color: var(--body-clr);
      font-weight: $regular;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      line-height: 18px;
      padding-top: 2px;
    }
  }

  .icon-arrow {
    display: inline-block;
    width: 32px;
    height: 18px;
    margin-top: -2px;
    background: url(/img/common/icon_rtarrow_fff.png) no-repeat 50% 50%;
    text-indent: -10000px;
    white-space: nowrap;
    vertical-align: middle;
    overflow: hidden;
  }
}

.btn-print::before {
  background: url(/img/common/icon_print.png) no-repeat center / contain;
}

.btn-down::before {
  background: url(/img/common/icon_down.png) no-repeat center / contain;
}

.btn-add::before {
  background: url(/img/common/icon_add.png) no-repeat center / contain;
}

.btn-basic span {
  color: inherit;
  font-weight: $bold;
}

.btn-print,
.btn-down,
.btn-add {
  gap: 10px;
  background-color: var(--cap-clr);

  &.btn-active {
    background-color: #000;
  }

  span {
    padding: 0 !important;
    font-weight: $bold;
    line-height: 28px;
    color: var(--bg-clr2);
  }

  &::before {
    content: '';
    width: 25px;
    height: 25px;
  }
}

.btn-active {
  background: #000;
}

/*----------------------------------------*\
  || Btn Plus
\*----------------------------------------*/

.btn-plus {
  @include flex($justify: center);
  @include size(100%, 51px);
  background-color: #f2f4f6;
  border-radius: 12px;

  transition: all .2s ease;

  &:hover {
    @include darken(#f2f4f6);
  }

  span {
    font-size: 16px;
    font-weight: $bold;
    line-height: 19px;
    color: var(--tit-clr);
  }

  .icon-plus {
    display: none;
    // width: 34px;
    // height: 34px;
    // background: url('/img/common/icon_plus.png') no-repeat center / 34px;
    // vertical-align: middle;
  }
}

/*----------------------------------------*\
  || Btn-clickbox
\*----------------------------------------*/

.btn-clickbox {
  width: 100%;
  clear: both;
  padding-top: 10px;

  &::after {
    content: '';
    display: block;
    float: none;
    clear: both;
  }

  button {
    margin-right: 10px;
  }
}

/*----------------------------------------*\
  || Btn-small / Btn-sm-wh
\*----------------------------------------*/

.btn-sm {
  width: 70px;
  min-height: 35px;
  border: 1px solid var(--disable-clr);
  border-radius: 4px;
  font-size: 12px;
  color: var(--tit-clr);
}

.btn-small {
  width: 100%;
  max-width: 150px;
  height: auto;
  padding: 15px 0;
  background: var(--main-clr);
  font-size: 16px;
  color: var(--bg-clr2);
  font-weight: $bold;
  border-radius: 14px;
  transition: all 0.2s ease;

  // &:hover {
  //   filter: brightness(0.98);
  // }
}

.btn-small-active {
  background: #000
}

.btn-sm-wh-wrap .btn-sm-wh {
  width: 120px;
  margin-top: 10px;
  margin-right: 20px;
}

.btn-sm-wh {
  width: 100%;
  min-width: fit-content;
  max-width: 120px;
  height: 47px;
  padding: 0 8px;
  background: var(--bg-clr2);
  border: 1px solid var(--line-clr3);
  color: var(--body-clr);
  font-size: 14px;
  border-radius: 10px;
  transition: all .2s ease;

  // &:hover {
  //   filter: brightness(.99)
  // }

  &.resize {
    max-width: none;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  &.small {
    max-width: 50px;
  }

  &.ico_down {
    min-width: 240px;
    padding: 0 40px 0 15px;
    background: var(--bg-clr2) url(/img/common/ico_down.png) no-repeat 95% center;
    text-align: left;
  }

  &.btn-qrcode {
    margin-top: 10px;
    font-weight: $regular;

    &:hover,
    &:active,
    &:focus {
      & ~ .tooltips.qrcode {
        display: block;
      }
    }
  }

  .w90 {
    max-width: 90px;
  }
}

.btn-sm-whactive {
  border-bottom: 2px solid #999;
  border-color: #999;
}

.btn-ctactive {
  position: relative;

  &::after {
    max-width: 220px;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
  }

  span {
    position: relative;
    z-index: 5;
    display: inline-block;
    color: rgba(255, 255, 255, 1);
    font-weight: $bold;
  }
}

/*----------------------------------------*\
  || Btn Link
\*----------------------------------------*/

.btn-link {
  position: relative;
  display: block;
  width: 200px;
  padding: 10px 20px;
  border: 1px solid var(--disable-clr2);
  text-align: center;
  margin: 20px auto;

  &::after {
    content: '';
    position: absolute;
    top: 14px;
    right: 15px;
    border: 1px solid var(--cap-clr);
    width: 9px;
    height: 9px;
    border-left: none;
    border-bottom: none;
    transform: rotate(45deg);
  }
}

/*----------------------------------------*\
  || Btn Thin
\*----------------------------------------*/

.btn-thin-wrap.top .btn-thin {
  margin-top: 15px;
  margin-right: 5px;
}

.btn-thin-wrap.bottom .btn-thin {
  margin-bottom: 15px;
  margin-right: 5px;
}

.btn-thin {
  width: 100%;
  max-width: 80px;
  padding: 4px 0;
  border: 1px solid var(--line-clr3);
  font-size: 12px;
  color: var(--body-clr);
  background: var(--bg-clr2);
  border-radius: 4px;

  &.active,
  &[aria-pressed="true"] {
    color: var(--main-clr) !important;
    border-color: var(--main-clr) !important;
  }

  &.disable {
    background-color: #efefef !important;
    border-color: var(--disable-clr) !important;
  }

  &.sm {
    max-width: 40px;
    width: 100%;
  }

  &.resize {
    width: auto;
    max-width: inherit;
    padding: 4px;
  }

  &.retouch {
    float: right;
    margin-top: -5px;
  }
}

/*----------------------------------------*\
  || Btn Style01
\*----------------------------------------*/

.btn-style01,
.btn-style02,
.btn-style03,
.btn-style04,
.btn-style05,
.btn-style06 {
  transition: all .2s ease;

//   &:hover {
//     filter: brightness(.99);
//   }
}

.btn-style01 {
  display: inline-block;
  min-width: 120px;
  height: 40px;
  padding: 0 10px;
  border: 1px solid var(--disable-clr);
  border-radius: 5px;
  background: var(--bg-clr2);
  color: #666;
  font-size: 16px;
  text-align: center;

  &.type02 {
    background: #666;
  }

  .detailview {
    display: inline-block;
    margin: 0 17px;
    padding: 2px 17px 0 0;
    background: url('/img/hss_common/button/btn_detailview.gif') no-repeat right 3px;

    &.type02 {
      background: url('/img/hss_common/button/btn_detailview_white.png') no-repeat right 3px;
      color: var(--bg-clr2);
    }
  }

  .down {
    margin: 0 17px;
    padding: 0 30px 0 0;
    background: url('/img/hss_common/button/btn_down.gif') no-repeat right 0;
  }

  .download {
    font-size: 16px;
    color: #666;
    font-weight: $regular;
  }
}

a.btn-style01 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 40px;
}

.link-box .btn-style01 .detailview {
  padding: 0 17px 0 0;
  line-height: 38px;
  background-position: right center;
}

/*----------------------------------------*\
  || Btn Style02
\*----------------------------------------*/

.btn-style02 {
  display: inline-block;
  min-width: 200px;
  height: 50px;
  padding: 0 18px;
  border: 1px solid var(--main-clr);
  border-radius: 5px;
  background: #f3f9e8;
  color: var(--body-clr);
  font-size: 18px;
  font-weight: $bold;
  text-align: center;
}

/*----------------------------------------*\
  || Btn Style03
\*----------------------------------------*/

.btn-style03 {
  display: inline-block;
  min-width: 200px;
  height: 50px;
  padding: 0 18px;
  border: 1px solid var(--main-clr);
  border-radius: 5px;
  background: var(--bg-clr2);
  color: var(--body-clr);
  font-size: 18px;
  font-weight: $bold;
  text-align: center;
}

/*----------------------------------------*\
  || Btn Style04
\*----------------------------------------*/

.btn-style04 {
  display: inline-block;
  min-width: 150px;
  height: 40px;
  padding: 0 18px;
  border-radius: 5px;
  background: var(--cap-clr);
  color: var(--bg-clr2);
  font-size: 16px;
  font-weight: $bold;
  text-align: center;
}

/*----------------------------------------*\
  || Btn Style05
\*----------------------------------------*/

.btn-style05 {
  display: inline-block;
  min-width: 150px;
  height: 40px;
  padding: 0 18px;
  border-radius: 5px;
  background: #918d8d;
  color: var(--bg-clr2);
  font-size: 16px;
  font-weight: $bold;
  text-align: center;
}

/*----------------------------------------*\
  || Btn Style06
\*----------------------------------------*/

.btn-style06 {
  display: inline-block;
  min-width: 150px;
  height: 40px;
  padding: 0 18px;
  border-radius: 5px;
  background: var(--main-clr);
  color: var(--bg-clr2);
  font-size: 16px;
  font-weight: $bold;
  text-align: center;
}

a.btn-style06 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 40px;
}

/*----------------------------------------*\
  || Btn Table
\*----------------------------------------*/

.btn-table-wrap {
  padding: 0 !important;
}

.btn-table {
  position: relative;
  float: none !important;
  width: 100%;
  height: 61px;
  margin-right: 0 !important;
  display: block;
  border: 0;
  background: none;

  &:hover {
    background-color: #999;
  }

  &:hover:before,
  &:hover:after {
    background-color: var(--bg-clr2);
  }

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    content: '';
    width: 16px;
    height: 2px;
    background: var(--body-clr);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

/*----------------------------------------*\
  || Open Btn
\*----------------------------------------*/

.open-btn-wrap {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  border-top: 1px solid var(--disable-clr);
}

.open-btn {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background: var(--bg-clr2);
  border: 1px solid var(--disable-clr);
  border-radius: 50%;

  &::after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 10px;
    background: url('/img/common/icon_slide_up.png') no-repeat center /contain;
    transform: scaleY(-1);
    transition: all .5s ease;
  }

  &.on::after {
    transform: scaleY(1);
  }
}

/*----------------------------------------*\
  || Table Button
\*----------------------------------------*/

.btn-double,
.btn-trio {
  margin: auto;

  > .tool-box {
    width: 54px;
    float: left;
    margin-right: 5px !important;
    margin-bottom: 0;
  }

  > button {
    float: left;
    margin-right: 5px !important;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.btn-double {
  &.sm {
    width: 85px;
  }

  &.lg {
    width: 165px;
  }

  > .tool-box {
    float: left;
    margin-right: 5px !important;
    margin-bottom: 0;
  }
}

.btn-trio {
  width: 170px;

  &.type02 {
    width: 160px;

    > .tool-box {
      float: none;
      display: inline-block;
      vertical-align: middle;
      margin-right: 2px !important
    }

    > button {
      float: none;
      display: inline-block;
      vertical-align: middle;
      margin-right: 2px !important
    }
  }

  &.type03 {
    width: 190px;
    display: flex;
    justify-content: center;
  }

  > .tool-box {
    float: left;
    margin-right: 5px !important;
    margin-bottom: 0;
  }
}

.btn-quartet {
  width: 215px;
  justify-content: center;
  gap: 4px;
}

/*----------------------------------------*\
  || Btn Search
\*----------------------------------------*/

.btn-search-box {
  position: relative;
  min-width: 400px;
}

.btn-search {
  display: inline-block;
  width: 40px;
  height: 47px;
  background: url('/img/common/icon_search.png') right 12px center/22px 22px no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
}

/*----------------------------------------*\
  || Btn-mso
\*----------------------------------------*/

.btn-mso-txt {
  min-height: 16px;
  padding: 1px 0 1px 23px;
  background-size: 16px, 16px;
  background-position: left center;
  background-repeat: no-repeat;
  font-size: 14px;
  color: var(--body-clr);

  &.btn-mso04.slide {
    width: 120px;
    padding: 27px 0 27px 20px;
    border-left: 1px solid var(--disable-clr);
    background-position: left 20px center;
  }
}

.btn-mso,
.btn-mso-s {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-mso {
  @include size(54px);
}

.btn-mso-s {
  @include size(16px);
}

.btn-mso01,
.btn-mso04 {
  padding-left: 28px;
  background-image: url("/img/common/icon/icon_excel.png");
  background-size: 24px;
  font-size: 14px;
  line-height: 16px;
  color: var(--tit-clr);

  &:hover {
    @include mintify(1);
    font-weight: $bold;
  }
}

.btn-mso02 {
  background-image: url("/img/common/mso_icon02.png");
}

.btn-mso03 {
  background-image: url("/img/common/mso_icon03.png");
}

.btn-mso04 {
  background-image: url("/img/common/icon/icon_print.png");
}

/*----------------------------------------*\
  || Btn-close
\*----------------------------------------*/

.btn-close {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-close-l {
  @include size(23px);
  background-image: url("/img/common/btn_close_l.png");
}

.btn-close-s {
  @include size(10px);
  background-image: url("/img/common/btn_close_s.png");
}

/*----------------------------------------*\
  || Btn Smart 스마트 간편한도조회
\*----------------------------------------*/

.btn-smart-wrap {
  position: relative;
  float: left;
  width: 175px;
  margin-left: 40px;
  background: #ff9358;
  height: 45px;
  text-align: center;
  line-height: 48px;
  border-radius: 30px;
  transition: all .5s ease;

  &:hover {
    margin-left: 50px;
    box-shadow: -10px 0 0 #aaa;
  }
}

.btn-smart {
  color: var(--bg-clr2);
  font-size: 19px;
}

/*----------------------------------------*\
  || Btn Download / Btn Support
\*----------------------------------------*/

.btn-download,
.btn-support {
  width: 90px;
  height: 22px;
  padding-right: 20px;
  background: #7a8999;
  background-image: url('/img/hss_common/bg_download.png');
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-radius: 3px;
  font-size: 11px;
  color: var(--bg-clr2);
  vertical-align: bottom;
  text-align: center;
}

a.btn-download,
a.btn-support {
  margin-left: 16px;
  padding-top: 2px;

  &.type01 {
    display: inline-block;
    width: initial;
    height: initial;
    padding: 10px 30px 10px 10px;
    margin-top: 15px;
  }
}

.btn-support {
  background-position: right 17px center;
}

/*----------------------------------------*\
  || 이전 / 다음
\*----------------------------------------*/

.btn-box04 {
  margin-top: 20px;
  text-align: center;
}

.btn-prev01 {
  .prev {
    color: var(--disable-clr);
    font-size: 14px;
    font-weight: $bold;

    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 10px;
      margin-right: 11px;
      background: url("/img/common/btn_prev01.png") no-repeat;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 10px;
      margin: 0 27px 0 30px;
      background: var(--disable-clr);
    }
  }

  &.active .prev {
    color: var(--body-clr);

    &::before {
      background-image: url("/img/common/btn_prev01_on.png");
    }
  }
}

.btn-next01 {
  .next {
    color: var(--disable-clr);
    font-size: 14px;
    font-weight: $bold;

    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 10px;
      margin-left: 11px;
      background: url("/img/common/btn_next01.png") no-repeat;
    }
  }

  &.active .next {
    color: var(--body-clr);

    &::after {
      background-image: url("/img/common/btn_next01_on.png");
    }
  }
}

.btn-prev01,
.btn-next01 {
  cursor: default;

  &:active {
    cursor: pointer;
  }
}

/*----------------------------------------*\
  || Color
\*----------------------------------------*/

.btn-grey {
  background: var(--cap-clr);
  border-color: var(--cap-clr);
  color: var(--bg-clr2);
}

.btn-blue {
  background: var(--main-clr);
  border: 0;
  color: var(--bg-clr2);
  min-width: 184px;
  margin-bottom: 30px;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .btn-area {
      margin-top: 25px;
      flex-wrap: wrap;

      &.retouch-m {
        padding: 25px 16px;

        .btn-sm-wh {
          width: 100%;
          margin-bottom: 10px;
        }
      }

      // button + button {
      //   margin-top: 15px;
      // }
    }

    .btn-basic {
      width: 100%;
      height: auto;
      padding: 12px 0 11px;
      font-size: 15px;

      &.margin-r-b {
        margin-right: 0;
        margin-bottom: 7px;
      }
    }

    .btn-ctactive {
      height: auto;
      font-size: 15px;
      font-weight: $regular;
      max-width: none;

      &::after {
        max-width: none;
      }
    }

    .btn-print,
    .btn-down,
    .btn-add {
      width: 100%;
      padding: 8px 0 7px;

      span {
        font-size: 15px;
        line-height: 20px;
      }
    }

    .btn-small {
      font-size: 15px;
      max-width: none;
    }

    .btn-sm-wh {
      max-width: none;

      &.agree {
        margin: 10px 0 0 !important
      }

      &.resize.mobile-type01 {
        padding: 0 13px;
      }
    }

    .icon-area {
      &.retouch {
        margin-top: 18px;
        font-size: 12px;

        li {
          margin-right: 7px;
        }
      }

      .btn-sm-wh {
        margin-bottom: 7px;
      }
    }

    .btn-reset {
      display: none;
    }

    .btn-quartet {
      width: 100%;
      padding: 10px;
    }

    .btn-thin {
      max-width: none;
      height: auto;
      width: 100%;
      font-size: 13px;
      padding: 7px 0;

      &.retouch {
        font-size: 10px;
        font-weight: $regular;
        width: auto;
        margin-left: -52px;
        margin-top: 22px;
        margin-bottom: -7px;
        padding: 7px 10px;
        letter-spacing: 3px;
      }
    }

    .tool-box {
      width: 100%;
    }

    .btn-trio,
    .btn-double {
      width: 100%;

      > button {
        display: block;
        height: auto;
        color: var(--body-clr);
        background: var(--bg-clr2);
        font-size: 15px;
        padding: 7px 0;
        border-radius: 2px;
      }
    }

    .btn-trio.type02 {
      display: table-cell;
      padding: 10px 16px;

      > .tool-box {
        max-width: calc(33.3334% - 10px);

        & .btn-thin {
          max-width: 100% !important;
          width: 100%;
          padding: 8px 0;
        }
      }

      & .btn-thin {
        display: inline-block;
      }
    }

    .btn-trio {
      .btn-thin {
        max-width: calc(33.3334% - 10px);
      }

      > .tool-box {
        max-width: inherit;
        width: calc(33% - 10px);
        margin-left: 5px;

        .btn-thin {
          max-width: inherit !important;
          width: 100%;
          margin: 0 !important;
        }
      }
    }

    .btn-double > button {
      width: 50%;
      max-width: calc(50% - 10px);
    }

    .btn-smart-wrap {
      display: none;
    }


    .btn-downlode {
      padding: 18px 65px 18px 30px;
      background: url("/img/common/btn_down_icon.jpg") no-repeat 10px 50%;
    }

    .btn-slide {
      width: 50px;
      background-size: 17px;
    }

    .btn-support {
      width: 112px;
      height: 32px;
    }

    .btn-newWp {
      border-top: 1px solid var(--line-clr3);
      padding-top: 10px;
      margin-top: 10px;
      text-align: center;
    }
  }

  .btn-thin-wrap {
      &.top,
      &.bottom {
        margin-top: 10px;

        .btn-thin {
          width: 60px;
          font-size: 11px;
          padding: 8px 0;
          background-color: #eeeff1;
          border: 0;
          border-radius: inherit;
          color: #363636;
        }
      }

      &.top .btn-thin {
        margin-top: 5px;
      }

      &.bottom .btn-thin {
        margin-bottom: 5px;
      }
    }
}

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  .btn-basic {
    width: 100%;
    min-width: auto;
  }
}
