@charset "utf-8";

/*----------------------------------------*\
  || 전체계좌조회
\*----------------------------------------*/

.inq-info-box {
  @include flex($justify: space-between, $gap: 10px, $wrap: wrap);

  .arrow-link {
    margin-left: auto;
  }
}

.aside-data {
  font-size: 14px;
  font-weight: $regular;

  time {
    margin-left: 10px;
    color: var(--cap-clr);
  }
}

.info-acco-group-item {
  &:has(.btn[aria-expanded="true"]) {
    &:has(.util-box) {
      margin-bottom: 46px;
    }

    .util-box {
      display: flex;
    }
  }

  .util-box {
    display: none;
    align-items: center;
    gap: 20px;
    position: absolute;
    right: 30px;
    bottom: -36px;
  }
}

.info-acco-list {
  display: none;
  padding: 30px;

  &:has(.btn[aria-expanded="true"]) + .info-acco-list {
    border-top: 1px solid #d2d4d4;
  }
}

.info-acco-item {
  @include flex($align: normal, $justify: space-between, $wrap: wrap);
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d9d9d9;

  .ico-bi {
    @include size(48px);
    margin-right: 12px;
    border: 1px solid var(--bg-clr3);
    border-radius: 50%;
  }

  .left,
  .right {
    @include flex($align: normal, $justify: space-between, $direction: column);
    min-height: 52px;

    .title,
    .balance {
      font-size: 18px;
      font-weight: $medium;
      line-height: 21px;
      color: var(--tit-clr);
    }
  }

  .left {
    .title {
      @include flex;
      line-height: unset;
    }

    .name {
      font-size: 16px;
      line-height: 19px;
      color: var(--tit-clr);

      &::before {
        @include size(1px, 14px);
        content: '';
        display: inline-block;
        margin: 0 14px -2px;
        background: #d9d9d9;
      }
    }

    .tag {
      height: 22px;
      margin-left: 10px;
      padding: 3px 6px;
      border: 1px solid var(--main-clr);
      border-radius: 17px;
      font-size: 12px;
      font-weight: $semi;
      line-height: 14px;
      color: var(--main-clr);

      &.red {
        border-color: var(--neg-clr);
        color: var(--neg-clr);
      }

      &.blue {
        border-color: var(--pos-clr);
        color: var(--pos-clr);
      }
    }

    .wrap {
      @include flex($gap: 10px);

      .account {
        font-size: 13px;
        line-height: 15px;
        color: #666;

        &:hover::after,
        &:focus::after {
          content: '';
          @include size(100%, 1px);
          position: relative;
          display: block;
          background-color: #666;
          bottom: -2px;
        }

        em {
          line-height: inherit;
          color: inherit;
          margin-right: 10px;
        }

        span {
          line-height: inherit;
          color: inherit;
        }
      }

      .btn-copy {
        margin-left: -5px;
        margin-right: -5px;
      }

      .date {
        @include flex;
        font-size: 13px;
        line-height: 15px;
        color: var(--cap-clr);

        &::before {
          @include size(1px, 10px);
          content: '';
          margin: 0 14px 0 4px;
          background: #d9d9d9;
        }

        time {
          margin-left: 8px;
          line-height: inherit;
          color: inherit;
        }

        &.notify time {
          color: var(--neg-clr);
        }
      }
    }
  }

  .right {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    margin-left: auto;

    .balance {
      font-weight: $regular;

      .num {
        margin-right: 4px;
        font-weight: $medium;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

@include respond-to ("medium") {
  .info-acco-list {
    display: none;
    padding: 20px 15px;
  }

  .info-acco-item {
    justify-content: normal;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .ico-bi {
      @include size(24px);
      margin-right: 10px;
    }

    .left,
    .right {
      .title,
      .balance {
        font-size: 16px;
        line-height: 19px;
      }
    }

    .left {
      max-width: calc(100% - 34px);
      min-height: 46px;

      .title {
        align-items: normal;
      }

      .wrap {
        flex-wrap: wrap;

        .date {
          width: 100%;

          &::before {
            display: none;
          }
        }
      }

      .name {
        min-width: fit-content;
      }
    }

    .right {
      flex-wrap: wrap;
      margin-top: 15px;

      .balance {
        width: 100%;
        text-align: right;
      }
    }

    .btn-agree {
      justify-content: flex-end;
      width: 100%;
    }
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/* 계좌별명관리팝업 */
.point-icon::before {
  content: "*";
  color: var(--neg-clr);
  padding: 3px;
}

/*----------------------------------------*\
  || 전체계좌조회 : 총잔액
\*----------------------------------------*/

// .inq-assets-box {
//   margin-top: 83px;
// }

.inq-unitdate {
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.25px;

  .title {
    font-weight: $bold;
    letter-spacing: 1px;
  }

  & + .table-wrap01 {
    margin-top: 15px;
  }
}

.assets-unit,
.assets-price {
  color: var(--main-clr);
  font-weight: $bold;
}

.assets-price {
  margin-right: 5px;
  font-size: 18px;
}


.inq-assets-box + .info-table-area {
  margin-top: 50px;
}

/*----------------------------------------*\
  || 계좌상세조회
\*----------------------------------------*/

.lookup-01-wrap {
  @include flex;
  width: 100%;
  padding: 25px;
  background: #f7f7f7;
  border-radius: 20px;

  // &.width .lookup-01-list {
  //   width: 25%;
  // }

  // &.width2 .lookup-01-list {
  //   width: 20%;
  // }

  .look-up-txt {
    font-size: 14px;
    line-height: 24px;
    word-break: auto-phrase;
    min-height: 48px;
  }

  .lookup-title {
    font-weight: $bold;
    font-size: 18px;
  }

  // .lookup-02-list {
  //   width: 50%;
  // }
}

.lookup-01-sample + .table-03-wrap {
  margin-top: 40px;
}

.lookup-01-list {
  flex: 1;
  // width: 33.333%;
  height: auto;
  padding: 0 25px;
  border-right: 1px solid var(--line-clr3);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    border-right: 0;
  }

  &.pt30 {
    padding-top: 0 !important; // 수시지급이자 받기 대응 ib_ibaintr_0100.html
  }

  h5 {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 20px;
  }

  p {
    margin-bottom: 13px;
    font-size: 16px;

    &.txt-bold {
      @include flex($justify: space-between);

      .btn-thin.retouch {
        margin-top: 0;
      }
    }

    > span {
      display: inline-block;
      max-width: 140px;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  span {
    @include flex;
    font-size: 22px;
    font-weight: $bold;
    word-break: auto-phrase;

    & + span,
    &:has(+ span) {
      display: inline;
    }

    &.type01 {
      display: block;
      margin-top: 8px;
      font-size: 18px;
      font-weight: $regular;
      color: var(--cap-clr);
    }

    span {
      margin-top: 5px;
      margin-left: 5px;
      font-size: 16px;
      font-weight: $regular;
    }
  }

  .sub-title-lower {
    margin-left: 15px;
    font-size: 16px;

    &::before {
      content: ':';
      margin-right: 15px;
    }
  }

  .btn-thin.retouch {
    @include flex($justify: center);
    float: none;
    width: fit-content;
    max-width: none;
    min-width: 60px;
    margin: 0 0 0 auto;
    padding:5px 10px;
    border: 1px solid var(--line-clr3);
    font-size: 12px;
    color: var(--body-clr);
  }
}

.lookup-02-wrap,
.lookup-03-wrap {
  background: #f7f7f7;

  h5 {
    margin-bottom: 8px;
  }

  span {
    font-size: 22px;
    vertical-align: baseline;
  }

  .lookup-result {
    display: inline-block;
    font-size: 16px;
    font-weight: $regular;
    padding-left: 8px;
  }

  .lookup-03result {
    display: inline-block;
  }
}

.lookup-02-wrap li {
  // width: 50%;
  // padding: 20px 40px;
  text-align: center;
}

// .lookup-03-wrap li {
//   width: 33.3%;
// }

.list-date li {
  float: left;
  font-size: 13px;

  &::after {
    content: '';
    display: block;
    float: left;
    width: 1px;
    height: 13px;
    margin: 0 10px;
    background-color: var(--disable-clr);
  }

  &:first-child::after {
    display: none;
  }

  &:nth-child(2)::after {
    background: var(--disable-clr);
  }
}

.look-rut::after {
  content: '';
  display: block;
  float: left;
  width: 1px;
  height: 22px;
  margin: 0 20px;
  background-color: var(--disable-clr);
}

// .font-sizeup {
//   font-size: 18px !important;
//   margin-top: -2.5px;
// }

/*----------------------------------------*\
  || 자기앞수표조회
\*----------------------------------------*/

.cach-content-wrap {
  width: 100%;
  border: 1px solid var(--disable-clr);
  padding: 25px 20px;
  background: var(--bg-clr2);
  margin-bottom: 40px;
}

.cach-img {
  margin: 0 auto;
  display: block;
}

.number-k {
  font-size: 1em;
  font-weight: $medium;
  padding: 14px 5px 14px 20px;
  display: inline-block;

  span {
    font-weight: $medium;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .lookup-01-wrap {
      flex-direction: column;
    }

    .lookup-01-list {
      min-width: 100%;
      padding: 20px 0;
      border: 0;
      border-bottom: 1px solid var(--line-clr3);

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      &.pt30:not(:first-child) {
        padding-top: 30px !important;
      }

      h5 {
        margin-bottom: 0;
        font-size: 15px;
      }

      > span {
        margin-top: 3px;
        font-size: 17px;
      }

      span span {
        margin-top: 2px;
        margin-left: 2px;
        font-size: 13px;
      }

      .txt-bold .btn-thin.retouch {
        margin-top: 0;
      }

      .lookup-title {
        font-size: 19px;
      }

      .look-up-txt {
        width: 100%;
        min-height: auto;
        margin-bottom: 0;
        font-size: 13px;
        line-height: 24px;
      }

      .btn-thin.retouch {
        margin: 15px 0 0 auto;
        padding: 7px 10px;
        font-size: 14px;
        letter-spacing: 0;
      }
    }

    // .font-sizeup {
    //   position: relative;
    //   margin-top: 0;
    //   margin-bottom: -20px !important;
    //   padding-bottom: 40px;
    //   font-size: 15px !important;
    //}

    .cach-content-wrap {
      padding: 30px 0;
      margin-bottom: 15px;
    }

    .cach-img {
      width: 80%;
    }

    .number-k {
      padding-right: 0;
      padding-bottom: 0;
      float: right;
    }

    .inquiry-wrap {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .lookup-01-list p > span {
    max-width: 100%;
  }

  .inq-assets-box {
    margin-top: 30px;

    & + .info-table-area {
      margin-top: 50px;
    }
  }

  .inq-unitdate {
    letter-spacing: initial;
  }
}
