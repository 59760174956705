@charset "utf-8";

@mixin clearfix() {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin blind() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);

  &::before {
    content: "\00a0";
    display: block;
    width: 0;
    height: 0;
    font-size: 0;
  }
}

@mixin font($size: null, $weight: null, $type: body, $lh: 150%) {
  @if $type==body {
    font-size: map-get(map-get($fontSize, body), $size);
  }

  @else {
    font-size: map-get(map-get($fontSize, title), $size);
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh;
  }
}

@mixin borderRadius($size) {
  border-radius: map-get($borderRadius, $size) or 10px;
}

@mixin ellipsis($line: 1) {
  @if $line==1 {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @else {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
  }
}

@mixin center {
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin flex($align: center, $justify: null, $direction: null, $wrap: null, $gap: null) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $wrap;
  gap: $gap;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin bgImg($url: null, $x:center, $y:center, $size: contain) {
  @if $url==null {
    background: {
      repeat: no-repeat;
      position: $x $y;
      size: $size;
    }
  }

  @else {
    background: url($url) no-repeat $x $y;
    background-size: $size;
  }
}

@mixin respond-to ($breakpoint) {
  @if $breakpoint=="medium" {
    @media (max-width: 768px) {
      @content;
    }
  }

  @else if $breakpoint=="large" {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @else if $breakpoint=="huge" {
    @media (max-width: 1200px) {
      @content;
    }
  }
}

@mixin responsiveHugeBox {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

@mixin mintify($color: 0) {
  @if $color==0 {
    /* #000 기준 */
    filter: invert(46%) sepia(70%) saturate(4498%) hue-rotate(152deg) brightness(98%) contrast(97%);
  }

  @else if $color==1 {
    /* #111 기준 */
    filter: invert(46%) sepia(70%) saturate(4498%) hue-rotate(152deg) brightness(98%) contrast(94%);
  }

  @else if $color==3 {
    /* #333 기준 */
    filter: invert(46%) sepia(70%) saturate(4498%) hue-rotate(152deg) brightness(96%) contrast(97%);
  }

  @else if $color==6 {
    /* #666 기준 */
    filter: invert(46%) sepia(70%) saturate(4498%) hue-rotate(151deg) brightness(95%) contrast(97%);
  }

  @else if $color==8 {
    /* #888 기준 */
    filter: invert(46%) sepia(70%) saturate(4498%) hue-rotate(150deg) brightness(95%) contrast(97%);
  }
}

@mixin darken($clr, $num: 5%) {
  background-color: darken($color: $clr, $amount: $num);
}
