@charset "utf-8";

/*----------------------------------------*\
  || [공통] Float
\*----------------------------------------*/

.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

.cl {
  clear: both !important;
}

.fn {
  float: none !important;
}

.clearfix {
  @include clearfix;
}

/*----------------------------------------*\
  || [공통] Display
\*----------------------------------------*/

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

/*----------------------------------------*\
  || [공통] Gap
\*----------------------------------------*/

.unit-block {
  &-xs {
    margin-bottom: 10px !important;
  }

  &-s {
    margin-bottom: 20px !important;
  }

  & {
    margin-bottom: 30px !important;
  }

  &-m {
    margin-bottom: 40px !important;
  }

  &-l {
    margin-bottom: 50px !important;
  }

  &-xl {
    margin-bottom: 60px !important;
  }
}

[class*=gap-list] {
  @include flex($wrap: wrap);
}

.gap-list- {
  &xs {
    gap: 5px;
  }

  &s {
    gap: 10px;
  }

  &sm {
    gap: 15px;
  }

  &m {
    gap: 20px;
  }

  &ml {
    gap: 25px;
  }

  &l {
    gap: 30px;
  }

  &xl {
    gap: 40px;
  }
}

@for $i from 1 through 10 {
  .gap#{$i * 5} {
    gap: $i * 5px !important;
  }
}

/*----------------------------------------*\
  || [공통] Show/Hide
\*----------------------------------------*/

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}

.view-pc,
.pc-view {
  display: block !important;
}

.view-pc-inline {
  display: inline !important;
}

.view-pc-i-block {
  display: inline-block !important;
}

.view-pc-flex {
  display: flex !important;
}

.view-mobile-flex,
.view-mobile-inline,
.view-mobile-i-block,
.view-mobile,
.mobile-view {
  display: none !important;
}

html.responsive {
  .pc-viewbox {
    display: block !important;
  }

  .tablet-viewbox,
  .mobile-viewbox {
    display: none !important;
  }
}

@include respond-to ("huge") {
  html.responsive {
    .tablet-viewbox {
      display: block !important;
    }

    .pc-viewbox,
    .mobile-viewbox {
      display: none !important;
    }
  }
}

@include respond-to ("medium") {
  .view-pc-flex,
  .view-pc-inline,
  .view-pc-i-block,
  .view-pc,
  .pc-view {
    display: none !important;
  }

  .view-mobile,
  .mobile-view {
    display: block !important;
  }

  .view-mobile-inline {
    display: inline !important;
  }

  .view-mobile-i-block {
    display: inline-block !important;
  }

  .view-mobile-flex {
    display: flex !important;
  }

  html.responsive {
    .mobile-viewbox {
      display: block !important;
    }

    .pc-viewbox,
    .tablet-viewbox {
      display: none !important;
    }
  }
}

/*----------------------------------------*\
  || [공통] IR / TTS
\*----------------------------------------*/

.sr-only {
  @include blind;

  &-focusable {
    &:active,
    &:focus {
      @include size(auto);
      position: static;
      overflow: visible;
      clip: auto;
      white-space: normal;
    }
  }
}

caption,
.tts,
.for-a11y,
.component-layer-close-button-text {
  @include blind;
}

/*----------------------------------------*\
  || [공통] Vertical
\*----------------------------------------*/

.vat {
  vertical-align: top !important;
}

.vam {
  vertical-align: middle !important;
}

.vab {
  vertical-align: bottom !important;
}

/*----------------------------------------*\
  || [공통] Border
\*----------------------------------------*/

.bt-none {
  border-top: none !important;
}

.bl-none {
  border-left: none !important;
}

.br-none {
  border-right: none !important;
}

.bbnone,
.bb-none,
.borderbot-n {
  border-bottom: none !important;
}

.bd-none {
  border: 0 !important;
}

.bt-ccc {
  border-top: 1px solid var(--disable-clr) !important;
}

.bl-ccc {
  border-left: 1px solid var(--disable-clr) !important;
}

.th-brline,
.br-line,
.br-ccc {
  border-right: 1px solid var(--disable-clr) !important;
}

.bb-ccc {
  border-bottom: 1px solid var(--disable-clr) !important;
}

.bd-ccc {
  border: 1px solid var(--disable-clr) !important;
}

.br-ef,
.be-efef,
.brc-tdline,
.br-style01 {
  border-right: 1px solid #efefef !important;
}

.bt-333 {
  border-top: 1px solid var(--body-clr);
}

/*----------------------------------------*\
  || [공통] HR / Line
\*----------------------------------------*/

.hr-line01 {
  width: 100%;
  height: 1px;
  margin: 14px 0;
  background-color: var(--line-clr3);
  border: 0;
}

/*----------------------------------------*\
  || [공통] Position
\*----------------------------------------*/

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed !important;
}

.static {
  position: static !important;
}

.left52 {
  left: 52% !important;
}

/*----------------------------------------*\
  || [공통] Scroll / Cursor / Etc
\*----------------------------------------*/

.over-hidden {
  overflow-x: hidden !important;
  overflow-y: auto;
}

.scroll {
  overflow: auto !important;
}

.clickable {
  cursor: pointer;
}

.deg180 {
  transform: rotate(180deg);
}

/*----------------------------------------*\
  || [공통] Flex
\*----------------------------------------*/

.flex {
  & {
    @include flex($justify: flex-start);
  }

  &2 {
    @include flex($justify: space-between);
  }

  &3 {
    @include flex($justify: center);
  }

  &4 {
    @include flex($justify: flex-end);
  }

  &-row {
    flex-direction: row !important;
  }

  &-col {
    flex-direction: column !important;
  }

  &-wrap {
    flex-wrap: wrap !important;
  }

  &-nowrap {
    flex-wrap: nowrap !important;
  }

  &-as {
    align-items: flex-start !important;
  }

  &-ae {
    align-items: flex-end !important;
  }

  &-ac {
    align-items: center !important;
  }

  &-ast {
    align-items: stretch !important;
  }

  &-jc {
    justify-content: center;
  }

  &-jsb {
    justify-content: space-between;
  }

  &-jfs {
    justify-content: flex-start;
  }

  &-jfe {
    justify-content: flex-end;
  }
}

/*----------------------------------------*\
  || [공통] Width / Height
\*----------------------------------------*/

.w-auto {
  width: auto !important;
}

.mnw-fit {
  min-width: fit-content !important;
}

.mnw-auto,
.wn-auto {
  min-width: auto !important;
}

.mxw-auto,
.wx-auto {
  max-width: auto !important;
}

.mxw-inh,
.maxw-auto {
  max-width: inherit !important;
}

.maxh-none {
  max-height: none !important;
}

@for $i from 1 through 20 {
  .w#{$i * 5} {
    width: $i * 5% !important;
  }
}

/*----------------------------------------*\
  || [공통] Margin
\*----------------------------------------*/

.m-auto {
  margin: auto !important;
}

.m-top {
  margin-top: auto !important;
}

.m-left {
  margin-left: auto !important;
}

.m-bottom {
  margin-bottom: auto !important;
}

.m-right {
  margin-right: auto !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mb4 {
  margin-bottom: 4px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.ml2 {
  margin-left: 2px;
}

.mr4 {
  margin-right: 4px;
}

.mr11 {
  margin-right: 11px;
}

.margin-b {
  margin-bottom: 20px;
}

.mln30 {
  margin-left: -30px
}

@for $i from 0 through 10 {
  .mg#{$i * 5} {
    margin: $i * 5px !important;
  }
}

@for $i from 0 through 20 {
  .mt#{$i * 5} {
    margin-top: $i * 5px !important;
  }
}

@for $i from 0 through 20 {
  .ml#{$i * 5} {
    margin-left: $i * 5px !important;
  }
}

@for $i from 0 through 20 {
  .mb#{$i * 5} {
    margin-bottom: $i * 5px !important;
  }
}

@for $i from 0 through 20 {
  .mr#{$i * 5} {
    margin-right: $i * 5px !important;
  }
}

/*----------------------------------------*\
  || [공통] Padding
\*----------------------------------------*/

.btm-pad {
  padding-bottom: 60px;
}

.pt13 {
    padding-top: 13px;
}

@for $i from 0 through 20 {
  .pd#{$i * 5} {
    padding: $i * 5px !important;
  }
}

@for $i from 0 through 20 {
  .pt#{$i * 5} {
    padding-top: $i * 5px !important;
  }
}

@for $i from 0 through 20 {
  .pl#{$i * 5} {
    padding-left: $i * 5px !important;
  }
}

@for $i from 0 through 20 {
  .pb#{$i * 5} {
    padding-bottom: $i * 5px !important;
  }
}

@for $i from 0 through 20 {
  .pr#{$i * 5} {
    padding-right: $i * 5px !important;
  }
}

/*----------------------------------------*\
  || [공통] Opacity
\*----------------------------------------*/

@for $i from 0 through 10 {
  .op#{$i} {
    opacity: calc($i/10) !important;
  }
}

.opa0 {
  opacity: 0 !important;
}

.opa1 {
  opacity: 1 !important;
}
