@charset "utf-8";

/*----------------------------------------*\
  || 화살표 Icon 인터랙션
\*----------------------------------------*/

.moving-arrow {
  @include flex($gap: 4px, $justify: center);
  margin: auto 12px;

  span {
    @include size(5px, 10px);
    background: url(/img/common/icon/icon_arrow_mint.png) no-repeat center / contain;
    opacity: 0;
    animation: movingArrow 1.5s linear infinite;
  }

  .moving-arrow01 {
    opacity: .5;
  }

  .moving-arrow02 {
    animation-delay: .5s;
  }

  .moving-arrow03 {
    animation-delay: 1s;
  }
}

@keyframes movingArrow {
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/*----------------------------------------*\
  || Icon Area
\*----------------------------------------*/

.icon-area {
  text-align: right;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  &.bottom {
    padding-top: 20px;

    button {
      margin-left: 20px;
    }
  }

  li {
    float: left;

    &::before {
      content: '';
      display: block;
      float: left;
      width: 1px;
      height: 18px;
      background-color: #ccc;
      margin: 4px 10px 0;
    }

    &:first-child::before {
      display: none;
    }
  }
}

.icon-area.retouch {
  float: right;
  margin-bottom: -10px;

  &::before,
  &:has(.btn-sm-wh) li::before {
    display: none;
  }

  &:last-child {
    margin-right: 0;
  }

  li {
    margin-right: 10px;
  }

  .btn-sm-wh.resize {
    min-width: 120px;
  }
}

.icon-area02 {
  float: right;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  li {
    float: left;

    .btn-list,
    .btn-calender {
      position: relative;
      width: 30px;
      height: 30px;
      background: var(--line-clr);

      &.on {
        border: 2px solid var(--main-clr);

        &::after {
            filter: invert(46%) sepia(70%) saturate(4498%) hue-rotate(152deg) brightness(98%) contrast(97%);
        }
      }
    }
  }
}

@include respond-to ("huge") {
  .icon-area .btn-sm-wh {
    margin-bottom: 7px;
  }
}

/*----------------------------------------*\
  || 아이콘 세팅
\*----------------------------------------*/

.ico {
  position: relative;
  font-size: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    background: url('/img/icn_ir.png') no-repeat 0 0;
    transform: translateY(-50%);
  }
}

.ico-bi {
  @include size(40px);
  position: relative;
  display: inline-flex;
  align-items: center;
  aspect-ratio: 1;

  &::before {
    @include size(100%);
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
    background-repeat: no-repeat;
  }

  > span {
    font-size: 0;
    padding-left: 42px;
  }

  &.has-txt {
    max-width: max-content !important;

    > span {
      font-size: 17px;
    }
  }

  &.size-xs {
    @include size(18px);

    > span {
      padding-left: 33px !important;
      font-size: 16px;
    }
  }

  &.size-s {
    @include size(25px);

    > span {
      padding-left: 33px !important;
      font-size: 16px;
    }
  }

  &.size-m {
    @include size(35px);
  }
}

.ico-star {
  position: relative;

  &::after {
    content: '대표계좌';
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 24px;
    height: 24px;
    background: url('/img/icn_ir.png') no-repeat -119px 0;
    background-size: 200px;
    font-size: 0;
  }
}

.ico-star-r {
  position: relative;
  line-height: 24px;
  height: 24px;

  &::after {
    content: '대표계좌';
    display: inline-block;
    /* position: absolute;
    top: -4px;
    right: -23px; */
    width: 24px;
    height: 24px;
    background: url('/img/icn_ir.png') no-repeat -119px 0;
    background-size: 200px;
    font-size: 0;
    margin-top: -2px;
    vertical-align: top;
  }
}

.ico.ico-add::before,
.ico.ico-add-txt::before {
  @include size(16px);
  background-position: -26px 0;
  background-size: 200px;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}

.ico.has-txt {
  font-size: 16px;
  padding-left: 25px;
}

/*----------------------------------------*\
  || Icon List
\*----------------------------------------*/

.icon-list-box {
  width: 100%;

  > li {
    height: auto;
    min-height: 393px;
    padding: 40px 5px 40px 34px;
    width: 25%;
    border-right: 1px solid #efefef;
    float: left;
    text-align: center;

    &:last-child {
      border-right: none;
    }
  }
}

.icon-list-txt {
  font-weight: $medium;
  font-size: 16px;
  padding: 20px 0;
}

.icon-list01,
.icon-list02,
.icon-list03,
.icon-list04 {
  width: 107px;
  height: 97px;
  background: url(/img/common/icon_list01.png);
}

.icon-list02 {
  background: url(/img/common/icon_list02.png)
}

.icon-list03 {
  background: url(/img/common/icon_list03.png)
}

.icon-list04 {
  background: url(/img/common/icon_list04.png)
}

.icon-otpex {
  width: 540px;
  height: 168px;
  position: relative;
  top: 0;
  left: 0;
  background: url(/img/common/MRT-700nP_otplay.gif) no-repeat center center;
  margin: 10px 0 10px 10px;
}

.icon-otpbg {
  position: absolute;
  top: 0;
  left: 10px;
  background: url(/img/common/icon_otpbgimg.png);
  width: 100%;
  height: 100%;
}

/*----------------------------------------*\
  || Icon
\*----------------------------------------*/

.icon-kdic {
  vertical-align: middle;
  display: inline-block;
  width: 53px;
  height: 34px;
  background: url(/img/common/icon_kdiclogo.png) no-repeat center center;
  margin-right: 8px;
}

.icon-btr {
  width: 40px;
  height: 16px;
  background: url(/img/common/icon_btr.png) no-repeat center center / 28px;
  display: inline-block;
  vertical-align: inherit;
}

.icon-lobat {
  width: 224px;
  height: 132px;
  background: url(/img/common/MRT-700nP_low.gif) no-repeat 50% 50%;
  display: inline-block;
  vertical-align: inherit;
}

.icon-logout {
  height: 60px;
  background: url('/img/common/logout_icon.png') no-repeat 50% 50%;
}

.icon-timer {
  height: 60px;
  background: url('/img/common/popup_timer_icon.png') no-repeat 50% 50%;
}

.icon-error {
  height: 60px;
  background: url(/img/common/icon/icon_error.png) no-repeat center / contain;
  filter: hue-rotate(183deg);
}

.icon-question {
  width: 16px;
  height: 14px;
  background: url('/img/common/icon_question.png') no-repeat center center;
  margin-left: 20px;
}

.icon-prev,
.icon-next {
  width: 16px;
  height: 10px;
  background: url(/img/common/arrow_icon_black.png) no-repeat 50% 50%;
  transform: rotate(270deg);
  margin-right: 4px;
}

.icon-next {
  transform: rotate(90deg);
}

.txt-question {
  vertical-align: bottom;
  margin-right: 10px;
  width: 29px;
  height: 28px;
  background: url('/img/common/icon_question02.png') no-repeat center center;
}

.arrow-icon {
  width: 6px;
  height: 13px;
  background: url("/img/common/arrow_icon_ss.png") no-repeat center;
  background-size: cover;
  display: inline-block;
}

.timer {
  padding: 13px 25px;
  background: url("/img/common/timer_icon.png") no-repeat left center / 16px;
  display: inline-block;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive .icon-lobat {
    width: 100%;
    height: auto;
    padding-top: 58.92%;
    background-size: 100%;
  }

  .icon-list-box > li {
    float: none;
    width: 100%;
    min-height: auto;
    padding: 30px 25px;
    border-top: 1px solid #efefef;
    border-right: 0;

    &:first-child {
      border-top: 0;
    }
  }

  .icon-otpex {
    width: 100%;
    height: auto;
    padding-top: 31.11%;
    background-size: auto 100%;
  }

  .icon-otpbg {
    background-size: 100%;
  }
}

/*----------------------------------------*\
  || 저축은행 아이콘
\*----------------------------------------*/

/* SB저축은행 */
.ico-bi.b050::before {
  background-image: url('/img/logo_thumb/bank/050.png');
}

/* 고려 */
.ico-bi.sb101::before {
  background-image: url('/img/logo_thumb/bank/101.png');
}

/* 금화 */
.ico-bi.sb153::before {
  background-image: url('/img/logo_thumb/bank/153.png');
}

/* 대명 */
.ico-bi.sb401::before {
  background-image: url('/img/logo_thumb/bank/401.png');
}

/* 대신 */
.ico-bi.sb063::before {
  background-image: url('/img/logo_thumb/bank/063.png');
}

/* 대원 */
.ico-bi.sb617::before {
  background-image: url('/img/logo_thumb/bank/617.png');
}

/* 더블 */
.ico-bi.sb557::before {
  background-image: url('/img/logo_thumb/bank/557.png');
}

/* 동양 */
.ico-bi.sb556::before {
  background-image: url('/img/logo_thumb/bank/556.png');
}

/* 드림 */
.ico-bi.sb605::before {
  background-image: url('/img/logo_thumb/bank/605.png');
}

/* 머스트삼일 */
.ico-bi.sb610::before {
  background-image: url('/img/logo_thumb/bank/610.png');
}

/* 민국 */
.ico-bi.sb012::before {
  background-image: url('/img/logo_thumb/bank/012.png');
}

/* 부림 */
.ico-bi.sb310::before {
  background-image: url('/img/logo_thumb/bank/310.png');
}

/* 삼호 */
.ico-bi.sb510::before {
  background-image: url('/img/logo_thumb/bank/510.png');
}

/* 상상인플러스 */
.ico-bi.sb459::before {
  background-image: url('/img/logo_thumb/bank/459.png');
}

/* 센트럴 */
.ico-bi.sb565::before {
  background-image: url('/img/logo_thumb/bank/565.png');
}

/* 스마트 */
.ico-bi.sb566::before {
  background-image: url('/img/logo_thumb/bank/566.png');
}

/* 스타 */
.ico-bi.sb506::before {
  background-image: url('/img/logo_thumb/bank/506.png');
}

/* 아산 */
.ico-bi.sb460::before {
  background-image: url('/img/logo_thumb/bank/460.png');
}

/* 안국 */
.ico-bi.sb304::before {
  background-image: url('/img/logo_thumb/bank/304.png');
}

/* 애큐온 */
.ico-bi.sb002::before {
  background-image: url('/img/logo_thumb/bank/002.png');
}

/* 엠에스 */
.ico-bi.sb209::before {
  background-image: url('/img/logo_thumb/bank/209.png');
}

/* 예가람 */
.ico-bi.sb049::before {
  background-image: url('/img/logo_thumb/bank/049.png');
}

/* 오투 */
.ico-bi.sb461::before {
  background-image: url('/img/logo_thumb/bank/461.png');
}

/* 웰컴 */
.ico-bi.sb066::before {
  background-image: url('/img/logo_thumb/bank/066.png');
}

/* 유안타 */
.ico-bi.sb036::before {
  background-image: url('/img/logo_thumb/bank/036.png');
}

/* 융창 */
.ico-bi.sb324::before {
  background-image: url('/img/logo_thumb/bank/324.png');
}

/* 인천 */
.ico-bi.sb158::before {
  background-image: url('/img/logo_thumb/bank/158.png');
}

/* 조흥 */
.ico-bi.sb657::before {
  background-image: url('/img/logo_thumb/bank/657.png');
}

/* 참 */
.ico-bi.sb614::before {
  background-image: url('/img/logo_thumb/bank/614.png');
}

/* 키움 */
.ico-bi.sb313::before {
  background-image: url('/img/logo_thumb/bank/313.png');
}

/* 페퍼 */
.ico-bi.sb328::before {
  background-image: url('/img/logo_thumb/bank/328.png');
}

/* 푸른 */
.ico-bi.sb014::before {
  background-image: url('/img/logo_thumb/bank/014.png');
}

/* 한국투자 */
.ico-bi.sb551::before {
  background-image: url('/img/logo_thumb/bank/551.png');
}

/* 한화 */
.ico-bi.sb334::before {
  background-image: url('/img/logo_thumb/bank/334.png');
}

/* BNK */
.ico-bi.sb130::before {
  background-image: url('/img/logo_thumb/bank/130.png');
}

/* DB */
.ico-bi.sb007::before {
  background-image: url('/img/logo_thumb/bank/007.png');
}

/* ES */
.ico-bi.sb016::before {
  background-image: url('/img/logo_thumb/bank/016.png');
}

/* JT */
.ico-bi.sb332::before {
  background-image: url('/img/logo_thumb/bank/332.png');
}

/* KB */
.ico-bi.sb065::before {
  background-image: url('/img/logo_thumb/bank/065.png');
}

/* OK */
.ico-bi.sb064::before {
  background-image: url('/img/logo_thumb/bank/064.png');
}

/* SBI */
.ico-bi.sb028::before {
  background-image: url('/img/logo_thumb/bank/028.png');
}

/* 국제 */
.ico-bi.sb102::before {
  background-image: url('/img/logo_thumb/bank/102.png');
}

/* 남양 */
.ico-bi.sb305::before {
  background-image: url('/img/logo_thumb/bank/305.png');
}

/* 대백 */
.ico-bi.sb207::before {
  background-image: url('/img/logo_thumb/bank/207.png');
}

/* 대아 */
.ico-bi.sb607::before {
  background-image: url('/img/logo_thumb/bank/607.png');
}

/* 대한 */
.ico-bi.sb554::before {
  background-image: url('/img/logo_thumb/bank/554.png');
}

/* 더케이 */
.ico-bi.sb021::before {
  background-image: url('/img/logo_thumb/bank/021.png');
}

/* 동원제일 */
.ico-bi.sb656::before {
  background-image: url('/img/logo_thumb/bank/656.png');
}

/* 라온 */
.ico-bi.sb604::before {
  background-image: url('/img/logo_thumb/bank/604.png');
}

/* 모아 */
.ico-bi.sb162::before {
  background-image: url('/img/logo_thumb/bank/162.png');
}

/* 바로 */
.ico-bi.sb038::before {
  background-image: url('/img/logo_thumb/bank/038.png');
}

/* 삼정 */
.ico-bi.sb314::before {
  background-image: url('/img/logo_thumb/bank/314.png');
}

/* 상상인 */
.ico-bi.sb330::before {
  background-image: url('/img/logo_thumb/bank/330.png');
}

/* 세람 */
.ico-bi.sb325::before {
  background-image: url('/img/logo_thumb/bank/325.png');
}

/* 솔브레인 */
.ico-bi.sb654::before {
  background-image: url('/img/logo_thumb/bank/654.png');
}

/* 스카이 */
.ico-bi.sb008::before {
  background-image: url('/img/logo_thumb/bank/008.png');
}

/* 신한 */
.ico-bi.sb347::before {
  background-image: url('/img/logo_thumb/bank/347.png');
}

/* 아주 */
.ico-bi.sb403::before {
  background-image: url('/img/logo_thumb/bank/403.png');
}

/* 안양 */
.ico-bi.sb320::before {
  background-image: url('/img/logo_thumb/bank/320.png');
}

/* 에스앤티 */
.ico-bi.sb651::before {
  background-image: url('/img/logo_thumb/bank/651.png');
}

/* 영진 */
.ico-bi.sb321::before {
  background-image: url('/img/logo_thumb/bank/321.png');
}

/* 오성 */
.ico-bi.sb616::before {
  background-image: url('/img/logo_thumb/bank/616.png');
}

/* 우리 */
.ico-bi.sb120::before {
  background-image: url('/img/logo_thumb/bank/120.png');
}

/* 유니온 */
.ico-bi.sb208::before {
  background-image: url('/img/logo_thumb/bank/208.png');
}

/* 다올 */
.ico-bi.sb048::before {
  background-image: url('/img/logo_thumb/bank/048.png');
}

/* 인성 */
.ico-bi.sb151::before {
  background-image: url('/img/logo_thumb/bank/151.png');
}

/* 좋은 */
.ico-bi.sb024::before {
  background-image: url('/img/logo_thumb/bank/024.png');
}

/* 진주 */
.ico-bi.sb658::before {
  background-image: url('/img/logo_thumb/bank/658.png');
}

/* 청주 */
.ico-bi.sb405::before {
  background-image: url('/img/logo_thumb/bank/405.png');
}

/* 키움예스 */
.ico-bi.sb017::before {
  background-image: url('/img/logo_thumb/bank/017.png');
}

/* 평택 */
.ico-bi.sb317::before {
  background-image: url('/img/logo_thumb/bank/317.png');
}

/* 하나 */
.ico-bi.sb067::before {
  background-image: url('/img/logo_thumb/bank/067.png');
}

/* 한성 */
.ico-bi.sb408::before {
  background-image: url('/img/logo_thumb/bank/408.png');
}

/* 흥국 */
.ico-bi.sb127::before {
  background-image: url('/img/logo_thumb/bank/127.png');
}

/* CK */
.ico-bi.sb351::before {
  background-image: url('/img/logo_thumb/bank/351.png');
}

/* DH */
.ico-bi.sb115::before {
  background-image: url('/img/logo_thumb/bank/115.png');
}

/* IBK */
.ico-bi.sb650::before {
  background-image: url('/img/logo_thumb/bank/650.png');
}

/* JT친애 */
.ico-bi.sb068::before {
  background-image: url('/img/logo_thumb/bank/068.png');
}

/* NH */
.ico-bi.sb062::before {
  background-image: url('/img/logo_thumb/bank/062.png');
}

/* OSB */
.ico-bi.sb003::before {
  background-image: url('/img/logo_thumb/bank/003.png');
}

/*----------------------------------------*\
  || 다른 은행 아이콘
\*----------------------------------------*/

/* 기업은행 */
.ico-bi.b003::before {
  background-image: url('/img/logo_thumb/other_bank/003.png');
}

/* 우리은행 */
.ico-bi.b020::before {
  background-image: url('/img/logo_thumb/other_bank/020.png');
}

/* 하나은행 */
.ico-bi.b081::before {
  background-image: url('/img/logo_thumb/other_bank/081.png');
}

/* 한국씨티은행 */
.ico-bi.b027::before {
  background-image: url('/img/logo_thumb/other_bank/027.png');
}

/* 경남은행 */
.ico-bi.b039::before {
  background-image: url('/img/logo_thumb/other_bank/039.png');
}

/* 대구은행 */
.ico-bi.b031::before {
  background-image: url('/img/logo_thumb/other_bank/031.png');
}

/* 산림조합 */
.ico-bi.b064::before {
  background-image: url('/img/logo_thumb/other_bank/064.png');
}

/* 새마을금고 */
.ico-bi.b045::before {
  background-image: url('/img/logo_thumb/other_bank/045.png');
}

/* 신협 */
.ico-bi.b048::before {
  background-image: url('/img/logo_thumb/other_bank/048.png');
}

/* 제주은행 */
.ico-bi.b035::before {
  background-image: url('/img/logo_thumb/other_bank/035.png');
}

/* 케이뱅크 */
.ico-bi.b089::before {
  background-image: url('/img/logo_thumb/other_bank/089.png');
}

/* 국민은행 */
.ico-bi.b004::before {
  background-image: url('/img/logo_thumb/other_bank/004.png');
}

/* 신한은행 */
.ico-bi.b088::before {
  background-image: url('/img/logo_thumb/other_bank/088.png');
}

/* 농협은행 */
.ico-bi.b011::before,
.ico-bi.b012::before {
  background-image: url('/img/logo_thumb/other_bank/011.png');
}

/* SC은행 */
.ico-bi.b023::before {
  background-image: url('/img/logo_thumb/other_bank/023.png');
}

/* 우체국 */
.ico-bi.b071::before {
  background-image: url('/img/logo_thumb/other_bank/071.png');
}

/* 광주은행 */
.ico-bi.b034::before {
  background-image: url('/img/logo_thumb/other_bank/034.png');
}

/* 부산은행 */
.ico-bi.b032::before {
  background-image: url('/img/logo_thumb/other_bank/032.png');
}

/* 산업은행 */
.ico-bi.b002::before {
  background-image: url('/img/logo_thumb/other_bank/002.png');
}

/* 수협 */
.ico-bi.b007::before {
  background-image: url('/img/logo_thumb/other_bank/007.png');
}

/* 전북은행 */
.ico-bi.b037::before {
  background-image: url('/img/logo_thumb/other_bank/037.png');
}

/* 카카오뱅크 */
.ico-bi.b090::before {
  background-image: url('/img/logo_thumb/other_bank/090.png');
}

/* 도이치 */
.ico-bi.b055::before {
  background-image: url('/img/logo_thumb/other_bank/055.png');
}

/* BNPP */
.ico-bi.b061::before {
  background-image: url('/img/logo_thumb/other_bank/061.png');
}

/* 중국건설 */
.ico-bi.b067::before {
  background-image: url('/img/logo_thumb/other_bank/067.png');
}

/* 수출입 */
.ico-bi.b008::before {
  background-image: url('/img/logo_thumb/other_bank/008.png');
}

/* 중국공상 */
.ico-bi.b062::before {
  background-image: url('/img/logo_thumb/other_bank/062.png');
}

/* BOA */
.ico-bi.b060::before {
  background-image: url('/img/logo_thumb/other_bank/060.png');
}

/* HSBC */
.ico-bi.b054::before {
  background-image: url('/img/logo_thumb/other_bank/054.png');
}

/* JP모간 */
.ico-bi.b057::before {
  background-image: url('/img/logo_thumb/other_bank/057.png');
}

/* 중국은행 */
.ico-bi.b063::before {
  background-image: url('/img/logo_thumb/other_bank/063.png');
}

/* 토스은행 */
.ico-bi.b092::before {
  background-image: url('/img/logo_thumb/other_bank/092.png');
}

/* 국세, 국고금, 지방세 */
.ico-bi.b000::before {
  background-image: url('/img/logo_thumb/other_bank/000.png');
}

/*----------------------------------------*\
  || 증권사 아이콘
\*----------------------------------------*/

/* 한국포스증권 */
.ico-bi.b294::before {
  background-image: url('/img/logo_thumb/stock/294.png');
}

/* 교보증권 */
.ico-bi.b261::before {
  background-image: url('/img/logo_thumb/stock/261.png');
}

/* 메리츠증권 */
.ico-bi.b287::before {
  background-image: url('/img/logo_thumb/stock/287.png');
}

/* 부국증권 */
.ico-bi.b04::before {
  background-image: url('/img/logo_thumb/stock/04.png');
}

/* 신영증권 */
.ico-bi.b05::before {
  background-image: url('/img/logo_thumb/stock/05.png');
}

/* 유안타증권 */
.ico-bi.b06::before {
  background-image: url('/img/logo_thumb/stock/06.png');
}

/* LS증권 */
.ico-bi.b265::before {
  background-image: url('/img/logo_thumb/stock/265.png');
}

/* 키움증권 */
.ico-bi.b264::before {
  background-image: url('/img/logo_thumb/stock/264.png');
}

/* 하이투자증권 */
.ico-bi.b262::before {
  background-image: url('/img/logo_thumb/stock/262.png');
}

/* 한화증권 */
.ico-bi.b269::before {
  background-image: url('/img/logo_thumb/stock/269.png');
}

/* DB증권 */
.ico-bi.b279::before {
  background-image: url('/img/logo_thumb/stock/279.png');
}

/* 다올투자증권 */
.ico-bi.b12::before {
  background-image: url('/img/logo_thumb/stock/12.png');
}

/* BNK투자증권 */
.ico-bi.b13::before {
  background-image: url('/img/logo_thumb/stock/13.png');
}

/* NH투자증권 */
.ico-bi.b247::before {
  background-image: url('/img/logo_thumb/stock/247.png');
}

/* 대신증권 */
.ico-bi.b267::before {
  background-image: url('/img/logo_thumb/stock/267.png');
}

/* 미래에셋대우 */
.ico-bi.b238::before {
  background-image: url('/img/logo_thumb/stock/238.png');
}

/* 삼성증권 */
.ico-bi.b240::before {
  background-image: url('/img/logo_thumb/stock/240.png');
}

/* 신한금융투자 */
.ico-bi.b278::before {
  background-image: url('/img/logo_thumb/stock/278.png');
}

/* 유진투자증권 */
.ico-bi.b280::before {
  background-image: url('/img/logo_thumb/stock/280.png');
}

/* 케이프투자증권 */
.ico-bi.b20::before {
  background-image: url('/img/logo_thumb/stock/20.png');
}

/* 하나금융투자 */
.ico-bi.b270::before {
  background-image: url('/img/logo_thumb/stock/270.png');
}

/* 한국투자증권 */
.ico-bi.b243::before {
  background-image: url('/img/logo_thumb/stock/243.png');
}

/* 현대차증권 */
.ico-bi.b263::before {
  background-image: url('/img/logo_thumb/stock/263.png');
}

/* KB증권 */
.ico-bi.b218::before {
  background-image: url('/img/logo_thumb/stock/218.png');
}

/* SK증권 */
.ico-bi.b266::before {
  background-image: url('/img/logo_thumb/stock/266.png');
}

/* 카카오페이증권 */
.ico-bi.b26::before {
  background-image: url('/img/logo_thumb/stock/26.png');
}

/* 카카오페이증권 */
.ico-bi.b288::before {
  background-image: url('/img/logo_thumb/stock/288.png');
}

/* 토스증권 */
.ico-bi.b271::before {
  background-image: url('/img/logo_thumb/stock/271.png');
}

/* 케이프투자증권 */
.ico-bi.b292::before {
  background-image: url('/img/logo_thumb/stock/292.png');
}

/* 유안타증권 */
.ico-bi.b209::before {
  background-image: url('/img/logo_thumb/stock/209.png');
}

/* 신영증권 */
.ico-bi.b291::before {
  background-image: url('/img/logo_thumb/stock/291.png');
}

/* 부국증권 */
.ico-bi.b290::before {
  background-image: url('/img/logo_thumb/stock/290.png');
}

/* 다올증권 */
.ico-bi.b227::before {
  background-image: url('/img/logo_thumb/stock/227.png');
}

/* BNK증권 */
.ico-bi.b224::before {
  background-image: url('/img/logo_thumb/stock/224.png');
}

/* IBK증권 */
.ico-bi.b225::before {
  background-image: url('/img/logo_thumb/stock/225.png');
}

/* 상상인증권 */
.ico-bi.b221::before {
  background-image: url('/img/logo_thumb/stock/221.png');
}

/*----------------------------------------*\
  || 카드사 아이콘
\*----------------------------------------*/

/* 오픈기관 */
.ico-bi.b399::before {
  background-image: url('../img/logo_thumb/card/399399.png');
}

/* 우리카드 */
.ico-bi.b041::before {
  background-image: url('../img/logo_thumb/card/041041.png');
}

/* 광주카드 */
.ico-bi.b364::before {
  background-image: url('../img/logo_thumb/card/034034.png');
}

/* 삼성카드 */
.ico-bi.b365::before {
  background-image: url('../img/logo_thumb/card/365365.png');
}

/* 신한카드 */
.ico-bi.b366::before {
  background-image: url('../img/logo_thumb/card/366366.png');
}

/* 현대카드 */
.ico-bi.b367::before {
  background-image: url('../img/logo_thumb/card/367367.png');
}

/* 롯데카드 */
.ico-bi.b368::before {
  background-image: url('../img/logo_thumb/card/368368.png');
}

/* 수협은행 */
.ico-bi.b369::before {
  background-image: url('../img/logo_thumb/card/007007.png');
}

/* 씨티카드 */
.ico-bi.b370::before {
  background-image: url('../img/logo_thumb/card/027027.png');
}

/* NH농협은행 */
.ico-bi.b371::before {
  background-image: url('../img/logo_thumb/card/011011.png');
}

/* 전북카드 */
.ico-bi.b0372::before {
  background-image: url('../img/logo_thumb/card/037037.png');
}

/* 제주카드 */
.ico-bi.b0373::before {
  background-image: url('../img/logo_thumb/card/035035.png');
}

/* 하나카드 */
.ico-bi.b374::before {
  background-image: url('../img/logo_thumb/card/374374.png');
}

/* KB국민카드 */
.ico-bi.b381::before {
  background-image: url('../img/logo_thumb/card/381381.png');
}

/* 산업은행 */
.ico-bi.b902::before {
  background-image: url('../img/logo_thumb/card/002002.png');
}

/* 기업은행(BC카드) */
.ico-bi.b903::before {
  background-image: url('../img/logo_thumb/card/003003.png');
}

/* 신협카드 */
.ico-bi.b948::before {
  background-image: url('../img/logo_thumb/card/948.png');
}

/* 저축은행카드 */
.ico-bi.b950::before {
  background-image: url('../img/logo_thumb/card/950.png');
}

/* 우체국카드 */
.ico-bi.b971::before {
  background-image: url('../img/logo_thumb/card/971.png');
}

/* 케이뱅크카드 */
.ico-bi.b989::before {
  background-image: url('../img/logo_thumb/card/089089.png');
}

/* 카카오뱅크 */
.ico-bi.b990::before {
  background-image: url('../img/logo_thumb/card/381090.png');
}

/* 토스뱅크 */
.ico-bi.b992::before {
  background-image: url('../img/logo_thumb/card/092092.png');
}

/* BC카드 */
.ico-bi.b361361::before {
  background-image: url('../img/logo_thumb/card/361361.png');
}

/* SC제일은행(BC카드) */
.ico-bi.b361023::before {
  background-image: url('../img/logo_thumb/card/361023.png');
}

/* 대구은행(BC카드) */
.ico-bi.b361031::before {
  background-image: url('../img/logo_thumb/card/361031.png');
}

/* 부산은행(BC카드) */
.ico-bi.b361032::before {
  background-image: url('../img/logo_thumb/card/361032.png');
}

/* 경남은행(BC카드) */
.ico-bi.b361039::before {
  background-image: url('../img/logo_thumb/card/361039.png');
}

/*----------------------------------------*\
  || 선불사 아이콘
\*----------------------------------------*/

/* 오픈기관 */
.ico-bi.bF00::before {
  background-image: url('../img/logo_thumb/pay/F00.png');
}

/* 지머니트랜스 */
.ico-bi.bF01::before {
  background-image: url('../img/logo_thumb/pay/F01.png');
}

/* 핀샷 */
.ico-bi.bF02::before {
  background-image: url('../img/logo_thumb/pay/F02.png');
}

/* 글로벌머니익스프레스 */
.ico-bi.bF03::before {
  background-image: url('../img/logo_thumb/pay/F03.png');
}

/* 한패스 */
.ico-bi.bF04::before {
  background-image: url('../img/logo_thumb/pay/F04.png');
}

/* 트래블월렛 */
.ico-bi.bF05::before {
  background-image: url('../img/logo_thumb/pay/F05.png');
}

/* 비바리퍼블리카(토스) */
.ico-bi.bF06::before {
  background-image: url('../img/logo_thumb/pay/F06.png');
}

/* 핀크 */
.ico-bi.bF07::before {
  background-image: url('../img/logo_thumb/pay/F07.png');
}

/* 디셈버앤컴퍼니 */
.ico-bi.bF08::before {
  background-image: url('../img/logo_thumb/pay/F08.png');
}

/* 카카오페이 */
.ico-bi.bF09::before {
  background-image: url('../img/logo_thumb/pay/F09.png');
}

/* 세틀뱅크 */
.ico-bi.bF10::before {
  background-image: url('../img/logo_thumb/pay/F10.png');
}

/* 뱅크샐러드 */
.ico-bi.bF11::before {
  background-image: url('../img/logo_thumb/pay/F11.png');
}

/* 쿠콘 */
.ico-bi.bF12::before {
  background-image: url('../img/logo_thumb/pay/F12.png');
}

/* 갤럭시아머니트리 */
.ico-bi.bF13::before {
  background-image: url('../img/logo_thumb/pay/F13.png');
}

/* 코나아이 */
.ico-bi.bF14::before {
  background-image: url('../img/logo_thumb/pay/F14.png');
}

/* 쿠팡페이 */
.ico-bi.bF15::before {
  background-image: url('../img/logo_thumb/pay/F15.png');
}

/* 네이버파이낸셜 */
.ico-bi.bF16::before {
  background-image: url('../img/logo_thumb/pay/F16.png');
}

/* 에스에스지닷컴 */
.ico-bi.bF17::before {
  background-image: url('../img/logo_thumb/pay/F17.png');
}

/* 티머니 */
.ico-bi.bF18::before {
  background-image: url('../img/logo_thumb/pay/F18.png');
}

/* 페이코 */
.ico-bi.bF19::before {
  background-image: url('../img/logo_thumb/pay/F19.png');
}

/* 십일번가 */
.ico-bi.bF20::before {
  background-image: url('../img/logo_thumb/pay/F20.png');
}

/* 케이에스넷 */
.ico-bi.bF21::before {
  background-image: url('../img/logo_thumb/pay/F21.png');
}

/* DGB 유페이 */
.ico-bi.bF22::before {
  background-image: url('../img/logo_thumb/pay/F22.png');
}

/* 롯데멤버스 */
.ico-bi.bF23::before {
  background-image: url('../img/logo_thumb/pay/F23.png');
}

/* 오케이인베스트먼트파트너스 */
.ico-bi.bF24::before {
  background-image: url('../img/logo_thumb/pay/F24.png');
}

/* 나이스정보통신 */
.ico-bi.bF25::before {
  background-image: url('../img/logo_thumb/pay/F25.png');
}

/* 이베이코리아 */
.ico-bi.bF26::before {
  background-image: url('../img/logo_thumb/pay/F26.png');
}

/* 차이코퍼레이션 */
.ico-bi.bF27::before {
  background-image: url('../img/logo_thumb/pay/F27.png');
}

/* 다날 */
.ico-bi.bF28::before {
  background-image: url('../img/logo_thumb/pay/F28.png');
}

/* 웰컴페이먼츠 */
.ico-bi.bF29::before {
  background-image: url('../img/logo_thumb/pay/F29.png');
}

/* 에스케이플래닛 */
.ico-bi.bF30::before {
  background-image: url('../img/logo_thumb/pay/F30.png');
}

/* 아이씨비 */
.ico-bi.bF31::before {
  background-image: url('../img/logo_thumb/pay/F31.png');
}

/* 에스케이플래닛 */
.ico-bi.bF32::before {
  background-image: url('../img/logo_thumb/pay/F32.png');
}

/* 카카오모빌리티 */
.ico-bi.bF33::before {
  background-image: url('../img/logo_thumb/pay/F33.png');
}

/* 지엘엔인터내셔널 */
.ico-bi.bF34::before {
  background-image: url('../img/logo_thumb/pay/F34.png');
}

/* 와이어바알리 */
.ico-bi.bF35::before {
  background-image: url('../img/logo_thumb/pay/F35.png');
}

/* 당근페이 */
.ico-bi.bF36::before {
  background-image: url('../img/logo_thumb/pay/F36.png');
}

/* Finddy */
.ico-bi.bF38::before {
  background-image: url('../img/logo_thumb/pay/F38.png');
}

/* 비즈플레이 */
.ico-bi.bF39::before {
  background-image: url('../img/logo_thumb/pay/F39.png');
}

/* 한국선불카드 */
.ico-bi.bF40::before {
  background-image: url('../img/logo_thumb/pay/F40.png');
}

/* 우아한형제들 */
.ico-bi.bF41::before {
  background-image: url('../img/logo_thumb/pay/F41.png');
}

/* 페이네스트 */
.ico-bi.bF42::before {
  background-image: url('../img/logo_thumb/pay/F42.png');
}

/* 하이픈코퍼레이션 */
.ico-bi.bF43::before {
  background-image: url('../img/logo_thumb/pay/F43.png');
}

/* 섹타나인 */
.ico-bi.bF44::before {
  background-image: url('../img/logo_thumb/pay/F44.png');
}

/* 스마트로 */
.ico-bi.bF45::before {
  background-image: url('../img/logo_thumb/pay/F45.png');
}

/* NICE */
.ico-bi.NIC::before {
  background-image: url('/img/logo_thumb/service/NIC.svg');
}
