@charset "utf-8";

/*----------------------------------------*\
  || 인증서 선택 Certi List
\*----------------------------------------*/

.certi-list {
  @include flex($direction: column, $gap: 10px);
  width: 100%;
  margin-top: 30px;
}

.certi-item {
  @include flex($gap: 24px);
  width: 100%;
  min-height: 74px;
  border: 1px solid var(--line-clr3);
  border-radius: 20px;
  transition: border-color .15s ease;

  &:hover {
    border: 2px solid var(--main-clr);

    a {
      padding: 12px 18px;
    }
  }

  &.disabled {
    background-color: #f2f4f6;
    border-color: #f2f4f6;

    a {
      pointer-events: none;
      user-select: none;

      .img {
        display: none;

        &.off {
          display: block;
          filter: grayscale(1);
        }
      }

      .wrap {
        width: 100%;
      }

      .name {
        flex: 1;
      }

      .tag {
        display: block;
      }
    }
  }

  &.unhover {
    padding: 13px 19px;

    &:hover {
      border: 1px solid var(--line-clr3);
    }
  }

  a {
    @include flex($gap: 12px);
    @include size(100%);
    padding: 13px 19px;

    &::after {
      @include size(28px);
      @include bgImg($url: '/img/common/icon/icon_arrow_right.png', $size: auto);
      content: '';
      display: block;
      min-width: 28px;
      margin-left: auto;
      border: 1px solid var(--disable-clr);
      border-radius: 50%;
    }
  }

  .img {
    @include size(40px);
    border-radius: 50%;

    &.off {
      display: none;
    }
  }

  .wrap {
    @include flex($wrap: wrap, $gap: 8px 10px);
  }

  .name {
    width: 100%;
    font-size: 18px;
    font-weight: $bold;
    line-height: 21px;
    color: var(--tit-clr);

    // & + .list {
    //   margin-top: 5px;
    // }
  }

  .dl-wrap {
    @include flex($gap: 5px 30px, $wrap: wrap);
  }

  .list {
    @include flex($gap: 4px);

    dt,
    dd {
      font-size: 14px;
      line-height: 16px;
      color: var(--cap-clr);
    }

    // dt {
    //   min-width: 40px;
    // }

    dd {
      color: #666;
    }
  }

  .tag {
    display: none;
    padding: 4px 6px;
    border: 2px solid var(--line-clr3);
    border-radius: 17px;
    font-size: 13px;
    font-weight: $bold;
    line-height: 16px;
    color: var(--line-clr3);
  }
}

.dim-contents {
  &:has(.certi-list) {
    width: 430px;
  }

  .popup-body01:has([role="tablist"]) {
    padding-top: 20px !important;
    padding-bottom: 30px;
  }

  .popup-body01:has(.certi-list) {
    height: 540px;
  }
}

@include respond-to ("medium") {
  .certi-item {
    // min-height: 90px;
    // padding: 20px;

    // a {
    //   gap: 15px;
    // }

    .img {
      @include size(38px);
    }
  }
}

/*----------------------------------------*\
  || 간편인증서
\*----------------------------------------*/

.private-list {
  @include flex($justify: space-between, $gap: 10px, $wrap: wrap);

  &.type-tab {
    margin-bottom: 30px;

    a,
    button {
      flex: 1;
      height: 145px;
    }
  }

  .private-item {
    @include size(auto, 175px);
    flex: 1;
    min-width: 219px;
  }

  a,
  button {
    @include flex($direction: column, $gap: 20px, $justify: center);
    @include size(100%);
    background-color: var(--bg-clr2);
    border: 1px solid var(--line-clr3);
    border-radius: 14px;
    transition: all .15s ease;

    &:hover,
    &[aria-selected=true] {
      border-color: var(--main-clr);
    }
  }

  .img {
    @include size(56px);
  }

  .text {
    font-size: 16px;
    font-weight: $bold;
    line-height: 19px;
    color: var(--tit-clr);
  }
}

/*----------------------------------------*\
  || 앱 인증 App Certi Box
\*----------------------------------------*/

.app-certi-box {
  @include flex($justify: center);
  @include size(120px);
  margin: 50px auto 20px;
  border: 1px solid var(--line-clr3);
  border-radius: 35px;

  img {
    width: 55px;
    margin-bottom: 7px;
  }
}

/*----------------------------------------*\
  || 보안카드 Security Card
\*----------------------------------------*/

.basic-security-wrap {
  @include flex($wrap: wrap, $gap: 25px);
}

.security-img-box {
  position: relative;

  .security-img {
    width: 515px;
  }

  .security-mark {
    position: absolute;
    display: block;
    width: 25px;
    height: 19px;
    background-color: #ff40404f;
    border: 1px solid var(--neg-clr);
    opacity: .7;


    &.mark-0101 {
      top: 35px;
      left: 44px;
    }

    &.mark-0102 {
      top: 35px;
      left: 68px;
    }

    &.mark-0201 {
      top: 35px;
      left: 146px;
    }

    &.mark-0202 {
      top: 35px;
      left: 171px;
    }

    &.mark-0301 {
      top: 35px;
      left: 249px;
    }

    &.mark-0302 {
      top: 35px;
      left: 273px;
    }

    &.mark-0401 {
      top: 35px;
      left: 352px;
    }

    &.mark-0402 {
      top: 35px;
      left: 376px;
    }

    &.mark-0501 {
      top: 35px;
      left: 455px;
    }

    &.mark-0502 {
      top: 35px;
      left: 479px;
    }

    &.mark-0601 {
      top: 68px;
      left: 44px;
    }

    &.mark-0602 {
      top: 68px;
      left: 68px;
    }

    &.mark-0701 {
      top: 68px;
      left: 146px;
    }

    &.mark-0702 {
      top: 68px;
      left: 171px;
    }

    &.mark-0801 {
      top: 68px;
      left: 249px;
    }

    &.mark-0802 {
      top: 68px;
      left: 273px;
    }

    &.mark-0901 {
      top: 68px;
      left: 352px;
    }

    &.mark-0902 {
      top: 68px;
      left: 376px;
    }

    &.mark-1001 {
      top: 68px;
      left: 455px;
    }

    &.mark-1002 {
      top: 68px;
      left: 479px;
    }

    &.mark-1101 {
      top: 102px;
      left: 44px;
    }

    &.mark-1102 {
      top: 102px;
      left: 68px;
    }

    &.mark-1201 {
      top: 102px;
      left: 146px;
    }

    &.mark-1202 {
      top: 102px;
      top: 171px;
    }

    &.mark-1301 {
      top: 102px;
      left: 249px;
    }

    &.mark-1302 {
      top: 102px;
      left: 273px;
    }

    &.mark-1401 {
      top: 102px;
      left: 352px;
    }

    &.mark-1402 {
      top: 102px;
      left: 376px;
    }

    &.mark-1501 {
      top: 102px;
      left: 455px;
    }

    &.mark-1502 {
      top: 102px;
      left: 479px;
    }

    &.mark-1601 {
      top: 136px;
      left: 44px;
    }

    &.mark-1602 {
      top: 136px;
      left: 68px;
    }

    &.mark-1701 {
      top: 136px;
      left: 146px;
    }

    &.mark-1702 {
      top: 136px;
      top: 171px;
    }

    &.mark-1801 {
      top: 136px;
      left: 249px;
    }

    &.mark-1802 {
      top: 136px;
      left: 273px;
    }

    &.mark-1901 {
      top: 136px;
      left: 352px;
    }

    &.mark-1902 {
      top: 136px;
      left: 376px;
    }

    &.mark-2001 {
      top: 136px;
      left: 455px;
    }

    &.mark-2002 {
      top: 136px;
      left: 479px;
    }

    &.mark-2101 {
      top: 169px;
      left: 44px;
    }

    &.mark-2102 {
      top: 169px;
      left: 68px;
    }

    &.mark-2201 {
      top: 169px;
      left: 146px;
    }

    &.mark-2202 {
      top: 169px;
      top: 171px;
    }

    &.mark-2301 {
      top: 169px;
      left: 249px;
    }

    &.mark-2302 {
      top: 169px;
      left: 273px;
    }

    &.mark-2401 {
      top: 169px;
      left: 352px;
    }

    &.mark-2402 {
      top: 169px;
      left: 376px;
    }

    &.mark-2501 {
      top: 169px;
      left: 455px;
    }

    &.mark-2502 {
      top: 169px;
      left: 479px;
    }

    &.mark-2601 {
      top: 203px;
      left: 44px;
    }

    &.mark-2602 {
      top: 203px;
      left: 68px;
    }

    &.mark-2701 {
      top: 203px;
      left: 146px;
    }

    &.mark-2702 {
      top: 203px;
      top: 171px;
    }

    &.mark-2801 {
      top: 203px;
      left: 249px;
    }

    &.mark-2802 {
      top: 203px;
      left: 273px;
    }

    &.mark-2901 {
      top: 203px;
      left: 352px;
    }

    &.mark-2902 {
      top: 203px;
      left: 376px;
    }

    &.mark-3001 {
      top: 203px;
      left: 455px;
    }

    &.mark-3002 {
      top: 203px;
      left: 479px;
    }

    &.mark-3101 {
      top: 236px;
      left: 44px;
    }

    &.mark-3102 {
      top: 236px;
      left: 68px;
    }

    &.mark-3201 {
      top: 236px;
      left: 146px;
    }

    &.mark-3202 {
      top: 236px;
      top: 171px;
    }

    &.mark-3301 {
      top: 236px;
      left: 249px;
    }

    &.mark-3302 {
      top: 236px;
      left: 273px;
    }

    &.mark-3401 {
      top: 236px;
      left: 352px;
    }

    &.mark-3402 {
      top: 236px;
      left: 376px;
    }

    &.mark-3501 {
      top: 236px;
      left: 455px;
    }

    &.mark-3502 {
      top: 236px;
      left: 479px;
    }
  }
}


.security-card-wrap {
  @include flex($direction: column, $gap: 35px, $align: normal, $justify: center);
  max-width: 507px;
  // margin-top: auto;
}

.security-card-list {
  margin-left: 30px;
}

.security-card-item {
  @include flex($gap: 4px);

  & + & {
    margin-top: 10px;
  }

  .security-card-label {
    margin-right: 38px;
    font-size: 16px;
    line-height: 18px;
    color: var(--cap-clr);

    strong {
      color: var(--tit-clr);
    }
  }
}

.security-card-inp-wrap {
  @include flex;
  width: 100%;
  max-width: 76px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--line-clr3);
  transition: all .15s ease;

  &.type-triple {
    width: 100px;
    max-width: none;

    .security-card-inp {
      max-width: 71px;
    }
  }

  &.disabled {
    justify-content: center;
    max-width: 36px;
    background-color: #fafafb;
    aspect-ratio: 1;

    &::before {
      @include size(10px);
      content: '';
      display: block;
      background-color: var(--cap-clr);
      border-radius: 50%;
    }
  }

  &:focus-within {
    border-color: var(--main-clr);
    box-shadow: inset 1px 1px var(--main-clr), inset -1px -1px var(--main-clr);
  }

  .security-card-inp {
    max-width: 47px;
    height: 34px;
    padding: 0 0 0 6px;
    font-family: monospace;
    font-size: 43px;
    letter-spacing: 0.02em;

    &:focus {
      outline: none;
    }
  }

  .vitualKeyCheck {
    height: 34px;

    .btn-mouse,
    .icon-mouse,
    .icon-mouse02 {
      margin: 0 0 0 -4px;
    }

    .btn-mouse {
      @include size(24px);
    }
  }
}

// 일련번호
// .serial-number-wrap {
//   .serial-number-title {
//     color: #555;
//     font-size: 16px;
//     line-height: 150%;

//     strong {
//       color: var(--tit-clr);
//     }

//     .serial-number {
//       font-weight: $bold;
//     }
//   }

//   .basic-security-wrap {
//     @include flex($wrap: wrap, $gap: 60px, $justify: center);
//     margin: 12px 0 20px;
//   }

//   .security-card-inp-wrap {
//     &.disabled {
//       width: 36px;
//       height: 36px;
//     }

//     .security-card-inp {
//       max-width: 36px;
//       padding: 0;
//       text-align: center;
//     }
//   }
// }

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*보안모듈페이지 타이틀 이미지*/
.title-top.bg-com-img {
  background-image: url("/img/common/title_com_img.png");
  background-repeat: no-repeat;
  background-position: right 30px center;
}

/*----------------------------------------*\
  || 2채널 인증
\*----------------------------------------*/

.ars-num {
  width: 86px;
  padding: 13px 0;
  border: 1px solid var(--disable-clr);
  text-align: center;
  font-size: 24px;
  border-radius: 7px;
}

.alert-message,
td.alert-message {
  padding: 60px 0;
  text-align: center;
}


.alert-message .text {
  font-size: 16px;
}

/*----------------------------------------*\
  || OTP IMG
\*----------------------------------------*/

.bg-otpimg {
  margin-top: 40px;
  padding: 30px;
  background: #f7f7f7 url(/img/common/bg_otpimg.png) no-repeat right 80px center;
  background-size: 20%;

  .warn-title {
    width: 70%;

    &::after {
      content: '';
    }
  }

  .warn-txt {
    width: 70%;
  }
}

/*----------------------------------------*\
  || 2채널 보안 인증완료
\*----------------------------------------*/

.certifiy-result-wrap {
  padding: 165px 0 73px;
  background: url('/img/common/bg_result_succ.png') no-repeat 50% 34%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  & + .list-style.type02 {
    margin-top: 34px;
  }

  .text {
    font-weight: $medium;
    font-size: 24px;
    color: var(--body-clr);
  }
}

/*----------------------------------------*\
  || 보안카드 코드표
\*----------------------------------------*/

.secret-code-select {
  position: relative;
  width: 480px;
  height: 300px;
  background: url('/img/common/security_card.jpg') no-repeat center center;

  .mark {
    position: absolute;
    border: 1px solid #e06262;
    border-radius: 0px;
    width: 22px;
    height: 18px;
    background: var(--bg-clr2);
    /*background:#f0b2b2;*/
    text-indent: -5000px;

    &.mark-0101 {
      top: 54px;
      left: 52px;
    }

    &.mark-0102 {
      top: 54px;
      left: 68px;
    }

    &.mark-0201 {
      top: 54px;
      left: 140px;
    }

    &.mark-0202 {
      top: 54px;
      top: 171px;
    }

    &.mark-0301 {
      top: 54px;
      left: 249px;
    }

    &.mark-0302 {
      top: 54px;
      left: 273px;
    }

    &.mark-0401 {
      top: 54px;
      left: 352px;
    }

    &.mark-0402 {
      top: 54px;
      left: 376px;
    }

    &.mark-0501 {
      top: 54px;
      left: 455px;
    }

    &.mark-0502 {
      top: 54px;
      left: 479px;
    }

    &.mark-0601 {
      top: 68px;
      left: 52px;
    }

    &.mark-0602 {
      top: 68px;
      left: 68px;
    }

    &.mark-0701 {
      top: 68px;
      left: 140px;
    }

    &.mark-0702 {
      top: 68px;
      top: 171px;
    }

    &.mark-0801 {
      top: 68px;
      left: 249px;
    }

    &.mark-0802 {
      top: 68px;
      left: 273px;
    }

    &.mark-0901 {
      top: 68px;
      left: 352px;
    }

    &.mark-0902 {
      top: 68px;
      left: 376px;
    }

    &.mark-1001 {
      top: 68px;
      left: 455px;
    }

    &.mark-1002 {
      top: 68px;
      left: 479px;
    }

    &.mark-1101 {
      top: 102px;
      left: 52px;
    }

    &.mark-1102 {
      top: 102px;
      left: 68px;
    }

    &.mark-1201 {
      top: 102px;
      left: 140px;
    }

    &.mark-1202 {
      top: 102px;
      top: 171px;
    }

    &.mark-1301 {
      top: 102px;
      left: 249px;
    }

    &.mark-1302 {
      top: 102px;
      left: 273px;
    }

    &.mark-1401 {
      top: 102px;
      left: 352px;
    }

    &.mark-1402 {
      top: 102px;
      left: 376px;
    }

    &.mark-1501 {
      top: 102px;
      left: 455px;
    }

    &.mark-1502 {
      top: 102px;
      left: 479px;
    }

    &.mark-1601 {
      top: 136px;
      left: 52px;
    }

    &.mark-1602 {
      top: 136px;
      left: 68px;
    }

    &.mark-1701 {
      top: 136px;
      left: 140px;
    }

    &.mark-1702 {
      top: 136px;
      top: 171px;
    }

    &.mark-1801 {
      top: 136px;
      left: 249px;
    }

    &.mark-1802 {
      top: 136px;
      left: 273px;
    }

    &.mark-1901 {
      top: 136px;
      left: 352px;
    }

    &.mark-1902 {
      top: 136px;
      left: 376px;
    }

    &.mark-2001 {
      top: 136px;
      left: 455px;
    }

    &.mark-2002 {
      top: 136px;
      left: 479px;
    }

    &.mark-2101 {
      top: 169px;
      left: 52px;
    }

    &.mark-2102 {
      top: 169px;
      left: 68px;
    }

    &.mark-2201 {
      top: 169px;
      left: 140px;
    }

    &.mark-2202 {
      top: 169px;
      top: 171px;
    }

    &.mark-2301 {
      top: 169px;
      left: 249px;
    }

    &.mark-2302 {
      top: 169px;
      left: 273px;
    }

    &.mark-2401 {
      top: 169px;
      left: 352px;
    }

    &.mark-2402 {
      top: 169px;
      left: 376px;
    }

    &.mark-2501 {
      top: 169px;
      left: 455px;
    }

    &.mark-2502 {
      top: 169px;
      left: 479px;
    }

    &.mark-2601 {
      top: 203px;
      left: 52px;
    }

    &.mark-2602 {
      top: 203px;
      left: 68px;
    }

    &.mark-2701 {
      top: 203px;
      left: 140px;
    }

    &.mark-2702 {
      top: 203px;
      top: 171px;
    }

    &.mark-2801 {
      top: 203px;
      left: 249px;
    }

    &.mark-2802 {
      top: 203px;
      left: 273px;
    }

    &.mark-2901 {
      top: 203px;
      left: 352px;
    }

    &.mark-2902 {
      top: 203px;
      left: 376px;
    }

    &.mark-3001 {
      top: 203px;
      left: 455px;
    }

    &.mark-3002 {
      top: 203px;
      left: 479px;
    }

    &.mark-3101 {
      top: 236px;
      left: 52px;
    }

    &.mark-3102 {
      top: 236px;
      left: 68px;
    }

    &.mark-3201 {
      top: 236px;
      left: 140px;
    }

    &.mark-3202 {
      top: 236px;
      top: 171px;
    }

    &.mark-3301 {
      top: 236px;
      left: 249px;
    }

    &.mark-3302 {
      top: 236px;
      left: 273px;
    }

    &.mark-3401 {
      top: 236px;
      left: 352px;
    }

    &.mark-3402 {
      top: 236px;
      left: 376px;
    }

    &.mark-3501 {
      top: 236px;
      left: 455px;
    }

    &.mark-3502 {
      top: 236px;
      left: 479px;
    }
  }
}

.docu-table-area {
  & + & {
    margin-top: 40px;
  }

  .sub-title {
    font-size: 18px;
  }
}

table .th-sub {
  display: block;
  margin-top: 2px;
  font-size: 11px;
}

.radio-box .th-sub {
  display: inline-block;
  padding-left: 30px;
}

/*----------------------------------------*\
  || 서비스 이용절차
\*----------------------------------------*/

.service-step {
  border-top: 1px solid var(--body-clr);

  .table.width-style01 {
    width: 50%
  }

  .table-cell {
    width: 50%;
    padding: 40px 203px 193px 60px;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    vertical-align: top;

    &:nth-child(2n) {
      border-right: none;
    }

    &.service-box {
      background: url('/img/common/icon_uselist01.gif') no-repeat 92% 35px;
      background-size: 27% auto;
    }

    &.service-box.img02 {
      padding-right: 340px;
      background-image: url('/img/common/icon_uselist02.gif');
      background-size: 50% auto;
    }

    &.service-box.img03 {
      background-image: url('/img/common/icon_uselist03.gif');
    }

    &.service-box.img04 {
      padding-right: 340px;
      background-image: url('/img/common/icon_uselist04.gif');
      background-size: 50% auto;
    }

    &.service-box.img05 {
      background-image: url('/img/common/icon_uselist05.gif')
    }
  }

  .step-title {
    position: relative;

    > .txt {
      display: block;
      margin-bottom: 14px;
      font-weight: $medium;
      font-size: 18px;
      line-height: 1.2;

      &:last-child {
        font-size: 16px;
      }
    }

    .num-icon {
      position: absolute;
      top: -4px;
      left: -41px;
      display: block;
      width: 24px;
      height: 24px;
      background: var(--body-clr);
      border-radius: 50%;
      color: var(--bg-clr2);
      text-align: center;
      line-height: 27px;
      font-size: 16px;
    }
  }

  .step-des {
    li {
      margin-top: 5px;
      font-size: 14px;
      line-height: 23px;
      padding-left: 15px;

      &::before {
        content: '';
        float: left;
        margin: 7px 0 0 -15px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #999;
      }
    }

    .small {
      display: block;
      font-size: 14px;
      line-height: 1.6;
    }
  }
}

/*----------------------------------------*\
  || Toss 토스인증
\*----------------------------------------*/

.cert-list {
  display: flex;
  justify-content: center;
}

.cert-item {
  margin: 10px;
  padding: 30px 15px 20px;
  border: 2px solid var(--line-clr);
  text-align: center;
}

/*----------------------------------------*\
  || Sub Box
\*----------------------------------------*/

.sub-box-wrap {
  padding: 20px;

  &.option-td {
    display: none;
    width: 680px;
    clear: both;
    padding: 15px 0 0;

    .sub-box {
      display: table;
      table-layout: fixed;
      width: 100%;
    }
  }
}

.sub-box {
  background-color: #f7f7f7;
  padding: 20px 20px 16px;

  &.sub-box02 {
    padding: 40px;
  }
}

.sub-box,
.sub-box-wrap {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.sub-box-num,
.sub-box-btn {
  display: table-cell;
  vertical-align: middle;
}

.sub-box-num {
  width: 410px;
  padding: 10px 0 10px 20px;

  li {
    font-size: 14px;
    line-height: 30px;
  }
}

.sub-box-btn {
  width: 270px;
}

.doc-box02 {
  width: 50%;
  float: left;
  font-size: 0;
  text-align: center;

  &:first-child {
    border-right: 1px solid var(--disable-clr);
  }

  span {
    color: #666;
    font-size: 14px;
    font-weight: $bold;
    vertical-align: middle;
  }

  button {
    vertical-align: middle;
    margin-left: 20px;
  }
}

.checked-txt-fail {
  padding: 10px 10px 10px 25px;
  background: url("/img/common/checked_icon_fail.png") no-repeat left center;
  display: block;
}

.table-exp {
  font-size: 11px;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .bg-otpimg {
      margin-top: 0;
      padding: 24px 16px;
      background-image: none;
    }

    .warn-title {
      width: 100%;
      margin-bottom: 15px;
      font-size: 20px;
    }

    .warn-txt {
      width: 100%;
      font-size: 13px;
    }

    & + .info-table-area {
      padding-top: 30px;
    }

    .service-step {
      .table {
        display: block !important;

        &.width-style01 {
          width: 100%;
        }
      }

      .table-cell {
        display: block !important;
        width: 100%;
        border-right: 0;

        .box.img {
          padding-left: 10px;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .service-step .table-cell.service-box {
    background-size: 150px auto;

    &.img02 {
      background-size: 260px auto;
    }

    &.img04 {
      background-size: 260px auto;
    }
  }
}

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  .service-step .table-cell {
    padding: 40px 60px 350px 60px;

    &.service-box {
      background-size: 150px auto;
      background-position: 50% 90%;
    }

    &.service-box.img02 {
      padding: 40px 60px 300px 60px;
      background-size: 260px auto;
    }

    &.service-box.img04 {
      padding: 40px 60px 300px 60px;
      background-size: 260px auto;
    }

    &.service-box + &.service-box {
      margin-top: 30px;
    }
  }
}
