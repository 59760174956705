@charset "utf-8";

/*----------------------------------------*\
  || 예적금상품목록 / 대출상품목록
\*----------------------------------------*/

.wide-product-list {
  @include size(100%, auto);
  margin: 30px 0;
}

.wide-product-item {
  @include flex($justify: space-between, $gap: 20px, $wrap: wrap);
  width: 100%;
  padding: 30px 40px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--line-clr3);
  border-radius: 20px;
  transition: all .15s ease;

  &:hover {
    border-color: var(--main-clr);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  }

  & + li {
    margin-top: 16px;
  }

  .basic-btn {
    @include size(160px, 47px);
    font-size: 16px;
    font-weight: $medium;
    line-height: 19px;
  }
}

.wide-product-box {
  @include flex($justify: space-between, $gap: 15px);
  flex-grow: 1;

  &:hover .title {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  .box {
    @include flex($justify: center, $direction: column, $gap: 10px);
    flex: 1;

    &:first-child {
      align-items: normal;
      width: auto;
      max-width: 580px;
      margin-right: auto;
    }

    &:not(:first-child) {
      min-width: 160px;
      max-width: 160px;
    }

    &:not(:first-child) + .box {
      min-width: 165px;
      max-width: 165px;
      padding-left: 15px;
      border-left: 1px solid var(--line-clr3);
    }

    &:nth-child(2):has(+.hide) {
      min-width: 330px;
      max-width: 330px;
    }
  }

  .list {
    @include flex($gap: 8px);

    span {
      @include flex($gap: 8px);
      font-weight: $medium;

      &::before {
        @include size(1px, 10px);
        content: '';
        background-color: var(--line-clr3);
      }

      &:first-child::before {
        display: none;
      }
    }
  }

  .title {
    @include ellipsis;
    max-width: 600px;
    font-size: 24px;
    line-height: 26px;
    color: var(--tit-clr);
  }

  .des {
    @include ellipsis;
    max-width: 600px;
  }

  .interest {
    @include flex($gap: 5px);
    font-size: 24px;
    font-weight: $medium;
    line-height: 28px;
    color: #ff6200;

    // span {
    //   margin-right: 5px;
    // }
  }

  span {
    font-size: 14px;
    line-height: 16px;
    color: #666;
  }
}

@include respond-to ("medium") {
  .wide-product-item {
    padding: 20px;
    border-radius: 14px;

    .basic-btn {
      margin: 10px auto auto;
      height: 40px;
      border-radius: 10px;
    }
  }

  .wide-product-box {
    flex-direction: column;
    width: 100%;

    .box {
      &:first-child {
        flex-direction: column;
      }

      &:not(:first-child) + .box {
        padding-left: 0;
        padding-top: 15px;
        border-left: none;
        border-top: 1px solid var(--line-clr3);
      }

      &:nth-child(2):has(+.hide) {
        min-width: auto;
      }
    }

    .list span {
      font-size: 13px;
    }

    .title {
      font-size: 20px;
      line-height: 24px;
      white-space: initial;
    }

    .des {
      @include ellipsis(2);
      max-width: none;
      font-size: 13px;
      white-space: initial;
    }
  }
}

/*----------------------------------------*\
  || 예적금상품안내 / 대출상품안내
\*----------------------------------------*/

.dep-title-wrap {
  margin-bottom: 120px;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -50vw;
    width: 150vw;
    height: 447px;
    background-color: #eef7fa;
    z-index: -1;
  }

  .page-main-title {
    margin: 0 0 10px;
    font-weight: $bold;
  }

  .des {
    font-size: 18px;
    line-height: 21px;
    color: #666;
  }

  .interest {
    @include flex($gap: 8px, $justify: center);
    margin: 40px auto;

    dt,
    dd {
      font-size: 30px;
      font-weight: $medium;
      line-height: 36px;
      color: var(--body-clr);
    }

    dd {
      font-weight: $bold;
      color: var(--neg-clr);
    }
  }

  .date {
    @include flex($gap: 10px, $justify: center);

    &::before {
      @include size(38px);
      @include bgImg($url: '/img/content/dep/icon_date.png');
      content: '';
    }

    dt,
    dd {
      font-size: 20px;
      font-weight: $medium;
      line-height: 24px;
      color: var(--body-clr);
    }
  }

  .basic-btn {
    margin: 40px auto auto;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  }
}

.dep-calc-box {
  @include flex($align: stretch, $wrap: wrap, $justify: space-between);
}

.info-acco-group-item.type-calc {

  .info-acco-head .btn,
  .info-acco-head .btn strong {
    transition: all .3s ease;
  }

  .info-acco-head .btn[aria-expanded="true"] {
    align-items: flex-start;
    padding: 30px 30px 10px;
    background-color: var(--bg-clr2);

    &:hover {
      filter: none;
    }

    strong {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .left {
    @include flex($direction: column, $align: normal, $gap: 10px);

    .des {
      margin-bottom: auto;
      font-size: 16px;
      line-height: 19px;
      color: #666;
    }
  }

  .info-acco-box {
    padding-top: 0;
  }
}

.calc-list {
  @include flex($direction: column, $gap: 20px);

  > li {
    @include flex($gap: 10px, $justify: space-between);
    width: 100%;

    > label,
    > .label {
      flex-grow: 1;
      min-width: fit-content;
      margin-right: 5px;
      font-size: 16px;
      font-weight: $medium;
      line-height: 19px;
    }
  }

  .basic-inp-wrap {
    width: 500px;

    span.basic-inp {
      @include flex($justify: flex-end);
      min-width: 120px;
      height: 30px;
      line-height: 1;
    }
  }

  .basic-full-radio-list {
    min-width: 250px;

    & + .basic-inp-wrap {
      width: 240px;
    }
  }

  .basic-btn-wrap {
    margin: 0 0 0 auto;
    max-width: 500px;
  }

  .basic-btn {
    @include size(500px, 44px);
    margin-left: auto;
    border-radius: 10px;
    font-size: 16px;
    font-weight: $medium;
    line-height: 19px;
  }
}

.info-acco-group-item.type-kdic {
  @include flex($gap: 40px, $wrap: wrap);
  padding: 30px;

  img {
    width: 120px;
  }

  p {
    font-size: 14px;
    line-height: 28px;
    word-break: auto-phrase;
  }
}

.info-acco-box {
  display: none;
  padding: 30px 30px 40px;

  .info-table-area:first-child {
    margin-top: 0 !important;
  }
}

@include respond-to ("medium") {
  .dep-title-wrap {
    @include flex($direction: column, $justify: space-between);
    height: 300px;
    margin-bottom: 85px;

    &::after {
      height: 375px;
    }

    .page-main-title {
      font-size: 24px;
      line-height: 30px;
    }

    .des {
      margin-bottom: auto;
      font-size: 14px;
      line-height: 18px;
    }

    .interest {
      margin: 25px auto;
      font-size: 24px;
      line-height: 30px;

      dt,
      dd {
        font-size: 24px;
      }
    }

    .date {

      &::before {
        @include size(28px);
      }

      dt,
      dd {
        font-size: 16px;
        line-height: 19px;
      }
    }

    .basic-btn {
      max-width: 230px;
      margin: 40px auto 0;
    }
  }

  .dep-calc-box {
    gap: 40px;
    flex-direction: column;
  }

  .info-acco-group-item.type-calc {

    .info-acco-head .btn[aria-expanded="true"] {
      padding: 15px;

      strong {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .left {
      width: 100%;

      .des {
        margin-bottom: 15px;
      }
    }
  }

  .calc-list {

    > li {
      gap: 0;
      align-items: normal;
      flex-direction: column;

      > label,
      > .label {
        font-size: 14px;
      }
    }

    .basic-inp-wrap {
      width: 100%;
    }

    .basic-full-radio-list {
      min-width: auto;
      margin-top: 10px;

      & + .basic-inp-wrap {
        width: 100%;
      }
    }

    .basic-btn {
      width: 100%;
    }
  }

  .info-acco-box {
    padding: 15px;
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.no-item {
  min-height: 178px;
  text-align: center;
  font-weight: $medium;
  font-size: 22px;
  line-height: 117px;
}

/* 모아저축은행 SB톡톡 플러스 정기예금 단리 중도해지이율  (*기준 : 연이율, 세전) 19.09.17 김재원 */
.sub-infotxt-type01 {
  clear: both;
  float: right;
  text-align: right;
  font-size: 14px !important;
}

/*----------------------------------------*\
  || 예적금 목록
\*----------------------------------------*/

.product-list {
  overflow: hidden;
  width: 100%;
  border-top: 1px solid var(--body-clr);

  > li {
    overflow: hidden;
    width: 100%;
    padding: 30px 3.7% 35px;
    position: relative;
    border-bottom: 1px solid #efefef;

    .product-list-l {
      float: left;
      width: 47%;
      height: 100%;
    }

    .product-list-tit {
      font-size: 24px;
      float: left;
      width: 380px;
      padding-top: 49px;
      font-weight: $medium;
      letter-spacing: -0.05em;
    }

    .product-list-exp {
      width: 100%;
      float: left;
      line-height: 1.5;
      font-size: 16px;
      padding-top: 15px;
    }

    .product-list-classi {
      position: absolute;
      top: 30px;
      left: 40px;
      font-size: 11px;
    }

    .product-list-irate {
      width: 38.2%;
      display: table;
      text-align: center;
      float: left;
      margin-top: 21px;

      > li {
        display: table-cell;
        width: 50%;
        border-left: 1px solid #efefef;
        margin-left: 40px;
        height: 90px;
        text-align: center;
        font-size: 14px;
        letter-spacing: -0.07em;

        &:first-child {
          width: 125px;
          padding-left: 0;
          margin-left: 0;
          border-left: 0;
        }

        em {
          display: block;
          font-size: 20px;
          font-style: normal;
          font-weight: $medium;
          padding: 10px 0;

          i {
            display: inline;
            width: auto;
            color: var(--neg-clr);
            font-size: 30px;
            font-style: normal;
            font-weight: $medium;
          }
        }
      }
    }

    .product-list-btn {
      float: left;
      width: 14.8%;
      margin-top: 17px;

      button {
        margin-bottom: 10px;
      }
    }
  }
}

.product-list-btn.margin .btn-small {
  margin-bottom: 10px;
}

.product-list-classi > span:not([class^=ico]) {
  display: inline-block;
  vertical-align: middle;
}

.product-view {
  width: 100%;
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);
  padding-top: 20px;

  &.type01 {
    padding-top: 30px;
  }

  .product-list-l {
    position: relative;
    padding: 0 40px;
  }

  .ico-internet,
  .ico-smartphone,
  .ico-shop {
    display: inline-block;
  }

  .product-view-tit {
    font-size: 24px;
    display: inline-block;
    width: 100%;
    padding-top: 25px;
    font-weight: $medium;
    letter-spacing: -0.05em;
  }

  .product-view-exp {
    width: 100%;
    line-height: 1.5;
    font-size: 16px;
    display: inline-block;
    padding-top: 15px;
  }

  .product-view-sns {
    float: right;

    a {
      float: left;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.product-view-dl {
  overflow: hidden;
  width: 100%;
  margin-top: 35px;
  padding: 19px 40px 0;
  border-top: 1px solid #efefef;

  & + & {
    margin-top: 0;
  }

  > li {
    display: table;
    width: 100%;
    padding-top: 15px;

    &:first-child {
      padding-top: 0px;
    }
  }

  .notice {
    padding-left: 15px;
    font-size: 16px;
    font-weight: $medium;
    line-height: 1.4;

    &::before {
      content: '';
      float: left;
      margin-left: -15px;
      margin-top: 4px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: var(--cap-clr);
    }
  }

  .product-view-dt {
    font-weight: $medium;
    display: table-cell;
    width: 165px;
    padding-left: 15px;
    padding-right: 20px;
    vertical-align: top;
    font-size: 14px;
    line-height: 32px;
    word-break: keep-all;

    &:before {
      content: '';
      float: left;
      margin-left: -15px;
      margin-top: 6px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: var(--cap-clr);
    }

    &.type02 {
      width: 100%;
    }

    span {
      display: block;
      font-weight: $medium;
    }
  }

  .product-view-dd {
    display: inline-block;
    width: calc(100% - 170px);
    width: -webkit-calc(100% - 170px);

    .table-wrap01 {
      margin-top: 10px;
    }

    .table-wrap01 + .notice-list-wrap {
      margin-top: 15px;
    }

    .notice-list-wrap {
      margin-top: 5px;
      padding-top: 0;
    }

    .notice-list-wrap + .notice-list-wrap {
      margin-top: 10px;
    }

    .table-type04 th {
      width: 145px;
    }

    .table-type04 td {
      width: auto;
      border-right: 1px solid #efefef;

      &:last-child {
        border-right: 0;
      }
    }

    .img-wrap {
      margin-top: 10px;

      img {
        width: 100%;
      }
    }
  }

  .product-view-dt,
  .product-view-dd {
    font-size: 14px;
    line-height: 1.6;
  }

  .list {
    overflow: hidden;
    padding-top: 20px;
  }
}

.product-view-dd .btn-thin.resize {
  display: inline-block;
  padding: 4px 8px 3px;
  vertical-align: 1px;
  line-height: 20px;
}

.ico-wrap {
  font-size: 11px;
}

.ico-internet,
.ico-smartphone,
.ico-notice,
.ico-event,
.ico-etc,
.ico-shop {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  margin-right: 4px;
  padding: 0 8px;
  background-color: #017a51;
  border: 0;
  border-radius: 13px;
  font-size: 12px;
  font-weight: $medium;
  color: var(--bg-clr2);
  vertical-align: middle;

  &.w46 {
    width: 46px;
  }
}

.ico-notice,
.ico-internet {
  background-color: var(--neg-clr);
}

.ico-smartphone {
  background-color: var(--main-clr);
}

.ico-event {
  background-color: var(--pos-clr);
}

.ico-etc {
  background-color: var(--cap-clr);
}

.txt-total {
  font-size: 14px;

  &.loan {
    display: inline-block;
    margin-bottom: 15px;

    &::after {
      content: "";
      display: block;
      float: none;
      clear: both;
    }

    .item {
      float: left;
      font-weight: $medium;
      margin-right: 10px;
    }
  }
}

/*----------------------------------------*\
  || 상품테이블
\*----------------------------------------*/

.product-title {
  font-size: 24px;
  font-weight: $medium;
  padding-top: 30px;
  padding-bottom: 15px;
}

.product-txt {
  font-size: 16px;
  line-height: 1.3;
}

.loan-p-wrap {
  padding: 14px 0;
  background: #f7f7f7;

  &.one {
    padding: 40px 0px;
  }

  &.type01 {
    padding: 17px 0 20px;

    .loan-p-txt + .loan-p-point {
      display: inline-block;
      margin-top: 8px;
    }
  }

  .loan-p-txt {
    font-size: 16px;
    word-break: keep-all;
    display: block;
    line-height: 1.2;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.pc-none {
      display: none;
    }
  }

  .loan-p-point {
    font-size: 30px;
    color: var(--neg-clr);
    font-weight: $bold;
    line-height: 1.2;
    letter-spacing: -1px;
  }
}

.loan-p-info {
  font-size: 12px;
  line-height: 1.3;
  padding-top: 10px;
  display: inline-block;
}

/*----------------------------------------*\
  || 교직원 회원여부 조회
\*----------------------------------------*/

.inquiry-wrap {
  padding-top: 40px;

  .notice-list li strong {
    font-weight: $medium;
  }

  .chk-wrap {
    margin-top: 20px;
    display: block;
    text-align: right;
  }

  .inquiry-input {
    margin-top: 10px;
    text-align: right;
    font-size: 14px;
  }

  .title {
    display: inline-block
  }

  .input-basic {
    max-width: 128px !important;
  }
}

/*----------------------------------------*\
  || 보호금융상품 KDIC
\*----------------------------------------*/

.pv-kdic {
  display: table;
  width: 100%;
  margin-top: 25px;
  padding: 30px 150px 30px 40px;
  background: #f7f7f7;
  border-top: 1px solid #efefef;
  font-size: 14px;
  letter-spacing: -0.05em;
  word-break: keep-all;
  overflow: hidden;

  &.type02 {
    margin-top: 27px;
  }

  & + .common-text01 {
    margin-top: 45px;
  }

  .pv-kdic-logo {
    display: table-cell;
    padding-right: 40px;
    vertical-align: middle;
  }

  .pv-kdic-txt {
    display: table-cell;
    width: calc(100% - 180px);
    margin-top: 10px;
    line-height: 24px;
    vertical-align: middle;
  }
}

/*----------------------------------------*\
  || 상품설명 Style 추가
\*----------------------------------------*/

.list-wrap01 {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid var(--body-clr);
}

.list-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 20%;
  border-bottom: 1px solid var(--disable-clr);

  &-tit {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0px;
    width: 100%;
    max-width: 20%;
    background: #f7f7f7;
    border-right: 1px solid var(--disable-clr);
    font-size: 16px;
    font-weight: $bold;
    text-align: center;
  }

  &-txt {
    display: block;
    width: 100%;
    min-height: 52px;
    padding: 16px 18px;
    font-size: 16px;
  }

  &-box {
    width: 100%;
    height: 100%;
    padding: 18px;
    border-bottom: 1px solid var(--disable-clr);
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .no-item {
      padding-bottom: 16px !important;
      font-size: 19px;
      line-height: 138px;
    }

    .full-content {
      margin: 0 -18px !important;

      .btn-area {
        padding: 0 18px !important;
      }
    }

    .product-list {
      > li {
        padding: 20px 16px 35px;

        .product-list-tit {
          font-size: 16px;
        }

        .product-list-l {
          width: 100%;
        }

        .product-list-classi {
          left: 16px;
          top: 26px;
          font-size: 11px;
        }

        .product-list-exp {
          width: 100%;
          font-size: 13px;
          color: #666
        }

        .product-list-btn {
          position: relative;
          right: 0;
          top: 10px;
          width: 100%;
          display: inline-block;
          margin-top: 25px;

          button {
            width: 100%;
          }
        }
      }

      .product-list-irate {
        position: relative;
        left: 0px;
        top: 10px;
        width: 100%;
        margin-top: 0;
        background: #efefef;

        > li {
          height: auto;
          width: 50%;
          padding: 25px 0;
          margin: 0;
          font-size: 11px;
          border-left: 1px solid var(--disable-clr) !important;

          &:first-child {
            border: 0 !important;
          }

          .m-bold {
            font-size: 13px;
            font-weight: $medium;
          }

          em {
            font-size: 18px;

            i {
              font-size: 24px;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .product-view {
      padding: 20px;

      &.mt50 {
        margin-top: 15px !important;
      }

      .product-view-tit {
        padding-top: 0;
        font-size: 20px;
      }

      .product-list-l {
        padding: 0 5px;
      }

      .product-view-dl {
        padding: 20px 5px;
        margin-top: 20px;
      }

      .product-view-exp {
        font-size: 14px;
      }

    }

    .product-view-dl {
      li {
        overflow: hidden;
      }

      .product-view-dt {
        display: block;
        width: 100%;
        padding-left: 10px;
        line-height: 20px;

        &::before {
          margin-top: 8px;
          margin-left: -10px;
        }

        span {
          display: inline;
        }
      }

      .product-view-dd {
        width: 100%;

        .table-type03-w {
          display: table;
        }

        .img-wrap img {
          max-width: 100%;
        }

        .notice-list-wrap {
          padding: 0;
        }
      }
    }

    .product-view-dt {
      float: left;
      width: 33%;
    }

    .product-view-dd {
      float: left;
      width: 67%;
      line-height: 1.2;
    }

    .txt-total {
      display: inline-block;
      padding: 20px 0 0 16px;
      font-size: 11px;
      color: #666;
    }

    .pv-kdic {
      padding: 16px;

      .pv-kdic-logo {
        display: inline-block;
        margin-top: 7px;
        margin-right: 20px;
        padding-right: 0;
        min-width: 100px;

        img {
          max-width: 100%;
        }
      }
    }

    .product-title {
      font-size: 16px;
      padding-top: 25px;
      padding-bottom: 10px;
    }

    .product-txt {
      font-size: 13px;
    }

    .loan-p-wrap {
      text-align: center;
      padding: 30px 0 0px;

      .loan-p-txt {
        font-size: 16px;
        font-weight: $bold;

        &.pc-none {
          display: block;
        }
      }

      .loan-p-point {
        font-size: 24px;
      }
    }

    .loan-p-info {
      width: 100%;
      background: #f7f7f7;
      text-align: center;
      font-size: 11px;
      padding-bottom: 20px;
    }

    .ico-wrap {
      font-size: 11px;
    }

    .ico-internet,
    .ico-smartphone,
    .ico-notice,
    .ico-event,
    .ico-etc,
    .ico-shop {
      padding: 3px 11px 1px;
    }

  }

  .list-item-tit,
  .list-item-txt {
    font-size: 14px;
  }
}
