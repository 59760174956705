@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/* 금리인하 신청 결과 동의 */
.consent-txt {
  width: 100%;
  margin: 26px 0 20px;
  text-align: center;
  font-size: 21px;
}

.no-info {
  padding: 50px 0;
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);
  text-align: center;
  font-size: 16px;
}

/*----------------------------------------*\
  || 약관동의 Top Notice
\*----------------------------------------*/

.slide-notice-wrap {
  background: #fcfcfc;
  border-bottom: 1px solid var(--disable-clr);
  margin-top: 0;
  padding: 20px;

	.notice-i-list-wrap {
		margin-top: 0;
	}
}

/*----------------------------------------*\
  || 대출 심사 결과
\*----------------------------------------*/

.divi-styl {
  overflow: hidden;
  background: var(--bg-clr2);
  padding: 30px 0;

  &:before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    display: inline-block;
    width: 1px;
    height: 30px;
    background: var(--line-clr3);
  }

  &:last-child {
    margin-right: 0;
    padding-right: 0;
  }

  &:first-child:before {
    background: none;
  }

  li {
    display: inline-block;
    position: relative;
    width: 24.5%;
    text-align: center;

    .divi-stylop {
      font-size: 18px;
      font-weight: $medium;
      display: block;
      margin-top: 14px;
    }

    .divi-styl.type01 li {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding-left: 40px;
      text-align: left;
      font-size: 14px;
    }
  }
}

/*----------------------------------------*\
  || PEY 추가 대출
\*----------------------------------------*/

.notice-area-wrap {
  padding-bottom: 60px;
}

.notice-area-wrap .notice-box li {
  background: #f7f7f7;
  padding: 40px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.notice-area-wrap .notice-box li .notice-number,
.notice-txtbox .notice-number {
  @include flex($gap: 15px);
  width: 60px;
  font-weight: $medium;
  font-size: 24px;
  vertical-align: middle;
}

.notice-txtbox {
  @include flex;

  .notice-number {
    width: auto;

    &::after {
      content: '';
      width: 1px;
      height: 14px;
      background: #e1e1e1;
      vertical-align: middle;
    }
  }

  .table-cell {
    font-size: 14px;
  }
}

.notice-subtxt,
.notice-titletxt {
  display: table-cell;
  vertical-align: middle;
  font-size: 20px;
  line-height: 25px;
}

.notice-titletxt {
  font-size: 24px;
  font-weight: $medium;
}

.notice-box {
  .notice-list {
    padding-top: 20px;
    background: transparent;

    li {
      padding: 0 0 0 74px;
      margin-bottom: 0;
    }

    .notice-list li {
      padding-left: 10px;
    }
  }

  &.notis .notice-list li {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    .fir-line {
      line-height: 28px;
    }
  }
}

.notice-number.no-title {
  font-size: 22px;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {

  // html.responsive {}
  .notice-area-wrap .notice-box > li {
    padding: 32px;
  }

  .notice-area-wrap .notice-box > li .notice-number,
  .notice-txtbox .notice-number {
    margin-top: 3px;
    font-size: 20px;
  }

  .notice-number:after {
    margin-top: 2px;
  }

  .notice-subtxt,
  .notice-titletxt {
    font-size: 16px;
  }

  .divi-styl li {
    display: inline-block;
    position: relative;
    width: 49.5%;
    text-align: center;

    &:nth-child(3):before {
      display: none;
    }

    .divi-stylop {
      margin-bottom: 14px;
    }
  }
}
