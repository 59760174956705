@charset "utf-8";

/*----------------------------------------*\
  || Widget
\*----------------------------------------*/

.ui-widget {
  &.ui-widget-content {
    border: 1px solid var(--cap-clr);
  }
}

.ui-widget-content {
  background: #f7f7f7;
  color: var(--body-clr);
}

.ui-datepicker {
  width: 307px !important;
  padding: 10px 24px;
}

.ui-widget-header {
  background: none;
  border: 0;
}

/*----------------------------------------*\
  || Datepicker
\*----------------------------------------*/

.ui-datepicker {
  .ui-datepicker-title {
    font-weight: $bold;
    width: 72%;
    margin: 0 auto;

    select {
      font-size: 18px;
      background: url("/img/common/cal_select_icon.jpg") no-repeat right 0px center;

      &.ui-datepicker-month,
      &.ui-datepicker-year {
        width: 40%;
      }

      &.ui-datepicker-year {
        margin-right: 20px;
        margin-left: 10px;
      }
    }
  }

  table {
    font-size: 14px;
  }

  th span {
    color: var(--cap-clr) !important;
  }

  td a,
  td span {
    padding: 10px 5px;
    text-align: center;
    border-radius: 50%;
  }
}

/*버튼*/
.ui-datepicker-next.ui-corner-all,
.ui-datepicker-prev.ui-corner-all {
  display: none;
}

/*일*/
.ui-state-default,
.ui-widget-content .ui-state-default {
  background: none;
  border: 0;
}

/*오늘*/
.ui-widget-content .ui-state-highlight {
  border: 1px solid var(--neg-clr);
}

/*선택일*/
.ui-state-active,
.ui-widget-content .ui-state-active {
  color: var(--bg-clr2);
  position: relative;
}

.ui-state-active::after {
  content: '';
  width: 30px;
  height: 30px;
  background: var(--neg-clr);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -5;
  border-radius: 50%;
}

[id*=monthpicker].ui-widget select {
  font-size: 18px;
  background: url("/img/common/cal_select_icon.jpg") no-repeat right 10px center;
  padding: 3px 35px;
}


.ui-state-default,
.ui-widget-content .ui-state-default {
  padding: 10px 0px !important;
  cursor: pointer !important;
}

.mtz-monthpicker {
  .ui-state-active {
    background: transparent;
    color: var(--bg-clr2);
    position: relative;
    z-index: 10;

    &::after {
      content: '';
      width: 30px;
      height: 30px;
      background: var(--neg-clr);
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: -5;
      border-radius: 50%;
    }
  }
}

/*----------------------------------------*\
  || Input
\*----------------------------------------*/

.input-area {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
  height: 100%;

  .btn-datepicker {
    position: absolute;
    right: 10px;
    top: 13px;
  }
}

.input-area02 {
  position: relative;
  display: inline-block;
  height: 100%;

  .btn-datepicker {
    position: absolute;
    left: 0;
    top: -22px;
  }
}

.date-box2 .input-area {
  width: 184px;
  vertical-align: middle;

  .input-basic {
    margin-right: 0;
  }
}

.glyphicon-calendar img {
  width: 19px;
}

.dim-contents .datepicker-calendar {
  position: fixed;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
}

.datepicker-calendar {
  position: absolute;
  height: auto;
  width: 306px;
  padding: 40px 24px 24px;
  border: 1px solid var(--tit-clr);
  background: var(--bg-clr2);
  color: var(--body-clr);
  z-index: 100;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  .datepicker-month-wrap {
    @include flex($justify: space-around);
    position: relative;
    width: 100%;
    margin: 0 auto;
  }

  .datepicker-month-wrap div[role="button"] {
    @include size(16px);
  }
}

.datepicker-month {
  order: 1;
  font-size: 16px;
  font-weight: $medium;
  line-height: 18px;
  color: var(--body-clr);
}

// .datepicker-close-wrap {
//   position: absolute;
//   top: 5px;
//   right: 5px;

//   button {
//     width: 30px;
//     height: 30px;
//     font-size: 20px;

//     &.datepicker-close {
//       margin-right: 0;
//     }
//   }
// }

.datepicker-month-fast-prev,
.datepicker-month-fast-next {
    @include bgImg($url: '/img/common/icon/icon_arrow_left_double.png');
//   position: absolute;
//   left: 0;
//   top: 0;
}

.datepicker-month-prev,
.datepicker-month-next {
    @include bgImg($url: '/img/common/icon/icon_arrow_left.png');
  // position: absolute;
  // left: 25px;
  // top: 0;
}

.datepicker-month-next,
.datepicker-month-fast-next {
  transform: rotate(180deg);
  // position: absolute;
  // right: 0;
  // top: 0;
}

.datepicker-month-next {
  order: 2;
}

.datepicker-month-fast-next {
  order: 3;
}

.datepicker-grid {
  width: 100%;
  margin-top: 24px;

  & * {
    float: none !important;
  }

  tr td:first-child:not(.empty, .cur-day, .month),
  tr td:last-child:not(.empty, .cur-day, .month) {
    color: var(--neg-clr);
  }

  th {
    border: 0;
    font-size: 12px;
    font-weight: $medium;
    color: var(--cap-clr);
    text-align: center;
  }

  td {
    height: initial;
    font-size: 16px;
    color: var(--body-clr);
    text-align: center;
    cursor: pointer !important;

    &.cur-day {
      background: var(--main-clr);
      color: var(--bg-clr2);
      border-radius: 50%;
    }

    &.disabled,
    &.empty {
      color: var(--cap-clr);
    }
  }

  th,
  td {
    border: 0 !important;
    padding: 0 !important;
    width: calc(258px / 7) !important;
    height: 35px !important;
    text-align: center !important;
    vertical-align: middle;
  }

  th {
    height: 20px !important;
    padding-bottom: 10px !important;
  }
}

.datepicker-caption {
  height: 1px;
  overflow: hidden;
  opacity: 0;
  color: transparent;
  font-size: 1px;
  line-height: 1px;
  margin: 0 0 -1px;
  padding: 0;
  border: 0;
  background: transparent;
}

/*----------------------------------------*\
  || Calendar
\*----------------------------------------*/

.calendar-area.type01 {
  display: inline-block;

  .input-area .btn-datepicker {
    top: -12px;
    left: 9px;
    width: 22px;
    height: 22px;
  }

  .datepicker-calendar {
    top: 0 !important;
    left: 0 !important;
  }
}

/*----------------------------------------*\
  || Close Button
\*----------------------------------------*/

.datepicker-close-wrap {
  position: absolute;
  top: 16px;
  right: 16px;

  .datepicker-close {
    @include size(14px);
    @include bgImg($url: '/img/common/icon/icon_close_s2.png')
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  .datepicker-calendar {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}
