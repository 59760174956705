@charset "utf-8";

/*----------------------------------------*\
  || App Guide 앱탈퇴가이드
\*----------------------------------------*/

.app-guide-wrap {
  @include flex($align: normal, $gap: 20px, $wrap: wrap);
  margin-top: 65px;
  margin-left: 30px;

  & + & {
    margin-top: 90px;
  }

  .text {
    width: 450px;

    .num {
      position: relative;
      display: inline-block;
      margin-bottom: 12px;
      font-size: 36px;
      line-height: 43px;
      color: var(--main-clr);

      &::after {
        @include size(100%, 3px);
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: var(--main-clr);
        border-radius: 10px;
      }
    }

    .title {
      display: block;
      margin-bottom: 22px;
      font-size: 30px;
      line-height: 34px;
      color: var(--tit-clr);
    }
  }

  .img {
    @include flex;
    margin-top: 55px;

    img {
      max-height: 410px;
    }

    img:last-child {
      display: none;
    }
  }
}

@include respond-to ("huge") {
  .app-guide-wrap {
    flex-direction: column;
  }
}

@include respond-to ("medium") {
  .app-guide-wrap {
    gap: 15px;
    margin-top: 25px;
    margin-left: 0;

    .text {
      width: 100%;

      .num {
        font-size: 26px;
        font-weight: $medium;
        line-height: 31px;

        &::after {
          border-radius: 0;
        }
      }

      .title {
        margin-bottom: 14px;
        font-size: 20px;
        font-weight: $medium;
        line-height: 24px;
      }
    }

    .img {
      margin-top: 0;

      img {
        display: none;
        width: 100%;
        max-height: none;
      }

      img:last-child {
        display: block;
      }
    }
  }
}


/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.sub-newtitle {
  color: var(--body-clr);
  font-size: 22px;
  font-weight: $bold;
}

.info-newsubtxt {
  margin-top: 13px;
  color: var(--body-clr);
  font-size: 16px;
  line-height: 25px;
}

.info-subtxt {
  padding-top: 20px !important;
  font-size: 16px;
  line-height: 20px;
}

.info-address {
  text-decoration: underline;
  display: inline-block;
}

.event-link img {
  display: inline-block;
  width: 100%;
  height: 100%;
}

/*----------------------------------------*\
  || 게시판 BBS
\*----------------------------------------*/

.bbs-top {
  margin: 50px 0 10px;
  overflow: hidden;

  .bbs-info {
    float: left;
    margin-top: 26px;
    font-weight: $regular;
  }

  .total {
    font-size: 14px;
    font-weight: $regular;

    .num {
      font-weight: $bold;
    }
  }
}

.bbs-search {
  display: table;
  float: right;

  &.style01 {
    width: 100%;
    max-width: 370px;
  }
}

.search-box {
  display: table-cell;
  width: 180px;
  padding-right: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;

  .division-box {
    display: table-cell;
    position: relative;
    vertical-align: top;

    .input-basic {
      padding-right: 45px !important;
    }
  }

  .btn-search {
    height: 43px;
    white-space: nowrap;
    text-indent: -10000px;
    overflow: hidden;
    outline-offset: -2px;
  }
}

.bbs-list {
  &::after {
    display: block;
    content: '';
    clear: both;
  }

  .table-style01 {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    th {
      padding: 15px 20px;
      border: 0;
    }

    td {
      padding: 15px 20px;
      background-color: var(--bg-clr2);
    }

    tbody tr {
      td:first-child {
        border-left: none;
      }

      td:last-child {
        border-right: none;
      }
    }

    .ico-category {
      display: inline-block;
      min-width: 47px;
      margin: 0 4px 0 0;
      padding: 6px 8px;
      text-align: center;
    }

    .link {
      display: inline-block;
      font-size: 14px;
      line-height: 22px;
      vertical-align: top;
      font-weight: $regular;
    }
  }
}

.bbs-view {
  margin-top: 50px;
}

.tts + .bbs-view {
  margin-top: 20px;
}

.common-text01 + .bbs-view {
  margin-top: 40px;
}

.bbs-head-wrap {
  padding: 19px 33px 18px 20px;
  background-color: #f7f7f7;
  border-radius: 14px;
}

.bbs-head {
  @include flex;
  position: relative;

  // &::after {
  //   display: block;
  //   content: '';
  //   clear: both;
  // }

  &.style01 {
    // &::after {
    //   content: " ";
    //   display: block;
    //   height: 0;
    //   clear: both;
    //   visibility: hidden;
    // }

    .title {
      float: left;
      width: 1010px;
      color: var(--body-clr);
      font-size: 20px;
      font-weight: $bold;

      .badge {
        margin-right: 5px;
        vertical-align: 2px;
      }
    }

    .date {
      display: inline-block;
      vertical-align: middle;
      font-weight: $regular;
    }

    .snsbox {
      display: inline-block;
      vertical-align: middle;
      width: fit-content;
      height: 22px;

      .snslink-view {
        display: block;
        overflow: hidden;
        width: 64px;
        height: 30px;
        margin-top: -3px;
        background: url('/img/common/icon_share.png') no-repeat right 20px top;
        font-size: 0;
        line-height: 0;
      }

      .tooltip-layerpopup {
        display: none;
        position: absolute;
        top: 50px;
        right: -48px;
        width: 381px;
        height: 148px;
        padding: 40px 0;
        border: 1px solid #000;
        text-align: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background: var(--bg-clr2);

        &::after {
          position: absolute;
          top: -9px;
          right: 67px;
          content: " ";
          display: block;
          width: 15px;
          height: 9px;
          background: url('/img/common/bg_tooltip.gif') no-repeat 0 0;
        }

        .kakao {
          background: url('/img/common/bul_kakao_off.png') no-repeat 50% 0
        }

        .instagram {
          background: url('/img/common/bul_insta_off.png') no-repeat 50% 0
        }

        .facebook {
          background: url('/img/common/bul_facebook_off.png') no-repeat 50% 0
        }

        .urlcopy {
          background: url('/img/common/bul_url_off.png') no-repeat 50% 0
        }

        .sns {
          display: inline-block;
          width: 79px;
          padding-top: 65px;
          text-align: center;
          font-size: 12px;
          font-weight: $bold;
          color: #666;
        }

        .tooltip-layerpopup-close {
          background: url('/img/common/bg_close.png');
          font-size: 0;
        }
      }

      .tooltip-layerpopup-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 10px;
        height: 10px;
        background: #3399ff;
        z-index: 100;
      }
    }
  }

  .subject {
    font-size: 14px;
    font-weight: $bold;
    vertical-align: bottom;
  }

  .ico-category {
    display: inline-block;
    width: auto;
    margin-right: 12px;
    padding: 2px 6px 0;
    line-height: 22px;
    text-align: center;

    .subject {
      overflow: hidden;
      width: 100%;
      padding-right: 254px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: $bold;
      line-height: 22px;
      vertical-align: bottom;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;

      &.type01 {
        padding-right: 142px;
      }

      &.type02 {
        padding-right: 0;
      }

      &.type03 {
        padding-right: 320px;
      }

      &.type04 {
        padding-right: 80px;
      }
    }
  }

  .bbs-headinfo {
    margin-left: auto;
    min-width: fit-content;
  }

  .views {
    margin-left: 40px;
  }

  .name {
    overflow: hidden;
    width: 190px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 20px;
  }

  .name,
  .date,
  .views {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    vertical-align: bottom;
  }
}

.bbs-headinfo .snsbox,
.product-list-l .snsbox {
  position: relative;
  float: right;
  right: -22px;
  width: 64px;

  .snslink-view {
    display: block;
    overflow: hidden;
    width: 64px;
    height: 30px;
    margin-top: -3px;
    background: url('/img/common/icon_share.png') no-repeat right 20px top;
    font-size: 0;
    line-height: 0;
  }

  .tooltip-layerpopup {
    display: none;
    position: absolute;
    top: 50px;
    right: -48px;
    width: 300px;
    height: 148px;
    padding: 40px 0;
    border: 1px solid #000;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: var(--bg-clr2);

    &::after {
      position: absolute;
      top: -9px;
      right: 67px;
      content: " ";
      display: block;
      width: 15px;
      height: 9px;
      background: url('/img/common/bg_tooltip.gif') no-repeat 0 0;
    }
  }

  .tooltip-layerpopup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 17px;
    height: 17px;
    padding: 5px;
    z-index: 100;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.bbs-headinfo .bbs-head.style01:after,
.product-list-l .bbs-head.style01:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.ico-category.ico-notice.type01 {
  margin-right: 15px;
}

.bbs-cont {
  min-height: 250px;
  padding: 25px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: var(--bg-clr2);
  font-size: 14px;
  line-height: 25px;
  font-weight: $regular;

  * {
    line-height: 150%;
  }

  th,
  td {
    border: 1px solid #000;
    font-size: 12px;
    line-height: 1.4;
    text-align: center;
  }

  &.type02 {
    position: relative;
  }

  .event-button-box {
    position: absolute;
    top: 25px;
    left: 20px;
    right: 20px;
  }

  .common-layerpopup__title {
    text-align: left;
  }
}

.bbs-nav {
  margin-top: 50px;

  a {
    display: inline-block;
    width: 100%;
    padding-right: 235px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    font-weight: $regular;
  }

  .ico-category {
    width: auto;
    margin-right: 12px;
    padding: 0 6px;
  }

  .link {
    display: inline-block;
    width: auto;
    font-size: 14px;
    line-height: 22px;
  }

  .table-wrap01 {
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
  }

  .table-type04 th {
    width: 20%;
    font-weight: $bold;
  }
}

.snsbox {
  position: relative;
  float: right;
  right: -22px;
  width: 64px;

  .snslink-view {
    display: block;
    overflow: hidden;
    width: 64px;
    height: 30px;
    margin-top: -3px;
    background: url('/img/common/icon_share.png') no-repeat right 20px top;
    font-size: 0;
    line-height: 0;
  }

  .tooltip-layerpopup {
    display: none;
    position: absolute;
    top: 50px;
    right: -48px;
    width: 300px;
    height: 148px;
    padding: 40px 0;
    border: 1px solid #000;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: var(--bg-clr2);

    &::after {
      position: absolute;
      top: -9px;
      right: 67px;
      content: " ";
      display: block;
      width: 15px;
      height: 9px;
      background: url('/img/common/bg_tooltip.gif') no-repeat 0 0;
    }

    .kakao {
      background: url('/img/common/bul_kakao_off.png') no-repeat 50% 0
    }

    .instagram {
      background: url('/img/common/bul_insta_off.png') no-repeat 50% 0
    }

    .facebook {
      background: url('/img/common/bul_facebook_off.png') no-repeat 50% 0
    }

    .urlcopy {
      background: url('/img/common/bul_url_off.png') no-repeat 50% 0
    }

    .sns {
      display: inline-block;
      width: 79px;
      padding-top: 65px;
      text-align: center;
      font-size: 12px;
      font-weight: $bold;
      color: #666;
    }

    .tooltip-layerpopup-close {
      background: url('/img/common/bg_close.png');
      font-size: 0;
    }
  }
}

.tooltip-layerpopup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  background: #3399ff;
  z-index: 100;
}

.common-viewdetail {
  padding: 40px 20px;
  border-bottom: 1px solid var(--line-clr3);
  color: #666;
  font-size: 16px;
  line-height: 30px;

  p {
    margin-top: 35px;
    color: #666;
    font-size: 16px;
    line-height: 30px;

    &:first-child {
      margin: 0;
    }
  }
}

.product-list-l .snsbox {
  right: -5px;
}

/*----------------------------------------*\
  || 고객센터 / FAQ
\*----------------------------------------*/

.result-none-area {
  width: 100%;
  height: auto;
  padding: 125px 0 65px;
  text-align: center;
  background: var(--bg-clr2) 50% 64px/auto no-repeat;
  font-size: 16px;
  font-weight: $regular;

  &.success {
    background-image: url('/img/common/icon_alert.png');
  }
}

.input-timer span {
  position: absolute;
  top: 0;
  right: 295px;
  color: #cd0606;
}

/*----------------------------------------*\
  || Notice
\*----------------------------------------*/

.notice-content-wrap {
  padding-top: 50px;
}

.notice-cont-top {
  width: 100%;
  padding: 25px 20px;
  border-top: 1px solid var(--body-clr);
  background: #f7f7f7;

  span {
    font-size: 14px;
  }
}

.notice-cont-box {
  padding: 30px 20px;
  border-top: 1px solid var(--disable-clr);
  border-bottom: 1px solid var(--disable-clr);
  min-height: 288px;
}

.notice-content {
  max-width: 100%;

  span {
    line-height: 25px;
    font-size: 14px;
  }
}

.box-provisio {
  .slide-box-txt {
    min-height: 324px;
  }

  .sub-title {
    margin: 14px 0px;
    color: var(--body-clr);
  }
}

/*----------------------------------------*\
  || SNS Box
\*----------------------------------------*/

.snsbox .tooltip-layerpopup {
  .kakao {
    background: url('/img/common/bul_kakao_off.png') no-repeat 50% 0
  }

  .instagram {
    background: url('/img/common/bul_insta_off.png') no-repeat 50% 0
  }

  .facebook {
    background: url('/img/common/bul_facebook_off.png') no-repeat 50% 0
  }

  .urlcopy {
    background: url('/img/common/bul_url_off.png') no-repeat 50% 0
  }

  .sns {
    display: inline-block;
    width: 79px;
    padding-top: 65px;
    text-align: center;
    font-size: 12px;
    font-weight: $bold;
    color: #666;
  }

  .tooltip-layerpopup-close {
    background: url('/img/common/bg_close.png');
    font-size: 0;
  }
}

/*----------------------------------------*\
  || Event
\*----------------------------------------*/

.event-info {
  margin-top: 40px;

  dt,
  dd {
    display: inline-block;
    margin-top: 15px;
    vertical-align: top;
  }

  .title {
    width: 7%;
    margin-top: 19px;
    margin-right: 15px;
    font-size: 14px;
    font-weight: $bold;
    color: var(--body-clr);

    &.type01 {
      margin-top: 22px;
    }
  }

  .text {
    width: 90%;
    font-size: 14px;
    color: var(--body-clr);
    line-height: 25px;

    strong {
      font-weight: $bold;
    }
  }

  .text.type01,
  .title.type01 {
    margin-top: 18px;
    vertical-align: top;
  }

  .btn-sm-wh {
    margin-left: 15px;
  }
}

.event-list li {
  font-size: 14px;
  color: var(--body-clr);
  line-height: 28px;
  font-weight: $regular;
}

@include respond-to ("huge") {
  html.responsive {

    .bbs-view {
      margin-top: 20px;
    }

    .bbs-nav {
      margin-top: 0;
    }

    .bbs-top {
      margin-top: 10px;
    }

    .bbs-head {
      .name {
        display: block;
      }

      .subject {
        margin-top: 5px;
        padding: 0;
      }

      .bbs-headinfo {
        float: none;
        margin-top: 5px;
      }

      &.style01 .snsbox {
        position: absolute;
        right: 10px;
        top: 26px;
      }
    }

    .event-info {
      .title {
        width: 100%;
      }

      .text {
        width: 100%;
        margin-top: 5px;
        color: #666;
      }

      dd:first-of-type {
        margin-top: 5px;
      }

      .btn-sm-wh {
        margin: 0;
      }
    }
  }

  .snsbox .tooltip-layerpopup {
    right: -3px;
    width: 155px;
    height: 62px;
    padding: 19px 0 40px 0;

    &:after {
      right: 20px
    }

    .kakao,
    .instagram,
    .facebook,
    .urlcopy {
      background-size: 80%;
      line-height: 0;
    }

    .sns {
      width: 32px;
      padding-top: 34px;
      font-size: 0;
    }
  }

  .bbs-head.style01 .snsbox .tooltip-layerpopup:after {
    right: 13px;
  }
}

@include respond-to ("medium") {
  .bbs-view {
    margin-top: 0;
  }

  .bbs-top {
    margin-top: 10px;
  }

  .bbs-head-wrap {
    padding: 15px;
  }

  .bbs-head {
    flex-direction: column;
    align-items: normal;

    .subject {
      @include flex;
      padding: 0;
    }

    .ico-category {
      min-width: fit-content;
      margin-top: 0;
      margin-bottom: auto;
    }

    .bbs-headinfo {
      margin: 5px auto 0 0;
    }

    .views {
      margin-left: 20px;
    }

    .snsbox {
      display: none;
    }
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  .bbs-search.style01 {
    width: auto;
  }
}
