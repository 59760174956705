@charset "utf-8";

/*----------------------------------------*\
  || Basic Frame
\*----------------------------------------*/

.basic-frame {
  position: relative;
  width: 100%;
  margin: 20px auto;
  padding: 30px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--line-clr3);
  border-radius: 10px;

  &:first-child {
    margin-top: 0;
  }

  &.on {
    background-color: var(--bg-clr2);
    border: 2px solid var(--main-clr);
  }

  &--compact {
    padding: 20px;
  }

  &--shadow {
    border: 0;
    box-shadow: 0 0 12px rgba(0, 0, 0, .06);
  }

  .basic-line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background-color: var(--line-clr3);
    border: 0;
  }

  [aria-expanded=true] .arrow-span::after {
    background-image: url("/img/content/main/icon_down.png");
    background-color: var(--bg-clr2);
    border-color: var(--disable-clr);
    transform: scaleY(-1);
  }
}

.basic-frame-inner {
  position: relative;
  width: 100%;
  padding: 20px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--line-clr3);
  border-radius: 10px;

  & + & {
    margin-top: 20px;
  }

  &.no-gap {
    padding: 100px 50px 50px;

    .basic-tab-list {
      position: absolute;
      inset: 0;
      bottom: initial;
    }
  }

  &.no-line {
    border: none;
  }

  .basic-table tbody tr:last-child {
    border: 0;

    th,
    td {
      padding-bottom: 0;
    }
  }
}

@include respond-to ("medium") {
  .basic-frame-inner {
    padding: 15px;
    overflow: auto;
  }
}

/*----------------------------------------*\
  || Tab Frame
\*----------------------------------------*/

.tab-frame {
  position: relative;
  width: 100%;
  margin: 20px auto;
  padding: 10px 20px 0;
  background-color: var(--bg-clr2);
  border: 1px solid var(--line-clr3);
  border-radius: 14px;
}

/*----------------------------------------*\
  || Basic Wide Box
\*----------------------------------------*/

.basic-wide-box {
  width: 910px;
  margin: auto;
}

/*----------------------------------------*\
  || Basic Info Box
\*----------------------------------------*/

.basic-info-box {
  @include flex($direction: column, $gap: 20px);
  margin: auto auto 40px;
  text-align: center;

  .img {
    @include size(100px);

    &.size-s {
      @include size(80px);
      margin-bottom: 10px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: $bold;
    line-height: 28px;
    color: var(--main-clr);
    word-break: auto-phrase;
  }

  .des {
    font-size: 16px;
    line-height: 19px;
    color: #666;
    word-break: auto-phrase;
  }
}

/*----------------------------------------*\
  || Center Box
\*----------------------------------------*/

.center-box {
  @include flex($direction: column);
  @include size(100%, auto);
  margin: auto;
  padding: 100px 0;
  text-align: center;

  .img {
    @include size(56px);
    margin-bottom: 20px;
  }

  .title {
    font-size: 24px;
    font-weight: $bold;
    line-height: 28px;
    color: var(--tit-clr);
  }

  .text {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
    color: #666;
  }
}

.dim-contents .center-box {
  padding: 50px 0 30px;
}

/*----------------------------------------*\
  || Background Color Box
\*----------------------------------------*/

.shadow-box {
  padding: 22px 20px;
  background: var(--bg-clr2);
  border-radius: 7px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.13);
}

.white-box {
  padding: 20px;
  border-radius: 6px;
  background-color: var(--bg-clr2);
}

.grey-box {
  padding: 15px 0;
  border-radius: 5px;
  background-color: #f8f8f8;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--cap-clr);
}

.grey-box02 {
  background: #f7f8fa;
  text-align: center;
  padding: 25px;
  line-height: 20px;
  border-radius: 12px;
}

.grey-box03 {
  background: #fcfcfc;
  text-align: center;
  padding: 30px;
  border-radius: 14px;
}

.grey-box04 {
  @include flex($justify: center);
  background: #f2f4f6;
  padding: 20px;
  border-radius: 14px;
}

.mint-box {
  background: #effaf8;
  border-radius: 12px;
  padding: 25px;
  line-height: 20px;
  text-align: center;
  color: var(--tit-clr);
}

.grey-box02 p,
.mint-box p {
  font-size: 18px;
  line-height: 1.3;
}


/*----------------------------------------*\
  || Info Empty Box
\*----------------------------------------*/

.info-empty-box {
  @include bgImg($url: '/img/common/icon/icon_info.png', $size: 46px, $y: top);
  margin: 60px auto;
  padding-top: 76px;
  text-align: center;

  &.red {
    background-image: url('/img/common/icon/icon_info_red.png');
  }

  p + p {
    margin-top: 10px;
  }

  .title {
    font-size: 18px;
    font-weight: $bold;
    line-height: 24px;
    color: var(--tit-clr);
  }

  .txt {
    font-size: 16px;
    line-height: 130%;
  }

  .des {
    font-size: 14px;
    line-height: 130%;
    color: #666;
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.border-box {
  margin: 10px 0;
  padding: 50px 30px;
  background: var(--bg-clr2);
  border: 15px solid var(--bg-clr1);
  box-shadow: 0 0 1px var(--disable-clr);
}

/*----------------------------------------*\
  || Info Box
\*----------------------------------------*/

.info-box {
  position: relative;
  display: table;
  width: 100%;
  margin: 30px 0;
  padding: 30px 25px;
  background-color: var(--bg-clr1);
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 14px;

  &:has(.left) {
    margin: 10px 0 0;
    padding: 0;
    background: none;
    border: 0;
  }

  .left {
    display: table-cell;
    width: 50%;
    vertical-align: bottom;
  }

  .right {
    display: table-cell;
    width: 50%;
    vertical-align: bottom;
    text-align: right;
  }

  .text {
    display: block;
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
    color: #666;

    &:first-child {
      margin-top: 0;
    }

    & + .text {
      margin-top: 3px;
    }
  }

  .select-basic {
    width: 130px;
    margin-top: 35px;
  }
}
