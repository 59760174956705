@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || 공과금 : 납부고지서
\*----------------------------------------*/

.document-area {
  border: 1px solid var(--disable-clr);
  padding: 40px;
}

.docu-title-area {
  position: relative;
  width: 100%;
  text-align: center;
}

.mark {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px 10px;
  border: 1px solid var(--neg-clr);
  border-radius: 3px;
  font-size: 11px;
  font-weight: $medium;
}

.docu-title {
  font-weight: $medium;
  font-size: 20px !important;

  &.top {
    margin-bottom: 40px;
  }

  &.btm {
    margin: 40px 0 15px;
    text-align: center;
    font-size: 18px !important;

    .docu-title {
      font-size: 18px;
    }
  }
}

.sign-area .com-td {
  display: table;
  font-size: 14px;

  > span {
    display: table-cell;
    vertical-align: middle;
  }

  .name {
    padding-right: 20px;

    &.name02 {
      text-align: right;
      line-height: 1.5;
    }
  }

  .sign {
    width: 78px;
    height: 78px;
    font-size: 12px;
    font-weight: $medium;
    text-align: center;
    border: 2px dashed #c00;
    border-radius: 50%;
    position: relative;

    > span {
      display: block;
      margin-bottom: 7px;
      font-size: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .date02 {
      font-weight: $medium;
      margin-bottom: 12px;
    }

    .bank {
      word-break: keep-all;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

/*----------------------------------------*\
  || 공과금 : 지방세 납부
\*----------------------------------------*/

.table-infolist {
  padding-top: 10px;

  li {
    float: left;
    font-size: 13px;

    &:last-child {
      position: relative;
      margin-left: 15px;
      padding-left: 15px;

      &::before {
        position: absolute;
        top: 1px;
        /*20190530  top:3에서 1로 수정*/
        left: 0;
        content: '';
        display: inline-block;
        width: 1px;
        height: 10px;
        background: var(--body-clr);
      }
    }
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

// @include respond-to ("huge") {
//   html.responsive {}
// }
