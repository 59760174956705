@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || 프리뱅킹 안내
\*----------------------------------------*/


.free-info-wrap {
  border-top: 1px solid var(--body-clr);

  .free-info {
    display: table;
    width: 100%;
    border-top: 1px solid var(--disable-clr);

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: 1px solid var(--disable-clr);
    }

    li {
      display: table-cell;
      height: 100px;
      font-size: 14px;
      vertical-align: middle;
    }

    .bank-name {
      width: 290px;
      padding-left: 22px;
      border-right: 1px solid var(--disable-clr);
    }

    .bank-address {
      padding-left: 29px;

      .number {
        margin-top: 30px;

        .tel {
          display: inline-block;
          width: 400px;
        }
      }
    }
  }
}

/*----------------------------------------*\
  || 상품공시이용메뉴얼 / 뱅킹관리 안내 / 보안센터 안내 / 프리뱅킹 안내 / 평생계좌서비스 안내
\*----------------------------------------*/

.product-manual-top {
  padding: 59px 273px 51px 41px;
  margin-top: 40px;
  background: #eeeff1 url('/img/hss_content/bg_hand.png') no-repeat right 50%;
  color: var(--body-clr);
  font-size: 22px;
  font-weight: $bold;
  line-height: 36px;
  border-radius: 20px;

  &:first-child {
    margin-top: 0;
  }

  .str {
    display: inline-block;
    color: var(--main-clr);
    font-weight: $bold;
  }

  &.bank-info {
    padding: 59px 385px 51px 41px;
    background: #eeeff1 url('/img/common/bg_notebook.png') no-repeat right 50%;
    color: var(--body-clr);
    font-weight: $bold;
    line-height: 28px;

    .str {
      display: inline-block;
      color: var(--main-clr);
      font-size: 22px;
      font-weight: $bold;
      line-height: 36px;
    }
  }

  &.security-info {
    padding: 59px 385px 51px 41px;
    background: #eeeff1 url('/img/common/bg_key.png') no-repeat right 50%;
    color: var(--body-clr);
    font-weight: $bold;
    line-height: 28px;

    .str {
      display: inline-block;
      color: var(--main-clr);
      font-size: 22px;
      font-weight: $bold;
      line-height: 36px;
    }
  }

  &.free-info {
    font-weight: $regular;
  }

  &.lifetime-info {
    padding: 40px 273px 38px 41px;
    background: #eeeff1;
  }

  &.koreatuja-bank .str {
    display: inline-block;
    color: var(--main-clr);
    font-size: 22px;
    font-weight: $bold;
    line-height: 36px;
  }
}

.product-manual-box {
  margin-top: 50px;
  border-top: 1px solid #000;

  li {
    padding: 30px 0 30px 220px;
    min-height: 180px;
    border-bottom: 1px solid #efefef;

    &:first-child {
      background: url('/img/hss_content/bg_manualimg01.png') no-repeat 0px 50%;
    }

    &:nth-child(2) {
      background: url('/img/hss_content/bg_manualimg02.png') no-repeat 0px 50%;
    }

    &:nth-child(3) {
      background: url('/img/hss_content/bg_manualimg03.png') no-repeat 0px 50%;
    }

    &:nth-child(4) {
      background: url('/img/hss_content/bg_manualimg04.png') no-repeat 0px 50%;
    }

    &:nth-child(5) {
      background: url('/img/hss_content/bg_manualimg05.png') no-repeat 0px 50%;
    }

    &:nth-child(6) {
      background: url('/img/hss_content/bg_manualimg06.png') no-repeat 0px 50%;
      border-bottom: 1px solid var(--disable-clr);
    }

    .text {
      font-size: 16px;
      color: var(--body-clr);
      font-weight: $bold;
      line-height: 25px;
    }

    .shortcut {
      display: inline-block;
      margin-top: 16px;
      font-size: 14px;
      color: var(--body-clr);

      & + .shortcut {
        margin-left: 20px;
      }
    }

    .link {
      display: inline-block;
      width: 72px;
      margin-left: 20px;
      padding: 5px 10px;
      border: 1px solid var(--disable-clr);
    }
  }

  &.bank-info li {
    padding: 30px 0 30px 220px;
    min-height: 180px;
    border-bottom: 1px solid #efefef;


    &:first-child {
      background: url('/img/common/bg_bank_info01.png') no-repeat 0px 50%;
    }

    &:nth-child(2) {
      background: url('/img/common/bg_bank_info02.png') no-repeat 0px 50%;
    }

    &:nth-child(3) {
      background: url('/img/common/bg_bank_info03.png') no-repeat 0px 50%;
    }

    &:nth-child(4) {
      background: url('/img/common/bg_bank_info04.png') no-repeat 0px 50%;
    }

    &:nth-child(5) {
      background: url('/img/common/bg_bank_info05.png') no-repeat 0px 50%;
    }

    &:nth-child(6) {
      background: url('/img/common/bg_bank_info06.png') no-repeat 0px 50%;
    }

    &:nth-child(7) {
      background: url('/img/common/bg_bank_info07.png') no-repeat 0px 50%;
    }

    &:nth-child(8) {
      background: url('/img/common/bg_bank_info08.png') no-repeat 0px 50%;
    }

    &:nth-child(9) {
      background: url('/img/common/bg_bank_info09.png') no-repeat 0px 50%;
      border-bottom: 1px solid var(--disable-clr);
    }
  }

  &.security-info li {
    &:first-child {
      background: url('/img/common/bg_security_info01.png') no-repeat 0px 50%;
    }

    &:nth-child(2) {
      background: url('/img/common/bg_security_info02.png') no-repeat 0px 50%;
    }

    &:nth-child(3) {
      background: url('/img/common/bg_security_info03.png') no-repeat 0px 50%;
    }

    &:nth-child(4) {
      background: url('/img/common/bg_security_info04.png') no-repeat 0px 50%;
    }

    &:nth-child(5) {
      background: url('/img/common/bg_security_info05.png') no-repeat 0px 50%
    }

    &:nth-child(6) {
      background: url('/img/common/bg_security_info06.png') no-repeat 0px 50%;
      border-bottom: 1px solid var(--disable-clr);
    }
  }

  &.koreatuja-bank {
    padding: 59px 385px 51px 41px;
    background: #eeeff1 url('/img/hss_content/bg_hand.png') no-repeat right 50%;
    color: var(--body-clr);
    font-size: 16px;
    font-weight: $bold;
    line-height: 28px;

    li {
      &:first-child {
        background: url('/img/hss_content/bg_manualimg02.png') no-repeat 0px 50%;
      }

      &:nth-child(2) {
        background: url('/img/hss_content/bg_manualimg03.png') no-repeat 0px 50%;
      }

      &:nth-child(3) {
        background: url('/img/hss_content/bg_manualimg04.png') no-repeat 0px 50%;
      }

      &:nth-child(4) {
        background: url('/img/hss_content/bg_manualimg05.png') no-repeat 0px 50%;
      }

      &:nth-child(5) {
        background: url('/img/hss_content/bg_manualimg06.png') no-repeat 0px 50%;
        border-bottom: 1px solid var(--disable-clr);
      }
    }
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  // html.responsive {}

  .product-manual-top.koreatuja-bank {
    padding: 33px 41px 29px 41px;
    background-image: none;
    font-size: 14px;
    line-height: 23px;

    .str {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
    }
  }

  .product-manual-box {
    margin-top: 20px;

    &.koreatuja-bank li {
      &:first-child {
        background-position: 50% 20px;
      }

      &:nth-child(2) {
        background-position: 50% 20px;
      }

      &:nth-child(3) {
        background-position: 50% 20px;
      }

      &:nth-child(4) {
        background-position: 50% 20px;
      }

      &:nth-child(5) {
        background-position: 50% 20px;
        border-bottom: 1px solid var(--disable-clr);
      }
    }
  }

  .product-manual-box li,
  .product-manual-box.bank-info li,
  .product-manual-box.security-info li {
    min-height: auto;
    padding: 20px;
    background: none !important;

    // &:first-child {
    //   background-position: 50% 20px;
    // }

    // &:nth-child(2) {
    //   background-position: 50% 20px;
    // }

    // &:nth-child(3) {
    //   background-position: 50% 20px;
    // }

    // &:nth-child(4) {
    //   background-position: 50% 20px;
    // }

    // &:nth-child(5) {
    //   background-position: 50% 20px;
    // }

    // &:nth-child(6) {
    //   background-position: 50% 20px;
    //   border-bottom: 1px solid var(--disable-clr);
    // }

    .shortcut {
      display: block;

      & + .shortcut {
        margin-left: 0;
      }

      .link {
        display: block;
        margin: 10px 0 0 0;
      }
    }
  }

  // .product-manual-box.bank-info li {
  //   &:first-child {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(2) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(3) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(4) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(5) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(6) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(7) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(8) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(9) {
  //     background-position: 50% 20px;
  //     border-bottom: 1px solid var(--disable-clr);
  //   }
  // }

  // .product-manual-box.security-info li {
  //   &:first-child {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(2) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(3) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(4) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(5) {
  //     background-position: 50% 20px;
  //   }

  //   &:nth-child(6) {
  //     background-position: 50% 20px;
  //     border-bottom: 1px solid var(--disable-clr);
  //   }
  // }
}

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  .product-manual-top {
    padding: 33px 41px 29px 41px;
    background-image: none;
    font-size: 18px;
    line-height: 31px;

    &.bank-info {
      padding: 33px 41px 29px 41px;
      background-image: none;
      font-size: 14px;
      line-height: 23px;

      .str {
        font-size: 18px;
        font-weight: $bold;
        line-height: 26px;
      }
    }

    &.security-info {
      padding: 33px 41px 29px 41px;
      background-image: none;
      font-size: 14px;
      line-height: 23px;

      .str {
        font-size: 18px;
        font-weight: $bold;
        line-height: 26px;
      }
    }

    &.koreatuja-bank {
      padding: 33px 41px 29px 41px;
      background-image: none;
      font-size: 14px;
      line-height: 23px;

      .str {
        font-size: 18px;
        font-weight: $bold;
        line-height: 26px;
      }
    }
  }
}
