@charset "utf-8";

/* 대출모집법인 추가  */

.layout-wrap2 {
  width: 100%;
}

.header-wrap-cor {
  background: #fff;
  border-bottom: 1px solid #eee;
  box-shadow: 0 0 5px #eee;
}

.header-container-cor {
  width: 1200px;
  height: 100%;
  margin: auto;
}

.header-container-cor-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
}

.header-container-cor-top .name,
.header-container-cor-top .name span {
  font-size: 14px;
}

.header-container-cor-top .logout {
  margin-left: 15px;
  background: #1a61b3;
  padding: 8px 10px;
  font-size: 12px;
  color: #fff;
}

.header-container-cor-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-list {
  display: flex;
  gap: 50px;
  align-items: center;
  height: 100%;
}

.nav-link {
  font-size: 21px;
  line-height: 120px;
  font-weight: 400;
}

.nav-item {
  transition: all .5s ease;
}

.nav-item:hover {
  border-color: #1a61b3;
}

.nav-item.on {
  border-color: #1a61b3;
}

.nav-item {
  border-bottom: 3px solid transparent;
}

.title-wrap2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 390px;
  background: url(/img/content/cor/bg_cor.png) no-repeat center / cover;
}

.title-wrap2.type01 {
  background-image: url(/img/content/cor/bg_cor01.png);
}

.title-wrap2.type02 {
  background-image: url(/img/content/cor/bg_cor02.png);
}

.title-wrap2.type03 {
  background-image: url(/img/content/cor/bg_cor03.png);
}

.page-title2 {
  margin-top: 110px;
  font-size: 38px;
  font-weight: 400;
}

.container2 {
  width: 1200px;
  margin: -80px auto auto;
}

.basic-box {
  width: 100%;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #eee;
}

.basic-box.type-blue {
  padding: 15px;
  border-color: #1a61b3;
  background: #f8fdff;
  text-align: center;
}

.table-cor {
  margin: 0 50px;
}

.table-cor tr {
  height: 80px;
  border-bottom: 1px solid #eee;
}

.table-cor tr:last-child {
  border-bottom: none;
}

.table-cor th {
  line-height: 80px;
  text-align: left;
  font-size: 14px;
  color: #000;
  vertical-align: middle;
  word-break: keep-all;
  white-space: normal;
}

.table-cor td {
  vertical-align: middle;
}

.table-cor02 {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.table-cor02 thead tr {
  border-bottom: 1px solid #ccc;
}

.table-cor02 tbody tr {
  border-top: 1px solid #eee;
}

.table-cor02 tbody tr:hover {
  background: #f8fdff;
}

.table-cor02 th {
  color: #111;
  vertical-align: middle;
  word-break: keep-all;
  white-space: normal;
}

.table-cor02 th,
.table-cor02 td {
  padding: 15px 10px;
}

.table-cor02 td {
  vertical-align: middle;
}

.table-cor03 {
  position: relative;
  width: 100%;
  margin-top: 15px;
  border: 1px solid #eee;
  border-radius: 14px;
  border-collapse: initial;
  text-align: left;
  overflow: hidden;
}

.table-cor03 tr::after {
  content: '';
  position: absolute;
  left: 15px;
  right: 15px;
  background: #eee;
  height: 1px;
}

.table-cor03 th {
  background: #f8fdff;
  vertical-align: middle;
}

.table-cor03 th,
.table-cor03 td {
  padding: 20px;
  font-size: 14px;
}


.table-cor03 tr:first-child::after {
  display: none;
}

.table-cor03 tr.type-black::after {
  background: #333;
}


.input-cor {
  width: 555px;
  height: 50px;
  padding: 0 15px;
  border: 1px solid #eee;
  border-radius: 7px;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
}

.input-cor:focus {
  border-color: #1a61b3;
  background: #f8fdff;
}

.input-cor.with-btn {
  width: 410px;
  padding-right: 60px;
}

.input-col-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.input-time {
  position: absolute;
  right: 15px;
  top: 20px;
  font-size: 14px;
  color: #ff3e3e;
}

.label-cor {
  position: relative;
  font-size: 14px;
}

.input-cor.type-xxs {
  width: 100px;
}

.input-cor.type-xs {
  width: 130px;
}

.input-cor.type-cal {
  background: url(/img/content/cor/ico_cor_03.png) no-repeat center right 5px;
  cursor: pointer;
}

.radio-cor + .label-cor::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #eee;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}

.radio-cor:checked + .label-cor::before {
  background: #1a61b3;
}

.radio-cor:checked + .label-cor::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  left: 6px;
  top: 6px;
}

.select-cor {
  border: 1px solid #eee;
  border-radius: 7px;
  width: 70px;
  height: 35px;
  padding: 0 10px;
  font-size: 14px;
  background: url(/img/common/icon_select_333.png) no-repeat center right 8px / 15px;
}

.btn-wrap-cor {
  display: flex;
  justify-content: center;
  align-items: center;
}

.basic-box + .btn-wrap-cor {
  margin: 100px 0;
}

.btn-basic-cor {
  width: 140px;
  height: 50px;
  line-height: 50px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 7px;
  font-size: 14px;
  text-align: center;
}

.btn-basic-cor + .btn-basic-cor {
  margin-left: 15px;
}

.btn-basic-cor:hover {
  box-shadow: 0 0 8px #eee;
}

.btn-basic-cor.type-l {
  width: 240px;
}

.btn-small-cor {
  font-size: 14px;
  color: #888;
}

.btn-basic-cor.type-blue {
  background: #315d8f;
  color: #fff;
  border-color: #315d8f;
}

.btn-basic-cor.type-blue2 {
  background: #1a61b3;
  color: #fff;
  border-color: #1a61b3;
}

.btn-basic-cor.type-skyblue {
  background: #f8fdff;
  color: #333;
}

.btn-basic-cor.type-gray {
  background: #f2f2f2;
  color: #1a61b3;
}

.btn-basic-cor.type-xs {
  width: 80px;
  height: 45px;
  line-height: 45px;
  font-size: 12px;
}

.btn-basic-cor.type-s {
  width: 100px;
}

.btn-basic-cor.type-search {
  background: #315d8f;
  color: #fff;
}

.btn-basic-cor.type-search span {
  color: inherit;
}

.btn-basic-cor.type-search span::before {
  content: '';
  position: relative;
  top: -1px;
  width: 18px;
  height: 13px;
  margin-right: 2px;
  display: inline-block;
  background: url('/img/content/cor/ico_search.png') no-repeat center / contain;
  vertical-align: middle;
}

.footer-wrap2 {
  border-top: 1px solid #eee;
}

.footer-container2 {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 30px auto;
  padding-bottom: 50px;
}

.footer-nav-list2 {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  font-size: 14px;
}

.footer-nav-mini-list {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  font-size: 12px;
}

.footer-address2,
.footer-copyright2 {
  color: #666;
  font-style: normal;
  margin-bottom: 7px;
  font-size: 11px;
}

.dim-background {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #000;
  z-index: 900;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.dim-background.on {
  opacity: 0.8;
}

.popup-wrap-cor {
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 364px;
  max-width: 760px;
  background: #fff;
  padding: 30px;
  border-radius: 14px;
}

.popup-wrap-cor-head {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.popup-wrap-cor-title {
  font-size: 20px;
  font-weight: 500;
}

.popup-wrap-cor-btn-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: url(/img/common/btn_close_l.png) no-repeat center / 20px;
}

.popup-tit-cor {
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
}

.popup-box-cor {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background: #f8fdff;
  padding: 20px 15px;
}

.popup-dl-cor {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.popup-dl-cor:last-child {
  margin-bottom: 0;
}

.popup-dt-cor {
  min-width: 80px;
}

.popup-dt-cor,
.popup-dd-cor {
  font-size: 14px;
  line-height: 1.5;
}


.notice-msg-cor {
  margin-top: 15px;
  margin-left: 20px;
  text-indent: -20px;
  font-size: 14px;
  line-height: 1.5;
}

.notice-msg-cor::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  background: url(/img/content/cor/ico_cor_01.png) no-repeat center / contain;
  vertical-align: middle;
}


.basic-box + .page-num-cor {
  margin: 100px auto;
}

.page-num-list-cor {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  gap: 5px;
}

.page-num-cor .page-first,
.page-num-cor .page-prev,
.page-num-cor .page-next,
.page-num-cor .page-last {
  width: 100%;
  height: 100%;
  background: url(/img/common/page_left.png) no-repeat center;
}

.page-num-cor .page-first {
  background-image: url(/img/common/page_first.png)
}

.page-num-cor .page-prev {
  background-image: url(/img/common/page_left.png)
}

.page-num-cor .page-next {
  background-image: url(/img/common/page_right.png)
}

.page-num-cor .page-last {
  background-image: url(/img/common/page_last.png)
}

.page-num-cor .page.on {
  background: #1a61b3;
  color: #fff;
}

.page-num-item-cor {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #eee;
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
}

.file-name-cor {
  color: #666;
  margin-bottom: 10px;
}

.file-name-cor:last-child {
  margin-bottom: 0;
}

.del-btn-cor {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  background: url(/img/content/cor/ico_cor_06.png) no-repeat center;
  vertical-align: text-bottom;
}

/* // 대출모집법인 추가  */
