@charset "utf-8";

.breadcrumb-wrap {
  position: relative;
  background: var(--bg-clr2);
  // border-top: 1px solid #f0f0f3;

  &::after {
    @include size(100%, 2px);
    content: "";
    display: block;
    background: linear-gradient(90deg, var(--main-clr), #33c7c4);
  }
}

.breadcrumb-box {
  @include flex($justify: space-between);
  width: $wrapSize;
  min-height: 56px;
  margin: auto;
  padding: 0;
}

.breadcrumb-list {
  @include flex;

  .breadcrumb-item {
    position: relative;

    & + li::before {
      @include size(1px, 14px);
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      background: #e9e9ec;
      transform: translateY(-50%);
    }

    .home {
      @include size(20px);
      @include bgImg("/img/content/main/icon_home_gray.png");
      margin-right: 20px;
      transition: all 0.2s ease;

      &:hover,
      &:focus {
        filter: brightness(0.8);
      }
    }
  }
}

.breadcrumb-select {
  position: relative;
  width: fit-content;
  min-width: 165px;
  padding: 0 45px 0 20px;
  font-size: 14px;
  line-height: 53px;
  color: var(--tit-clr);
  text-align: left;

  &::after {
    @include size(18px);
    @include bgImg($url: "/img/content/main/icon_down_w.png", $size: 6px);
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    display: block;
    background-color: #7e95b5;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
  }

  &.on {
    font-weight: $bold;

    &::after {
    background-color: #8993a2;
    transform: translateY(-50%) scaleY(-1);
  }
  }
}

.breadcrumb-select-list {
  @include flex($direction: column, $gap: 20px, $align: normal);
  display: none;
  position: absolute;
  top: calc(100% - 10px);
  width: 100%;
  min-width: 165px;
  max-height: 297px;
  padding: 14px;
  background: var(--bg-clr2);
  border: 1px solid var(--main-clr);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;

  &::-webkit-scrollbar {
      display: none;
  }

  @at-root .breadcrumb-select.on + .breadcrumb-select-list {
    display: flex;
  }

  .breadcrumb-select-item {
    a {
      font-size: 14px;
      line-height: 18px;
      color: var(--tit-clr);
    }
  }
}

.breadcrumb-right {
  @include flex;

  .breadcrumb-zoom-title {
    margin-right: 13px;
    font-size: 14px;
    line-height: 16px;
    color: var(--tit-clr);
  }

  .breadcrumb-zoom-button {
    @include size(22px);
    @include bgImg($url: "/img/content/main/icon_plus_w.png", $y: left 9px, $size: 8px);
    background-color: #7e95b5;
    border-radius: 11px 0 0 11px;

    & + button {
      @include bgImg($url: "/img/content/main/icon_minus_w.png", $y: right 9px, $size: 8px);
      margin-left: 1px;
      background-color: #7e95b5;
      border-radius: 0 11px 11px 0;
    }
  }
}

/*----------------------------------------*\
  || Responsive
\*----------------------------------------*/

@include respond-to ("huge") {
  .breadcrumb-box {
    @include responsiveHugeBox;
  }
}

@include respond-to ("medium") {
  .breadcrumb-wrap {
    display: none;
  }
}
