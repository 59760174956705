@charset "utf-8";

/*----------------------------------------*\
  || 보안매체 정보입력
\*----------------------------------------*/

.sec-box-wrap {
  padding: 30px 20px 20px 20px;
  border-top: 1px solid var(--disable-clr);
  border-bottom: 1px solid var(--disable-clr);
}

.sec-box-wrap02 {
  padding: 30px 20px;
  border-top: 1px solid #000;
  border-bottom: none;
}

.sec-table02 {
  display: table;
  width: 100%;
  background: #f7f7f7;
  padding: 45px 75px;
}

.sec-table-row02:first-child {
  margin-bottom: 20px;
}

.sec-table-cell02 {
  display: table-cell;
  vertical-align: middle;

  &:first-child {
    width: 380px;
    font-size: 20px;
  }
}

.sec-box {
  &.left {
    width: 550px;
    padding-right: 30px;
  }

  &.right {
    width: 510px;
    padding-left: 30px;

    .txt-des {
      display: inline-block;
      padding-top: 15px;
      font-size: 11px;
    }
  }

  .notice-i-list {
    display: inline-block;
    color: var(--body-clr);
    line-height: 16px;
    padding: 20px 0 15px 16px;
    margin-left: 0;
  }

  .notice-list li {
    padding-bottom: 0;
    line-height: 28px;
    font-size: 14px;
  }

  .code-img {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
}

.code-img {
  position: relative;

	.mark {
		position: absolute;
		top: 125px;
		left: 405px;
		display: block;
		width: 22px;
		height: 18px;
		background: #f0b2b2;
		border: 1px solid var(--neg-clr);

		&.box02 {
			top: 160px;
			left: 165px;
		}
	}
}

.sec-table {
  display: table;
  width: 100%;
  background: #f7f7f7;
  padding: 55px 75px;

  .sec-table-row:first-child .sec-table-cell {

    .btn-mouse,
    .icon-mouse {
      margin-left: -35px;
    }
  }
}

.sec-table-cell {
  display: table-cell;
  vertical-align: middle;

  &:first-child {
    width: 190px;
    font-size: 20px;
  }

  .btn-mouse,
  .icon-mouse {
    margin-left: -30px;
  }

  .input-basic {
    margin-right: 5px;

    &.double,
    &.single {
      float: left;
      font-size: 22px;
      color: var(--body-clr);
      font-weight: $bold;
      text-align: center;
      padding: 5px 10px;
    }

    &.double {
      max-width: 90px;
      height: 47px;
      text-align: left;
      padding: 5px 30px 5px 12px;
    }

    &.single {
      max-width: 40px;
      height: 47px;

      &:disabled {
        color: var(--body-clr);
      }
    }
  }
}

/*----------------------------------------*\
  || PEY 추가
\*----------------------------------------*/

.attention-wrap {
  @include flex($gap: 10px);
  position: relative;
}

.attention {
  width: 220px;
  font-size: 14px;
  display: block;
  position: absolute;
  left: 50%;
  bottom: -24px;
  transform: translateX(-50%);
}

.warn-area {
  margin-top: 50px;
  padding: 30px;
  background: #f7f7f7 url(/img/common/bg_warning.png) no-repeat right 80px center;

  & + .notice-list-wrap {
    padding-top: 43px;
  }
}

.warn-title {
  font-size: 24px;
  font-weight: $medium;
  margin-bottom: 20px;
}

.warn-txt {
  font-size: 16px;
  line-height: 24px;
}

.bg-content-wrap {
  padding-bottom: 60px;
}

.bg-img-wrap {
  display: table;
  width: 100%;
  margin-top: 40px;
  padding: 60px 20px;
  background-color: #eeeff1;
  text-align: center;

  .img-box {
    display: table-cell;
    vertical-align: middle;
  }
}

.step-list-wrap {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding-top: 60px;
}

.step-list-box {
  position: relative;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;

    &:before {
      display: none;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--disable-clr);
    position: absolute;
    top: 0;
    left: 12px;
    z-index: 0;
  }
}

.step-circle-box {
  width: 280px;
  height: auto;
  background: transparent;
  position: relative;
  float: left;
  min-height: 50px;
  height: auto;
}

.step-circle {
  &:first-child {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 24px;
    height: 34px;
    color: var(--bg-clr2);
    background: var(--bg-clr2);
    text-align: center;
    padding-top: 6px;
    font-size: 16px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: -1;
    width: 24px;
    height: 24px;
    background: var(--body-clr);
    transform: translateX(-50%);
    border-radius: 50%;
  }
}

.step-list-content {
  width: 820px;
  display: inline-block;
  padding-bottom: 48px;

  .img-list {
    display: flex;
    justify-content: space-between;

    li {
      &:last-child {
        margin-right: 0;
      }

      img {
        display: inline-block;
        width: 100%;
        border: 1px solid var(--disable-clr);
      }
    }
  }

  .img-type2 li {
    width: 100%;
    height: auto;
  }

  .img-type3 {
    li {
      width: 100%;
      height: auto;
      float: left;
      margin-top: 20px;
      margin-right: 20px;

      &:frist-child {
        margin-top: 0;
      }
    }
  }

  .img-type4 li:first-child {
    width: 100%;
    height: auto;
  }

  .img-list li,
  .img-type2 li,
  .img-type4 li {
    width: 100%;
    margin-right: 20px;
  }

  .img-title {
    text-align: center;
    display: block;
    padding-top: 16px;
    font-size: 16px;
  }

  .notice-list li::before {
    margin-top: 4px;
  }
}

.step-txt02 {
  font-weight: $medium;
  padding-left: 40px;
  display: block;
  margin-bottom: 16px;
  padding-top: 4px;
  font-size: 18px;
}

.step-subtxt {
  display: block;
  padding-bottom: 10px;
  padding-left: 40px;
  font-size: 16px;
}

.img-content {
  .img-circle {
    width: 20px;
    display: inline-block;
    height: 20px;
    border-radius: 50%;
    background: #7a8999;
    color: var(--bg-clr2);
    text-align: center;
    padding-top: 4px;
    vertical-align: top;
    margin-right: 10px;
    font-size: 14px;
  }

  .img-subtxt {
    display: inline-block;
    line-height: 22px;
    width: calc(100% - 30px);
    font-size: 16px;
  }
}

.page-title {
  .title-arrow {
    font-size: 48px;
    font-weight: $bold;
    word-break: keep-all;

    &:after {
      content: '';
      float: right;
      width: 60px;
      height: 40px;
      background: url(/img/common/icon_titlearrow.png) no-repeat center center;
    }
  }
}

.sub-title-area .sub-infotxt {
  font-size: 11px;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .bg-content-wrap {
      padding: 15px 16px 0;
      background-color: var(--bg-clr2);
    }

    .bg-img-wrap {
      .img-box img {
        width: 100%;
      }

      > img {
        width: 100%;
      }
    }

    .step-list-wrap {
      margin-top: 15px;
      padding: 50px 16px 0;
      background-color: var(--bg-clr2);
    }

    .step-list-box:before {
      display: none;
    }

    .step-circle:first-child {
      background: initial;
    }

    .step-txt02,
    .step-subtxt {
      display: inline-block;
    }

    .step-subtxt {
      padding-left: 15px;
    }

    .step-list-content {
      width: 100%;
    }
  }
}
