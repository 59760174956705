@charset "utf-8";

/*----------------------------------------*\
  || 팝업 리뉴얼
\*----------------------------------------*/

.common-layerpopup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 280px;
  width: 100%;
  max-width: 320px;
  padding: 24px 12px 12px;
  background-color: #fff;
  border-radius: 14px;
  outline: none;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.0784313725);
  transform: translate3d(-50%, -50%, 0);
  transform-origin: 50% 50%;
  z-index: 1000;
  will-change: auto;
  backface-visibility: hidden;

  &--system {
    width: 540px;
    max-width: none;
    padding: 20px 0 0;
    border-radius: 0;
  }

  &__content {
    padding: 30px;
  }

  &__title {
    margin-bottom: 25px;
    font-size: 28px;
    font-weight: $bold;
    line-height: 130%;
    text-align: center;

    small {
      font-size: .8em;
      font-weight: inherit;
      line-height: inherit;
    }
  }

  &__text {
    font-size: 15px;
    line-height: 150%;
    color: var(--cap-clr);
    word-break: auto-phrase;

    & + & {
      margin-top: 8px;
    }
  }

  &__list {
    margin-top: 25px;

    .name {
      margin-top: 18px;
      font-size: 18px;
      font-weight: $bold;
      line-height: 120%;

      &:first-child {
        margin-top: 0;
      }
    }

    .value {
      margin-top: 12px;
      font-size: 16px;
      line-height: 150%;
      color: var(--cap-clr);
      word-break: auto-phrase;
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 15px 30px;
    background-color: #373d5c;

    &-button {
      font-size: 14px;
      font-weight: $medium;
      color: #fff;
    }
  }
}

@include respond-to ("medium") {
  .common-layerpopup {
    &--system {
      min-width: 280px;
      width: 100%;
      max-width: 320px;
      padding: 0;
      border-radius: 0;
    }

    &__content {
      padding: 30px 20px 28px;
    }

    &__title {
      margin-bottom: 16px;
      font-size: 18px;
    }

    &__text {
      font-size: 12px;
    }

    &__list {
      margin-top: 18px;

      .name {
        margin-top: 6px;
        font-size: 13px;
      }

      .value {
        margin-top: 6px;
        font-size: 13px;
      }
    }

    &__close {
      margin-top: 0;
      padding: 13px 14px;

      &-button {
        font-size: 13px;
      }
    }
  }
}



/*----------------------------------------*\
  DIM BG
\*----------------------------------------*/

.dim-bg {
  display: none;
  position: absolute;
  inset: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.popup:last-of-type .dim-bg {
  display: block;
}

.layout-container::before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: -999999px;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 900;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

html.js-html-layer-opened .layout-container::before {
  left: 0;
  opacity: 0.8;
}

html.js-html-layer-closed-animate .layout-container::before {
  opacity: 0;
}

/*----------------------------------------*\
  New
\*----------------------------------------*/

.dim-contents {
  @include size(800px, auto);
  position: fixed;
  top: 50%;
  left: 50%;
  background: var(--bg-clr2);
  border-radius: 20px;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%) translateZ(0);
  will-change: transform;
  z-index: 10000;

  [class^=popup-head] {
    height: 48px;
    border-radius: 20px 20px 10px 0;
  }

  .popup-title {
    @include ellipsis;
    width: 100%;
    padding: 20px 40px 0 20px;
    font-size: 14px;
    font-weight: $bold;
    line-height: 18px;
    color: var(--tit-clr);
  }

  [class^=popup-body] {
    max-height: calc(80vh - 60px);
    padding: 20px;
    overflow: auto;
    backface-visibility: hidden;

    &::-webkit-scrollbar {
      width: 10px;

      &-thumb {
        width: 100%;
        background: var(--line-clr);
      }

      &-track {
        background: transparent;
      }
    }

    &:first-child {
      padding-top: 68px;
    }
  }

  [class^=popup-foot] {
    @include flex($justify: center, $gap: 8px);
    padding: 20px;

    .btn-area {
      margin: 0 auto;
      padding: 0 !important;
    }

    .btn-basic,  // old 버튼
    .btn-print,  // old 버튼
    .btn-down,  // old 버튼
    .btn-add,  // old 버튼
    a.btn-basic, // old 버튼
    .btn-small, // old 버튼
    .basic-btn {
      max-width: 180px;
      height: 40px;
      font-size: 14px;
      font-weight: $bold;
      line-height: 150%;

      &.type-normal {
        height: 54px;
        font-size: 18px;
      }
    }
  }

  .page-sub-heading {
    margin-bottom: 14px;
  }

  .body-des {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: $bold;
    line-height: 1.2;
  }

  .close,
  .btn-close-l {
    @include size(16px);
    position: relative;
    background: none;

    &::before,
    &::after {
      @include size(16px, 2px);
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      background-color: var(--body-clr);
      border-radius: 10px;
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  .btn-wrap {
    position: absolute;
    top: 0;
    right: 0;
  }

  .btn-close-l {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0; // 구조 수정 전 요소
  }

  .btn-small {
    @include flex($justify: center);
    @include size(230px, 54px);
    max-width: none;
    margin: 0;
    // background-color: var(--cap-clr) !important;
    border-radius: 14px;
    font-size: 18px;
    font-weight: $bold;
    line-height: 21px;
    color: var(--bg-clr2);

    &.bg-color01 {
      background-color: var(--main-clr) !important;
    }
  }

  .pop-newWp .td-wrap {
    padding: 0;
  }

  .slide-box-area:first-child {
    margin-top: 0;
  }
}

@include respond-to ("medium") {
  html:has(.dim-contents)::before {
    content: '';
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 4;
  }

  html:has(.dim-contents[style*="none"])::before {
    display: none;
  }

  .dim-contents:not(.sm, .swiper, .error-code) {
    inset: 0;
    width: 100vw !important;
    border-radius: 0;
    transform: none;

    .popup-head01 {
      height: 54px;
      padding: 0 20px;
      background: none;
      border-radius: 0;

      .popup-title {
        font-size: 18px;
        line-height: 21px;
        padding: 15px 0 0 0;
      }
    }

    .popup-head01:not(.no-title) + .popup-body01,
    .popup-head01:not(.no-title) + .popup-body02 {
      // max-height: 100%;
      padding-top: 20px !important;
    }

    .popup-body01 {
      max-height: calc(100vh - 60px);
      padding: 80px 20px 20px !important;
    }

    &:has(.popup-foot01) .popup-body01 {
      max-height: calc(100vh - 150px);
    }

    .popup-foot01 {
      position: fixed;
      inset: 0;
      top: initial;
      padding: 20px;
      z-index: 10;

      .basic-btn {
        flex: 1;
      }
    }

    .btn-close-l {
      top: 16px;
      right: 25px;
    }
  }
}

/*----------------------------------------*\
  || Alert Msg
\*----------------------------------------*/

.alert-msg li {
  // margin-bottom: 10px;
  line-height: 150%;
  font-size: 14px;
  text-align: center;
  word-break: auto-phrase;

  &:last-child {
    margin-bottom: 0;
  }
}

@include respond-to ("medium") {
  // .alert-msg li {
  //   font-size: 16px;
  //   line-height: 24px;
  // }
}

/*----------------------------------------*\
  || Swiper / 메인 슬라이드 팝업
\*----------------------------------------*/

.dim-contents.swiper {
  @include size(600px, 640px);
  overflow: hidden;

  .popup-head01 {
    // 슬라이드 팝업은 슬라이드 내부에 title 영역이 있기 때문에 head 영역을 숨깁니다.
    display: none;
  }

  .popup-body01 {
    height: 520px;
    padding: 0 !important;
    overflow: visible;

    > li {
      @include flex($direction: column);
      // @include size(100%, auto);
      background-color: var(--bg-clr2);
      transform: none;

      .popup-title-wrap {
        @include flex($justify: space-between);
        @include size(100%, 60px);
        min-height: 60px;
        padding: 0 30px;
        // position: sticky;
        // top: 0;
        // margin: 0 -30px 30px;
        // padding: 0 30px;
        // background-color: var(--line-clr);

        .popup-title {
          padding: 0;
        }
      }

      .popup-content-wrap {
        @include size(100%);
        padding: 0 30px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 15px;

          &-thumb {
            width: 100%;
            background: var(--line-clr);
          }

          &-track {
            background: transparent;
          }
        }

        img {
          width: 100% !important;
          height: auto !important;
        }
      }

      // .popup-title-wrap + p:last-child,
      // .popup-title-wrap + a:last-child {
      //   @include flex($align: center);
      //   @include size(100%, fit-content);
      //   margin-top: 0;

      //   > p:only-child {
      //     @include flex($align: center);
      //     @include size(100%, fit-content);
      //     margin-top: 0;

      //     > img:only-child {
      //       width: 100% !important;
      //       height: auto !important;
      //     }
      //   }

      //   > img:only-child {
      //     width: 100% !important;
      //     height: auto !important;
      //   }
      // }

      /* 서식 */
      .template {

        h3 {
          width: 100%;
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid var(--line-clr3);
          font-size: 18px;
          font-weight: $bold;
          line-height: 23px;
          color: var(--body-clr);
          word-break: auto-phrase;
        }

        h4 {
          margin-top: 20px;
          font-size: 16px;
          font-weight: $bold;
          line-height: 19px;
          color: var(--body-clr);
        }

        h5 {
          margin-top: 15px;
          font-size: 14px;
          font-weight: $bold;
          line-height: 16px;
          color: var(--main-clr);
        }

        p {
          margin-top: 15px;
          font-size: 16px;
          font-weight: $regular;
          line-height: 25px;
          color: var(--cap-clr);
          word-break: auto-phrase;

          &:first-child {
            margin-top: 0;
          }

          & + p {
            margin-top: 5px;
          }
        }

        ul {
          margin-top: 10px;

          > li {
            margin-left: 7px;
            font-size: 14px;
            font-weight: $regular;
            line-height: 16px;
            color: var(--body-clr);
            text-indent: -7px;

            &::before {
              @include size(3px);
              content: '';
              display: inline-block;
              margin-right: 4px;
              margin-bottom: 2px;
              background-color: var(--body-clr);
              border-radius: 50%;
              vertical-align: middle;
            }

            & + li {
              margin-top: 10px;
            }

            ul li {
              margin-left: 8px;
              font-size: 13px;
              font-weight: $regular;
              line-height: 15px;
              color: var(--body-clr);
              text-indent: -8px;

              &::before {
                @include size(4px, 1px);
                content: '';
                display: inline-block;
                margin-right: 4px;
                background-color: var(--body-clr);
                vertical-align: middle;
              }

              & + li {
                margin-top: 5px;
              }
            }
          }

          & + ul {
            margin-top: 15px;
          }
        }

        small {
          display: block;
          margin-top: 20px;
          margin-left: 17px;
          font-size: 13px;
          font-weight: $regular;
          line-height: 15px;
          color: var(--cap-clr);
          text-indent: -17px;

          &::before {
            @include size(15px);
            @include bgImg($url: '/img/common/tooltip_icon_m.png', $size: 12px);
            content: '';
            display: inline-block;
            margin-right: 4px;
            vertical-align: top;
          }
        }
      }
    }
  }

  .popup-close {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    height: 120px;
    padding: 30px 40px 40px;
    background: var(--bg-clr2);

    .swiper-controller {
      margin-top: 0;
      margin-right: auto;
    }
  }

  .label-wrap {
    label {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      line-height: 16px;
      color: var(--cap-clr);

      &::before {
        @include size(20px);
        content: "";
        display: block;
        background-color: var(--disable-clr2);
        border-radius: 4px;
      }
    }

    .chk-basic:checked ~ label::before {
      background: var(--body-clr) url('/img/common/icon/icon_check_white.png') no-repeat center / 12px;
    }
  }

  .basic-btn {
    width: 100px;
  }

  .popup-close-top {
    display: none;
  }
}

/*----------------------------------------*\
  Alert / SM / Phone
\*----------------------------------------*/

// .dim-contents.sm {
//   width: 430px;

//   .popup-head01:not(.no-title) + .popup-body01,
//   .popup-head01:not(.no-title) + .popup-body02 {
//     padding-top: 60px;
//   }

//   .popup-body01,
//   .popup-body02 {
//     padding: 80px 30px 60px;
//   }

//   .popup-foot01 {
//     @include flex($gap: 10px);
//     padding: 60px 30px 30px;
//   }

//   .btn-small {
//     flex: 1;
//     max-width: none;

//     &:only-child,
//     & + .btn-small {
//       background-color: var(--main-clr) !important;
//     }
//   }

//   .btn-close-l {
//     position: absolute;
//     top: 20px;
//     right: 30px;
//   }
// }

// .dim-contents.sm.phone {
//   width: 550px;

//   .popup-body01,
//   .popup-body02 {
//     padding: 30px !important;
//   }

//   .popup-foot01 {
//     padding: 30px;
//   }
// }

// @include respond-to ("medium") {
//   .dim-contents.sm {
//     width: calc(100% - 80px);

//     .popup-head01 {
//       justify-content: center;
//       background: none;

//       .popup-title {
//         max-width: none;
//         padding: 20px 12px;
//         font-size: 18px;
//         line-height: 20px;
//       }
//     }

//     .popup-head01:not(.no-title) + .popup-body01,
//     .popup-head01:not(.no-title) + .popup-body02 {
//       padding-top: 0;
//     }

//     .popup-body01,
//     .popup-body02 {
//       padding: 34px 12px 0;
//     }

//     .popup-foot01 {
//       padding: 24px 12px 12px;

//       .basic-btn,
//       .btn-small {
//         flex: 1;
//         height: 42px;
//         border-radius: 10px;
//         font-size: 16px;
//         line-height: 18px;
//       }
//     }

//     .btn-wrap {
//       display: none;
//     }
//   }

//   .dim-contents.error-code {
//     .popup-head01 {
//       padding-top: 16px;
//     }

//     .btn-wrap {
//       display: none;
//     }
//   }
// }

/*----------------------------------------*\
  SIZE
\*----------------------------------------*/

.dim-contents.sm {
  width: 430px;

  .btn-basic,  // old 버튼
  .btn-print,  // old 버튼
  .btn-down,  // old 버튼
  .btn-add,  // old 버튼
  a.btn-basic, // old 버튼
  .btn-small, // old 버튼
  .basic-btn {
    width: 100%;
    max-width: none;
    height: 40px;
    font-size: 14px;
  }
}

.dim-contents.md {
  width: 600px;
}

.dim-contents.lg {
  width: 1000px;
}

.dim-contents.w-auto {
  min-width: 430px;
}

/*----------------------------------------*\
  Dim contents 팝업 내부 분기 처리 요소
\*----------------------------------------*/

.dim-contents {

  .basic-table tbody th,
  .basic-table tbody td,
  .basic-table thead + tbody tr:first-child th,
  .basic-table thead + tbody tr:first-child td {
    padding: 14px 0;
  }

  .basic-table thead + tbody tr:only-child th,
  .basic-table thead + tbody tr:only-child td {
    padding-bottom: 0;
  }
}

/*----------------------------------------*\
  Toast Popup
\*----------------------------------------*/

.toast-popup {
  position: fixed;
  left: 50%;
  bottom: -50px;
  min-width: 400px;
  min-height: 50px;
  padding: 13px 16px;
  background-color: #e6f6f5;
  border: 1px solid var(--main-clr);
  border-radius: 25px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateX(-50%);
  animation: toastPopup 2.5s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 100;

  p {
    @include flex($gap: 10px);
    font-size: 16px;
    line-height: 19px;
    color: var(--tit-clr);

    &::before {
      @include size(22px);
      @include bgImg($url: '/img/common/icon/icon_check_shadow.png', $size: 22px);
      content: '';
      min-width: 22px;
    }
  }
}

@keyframes toastPopup {

  0%,
  100% {
    bottom: -50px;
    opacity: 0;
  }

  25%,
  75% {
    bottom: 40px;
    opacity: 1;
  }
}

@include respond-to ("medium") {
  .toast-popup {
    min-width: calc(100vw - 20px);
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  Uncategorized
\*----------------------------------------*/

// .popup-subtitle01 {
//   display: inline-block;
//   padding-top: 5px;
//   font-size: 20px;
//   color: #666;
//   font-weight: $medium;
// }

// .no-title + .popup-body01,
// .no-title + .popup-body02 {
//   border-top-left-radius: 20px;
//   border-top-right-radius: 20px;
// }

/*----------------------------------------*\
  Popup 01
\*----------------------------------------*/

// .dim-contents {
//   // width: 850px;
//   // height: auto;
//   // max-height: 820px;
//   // margin-top: -32.5px !important;
//   // top: 50% !important;
//   // left: 50%;
//   // -webkit-transform: translate(-50%, -50%);
//   // transform: translate(-50%, -50%);
//   // background: var(--bg-clr2);

//   &.paybooc-layer {
//     max-height: 700px;
//   }

//   &.style01 .popup-body02,
//   &.style01 .popup-foot01 {
//     background: var(--bg-clr2);
//   }

//   &.style01 .ui-accordion-layer .slide-box-txt {
//     height: 145px;
//   }

//   &.w900 {
//     width: 900px;
//   }

//   .content-wrap {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     padding: 60px 30px 40px;
//   }

//   .contents {
//     width: 100%;
//     height: 100%;
//     overflow-y: auto;
//   }

//   .notice-list li {
//     padding-left: 15px;
//     font-size: 14px;
//     line-height: 23px;

//     &:first-child {
//       margin-top: 0;
//     }

//     &::before {
//       width: 5px;
//       height: 5px;
//       margin-left: -15px;
//       background-color: #999;
//     }
//   }

//   .content-box-wrap {
//     width: 100%;
//     padding: 30px;
//     border: 1px solid var(--disable-clr);
//   }

//   .content-box {
//     padding: 30px;
//     // border: 3px solid #928781;

//     &::after {
//       content: '';
//       display: table;
//       clear: both;
//     }
//   }

//   .content-header,
//   .content-body {
//     display: table;
//     width: 100%;
//   }

//   .con-item {
//     display: table-cell;
//     vertical-align: middle;
//     text-align: right;

//     &:first-child {
//       text-align: left;
//     }

//     .lh150 {
//       font-size: 16px;
//     }
//   }

//   .name-info,
//   .name-info > span {
//     font-size: 20px;
//     font-weight: $bold;
//   }

//   .content-list {
//     margin-top: 24px;
//     padding: 25px 26px 45px;
//     background: #f3f1ef;
//   }

//   .content-table {
//     width: 100%;
//     margin-bottom: 20px;

//     > li {
//       display: table;
//       width: 100%;
//       table-layout: fixed;
//     }

//     .th,
//     .td {
//       display: table-cell;
//       text-align: left;
//       font-size: 16px;
//       font-weight: $bold;
//       padding: 8px 0;
//     }

//     .th {
//       width: 15%;
//     }

//     .td {
//       width: 85%;
//       color: var(--main-clr);
//     }
//   }

//   .info-box {
//     padding-top: 20px;
//     border-top: 1px dashed #d4d4d4;

//     .con-info {
//       font-size: 12px;
//       line-height: 24px;
//     }

//     .bank-name {
//       font-size: 14px;
//       font-weight: $bold;
//     }

//     .bank-sign {
//       vertical-align: middle;
//       margin-left: 10px;
//     }
//   }

//   .content-foot {
//     font-size: 0;
//   }

//   .footer-info {
//     position: relative;
//     display: inline-block;
//     padding: 0 12px 0 10px;
//     margin-top: 7px;

//     &::after {
//       position: absolute;
//       top: 50%;
//       margin-top: -6px;
//       right: 0;
//       content: '';
//       width: 1px;
//       display: inline-block;
//       height: 12px;
//       background: var(--body-clr);
//     }

//     &:last-child::after {
//       display: none;
//     }

//     dt,
//     dd {
//       display: inline-block;
//       font-size: 13px;
//       line-height: 24px;
//     }

//     dt {
//       margin-right: 8px;
//     }
//   }

//   .content-btn-wrap {
//     @include flex($justify: center, $gap: 10px);
//     margin-top: 30px;

//     a.margin-r-b,
//     button.margin-r-b {
//       margin: 0;
//     }

//     .btn-basic + .btn-basic {
//       background-color: var(--main-clr);
//     }
//   }

//   .info-table-area:first-child {
//     margin-top: 0 !important;

//     &:first-child {
//       padding-top: 0;
//     }

//     & + & {
//       margin-top: 40px;
//     }

//     & + .notice-list-wrap {
//       padding-top: 24px;
//     }
//   }

//   .notice-list + .table-wrap01,
//   .notice-list + .info-table-area,
//   .notice-list + .search-table-area {
//     margin-top: 20px;
//   }

//   .table-title {
//     margin-top: 0;
//     padding-bottom: 0;

//     & + .table-wrap01 {
//       margin-top: 15px;
//     }
//   }
// }

/*----------------------------------------*\
  Popup 02 MD
\*----------------------------------------*/

// .dim-contents {
//   // &.md {
//   //   width: 700px;
//   //   height: auto;
//   // }

//   .popup-head01 {
//     // position: relative;
//     // width: 100%;
//     // padding: 22px 30px 20px;
//     // background: #efefef;
//     // border-bottom: 1px solid var(--disable-clr);

//     &.no-title {
//       height: 0;
//       overflow: hidden;
//       // padding: 0;
//       // height: 65px;
//       // background-color: var(--bg-clr2);
//       // border-bottom: none;
//     }

//     .popup-title {
//       font-size: 22px;
//       font-weight: $bold;
//     }
//   }

//   .popup-body01 {
//     // max-height: 60vh;
//     // overflow-y: auto;
//     // padding: 40px 30px;

//     // &.no-title {
//     //   padding: 0 30px;
//     // }

//     // &.ars-content-wrap {
//     //   padding: 0 30px;
//     //   background: var(--bg-clr2);
//     // }

//     .body-des {
//       margin-bottom: 15px;
//       font-size: 18px;
//       font-weight: $bold;
//       line-height: 1.2;
//     }

//     .font-1rem li {
//       font-size: 16px;
//     }
//   }

//   .popup-body02 {
//     .paybook-img img {
//       width: 100%;
//     }
//   }

//   // .popup-foot01 {
//   //   padding: 40px 30px 60px;
//   //   text-align: center;
//   //   border-top: 1px solid var(--disable-clr);
//   //   background: var(--bg-clr2);

//   //   button {
//   //     margin: 0 5px;
//   //   }
//   // }
// }

/*----------------------------------------*\
  Popup 03
\*----------------------------------------*/

// .dim-contents {
//   .popup-body01 {
//     &.content-body {
//       min-height: 200px;
//     }

//     .body-msg {
//       font-size: 24px;
//       font-weight: $bold;
//       line-height: 1.5;
//       text-align: center;

//       &.type01 {
//         padding-top: 90px;
//         background: url(/img/content/sbt/img_danger_msg.png) no-repeat top center / 60px;
//       }

//       span {
//         font-weight: $bold;
//       }
//     }

//     .body-logout {
//       font-size: 24px;
//       font-weight: $regular;
//       line-height: 1.5;
//       text-align: center;
//     }

//     .body-logout span {
//       font-weight: $bold;
//     }
//   }
// }

/*----------------------------------------*\
  Popup Body 01
\*----------------------------------------*/

// .popup-body01 {
//   border-radius: 0 0 20px 20px;

//   // &.type02 {
//   //   padding-bottom: 35px;
//   // }

//   // &.ars-content-wrap .sub-box-wrap {
//   //   padding: 38px 0 20px !important;
//   // }

//   // &.qrcode-body {
//   //   padding: 34px 30px 29px !important;
//   // }

//   &.img-con {
//     padding-right: 30px !important;
//   }

//   // .btn-blue {
//   //   margin-bottom: 25px;
//   // }
// }

/*----------------------------------------*\
  Popup Body 02
\*----------------------------------------*/

// .popup-body02 {
//   min-height: 150px;
//   max-height: calc(80vh - 140px);
//   padding: 90px 30px 30px;
//   border-radius: 0 0 20px 20px;
//   overflow-y: auto !important;

//   &::-webkit-scrollbar {
//     width: 10px;

//     &-thumb {
//       width: 100%;
//       background: var(--line-clr3);
//     }

//     &-track {
//       background: transparent;
//     }
//   }

//   // &.type01 {
//   //   padding: 15px 30px 30px
//   // }

//   // &.type03 {
//   //   max-height: none;
//   //   overflow: visible;
//   // }

//   // &.pad0 {
//   //   padding: 0;
//   // }

//   &.alert {
//     display: table;
//     width: 100%;
//     min-height: auto;
//     padding: 30px;
//   }

//   &:has(+.popup-foot01) {
//     padding-bottom: 0 !important;
//   }

//   .alert-msg {
//     display: table-cell;
//     vertical-align: middle;
//   }

//   .notice-list.line-style01 li {
//     line-height: 1.5;
//   }
// }

/*----------------------------------------*\
  Popup Foot 01
\*----------------------------------------*/

// .popup-foot01.qrcode-foot {
//   border-top: 1px solid #000 !important;
// }

/*----------------------------------------*\
  Popup Gap
\*----------------------------------------*/

// .popupgap-newstyle01 {
//   padding-top: 10px !important;
//   border-top: none !important;
// }

// .popupgap-newstyle02 {
//   padding-top: 40px !important;
//   border-top: none !important;
// }

/*----------------------------------------*\
  Layer Wrap
\*----------------------------------------*/

.layer-wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  opacity: 0;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  &.scroll-type01 .dim-contents .btn-close-l {
    font-size: 0;
  }
}

.layer-container {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.2;
  white-space: normal;
  background: var(--bg-clr2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/*----------------------------------------*\
  Pop New Wp
\*----------------------------------------*/

.pop-newWp {
  // background: #efefef;

  input ~ .notice-i-list {
    margin-left: 0;
  }

  .td-wrap {
    margin: auto;
    padding: 40px 30px;

    &.tal label {
      font-size: 14px;
    }

    &.td-wrap.lonc .divi-styl li {
      width: 33.1%;
      font-size: 14px;
    }

    &.td-wrap.lonc .notice-i-list.trn-notice-i-list {
      margin-left: 0;
    }
  }

  .table {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .input-basic {
      max-width: 100%;
    }
  }

  .table-cell {
    width: 50%;
    text-align: left;

    &.left {
      padding-right: 20px;
      vertical-align: top;
    }

    &.right {
      padding-left: 20px;
      vertical-align: top;

      &.fir {
        padding-right: 0;

        .calendar-area {
          width: 370px;

          .input-area {
            width: 370px;
          }
        }
      }
    }
  }

  .table-cell02 input[type="text"] {
    width: calc(50% - 24px);
  }

  .ses-title,
  .lon-item-list {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: $medium;
  }

  .btn-area {
    @include flex($justify: center, $gap: 10px);
    margin-bottom: 0;
  }

  .dtype {
    .number-k {
      display: block;
      font-size: 11px;
    }

    .btn-sm-wh {
      max-width: 85px;
    }

    .wtype01 {
      width: 240px;
    }
  }

  .stype .date-box2 .input-area {
    flex: 1;
    width: auto;
    max-width: initial;
  }

  .input-basic,
  .stype .select-basic {
    width: 99%;
  }

  .input-basic,
  .select-basic {
    max-width: 370px;
  }
}

.btn-newWp {
  @include flex($justify: center, $gap: 10px);
  padding-top: 20px;
  margin-top: 20px;
  text-align: center;
}

.loan-inpWp {
  max-width: 270px !important;
  margin-left: 10px;
}

.dur-date {
  display: inline-block;

  .input-area {
    width: 270px;
    margin-top: 10px;
  }
}

/*----------------------------------------*\
  마이페이지 팝업
\*----------------------------------------*/

.p-img-wrap {
  width: calc(100% + 10px);
  margin: 20px -5px 25px;

  .p-img-box {
    position: relative;
    width: calc(100% / 7 - 10px);
    padding-top: 12.845%;
    border: 1px solid var(--disable-clr);
    float: left;
    margin: 5px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.p-img-box {
  position: relative;

  .label-wrap {
    position: absolute;
    top: 0;
    left: 0;

    .icon-chk {
      display: inline-block;
      margin: 5px;
    }
  }
}

/*----------------------------------------*\
  ARS 팝업
\*----------------------------------------*/

.ars-content-wrap {
  .list-style li {
    margin-left: 0;
    line-height: 22px;
    padding-left: 17px;
    text-indent: -13px;

    &::before {
      margin-top: 8px;
    }
  }

  .ars-content {
    padding-bottom: 30px;
    text-align: center;
  }

  .subtext01 {
    margin-top: 6px;
    font-size: 24px;
  }

  .text {
    font-size: 20px;
    font-weight: $medium;
    line-height: 1.5;
    color: var(--body-clr);
  }

  .subtext02 {
    margin-top: 6px;
    font-size: 16px;
    color: #666;
    line-height: 24px;
  }

  .ars-cerbox {
    width: 280px;
    margin: 30px auto;
    padding: 20px 0 18px;
    background: #efefef;
    text-align: center;
    border-radius: 10px;
  }
}

.ars-cerbox {
  span {
    display: block;
  }

  .title {
    font-size: 24px;
    font-weight: $medium;
    color: var(--body-clr);
  }

  .num {
    margin-top: 10px;
    font-size: 72px;
    color: var(--main-clr);
    font-weight: $medium;
  }

  .timer {
    margin-top: 6px;
    padding: 0;
    background: none;
    font-size: 16px;
    color: #000;
  }
}

/*----------------------------------------*\
  Phone Content 팝업
\*----------------------------------------*/

.phone-content {
  .phonetext {
    font-size: 20px;
    font-weight: $medium;
    line-height: 1.5;
    text-align: center;
  }

  .certifiy-result-wrap {
    padding: 156px 0 22px;
    border: 0;
  }

  & + .sub-box-wrap.type01 {
    padding-top: 20px !important;
  }
}

/*----------------------------------------*\
  전화승인실패 팝업
\*----------------------------------------*/

.error-content {
  width: 490px;
  margin: 35px auto 0;
  padding: 44px 0;
  border-top: 1px solid var(--disable-clr);
  background: #efefef;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
}

/*----------------------------------------*\
  주소찾기 팝업
\*----------------------------------------*/

.address-search {
  margin-bottom: 10px;

  * {
    float: left;
    margin-right: 10px;
  }

  select {
    width: 140px;
  }

  input {
    width: 343px;
  }

  button {
    width: 120px;
    margin-right: 0;
  }

  &.type02 input {
    width: 510px;
    max-width: inherit;
  }
}

.address-search::after,
.address-result li::after {
  content: '';
  display: block;
  clear: both;
}

.address-view {
  border: 1px solid var(--disable-clr);
  padding: 20px;
  max-height: 150px;
  overflow-y: auto;
}

.address-info li {
  text-align: center;
  font-size: 14px;
  padding: 3px 0;
}

.address-result {
  li {
    margin-bottom: 10px;
  }

  [class*=txt-addr] {
    font-size: 14px;
    float: left;
    display: block;
    line-height: 1.2em;
  }

  .txt-addr01 {
    font-weight: $medium;
    width: 90px;
  }

  .txt-addr02 {
    max-width: 490px;
  }
}

.address-input {
  width: 100%;
}

/*----------------------------------------*\
  문자전송 팝업
\*----------------------------------------*/

.send-msg-box {
  width: 100%;
  height: auto;
  padding: 20px;
  margin-top: 30px;
  border: 1px solid var(--line-clr3);
  border-radius: 14px;

  dt,
  dd {
    font-size: 13px;
    line-height: 1.5em;
    word-break: keep-all;
  }

  dt {
    font-weight: $medium;
    padding-bottom: 10px;
  }
}

/*----------------------------------------*\
  이체 확인증 출력 팝업
\*----------------------------------------*/

.docu-title.top.bd-btm {
  border-bottom: 1px solid #000;
  display: inline-block;
  padding: 0 5px 5px;
}

.txt-option01 {
  font-weight: $medium;
  font-size: 20px;
  padding-bottom: 15px;
}

/*----------------------------------------*\
  지연이체 팝업
\*----------------------------------------*/

.popup-b-txt {
  margin-bottom: 28px;

  li {
    font-weight: $medium;
    font-size: 18px;
    margin-bottom: 12px;
  }
}

.popup-checkbox-wrap {
  border: 1px solid var(--line-clr3);
  padding: 20px;
  margin: 15px 0;
  border-radius: 10px;

  &.agree {
    margin-bottom: 0;
    background: #f7f7f7;
    border: 0;
    text-align: center;

    .chk-label {
      font-weight: $medium;
      color: #666;
    }

    .radio-box-wrap {
      margin-left: 0;
      transform: translateX(0);
    }
  }

  .radio-box-wrap {
    display: inline-block;
  }
}

/*----------------------------------------*\
  로그인 팝업
\*----------------------------------------*/

.log-pop-top {
  display: table;
  width: 100%;

  .log-pop-txt {
    font-size: 14px;
    text-align: center;
  }
}

.log-pop-center {
  padding: 20px 0 1px 0;

  .qrcode-wrap {
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .timer {
    display: block;
    width: 150px;
    margin: 0 auto;
    text-align: center;
    background: url("/img/common/timer_icon.png") no-repeat left 35px center / 18px;
    padding-left: 45px;
    font-size: 16px;
  }

  .qr-num-box {
    margin-top: 20px;
    text-align: center;
  }

  .qr-num {
    display: inline-block;
    border: 1px solid var(--disable-clr);
    width: 130px;
    text-align: center;
    font-size: 24px;
    padding: 13px 0;
    vertical-align: middle;
    margin: 0 auto 20px;

    & + .qr-num {
      margin-left: 10px;
    }

    span {
      display: inline-block;
      vertical-align: sub;
    }
  }
}

.log-pop-blist {
  width: 500px;
  list-style: decimal;
  margin-left: 20px;

  li {
    font-size: 14px;
    line-height: 1.5;
  }
}

.log-pop-bottom {
  border-top: 1px solid var(--disable-clr);
  padding: 22px 0 0px 0;
  position: relative;
}

.pop-bottom-txt {
  width: 500px;
  line-height: 1.3;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 6px;
}

.pop-bottom-logo {
  position: absolute;
  top: calc(50% - 55px);
  right: 0;
  width: 50px;
}

/*----------------------------------------*\
  OTP 만료안내 팝업
\*----------------------------------------*/

.otp-alert {
  padding: 30px 0;
  background: #efefef;
  font-size: 16px;
  text-align: center;

  & + .notice-list-wrap {
    padding-top: 23px;
  }
}

.otp-alert-txt {
  margin-top: 14px;
  line-height: 30px;
  font-size: 18px;
}

.battery-confirm {
  padding: 30px 0 10px;
  border-top: 1px solid var(--disable-clr);
}

.icon-alert {
  height: 40px;
  background: url('/img/common/icon_alert.png') no-repeat 50% 50%;
}

.confirm-txt {
  font-size: 20px;
  font-weight: $medium;
}

.otp-box,
.otp-box02 {
  width: 265px;
  height: 160px;

  img {
    width: 265px;
    height: 130px;
  }

  p {
    font-weight: $medium;
    margin-top: 10px;
    font-size: 16px;
  }
}

.otp-box02 {
  margin-left: 55px;
}

.popup-close {
  height: 50px;
  padding: 15px 30px;
  background: #666;
}

i.chk-w {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url(/img/common/checkbox-w.png) no-repeat center / contain;
  display: table-cell;
}

.popup-close-btn {
  padding: 2px 20px 0 0;
  color: var(--bg-clr2);
  background: url('/img/common/icon_popup_close.png') no-repeat right center;
  font-size: 16px;
}

/*----------------------------------------*\
  휴대폰인증 팝업
\*----------------------------------------*/

.phone-table {
  tr:last-child {
    margin-bottom: 0;
  }

  th {
    font-size: 14px;
    text-align: left;
    vertical-align: middle;
    font-weight: $medium;

    label {
      font-weight: $medium;
    }
  }

  td {
    padding: 10px 0;
  }
}

.phone-radios,
.phone-radios02 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;

  &:focus + label {
    border: 1px dotted var(--bg-clr2);
  }

  &:checked + label {
    border: 1px solid var(--main-clr);
    color: var(--bg-clr2);
    background: var(--main-clr);
  }
}

.phone-radio:focus + label,
.phone-radios:focus + label {
  border: 1px dotted var(--bg-clr2);
}

.phone-radio02:focus + label,
.phone-radios02:focus + label {
  border: 1px dotted var(--bg-clr2);
}

.phone-radios + label {
  padding: 13px 26px;
  border: 1px solid var(--disable-clr);
  box-sizing: border-box;
  font-weight: $regular;
  color: var(--body-clr);
  font-size: 14px;
  cursor: pointer;
}

.phone-radios02 + label {
  width: 109px;
  height: 47px;
  display: inline-block;
  text-align: center;
  border: 1px solid var(--disable-clr);
  box-sizing: border-box;
  font-weight: $regular;
  color: var(--body-clr);
  font-size: 14px;
  cursor: pointer;
}

.phone-slide-area {
  border: 1px solid var(--disable-clr);
}

.phone-slide-top {
  padding: 15px 20px;
  border-top: 1px solid var(--disable-clr);

  &:first-child {
    border-top: none;
  }
}

.phone-slide-txt {
  display: none;
  max-width: 100%;
  height: 150px;
  padding: 20px;
  border-top: 1px solid var(--disable-clr);
  overflow-y: auto;

  p {
    font-size: 14px;
    line-height: 1.5em;
  }
}

.phone-slide-btn {
  width: 16px;
  height: 9px;
  margin-top: 6px;
  background: url("/img/common/icon_slide_down.png") no-repeat center;
}

.phone-slide-active .phone-slide-btn {
  background: #f7f7f7 url("/img/common/icon_slide_up.png") no-repeat center;
  transform: none;
}

.phone-table02 {
  margin: 10px 0 5px;

  th {
    font-size: 16px;
    vertical-align: top;
  }
}

/*----------------------------------------*\
  통장사본
\*----------------------------------------*/

// .dim-contents:has(.content-wrap) {
//   width: 900px;
// }

/*----------------------------------------*\
  공과금 팝업
\*----------------------------------------*/

// .dim-contents .document-area {
//   border: 0;
//   margin-bottom: 0;
//   padding: 0;
// }

/*----------------------------------------*\
  영수증번호 팝업
\*----------------------------------------*/

.popup-top-number {
  @include flex;
  width: 100%;
  background: #f7f7f7;
  margin-bottom: 30px;
  border-radius: 14px;
  border: 1px solid var(--line-clr3);
  overflow: hidden;

  .number {
    display: inline-block;
    width: 126px;
    text-align: center;
    padding: 15px;
    border-right: 1px solid var(--line-clr3);
    font-weight: $medium;
    font-size: 14px;

    &:last-child {
      border-right: none;
    }
  }
}

/*----------------------------------------*\
  오류코드 팝업
\*----------------------------------------*/

.dim-contents.error-code {
  width: auto;
  min-width: 300px;
  max-width: 430px;
  text-align: center;

  .popup-head01 {
    text-align: left;
  }

  // .popup-title {
  //   padding: 0 16px;
  //   font-size: 16px;
  // }

  // .popup-head01:not(.no-title) + .popup-body01 {
  //   padding: 16px 20px;
  //   text-align: center;
  // }

  .code-name {
    font-size: 16px;
    line-height: 150%;
    color: var(--tit-clr);
    font-weight: 700;
  }

  .code {
    font-weight: 700;
  }

  .code-details {
    margin-top: 12px;
    font-size: 14px;
    line-height: 150%;
    color: #555;
  }

  // .popup-foot01 {
  //   padding: 20px;
  // }

  // .basic-btn {
  //   @include size(180px, 44px);
  // }

  // .btn-close-l {
  //   @include size(16px);
  //   top: 14px;
  //   right: 16px;

  //   &::before,
  //   &::after {
  //     @include size(20px, 2px);
  //     top: 8px;
  //     left: -2px;
  //   }
  // }
}

/*----------------------------------------*\
  팝업 버튼
\*----------------------------------------*/

.popup-control01 {
  position: fixed;
  top: 75px;
  right: 27px;
  width: 260px;
  border-radius: 20px;
  background: var(--body-clr);
  padding: 10px 20px;
}

.popup-control03 {
  position: fixed;
  bottom: 20px;
  left: 50%;
  margin-left: -142px;
  width: 284px;
  background: var(--body-clr);
  padding: 15px 20px 13px;

  .prev,
  .next {
    color: var(--bg-clr2);
    font-size: 16px;
    vertical-align: middle;
  }

  .popup-prev,
  .popup-next {
    width: 18px;
    height: 18px;
    margin-top: -2px;
    vertical-align: middle;
  }

  .popup-prev {
    margin-left: 15px;
    background: url('/img/common/icon_prev.png') no-repeat;
  }

  .popup-next {
    margin-right: 15px;
    background: url('/img/common/icon_next.png') no-repeat;
  }

  span {
    padding: 0 14px;
  }
}

.popup-control04 {
  position: fixed;
  bottom: 20px;
  right: 35px;
  width: 160px;

  button {
    width: 100%;
    max-width: 160px;
    padding: 14px 0;
    color: var(--bg-clr2);
  }
}

.popup-page {
  display: inline-block;
  font-weight: $medium;
  color: var(--bg-clr2);
  font-size: 16px;
  vertical-align: middle;
}

.popup-btn-plus,
.popup-btn-minus,
.popup-btn-original,
.popup-btn-basic,
.popup-btn-minimap {
  color: var(--bg-clr2);
  padding: 4px 0 3px 23px;
  font-size: 14px;
  margin-left: 7px;
}

.popup-btn-plus {
  margin-left: 0;
  background: url('/img/common/icon_plus_wh.png') no-repeat left center;
}

.popup-btn-minus {
  background: url('/img/common/icon_minus_wh.png') no-repeat left center;
}

.popup-btn-original {
  background: url('/img/common/icon_original.png') no-repeat left center;
}

.popup-btn-basic {
  background: url('/img/common/icon_basic.png') no-repeat left center;
}

.popup-btn-minimap {
  background: url('/img/common/icon_minimap.png') no-repeat left center;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    // .dim-contents .popup-foot01 {
    //   .btn-small {
    //     margin: 0;
    //   }

    //   .right {
    //     margin-left: 0;
    //   }
    // }

    .dtype {
      .radio-box-wrap label {
        float: inherit;

        &:last-child {
          margin-bottom: 10px;
        }
      }

      .btn-small {
        width: 49.5%;
      }
    }

    .stype .m-date .date-box2 .datemid {
      display: none;
    }

    .p-img-wrap .p-img-box {
      width: calc(50% - 10px);
      padding-top: 47.21%;
    }
  }

  .pop-newWp {
    .td-wrap {
      margin: auto;
      padding: 20px 20px;

      &.td-wrap.lonc .divi-styl li {
        width: 100%;

        &:before {
          background: none;
        }
      }
    }

    .table {
      width: 100%;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &.during .radio-box {
        display: block;
        margin-bottom: 10px;
      }

      .input-basic {
        max-width: 100%;
      }
    }

    .table-cell {
      width: 100%;
      text-align: left;

      &.left {
        padding-right: 0px;
        display: block !important;
      }

      &.right {
        padding-left: 0px;
        display: block !important;
        padding-top: 10px;

        &.fir .calendar-area {
          width: inherit;

          .input-area {
            width: inherit;
          }
        }
      }
    }

    .ses-title {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: $medium;
    }

    .btn-area {
      padding-top: 20px;
    }

    .input-area {
      width: 100%;
    }

    .dtype {
      .btn-sm-wh {
        max-width: 120px;
        margin-top: 5px;
      }

      .wtype01 {
        width: 100%;
      }

      .radio-box-wrap {
        margin-bottom: 15px;
      }

      .loan-inpWp {
        margin-left: 0;
        margin-top: 5px;
      }
    }

    .stype .date-box2 {
      display: block;

      .input-area {
        max-width: inherit;
        width: 100%;
      }
    }

    .input-basic,
    .select-basic {
      max-width: inherit;
    }

    .table-cell02 input[type="text"] {
      width: 99%;
      text-align: left;
    }
  }
}

/*----------------------------------------*\
  Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  .dim-contents.w-auto {
    min-width: inherit;
  }
  // .dim-contents {

  //   button:not(.swiper-pagination-bullet) {
  //     flex: 1;
  //   }

  //   &.paybooc-layer {
  //     max-height: 560px;
  //   }
  // }

  // .dim-contents.sm.phone {
  //   inset: 0;
  //   width: auto;
  //   border-radius: 0;
  //   transform: translate(0, 0);

  //   .popup-body01,
  //   .popup-body02 {
  //     padding: 20px;
  //     max-height: calc(100vh - 160px);
  //   }
  // }

  // html.responsive {
  //   &:has(.dim-contents) {
  //     overflow: hidden;
  //   }

  //   &:has(.dim-contents.swiper[style*=none]) {
  //     overflow: visible;
  //   }

    .dim-contents.swiper {
      width: 100%;
      height: 57.5%;
      max-height: none;
      border-radius: 32px 32px 0 0;
      margin: 0 !important;
      transform: none;
      inset: 0;
      top: initial !important;
      box-shadow: none;
      overflow: visible;
      will-change: auto;

      .popup-body01 {
        max-height: calc(100% - 72px);
        padding: 0;

        &:has(>:only-child) {
          max-height: calc(100% - 34px);
        }

        > li {
          height: 100%;
          // padding: 0 20px;
          border-radius: 32px 32px 0 0;

          &::-webkit-scrollbar {
            display: none;
          }

          .popup-title {
            max-width: 100%;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
          }

          h3 {
            margin-bottom: 16px;
            padding-bottom: 16px;
            font-size: 20px;
            line-height: 26px;
          }

          p {
            word-break: normal;
          }

          h4,
          h5 {
            margin-top: 16px;
          }
        }

        .popup-title-wrap {
          justify-content: center;
          margin-bottom: 0;
          background-color: var(--bg-clr2);
          // top: -36px;
          // left: auto;
          // right: auto;
          // width: calc(100% - 40px);
        }

        .popup-content-wrap {
          @include size(100%);
          padding: 0 30px;
          overflow: auto;

          &::-webkit-scrollbar {
            width: 10px;
          }
        }

        .close {
          display: none;
        }
      }

      .popup-close {
        justify-content: center;
        height: auto;
        padding: 20px 0 30px;

        .swiper-controller {
          margin: 2px auto;
          min-height: 24px;
        }
      }

      .popup-close-top {
        @include flex($justify: space-between);
        position: absolute;
        top: -40px;
        left: 20px;
        width: calc(100% - 40px);

        .today,
        .close-wrap {
          font-size: 15px;
          line-height: 17px;
          ;
          color: var(--bg-clr2);
          text-align: left;
        }

        .close-wrap {
          @include flex($gap: 10px, $justify: flex-end);
        }

        .close {
          @include size(30px);
          background-color: var(--bg-clr2);
          border-radius: 50%;

          &::before,
          &::after {
            @include size(14px, 2px);
            top: 14px;
            left: 8px;
          }
        }
      }

      .dim-bg {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: -1;
      }
    }

  //   .dim-contents {
  //     // width: calc(100% - 40px);
  //     // max-height: 600px;

  //     // &.md {
  //     //   width: calc(100% - 40px);
  //     // }

  //     .content-wrap {
  //       -webkit-box-sizing: border-box;
  //       box-sizing: border-box;
  //     }

  //     .content-wrap,
  //     .content-box-wrap,
  //     .content-box {
  //       padding-left: 2.78%;
  //       padding-right: 2.78%;
  //     }

  //     .notice-list {
  //       padding-right: 50px;

  //       li::before {
  //         margin-top: 7px;
  //       }
  //     }

  //     .content-table {
  //       font-size: 13px;

  //       .th {
  //         width: 80px;
  //       }
  //     }


  //     // .popup-foot01 .btn-small.left {
  //     //   margin-left: 0px;
  //     //   margin-right: 0px;
  //     //   margin-bottom: 10px;
  //     // }

  //     // .popup-head01 {
  //     //   display: none;

  //     //   .popup-title {
  //     //     font-size: 18px;
  //     //   }
  //     // }


  //     .popup-body01 {
  //       padding: 40px 20px;
  //       min-height: auto;

  //       .body-des {
  //         font-size: 15px;
  //       }

  //       .notice-list li {
  //         font-size: 12px;

  //         &::before {
  //           margin-top: 4px;
  //         }
  //       }
  //     }
  //   }
  // }
}

/*----------------------------------------*\
  540px
\*----------------------------------------*/

@media (max-width: 540px) {
  // .dim-contents .popup-body01 .body-msg {
  //   font-size: 16px;
  // }

  // html.responsive .dim-contents {
  //   // width: calc(100% - 40px);
  //   // border-radius: 15px;
  //   // overflow: hidden;
  //   // box-shadow: 0 0 5px var(--disable-clr);

  //   .popup-body01.content-body {
  //     min-height: 100px;
  //   }
  // }
}
