@charset "utf-8";

/*----------------------------------------*\
  || Page Step List
\*----------------------------------------*/

.page-step-list {
  @include flex($justify: flex-end, $gap: 20px);

  li {
    @include flex($gap: 10px);
    counter-increment: line-number;

    &::before {
      @include size(8px);
      content: "";
      background-color: #abb8ca;
      border-radius: 50%;
      box-sizing: border-box;
    }

    &.current {
      &::before {
        @include flex($justify: center);
        @include size(30px);
        content: counter(line-number);
        background-color: var(--main-clr);
        border: 4px solid #d9f5f4;
        font-size: 15px;
        font-weight: $bold;
        line-height: 18px;
        color: var(--bg-clr2);
      }

      .txt {
        font-size: 18px;
        font-weight: $bold;
        line-height: 21px;
        color: var(--main-clr);
      }
    }

    &:not(.current) .txt {
      @include blind;
    }
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Process Step
\*----------------------------------------*/

.process-step {
  display: block;
  float: right;
  margin-top: 12px;

  li {
    width: 140px;
    float: left;
    color: #666;
    font-size: 12px;
    padding: 14px 40px 0;
    position: relative;

    &:first-child,
    &:last-child {
      width: 140px;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      height: 1px;
      background-color: #ccc;
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
    }

    &::before {
      right: 50%;
      margin-right: 22px;
    }

    &::after {
      left: 50%;
      margin-left: 22px;
    }

    &.active-step {
      color: #333;
      font-weight: 500;

      .step-txt {
        color: #333;
        font-weight: 500;
      }

      .step-num {
        background-color: #333;
        color: #333;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 5px;
          height: 12px;
          border: 1px solid #fff;
          border-width: 0 2px 2px 0;
          margin: -8px 0 0 -3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          border-radius: 1px;
        }
      }
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .step-txt {
    width: 100%;
    position: absolute;
    top: -18px;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 1.2;
    word-break: keep-all;
    color: #666;
  }

  .step-num {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    border-radius: 100%;
    background-color: #909090;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .process-step {
      display: none;
    }
  }
}
