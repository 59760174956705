@charset "utf-8";

/*----------------------------------------*\
  || Error Page 에러페이지
\*----------------------------------------*/

.common-notice-wrap {
  @include flex($direction: column, $justify: center);
  position: fixed;
  inset: 0;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  * {
    max-width: 520px;
  }

  .img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 10px;
  }

  .icon {
    width: 16px;
    height: auto;
    min-width: 16px;
    margin-right: 5px;
    vertical-align: middle;
  }

  h3.title {
    font-size: 24px;
    line-height: 28px;
    color: var(--tit-clr);
    text-align: center;
  }

  strong.title {
    font: inherit;
    font-weight: $bold;
    color: inherit;
  }

  .title em,
  strong.txt em {
    font: inherit;
    color: var(--main-clr);
  }

  .title + p.txt {
    margin-top: 20px;
  }

  .title + strong.txt {
    margin-top: 8px;
  }

  .txt {
    font-size: 16px;
    line-height: 19px;
    color: #666;
    text-align: center;
    word-break: auto-phrase;

    em {
      font: inherit;
      font-weight: $medium;
      color: var(--body-clr);
    }

    & + .txt {
      margin-top: 20px;
    }
  }

  .notice-line {
    @include size(100%, 1px);
    margin: 30px auto;
    background-color: #e6e8e8;
    border: 0;
  }

  .box {
    @include flex($gap: 10px, $align: normal);
    width: 100%;

    & + .box {
      margin-top: 20px;
    }

    dt {
      @include flex($justify: center);
      min-width: fit-content;
      height: 25px;
      padding: 3px 10px;
      border: 1px solid var(--main-clr);
      border-radius: 15px;
      font-size: 14px;
      font-weight: $bold;
      line-height: 16px;
      color: var(--main-clr);
    }

    dd {
      font-size: 16px;
      font-weight: $medium;
      line-height: 25px;
      color: var(--tit-clr);
    }
  }

  .notice {
    margin-top: 9px;
    padding-left: 12px;
    font-size: 12px;
    line-height: 14px;
    color: var(--cap-clr);
    text-indent: -12px;

    &::before {
      content: '※';
      display: inline-block;
      margin-right: 5px;
      text-indent: 0;
    }
  }
}

@include respond-to ("medium") {
  .common-notice-wrap {
    padding-bottom: 90px;

    .txt br {
      display: none;
    }

    .box {
      gap: 10px;
      align-items: flex-start;
      flex-direction: column;
    }

    .basic-btn-wrap {
      position: fixed;
      inset: 20px;
      top: initial;
      width: calc(100% - 40px);
      max-width: none;
      margin: 0;
    }

    .basic-btn {
      max-width: none;
    }
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || Search : 전체메뉴 검색
\*----------------------------------------*/

.dim-search-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
}

.dim-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  padding: 70px 77px 0px 77px;
  font-size: 16px;
  overflow-y: scroll;
}

.search-01dep {
  padding-bottom: 30px;
  color: var(--bg-clr2);
  font-size: 24px;
}

.search-02dep-wrap {
  border-top: 2px solid var(--bg-clr2);

  > li {
    width: 230px;
    padding: 30px 0 50px;
    color: var(--bg-clr2);
    font-size: 18px;
  }
}

.search-03dep-wrap li {
  a {
    margin-top: 15px;
    color: #efefef;
    font-size: 16px;
  }

  &:first-child a {
    margin-top: 20px;
  }
}

.search-result {
  li {
    padding: 18px 0;
    border-top: 1px solid #999999;

    &:last-child {
      border-bottom: 1px solid #999999;
    }

    a {
      padding-left: 20px;
      color: var(--bg-clr2);
    }
  }
}

span.search-arrow {
  width: 7px;
  height: 11px;
  padding-top: 5px;
  margin: 0 10px;
  display: inline-block;
  background: url('/img/common/icon_search_arrow.png') no-repeat;
}

/*----------------------------------------*\
  || Notice
\*----------------------------------------*/

.notice-info-box {
  width: 100%;
  padding: 20px;
  background: #efefef;
  border-radius: 7px;
  font-size: 18px;
  font-weight: $bold;
  line-height: 25px;
  word-break: keep-all;
}

.notice-info-txt {
  padding: 25px 35px;
  font-size: 18px;
  line-height: 27px;
}

.notice-smart-wrap {
  padding: 2px 20px 15px;
  background: #efefef;
  border-radius: 7px;
}

.notice-smart-title {
  padding: 20px 0 15px 0;
  font-weight: $bold;
  font-size: 15px;
}

.notice-smart-list {
  font-size: 14px;

  li {
    font-size: 14px;
    line-height: 24px;

    strong {
      margin-right: 5px;
      font-weight: $bold;
    }
  }
}

/*----------------------------------------*\
  || 에러 404
\*----------------------------------------*/

.title-top.error-top {
  justify-content: center;
}

.error-link {
  display: inline-block;
  margin-top: 10px;
  font-size: 22px;
  font-weight: $medium;
  text-decoration: underline;
}

/*----------------------------------------*\
  || 안내화면 바로가기
\*----------------------------------------*/

.guide-wrap {
  height: 420px;
  background: #eee;
  text-align: center;
  font-size: 22px;
  margin-top: 50px;

  h3 {
    padding-top: 100px;
    color: #ff0000;
    font-weight: $medium;
  }
}

/*----------------------------------------*\
  || 권유직원
\*----------------------------------------*/

.employee-box {
  .notice-list li {
    font-weight: $medium;
    font-size: 16px;
  }

  .select-basic {
    width: 140px;
  }

  .input-basic {
    width: 259px;
    margin-left: 7px;
  }

  .btn-sm-wh {
    margin-left: 8px;
  }
}

/*----------------------------------------*\
  || Error : 오류 페이지
\*----------------------------------------*/

.error-wrap.type02 {
  width: 100%;
  max-width: 700px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  text-align: center;

  .error-title {
    font-size: 0;
    padding: 0 0 16px;

    .title01 {
      font-size: 48px;
      color: var(--body-clr);
      font-weight: $bold;
      margin-right: 15px;
    }

    .title02 {
      font-size: 32px;
      color: var(--cap-clr);
      font-weight: $bold;
    }
  }

  .error-cont {
    background: #eeeff1;
    border-top: 1px solid var(--disable-clr);
    padding: 32px 16px;

    li {
      font-size: 16px;
      font-weight: $bold;
      padding-bottom: 10px;
      line-height: 1.4;
      letter-spacing: -1px;
    }
  }

  .btn-area {
    padding: 15px 0 0;
  }

  .btn-main {
    display: inline-block;
    padding: 10px 20px;
    background: #666;
    color: var(--bg-clr2);
    font-size: 12px;
    font-weight: $bold;
    text-decoration: none;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.error {
    font-size: 16px;
  }

  .error-wrap.type02 {
    width: calc(100% - 40px);
    margin: auto;
  }
}
