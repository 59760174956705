@charset "utf-8";

/*----------------------------------------*\
  || Details List
\*----------------------------------------*/

.details-list {
  display: grid;
  grid-template-columns: max-content auto;
  justify-content: space-between;
  align-items: baseline;
  gap: 4px 8px;
  width: 100%;

  &__name {
    font-size: 13px;
    line-height: 20px;
    color: var(--body-clr2);
  }

  &__value {
    font-size: 14px;
    line-height: 20px;
    color: var(--body-clr);
    text-align: right;

    em {
      font-size: 18px;
      font-weight: $bold;
      line-height: 27px;
      color: var(--main-clr);
    }
  }
}

/*----------------------------------------*\
  || Under Line List
\*----------------------------------------*/

.under-line-list {
  @include flex($direction: column, $justify: space-between);

  >li {
    @include flex($justify: space-between);
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid var(--line-clr3);

    &:first-child {
      padding-top: 0;
    }

    .txt {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

@include respond-to ("medium") {
  .under-line-list>li {
    padding: 10px 0;

    .txt {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

/*----------------------------------------*\
  || Number Circle List
\*----------------------------------------*/

.number-circle-list {
  @include flex($direction: column, $gap: 10px, $align: normal);

  &.grey {
    li {
      color: var(--cap-clr);

      &::before {
        background-color: var(--disable-clr);
      }
    }
  }

  li {
    counter-increment: line-number;
    padding-left: 25px;
    font-size: 14px;
    line-height: 18px;
    text-indent: -25px;
    color: #666;

    &::before {
      @include size(17px);
      content: counter(line-number);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 17px;
      margin-right: 8px;
      background-color: var(--tit-clr);
      border-radius: 50%;
      font-size: 10px;
      color: var(--bg-clr2);
      text-indent: 0;
    }
  }
}

/*----------------------------------------*\
  || Message List
\*----------------------------------------*/

.msg-list {
  @include flex($direction: column, $gap: 10px, $align: normal);

  &.grey li {
    color: var(--cap-clr);
  }

  li {
    counter-increment: line-number;
    padding-left: 25px;
    font-size: 14px;
    line-height: 18px;
    text-indent: -25px;
    color: #666;

    &::before {
      @include size(17px);
      @include bgImg($url: '/img/common/icon/icon_mark.png');
      content: '';
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 17px;
      margin-top: -1px;
      margin-right: 8px;
      border-radius: 50%;
      text-indent: 0;
      vertical-align: middle;
    }
  }
}

/*----------------------------------------*\
  || Notice List New / 알아두세요 New
\*----------------------------------------*/

.notice-title-new {
  @include flex($gap: 6px);
  margin-top: 60px;
  font-size: 16px;
  font-weight: $bold;
  line-height: 19px;
  color: var(--tit-clr);

  &::before {
    @include size(20px);
    @include bgImg($url: '/img/common/icon/icon_info02.png');
    content: '';
  }

  &+ul {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #d2d4d4;
  }
}

.notice-list-new {
  &.type-bg {
    padding: 20px;
    background-color: #fcfcfc;
    border-radius: 10px;
  }

  li {
    padding-left: 10px;
    font-size: 14px;
    line-height: 16px;
    color: var(--cap-clr);
    text-indent: -10px;

    &::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      margin-bottom: 2px;
      margin-right: 6px;
      background-color: #d9d9d9;
      border-radius: 50%;
      vertical-align: middle;
    }

    &+li {
      margin-top: 15px;
    }

    a {
      display: inline;
    }

    small {
      font-size: 12px;
      color: inherit;
    }

    .btn-tooltip {
      @include size(18px);
      display: inline-block;
      text-indent: 0;
    }
  }
}

/*----------------------------------------*\
  || Mini Link List
\*----------------------------------------*/

.mini-link-list {
  li {
    &+li {
      margin-top: 10px;
    }

    a {
      display: inline;
      font-size: 14px;
      line-height: 16px;
      color: var(--cap-clr);

      &:hover,
      &:focus {
        border-bottom: 2px solid var(--main-clr);
        font-weight: $bold;
        color: var(--main-clr);
      }
    }
  }
}

/*----------------------------------------*\
  || Simple Link List
\*----------------------------------------*/

.simple-link-list {
  @include flex($justify: space-between, $gap: 10px);

  .line {
    @include size(1px, 14px);
    background-color: var(--disable-clr);
  }
}

.simple-link-item {
  font-size: 14px;
  line-height: 16px;
  color: var(--tit-clr);

  &:hover {
    text-decoration: underline;
  }
}

/*----------------------------------------*\
  || Column Line List
\*----------------------------------------*/

.column-line-list {
  @include flex;
  width: 100%;

  .column-line-item {
    @include flex($direction: column, $gap: 12px, $justify: center);
    flex: 1;
    padding: 0 10px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &+li {
      border-left: 1px solid var(--line-clr3);
    }

    .text {
      font-size: 16px;
      line-height: 19px;
      color: var(--tit-clr);
    }

    .text02 {
      font-size: 18px;
      line-height: 21px;
      color: var(--cap-clr);
    }

    .img {
      width: auto;
      height: 80px;
      margin-bottom: 14px;
    }

    .arrow-link {
      font-weight: $bold;
    }
  }
}


@include respond-to ("medium") {
  .column-line-list {
    gap: 25px;
    flex-direction: column;

    .column-line-item {
      width: 100%;
      padding: 0;

      &+li {
        padding-top: 25px;
        border: 0;
        border-top: 1px solid var(--line-clr3);
      }
    }
  }
}

/*----------------------------------------*\
  || Column Arrow List
\*----------------------------------------*/

.column-arrow-list {
  @include flex($gap: 40px, $align: normal, $justify: center);
  width: 100%;

  .column-arrow-item {
    @include flex($direction: column, $gap: 12px);
    width: 80px;
    white-space: nowrap;

    &:nth-child(even) {
      @include size(16px);
      margin-top: 32px;
    }

    .num {
      @include flex($justify: center);
      @include size(18px);
      background-color: var(--main-clr);
      border-radius: 50%;
      font-size: 12px;
      line-height: 14px;
      color: var(--bg-clr2);
    }

    .text {
      font-size: 18px;
      line-height: 27px;
      color: var(--cap-clr);
      text-align: center;

      small {
        display: block;
        font-size: 14px;
        line-height: 21px;
        color: inherit;
      }
    }

    .img {
      width: auto;
      height: 80px;
      margin-bottom: 8px;
    }
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

.notice-list-none li::before {
  display: none;
}

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || Acco list
\*----------------------------------------*/


/* 신규 계좌선택 리스트 */

.acco-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #ccc;
  margin-bottom: 10px;
  padding: 20px;

  &.on {
    border-color: var(--main-clr);
  }

  .acco-box {
    margin-left: 20px;
  }

  .acco-name {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .acco-money {
    align-self: end;
    font-size: 18px;
  }
}

/*----------------------------------------*\
  || Bank list
\*----------------------------------------*/

.bank-list {
  margin-top: 20px;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;

  li {
    a {
      display: block;
      font-size: 14px;
      padding: 15px 10px;
      border: 1px solid var(--disable-clr);
      width: 100px;
      text-align: center;
      border-radius: 15px;
    }

    img {
      display: block;
      margin: auto auto 15px;
      width: 30px;
    }
  }
}

/*----------------------------------------*\
  || List Style 2채널 인증
\*----------------------------------------*/

.list-style {
  color: #666;

  li {
    color: inherit;
    line-height: 1.2em;
    padding-bottom: 10px;

    &::before {
      content: "";
      float: left;
      margin-right: 10px;
      margin-top: 5px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #999;
    }
  }

  &.type01 li {
    font-size: 16px;
  }

  &.type02 li {
    margin-left: 20px;
    font-size: 14px;

    &::before {
      float: none;
    }

    .txt-bold {
      color: #666;
    }
  }

  &.table-list {
    padding: 10px 0;

    li {
      margin-top: 5px;
      padding-left: 15px;
      line-height: 23px;
      padding-bottom: 0;
      color: #666;

      &:first-child {
        margin-top: 0;
      }

      &::before {
        margin-left: -15px;
        margin-top: 9px;
      }
    }
  }
}

.checked-txt {
  padding: 10px 10px 10px 25px;
  background: url("/img/common/checked_icon.png") no-repeat left center;
  display: block;

  &.t-disable {
    background: transparent;
    color: var(--cap-clr);
  }
}

/*----------------------------------------*\
  || Notice List
\*----------------------------------------*/

.notice-list02>li {
  position: relative;
  margin-bottom: 7px;
  padding-left: 18px;
  line-height: 1.6;
  font-size: 13px;
  color: var(--body-clr);

  &::before {
    position: absolute;
    content: "";
    top: 7px;
    left: 6px;
    width: 4px;
    height: 4px;
    background-color: var(--main-clr);
    border-radius: 50%;
  }
}

/*----------------------------------------*\
  || List Style01
\*----------------------------------------*/

.list-style01 {
  margin-top: 8px;

  li {
    padding-left: 0 !important;
    margin-top: 2px;
    line-height: 1.5 !important;

    &::before {
      display: none;
    }
  }
}

/*----------------------------------------*\
  || Notice List Wrap
\*----------------------------------------*/

.notice-list-wrap {
  position: relative;
  padding-top: 30px;
  background-color: var(--bg-clr2);

  &:first-child {
    padding-top: 0;
  }

  &.type01 {
    padding-top: 20px;
  }

  &.type02 {
    background-color: transparent;

    .notice-list {
      background: transparent;

      li {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  &+.tab-basic.pt50 {
    margin-top: -50px;
  }

  .info-title,
  .notice-i-list-wrap,
  &+.tab-basic .pc-tab,
  &+.tab-basic+.tab-style01 {
    margin-top: 0;
  }

  .p {
    margin-bottom: 10px;
    line-height: 23px;
  }

  .notice-i-list {
    display: flex;
    gap: 0;
    color: var(--body-clr);
    font-size: 14px;
    font-weight: $medium;
  }

  .sub-text {
    font-size: 14px;
  }
}

.sub-text+.notice-list {
  margin-top: 10px;
}

.product-view+.notice-list-wrap,
.table-wrap01+.notice-list-wrap {
  margin-top: 20px;
}

.tts+.notice-list-wrap,
.info-table-area+.notice-list-wrap {
  margin-top: 40px;
}

.notice-list-wrap+.tab-basic,
.notice-list-wrap+.search-select-area,
.notice-list-wrap+.search-table-area,
.notice-list-wrap+.cach-content-wrap,
.notice-list-wrap+.info-table-area,
.notice-list-wrap+.slide-box-area,
.notice-list-wrap+.slide-box-area.type02,
.notice-list-wrap+.ui-accordion-list,
.notice-list-wrap+.notice-area-wrap,
.notice-list-wrap+.document-area,
.notice-list-wrap+.dot-list-wrap,
.notice-list-wrap+.login-box-wrap,
.notice-list-wrap+.battery-confirm,
.notice-list-wrap+.table-wrap01 {
  margin-top: 35px;
}

.notice-list-wrap02 {
  margin-top: 0;
}

.notice-list-wrap02+.dot-list-wrap {
  margin-top: 35px;
}

.notice-list-wrap03 {
  margin-top: 16px;
}

.notice-list-wrap03+.info-table-area {
  margin-top: 45px;
}

.notice-list-wrap04 {
  margin-left: 30px;
  padding-top: 31px;
}

/*----------------------------------------*\
  || Notice I List Wrap
\*----------------------------------------*/

.notice-i-list-wrap {
  margin-top: 14px;

  &+& {
    margin-top: 5px;
  }
}

td .notice-i-list-wrap {
  margin: 14px 0 -4px;
}

.notice-i-list {
  display: block;
  font-size: 14px;
  color: #666;
  line-height: 19px;

  &::before {
    @include size(13px);
    @include bgImg($url: '/img/common/notice_icon_m.png');
    content: "";
    display: inline-block;
    margin-right: 5px;
    transform: translateY(2px);
    filter: grayscale(1) opacity(0.7);
  }

  &.txt-333.uif-mobile-type01 {
    padding-left: 0;

    &::before {
      margin-top: 0;
    }
  }

  span {
    line-height: inherit;
  }
}

input~ {
  .notice-i-list {
    margin-left: 15px;
  }

  .notice-i-list.trn-notice-i-list {
    margin-left: 10px;

    &.type02 {
      margin-left: 35px;
    }
  }
}

.trn-rowbox+.notice-i-list {
  margin-top: 12px;
}

.tab-txt-list .notice-i-list {
  font-size: 16px;
}

/*----------------------------------------*\
  || Notice List
\*----------------------------------------*/

.notice-list {
  &.sm li {
    padding-bottom: 10px;
    font-size: 14px;
  }

  &.option01 {
    margin-bottom: 20px;

    li {
      padding-bottom: 10px;
    }
  }

  &.none li {
    padding-left: 0;

    &:before {
      display: none;
    }
  }

  &.nolist-type2 {
    padding: 4px 0 7px;

    li {
      font-size: 14px;
    }
  }

  &.lifetime-info {
    padding: 30px 0;

    &::after {
      position: absolute;
      content: "";
      top: calc(50% - 55px);
      right: 30px;
      width: 550px;
      height: 130px;
      border: 5px solid #eeeff1;
      background: url('/img/common/img_lftmacc_info.jpg') no-repeat center / contain;
    }
  }

  &+.docu-table-area {
    margin-top: 20px;
  }

  li {
    .arrow {
      float: left;
      line-height: 17px;

      &:after {
        content: "";
        float: left;
        width: 24px;
        height: 14px;
        background: url(/img/common/icon_rtarrow.png) no-repeat center center;

        &:first-child:after {
          display: none;
        }
      }
    }

    .notice-i-list {
      font-weight: $regular;

      &::before {
        width: 18px;
        height: 25px;
        background: url(/img/common/notice_icon_m.png) no-repeat left center;
        background-size: 13px;
        background-color: transparent;
        display: inline-block;
        margin-left: 0;
        vertical-align: middle;
        border-radius: inherit;
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .table .table-cell {
    vertical-align: middle !important;
  }

  .font-088 {
    font-size: 14px;
    line-height: 1.3;
    padding-top: 10px;
  }

  .notice-link {
    display: inline-block;
    font-weight: $medium;
    text-decoration: underline;

    &.type02 {
      margin-left: 7px;
    }
  }
}

.notice-list2 {
  width: $wrapSize;
  margin: 0 auto;

  li {
    position: relative;
    font-size: 14px;
    line-height: 1.2;
    padding-bottom: 15px;
    padding-left: 15px;

    span {
      @include size(14px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      border: 1px solid #666;
      border-radius: 50%;
      font-size: 12px;
      color: #666;
    }
  }
}

.notice-list-sm {
  font-size: 11px;
  color: var(--neg-clr);
  padding-top: 10px;
  padding-left: 20px;
}

.notice-listtext {
  font-size: 14px;
  line-height: 23px;
  padding-left: 15px;

  &::before {
    content: "";
    float: left;
    margin: 7px 0 0 -12px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #999;
  }
}

/* 기본 dot style */
.notice-list {
  width: 100%;
  margin: 0 auto;
  background: var(--bg-clr2);

  .notice-list {
    padding: 10px;
  }

  >li {
    margin-top: 5px;
    color: #666;
    font-size: 14px;
    line-height: 23px;
    padding-left: 15px;
    font-weight: $regular;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: "";
      float: left;
      margin-left: -15px;
      margin-top: 9px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: var(--cap-clr);
    }
  }

  &.decimal>li {
    margin-left: 15px;
    padding-left: 0;
    list-style: decimal;

    &::before {
      display: none;
    }
  }

  a {
    display: inline-block;
  }

  li strong {
    color: var(--body-clr);
  }

  /* number style */
  &.type-N>li {
    counter-increment: line-number;

    &::before {
      content: counter(line-number) ". ";
      margin-top: 0 !important;
      background: none !important;
      width: 13px !important;
    }
  }

  /* Dash style */
  &.type-D li::before {
    content: "-";
    margin-top: 0 !important;
    background: none !important;
    width: 9px !important;
  }

  /* 중첩 style : text-indent */
  .indent {
    padding-left: 7px;
    color: inherit;
    font-size: inherit;
    text-indent: -8px;
    line-height: inherit;
  }
}

.notice-list-in li {
  padding-left: 0;

  &:first-child {
    margin-top: 5px;
  }

  &::before {
    display: none;
  }
}

/*----------------------------------------*\
  || Number List
\*----------------------------------------*/


.number-list {
  &.type01 {
    padding-left: 20px;

    li {
      text-indent: -17px;
      line-height: 22px;
    }
  }

  li {
    font-size: 16px;
    line-height: 1.2;
    padding-bottom: 15px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}


/*----------------------------------------*\
  || List Date
\*----------------------------------------*/

.list-date {
  float: right;
  margin-top: 10px;

  >span {
    font-size: 11px;
  }

  &.line-height li {
    line-height: 2.7;
  }

  li span:first-child {
    font-weight: 700 !important;
  }
}

.txt-mid {
  margin: 0 5px;
}

/*----------------------------------------*\
  || Month List
\*----------------------------------------*/

.month-list {
  span {
    float: left;

    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 12px;
      float: left;
      background: #000;
      margin: 0 12px;
    }

    &:first-child::after {
      display: none;
    }
  }
}

/*----------------------------------------*\
  || Trn Notice I List
\*----------------------------------------*/

.trn-notice-i-list {
  display: inline-block;
  line-height: 25px;
  // padding-left: 35px;

  &::before {
    margin-right: 5px;
  }

  &~.date-box2 {
    margin-right: 10px;
  }

  &.mobile-type01 {
    margin-left: 30px;
  }
}

.trn-notice-i-list02 {
  line-height: 40px;
  white-space: nowrap;
  display: flex;
  margin-left: 10px;

  &::before {
    height: 40px;
  }
}

.trn-notice-i-list03 {
  line-height: 40px;
  white-space: nowrap;
  font-size: 14px;

  &::before {
    height: 40px;
  }
}

.trn-notice-i-list04 {
  display: flex;
  margin-top: 10px;
  white-space: nowrap;
  font-size: 14px;

  &::before {
    height: 40px;
  }
}

.trn-notice-i-list05 {
  display: flex;
}

input~ {
  .trn-notice-i-list {
    padding-top: 11px;
    margin-left: 10px;
  }

  .trn-notice-i-list05 {
    padding-left: 20px;
    margin: 10px 10px 0 0;
  }
}

/*----------------------------------------*\
  || Dot List Wrap
\*----------------------------------------*/

.dot-list-wrap {
  width: 100%;
  border-top: 1px solid var(--body-clr);

  li {
    width: 50%;
    float: left;
    padding: 20px;
    font-size: 14px;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid var(--disable-clr);
    min-height: 56px;

    &:nth-child(even) {
      border-right: none;
    }

    &::before {
      content: "";
      margin-right: 10px;
      margin-top: 5px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #999;
      float: left;
    }
  }
}

/*----------------------------------------*\
  || Question List
\*----------------------------------------*/

.question-list {
  li {
    display: flex;
    gap: 8px;
    margin-bottom: 50px;
    line-height: 24px;

    strong,
    p {
      line-height: 24px;
      font-size: 18px !important;
      font-weight: $medium;
    }
  }

  .radio-box-wrap {
    label {
      font-size: 18px;
    }

    .radio-box+.radio-box {
      margin-left: 30px;
    }
  }
}

/*----------------------------------------*\
  || Notice List Hyphen
\*----------------------------------------*/

.notice-list-hyphen>li {
  display: flex;
  gap: 3px;
  font-size: 14px;

  &:before {
    content: '-';
  }

  &.notice-i-list::before {
    @include size(18px, 16px);
    content: "";
    display: inline-block;
    background: url("/img/common/notice_icon_m.png") no-repeat left center;
    background-size: 13px;
    margin-left: -20px;
    padding-left: 15px;
    vertical-align: middle;
  }

  &+li {
    margin-top: 5px;
  }
}

/*----------------------------------------*\
  || List Result
\*----------------------------------------*/

.list-result li {
  display: inline-block;
  position: relative;
  padding-left: 13px;
  margin-left: 13px;
  font-size: 16px;

  &:first-child {
    margin-left: 0;
    padding-left: 0;

    &:before {
      background: none;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: inline-block;
    width: 1px;
    height: 16px;
    background: var(--line-clr3);
  }
}

/*----------------------------------------*\
  || Table Step / 인터넷 대출 절차 / 이용절차
\*----------------------------------------*/

.table-step>li {
  position: relative;
  float: left;
  width: 175px;
  height: 90px;
  margin-right: 10px;
  background: var(--cap-clr);
  text-align: center;

  &:after,
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    right: -10px;
    width: 0;
    height: 0;
    margin-top: -9px;
    border-top: 20px solid var(--cap-clr);
    border-left: 20px solid rgba(0, 0, 0, 0);
    transform: rotate(45deg);
    z-index: 1;
  }

  &:before {
    right: -18px;
    margin-top: -16.4px;
    border-left: 35px solid rgba(0, 0, 0, 0);
    border-top: 35px solid var(--bg-clr2);
  }

  &:last-child {
    margin-right: 0;
  }

  &:nth-child(n + 6) {
    margin-top: 10px;
  }

  &:last-child:after,
  &:last-child:before {
    display: none;
  }

  .num {
    font-weight: $medium;
    font-size: 20px;
    color: var(--bg-clr2);
    margin-bottom: 4px;
  }

  .txt {
    font-size: 14px;
    color: var(--bg-clr2);
    line-height: 17px;
  }
}

.table-step2 {
  &.type01 {
    margin: 12px 0 -5px;

    >li {
      width: 182px;
    }
  }

  &.type02>li {
    margin-bottom: 100px;
  }

  >li {
    width: 152px;
    margin-bottom: 44px;
  }
}

.table-step2,
.table-step3 {
  >li .num {
    padding-top: 16px;
  }

  .table .table-cell {
    vertical-align: top;
  }
}

.table-step-txt,
.table-step-txt2,
.table-step-txt3 {
  position: absolute;
  bottom: -22px;
  right: 0;
  left: 0;
  font-size: 14px;
}

.table-step-txt2 {
  bottom: -46px;
  line-height: 20px;
}

.table-step-txt3 {
  top: 100px;
  line-height: 1.5;
  word-break: keep-all;
}

.table-step3>li {
  width: 152px;
}

.mStep.table-step>li {
  width: 150px
}

.table-step03 {
  width: 100%;

  &+& {
    margin-top: 20px;
  }

  .step-box {
    position: relative;
    display: inline-block;
    width: calc(25% - 13px);
    margin-right: 10px;
    text-align: center;
    vertical-align: top;

    &::before {
      position: absolute;
      content: "";
      right: -21px;
      margin-top: 29px;
      border-left: 35px solid rgba(0, 0, 0, 0);
      border-top: 35px solid var(--bg-clr2);
      transform: rotate(45deg);
      transform: -webkit-rotate(45deg);
      transform: -moz-rotate(45deg);
      transform: -o-rotate(45deg);
      transform: -ms-rotate(45deg);
      z-index: 1;
    }

    .text {
      margin-top: 7px;
      padding-bottom: 15px;
      font-size: 14px;
      line-height: 20px;
      word-break: keep-all;
    }

    .step {
      position: relative;
      height: 90px;
      padding: 16px 0 0 4px;
      background: #909090;

      &::after {
        position: absolute;
        content: "";
        top: 50%;
        right: -10px;
        width: 0;
        height: 0;
        border-left: 20px solid rgba(0, 0, 0, 0);
        border-top: 20px solid #909090;
        transform: rotate(45deg);
        transform: -webkit-rotate(45deg);
        transform: -moz-rotate(45deg);
        transform: -o-rotate(45deg);
        transform: -ms-rotate(45deg);
        margin-top: -9px;
        z-index: 1;
      }

      .num {
        font-weight: $medium;
        font-size: 20px;
        color: var(--bg-clr2);
        margin-bottom: 4px;
      }

      .txt {
        font-size: 14px;
        color: var(--bg-clr2);
        line-height: 20px;
        word-break: keep-all;
      }
    }

    &:last-child .step {
      margin-right: 0;
    }

    &:nth-child(4n)::before,
    &:nth-child(4n) .step::after,
    &:last-child::before,
    &:last-child .step::after {
      display: none;
    }
  }
}

.dim-contents .table-step.pl40 {
  padding: 0 !important;

  .table {
    margin-bottom: 0 !important;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to("huge") {
  html.responsive {
    .notice-list-wrap {
      // background-color: var(--bg-clr2);
      // padding: 15px 16px;

      &+.table-wrap01 {
        margin-top: 0;
      }

      &+.tab-basic,
      &+.tab-basic.pt50 {
        margin-top: 0;
      }
    }

    .pc-tab-content .notice-list-wrap:first-child {
      padding-top: 15px;
    }

    .notice-i-list-wrap {
      margin-top: 10px;
      padding: 0;
      // padding: 10px 16px;

      &+.btn-area button {
        margin-bottom: 5px;
      }

      &+.btn-area button+button {
        margin-top: 5px;
      }
    }

    .notice-i-list {
      margin-top: 10px;
      font-size: 12px;
      // line-height: 1.6;
    }

    .notice-list {
      &.bg {
        background: var(--bg-clr2);
        padding: 30px 20px;
        margin-bottom: -30px;
      }

      li {
        padding: 0;
      }
    }

    .trn-notice-i-list.mobile-type01 {
      margin-left: 0;
    }

    .dot-list-wrap {
      margin-top: 0;
      background-color: var(--bg-clr2);

      li {
        position: relative;
        width: 100%;
        padding-left: 34px;
        line-height: 1.4;
        border-right: 0;

        &::before {
          position: absolute;
          top: 21px;
          left: 20px;
        }
      }
    }

    .warn-area {
      margin-top: 0;
      margin-bottom: 15px;
      background: none;
    }
  }

  .table-step03 {
    margin-top: -5px;

    >li {
      margin-top: 5px;
    }

    .step-box {
      width: 150px;
      min-height: 150px;

      &:first-child .step {
        margin-top: 0;
      }

      &:nth-child(4n)::before,
      &:nth-child(4n) .step::after {
        display: block;
      }

      &:last-child::before,
      &:last-child .step::after {
        display: none;
      }

      .step {
        margin-top: 20px;
      }
    }
  }

  html.responsive .notice-list li,
  ul .common-liststyle01 {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 6px;
    padding-left: 8px;

    &::before {
      margin-left: -8px;
      margin-top: 8px;
      width: 2px;
      height: 2px;
      background-color: var(--body-clr);
    }
  }

  html.responsive .notice-list.decimal>li {
    margin-left: 8px;
    padding-left: 0;
  }
}
