@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.basic-swipe-container.type02 .product-view-dl > li:last-child {
  padding-bottom: 20px;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive .basic-swipe-container.type02 .product-view-dl > li:last-child {
    padding-bottom: 0;
  }
}
