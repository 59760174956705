@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || CEO 인사말
\*----------------------------------------*/

.ceo-info-wrap {
  &.type01 .ceo-sign img {
    margin: 0;
  }

  .ceo-sign.yat-bank img {
    margin: 0 0 -19px 21px;
  }
}

.ceo-img-box .text::before {
  background: #008ed8;
}

/* 스타저축은행 */
.ceo-img-box.star-bank {
  .text {
    top: 183px;

    &::before {
      top: -53px;
      background: #014c9a;
    }
  }
}

.ceo-info-wrap.star-bank {
  .ceo-info .text {
    text-align: center;
  }

  .ceo-sign {
    margin-top: 75px;
    display: none;

    img {
      margin: 0;
    }
  }
}

/*----------------------------------------*\
  || 윤리강령
\*----------------------------------------*/

.ethics-box {
  display: table;
  min-height: 200px;
  padding: 0 320px 0 40px;
  width: 100%;
  background: #f7f7f7 url('/img/hss_company/star_content/bg_ethics.png');

  /* 스타저축은행 */
  &.star-bank {
    width: 100%;
    padding: 55px 291px 56px 39px;
    background: #f7f7f7 url('/img/star_content/bg_ethics.png');

    & + .ethics-list.star-bank {
      margin-top: 38px;
    }

    .text {
      color: var(--body-clr);
      font-size: 18px;
      line-height: 30px;

      & + .text {
        margin-top: 5px;
      }

      strong {
        font-size: 18px;
      }
    }
  }

  .text {
    display: table-cell;
    color: var(--body-clr);
    font-size: 16px;
    vertical-align: middle;
    line-height: 28px;
    word-break: keep-all;

    strong {
      font-size: 18px;
    }

    .ty01 {
      display: block;
      line-height: 30px
    }

    & + .text {
      margin-top: 5px;
    }
  }

  .text-point {
    font-size: 18px;
    font-weight: $medium;
  }

  /* 한화저축은행  */
  &.hanhwa-bank {
    height: 200px;
    background-image: url('/img/hss_companycontent/hanhwa_content/bg_ethics.jpg');

    .text-point {
      color: #f46c0e;
    }
  }

  /* 키움yes 저축은행 */
  &.kiumyes-bank {
    background-image: url('/img/hss_companycontent/kiumyes_content/bg_ethics.jpg');

    .text strong {
      font-size: 20px;
    }

    .text-point {
      color: #1d3d6e;
    }
  }

  /* 청주저축은행 */
  &.cheongju-bank {
    background-image: url('/img/hss_companycontent/cheongju_content/bg_ethics.jpg');
  }

  /* 진주저축은행 */
  &.jinju-bank {
    background-image: url('/img/hss_companycontent/jinju_content/bg_ethics.jpg');
  }

  /* 인천저축은행 */
  &.incheon-bank {
    background-image: url('/img/hss_companycontent/incheon_content/bg_ethics.jpg');
  }

  /* 오성저축은행 */
  &.osung-bank {
    background-image: url('/img/hss_companycontent/osung_content/bg_ethics.jpg');
  }

  /* 안양저축은행 */
  &.anyang-bank {
    background-image: url('/img/hss_companycontent/anyang_content/bg_ethics.jpg');
  }

  /* 바로저축은행 */
  &.baro-bank {
    background-image: url('/img/hss_companycontent/baro_content/bg_ethics.jpg');
  }

  /* 스타저축은행 */
  &.star-bank {
    background-image: url('/img/hss_companycontent/star_content/bg_ethics.jpg');
  }

  /* 스마트저축은행 */
  &.smart-bank {
    background-image: url('/img/hss_companycontent/smart_content/bg_ethics.jpg');
  }

  /* 라온저축은행 */
  &.raon-bank {
    background-image: url('/img/hss_companycontent/raon_content/bg_ethics.jpg');
  }

  /* 드림저축은행 */
  &.dream-bank {
    background-image: url('/img/hss_companycontent/dream_content/bg_ethics.jpg');
  }

  /* 더블저축은행 */
  &.dobble-bank {
    background-image: url('/img/hss_companycontent/dobble_content/bg_ethics.jpg');
  }

  /* 대한저축은행 */
  &.daehan-bank {
    background-image: url('/img/hss_companycontent/daehan_content/bg_ethics.jpg');
  }

  &.daeback-bank {
    background-image: url('/img/hss_companycontent/daeback_content/bg_ethics.jpg');
  }

  /* 대백저축은행 */
  &.namyang-bank {
    background-image: url('/img/hss_companycontent/namyang_content/bg_ethics.jpg');
  }

  /* 금화저축은행 */
  &.kuemhwa-bank {
    background-image: url('/img/hss_companycontent/kuemhwa_content/bg_ethics.jpg');
  }

  /* dh저축은행 */
  &.dh-bank {
    background-image: url('/img/hss_companycontent/dh_content/bg_ethics.jpg');
  }

  /* 모아저축은행 */
  &.moa-bank {
    background-image: url('/img/hss_companycontent/baro_content/bg_ethics.jpg');
  }

  /* 인성저축은행 */
  &.insung-bank {
    height: 350px;
    background-image: url('/img/hss_companycontent/insung_content/bg_ethics.jpg');
    background-position: center;
  }

  &.smart-bank .text,
  &.smart-bank .text span,
  &.dream-bank .text,
  &.dream-bank .text span,
  &.kuemhwa-bank .text span,
  &.kuemhwa-bank .text strong,
  &.namyang-bank .text,
  &.namyang-bank .text span,
  &.namyang-bank .text strong {
    color: var(--bg-clr2);
  }

  & + .tab-basic {
    margin-top: 40px;
  }
}

.ethics-text {
  margin-top: 45px;
  font-size: 18px;
  line-height: 28px;

  &.type01 {
    color: var(--body-clr);
    font-size: 14px;
    text-align: right;
  }
}

.ethics-list {
  margin-top: 38px;

  /* 스타저축은행 */
  .star-bank + .slide-box-area.star-bank {
    margin-top: 58px;
  }
}

.ethics-list-text {
  margin-top: 4px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 24px;
  text-indent: -20px;

  &::before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 15px;
    background: #999;
    border-radius: 50%;
    vertical-align: 3px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.ethics-telegram {
  margin-top: 67px;
  font-size: 24px;
  text-align: center;
}

.ethics-telegram .date {
  display: block;
  padding-bottom: 19px;
  font-size: 18px;
}

/* 인성저축은행 */

.ethics-list-box.insung-bank {
  margin-bottom: 100px;

  .title-eng {
    font-family: GothamPro;
    font-weight: $bold;
    font-size: 60px;
    letter-spacing: -.25px;
    color: #e0e0e4;
    margin-top: 80px
  }

  .title {
    font-weight: $regular;
    font-size: 40px;
    letter-spacing: -.5px;
    color: var(--body-clr);
    margin-top: 30px;
  }

  .text {
    font-size: 18px;
    letter-spacing: -.25px;
    color: #666;
    margin-top: 15px;
  }
}

/*----------------------------------------*\
  || 경영이념
\*----------------------------------------*/

.management-philosophy-box {
  &.star-bank {
    .management-philosophy dt {
      font-size: 14px;
      font-weight: $medium;
    }

    dd.box-style01 {
      height: auto;
      padding: 30px 20px 29px;

      .sub-title {
        margin-top: 0;
        line-height: 22px;
      }
    }

    dd.box-style02 {
      height: auto;
      padding: 26px 20px 24px;

      .sub-style01 {
        margin-top: 0;
        font-size: 14px;
        line-height: 23px;
      }
    }

    dd.box-style03 {
      padding: 19px 20px 17px;
      background: var(--bg-clr2);
      border: 1px solid #014c9a;
      border-radius: 5px;
      font-size: 14px;
      text-align: center;
      line-height: 18px;
    }
  }
}

/*----------------------------------------*\
  || 조직도
\*----------------------------------------*/

.organize-box img {
  width: 100%;

  &.yat-bank img {
    width: 100%;
  }
}

.tablet-viewbox .organize-box {
  margin-top: 30px;
}

.mobile-viewbox .organize-box {
  margin-top: 20px;
}

.info-table-wrap.pyeongtaek-bank .bold {
  font-weight: $medium;
}

/*  조직도 - 평택저축은행 */
.moa-bank .organize-box {
  margin-top: 35px;
}

.organize-table-area {
  overflow: hidden;
  margin-top: 47px;

  .sub-title-area {
    padding-bottom: 0;
  }

  .info-table-area {
    float: left;
    width: 530px;
    margin-top: 20px;
    padding-bottom: 0;

    &:nth-child(2n-1) {
      float: right;
    }

    .table-wrap01 th {
      background: #f0f9fd;
    }

    .table-wrap01 td {
      height: 54px;
      padding: 17px 20px 16px 20px;
    }

    .table-wrap01 tr td:first-child {
      text-align: left;
      font-weight: $bold;
    }
  }
}

/*----------------------------------------*\
  || 키움예스 금융소비자보호체계
\*----------------------------------------*/

.protectinfo-list-box li {
  margin-top: 16px;

  .tit {
    font-size: 16px;
    color: #00397c;
    font-weight: $medium;
  }

  .explain {
    margin-top: 10px;
    font-size: 16px;
    color: var(--body-clr);
    line-height: 1.2;
  }
}

.common-titlestyle01 + .protectinfo-img-box {
  margin-top: 15px;
}

.protectinfo-img-box img {
  width: 100%;
}

/*----------------------------------------*\
  || 키움예스 상품별 리스트 페이지
\*----------------------------------------*/

.common-titlestyle01 + .deposit-item-list {
  margin-top: 10px;
}

.tts + .deposit-item-list {
  margin-top: 82px;
}

.deposit-item-list {
  overflow: hidden;
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);

  li {
    overflow: hidden;

    &:last-child .deposit-item {
      border-bottom: none;
    }

    .deposit-item {
      float: left;
      display: table;
      width: 50%;
      padding: 13px 20px;
      border-bottom: 1px solid #efefef;
      font-size: 14px;
      color: var(--body-clr);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-align: right;
    }

    .name {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
    }

    .btn-thin {
      display: table-cell;
    }

    .deposit-item:first-child {
      border-right: 1px solid #efefef;
    }
  }
}

/*----------------------------------------*\
  || 수수료 : 키움예스/한국투자/모아
\*----------------------------------------*/

.box-list-wrap {
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);

  &.koreatuja .box-list .box-item,
  &.moa-bank .box-list .box-item {
    background: none;
  }

  .box-list {
    overflow: hidden;
    border-top: 1px solid #efefef;

    &:first-child {
      border-top: 0;
    }

    .box-item {
      float: left;
      width: 50%;
      padding: 30px 150px 30px 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      &:first-child {
        background: url('/img/hss_companycontent/kiumyes_content/kiumtes_chargeitem01.png') no-repeat 400px 50%;
      }

      &.box-item:nth-child(2) {
        background: url('/img/hss_companycontent/kiumyes_content/kiumtes_chargeitem02.png') no-repeat 400px 50%;
      }

      &:nth-child(2n) {
        border-left: 1px solid #efefef;
      }

      .explain {
        margin-top: 10px;
        font-size: 16px;
        color: var(--body-clr);
        line-height: 1.4;
      }

      .btn-thin {
        display: inline-block;
        margin-top: 40px;
        text-align: center;
        line-height: 1;
      }
    }
  }
}

.tts + .box-list-wrap {
  margin-top: 40px;
}

/*----------------------------------------*\
  || SR List WP
\*----------------------------------------*/

// .store-searchWp {
//   overflow: hidden;
//   margin-top: 40px;

//   &::after {
//     display: block;
//     content: '';
//     clear: both;
//   }
// }

// .search-tabWp {
//   float: left;
//   width: 478px;
//   min-height: 800px;
//   border: 1px solid var(--disable-clr);
// }

// .sr-listWp {
//   background: var(--bg-clr2);

//   ul {
//     border-bottom: 1px solid #efefef;

//     li {

//       &.on {
//         position: relative;

//         &::before {
//           content: '';
//           position: absolute;
//           left: -1px;
//           bottom: -1px;
//           right: -1px;
//           height: 100%;
//           z-index: 200;
//         }

//         &::after {
//           content: '';
//           position: absolute;
//           left: -1px;
//           top: -1px;
//           right: -1px;
//           height: 100%;
//           border: 2px solid var(--main-clr);
//           z-index: 200;
//         }
//       }

//       .t-logo {
//         float: left;
//         padding: 10px;
//         border: 1px solid #efefef;
//         width: 142px;

//         img {
//           width: 120px;
//           height: 38px;
//           vertical-align: top;
//         }
//       }

//       .s-info {
//         float: left;
//         width: 275px;
//         padding-left: 15px;

//         .tit {
//           display: block;
//           color: #666;
//           font-size: 16px;
//           font-weight: $bold;
//           letter-spacing: -.25px;
//         }

//         p.add,
//         p.add02,
//         p.add03 {
//           color: #666;
//           font-size: 14px;
//           line-height: 18px;
//           letter-spacing: -.25px;
//           margin-top: 5px;
//         }
//       }

//       .paging-wrap a {
//         margin-top: 30px;
//       }

//       a {
//         display: block;
//         padding: 19px 43px 12px 15px;
//         border-top: 1px solid #efefef;
//         background: url(/img/hss_content/hps/ico-map.png) no-repeat 434px 50%;

//         &::after {
//           content: '';
//           display: block;
//           clear: both;
//         }

//         &.active {
//           background: url(/img/hss_common/ico-map02.png) no-repeat 434px 50%;
//           background-size: 30px auto;
//         }
//       }
//     }
//   }
// }

/*----------------------------------------*\
  || New Thumb
\*----------------------------------------*/

.new-thumbWp {
  border-top: 1px solid var(--main-clr);
}

.new-thumb-list .thumb-wp img {
  width: 100%;
  height: 100%;
}

.thumb-wp {
  overflow: hidden;
}

ul.new-thumb-list {
  overflow: hidden;
  margin: 0 -32px;

  li {
    float: left;
    width: 324px;
    margin: 0 32px;
    padding-top: 40px;

    .thumb-wp {
      width: 322px;
      height: 348px;
    }

    .txt-wp {
      padding: 17px 20px;
      min-height: 115px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      .date {
        display: block;
        color: #666;
        font-size: 12px;
        font-weight: $bold;
        letter-spacing: -.25px;
        margin-top: 18px;
      }
    }

    .tit {
      display: block;
      color: var(--body-clr);
      font-size: 16px;
      font-weight: $bold;
      line-height: 24px;
      letter-spacing: -.25px;
    }

    .cmt {
      overflow: hidden;
      display: block;
      height: 48px;
      word-break: break-all;
    }

    > a {
      display: block;
      border: 1px solid var(--disable-clr);
      text-decoration: none;
    }
  }
}


.new-thumbWp.tmb-type02 ul.new-thumb-list li {
  .thumb-wp {
    height: 216px;
  }

  .txt-wp {
    border-top: 1px solid var(--disable-clr);
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  // html.responsive {}

  .ceo-info-wrap {
    .hanhwa-bank .ceo-sign {
      display: none;
    }

    .star-bank .ceo-sign {
      margin-top: 20px;
    }
  }

  .ceo-img-box.star-bank .text {
    top: 5px;
  }

  .store-searchWp {
    overflow: inherit;
    margin: 30px 0 0;
  }

  .store-mapWp {
    float: left;
    padding-left: 60px;
    min-height: 800px;
    clear: both;
    width: 100%;
    height: inherit;
    margin-top: 20px;
    padding: 0 16px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .map-area {
    width: 100%;
    height: 250px;
    min-height: auto;
    margin-top: 30px;

    img {
      width: 100%;
      height: inherit;
    }
  }

  .s-inp-optWp {
    height: inherit;
    padding: 0 16px;
  }

  .store-optWp {
    float: inherit;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;

    .check-optWp {
      padding: 26px 0 20px;

      .chk-wrap {
        display: inline-block;
        width: 32.5%;
        margin-bottom: 13px;
      }
    }
  }

  .search-tabWp {
    width: 100%;
    height: inherit;
    padding-bottom: 20px;
    border-left: 0;
    border-right: 0;
    border-top: 0;

    .tab-basic-buttons-item {
      padding: 9px 10px 8px;
      font-size: 13.3px;
    }
  }

  .ser-result {
    padding: 11px 16px 17px;

    p {
      font-size: 13.3px;
      letter-spacing: -.25px;
      line-height: 20px;

      strong {
        color: #18a33e;
      }
    }
  }

  // .sr-listWp ul li {
  //   a {
  //     padding: 17px 30px 17px 16px;
  //     background: url('/img/hss_common/ico-map-m.png') no-repeat 100% 21px;

  //     &.active {
  //       background: url('/img/hss_common/ico-map-m02.png') no-repeat 100% 21px;
  //       background-size: 28px auto;
  //     }
  //   }

  //   .t-logo {
  //     float: left;
  //     width: 90px;
  //     height: 48px;
  //     margin: 0 13px 0px 0;
  //     padding: 10px 0px 0;
  //     border: 1px solid #efefef;

  //     img {
  //       width: 90px;
  //       vertical-align: middle;
  //     }
  //   }

  //   .s-info {
  //     float: left;
  //     width: 186px;
  //     padding-left: 0px;

  //     .tit {
  //       font-size: 14.6px;
  //       font-weight: $bold;
  //     }

  //     p.add,
  //     p.add02,
  //     p.add03 {
  //       margin-top: 3px;
  //       font-size: 12px;
  //       line-height: 20px;
  //     }
  //   }
  // }

  .regionS .r-info {
    font-size: 13px;
    padding: 15px 10px;
  }

  .r-opt-wp {
    height: inherit;
    background: var(--bg-clr2);
  }

  .rlist-opt li button {
    height: 35px;
    padding: 7px 0;
    text-align: center;

    span {
      display: inline-block;
      font-size: 13px;
      letter-spacing: -.25px;
    }
  }

  .deposit-item-list li {
    .deposit-item {
      float: none;
      width: 100%;

      &:last-child {
        .deposit-item:first-child {
          border-bottom: 1px solid #efefef;
        }

        .deposit-item:first-child {
          border-right: none;
        }

        .deposit-item:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .box-list-wrap {
    margin-top: 20px;
    padding: 0 20px;

    .box-list .box-item {
      float: none;
      width: 100%;
      padding: 30px 0;

      &:first-child {
        background: url('/img/hss_companycontent/kiumyes_content/kiumtes_chargeitem01.png') no-repeat right 50%;
      }

      &:nth-child(2) {
        border-top: 1px solid #efefef;
        border-left: none;
        background: url('/img/hss_companycontent/kiumyes_content/kiumtes_chargeitem02.png') no-repeat right 50%;
      }
    }
  }

  .organize-table-area .info-table-area {
    width: 100%;
  }

  .ethics-list-box.insung-bank {
    margin-bottom: 60px;

    .title-eng {
      font-size: 48px;
      font-weight: $regular;
    }

    .title {
      font-size: 40px;
      margin-top: 40px;
    }

    .text {
      margin-top: 20px;
    }
  }

  .ethics-box.star-bank {
    padding: 43px 238px 44px 39px;
  }
}

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {

  .management-philosophy-box.star-bank dd.box-style01,
  .management-philosophy-box.star-bank dd.box-style03 {
    margin-top: 9px;
  }

  .ethics-box {
    padding: 33px 39px 34px;

    &.star-bank {
      padding: 33px 39px 34px;
      background-image: none;

      & + .ethics-list.star-bank {
        margin-top: 28px;
      }

      .text {
        font-size: 15px;

        &::before {
          width: 3px;
          height: 3px;
          background: #000;
        }

        strong {
          font-size: 15px;
        }
      }
    }

    .text {
      font-size: 15px;

      strong {
        font-size: 15px;
      }
    }
  }

  .ethics-list.star-bank + .slide-box-area.star-bank {
    margin-top: 38px;
  }

  .box-list-wrap .box-list .box-item {
    padding: 150px 0 20px;
    text-align: center;

    &:first-child {
      background: url('/img/hss_companycontent/kiumyes_content/kiumtes_chargeitem01.png') no-repeat 50% 30px;
    }

    &:nth-child(2) {
      background: url('/img/hss_companycontent/kiumyes_content/kiumtes_chargeitem02.png') no-repeat 50% 30px;
    }

    .btn-thin {
      margin-top: 20px;
    }
  }

  .ethics-list-box.insung-bank {
    margin-bottom: 30px;

    .title-eng {
      font-size: 24px;
      margin-top: 40px;
      font-weight: $regular;
    }

    .title {
      font-size: 30px;
      margin-top: 20px;
    }

    .text {
      font-size: 14px;
      margin-top: 5px;
    }
  }
}
