@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || 대출신청
\*----------------------------------------*/

.td-v {
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: var(--bg-clr2);

  &.email-box {
    select {
      margin-left: 5px
    }

    .alpha {
      float: left;
      margin: 0 10px 0 2px;
      color: var(--cap-clr);
      font-size: 14px;
      line-height: 40px;
    }
  }
}

.addr .post .input-basic {
  width: 100px;
  margin-left: 15px;
}

/* 고려저축은행 대출페이지 반응형 */
.ui-accordion-layer.js-ui-accordion-layer.slide-bg01 .slide-box-txt {
  background: #c6dcf4;
}


/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  // html.responsive {}

  .td-v {
    display: block;
    width: 100%;
    padding: 0;

    &.email-box {
      select {
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
      }

      .alpha {
        display: block;
        width: 100%;
        padding: 0;
      }
    }
  }

  .addr .post {
    .fl {
      width: 50px
    }

    .input-basic {
      width: 35%;
    }

    .btn-sm-wh {
      width: 80px;
    }
  }
}

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  // html.responsive {}

  .addr .post {
    .fl {
      width: 100%;
      text-align: center;
    }

    .input-basic {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }

    .btn-sm-wh {
      width: 100%;
      margin-top: 10px;
    }
  }
}
