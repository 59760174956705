@charset "utf-8";

/*----------------------------------------*\
  || GNB
\*----------------------------------------*/

.gnb-depth01-list {
  @include flex;

  .gnb-depth01-item {
    height: 72px;

    &:hover > a::after,
    &:focus-within > a::after {
      background: #0d7a7a;
    }

    > a {
      position: relative;
      padding: 0 30px;
      border-bottom: 4px solid transparent;
      font-size: 18px;
      font-weight: $bold;
      line-height: 72px;
      color: var(--tit-clr);
      transition: border-color 0.2s ease;
      white-space: nowrap;

      &::after {
        @include size(calc(100% - 60px), 4px);
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        background: transparent;
        transition: background 0.2s ease;
      }
    }
  }
}

.gnb-depth02-list {
  @include flex($align: normal, $wrap: wrap, $gap: 50px 60px);
  position: absolute;
  top: 100%;
  left: 50%;
  width: $wrapSize;
  padding: 50px 0;
  transform: translateX(-50%);
  z-index: 1;

  .gnb-depth02-item {
    min-width: 190px;
    font-size: 18px;
    font-weight: $bold;
    color: var(--body-clr);
  }
}


.gnb-depth03-list {
  margin-top: 30px;

  .gnb-depth03-item {
    font-size: 16px;
    color: #666;

    & + li {
      margin-top: 20px;
    }

    > a:hover,
    > a:focus {
      font-weight: $bold;
      color: var(--main-clr);
    }
  }
}

.gnb-bg {
  @include size(100%, 0);
  position: absolute;
  top: 100%;
  left: 0;
  background: #f6fafa;
  border-top: 2px solid transparent;
  opacity: 0;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

/*----------------------------------------*\
  || Search
\*----------------------------------------*/

.search-wrap {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 490px;
  background: #f6fafa;
  border-top: 2px solid #d9d9d9;
  box-shadow: 0 4px 14px rgb(0 0 0 / 10%);
  transition: all 0.3s ease;
  z-index: 2;
  animation: searchWrap 0.5s ease;

  &::after {
    @include size(100%, 70vh);
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    display: block;
    background: linear-gradient(180deg, rgb(27 32 33 / 20%), transparent);
    animation: fadeIn 0.4s ease;
  }

  .search-box {
    display: block;
    width: $wrapSize;
    margin: auto;
    padding: 40px 50px;
    overflow: hidden;
    animation: searchWrap 0.5s ease;
  }
}

@keyframes searchWrap {
  0% {
    max-height: 100px;
    opacity: 0.5;
  }

  40% {
    opacity: 1;
  }

  100% {
    max-height: 490px;
  }
}

.search-inp-wrap {
  @include flex($justify: space-between, $gap: 20px);
  @include size(100%, 60px);
  background: var(--bg-clr2);
  border: 1px solid var(--main-clr);
  border-radius: 35px;
  overflow: hidden;
  transition: all 0.1s ease;

  &:hover,
  &:focus-within {
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
  }

  .inp-search {
    @include size(100%, 60px);
    padding: 0 35px;
    border-radius: 35px;
    font-size: 18px;
    line-height: 21px;
    color: var(--body-clr);
    outline: none;
  }
}

.search-result-wrap {
  position: relative;
  margin-top: 30px;
  padding: 0 35px;

  .search-result-title {
    font-size: 18px;
    line-height: 21px;
    color: var(--body-clr);

    strong {
      color: var(--main-clr);
    }
  }
}

.search-result-list {
  @include flex($direction: column, $gap: 20px, $align: normal);
  max-height: 270px;
  margin-top: 30px;
  padding-right: 10px;
  overflow: auto;
  animation: searchResultList 0.3s ease;

  .search-result-item {
    padding-bottom: 20px;
    border-bottom: 1px solid #d5e6e6;

    a {
      font-size: 16px;
      line-height: 19px;
      color: var(--body-clr);

      span {
        color: var(--main-clr);
      }
    }
  }

  &::-webkit-scrollbar {
    width: 8px;

    &-thumb {
      background: var(--main-clr);
      border-radius: 12px;
    }

    &-track {
      background: #f6fafa;
      border-radius: 12px;
    }
  }
}

@keyframes searchResultList {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 270px;
  }
}

.search-result-msg {
  position: relative;
  max-height: 0;
  padding: 0;
  opacity: 0;
  font-size: 18px;
  font-weight: $bold;
  color: var(--body-clr);
  text-align: center;
  animation: searchResultMsg 0.3s ease forwards;
  animation-delay: 0.6s;

  &::before {
    @include size(80px);
    @include bgImg($url: "/img/content/main/icon_alert.png");
    content: "";
    display: block;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    animation: searchResultMsg2 0.5s ease;
  }
}

@keyframes searchResultMsg {
  0% {
    top: -10px;
    max-height: 0px;
    padding: 0;
    opacity: 0;
  }

  100% {
    top: 0;
    max-height: 270px;
    padding: 180px 0 90px;
    opacity: 1;
  }
}

@keyframes searchResultMsg2 {
  0% {
    top: -70px;
    opacity: 0;
  }

  100% {
    top: 70px;
    opacity: 1;
  }
}

/*----------------------------------------*\
  || Full Menu
\*----------------------------------------*/

.full-menu-wrap {
  position: fixed;
  inset: 0;
  background: #f6fafa;
  z-index: 99;

  .full-menu-box {
    position: relative;
    width: $wrapSize;
    margin: auto;
    padding: 80px 0;

    .full-menu-title {
      font-size: 36px;
      font-weight: $bold;
      color: var(--tit-clr);
    }
  }
}

.full-menu-list {
  @include flex($wrap: wrap, $gap: 20px);
  margin-top: 40px;

  .full-menu-btn {
    min-width: 120px;
    height: 44px;
    padding: 0 40px;
    background: var(--bg-clr2);
    border: 1px solid #d9d9d9;
    border-radius: 30px;
    font-size: 20px;
    color: var(--tit-clr);
    transition: box-shadow 0.2s ease;

    &.on,
    &:hover {
      background: linear-gradient(90deg, #33c7c4, var(--main-clr));
      border-color: transparent;
      font-weight: $bold;
      color: var(--bg-clr2);
    }

    &:hover {
      background: darken($color: $mainClr, $amount: 3%);
    }
  }
}

.full-menu-depth01-list {
  max-height: calc(100vh - 340px);
  margin-top: 60px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;

    &-thumb {
      background: var(--main-clr);
      border-radius: 12px;
    }

    &-track {
      background: #f6fafa;
      border-radius: 12px;
    }
  }

  .full-menu-depth01-item {
    & + li {
      margin-top: 60px;
      padding-top: 60px;
      border-top: 1px solid #d9d9d9;
    }

    > a,
    > span {
      font-size: 24px;
      font-weight: $bold;
      line-height: 28px;
      color: var(--main-clr);
    }

    a {
      display: inline;
      outline-offset: -1px;
    }

  }
}

.full-menu-depth02-list {
  @include flex($align: normal, $wrap: wrap, $gap: 50px);
  margin-top: 40px;

  .full-menu-depth02-item {
    width: 190px;

    > a,
    > span {
      font-size: 18px;
      font-weight: $bold;
      line-height: 21px;
      color: var(--body-clr);
    }
  }
}

.full-menu-depth03-list {
  margin-top: 30px;

  .full-menu-depth03-item {
    & + li {
      margin-top: 20px;
    }

    > a {
      font-size: 16px;
      line-height: 14px;
      color: #666;
    }
  }
}

.full-menu-close-btn {
  @include size(36px);
  @include bgImg($url: "/img/content/main/icon_delete02.png");
  position: absolute;
  top: 80px;
  right: 0;
}

/*----------------------------------------*\
  || Full Menu Mobile
\*----------------------------------------*/

.full-menu-mob-box {
  @include size(100%);
  position: relative;
  background: var(--bg-clr2);
  animation: fullMenuMob 0.5s ease;

  .full-menu-head {
    height: 56px;
    padding: 16px 0;

    .home {
      @include size(24px);
      @include bgImg($url: "/img/content/main/icon_home.png");
      margin-left: auto;
      margin-right: 60px;
    }
  }
}

@keyframes fullMenuMob {
  0% {
    left: 100%;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

.full-menu-login-box {
  @include flex;
  padding: 0 16px 14px;

  .full-menu-user-box {
    @include size(50px);
    margin-right: 10px;
  }

  img {
    width: 50px;
    border: 1px solid rgba(0, 0, 0, 0.0784313725);
    border-radius: 50%;
    filter: grayscale(1);

    &.on {
      filter: none;
    }
  }

  .text-box {
    @include flex($align: normal, $direction: column, $gap: 10px);

    .title {
      font-size: 18px;
      font-weight: $bold;
      line-height: 24px;
      color: var(--tit-clr);
    }

    .time {
      font-size: 12px;
      line-height: 14px;
      color: #666;
    }
  }

  .btn-mini {
    margin-top: auto;
    margin-left: auto;
  }
}

.full-menu-search-wrap {
  position: relative;
  padding: 8px 16px 18px;

  .full-menu-search-inp-wrap {
    @include flex($gap: 10px);
    height: 40px;
    background: #f5f7f8;
    border: 1px solid #f5f7f8;
    border-radius: 24px;
    transition: all 0.1s ease;

    &:hover,
    &:focus-within {
      background: var(--bg-clr2);
      border-color: var(--main-clr);
    }

    .inp-search.mob {
      @include size(100%, auto);
      padding-left: 17px;
      font-size: 16px;
      line-height: 40px;
      border-radius: 24px;
      outline: none;
    }

    .search-btn {
      @include size(24px);
      min-width: 24px;
      margin-right: 10px;
    }

    .clearbtn {
      @include size(18px);
      min-width: 18px;
    }
  }
}

.full-menu-search-result-wrap {
  position: fixed;
  inset: 0;
  top: 180px;
  padding: 30px;
  background: #f6fafa;
  overflow: hidden;
  animation: searchResultWrap 0.3s ease;
  z-index: 2;

  &::before {
    @include size(100%, 2px);
    content: "";
    position: fixed;
    top: 180px;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #33c7c4, var(--main-clr));
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .search-result-list {
    max-height: 100%;
    margin: 0;
    animation: none;

    .search-result-item {
      padding: 9px 0;
      border: 0;

      a {
        color: #666;
      }
    }
  }
}

@keyframes searchResultWrap {
  0% {
    bottom: 100vh;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

.full-menu-link-list {
  @include flex($justify: space-around);
  position: relative;
  padding: 0 18px 18px;

  &::after {
    @include size(100%, 2px);
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(90deg, #33c7c4, var(--main-clr));
  }

  .full-menu-link-item {
    a {
      @include bgImg($url: "/img/content/main/icon_menu_A01_m.png", $x: left);
      min-height: 24px;
      padding-left: 35px;
      font-size: 15px;
      font-weight: $bold;
      line-height: 24px;
      color: var(--tit-clr);

      &.security {
        background-image: url("/img/content/main/icon_menu_A02_m.png");
      }

      &.notice {
        background-image: url("/img/content/main/icon_menu_A03_m.png");
      }
    }
  }
}

.full-menu-mob-list-wrap {
  position: absolute;
  inset: 0;
  top: 228px;
}

.full-menu-mob-depth01-list {
  @include flex($direction: column, $gap: 20px);
  @include size(150px, 100%);
  padding: 20px 15px;
  background: var(--bg-clr2);
  border-right: 1px solid #f1f3f8;

  .full-menu-mob-depth01-item {
    > button {
      width: max-content;
      min-width: 56px;
      min-height: 39px;
      padding: 10px 14px;
      border-radius: 30px;
      font-size: 16px;
      font-weight: $bold;
      line-height: 20px;
      color: var(--tit-clr);
      word-break: auto-phrase;

      &.on {
        background: var(--main-clr);
        color: var(--bg-clr2);
      }
    }
  }
}

.full-menu-mob-depth02-list {
  position: absolute;
  inset: 0;
  left: 150px;
  background: #fcfdfd;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  z-index: -1;

  &::-webkit-scrollbar {
    width: 5px;

    &-thumb {
      background: var(--main-clr);
    }

    &-track {
      background: #f5f7f8;
    }
  }

  @at-root .full-menu-mob-depth01-item > button.on + .full-menu-mob-depth02-list {
    opacity: 1;
    visibility: visible;
    transition: none;
    z-index: 1;
  }

  .full-menu-mob-depth02-item {
    > a,
    > button {
      @include flex;
      position: relative;
      width: calc(100% - 5px);
      min-height: 65px;
      padding: 15px 45px 15px 24px;
      font-size: 16px;
      line-height: 1.4;
      text-align: left;
      color: #666;
      transition: all 0.3s ease;

      &::after {
        @include size(20px);
        @include bgImg($url: "/img/common/icon_list.png", $size: 8px);
        content: "";
        position: absolute;
        top: 50%;
        right: 17px;
        display: block;
        transform: translateY(-50%) rotate(90deg);
        transition: all 0.3s ease;
      }
    }

    > button {
      padding-right: 45px;

      &.on {
        color: var(--main-clr);

        &::after {
          transform: translateY(-50%) rotate(90deg) scaleX(-1);
          filter: invert(45%) sepia(79%) saturate(3392%) hue-rotate(150deg) brightness(94%) contrast(103%);
        }
      }
    }

    > a::after {
      transform: translateY(-50%);
    }

  }
}

.full-menu-mob-depth03-list {
  max-height: 0;
  padding: 0 30px;
  background: #f5f7f8;
  border: 0 solid transparent;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);

  @at-root .full-menu-mob-depth02-item > button.on + .full-menu-mob-depth03-list {
    max-height: max-content;
    padding: 25px 30px;
    border-top: 1px solid #f1f3f8;
    border-bottom: 1px solid #f1f3f8;
    visibility: visible;
  }

  .full-menu-mob-depth03-item {
    & + li {
      margin-top: 20px;
    }

    a {
      font-size: 14px;
      line-height: 19px;
      color: var(--cap-clr);
      word-break: auto-phrase;
    }
  }
}

.full-menu-close-btn.mob {
  @include size(24px);
  @include bgImg($url: "/img/content/main/icon_close.png");
  position: absolute;
  top: 16px;
  right: 16px;
}

/*----------------------------------------*\
  || Responsive
\*----------------------------------------*/

@include respond-to ("huge") {

  .gnb-depth02-list,
  .search-box,
  .full-menu-box {
    @include responsiveHugeBox;
  }

  .full-menu-wrap .full-menu-box {
    @include size(100%);
    padding: 40px;
  }

  .full-menu-depth01-list {
    max-height: calc(100vh - 280px);
  }

  .full-menu-close-btn {
    top: 40px;
    right: 15px;
  }
}


@include respond-to ("medium") {
  .gnb-new {
    display: none;
  }

  .full-menu-wrap {
    background: transparent;
  }
}

/*----------------------------------------*\
  || Type B
\*----------------------------------------*/

@include respond-to ("medium") {
  .type-B {
    .full-menu-mob-box {
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .full-menu-head {
      @include flex($direction: row-reverse, $justify: space-between);
      position: sticky;
      top: 0;
      height: auto;
      min-height: 44px;
      padding: 10px 58px 19px 20px;
      background: var(--bg-clr2);
      z-index: 1;

      .home {
        @include size(32px);
        @include bgImg($url: '/img/content/main/icon_home_B.png');
        margin: 0 0 auto;
      }
    }

    .full-menu-login-box {
      height: min-content;
      margin-top: 4px;
      padding: 0;

      .link {
        @include flex;
        margin-top: -4px;
        font-size: 16px;
        font-weight: $bold;
        line-height: 19px;
        color: #666;

        &::after {
          @include size(12px, 7px);
          @include bgImg($url: "/img/content/main/icon_up.png");
          content: "";
          transform: rotate(90deg);
          filter: invert(1) brightness(0) opacity(0.7);
        }
      }

      .wrap {
        @include flex($align: normal, $direction: column, $justify: flex-end, $gap: 7px);
      }

      .title-box {
        @include flex($gap: 20px);
      }

      .title {
        font-size: 18px;
        font-weight: $bold;
        line-height: 18px;
        color: var(--tit-clr);
      }

      .time {
        font-size: 12px;
        line-height: 14px;
        color: #666;
      }

      button {
        min-height: 29px;
        padding: 6px 10px;
        background-color: var(--bg-clr1);
        border-radius: 50px;
        font-size: 14px;
        line-height: 16px;
        color: var(--tit-clr);
      }
    }

    .full-menu-search-wrap {
      padding: 10px 20px;

      .full-menu-search-inp-wrap {
        height: 51px;
        background: var(--bg-clr1);
        border: 0;
        border-radius: 100px;

        .inp-search.mob {
          padding-left: 24px;
          border-radius: 100px;
          color: var(--cap-clr);
        }
      }

      .full-menu-search-result-wrap {
        top: 140px;
        background: var(--bg-clr2);

        &::before {
          display: none;
        }
      }
    }

    .full-menu-link-list {
      justify-content: space-between;
      padding: 10px 24px 30px;

      &::after {
        display: none;
      }

      .full-menu-link-item {
        &.line {
          @include size(1px, 12px);
          background: var(--line-clr3);
        }

        a {
          @include bgImg($url: "/img/content/main/icon_menu_B01_m.png", $x: left, $size: 24px);
          padding-left: 28px;
          font-weight: $regular;
          color: var(--tit-clr);

          &.security {
            background-image: url("/img/content/main/icon_menu_B02_m.png");
          }

          &.notice {
            background-image: url("/img/content/main/icon_menu_B03_m.png");
          }
        }
      }
    }

    .full-menu-mob-list-wrap {
      position: static;
    }

    .full-menu-mob-list-menu-box {
      position: sticky;
      top: 83px;
      z-index: 1;
    }

    .full-menu-open-btn {
      @include size(36px);
      @include bgImg($url: "/img/content/main/icon_down_w.png", $size: auto);
      position: absolute;
      top: 18px;
      right: 20px;
      background-color: var(--body-clr);
      border-radius: 50%;
      z-index: 2;
      transition: all 0.4s ease;

      @at-root .type-B .full-menu-mob-depth01-list.on + .full-menu-open-btn {
        opacity: 0.8;
        transform: scaleY(-1);
      }
    }

    .full-menu-mob-depth01-list {
      @include size(auto, 71px);
      position: relative;
      flex-direction: row;
      align-items: center;
      gap: 8px 4px;
      padding: 16px 60px 16px 20px;
      background-color: var(--bg-clr1);
      border: 0;
      overflow-x: auto;
      transition: height 0.4s ease;
      z-index: 1;

      &.on {
        flex-wrap: wrap;
        height: auto;
        padding: 16px 20px;
        overflow: hidden;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .full-menu-mob-depth01-item > button {
        padding: 10px 15px;
        background-color: var(--bg-clr2);
        border-radius: 100px;
        font-weight: $regular;
        color: #666;
        word-break: keep-all;

        &.on {
          background-color: var(--main-clr);
          font-weight: $bold;
          color: var(--bg-clr2);
        }
      }
    }

    .full-menu-mob-list-box {
      @include flex($direction: column, $align: normal);
      padding: 28px 20px;
      background: var(--bg-clr2);
      overflow: auto;
    }

    .full-menu-mob-depth01-title {
      margin-bottom: 20px;

      span {
        font-size: 24px;
        line-height: 28px;
        color: var(--cap-clr);
      }

      @at-root .type-B .full-menu-mob-depth02-list + .full-menu-mob-depth01-title {
        margin-top: 40px;
      }
    }

    .full-menu-mob-depth02-list {
      position: static;
      padding-bottom: 40px;
      background: none;
      border-bottom: 1px solid #eee;
      opacity: 1;
      z-index: 0;
      visibility: visible;
      overflow: visible;

      &:last-child {
        border: 0;
      }

      .full-menu-mob-depth02-item {
        & + li {
          margin-top: 40px;
        }

        > strong {
          font-size: 15px;
          line-height: 18px;
          color: var(--tit-clr);

          a {
            @include bgImg($url: '/img/common/icon_list.png', $x: right, $size: 7px);
          }
        }

        > a {
          @include bgImg($url: '/img/common/icon_list.png', $x: right, $size: 7px);
          width: auto;
          min-height: auto;
          padding: 0;
          font-size: 18px;
          line-height: 21px;
          color: #666;
          outline-offset: -1px;

          &::after {
            display: none;
          }

          &:hover {
            @include mintify(6);
            text-decoration: underline;
            text-underline-offset: 3px;
          }
        }
      }

      .full-menu-mob-depth03-list {
        max-height: max-content;
        margin-top: 16px;
        padding: 0;
        background: none;
        border: 0;
        visibility: visible;

        a {
          @include bgImg($url: '/img/common/icon_list.png', $x: right, $size: 7px);
          font-size: 18px;
          line-height: 21px;
          color: #666;
          outline-offset: -1px;

          &:hover {
            @include mintify(6);
            text-decoration: underline;
            text-underline-offset: 3px;
          }
        }
      }
    }

    .full-menu-close-btn.mob {
      @include size(32px);
      @include bgImg($url: '/img/content/main/icon_close_B.png');
      position: fixed;
      top: 10px;
      z-index: 1;
      animation: fullMenuMobBtn 0.5s ease;
    }
  }
}

@keyframes fullMenuMobBtn {
  0% {
    right: -100%;
    opacity: 0;
  }

  100% {
    right: 16px;
    opacity: 1;
  }
}
