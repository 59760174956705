@charset "utf-8";

/*190530 ie 대응 수정*/

.i-top {
  width: 195px;
  vertical-align: middle;
}

/*190531 ie 대응 및 수정*/

.faq-tab .slide-box-area.type02.qna .slide-box-top .sub-title {
  margin: 23px 0;
}

/*----//190531 ie 대응 및 수정-----*/

/*190311 현정 예적금 추가*/

/*타이틀 옆 리스트*/
.process-step.long li {
  width: 115px;
}


/* Layer Select */
.selectBox {
  display: inline-block;
  position: relative;
  min-width: 150px;
  height: 35px;
  text-align: left;
  cursor: pointer;
  background-color: var(--bg-clr2);
}

.selectBox a {
  display: block;
  padding: 0px 10px;
  font-size: 12px;
  line-height: 33px;
  color: var(--disable-clr);
  background-color: var(--body-clr);
}

.selectBox .selectVal {
  height: 35px;
  line-height: 35px;
  border: 1px solid #666;
}

.selectBox .selectVal a {
  background: var(--body-clr) url(/img/common/icon_select_fff.png) right center/13px 7px no-repeat;
  vertical-align: middle;
}

.selectBox .selectVal a:focus {
  color: var(--bg-clr2);
  background-color: var(--neg-clr);
}

.selectBox .selectMenu {
  display: none;
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  border: 1px solid #222;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
}

.selectBox.up .selectMenu {
  bottom: 35px;
  border-bottom: 0px;
}

.selectBox.down .selectMenu {
  top: 35px;
  border-top: 0px;
}

.selectBox.on .selectMenu {
  display: inline-block;
  display: inline;
  zoom: 1;
}

.selectBox .selectMenu li:hover a {
  background-color: #ebebeb;
}

.selectBox .selectMenu li a {
  border-bottom: 1px solid #666;
}

.selectBox .selectMenu li:last-child a {
  border-bottom: 0;
}

.selectBox .selectMenu li a:focus {
  background-color: var(--neg-clr);
}


/*=============================================*/
/*=============================================*/
/*=============================================*/
/*=============================================*/
/*=============================================*/

/* layer popup */
.layer-wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  opacity: 0;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  text-align: center;
}

.layer-wrap::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.layer-container {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.2;
  white-space: normal;
  background: var(--bg-clr2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* 인뱅 푸터 문제 임시방안 */
.height-auto .js-content-zoom-wrap {
  height: auto !important;
}

/* 조직도 */




/* 인재채용 */
.tab-basic .talent-wrap {
  margin-top: 40px;
}

.talent-title {
  font-size: 22px;
  line-height: 26px;
  margin: 40px 0 10px;
}

.talent-title:first-child {
  margin-top: 0;
}

.talent-text {
  font-size: 16px;
  line-height: 28px;
  color: #666;
  font-weight: $regular;
}

.talent-icon-list::after {
  content: '';
  display: table;
  clear: both;
}

.talent-icon-list {
  border: 1px solid var(--line-clr3);
  position: relative;
}

.talent-icon-list::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 33.3%;
  right: 33.3%;
  bottom: 0;
  border: 1px solid var(--line-clr3);
  border-width: 0 1px;
}

.talent-icon-list-item {
  width: 33.3%;
  float: left;
  box-sizing: border-box;
  padding: 30px 0 28px;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
}

.talent-icon-list-item:nth-child(3n-1) {
  width: 33.4%;
}

.talent-icon-list-item:nth-child(3n+1) {
  clear: both;
}

.talent-icon-list-item:nth-child(3) ~ .talent-icon-list-item {
  border-top: 1px solid var(--line-clr3);
}

.talent-icon-list-item::before {
  content: '';
  display: block;
  width: 130px;
  height: 130px;
  margin: 0 auto 15px;
  border-radius: 100%;
  background: no-repeat 50% 50% #efefef;
}

.talent-icon-list-item-type1::before {
  background-image: url(/img/hss_content/hps/talent-icon-01.png);
}

.talent-icon-list-item-type2::before {
  background-image: url(/img/hss_content/hps/talent-icon-02.png);
}

.talent-icon-list-item-type3::before {
  background-image: url(/img/hss_content/hps/talent-icon-03.png);
}

.talent-icon-list-item-type4::before {
  background-image: url(/img/hss_content/hps/talent-icon-04.png);
}

.talent-icon-list-item-type5::before {
  background-image: url(/img/hss_content/hps/talent-icon-05.png);
}

.talent-icon-list-item-type6::before {
  background-image: url(/img/hss_content/hps/talent-icon-06.png);
}

.talent-icon-list-item-type7::before {
  background-image: url(/img/hss_content/hps/talent-icon-07.png);
}

.talent-icon-list-item-type8::before {
  background-image: url(/img/hss_content/hps/talent-icon-08.png);
}

.talent-icon-list-item-type9::before {
  background-image: url(/img/hss_content/hps/talent-icon-09.png);
}

.talent-icon-list-item-type10::before {
  background-image: url(/img/hss_content/hps/talent-icon-10.png);
}

.talent-icon-list-title {
  display: block;
  padding: 0 30px;
  margin-bottom: 6px;
  font-size: 20px;
  line-height: 24px;
  font-weight: $medium;
}

.talent-icon-list-text {
  display: block;
  padding: 0 30px;
  font-size: 16px;
  line-height: 30px;
  font-weight: $medium;
  color: #666;
  font-weight: $regular;
}

.talent-left-title-list {
  margin: 30px 0;
}

.talent-left-title-list-item + .talent-left-title-list-item {
  border-top: 1px solid var(--line-clr3);
}

.talent-left-title-list-item {
  padding: 33px 0 33px 245px;
  word-wrap: break-word;
  word-break: keep-all;
}

.talent-left-title-list-item::after {
  content: '';
  display: table;
  clear: both;
}

.talent-left-title-list-title {
  display: block;
  float: left;
  margin-left: -245px;
  width: 230px;
  font-size: 20px;
  line-height: 36px;
  font-weight: $medium;
}

.talent-left-title-list-text {
  display: block;
  font-size: 16px;
  line-height: 28px;
  font-weight: $regular;
  color: #666;
}

.talent-info-list {
  margin: 30px 0 90px -70px;
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.talent-info-list-item {
  display: inline-block;
  width: 320px;
  margin-left: 70px;
  text-align: left;
  word-wrap: break-word;
  word-break: keep-all;
  vertical-align: top;
}

.talent-info-list-item:nth-child(3) ~ .talent-info-list-item {
  margin-top: 70px;
}

.talent-info-list-title {
  display: block;
  padding: 0 20px;
  height: 216px;
  font-size: 24px;
  line-height: 30px;
  font-weight: $medium;
  color: var(--bg-clr2);
  background: no-repeat 50% 0;
  text-shadow: 1px 3px 3px #000;
}

.talent-info-list-num {
  display: block;
  padding: 85px 0 8px;
  font-size: 48px;
  line-height: 50px;
  font-weight: $medium;
  color: var(--bg-clr2);
}

.talent-info-list-text {
  display: block;
  padding: 16px 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: $regular;
  border: 1px solid var(--disable-clr);
  box-sizing: content-box;
  min-height: 96px;
}

.talent-icon-list.type01 {
  border-bottom: 0;
  border-right: 0;
}

.talent-icon-list.type01 .talent-icon-list-item {
  border-top: 0;
  border-bottom: 1px solid var(--line-clr3);
}

.talent-icon-list.type01 .talent-icon-list-item:nth-child(3n) {
  border-right: 1px solid var(--line-clr3);
}

@media (max-width: $wrapSize) {
  .talent-icon-list.type01 {
    border-bottom: 1px solid var(--line-clr3);
  }

  .talent-icon-list.type01 .talent-icon-list-item {
    border-right: 1px solid var(--line-clr3);
  }
}

/* 스타 저축은행 */
.talent-icon-list.star-bank .talent-icon-list-item-type1::before {
  background-image: url(/img/hss_companycontent/star_content/talent-icon-01.png);
}

.talent-icon-list.star-bank .talent-icon-list-item-type2::before {
  background-image: url(/img/hss_companycontent/star_content/talent-icon-02.png);
}

.talent-icon-list.star-bank .talent-icon-list-item-type3::before {
  background-image: url(/img/hss_companycontent/star_content/talent-icon-03.png);
}

/* 키움예스 저축은행*/
.talent-icon-list.kiumyes-bank::before {
  z-index: 1;
}

.talent-icon-list.kiumyes-bank .talent-icon-list-item-type1::before {
  background-image: url(/img/hss_companycontent/kiumyes_content/talent-icon-01.png);
}

.talent-icon-list.kiumyes-bank .talent-icon-list-item-type2::before {
  background-image: url(/img/hss_companycontent/kiumyes_content/talent-icon-02.png);
}

.talent-icon-list.kiumyes-bank .talent-icon-list-item-type3::before {
  background-image: url(/img/hss_companycontent/kiumyes_content/talent-icon-03.png);
}

/* 고려 저축은행 */
.talent-wrap.goryeo-bank .talent-sub-title {
  font-size: 36px;
  text-align: center;
}

.talent-icon-list.goryeo-bank {
  border: 0;
}

.talent-icon-list.goryeo-bank::before {
  display: none;
}

.talent-icon-list.goryeo-bank .talent-icon-list-item {
  width: 25%;
  float: left;
  box-sizing: border-box;
  padding: 30px 0 28px;
  border: 0;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
  clear: none;
}

.talent-icon-list.goryeo-bank .talent-icon-list-item:nth-child(4n+1) {
  clear: both;
}

.talent-icon-list.goryeo-bank .talent-icon-list-item:nth-child(4) ~ .talent-icon-list-item {
  border-top: 1px solid var(--line-clr3);
}

.talent-icon-list.goryeo-bank .talent-icon-list-item::before {
  width: 200px;
  height: 200px;
  margin-bottom: 40px;
  background-color: transparent;
  border-radius: 0;
}

.talent-icon-list.goryeo-bank .talent-icon-list-item-type1::before {
  background-image: url(/img/hss_companycontent/goryeo_content/talent-icon-01.png);
}

.talent-icon-list.goryeo-bank .talent-icon-list-item-type2::before {
  background-image: url(/img/hss_companycontent/goryeo_content/talent-icon-02.png);
}

.talent-icon-list.goryeo-bank .talent-icon-list-item-type3::before {
  background-image: url(/img/hss_companycontent/goryeo_content/talent-icon-03.png);
}

.talent-icon-list.goryeo-bank .talent-icon-list-item-type4::before {
  background-image: url(/img/hss_companycontent/goryeo_content/talent-icon-04.png);
}

.talent-icon-list.goryeo-bank .talent-icon-list-title {
  margin-bottom: 15px;
  font-size: 34px;
  color: #9f8438;
}

.talent-icon-list.goryeo-bank .talent-icon-list-title span {
  display: block;
  margin-top: 10px;
  font-size: 18px;
}

.talent-icon-list.goryeo-bank .talent-icon-list-text {
  line-height: 25px;
}

/* 모아 저축은행*/
.sub-text02 {
  margin-top: 7px;
  color: #666;
  font-size: 16px;
  line-height: 30px;
}

.sub-text02 + .talent-icon-list {
  margin-top: 25px;
}

/* 스마트 저축은행 */
.talent-icon-list.type-smart {
  display: flex;
}

.talent-icon-list.type-smart::before {
  display: none;
}

.talent-icon-list.type-smart .talent-icon-list-item {
  float: none;
  width: 20%;
}

.talent-icon-list.type-smart .talent-icon-list-item:nth-child(3) ~ .talent-icon-list-item {
  border: 0;
}

.talent-icon-list.type-smart .talent-icon-list-item-type1::before {
  background-image: url(/img/hss_companycontent/smart_content/talent-icon-01.png);
  background-size: 70px;
}

.talent-icon-list.type-smart .talent-icon-list-item-type2::before {
  background-image: url(/img/hss_companycontent/smart_content/talent-icon-02.png);
  background-size: 70px;
}

.talent-icon-list.type-smart .talent-icon-list-item-type3::before {
  background-image: url(/img/hss_companycontent/smart_content/talent-icon-03.png);
  background-size: 70px;
}

.talent-icon-list.type-smart .talent-icon-list-item-type4::before {
  background-image: url(/img/hss_companycontent/smart_content/talent-icon-04.png);
  background-size: 70px;
}

.talent-icon-list.type-smart .talent-icon-list-item-type5::before {
  background-image: url(/img/hss_companycontent/smart_content/talent-icon-05.png);
  background-size: 70px;
}

.talent-info-list.type-smart .talent-info-list-title {
  text-shadow: none;
  color: #000;
}

.talent-info-list.type-smart .talent-info-list-num {
  color: #ed6408;
}

.talent-icon-list.smart-bank .talent-icon-list-title {
  font-size: 20px;
  color: var(--body-clr);
  line-height: 36px;
}

@media (max-width: 768px) {
  .talent-icon-list.type-smart {
    flex-direction: column;
  }

  .talent-icon-list.type-smart .talent-icon-list-item {
    width: 100%;
    border-bottom: 1px solid var(--disable-clr) !important;
  }
}

/* 인천 저축은행 */
.talent-icon-list.type02 {
  display: flex;
}

.talent-icon-list.type02::before {
  content: none;
  display: none;
}

.talent-icon-list.type02 .talent-icon-list-item {
  flex: 1;
  width: 25%;
}

.talent-icon-list.type02 .talent-icon-list-item {
  border-left: 1px solid var(--line-clr3);
}

.talent-icon-list.type02 .talent-icon-list-item:first-child {
  border-left: none;
}

.talent-icon-list.type02 .talent-icon-list-item:nth-child(3n+1) {
  clear: initial;
}

.talent-icon-list.type02 .talent-icon-list-item:nth-child(3) ~ .talent-icon-list-item {
  border-top: none;
}

.talent-icon-list.type02 .talent-icon-list-text {
  padding: 0 10px;
}

.talent-icon-list .incheon-bank.talent-icon-list-item-type1::before {
  background-image: url(/img/hss_companycontent/incheon_content/talent-icon-01.png);
}

.talent-icon-list .incheon-bank.talent-icon-list-item-type3::before {
  background-image: url(/img/hss_companycontent/incheon_content/talent-icon-02.png);
}

.talent-icon-list .incheon-bank.talent-icon-list-item-type4::before {
  background-image: url(/img/hss_content/hps/talent-icon-03.png);
}

.talent-icon-list .incheon-bank02.talent-icon-list-item-type7::before {
  background-image: url(/img/hss_content/hps/talent-icon-10.png);
}

.talent-icon-list .incheon-bank02.talent-icon-list-item-type10::before {
  background-image: url(/img/hss_content/hps/talent-icon-07.png);
}

.talent-icon-list .incheon-bank02.talent-icon-list-item-type11::before {
  background-image: url(/img/hss_content/hps/talent-icon-11.png);
}

@media (max-width: $wrapSize) {
  .talent-icon-list.type02 {
    display: block;
  }

  .talent-icon-list.type02 .talent-icon-list-item {
    width: 100%;
    border-left: none;
  }
}

/* 진주 저축은행 */
.talent-icon-list .jinju-bank.talent-icon-list-item-type1::before {
  background-image: url(/img/hss_companycontent/jinju_content/talent-icon-01.png);
}

.talent-icon-list .jinju-bank.talent-icon-list-item-type2::before {
  background-image: url(/img/hss_companycontent/jinju_content/talent-icon-02.png);
}

.talent-icon-list .jinju-bank.talent-icon-list-item-type3::before {
  background-image: url(/img/hss_companycontent/jinju_content/talent-icon-03.png);
}

.talent-icon-list .jinju-bank.talent-icon-list-item-type4::before {
  background-image: url(/img/hss_companycontent/jinju_content/talent-icon-04.png);
}

.talent-icon-list-item.jinju-bank::before {
  margin: 0 auto 30px;
}

.talent-icon-list-item.jinju-bank .talent-icon-list-title {
  padding: 0 20px;
}

/* 한국투자 저축은행 */
.talent-icon-list .koreatuja-bank.talent-icon-list-item-type1::before {
  background-image: url(/img/hss_companycontent/koreatuja_content/talent-icon-01.png);
}

.talent-icon-list .koreatuja-bank.talent-icon-list-item-type3::before {
  background-image: url(/img/hss_companycontent/koreatuja_content/talent-icon-03.png);
}

.talent-icon-list .koreatuja-bank.talent-icon-list-item-type4::before {
  background-image: url(/img/hss_companycontent/koreatuja_content/talent-icon-04.png);
}

.talent-icon-list .koreatuja-bank.talent-icon-list-item-type5::before {
  background-image: url(/img/hss_companycontent/koreatuja_content/talent-icon-05.png);
}

.talent-icon-list .koreatuja-bank.talent-icon-list-item-type6::before {
  background-image: url(/img/hss_companycontent/koreatuja_content/talent-icon-06.png);
}

.talent-icon-list .koreatuja-bank.talent-icon-list-item-type7::before {
  background-image: url(/img/hss_companycontent/koreatuja_content/talent-icon-07.png);
}

.talent-icon-list .koreatuja-bank.talent-icon-list-item-type8::before {
  background-image: url(/img/hss_companycontent/koreatuja_content/talent-icon-08.png);
}

.talent-icon-list .koreatuja-bank.talent-icon-list-item-type9::before {
  background-image: url(/img/hss_companycontent/koreatuja_content/talent-icon-09.png);
}

.talent-icon-list .talent-icon-list-item.koreatuja-bank .talent-icon-list-text {
  padding: 0 15px;
}

/* 금웅소비자보호헌장 */
.consumer-box {
  margin-top: 35px;
  padding: 82px 100px 72px 485px;
  background: #f7f7f7 url(/img/hss_common/img_ready.jpg) no-repeat 0 50%;
}

.consumer-box::after {
  content: '';
  display: block;
  clear: both;
}

.consumer-box .logo-box {
  display: none;
  float: left;
  width: 50%;
  padding-top: 18px;
}

.consumer-box .logo-box img {
  height: 77px;
}

.consumer-box .text-box {
  float: left;
  padding-left: 55px;
}

.consumer-box .text-box .text {
  font-size: 16px;
  color: #666;
  line-height: 28px;
  font-weight: $regular;
}

.consumer-box .text-box .text strong {
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
  color: var(--body-clr);
}

.consumer-list {
  margin-top: 45px;
}

.consumer-list li {
  font-size: 18px;
  font-weight: $regular;
  line-height: 40px;
}

.consumer-list li strong {
  margin-right: 25px;
  color: var(--main-clr);
}

.consumer-box.insung-bank {
  margin-top: 35px;
  padding: 82px 100px 72px 485px;
  background: #f7f7f7 url(/img/hss_content/ptc_151.png) no-repeat 40px 50%;
}

/*20201008 김재원 추가*/
/* 한국투자저축은행 */
.consumer-box.koreatuja-bank {
  margin-top: 35px;
  padding: 82px 123px 72px 550px;
  background: #f7f7f7 url(/img/hss_companycontent/koreatuja_content/img_koreatuja_logo.png) no-repeat 40px 50%;
}

/* 모아저축은행 */
.consumer-box.moa-bank {
  margin-top: 35;
  padding: 52px 123px 42px 550px;
  background-image: url(/img/hss_companycontent/moa_content/img_consumer_logo.png);
  background-position: 7%;
}

.consumer-box.moa-bank .text-box {
  padding: 0;
}

.consumer-box.moa-bank .text {
  word-break: keep-all;
}

/* 바로저축은행 */
.consumer-box.baro-bank {
  margin-top: 35px;
  padding: 82px 123px 72px 550px;
  background: #f7f7f7 url(/img/hss_companycontent/baro_content/img_baro_logo.png) no-repeat 105px 50%;
}

/* 민국저축은행 */
.consumer-box.minguk-bank {
  margin-top: 0;
  padding: 52px 90px 42px 433px;
  background-image: url(/img/hss_companycontent/minguk_content/img_consumer_logo.png);
  background-position: 16%;
}

@media (max-width: $wrapSize) {
  .consumer-box {
    margin-top: 30px !important;
    padding: 82px 50px 72px 390px !important;
    background-size: 270px !important;
  }

  .consumer-box.baro-bank {
    background-size: 200px !important;
  }

  /* 바로 저축은행 */
  .consumer-box.minguk-bank {
    background-size: 140px !important;
  }

  /* 민국 저축은행 */

}

@media (max-width: 768px) {
  .consumer-box {
    padding: 70px 20px 20px !important;
    background-position: 50% 20px !important;
    background-size: 190px !important;
  }

  .consumer-box.baro-bank {
    background-size: 150px !important;
  }

  /* 바로 저축은행 */
  .consumer-box.minguk-bank {
    padding-top: 50px !important;
    background-size: 130px !important;
  }

  /* 민국 저축은행 */
}

/* 금융소비자보호조직도 */
.organ-wrap.style02 .organ-wp {
  width: 433px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.organ-wrap.style02 {
  margin-top: 20px;
}

/* mobile */
@media (max-width: $wrapSize) {

  /* 금웅소비자보호헌장 */
  .consumer-box {
    padding: 20px;
  }

  .consumer-box .logo-box {
    float: none;
    width: 100%;
    padding-top: 0;
  }

  .consumer-box .logo-box img {
    height: 30px;
  }

  .consumer-box .text-box {
    float: none;
    width: 100%;
    margin-top: 10px;
    padding-left: 0;
  }

  .consumer-box .text-box .text {
    font-size: 14px;
    line-height: 26px;
  }

  .consumer-box .text-box .text strong {
    margin-bottom: 10px;
    font-size: 17px;
  }

  .consumer-list {
    margin-top: 30px;
    padding-left: 44px;
  }

  .consumer-list li {
    font-size: 14px;
    line-height: 25px;
    text-indent: -39px;
  }

  .consumer-list li strong {
    margin-right: 8px;
  }

  /* 금융소비자보호조직도 */
  .organ-wrap.style02 .organ-wp {
    width: auto;
    display: block;
  }

  .organ-wrap.style02 .organ-area h5 {
    width: 100px;
  }

  /* 인재채용 */
  .talent-wrap {
    margin-top: 20px;
  }

  .talent-icon-list .talent-icon-list-item {
    border-bottom: 1px solid var(--disable-clr);
  }

  .talent-icon-list .talent-icon-list-item:last-child {
    border-bottom: none
  }

  .talent-icon-list-item {
    width: 100%;
    float: none;
  }

  .talent-icon-list::before {
    border: 0;
  }

  .talent-icon-list-item:nth-child(3n-1) {
    width: auto;
  }

  .talent-left-title-list-item {
    padding-left: 0;
  }

  .talent-left-title-list-title {
    float: none;
    margin-left: 0;
  }

  .talent-info-list-item {
    margin-top: 20px;
  }

  .kdicbox-wrap {
    margin-top: 30px !important;
  }
}

@media (max-width: $wrapSize) {
  .organ-wrap {
    margin-top: 20px;
  }
}


/* 금융거래 유의사항,약관,이용사항 등의 텍스트정보페이지 */
.common-titlestyle01 {
  margin-top: 39px;
  color: var(--body-clr);
  font-size: 22px;
  font-weight: $medium;
  line-height: 1.5;
}

.common-titlestyle01:first-child {
  margin-top: 0px;
}

.common-titlestyle02 {
  margin-top: 30px;
  color: var(--body-clr);
  font-size: 18px;
  font-weight: $medium;
  line-height: 1.5;
}

.common-titlestyle02 + .table-wrap01 {
  margin-top: 10px;
}

.noticestyle-list01 li {
  margin-top: 20px;
  color: var(--body-clr);
  font-size: 16px;
  line-height: 1.5;
}

.noticestyle-list01 li:first-child {
  margin-top: 25px;
}

.common-textstyle01 {
  margin: 0 0 0 16px;
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $regular;
  line-height: 36px;
}

.common-textstyle02 {
  margin-top: 2px;
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $regular;
  line-height: 36px;
}

.common-textstyle03 {
  margin-top: 2px;
  color: var(--body-clr);
  font-size: 14px;
  font-weight: $regular;
  line-height: 26px;
}

.common-textstyle03 + .notice-list-wrap {
  margin-top: 5px;
}

.common-textstyle03 a {
  display: inline-block;
}

.common-liststyle01 {
  font-size: 16px;
  line-height: 1.8em;
  padding-bottom: 6px;
  padding-left: 15px;
  font-weight: $regular;
}

.common-liststyle01::before {
  content: '';
  float: left;
  margin-left: -15px;
  margin-top: 9px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #999;
}

.common-liststyle02 {
  display: block;
  position: relative;
  margin-top: 31px;
  padding: 0 0 0 27px;
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $regular;
  line-height: 36px;
}

.common-liststyle02:first-child {
  margin-top: 2px;
}

.common-liststyle02::after {
  content: ' ';
  position: absolute;
  top: 14px;
  left: 16px;
  display: block;
  width: 7px;
  background: #696969;
}

.common-liststyle01 .common-liststyle02 {
  margin: 2px 0 0 -16px;
  padding-left: 13px;
}

.common-list-instyle01 {
  margin: 31px 0 0 -16px;
}

.common-list-instyle01 li {
  padding: 0 0 0 16px;
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $regular;
  line-height: 36px;
  text-indent: -16px;
}

.common-titlestyle01 + .complaint-process {
  margin-top: 20px;
  margin-bottom: 0;
}

.common-titlestyle01 + .notice-list-wrap {
  margin-top: 10px;
}

@media (max-width:$wrapSize) {
  .common-titlestyle01 {
    font-size: 19px;
  }
}

.process-img img {
  max-width: 100%;
}

/* 예금자보호법 */
.kdicbox-wrap {
  overflow: hidden;
  width: 100%;
  margin-top: 65px;
  padding: 30px 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f7f7f7;
}

.kdicbox-wrap .imgbox {
  float: left;
  font-size: 0;
  line-height: 0;
}

.kdicbox-wrap .info {
  float: left;
  margin: 5px 0 0 41px;
}

.kdicbox-wrap .info dt {
  color: #666;
  font-size: 14px;
  font-weight: $regular;
  line-height: 24px;
}

.kdicbox-wrap .info dt .str {
  color: var(--neg-clr);
}

.kdicbox-wrap .info dd {
  color: #666;
  font-size: 14px;
  font-weight: $regular;
  line-height: 24px;
}

.deposit-box {
  margin-top: 20px;
  font-size: 0;
  line-height: 0;
}

@media (max-width: $wrapSize) {
  .kdicbox-wrap .info {
    clear: both;
    margin: 20px 0 0 0;
  }

  .deposit-box .mobile {
    display: block;
  }

  .deposit-box .pc {
    display: none;
  }
}


.arsguide-box {
  overflow: hidden;
  width: 100%;
  margin-top: 40px;
}

.arsguide-box .table-wrap01 {
  margin-top: 7px;
}

.arsguide-box .type01 {
  float: left;
  width: 530px;
}

.arsguide-box .type01 td,
.arsguide-box .type02 td {
  text-align: left
}

.arsguide-box .type02 {
  float: right;
  width: 540px;
}

.bbc-line {
  border-bottom-color: var(--disable-clr) !important;
}

@media (max-width: $wrapSize) {
  .arsguide-box .type01 {
    margin-bottom: 40px;
  }

  .arsguide-box .type02 {
    margin-top: 20px;
    float: none;
  }
}

/* 신안카드 */

.checkcash-cards li {
  position: relative;
  min-height: 160px;
  padding: 40px 180px 30px 331px;
  border-bottom: 1px solid var(--line-clr3);
  background-repeat: no-repeat;
  background-position: 20px 30px;
  -webkit-box-sizing: initial;
  box-sizing: initial;
}

.checkcash-cards li .title {
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $bold;
}

.checkcash-cards li .benefits {
  margin-top: 14px;
  color: #666;
  font-size: 12px;
  font-weight: $bold;
}

.checkcash-cards li .info {
  margin-top: 10px;
  color: #666;
  font-size: 14px;
  font-weight: $regular;
  line-height: 27px;
}

.checkcash-cards li .title + .info {
  margin-top: 35px;
}

.checkcash-cards li .link-box {
  position: absolute;
  right: 20px;
  bottom: 30px;
}

// 240925 _log.scss link-box 겹침 문제 수정 (클래스 추가)
.checkcash-cards-new li .link-box {
  width: auto;
  float: none;
  padding: 0;
}

.checkcash-cards li .link-box .btn-style01 + .btn-style01 {
  margin-left: 7px;
}

.notice-i-list.color-red {
  color: var(--neg-clr);
}

.notice-list.type02 {
  margin: 10px 0 0 20px;
}

.alert-card-txt {
  display: inline-block;
  margin: 15px 0 0 20px;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

.checkcash-cards li .link-box .btn-style01 {
  cursor: pointer;
}

/* 신안카드 태블릿 */
@media (max-width: $wrapSize) {
  .checkcash-cards li {
    padding: 30px 20px 30px 331px;
  }

  .checkcash-cards li .title + .info {
    margin-top: 17px;
  }

  .checkcash-cards li .link-box {
    position: static;
    margin-top: 10px;
  }
}

/* 신안카드 모바일 */
@media (max-width: 768px) {
  .notice-list.type02 {
    margin: 10px 0 0 15px;
  }

  .notice-list.type02 li {
    font-size: 12px;
  }

  .notice-list.type02 li::before {
    content: '';
    float: left;
    margin-left: -10px;
    margin-top: 7px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #999;
  }

  .alert-card-txt {
    display: inline-block;
    margin: 10px 0 0 20px;
    font-size: 12px;
    color: #666;
    line-height: 1.5;
  }

  .checkcash-cards li {
    position: relative;
    min-height: 160px;
    padding: 200px 18px 18px;
    border-bottom: 1px solid var(--line-clr3);
    background-repeat: no-repeat;
    background-position: 20px 30px;
    background-size: auto 150px;
  }

  .checkcash-cards li .link-box {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 20px;
  }
}

/* 경영이념 */
.management-philosophy-box {
  overflow: hidden;
  width: 100%;
  padding: 50px 100px 50px 100px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #efefef;
}

// .management-philosophy {}

.management-philosophy dt {
  clear: both;
  float: left;
  width: 116px;
  padding-top: 40px;
  color: var(--body-clr);
  font-size: 14px;
  line-height: 35px;
}

.management-philosophy dd {
  margin-left: 116px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.management-philosophy dd.box-style01 {
  width: auto;
  overflow: hidden;
  height: 138px;
  padding: 0 20px;
  border-radius: 5px;
  background: #1b569a;
  text-align: center;
}

.management-philosophy dd.box-style01 .sub-title {
  display: block;
  margin-top: 27px;
  color: var(--bg-clr2);
  font-size: 18px;
}

.management-philosophy dd.box-style01 .sub-style01 {
  display: block;
  margin-top: 12px;
  color: var(--bg-clr2);
  font-size: 16px;
}

.management-philosophy dd.box-style01 .sub-style02 {
  display: block;
  margin-top: 24px;
  color: var(--bg-clr2);
  font-size: 14px;
}


.management-philosophy dd.box-style02 {
  width: auto;
  overflow: hidden;
  height: 105px;
  margin-top: 12px;
  padding: 0 20px;
  border-radius: 5px;
  border: 1px solid #1b569a;
  background: #e6e8ee;
  text-align: center;
}

.management-philosophy dd.box-style02 .sub-title {
  display: block;
  margin-top: 24px;
  color: var(--body-clr);
  font-size: 16px;
  line-height: 1.4;
}

.management-philosophy dd.box-style02 .sub-style01 {
  display: block;
  margin-top: 24px;
  color: var(--body-clr);
  font-size: 14px;
  line-height: 1.4;
}

.management-philosophy dd.box-style03 {
  width: auto;
  margin-top: 24px;
  padding: 0;
  background: none;
  overflow: hidden;
}

.management-philosophy dd .list {
  overflow: hidden;
  width: calc(100% + 7px);
  margin-left: -7px;
}

.management-philosophy dd .list li {
  float: left;
  width: 20%;
  padding-left: 7px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.management-philosophy dd .list li .mpsub-title {
  width: 100%;
  height: 50px;
  padding-top: 16px;
  border-radius: 5px;
  border: 1px solid #1b569a;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #e6e8ee;
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $bold;
  text-align: center;
}

.management-philosophy dd .list li .sub-text {
  width: 100%;
  height: 172px;
  margin-top: 10px;
  padding-top: 12px;
  border: 1px solid #1b569a;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: var(--bg-clr2);
  color: var(--body-clr);
  font-size: 14px;
  text-align: center;
  line-height: 24px;
}

.management-philosophy dd .list li .sub-text .gap01 {
  display: block;
  line-height: 24px;
}

@media (max-width:$wrapSize) {
  .management-philosophy-box {
    margin-top: 30px;
  }
}

@media (max-width:768px) {

  .management-philosophy dd.box-style01 .sub-style02 {
    margin-top: 10px;
    line-height: 1.5;
  }

  .management-philosophy dd.box-style02 .sub-style01 {
    margin-top: 10px;
  }

  .management-philosophy dd .list {
    width: 100%;
    margin: 0;
  }

  .management-philosophy dd .list li {
    overflow: hidden;
    width: 100%;
    float: none;
    margin-top: 30px;
    padding: 0;
  }

  .management-philosophy dd .list li:first-child {
    margin: 0;
  }

  .management-philosophy dd .list li .sub-text {
    padding: 0 10px;
  }

  .management-philosophy dd .list li .sub-text .gap01 {
    display: inline-block;
    line-height: 24px;
  }

  .management-philosophy dd .list li .sub-text {
    width: 100%;
    height: auto;
    padding: 20px;
  }
}

@media (max-width:768px) {
  .management-philosophy dd.box-style01 .sub-style01 {
    line-height: 1.5;
  }

  .management-philosophy-box {
    padding: 20px 30px;
  }

  .management-philosophy dd.box-style01 {
    height: auto;
    padding-bottom: 24px;
  }

  .management-philosophy dd.box-style02 {
    height: auto;
    padding-bottom: 24px;
  }
}

@media (max-width:768px) {
  .management-philosophy-box {
    margin-top: 20px;
  }
}

@media (max-width:480px) {
  .management-philosophy dt {
    float: none;
    margin-top: 20px;
    padding-top: 0;
    line-height: 1.5;
  }

  .management-philosophy dt:first-child {
    margin-top: 0;
  }

  .management-philosophy dd {
    margin: 0;

    &[class*=box-style] {
      margin-top: 12px;
    }
  }
}

.table-wrap01 + .notice-date {
  margin-top: 50px;
}

/* 금융소비자보호광장 */
// .link-wrap {}

.gate-list::after {
  content: '';
  clear: both;
  display: table;
}

.gate-list > li {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.gate-list > li.sum-list {
  width: 66.66%;
}

.gate-list > li.sum-list .notice-list-wrap {
  float: left;
  width: 50%;
}

.gate-list .cont-box {
  overflow: hidden;
  border: 1px solid var(--disable-clr);
}

.gate-list .gate-title {
  padding: 20px;
  font-size: 16px;
  border-bottom: 1px solid var(--disable-clr);
}

.gate-list .notice-list-wrap {
  padding: 20px;
  min-height: 290px;
}

@media (max-width:768px) {
  .gate-list {
    margin-top: 40px;
  }

  .gate-list > li {
    float: none;
    width: 100%;
  }

  .gate-list > li.sum-list {
    width: 100%;
  }

  .gate-list .gate-title {
    padding: 15px;
    font-size: 14px;
  }

  .gate-list .notice-list-wrap {
    padding: 15px;
    height: auto;
  }
}


/* [공통]보호금융상품등록부 */
.protection-financial-product {
  padding: 59px 273px 51px 41px;
  background: #eeeff1 url(/img/hss_content/bg_money.gif) no-repeat right 50%;
  color: var(--body-clr);
  font-size: 22px;
  font-weight: $bold;
  line-height: 36px;
}

.protection-financial-product .str {
  display: block;
  color: var(--main-clr);
  font-weight: $bold;
}

.insurance-corporation-box {
  display: table;
  width: 100%;
  margin-top: 22px;
  padding: 21px 22px 21px 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f7f7f7;
}

.insurance-corporation-box .img-box {
  display: table-cell;
  width: 134px;
  vertical-align: middle;
}

.insurance-corporation-box .list {
  display: table-cell;
  padding-left: 55px;
  vertical-align: middle;
}

.insurance-corporation-box .list li {
  color: #666666;
  font-size: 14px;
  line-height: 24px;
}

.protection-financial-product-box {
  width: 100%;
  margin-top: 50px;
  padding: 40px 30px 50px;
  border: 2px solid var(--disable-clr);
}

.protection-financial-product-box .title {
  font-size: 20px;
  font-weight: $bold;
  text-align: center;
}

.protection-financial-product-box .text {
  margin-top: 32px;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
}

.protection-attachment-box {
  margin-top: 39px;
  position: relative;
  width: 100%;
  padding-left: 55px;
}

.protection-attachment-box .protection-attachment-title {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 16px;
  font-weight: $bold;
}

.protection-attachment-box .attachment-list-text {
  color: #0a67b1;
  font-size: 16px;
  font-weight: $bold;
  line-height: 25px;
  text-align: left;
}

.protection-financial-sign {
  margin-top: 38px;
  font-size: 18px;
  font-weight: $bold;
  text-align: center;
  line-height: 24px;
}

.protection-info-box {
  width: 100%;
  margin-top: 34px;
  padding: 24px 38px 20px;
  background: #f3f1ef;
  text-align: left;
}

.protection-info-box .protection-info-text {
  font-size: 14px;
  line-height: 25px;
}

.protection-info-text .str {
  color: #0a67b1;
}

.protection-info-text .str .underline {
  color: #0a67b1;
  text-decoration: underline;
  text-decoration-color: #0a67b1;
}

.protection-info-text .str .underline.link {
  display: inline-block;
}

.protection-info-text + .protection-info-list {
  margin-top: 21px;
  padding-top: 24px;
  border-top: 1px solid #d4d4d4;
}

.protection-financial-product.dh-bank .str {
  color: #0a67b1;
}

/* dh 저축은행 */
.protection-financial-product.goryeo-bank .str {
  color: #2e1263;
}

.protection-financial-product-box.goryeo-bank .protection-attachment-box .attachment-list-text {
  color: #2e1263;
}

.protection-financial-product-box.goryeo-bank .str,
.protection-financial-product-box.goryeo-bank .underline {
  color: #2e1263;
}

/* 고려 저축은행 */

@media (max-width:$wrapSize) {
  .protection-financial-product {
    margin-top: 30px;
  }
}

@media (max-width:768px) {
  .protection-financial-product .str {
    display: inline-block;
  }

  .protection-financial-product {
    margin-top: 20px;
    padding: 33px 41px 29px 41px;
    background-image: none;
    font-size: 18px;
    line-height: 31px;
  }

  .insurance-corporation-box {
    margin-top: 30px;
    padding: 13px 22px 21px 18px;
  }

  .insurance-corporation-box .img-box,
  .insurance-corporation-box .list {
    display: block;
  }

  .insurance-corporation-box .list {
    padding-left: 6px;
  }
}


/* 대출신청안내 */

.steplist-box > li {
  overflow: hidden;
  display: table;
  width: 100%;
  margin-top: 20px;
}

.steplist-box li:first-child {
  margin-top: 0;
}

.steplist-box li .title {
  position: relative;
  display: table-cell;
  width: 275px;
  height: 89px;
  background: var(--main-clr);
  color: var(--bg-clr2);
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}

/* .steplist-box li .title::after {content:' ';position:absolute;top:50%;right:-14px;margin-top:-15px;width:14px;height:29px;background:url(/img/hss_content/com/bul_step_blue.png) no-repeat 0 0;} */
.steplist-box li .title .str {
  display: block;
  margin-bottom: 12px;
  color: var(--bg-clr2);
  font-size: 18px;
  font-weight: $bold;
}

.steplist-box li .info {
  display: table-cell;
  height: 89px;
  padding-right: 20px;
  border-left: 34px solid var(--bg-clr2);
  background: #f7f7f7;
  vertical-align: middle;
}

.steplist-box li .text {
  position: relative;
  padding-left: 44px;
  color: var(--body-clr);
  font-size: 16px;
  line-height: 36px;
}

/* .steplist-box li .text::after {content:'';display:block;position:absolute;top:48%;left:28px;width:5px;height:5px;border-radius:50%;background:#999999;} */
.steplist-box li .text::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 10px;
  border-radius: 50%;
  background: #999999;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width:768px) {
  .steplist-box > li {
    display: block;
  }

  .steplist-box li .title {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    text-align: left;
  }

  .steplist-box li .title .str {
    display: inline-block;
    margin: 0 5px 0 0;
    font-size: 16px;
  }

  .steplist-box li .info {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    border: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .steplist-box li .info .text {
    padding: 0;
  }

  .steplist-box li .text {
    line-height: 1.5;
  }

  .steplist-box li .text::after {
    display: none;
  }
}

/* 대출유의사항 */
.tts + .common-guideinfo {
  margin-top: 40px;
}

.common-guideinfo {
  padding: 55px 322px 47px 41px;
  background: #eeeff1 url(/img/hss_content/bg_money02.png) no-repeat right 32px top 50%;
  color: var(--body-clr);
  font-size: 22px;
  font-weight: $bold;
  line-height: 36px;
}

.common-guideinfo + .bond-list01 {
  margin-top: 50px;
}

.common-guideinfo .str {
  display: block;
  color: var(--main-clr);
  font-weight: $bold;
  line-height: 32px;
}

@media (max-width:768px) {
  .common-guideinfo {
    padding: 33px 41px 29px 41px;
    background-image: none;
    font-size: 18px;
    line-height: 31px;
  }

  .common-guideinfo + .bond-list01 {
    margin-top: 20px;
  }

  .common-guideinfo + .bond-list01 .bond-textstyle01 {
    font-size: 14px;
    line-height: 22px;
  }
}

/* [공통] 전자민원신청 */
.complaint-process {
  margin-bottom: 75px;
  padding: 40px 10px;
  border: 1px solid var(--line-clr3);
}

.complaint-process ol {
  display: table;
  width: 100%;
}

.complaint-process ol li {
  display: table-cell;
  width: 20%;
  text-align: center;
  position: relative;
}

.complaint-process ol li::after {
  content: "";
  display: block;
  width: 21px;
  height: 40px;
  position: absolute;
  top: 47px;
  right: -10px;
  background: url(/img/hss_content/bg_apply_arrow.png) no-repeat 0 0;
}

.complaint-process ol li:last-child::after {
  background: none;
}

.complaint-process ol li p {
  padding-top: 146px;
  font-size: 16px;
  font-weight: $medium;
  line-height: 25px;
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.complaint-process ol li p > span {
  font-size: 14px;
}

.complaint-process ol li p.pcs-type1 {
  background-image: url(/img/hss_content/bg_applyimg01.png);
}

.complaint-process ol li p.pcs-type2 {
  background-image: url(/img/hss_content/bg_applyimg02.png);
}

.complaint-process ol li p.pcs-type3 {
  background-image: url(/img/hss_content/bg_applyimg03.png);
}

.complaint-process ol li p.pcs-type4 {
  background-image: url(/img/hss_content/bg_applyimg04.png);
}

.complaint-process ol li p.pcs-type5 {
  background-image: url(/img/hss_content/bg_applyimg05.png);
}

.complaint-process ol li .txt {
  display: block;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 14px;
  text-align: left;
  line-height: 20px;
}

/* 키움예스 대출신청절차 */
.sub-text01 {
  margin-top: 7px;
  color: var(--body-clr);
  font-size: 16px;
  line-height: 25px;
}

.sub-text01 + .complaint-process {
  margin-top: 16px;
}

.sub-text01 + .protectinfo-img-box {
  margin-top: 20px;
}

.complaint-process.kiumyes-bank {
  padding: 25px 10px 26px;
}

.complaint-process.kiumyes-bank .pcs-type4 {
  color: #00397c;
}

.complaint-process.kiumyes-bank .pcs-type5 {
  padding-top: 154px;
  background-image: url(/img/hss_companycontent/kiumyes_content/bg_applyimg05.png);
  background-position: 50% 0;
  color: #00397c;
}

@media (max-width: $wrapSize) {
  .complaint-process.kiumyes-bank li:nth-child(4)::after {
    top: 75px;
  }
}

@media (max-width: 768px) {
  .complaint-process.kiumyes-bank li:nth-child(4)::after {
    top: auto;
  }
}



.terms-wrap .terms-title {
  line-height: 24px;
}

.terms-wrap .terms-box strong {
  font-weight: $regular;
}

.complaint-popup.str-searPop {
  width: 850px;
}

.complaint-complete {
  padding: 90px 0 40px;
}

.complaint-complete .com-wp {
  display: table;
  width: 100%;
  padding-left: 90px;
  padding-bottom: 30px;
  text-align: left;
  border-bottom: 1px solid var(--disable-clr);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.complaint-complete .com-wp .img-box {
  display: table-cell;
  vertical-align: top;
}

.complaint-complete .com-wp .txt-box {
  display: table-cell;
  vertical-align: top;
  padding-left: 35px;
}

.complaint-complete .com-wp .txt-box h4 {
  color: var(--body-clr);
  font-size: 40px;
  font-weight: $regular;
}

.complaint-complete .com-wp .txt-box p {
  margin-top: 30px;
  color: #666;
  font-size: 18px;
  line-height: 24px;
}

.complaint-complete .com-wp .txt-box p strong {
  color: var(--body-clr);
  font-size: 18px;
  font-weight: $regular;
}

.complaint-complete .com-btn-wp {
  margin-top: 30px;
  margin-bottom: 0;
}

.complaint-complete .com-btn-wp button {
  width: 220px;
}

@media (max-width:768px) {
  .complaint-process {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 40px 10px;
  }

  .complaint-process ol li {
    display: inline-block;
    width: 33%;
    vertical-align: top;
  }

  .complaint-process ol li:nth-child(3)::after {
    display: none;
  }

  .complaint-process ol li:nth-child(4) {
    margin-top: 40px;
  }

  .complaint-process ol li:nth-child(5) {
    margin-top: 40px;
  }

  .complaint-popup.str-searPop {
    width: auto;
    margin: 0 20px;
  }

  .complaint-complete {
    padding: 60px 0 30px;
  }

  .complaint-complete .com-wp {
    padding-left: 30px;
    padding-right: 30px;
  }

  .complaint-complete .com-wp .img-box img {
    width: 100px;
  }

  .complaint-complete .com-wp .txt-box {
    padding-left: 20px;
  }

  .complaint-complete .com-wp .txt-box h4 {
    font-size: 30px;
    line-height: 1.2;
  }

  .complaint-complete .com-wp .txt-box p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
  }

  .complaint-complete .com-wp .txt-box p strong {
    font-size: 16px;
  }
}

@media (max-width:768px) {
  .complaint-complete .com-wp .img-box {
    display: none;
  }

  .complaint-complete .com-wp .txt-box {
    padding-left: 0;
  }
}

@media (max-width:768px) {
  .complaint-process ol li {
    width: 100%;
    padding-top: 40px;
  }

  .complaint-process ol li::after {
    width: 13px;
    height: 25px;
    top: auto;
    bottom: -30px;
    right: 50%;
    margin-right: -6.5px;
    background-size: cover;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }

  .complaint-process ol li:first-child {
    padding-top: 0;
  }

  .complaint-process ol li:nth-child(3)::after {
    display: block;
  }

  .complaint-process ol li:nth-child(4) {
    margin-top: 0;
  }

  .complaint-process ol li:nth-child(5) {
    margin-top: 0;
  }

  .complaint-process ol li:last-child .txt {
    margin-bottom: 0;
  }

  .complaint-process ol li p {
    padding-top: 140px;
    font-size: 17px;
  }

  .complaint-process ol li p > span {
    font-size: 15px;
  }

  .complaint-process ol li .txt {
    margin-bottom: 10px;
  }

  .complaint-complete .com-wp {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .complaint-complete .com-wp .txt-box h4 {
    font-size: 20px;
  }

  .complaint-complete .com-wp .txt-box p {
    margin-top: 10px
  }

  .complaint-complete .com-btn-wp button {
    width: auto;
  }
}



/* 스타 저축은행  */
.ceo-info-wrap.star-bank .ceo-info .text {
  text-align: center;
}

.ceo-info-wrap.star-bank .ceo-sign img {
  margin: 0;
}

.ceo-info-wrap.star-bank .ceo-sign {
  margin-top: 75px;
}

.ethics-box.star-bank {
  width: 100%;
  padding: 55px 291px 56px 39px;
  background: #f7f7f7 url(/img/star_content/bg_ethics.png);
}

.ethics-box.star-bank .text {
  display: block;
  color: var(--body-clr);
  font-size: 18px;
  line-height: 30px;
}

.ethics-box.star-bank .text strong {
  font-size: 18px;
}

.ethics-box.star-bank .text + .text {
  margin-top: 5px;
}

.ethics-box.star-bank + .ethics-list.star-bank {
  margin-top: 38px;
}

.ethics-list-text {
  margin-top: 10px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 18px;
  text-indent: -20px;
}

.ethics-list-text::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 15px;
  background: #999;
  border-radius: 50%;
  vertical-align: 3px;
}

.ethics-list-text:first-child {
  margin-top: 0;
}

.slide-box-area.star-bank .slide-box-top {
  padding: 23px 15px 20px;
}

.slide-box-area.star-bank .slide-box-top .sub-title {
  color: var(--body-clr);
  font-size: 18px;
}

.slide-box-area.star-bank .slide-box-txt {
  height: 213px;
}

.slide-box-area.star-bank .slide-box-txt p {
  font-size: 14px;
}

.slide-box-area.star-bank .slide-box-txt .title {
  margin-bottom: 0;
  font-weight: $medium;
}

.slide-box-area.star-bank .slide-box-txt .text {
  margin-top: 28px;
  margin-bottom: 0;
  line-height: 26px
}

.ethics-list.star-bank + .slide-box-area.star-bank {
  margin-top: 58px;
}

.management-philosophy-box.star-bank .management-philosophy dt {
  font-size: 14px;
  font-weight: $medium;
}

.management-philosophy-box.star-bank dd.box-style01 {
  height: auto;
  padding: 30px 20px 29px;
}

.management-philosophy-box.star-bank dd.box-style01 .sub-title {
  margin-top: 0;
  line-height: 22px;
}

.management-philosophy-box.star-bank dd.box-style02 {
  height: auto;
  padding: 26px 20px 24px;
}

.management-philosophy-box.star-bank dd.box-style02 .sub-style01 {
  margin-top: 0;
  font-size: 14px;
  line-height: 23px;
}

.management-philosophy-box.star-bank dd.box-style03 {
  padding: 19px 20px 17px;
  background: var(--bg-clr2);
  border: 1px solid #014c9a;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  line-height: 18px;
}

.gnb-link:hover,
.gnb-link:focus {
  color: #0282C9
}

.stat-bank.pc-viewbox {
  display: block;
}

.stat-bank.tablet-viewbox {
  display: none;
}

.stat-bank.mobile-viewbox {
  display: none;
}

.header-tab-item.is-active .header-tab-link {
  color: #0282C9
}

.header-count-time-number {
  color: var(--main-clr);
}

.nonfacing-stepbox {
  margin-top: 10px;
  padding: 28px 0 28px 0;
  border: 1px solid var(--line-clr3);
}

.nonfacing-stepbox .step-list {
  display: table;
  width: 100%;
}

.nonfacing-stepbox .step-list li {
  display: table-cell;
  width: 25%;
  text-align: center;
}

.nonfacing-stepbox .step-list li .step {
  height: 189px;
  background-repeat: no-repeat;
  background-position: left 50% top 37px;
}

.nonfacing-stepbox .step-list li .step-style01 {
  background-image: url(/img/hss_content/bg_accountimg01.png);
}

.nonfacing-stepbox .step-list li .step-style02 {
  background-image: url(/img/hss_content/bg_accountimg02.png);
}

.nonfacing-stepbox .step-list li .step-style03 {
  background-image: url(/img/hss_content/bg_accountimg03.png);
}

.nonfacing-stepbox .step-list li .step-style04 {
  background-image: url(/img/hss_content/bg_accountimg04.png);
}

.nonfacing-stepbox .step-list li .text {
  color: var(--body-clr);
  font-size: 16px;
  line-height: 25px;
}

.nonfacing-stepbox + .sub-title-area {
  margin-top: 60px;
}

.notice-subtext.type01 {
  margin-top: 0;
}

.notice-subtext.type01 + .sub-title-area {
  margin-top: 42px;
}

/* 텍스트만 있는 타입 */
.nonfacing-stepbox.type01 {
  margin-top: 20px;
  padding-bottom: 12px;
}

.nonfacing-stepbox.type01 .step-list li .step {
  height: auto;
  background: none;
}

.nonfacing-stepbox.type01 .step-list li .title {
  margin-top: 20px;
  font-weight: $medium;
}

.nonfacing-stepbox.type01 .step-list li .list {
  margin-top: 14px;
}

.nonfacing-stepbox.type01 .step-list li .text {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: $regular;
}

.steplistbox-wrap {
  display: table;
  width: 100%;
}

.steplistbox-wrap li {
  position: relative;
  display: table-cell;
  width: 1%;
  height: 89px;
  background: url(/img/hss_content/bg_step.png) no-repeat;
  background-color: var(--main-clr);
  color: var(--bg-clr2);
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}

.steplistbox-wrap li:first-child {
  background-image: none;
}

.steplistbox-wrap li .str {
  display: block;
  margin-bottom: 20px;
  color: var(--bg-clr2);
  font-size: 18px;
  font-weight: $bold;
}

.steplistbox-wrap + .sub-title-area {
  margin-top: 50px;
}

.tts + .steplistbox-wrap {
  margin-top: 40px;
}

.table-type01 .th-style03 {
  border-right: 0;
  border-bottom-color: #efefef;
}

.bb-none {
  border-bottom: 0 !important;
}

.steplistbox-wrap + .steplistbox-wrap {
  margin-top: 40px;
}

.step-list .step strong {
  color: var(--main-clr);
  font-size: 22px;
  font-weight: $medium;
}

.step-list .step::after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background: url(/img/hss_content/bg_apply_arrow.png) no-repeat left top 55px;
}

.step-list li:first-child .step::after {
  display: none;
}

.step-list .text .str {
  color: var(--main-clr);
  font-size: 16px;
  font-weight: $medium;
}

.step-list .text {
  font-weight: $medium;
}

.step-list .text02 {
  font-weight: $regular;
}

.th-style02 {
  border-bottom-color: #efefef !important;
}

@media (max-width: $wrapSize) {
  .common-titlestyle01:first-child {
    margin-top: 20px;
  }

  .arsguide-box {
    margin-top: 0;
  }

  .steplist-box {
    margin-top: 20px;
  }

  .common-viewtitle {
    margin-top: 20px;
  }

  .notice-subtext.type01 {
    margin-top: 20px;
  }

  .nonfacing-stepbox .step-list {
    display: block;
  }

  .nonfacing-stepbox .step-list li {
    display: block;
    width: 100%;
    margin-top: 30px;
  }

  .nonfacing-stepbox .step-list li:first-child {
    margin-top: 0;
  }

  .step-list .step::after {
    display: none;
  }
}

@media (max-width: $wrapSize) {
  .swiper-active-switch .main-page-service-tab-button {
    width: 53px;
    background-color: #339dd7;
  }
}

/* @media (max-width: $wrapSize) {
	.ceo-img-box {height:230px !important;background-repeat:no-repeat;background-size:100% 100%;}
	.ceo-img-box .text {display:none;}
	.ceo-info-wrap.star-bank .ceo-sign {margin-top:20px;}
	.stat-bank.tablet-viewbox {display:block;}
	.stat-bank.mobile-viewbox {display:none;}
	.stat-bank.pc-viewbox {display:none;}
} */

@media (max-width: $wrapSize) {
  .ethics-box.star-bank {
    padding: 43px 238px 44px 39px;
  }
}

@media (max-width: 768px) {
  .ethics-box.star-bank {
    padding-right: 175px;
  }
}

@media (max-width: 768px) {
  .ethics-box.star-bank .text {
    font-size: 15px;
  }

  .ethics-box.star-bank .text strong {
    font-size: 15px;
  }

  .ethics-box.star-bank {
    padding: 33px 39px 34px;
    background-image: none;
  }

  .ethics-list-text::before {
    width: 3px;
    height: 3px;
    background: #000;
  }

  .ethics-box.star-bank + .ethics-list.star-bank {
    margin-top: 28px;
  }

  .ethics-list.star-bank + .slide-box-area.star-bank {
    margin-top: 38px;
  }
}

@media (max-width: 768px) {
  .stat-bank.tablet-viewbox {
    display: none;
  }

  .stat-bank.mobile-viewbox {
    display: block;
  }

  .stat-bank.pc-viewbox {
    display: none;
  }
}

@media (max-width: 768px) {

  .management-philosophy-box.star-bank dd.box-style01,
  .management-philosophy-box.star-bank dd.box-style03 {
    margin-top: 9px;
  }
}

/* 별첨 다운로드 박스  */
.box-style05 {
  width: 100%;
  margin-top: 15px;
  padding: 10px 30px 18px;
  background: #f7f7f7;
}

.box-style05 .down-list {
  margin-top: 15px;
}

.box-style05 .down-list:first-child {
  margin-top: 0;
}

.box-style05 .down-list .title {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
  font-weight: $medium;
}

.box-style05 .down {
  display: inline-block;
  margin-top: 10px;
  margin-right: 20px;
  padding-left: 25px;
  background: url(/img/hss_content/bg_download.png) no-repeat;
  background-size: 14px;
  font-size: 14px;
}

@media (max-width: $wrapSize) {
  .box-style05 .down {
    background-size: 12px;
  }
}

/* 체크카드 팝업 */
.card-info .card-wrap {
  position: relative;
}

.card-info .scroll-box {
  max-height: 690px;
  overflow-y: auto;
}

.card-info .popup-body02 {
  max-height: inherit;
  text-align: left;
  padding: 30px 30px 60px;
  min-height: 150px;
  overflow-y: auto;
  background: var(--bg-clr2);
}

.card-info .body-box {
  margin-top: 35px;
}

.card-info .body-box:first-child {
  margin-top: 0;
}

.card-info .tit-des {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: $medium;
}

.card-info .sub-des {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: $medium;
  line-height: 1.3;
}

.card-info .notice-list .font-088 {
  padding-top: 20px;
  line-height: 23px;
}

.card-info .notice-list .notice-i-list {
  margin-top: 0;
}

.card-info .notice-list li .notice-i-list {
  padding-left: 0;
}

.card-info .notice-i-list {
  margin-top: 20px;
  color: var(--body-clr);
}

.card-info .nostyle-list {
  max-width: $wrapSize;
  margin: 0 auto;
}

.card-info .nostyle-list li {
  margin-top: 3px;
  font-size: 14px;
  line-height: 22px;
}

.card-info .nostyle-list li:first-child {
  margin-top: 0;
}

.card-info .notice-box {
  margin-top: 30px;
  padding: 25px 20px;
  background: #f7f7f7;
}

.card-info .notice-box .sub-des {
  margin-top: 0;
}

.card-info .notice-box .notice-list {
  padding-top: 0;
}

.card-info .notice-box .notice-list li {
  margin-top: 0;
  padding-left: 10px;
  font-size: 12px;
}

.card-info .notice-box .notice-list li a {
  display: inline;
  font-weight: $medium;
}

.card-info .notice-box .nostyle-list li {
  font-size: 12px;
}

.card-info .notice-box .notice-i-list {
  margin-top: 0;
  font-size: 12px;
}

.card-info .notice-box .notice-i-list::before {
  margin-left: -10px;
}

.card-info .step-ux {
  margin-top: 30px;
  padding: 30px 60px;
  background: #f7f7f7;
}

.card-info .step-ux ul {
  display: table;
  width: 100%;
}

.card-info .step-ux ul li {
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 25%;
  text-align: center;
}

.card-info .step-ux ul li::before {
  content: '';
  display: block;
  width: 16px;
  height: 29px;
  position: absolute;
  top: 40px;
  right: -8px;
  background: url(/img/mypage/card_check_arrow.png) no-repeat center;
}

.card-info .step-ux ul li:last-child::before {
  display: none;
}

.card-info .step-ux ul li p {
  padding-top: 128px;
  font-size: 14px;
  line-height: 25px;
  background-repeat: no-repeat;
  background-position: center top;
}

.card-info .step-ux ul li p.step-t01 {
  background-image: url(/img/mypage/card_check_step01.png);
  padding-top: 120px;
}

.card-info .step-ux ul li p.step-t02 {
  background-image: url(/img/mypage/card_check_step02.png);
}

.card-info .step-ux ul li p.step-t03 {
  background-image: url(/img/mypage/card_check_step03.png);
}

.card-info .step-ux ul li p.step-t04 {
  background-image: url(/img/mypage/card_check_step04.png);
}

.card-info .table-type04 th {
  width: 160px;
}

.card-info .table-type04 td {
  padding: 14px 10px 13px 10px;
}

.card-info td .notice-list {
  margin-left: 10px;
}

.checkcash-cards + .card-info {
  margin-top: 30px;
}

.popup-foot01.foot-style01 {
  padding: 30px 0 40px;
}

.table-title + .table-wrap {
  margin-top: 15px;
}

.notice-list + .popup-top-number {
  margin-top: 20px;
}

@media (max-width: $wrapSize) {
  .card-info .step-ux {
    padding: 30px 10px;
  }

  .card-info {
    margin-top: 30px;
  }
}

/* 요약공시 */
.common-stit {
  position: relative;
  margin-top: 52px;
  font-size: 22px;
  font-weight: $bold;
}

.common-text02 {
  margin-top: 5px;
  font-size: 14px;
  color: #666;
  line-height: 24px;
}

.searchselect-wrap {
  width: 100%;
  margin-top: 25px;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: right;
}

.searchselect-wrap .btn-basic {
  width: 150px;
  min-width: 150px;
  height: 40px;
  padding: 0;
  background: #666;
  color: var(--bg-clr2);
  font-size: 16px;
  font-weight: $bold;
  line-height: 15px;
  text-decoration: none;
}

.searchselect-wrap .datebox {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  line-height: 0;
}

.searchselect-wrap .datebox .datebox-interval {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.searchselect-wrap .datebox .datebox-interval02 {
  display: inline-block;
  vertical-align: middle;
}

.searchselect-wrap .datebox .datebox-interval select {
  min-width: 150px;
  padding: 0 30px 0 10px;
  color: #666;
}

.tbl-wrap {
  margin-top: 10px;
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);
}

.tbl-style02 {
  width: 100%;
}

.tbl-style02 th {
  height: 40px;
  padding: 0 10px;
  font-weight: $bold;
  font-size: 14px;
  line-height: 1.5;
  vertical-align: middle;
  word-break: break-all;
  border: 1px solid #eee;
  border-bottom: none !important;
  // border-right: 1px solid var(--disable-clr);
  // border-bottom: 1px solid var(--disable-clr);
}

.tbl-style02 td {
  height: auto;
  padding: 17px 10px;
  font-weight: $regular;
  font-size: 14px;
  line-height: 1.5;
  vertical-align: middle;
  word-break: break-all;
  border: 1px solid #eee;
  border-bottom: none !important;
  // border-right: 1px solid #efefef;
  // border-bottom: 1px solid #efefef;
}

// .tbl-style02 th:last-child,
// .tbl-style02 td:last-child {
//   border-right: none;
// }

.tbl-style02 th.sub-text {
  color: #666;
  font-size: 14px;
  // border-top: 1px solid var(--disable-clr);
}

// .tbl-style02 th.sub-text:last-child {
//   border-right: 1px solid var(--disable-clr);
// }

// .tbl-style02 thead tr + tr th {
//   border-top: 1px solid var(--disable-clr);
// }

.tbl-style02 thead tr {
  width: 100%;
  background: #f7f7f7;
}

// .tbl-style02 tbody tr:last-child td {
//   border-bottom: none;
// }

.tbl-style02 td a {
  color: #666;
  text-decoration: underline;
}

.tbl-style02 td a:hover {
  color: #00397c;
}

.tbl-style02.type02 th {
  height: 70px;
}

@media (max-width:768px) {
  .temp-view-tbl {
    position: relative;
    overflow: hidden;
    margin-top: 16px;
  }

  .temp-view-tbl::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    display: block;
    width: 20px;
    height: 100%;
    border-radius: 10px 0 0 10px / 50% 0 0 50%;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
  }

  .temp-view-tbl .tbl-wrap {
    overflow-x: auto;
    margin: 0;
  }

  .temp-view-tbl .tbl-wrap table {
    width: 680px;
  }

  .temp-view-tbl .tbl-wrap table.wide-tbl {
    width: 900px;
  }

  .common-stit {
    margin-top: 25px;
    font-size: 15px;
  }

  .common-text02 {
    font-size: 12px;
    line-height: 20px;
  }

  .searchselect-wrap {
    margin-top: 10px;
    text-align: left;
  }

  .searchselect-wrap .btn-basic {
    width: 100%;
  }

  .searchselect-wrap .datebox {
    width: 100%;
  }

  .searchselect-wrap .datebox .datebox-interval {
    width: 100%;
    margin-right: 0;
  }

  .searchselect-wrap .datebox .datebox-interval select {
    width: 100%;
  }

  .searchselect-wrap .datebox .datebox-interval02 {
    width: 100%;
    margin-top: 10px;
  }

  .info-box .text {
    margin-top: 10px;
  }

  .info-box .left {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
  }

  .info-box .right {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .info-box .text {
    font-size: 12px;
  }

  .tbl-style02 th,
  .tbl-style02 td {
    height: auto;
    padding: 12px 5px;
    font-size: 12px;
    line-height: 18px;
  }
}


/* 한화저축은행 계열사소개 페이지 스타일 2019.09.16 김재원 */
.group-info {
  background-image: url(/img/hss_companycontent/hanhwa_content/bg_group_info.jpg) !important;
}

.group-info .text .text-point {
  font-size: 30px !important;
}

.group-info .text .ty01 {
  color: var(--bg-clr2);
  font-size: 30px;
  line-height: 45px !important;
}

.group-info-text {
  color: #666;
  font-size: 18px;
  margin-top: 45px;
  line-height: 30px !important;
}


@media (max-width:$wrapSize) {
  .group-info .text .text-point {
    font-size: 25px !important;
  }

  .group-info .text .ty01 {
    color: var(--bg-clr2);
    font-size: 25px;
    line-height: 35px !important;
  }
}

@media (max-width:768px) {
  .group-info .text .text-point {
    font-size: 20px !important;
  }

  .group-info .text .ty01 {
    color: var(--bg-clr2);
    font-size: 20px;
    line-height: 30px !important;
  }
}


/*금융상품_신용카드*/
.credInfo-wp .cred-title {
  padding: 55px 0 17px;
  font-size: 22px;
  font-weight: $bold;
  letter-spacing: -.25px;
}

.cInfo-top {
  position: relative;
  padding: 45px 330px 50px 40px;
  background-color: #f7f7f7;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.cInfo-top::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 62px);
  right: 40px;
  width: 192px;
  height: 124px;
  background: url(/img/hss_companycontent/dream_content/bg_fincred.png) no-repeat;
}

.cInfo-top .title {
  max-width: 600px;
  padding: 0;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.25px;
}

.cInfo-top .cInfo-img {
  position: absolute;
  right: 46px;
  top: 20px;
}

.cred-kinds p {
  color: #666;
  font-size: 16px;
  letter-spacing: -.25px;
}

.cred-list {
  border-top: 1px solid #00397c;
}

.cred-list li {
  display: table;
  width: 100%;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--line-clr3);
}

.cred-list li .thumb {
  display: table-cell;
  vertical-align: top;
  width: 270px;
  padding: 40px 0 0 45px;
}

.cred-list li .info {
  display: table-cell;
  vertical-align: top;
  width: auto;
  padding: 50px 0 0 20px;
}

.cred-list li .info strong {
  display: block;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: $bold;
  line-height: 24px;
  letter-spacing: -0.25px;
}

.cred-list li .info p {
  color: #666;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.25px;
}

.cred-infoWp .btn-wp {
  text-align: center;
  padding: 40px 0 0 0;
}

.cred-infoWp .btn-wp button {
  width: 180px;
  padding: 0;
}

.save-infoWp .tbl-wrap {
  margin: 0 0 5px;
}

.save-infoWp table th.wh {
  background: var(--bg-clr2);
  border-right: 1px solid var(--line-clr3);
}

.save-infoWp table th.wh.last {
  border-right: 0;
}

.save-infoWp .tbl-style03 th {
  text-align: center;
}

.save-infoWp .tbl-style03 td {
  text-align: center;
}

.save-infoWp > p {
  color: #666;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.25px;
}

.save-mInfo {
  display: none;
}

.caution-wp {
  margin-top: 55px;
}

.c-detailWp {
  padding: 0 20px 20px;
}

.c-detailWp strong.ctit {
  display: block;
  padding: 25px 0 15px;
  font-size: 16px;
  letter-spacing: -0.25px;
}

// .c-detailWp .ctxt {}

// .c-detailWp .ctxt ul.bul {}

.c-detailWp .ctxt ul.bul li {
  position: relative;
  padding-left: 13px;
  color: #666;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
}

.c-detailWp .ctxt ul.bul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 4px;
  height: 4px;
  background: url(../img/common/bul02.gif) no-repeat left top;
}

.c-detailWp .ctxt ul.bul li p {
  position: relative;
  color: #666;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  padding-left: 13px;
}

.c-detailWp .ctxt ul.bul li p::before {
  content: '-';
  position: absolute;
  left: 0;
  top: 0px;
}

.c-detailWp .ctxt ul.bul li strong {
  color: var(--body-clr);
  font-weight: $regular;
}

.c-detailWp .ctxt ul.str li {
  position: relative;
  padding-left: 13px;
  color: #666;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
}

.c-detailWp .ctxt ul.str li::before {
  content: '-';
  position: absolute;
  left: 0;
  top: 0px;
}

.c-detailWp .ctxt ul.str li span {
  color: var(--body-clr);
  margin-right: 5px;
}

.c-detailWp .ctxt ul.str li p {
  padding-left: 40px;
  color: #666;
  text-indent: -40px;
}

.c-detailWp .ctxt ul.str li p.point {
  color: var(--body-clr);
  font-size: 14px;
  letter-spacing: -.25px;
}

.bank-cInfoWp .tbl-wrap {
  margin-top: 0;
}

.bank-cInfoWp table th {
  text-align: center;
}

.bank-cInfoWp table th.thr {
  border-right: 1px solid #b5c7de;
}

.bank-cInfoWp table td span {
  display: inline-block;
  line-height: 17px;
  border-bottom: 1px solid #bcbcbc;
  color: #666;
}

@media (max-width:$wrapSize) {

  /* 상단 infobox */
  .fin-infobox {
    min-height: auto;
    margin: 0 -18px 0;
    padding: 18px 16px;
  }

  .fin-infobox::after {
    display: none;
  }

  /*금융상품_신용카드*/
  .credInfo-wp .cred-title {
    font-size: 14.6px;
    padding: 20px 0 11px;
  }

  .save-infoWp .tbl-wrap {
    display: none;
  }

  .save-mInfo {
    display: block;
  }

  .save-mInfo dl {
    padding-bottom: 5px;
  }

  .save-mInfo dt {
    display: block;
    margin-bottom: 10px;
    padding: 8px 10px;
    background: #f7f7f7;
    font-size: 14px;
    font-weight: $bold;
  }

  .save-mInfo dd {
    padding-left: 10px;
  }

  .save-mInfo ul li {
    margin-bottom: 10px;
  }

  .save-mInfo ul li strong {
    display: block;
    font-size: 13.3px;
    margin-bottom: 5px;
  }

  .save-mInfo ul li p {
    color: #666;
    font-size: 12px;
    line-height: 20px;
  }

  .save-infoWp > p {
    margin-left: 10px;
    padding-left: 15px;
    font-size: 11px;
    line-height: 20px;
    text-indent: -15px;
  }

  .cInfo-top {
    margin-top: 30px;
    padding: 25px 15px;
  }

  .cInfo-top::after {
    display: none;
  }

  .cInfo-top strong {
    font-size: 14px;
    line-height: 20px;
  }

  .cInfo-top strong br {
    display: none
  }

  .cInfo-top .cInfo-img {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -30px;
  }

  .cInfo-top .cInfo-img img {
    width: 110px;
    height: 61px;
  }

  .cInfo-top .title {
    font-size: 14px;
    line-height: 22px;
  }

  .cred-kinds p {
    font-size: 13px;
  }

  .cred-list li {
    width: 100%;
    padding-bottom: 15px;
  }

  .cred-list li .thumb {
    width: 100px;
    padding: 15px 10px 0 10px;
  }

  .cred-list li .thumb img {
    width: 100%;
  }

  .cred-list li .info {
    padding: 20px 0 0 0;
  }

  .cred-list li .info strong {
    display: block;
    padding-bottom: 5px;
    font-size: 13px;
    line-height: 20px;
  }

  .cred-list li .info p {
    font-size: 12px;
    line-height: 20px;
  }

  .cred-infoWp .btn-wp {
    overflow: hidden;
    margin: 0;
    padding: 15px 0 0 0;
    text-align: center;
  }

  .cred-infoWp .btn-wp span {
    float: left;
    width: 50%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box
  }

  .cred-infoWp .btn-wp span:first-of-type {
    padding-right: 6px;
  }

  .cred-infoWp .btn-wp span:last-of-type {
    padding-left: 6px;
  }

  .cred-infoWp .btn-wp button {
    vertical-align: top;
    width: inherit;
    width: 100%;
    height: 34px;
    margin: 0;
    padding: 0;
    min-width: inherit;
    font-size: 12px;
  }

  .caution-wp {
    margin-top: 25px;
  }

  .c-detailWp {
    padding: 0 16px 10px;
  }

  .c-detailWp strong.ctit {
    display: block;
    padding: 15px 0 5px;
    font-size: 13.3px;
  }

  .c-detailWp .ctxt ul.bul li {
    font-size: 13.3px;
    line-height: 22px;
    padding-left: 10px;
  }

  .c-detailWp .ctxt ul.bul li::before {
    content: '';
    top: 4px;
    width: 3px;
    height: 3px;
    background-size: 3px 3px;
  }

  .c-detailWp .ctxt ul.bul li p {
    padding-left: 10px;
    font-size: 13.3px;
    line-height: 22px;
  }

  .c-detailWp .ctxt ul.bul li p::before {
    content: '-';
    top: 0px;
  }

  .c-detailWp .ctxt ul.str li {
    margin-bottom: 5px;
    padding-left: 10px;
    font-size: 13.3px;
    line-height: 22px;
  }

  .c-detailWp .ctxt ul.str li p {
    text-indent: -30px;
    padding-left: 30px;
  }

  .c-detailWp .ctxt ul.str li p.point {
    font-size: 13.3px;
  }

  .bank-cInfoWp table th {
    padding: 7px 10px 6px;
    text-align: center;
    font-size: 13px;
  }

  .bank-cInfoWp table td {
    padding: 7px 10px 6px;
    font-size: 13px;
    line-height: 20px;
  }

  .bank-cInfoWp table th.thr {
    border-right: 1px solid #b5c7de;
  }

  .bank-cInfoWp table td span {
    display: inline-block;
    line-height: 14px;
    border-bottom: 1px solid #bcbcbc;
    color: #666;
  }
}

.tbl-style03 {
  width: 100%;
}

.tbl-style03 tr {
  border-bottom: 1px solid #dfdfdf;
}

.tbl-style03 th {
  padding: 12px 20px 11px;
  background: #eff7f9;
  font-size: 16px;
  font-weight: $bold;
  text-align: left;
  line-height: 28px;
  word-break: keep-all;
}

.tbl-style03 td {
  padding: 12px 20px 11px;
  border-right: 1px solid #dfdfdf;
  font-size: 16px;
  color: #666;
  line-height: 28px;
  word-break: break-all;
}

.tbl-style03 tr td:last-child {
  border-right: none;
}

.tbl-style03 tr:last-child {
  border-bottom: none;
  ;
}

.tbl-style03 tr:last-child td {
  border-bottom: none;
}

.tbl-style03 th label {
  font-weight: $bold;
}

@media (max-width:$wrapSize) {
  .tbl-style03 .common-desc-i {
    margin: 10px 0 0 0;
  }
}

.cardinfo-wrap {
  overflow: hidden;
  margin-top: 40px;
  border-top: 1px solid #9eb5d3;
  border-bottom: 1px solid var(--line-clr3);
  padding: 20px 0 21px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cardinfo-divide {
  display: table;
  float: left;
  width: calc(100% - 402px);
}

.cardinfo-design {
  display: table-cell;
  min-width: 236px;
  vertical-align: top;
  font-size: 0;
  line-height: 0;
}

.cardinfo-btn {
  width: 100%;
  max-width: 236px;
  text-align: center;
}

.cardinfo-detail {
  display: table-cell;
  width: 351px;
  padding-right: 10px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cardinfo-title {
  padding-top: 25px;
  color: var(--body-clr);
  font-size: 21px;
  font-weight: $bold;
}

.cardinfo-text {
  margin-top: 10px;
  color: #666666;
  font-size: 12px;
  line-height: 18px;
}

.cardinfo-annualfee {
  display: table;
  width: 296px;
  height: 44px;
  margin-top: 35px;
  border: 1px solid var(--disable-clr);
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cardinfo-annualfee-title {
  display: table-cell;
  width: 62px;
  padding-top: 2px;
  color: var(--body-clr);
  font-size: 12px;
  font-weight: $bold;
  vertical-align: middle;
  text-align: center;
}

.cardinfo-annualfee-text {
  position: relative;
  display: table-cell;
  width: 137px;
  color: #666666;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle;
  text-align: center;
}

.cardinfo-annualfee-text::after {
  content: '';
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 1px;
  height: 29px;
  background: #d5d5d5;
}

.cardinfo-annualfee-title + .cardinfo-annualfee-text {
  width: 95px;
}

.saleinfo-list-wrap {
  display: table;
  float: right;
  width: 402px;
  border-left: 1px solid #f1f1f1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.saleinfo-list-wrap li {
  position: relative;
  display: table-cell;
  width: 50%;
  height: 200px;
  padding-top: 57px;
  vertical-align: top;
  text-align: center;
}

.saleinfo-list-wrap li .saletext {
  height: 40px;
  color: #00397c;
  font-size: 30px;
  font-weight: $bold;
  text-align: center;
}

.saleinfo-list-wrap li .saleinfo {
  color: #666666;
  font-size: 12px;
  font-weight: $bold;
  text-align: center;
  line-height: 24px;
}

.saleinfo-list-wrap li:first-child::after {
  content: '';
  display: block;
  position: absolute;
  top: 104px;
  right: 0;
  width: 1px;
  height: 72px;
  margin-top: -36px;
  background: var(--disable-clr);
}

@media (max-width:$wrapSize) {
  .cardinfo-wrap {
    margin: 40px -18px;
    padding: 20px 0 21px 0;
  }

  /* .first-gap-style {margin-top:-20px;} */
  .cardinfo-divide {
    display: block;
    float: none;
    width: 100%;
  }

  .cardinfo-design {
    display: block;
    width: 100%;
    text-align: center;
  }

  .cardinfo-detail {
    display: block;
    width: 100%;
    padding: 0 22px;
  }

  .cardinfo-title {
    padding-top: 25px;
    text-align: center;
  }

  .cardinfo-text {
    margin-top: 5px;
    text-align: center;
  }

  .cardinfo-annualfee {
    width: 100%;
    margin-top: 15px;
  }

  .cardinfo-btn {
    max-width: 100%;
  }

  .saleinfo-list-wrap {
    float: none;
    width: 100%;
    margin-top: 20px;
  }

  .saleinfo-list-wrap li {
    position: relative;
    display: table-cell;
    width: 50%;
    height: 120px;
    padding-top: 20px;
    vertical-align: top;
    text-align: center;
  }

  .saleinfo-list-wrap li:first-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 54px;
    right: 0;
    width: 1px;
    height: 72px;
    margin-top: -36px;
    background: var(--disable-clr);
  }

  .saleinfo-list-wrap li .saletext {
    height: 30px;
    font-size: 20px;
  }
}

/* [공통] 유의사항 */
.common-noticebox-wrap {
  position: relative;
  margin: 60px 0 0;
  padding: 24px 20px 18px;
  background: #f7f7f7;
}

.common-noticebox-title {
  padding-left: 21px;
  background: url(/img/hss_content/bg_att_i.png) no-repeat 0 2px;
  color: #f15757;
  font-size: 16px;
  font-weight: $bold;
}

.common-noticebox-list {
  margin-top: 8px;
}

.common-noticebox-list > li {
  padding-left: 20px;
  color: #666;
  font-size: 16px;
  line-height: 30px;
}

.common-noticebox-list > li::before {
  content: '';
  position: relative;
  top: -3px;
  left: 0;
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 5px;
  background: #666;
  border-radius: 50%;
}

.common-noticebox-list li .link {
  color: #666;
  font-weight: $bold;
}

.common-noticebox-in-list {
  margin-left: 15px;
}

.common-noticebox-in-list li {
  color: #666;
  font-size: 16px;
  line-height: 30px;
}

/* [공통] 아코디언 내부 타이틀,텍스트,테이블 스타일 */
.common-accordion-gapstyle-wrap {
  padding: 26px 20px 25px;
}

.common-subtitle-wrap01 {
  margin-top: 23px;
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $bold;
  line-height: 1.5;
}

.common-subtitle-wrap01:first-child {
  margin: 0;
}

.common-subtext-wrap01 {
  margin-top: 11px;
  color: #666666;
  font-size: 16px;
  line-height: 1.5;
}

.common-subtext-wrap01.type01 {
  margin-top: 0;
}

.common-subtable-wrap01 {
  margin-top: 15px;
  border-top: 1px solid var(--line-clr3);
  border-bottom: 1px solid var(--line-clr3);
}

.common-subtable-wrap01 .common-subtable {
  width: 100%;
  border-top: 1px solid var(--line-clr3);
  background: var(--bg-clr2);
}

.common-subtable-wrap01 .common-subtable thead th {
  height: 45px;
  border-left: 1px solid var(--line-clr3);
  color: var(--body-clr);
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}

.common-subtable-wrap01 .common-subtable thead th:first-child {
  border-left: none;
}

.common-subtable-wrap01 .common-subtable tbody td {
  height: 74px;
  padding: 0 10px 0 18px;
  border-left: 1px solid var(--line-clr3);
  border-top: 1px solid var(--line-clr3);
  color: var(--body-clr);
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
}

.common-subtable-wrap01 .common-subtable tbody td:first-child {
  border-left: none;
}

.common-subtable-wrap01 .common-subtable tbody td.al-l {
  text-align: left;
}

/* [공통] 모바일 스타일 */
@media (max-width:$wrapSize) {
  .common-noticebox-title {
    padding-left: 18px;
    background: url(/img/hss_content/bg_att_i.png) no-repeat 0 1px;
    font-size: 14px;
  }

  .caution-wp + .cardinfo-wrap {
    margin-top: 20px;
  }

  .common-noticebox-wrap {
    margin: 20px -18px 0;
  }

  .common-noticebox-list > li {
    padding-left: 0;
    font-size: 12px;
    line-height: 15px;
  }

  .common-noticebox-list > li::before {
    content: '';
    position: relative;
    top: -3px;
    left: 0;
    display: inline-block;
    width: 2px;
    height: 2px;
    margin-right: 5px;
    background: #666;
    border-radius: 50%;
  }

  .common-noticebox-in-list li {
    font-size: 12px;
    line-height: 15px;
  }

  .common-btn-mobile-style01 {
    width: 176px;
    margin: 0 auto;
  }

  // .common-btn-bullet-down {}

  .common-caution-text {
    font-size: 12px;
  }

  ul.common-caution-text li {
    font-size: 12px;
  }
}

.common-btn-style01 {
  display: inline-block;
  height: 30px;
  padding: 0 17px;
  border: 1px solid var(--disable-clr);
  border-radius: 5px;
  color: #666666;
  font-size: 12px;
  font-weight: $bold;
  line-height: 30px;
  text-align: center;
}

.common-btn-bullet-down {
  display: inline-block;
  padding: 1px 24px 0 0;
  background: url(/img/hss_common/bul_down.png) no-repeat right 1px top 7px;
  color: #666666;
  font-size: 12px;
  font-weight: $bold;
}

/* 진주 ci 소개 */
.sub-text03 {
  color: #666;
  font-size: 16px;
  line-height: 28px;
}

.cibg-wrap {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.cibg-wrap .logo {
  width: 100%;
}

.ci-color-listbox {
  margin-top: 32px;
}

.ci-color-listbox::after {
  content: '';
  clear: both;
  display: block;
}

.ci-color-listbox .color-list {
  float: left;
  height: 140px;
  margin-right: 30px;
}

.ci-color-listbox .color-list:last-child {
  margin-right: 0;
}

.ci-color-listbox li .color {
  display: block;
}

.ci-color-listbox li .color-text {
  display: block;
  color: var(--cap-clr);
  font-size: 14px;
  font-weight: $bold;
  line-height: 18px;
}

.ci-color-listbox li .color-text:nth-child(2) {
  margin-top: 8px;
}

/* 진주 도서문화 상품권 */
.talent-icon-list.jinju-bank {
  margin-top: 40px;
}

.talent-icon-list.jinju-bank .talent-icon-list-item::before {
  background-image: none;
  background-color: var(--bg-clr2);
}

.talent-icon-list.jinju-bank .talent-icon-list-item-type1::before {
  background-image: url(/img/hss_companycontent/jinju_content/content/bookcard-icon_39.jpg);
}

.talent-icon-list.jinju-bank .talent-icon-list-item-type2::before {
  background-image: url(/img/hss_companycontent/jinju_content/content/bookcard-icon_41.png);
}

.talent-icon-list.jinju-bank .talent-icon-list-item-type3::before {
  background-image: url(/img/hss_companycontent/jinju_content/content/bookcard-icon_44.jpg);
}

.talent-icon-list.jinju-bank .talent-icon-list-item-type4::before {
  background-image: url(/img/hss_companycontent/jinju_content/content/bookcard-icon_46.jpg);
}

.sub-text03 + .table-wrap01 {
  margin-top: 13px;
}

/* 민원처리절차 */

.arrow-list {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 70px;
}

.arrow-list li {
  position: relative;
  width: 300px;
  background: var(--bg-clr2);
}


.arrow-list li::after {
  content: '';
  position: absolute;
  right: -55px;
  top: 50%;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  display: block;
  background: url(/img/common/icon_titlearrow.png) no-repeat center;
}

.arrow-list li:last-child::after {
  display: none;
}

.arrow-tit {
  padding: 20px;
  background: #0B9444;
  color: var(--bg-clr2);
  text-align: center;
  font-size: 21px;
}

.arrow-txt {
  text-align: center;
  padding: 10px;
  border: 1px solid #0B9444;
}


/* ci 소개 (진주저축은행) */

.color-chip-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 35px;
  margin-top: 20px;
}

.color-chip-item {
  width: 350px;
  background: #455465;
  padding: 20px;
  border-radius: 0 0 110px 0;
}

.color-chip-item.jade {
  background: #60A786;
}

.color-chip-item.brown {
  background: #eda65d;
}

.color-chip-item.beige {
  background: #b7a99a;
}

.color-chip-item.pink {
  background: #f14b5d;
}

.color-chip-item.green {
  background: #009c4e;
}

.color-chip-item .color-tit {
  display: inline-block;
  width: 200px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bg-clr2);
  font-size: 20px;
  color: var(--bg-clr2);
}

.color-chip-item .color-txt {
  font-size: 18px;
  color: #eee;
}

.color-chip-item .color-txt strong {
  color: var(--bg-clr2);
  font-weight: 500 !important;
}


@media (max-width: 768px) {
  .color-chip-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .color-chip-item {
    width: 100%;
  }
}








/*검색결과 없을때 페이지 공통*/
.nosearch-wp {
  border-top: 1px solid var(--main-clr);
  padding: 260px 0 100px;
  background: #f7f7f7 url(/img/hss_common/ico-point.png) no-repeat center 100px;
  text-align: center;
}

.nosearch-wp p {
  font-size: 30px;
  line-height: 26px;
  letter-spacing: -.25px;
  font-weight: $regular;
}

.nosearch-wp .btn-wp {
  margin-top: 35px;
}

.nosearch-wp.type01 {
  padding: 112px 0 100px;
}

.nosearch-wp.type01 {
  background: #f7f7f7
}

.nosearch-wp.type01 p {
  font-weight: $regular;
}

/* hps_002.html */
.new-thumbWp {
  border-top: 1px solid var(--main-clr);

  .new-thumb-list {
    margin: 0;
  }
}

ul.new-thumb-list {
  overflow: hidden;
  margin: 0 -32px;
}

.new-thumb-list .thumb-wp img {
  width: 100%;
  height: 100%;
}

.thumb-wp {
  overflow: hidden;
}

ul.new-thumb-list li {
  float: left;
  width: 324px;
  margin: 0 32px;
  padding-top: 40px;
}

ul.new-thumb-list li > a {
  display: block;
  border: 1px solid var(--disable-clr);
  text-decoration: none;
}

ul.new-thumb-list li .thumb-wp {
  width: 322px;
  height: 348px;
}

.new-thumbWp.tmb-type02 ul.new-thumb-list li .thumb-wp {
  height: 216px;
}

.new-thumbWp.tmb-type02 ul.new-thumb-list li .txt-wp {
  border-top: 1px solid var(--disable-clr);
}

ul.new-thumb-list li .txt-wp {
  padding: 17px 20px;
  min-height: 115px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

ul.new-thumb-list li .tit {
  display: block;
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $bold;
  line-height: 24px;
  letter-spacing: -.25px;
}

ul.new-thumb-list li .txt-wp .date {
  display: block;
  color: #666;
  font-size: 12px;
  font-weight: $bold;
  letter-spacing: -.25px;
  margin-top: 18px;
}

ul.new-thumb-list li .cmt {
  overflow: hidden;
  display: block;
  height: 48px;
  word-break: break-all;
}

/* hps_005.html */
.store-searchWp {
  margin: 30px auto;
  overflow: hidden;
}

.store-searchWp::after {
  display: block;
  content: '';
  clear: both;
}

.search-tabWp {
  float: left;
  width: 478px;
  min-height: 800px;
  border: 1px solid var(--line-clr3);
  border-radius: 20px;
  overflow: hidden;
}

.sr-listWp {
  background: var(--bg-clr2);
}

// .sr-listWp ul {
//   padding: 15px;
// }

.sr-listWp ul li {
  a {
    @include flex($align: normal, $gap: 15px);
    padding: 20px 40px 20px 10px;
    border-top: 1px solid #efefef;
    background: url(/img/hss_content/hps/ico-map.png) no-repeat right 20px center;
  }

  &:first-child a {
    border: 0;
  }
}


.sr-listWp ul li a.active {
  background-color: var(--bg-clr1);
  // background: url(/img/hss_common/ico-map02.png) no-repeat right 20px center;
  // background-size: 30px auto;
}

.sr-listWp ul li .t-logo {
  width: 140px;
  padding: 10px;
  // border: 1px solid var(--line-clr3);
  // border-radius: 7px;
}

.sr-listWp ul li .s-info {
  width: 250px;
}

.sr-listWp ul li .s-info .tit {
  display: block;
  color: #666;
  font-size: 16px;
  font-weight: $bold;
  letter-spacing: -.25px;
}

.sr-listWp ul li .s-info p.add,
.sr-listWp ul li .s-info p.add02,
.sr-listWp ul li .s-info p.add03 {
  color: #666;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -.25px;
  margin-top: 5px;
}

// .sr-listWp ul li a::after {
//   content: '';
//   display: block;
//   clear: both;
// }

.sr-listWp ul li.on {
  position: relative;
  border: 2px solid var(--main-clr);
  border-radius: 14px;
}

.sr-listWp ul li.on::before {
  display: none;
  // content: '';
  // position: absolute;
  // left: -1px;
  // bottom: -1px;
  // right: -1px;
  // height: 100%;
  // z-index: 200;
}

.sr-listWp ul li.on::after {
  display: none;
  // content: '';
  // position: absolute;
  // inset: 0;
  // border: 2px solid var(--main-clr);
  // border-radius: 14px;
  // z-index: 0;
}

.sr-listWp ul li .t-logo img {
  width: 120px;
  // height: 38px;
  vertical-align: top;
}

.sr-listWp .paging-wrap a {
  margin-top: 30px;
}

.store-mapWp {
  float: left;
  padding-left: 60px;
  width: 560px;
  min-height: 800px;
}

.map-area {
  width: 560px;
  min-height: 800px;
}


@media (max-width: $wrapSize) {
  .tab-basic-buttons {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    text-align: center;
    border-bottom: 1px solid var(--line-clr3);
  }

  .tab-basic-buttons-list {
    display: inline-block;
    vertical-align: top;
    padding: 0 15px;
    width: auto;
  }

  .tab-basic-buttons-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0 6px;
    border: 0;
    font-size: 13px;
    line-height: 35px;
  }

  .tab-basic-buttons-item + .tab-basic-buttons-item::before {
    display: none;
  }

  .tab-basic-buttons-item::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 2px solid var(--main-clr) !important; // 임시 important
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  .tab-basic-buttons-item.js-tabpanel-active,
  .tab-basic-buttons-item.is-tab-basic-active {
    background: none;
    color: var(--main-clr);
  }

  .tab-basic-buttons-item.js-tabpanel-active::after,
  .tab-basic-buttons-item.is-tab-basic-active::after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .tab-basic-buttons-item + .tab-basic-buttons-item {
    margin-left: 12px;
  }

  .tab-basic .tab-basic-buttons-item {
    font-size: 14px !important;
  }

  .tab-basic .tab-basic-buttons {
    width: 100% !important;
  }

  .tab-basic {
    margin-top: 20px;
  }
}

/* common */

/* 다운로드 박스 */

.version-download-box {
  .btn-style01 {
    padding: 0 18px;
    background: url(/img/hss_content/hps/ico_down.png) no-repeat right 20px top 10px;
    text-align: left;
    border-radius: 0 !important;
    vertical-align: -1px;
  }

  .select-basic {
    margin-right: 10px;
  }
}

/* [공통] 컨텐츠 타이틀 */
.common-title {
  color: var(--body-clr);
  font-size: 40px;
  font-weight: $bold;
}

/* [공통] 버튼정렬 */
.btnalign-box {
  position: relative;
  margin-top: 40px;
  text-align: center;
}

/* [공통] 리스트 */
.common-list02 {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid var(--main-clr);

  > li {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 77px;
    padding: 14px 20px 13px 0;
    border-bottom: 1px solid var(--line-clr3);

    .number,
    .name {
      width: 107px;
      font-size: 14px;
      text-align: center;
      color: #666;
    }

    .info .title a {
      display: block;
      width: 983px;
      padding: 4px 0;
      font-size: 16px;
      font-weight: $regular;
      line-height: normal;
      word-break: break-all;
      color: var(--body-clr);

      &:hover,
      &:focus {
        text-decoration: underline;
        text-underline-offset: 3px;
      }

      &.link01 {
        width: 803px;
      }

      &.link02 {
        width: 883px;
      }

      &.link03 {
        width: 913px;
      }
    }

    .info .date {
      margin-top: 10px;
      font-size: 12px;
      font-weight: $regular;
      color: #666;
    }

    .state {
      width: 110px;
      margin-left: auto;
      text-align: center;
    }

    .snsbox {
      position: relative;
      width: 80px;
      text-align: center;
      overflow: hidden;

      .btn-downlist {
        display: block;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(/img/hss_common/button/btn_down02.gif) no-repeat 0 0;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
      }

      .tooltip-layerpopup {
        position: absolute;
        top: 52px;
        right: -400px;
        width: 389px;
        height: 118px;
        padding: 0;
        border: 1px solid #000;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background: var(--bg-clr2);
        -webkit-box-shadow: 9px 9px 43px -1px rgba(227, 227, 227, 1);
        -moz-box-shadow: 9px 9px 43px -1px rgba(227, 227, 227, 1);
        box-shadow: 9px 9px 43px -1px rgba(227, 227, 227, 1);
        z-index: 100;

        &::after {
          position: absolute;
          top: -9px;
          right: 68px;
          content: " ";
          display: block;
          width: 15px;
          height: 9px;
          background: url(/img/hss_common/bg_tooltip.gif) no-repeat 0 0;
        }
      }

      .tooltip-layerpopup-close {
        position: absolute;
        top: -50px;
        right: 57px;
        width: 40px;
        height: 40px;
        background: url(/img/hss_common/button/btn_close.gif) no-repeat 0 0;
        font-size: 0;
        line-height: 0;
        z-index: 100;
      }

      .tooltip-scroll-wrap {
        position: relative;
        width: 387px;
        height: 116px;
      }

      .tooltip-scroll {
        overflow: hidden;
        position: absolute;
        z-index: 1;
        top: 5px;
        bottom: 5px;
        right: 5px;
        left: 5px;
        text-align: left;

        .scroller {
          position: absolute;
          z-index: 1;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          width: 100%;
          -webkit-transform: translateZ(0);
          -moz-transform: translateZ(0);
          -ms-transform: translateZ(0);
          -o-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-text-size-adjust: none;
          -moz-text-size-adjust: none;
          -ms-text-size-adjust: none;
          -o-text-size-adjust: none;
          text-size-adjust: none;

          .list {
            padding: 14px 0;
          }

          .list li {
            margin-top: 8px;
          }

          .list li:first-child {
            margin-top: 0;
          }

          .list li a {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &.scroll-type {
        overflow: unset;

        .tooltip-layerpopup {
          right: -38px;
        }
      }
    }

    .btn-style01 {
      width: 112px;
      background-position: right 14px top 11px;
    }
  }

  .btn-style01 {
    width: 112px;
    margin-left: auto;
    margin-right: 20px;
    background: url(/img/hss_content/hps/ico_down.png) no-repeat right 20px top 10px / auto;
    text-align: left;
    margin-top: 10px;
    margin-right: 0;
  }
}

.common-list02.style01 > li {
  .info .link05 {
    width: 693px;
  }

  .info .link06 {
    width: 818px;
  }

  .number {
    max-width: 90px;
  }

  .state {
    width: 160px;
  }

  .snsbox {
    width: 175px;
  }
}

.common-list02.style01 li {
  position: relative;

  .info {
    width: 80%;
  }
}

.common-list02.style01 li .btn-style01 span {
  font-size: 12px;
}


@media (max-width:$wrapSize) {
  .common-list02 > li {
    .info {
      @include flex($align: normal, $direction: column);
    }

    .snsbox {
      .tooltip-layerpopup {
        width: 320px;
        height: 118px;
      }

      &.scroll-type .tooltip-layerpopup {
        right: 0;
      }

      .tooltip-scroll-wrap {
        width: 320px;
      }
    }

    .number,
    .name {
      width: 65px;
    }

    .info .title a {
      width: auto !important;
      white-space: inherit;
      line-height: normal;
      padding: 0;
    }
  }

  .common-viewtitle .title {
    width: auto;
  }

  .common-viewattachments .title {
    padding: 0 30px 0 20px;
  }

  .common-list02.type01 > li {
    position: relative;
    padding-top: 60px;

    .state {
      position: absolute;
      top: 20px;
      left: 0;
      text-align: left;
    }
  }
}

/* [공통] 상세 */
.common-viewtitle {
  padding: 20px 0 20px 20px;
  border-top: 1px solid var(--main-clr);
  border-bottom: 1px solid var(--line-clr3);

  &::after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .title {
    float: left;
    width: 1010px;
    color: var(--body-clr);
    font-size: 20px;
    font-weight: $bold;

    .badge {
      margin-right: 5px;
      vertical-align: 2px;
    }
  }

  .date {
    display: block;
    margin-top: 15px;
    color: #666;
    font-size: 14px;
  }

  .snsbox {
    position: relative;
    float: right;
    width: 64px;

    .tooltip-layerpopup {
      display: none;
      position: absolute;
      top: 46px;
      right: -48px;
      width: 381px;
      height: 148px;
      padding: 40px 0;
      border: 1px solid #000;
      text-align: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: var(--bg-clr2);

      &::after {
        position: absolute;
        top: -9px;
        right: 67px;
        content: " ";
        display: block;
        width: 15px;
        height: 9px;
        background: url(/img/hss_common/bg_tooltip.gif) no-repeat 0 0;
      }
    }

    .tooltip-layerpopup-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 10px;
      height: 10px;
      background: #3399ff;
      z-index: 100;
    }

    .snslink-view {
      display: block;
      overflow: hidden;
      width: 64px;
      height: 30px;
      background: url(/img/hss_content/com/icon_share.png) no-repeat right 20px center;
      font-size: 0;
      line-height: 0;
    }
  }
}

.tab-basic-contents .common-viewtitle {
  margin-top: 40px;
}


.common-viewdetail {
  padding: 40px 20px;
  border-bottom: 1px solid var(--line-clr3);
  color: #666;
  font-size: 16px;
  line-height: 30px;

  p {
    margin-top: 35px;
    color: #666;
    font-size: 16px;
    line-height: 30px;

    &:first-child {
      margin: 0;
    }
  }
}

.common-viewattachments {
  display: table;
  width: 100%;
  border-bottom: 1px solid var(--line-clr3);

  .title {
    display: table-cell;
    height: 77px;
    padding: 0 0 0 20px;
    background: #f7f7f7;
    color: var(--body-clr);
    font-size: 16px;
    font-weight: $bold;
    vertical-align: middle;
  }

  .list {
    display: table-cell;
    padding: 20px;
    color: var(--body-clr);
    font-size: 16px;
    font-weight: $bold;
    vertical-align: middle;

    li {
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

/* [공통] 테이블 이전글,다음글 보기 */
.common-previous-next {
  width: 100%;
  margin-top: 60px;
  // border: 1px solid var(--line-clr3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  &::after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

.common-previous-next li {
  float: left;
  width: 50%;
  height: 78px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.common-previous-next li .link {
  position: relative;
  display: block;
  height: 78px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.common-previous-next li .link:hover,
.common-previous-next li .link:focus {
  background: #f7f6ed;
}

.common-previous-next li .type {
  position: absolute;
  top: 25px;
  right: 20px;
  display: inline-block;
  padding: 7px 34px 5px 0;
  color: #666;
  font-size: 16px;
  font-weight: $bold;
}

.common-previous-next li .text {
  position: absolute;
  top: 30px;
  right: 115px;
  width: 410px;
  color: #666;
  font-size: 16px;
}

.common-previous-next li:first-child {
  border-right: 1px solid var(--line-clr3);
}

.common-previous-next li:first-child .type {
  position: absolute;
  top: 25px;
  right: auto;
  left: 20px;
  display: inline-block;
  padding: 5px 0 5px 43px;
}

.common-previous-next li:first-child .text {
  position: absolute;
  top: 30px;
  right: auto;
  left: 128px;
  width: 410px;
  color: #666;
  font-size: 16px;
}


/* [공통] 뱃지 */
.badge {
  display: inline-block;
  width: 70px;
  height: 22px;
  padding-top: 3px;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f15757;
  color: var(--bg-clr2);
  font-size: 11px;
  text-align: center;
  line-height: 16px;
  font-weight: $bold;
}

.badge.style01 {
  background: #efedee;
  border: 1px solid var(--disable-clr);
  color: var(--cap-clr);
}

.badge.style02 {
  width: 43px;
  background: var(--bg-clr2);
  border: 1px solid #18a33e;
  color: #18a33e;
}

/* [공통] 커스텀 iscroll 디자인스타일 */
.iScrollVerticalScrollbar {
  position: absolute;
  z-index: 9999;
  width: 6px;
  bottom: 0;
  top: 0;
  right: 0;
  overflow: hidden;
  border-radius: 8px;
  background: var(--disable-clr);
}

.iScrollVerticalScrollbar.iScrollBothScrollbars {
  bottom: 0;
}

.iScrollIndicator {
  position: absolute;
  border-radius: 8px;
}

.iScrollVerticalScrollbar .iScrollIndicator {
  width: 100%;
  background: var(--main-clr);
}

/* fs_newfnewpres_0100.html */
.pressreleases-list {
  margin-top: 23px;
  border-top: 1px solid var(--main-clr);
}

.pressreleases-list li {
  border-bottom: 1px solid var(--line-clr3);
}

.pressreleases-list li .link {
  display: table;
  overflow: hidden;
  width: 100%;
  padding: 40px 20px 40px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.pressreleases-list li .img-box {
  display: table-cell;
  padding-right: 57px;
}

.pressreleases-list li .img-box img {
  width: 325px;
}

.pressreleases-list li .info {
  display: table-cell;
  vertical-align: middle;
}

.pressreleases-list li .info .title {
  width: 1080px;
  color: var(--body-clr);
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pressreleases-list li .info .title .date {
  display: block;
  margin-top: 15px;
  color: #666666;
  font-size: 12px;
}

.pressreleases-list li .img-box + .info .title {
  width: 660px;
}

.pressreleases-list li .text {
  margin-top: 30px;
  color: #666666;
  font-size: 16px;
  line-height: 30px;
}

/* tooltip 이미지 */
.common-viewtitle .snsbox .tooltip-layerpopup .kakao {
  background: url(/img/hss_content/com/bul_kakao_off.png) no-repeat 50% 0
}

.common-viewtitle .snsbox .tooltip-layerpopup .instagram {
  background: url(/img/hss_content/com/bul_insta_off.png) no-repeat 50% 0
}

.common-viewtitle .snsbox .tooltip-layerpopup .facebook {
  background: url(/img/hss_content/com/bul_facebook_off.png) no-repeat 50% 0
}

.common-viewtitle .snsbox .tooltip-layerpopup .urlcopy {
  background: url(/img/hss_content/com/bul_url_off.png) no-repeat 50% 0
}

.common-viewtitle .snsbox .tooltip-layerpopup .sns {
  display: inline-block;
  width: 79px;
  padding-top: 65px;
  text-align: center;
  font-size: 12px;
  font-weight: $bold;
  color: #666;
}

.common-viewtitle .snsbox .tooltip-layerpopup .tooltip-layerpopup-close {
  background: url(/img/hss_content/com/bg_close.png);
  font-size: 0;
}

.common-viewtitle .snsbox .tooltip-layerpopup .kakao:hover {
  background-image: url(/img/hss_content/com/bul_kakao_on.png);
}

.common-viewtitle .snsbox .tooltip-layerpopup .instagram:hover {
  background-image: url(/img/hss_content/com/bul_insta_on.png);
}

.common-viewtitle .snsbox .tooltip-layerpopup .facebook:hover {
  background-image: url(/img/hss_content/com/bul_facebook_on.png);
}

.common-viewtitle .snsbox .tooltip-layerpopup .urlcopy:hover {
  background-image: url(/img/hss_content/com/bul_url_on.png);
}

.common-previous-next .link.prev::after {
  content: '';
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 26px;
  ;
  width: 14px;
  height: 25px;
  background: url(/img/hss_content/com/bul_leftarrow_off.png) no-repeat 0 0;
  background-size: cover;
}

.common-previous-next .link.next::after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 26px;
  ;
  width: 14px;
  height: 25px;
  background: url(/img/hss_content/com/bul_rightarrow_off.png) no-repeat 0 0;
  background-size: cover;
}

.common-previous-next .link.prev:hover::after {
  background-image: url(/img/hss_content/com/bul_leftarrow_on.png);
}

.common-previous-next .link.next:hover::after {
  background-image: url(/img/hss_content/com/bul_rightarrow_on.png);
}


/*팝업- 위치정보 검색결과 화면 팝업*/
.loca-pop01 {
  width: 700px;
}


.loca-pop01 .info-boxWp {
  padding: 30px 30px;
  position: relative;
  border-bottom: 1px solid var(--disable-clr);
}

.loca-pop01 .info-boxWp li {
  display: table;
  vertical-align: top;
  width: 100%;
}

.loca-pop01 .info-boxWp li > strong {
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 100px;
  padding-left: 15px;
  font-size: 16px;
  font-weight: $bold;
  line-height: 36px;
  letter-spacing: -.25px;
}

.loca-pop01 .info-boxWp li .l-info {
  display: table-cell;
  vertical-align: top;
  width: auto;
  padding-right: 60px;
}

.loca-pop01 .info-boxWp li .l-info p {
  font-size: 16px;
  line-height: 36px;
  letter-spacing: -.25px;
}


.loca-pop01 .btn-go-map a {
  position: absolute;
  right: 30px;
  top: 40px;
  width: 55px;
  height: 54px;
  background: url(/img/hss_common/ico-map-p.png) no-repeat top;
  padding-top: 60px;
}

.loca-pop01 .btn-go-map a span {
  display: block;
  color: #666;
  font-size: 12px;
  letter-spacing: -.25px;
  text-align: center;
}

.pop-table-wp {
  padding: 40px 30px 30px;
}

.tbl-wrap.type02 {
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);
  margin-top: 0;
}

.tbl-wrap.type02 th {
  background: #f7f7f7;
  font-size: 14px;
  border-right: 1px solid var(--disable-clr);
}

.pop-btn-wp {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.pop-btn-wp button {
  width: 220px;
  height: 50px;
}

.pop-btn-wp button:first-child {
  margin-right: 5px;
}

/*팝업-위치정보레이어팝업*/
.str-searPop {
  position: relative;
  width: 500px;
}

.store-popWp {
  padding: 75px 0px 60px;
  text-align: center;
}

.store-popWp strong.info {
  font-size: 22px;
  font-weight: $bold;
  line-height: 36px;
  letter-spacing: -.25px;
}

.store-popWp .pop-btn-wp {
  margin: 35px 0 0;
}

.store-popWp .pop-btn-wp button {
  width: 150px;
  height: 40px;
}

.layer-container button.layer-close {
  position: absolute;
  right: 30px;
  top: 20px;
  width: 23px;
  height: 23px;
  font-size: 0;
  text-indent: -9999em;
  background: url(/img/hss_common/layer-close.png) no-repeat center;
}

.layout-container::before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: -999999px;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 900;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

html.js-html-layer-opened .layout-container::before {
  left: 0;
  opacity: 0.8;
}

html.js-html-layer-closed-animate .layout-container::before {
  opacity: 0;
}

.pr-roomWp .common-tblinfobox {
  margin-bottom: 20px;
}

/* 영상 */
.prroom-media {
  margin-top: 40px;
}

.media-wp {
  height: 460px;
  background: #f3f9e8;
  padding: 40px 20px 35px 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 60px;
}

.media-area {
  overflow: hidden;
}

.media-area .media {
  float: left;
  width: 620px;
  height: 320px;
}

.media-txtWp {
  float: left;
  width: 380px;
  height: 350px;
  padding: 0px 0px 0 40px;
  overflow-y: scroll;
}

.media-txtWp strong.mtit {
  display: block;
  font-size: 22px;
  letter-spacing: -.25px;
  padding-top: 5px;
}

.media-txtWp .m-txt {
  padding-top: 40px;
}

.media-txtWp .m-txt p {
  color: #666;
  font-size: 16px;
  letter-spacing: -.25px;
  line-height: 28px;
}

.media-wp .m-str {
  color: #666;
  font-size: 14px;
  letter-spacing: -.25px;
  margin-top: 18px;
}

ul.new-thumb-list li > a {
  display: block;
  border: 1px solid var(--disable-clr);
  text-decoration: none;
}

.new-thumbWp.media ul.new-thumb-list li .t-media-wp {
  width: 322px;
  height: 180px;
}

.new-thumbWp.media ul.new-thumb-list li .t-media-wp img {
  height: 180px;
  vertical-align: top;
}

.new-thumbWp.media ul.new-thumb-list li .txt-wp {
  border-top: 1px solid var(--disable-clr);
  padding: 17px 20px;
  height: 80px;
  box-sizing: border-box;
  min-height: inherit;
}

ul.new-thumb-list li .tit {
  display: block;
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $bold;
  line-height: 24px;
  letter-spacing: -.25px;
}

/* 게시판 */
.common-list02.style01 .state {
  font-size: 14px;
  color: #666
}

/* 글씨 색상 */
.f-color07 {
  color: var(--main-clr) !important;
}

.f-color08 {
  color: red !important;
}

/* FAQ 게시판 */

.slide-box-area.only-top .slide-box-top {
  border-bottom: 1px solid var(--disable-clr);
  border-top: 0 !important;
}

.slide-box-area.bottom {
  margin-bottom: 50px;
}

.slide-box-top h3 {
  margin: 20px 0;
  color: var(--cap-clr);
}

.radio-agree-wrap label {
  margin-right: 40px;
}

.radio-agree-wrap label:last-child {
  margin-right: 0;
}

.btn-right-wrap {
  float: right;
  position: relative;
}

.btn-slide {
  background: url("/img/hss_common/icon_select_fff.png") no-repeat center var(--body-clr);
  width: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.slide-box-txt h4,
.slide-box-txt p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.7;
}

.slide-box-area.type02 .agree-n .label-wrap:last-child::after {
  display: none;
}

.slide-box-area.type02 .agree-n .label-wrap:first-child::before {
  display: none;
}

.slide-box-area.type02 .chk-agree .label-wrap:first-child::before,
.slide-box-area.type02 .chk-agree .label-wrap:first-child::after {
  display: none;
}

.faq-tab .slide-box-area.type02.qna .slide-box-top .sub-title {
  margin: 23px 0;
}

.slide-box-top::after {
  display: block;
  content: '';
  clear: both;
}


.btn-slide-active .sub-title {
  color: var(--cap-clr) !important;
}


/*연력탭*/
.tab-basic.fsb-style .tab-basic-buttons {
  width: 460px;
  margin: 0 auto;
}

.tab-basic.fsb-style .tab-basic-buttons-item {
  border: 0;
  font-size: 28px;
}


.tab-basic.fsb-style .tab-basic-buttons-item + .tab-basic-buttons-item::before {
  top: 11px;
  bottom: 11px;
  margin-left: -19px;
  border-left: 1px solid var(--disable-clr);
}

.tab-basic.fsb-style .tab-basic-buttons-item.js-tabpanel-active {
  position: relative;
  background-color: var(--bg-clr2);
  color: var(--main-clr)
}

.tab-basic.fsb-style .tab-basic-buttons-item.js-tabpanel-active::after {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 3px solid var(--main-clr);
}

/*연력*/
.history-box {
  width: $wrapSize;
  margin-top: 40px;
  padding: 70px 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #efefef;
}

.history-box::after {
  content: '';
  display: table;
  clear: both;
}

.history-box ul {
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-bottom: 15px;
}

.history-box ul::after {
  content: '';
  display: table;
  clear: both;
}

.history-box li {
  clear: both;
  width: 520px;
  margin-bottom: -15px;
}

.history-box li > div {
  position: relative;
  padding: 20px;
  background: var(--bg-clr2);

  div {
    @include flex($align: start, $gap: 8px);
  }
}

.history-box li > div::before {
  content: '';
  display: block;
  position: absolute;
  border: 0 solid transparent;
  border-width: 7px 10px;
  top: 0;
  border-top-color: var(--bg-clr2);
}

.history-box li > div::after {
  content: '';
  display: block;
  position: absolute;
  border: 5px solid var(--main-clr);
  border-radius: 100%;
  top: 0;
  width: 13px;
  height: 13px;
  background: var(--bg-clr2);
}

.history-box li:nth-child(2n) {
  float: left;
}

.history-box li:nth-child(2n) > div::before {
  left: 100%;
  border-left-color: var(--bg-clr2);
}

.history-box li:nth-child(2n) > div::after {
  left: 100%;
  margin-left: 28px;
}

.history-box li:nth-child(2n+1) {
  float: right;
}

.history-box li:nth-child(2n+1) > div::before {
  right: 100%;
  border-right-color: var(--bg-clr2);
}

.history-box li:nth-child(2n+1) > div::after {
  right: 100%;
  margin-right: 28px;
}

.history-box li:last-child {
  position: relative;
}

.history-box li:nth-child(2n):last-child::before {
  left: auto;
  right: -42px;
}

.history-box li:last-child::before {
  content: '';
  display: block;
  position: absolute;
  top: -8000px;
  bottom: 100%;
  left: -42px;
  border-left: 4px solid var(--main-clr);
  z-index: -1;
}

.history-box .year {
  display: block;
  font-size: 18px;
  font-weight: $bold;
  color: var(--main-clr);
  margin-bottom: 8px;
}

.history-box .month {
  // display: inline-block;
  // width: 35px;
  // margin-top: 4px;
  font-size: 14px;
  font-weight: $bold;
  // vertical-align: top;
  line-height: 24px;
}

.history-box .text {
  // display: inline-block;
  // width: 440px;
  font-size: 14px;
  line-height: 24px;
}

@media (max-width: $wrapSize) {
  .history-box .text {
    width: 100%;
  }
}

/* 안내문구 - 리스트 스타일 */

.popup-body01 .notice-list-wrap {
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
}

.notice-list-wrap .sub-text {
  font-size: 14px;
}

.notice-list-wrap + .tab-basic {
  margin-top: 35px;
}

.product-view + .notice-list-wrap {
  margin-top: 20px;
}

.table-wrap01 + .notice-list-wrap {
  margin-top: 20px;
}

.info-table-area + .notice-list-wrap {
  margin-top: 40px;
}

.sub-text + .notice-list {
  margin-top: 10px;
}

.tts + .notice-list-wrap {
  margin-top: 40px;
}

.notice-list-wrap + .tab-basic.pt50 {
  margin-top: -50px;
}

.notice-list-wrap.type02 {
  background-color: transparent;
}

.notice-list-wrap.type02 .notice-list {
  background: transparent;
}

.notice-list-wrap.type02 .notice-list li {
  padding-left: 0;
}

.notice-list-wrap.type02 .notice-list li::before {
  display: none;
}

.notice-list-in li {
  padding-left: 0;
}

.notice-list-in li:first-child {
  margin-top: 5px;
}

.notice-list-in li::before {
  display: none;
}

.textform-style01 {
  margin: 47px 0 -5px;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.3;
}

.table-titlestyle01 {
  margin-top: 9px;
  font-weight: 500;
}

.table-textstyle01 {
  margin-top: 5px;
  font-size: 14px;
  line-height: 23px;
}

.common-viewdetail {
  padding: 40px 20px;
  border-bottom: 1px solid var(--line-clr3);
  color: #666;
  font-size: 16px;
  line-height: 30px;
}

.common-viewdetail p {
  margin-top: 35px;
  color: #666;
  font-size: 16px;
  line-height: 30px;
}

.common-viewdetail p:first-child {
  margin: 0;
}

/* 190613 수정 */
.table-area .btn-area.style01 {
  margin-top: -10px;
  padding-top: 0;
}

.search-table-area .btn-area.type02,
.info-table-area .btn-area.type02 {
  margin-top: 40px !important;
  padding-top: 0;
}

/* event list */
.event-info {
  margin-top: 40px;
}

.event-info dt,
.event-info dd {
  display: inline-block;
  margin-top: 15px;
  vertical-align: top;
}

.event-info .title {
  width: 7%;
  margin-top: 19px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: $bold;
  color: var(--body-clr);
}

.event-info .text {
  width: 90%;
  font-size: 14px;
  color: var(--body-clr);
  line-height: 25px;
}

.event-info .text strong {
  font-weight: $bold;
}

.event-info .text.type01,
.event-info .title.type01 {
  margin-top: 18px;
  vertical-align: top;
}

.event-info .title.type01 {
  margin-top: 22px;
}

.event-info .btn-sm-wh {
  margin-left: 15px;
}

.event-list li {
  font-size: 14px;
  color: var(--body-clr);
  line-height: 28px;
  font-weight: $regular;
}

/* 모바일 페이징 */
.paging-wrap {
  margin-top: -20px;
  text-align: center;
}

.paging-wrap a {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-top: 50px;
  padding-top: 11px;
  vertical-align: top;
  border: 1px solid #efefef;
  font-size: 14px;
  color: #666;
  font-weight: $bold;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.paging-wrap .prev {
  background: url(/img/hss_common/page_left.png) no-repeat 50% 50%;
  border: 0;
  font-size: 0;
}

.paging-wrap .next {
  background: url(/img/hss_common/page_right.png) no-repeat 50% 50%;
  border: 0;
  font-size: 0;
}

.paging-wrap .on {
  background: var(--cap-clr);
  color: var(--bg-clr2);
  border-color: var(--cap-clr)
}

.paging-wrap a + a {
  margin-left: 2px;
}

/*소식 - 홍보자료실 -영상 */
.prroom-media {
  margin-top: 40px;
}

.media-wp {
  height: 460px;
  margin-bottom: 60px;
  padding: 40px 20px 35px 40px;
  background: #f3f9e8;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.media-area {
  overflow: hidden;
}

.media-area .media {
  float: left;
  width: 620px;
  height: 320px;
}

.media-scroll-wrap {
  float: left;
  position: relative;
  width: 380px;
  height: 350px;
  padding: 0;
}

.media-scroll {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  text-align: left;
}

.media-scroll .scroller {
  position: absolute;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none;
}

.media-scroll .scroller p {
  padding-right: 20px;
}

.media-area .mtit {
  display: block;
  font-size: 22px;
  letter-spacing: -.25px;
  padding-top: 5px;
}

.media-scroll .m-txt {
  padding-top: 40px;
}

.media-scroll .m-txt p {
  color: #666;
  font-size: 16px;
  letter-spacing: -.25px;
  line-height: 28px;
}

.media-wp .m-str {
  margin-top: 18px;
  color: #666;
  font-size: 14px;
  letter-spacing: -.25px;
}

.new-thumbWp.media ul.new-thumb-list li .t-media-wp {
  width: 322px;
  height: 180px;
}

.new-thumbWp.media ul.new-thumb-list li .t-media-wp img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  /*임시 : 동영상 올리고나중에 삭제해야함 */
}

.new-thumbWp.media ul.new-thumb-list li .txt-wp {
  height: 80px;
  border-top: 1px solid var(--disable-clr);
  padding: 17px 20px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  min-height: inherit;
}

/* [공통] 검색영역 */
.searchbox-wrap {
  display: inline-block;
  font-size: 0;
  line-height: 0;
}

.searchbox-wrap .input-text {
  width: 400px;
  height: 47px;
  padding: 0 50px 0 11px;
  border: 1px solid var(--disable-clr);
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.6;
  border-radius: 10px;
  box-sizing: border-box;
}

.searchbox-wrap .search-send {
  display: inline-block;
  width: 40px;
  height: 47px;
  margin-left: -50px;
  background: url(/img/common/icon_search.png) right 12px center/22px 22px no-repeat;
}

/* [공통] 테이블 상단 total/search 영역 */
.common-tblinfobox {
  @include flex($gap: 10px);
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  // &:first-child {
  //   margin-top: 0;
  // }
}

.common-tblinfobox .totalbox-wrap {
  margin-right: auto;
  font-size: 16px;
  font-weight: $regular;
}

.common-tblinfobox .searchbox-wrap {
  @include flex($gap: 10px, $justify: space-between);

  &:only-child {
    margin-left: auto;
  }

  .input-text {
    height: 47px;
  }

  // .search-send {
  //   margin-left: -50px;
  // }

  .search-box {
    padding: 0;
  }

  .select-basic {
    padding: 10px;
    background-size: 10px;
    line-height: normal;
  }
}

.searchbox-wrap.type01 input {
  width: 300px;
  font-weight: $regular;
}

@media (max-width: $wrapSize) {
  .common-tblinfobox {
    flex-direction: column;
  }
}

/* 셀렉트박스 있을때 */
/* .common-tblinfobox.style01 .search-box {float:right;width:187px;margin-right: 13px;} */
.common-tblinfobox.style01 .search-box {
  display: inline-block;
  width: 187px;
}

.common-tblinfobox.style01 .select-basic {
  min-height: 40px;
}

/* Q&A */
.essential-box {
  margin-bottom: 10px;
  text-align: right;
}

.essential-box .essential {
  margin-right: 20px;
  font-size: 14px;
  font-weight: $regular;
  color: var(--body-clr);
}

.essential::before {
  content: "*";
  color: var(--neg-clr);
  padding: 3px;
}

.email-box select {
  margin-left: 10px;
  vertical-align: middle
}

textarea {
  width: 100%;
  border: 1px solid var(--disable-clr);
  height: 270px;
  padding: 10px;
  resize: none;
  overflow-y: auto
}

.org-accord-wp {
  margin-top: 60px;
}

.org-info-wp {
  padding: 28px 20px;
}

.org-info {
  padding-bottom: 15px;
}

.org-info p {
  display: inline-block;
  font-size: 16px;
  letter-spacing: -0.5px;
}

.org-info p strong {
  display: inline-block;
  margin-right: 5px;
  font-weight: $bold;
}

.org-txt {
  display: table;
  width: 100%;
}

.org-txt .wtit {
  display: table-cell;
  vertical-align: top;
  width: 75px;
  padding-top: 5px;
}

.org-txt .wtit strong {
  display: block;
  font-size: 16px;
  font-weight: $bold;
  letter-spacing: -0.25px;
}

.org-txt .wtxt {
  display: table-cell;
  vertical-align: top;
  width: auto;
}

.org-txt .wtxt li {
  position: relative;
  padding-left: 13px;
  color: #666;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.25px;
}

.noitce-wrap {
  margin-top: 8px;
}

.noitce-wrap > li {
  padding: 0 10px 0 36px;
  font-size: 16px;
  color: var(--body-clr);
  font-weight: $regular;
  line-height: 32px;
  text-indent: -15px;
  word-break: keep-all;
}

.noitce-wrap li::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-top: 11px;
  margin-right: 10px;
  background: var(--cap-clr);
  border-radius: 50%;
  vertical-align: top;
}

.notice-tit {
  margin-top: 20px;
  font-size: 14px;
  font-weight: $regular;
  color: var(--neg-clr);
}

.notice-tit::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 15px;
  background: url(/img/hss_common/bul-notice.png) no-repeat;
  vertical-align: middle;
  filter: brightness(2) opacity(0.85);
}

/* input-width */
.maxw-none {
  max-width: inherit !important;
}

.w320 {
  max-width: 320px !important;
}

.pro-accordion-item {
  position: relative;
}

.pro-accordion-head {
  position: relative;
}

.pro-accordion-text {
  display: block;
  padding: 0 20px;
  background-color: var(--bg-clr2);
  font-size: 16px;
  font-weight: $bold;
  line-height: 50px;
  color: var(--body-clr);
}

.pro-accordion-opener {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 1px solid var(--disable-clr);
}

.pro-accordion-layer {
  height: 0;
  display: none;
  background: #f7f7f7;
}

.pro-accordion-layer .component-layer-close-button {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
}

.pro-accordion-list {
  /* border-bottom:1px solid var(--disable-clr); */
  border-left: 0;
  border-right: 0;
}

.pro-accordion-opener {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/img/hss_common/ico-accor.png) no-repeat 100% center
}

.js-accordion-opened .pro-accordion-opener {
  background: url(/img/hss_common/ico-accor-on.png) no-repeat 100% center;
  border-top: 1px solid var(--disable-clr);
  border-bottom: 1px solid var(--disable-clr);
}

.js-accordion-opened .pro-accordion-text {
  background-color: #f7f7f7;
}

.pro-infoBox {
  padding: 0 20px 30px;
}

.pro-infoBox p.info-txt {
  padding: 28px 80px 28px 0;
  color: #666;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -.25px;
}

.table01-wrap {
  background: var(--bg-clr2);
  border-top: 1px solid var(--line-clr3);
  border-bottom: 1px solid var(--line-clr3);
}

.table01-wrap table th {
  background: var(--bg-clr2);
  border-right: 1px solid var(--line-clr3);
}

.table01-wrap table th.vam {
  vertical-align: middle
}

.ui-accordion-list.style01 {
  margin-top: 20px;
}


.terms-wrap {
  padding: 20px 20px 15px;
  font-size: 14px;
}

.terms-wrap .terms-box {
  margin-top: 7px;
}

.terms-wrap .terms-box li,
.terms-wrap .terms-box p {
  font-weight: $regular;
  line-height: 24px;
}

/* 레이아웃 관련 */


.sub-title-area::after {
  content: '';
  display: block;
  clear: both;
}

.sub-title-area {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.sub-title-area + .table-wrap01,
.sub-title-area + .com-msgbox,
.sub-title-area + .steplist-box {
  margin-top: 20px;
}

.sub-title-area .notice-subtext {
  margin-top: 15px;
}

.sub-title-area + .notice-list-wrap {
  margin-top: 15px;
}

.sub-title {
  display: inline-block;
  width: auto;
  padding-right: 15px;
  font-size: 22px;
  font-weight: $medium;
  line-height: 28px;

  &.accoBtn {
    @include flex($justify: space-between);
    width: 100%;
  }
}

.sub-text {
  font-size: 16px;
  font-weight: $medium;
}


.btn-list {
  width: 26px;
  height: 22px;
}

.btn-list::after {
  content: '';
  display: block;
  width: 19px;
  height: 19px;
  margin: auto;
  background: url(/img/common/iconbox_account_list.png) no-repeat 0 2px / 100%;
}

.btn-calender {
  width: 26px;
  height: 23px;
  background: url(/img/common/iconbox_account_list.png) 0 100%/100% no-repeat;
}

.btn-calender::after {
  content: "";
  display: block;
  width: 19px;
  height: 19px;
  margin: auto;
  background: url(/img/common/icon/icon_calendar.png) no-repeat center / contain;
}

.content .info-table-area:first-child {
  padding-top: 0;
}

.content .info-table-area + .notice-subtext {
  margin-top: 30px;
}

.info-table-area.gap-type01 .common-text01 {
  margin-top: 15px;
}

.info-table-area.gap-type01 .btn-thin.resize {
  margin-top: 10px;
}

.tts + .sub-title-area {
  margin-top: 40px;
}

@media (max-width:$wrapSize) {
  // .info-table-area {
  //   padding-top: 10px;
  // }

  .info-table-area + .info-table-area {
    margin-top: 40px;
  }

  .sub-title-area .notice-subtext {
    margin-top: 0;
  }

  .sub-title {
    font-size: 18px;
  }
}

.common-text04 {
  color: #666;
  font-size: 16px;
  line-height: 24px;
}

/* text _ 16px #666 */
.common-text01 {
  font-weight: $regular;
}

.common-text01.other-txt {
  margin-top: 30px;
}

.common-text01.dot {
  padding-left: 15px;
}

.common-text01.dot::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: -2px 5px 0 -9px;
  border-radius: 50%;
  background: #666;
  vertical-align: middle;
}

.common-text01,
.common-text01 strong {
  margin-top: 5px;
  font-size: 14px;
  color: #666;
  line-height: 23px;
}

.common-text01.sm-indent {
  padding-left: 17px;
  text-indent: -14px;
}

.common-text01 a {
  color: #666;
  text-decoration: underline;
}

.common-text01 a:hover {
  color: #18a33e;
}

.common-text01 + .common-stit02 {
  margin-top: 30px;
}

.common-text01 + .com-list01 {
  margin-top: 30px;
}

.common-text01 + .com-list04 {
  margin-top: 33px;
}

.common-text01 + .table-wrap01 {
  margin-top: 35px;
}

.common-text01 + .sub-title-area {
  margin-top: 30px;
}

.common-stit02 {
  font-size: 16px;
  font-weight: $bold;
  margin-top: 15px;
}

.common-text01 .link {
  display: inline-block;
}

.common-text03 {
  padding-left: 16px;
}

/* text _ 14px #666 */
.common-text02 {
  font-size: 14px;
  color: #666;
  line-height: 23px;
}

.table-wrap01 + .common-text02 {
  margin-top: 15px;
}

.btn-area + .common-text01 {
  margin-top: 15px;
}

/* list _ 16px #666 */
/* .common-list01 {margin:8px 0 29px;} */
.common-list01 {
  margin: 8px 0 0;
}

.common-list01 li {
  margin-top: 3px;
  font-size: 14px;
  color: #666;
  line-height: 23px;
  font-weight: $regular;
}

.common-list01 li p {
  font-weight: $regular;
}

.common-list01.dot > li {
  padding-left: 15px;
}

.common-list01.dot > li::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: -2px 5px 0 -9px;
  border-radius: 50%;
  background: #666;
  vertical-align: middle;
}

/* 개인정보 처리방침 */
.version-download-box {
  margin-top: 53px;
}

@media (max-width:$wrapSize) {
  .version-download-box {
    margin-top: 20px;
  }

  .info-table-area.first-area {
    padding-top: 30px;
  }
}

.com-list01 li {
  margin-top: 11px;
  font-size: 16px;
  line-height: 28px;
  font-weight: $regular;
}


.com-list02 li {
  margin-top: 0;
  padding-left: 17px;
  font-size: 14px;
  color: #666;
  line-height: 23px;
}

.com-list03 li {
  margin-top: 0;
  font-size: 14px;
  color: #666;
  line-height: 23px;
  font-weight: $regular;
}

.com-list04 li {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
  line-height: 28px;
  font-weight: $regular;
}

.com-list04 li p {
  font-weight: $regular;
  line-height: 28px;
  color: #666
}

.com-list04 li > p.com-msg02 {
  padding-left: 17px;
  text-indent: 0;
}

.com-list05 {
  margin-top: 33px;
}

.com-list05 li {
  margin-top: 8px;
  font-size: 16px;
  color: #666;
  line-height: 28px;
  font-weight: $regular;
}

/* 햇살론 근저당권  */
.notice-type01 {
  margin-left: 15px;
  font-size: 16px;
}

.notice-type01 .title {
  margin-top: 9px;
  font-size: 16px;
  font-weight: $medium;
}

.notice-type01 .text {
  line-height: 30px;
  font-weight: $regular;
}

.notice-subtext strong {
  font-weight: $medium;
}

.notice-subtext {
  padding-left: 15px;
  text-indent: -15px;
  font-size: 14px;
  line-height: 23px;
}

.notice-subtext::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-top: 8px;
  margin-right: 10px;
  background: var(--cap-clr);
  border-radius: 50%;
  vertical-align: top;
}

.notice-type01 .notice-list.style02 li {
  padding-bottom: 0;
  padding-left: 0;
  line-height: 24px;
}

.notice-type01 .notice-list.style02 li .text {
  margin-top: 2px;
  font-weight: $regular;
  line-height: 24px;
}

.notice-type01 .notice-list.style02 li::before {
  display: none;
}

.table-wrap01 + .notice-list {
  padding-top: 15px;
}

.table-wrap01 + .notice-subtext {
  padding-top: 15px;
}

.table-type04.style01 td,
.table-type04.style01 th {
  width: auto !important;
}

.table-type04.style01 td {
  font-size: 14px;
  font-weight: $regular;
}

.table-type04.style01 .notice-list li p,
.table-type04.style01 .notice-list li,
.table-type01.style01 .notice-list li {
  font-size: 14px;
  font-weight: $regular;
}

.table-type04.style01 .notice-list li p,
.table-type01.style01 .notice-list li {
  line-height: 24px;
}

.table-type04.style01 .notice-list .btn-sm-wh {
  margin-top: 2px;
}

.table-type01.style01 td,
.table-type01.style01 th {
  line-height: 24px;
}

/* otp */
.bg-otpimg {
  margin-top: 50px;
  padding: 30px;
  background: #f7f7f7 url(/img/common/bg_otpimg.png) no-repeat right 80px center;
  background-size: 20%;
}

.bg-otpimg .warn-title {
  width: 70%;
}

.bg-otpimg .warn-title::after {
  content: '';
}

.bg-otpimg .warn-txt {
  width: 70%;
}

.bg-otpimg + .info-table-area {
  padding-top: 50px;
}

.icon-btr {
  width: 40px;
  height: 16px;
  background: url(/img/common/icon_btr.png) no-repeat center center / 28px;
  display: inline-block;
  vertical-align: inherit;
}

.icon-lobat {
  width: 224px;
  height: 132px;
  background: url(/img/common/MRT-700nP_low.gif) no-repeat 50% 50%;
  display: inline-block;
  vertical-align: inherit;
}

.warn-title {
  font-size: 24px;
  font-weight: $medium;
  margin-bottom: 20px;
}

.warn-txt {
  font-size: 16px;
  line-height: 24px;
}

.icon-otpex {
  width: 540px;
  height: 168px;
  position: relative;
  top: 0;
  left: 0;
  background: url(/img/hss_content/com/MRT-700nP_otplay.gif) no-repeat center center;
  margin: 10px 0 10px 10px;
}

.icon-otpbg {
  position: absolute;
  top: 0;
  left: 10px;
  background: url(/img/hss_content/com/icon_otpbgimg.png);
  width: 100%;
  height: 100%;
}

/*190311 PEY 추가*/
.icon-list-box {
  width: 100%;
}

.icon-list-box > li {
  height: auto;
  min-height: 393px;
  /*20190531 수정*/
  padding: 40px 22px;
  width: 25%;
  border-right: 1px solid #efefef;
  float: left;
  text-align: center;
}

.icon-list-box > li:last-child {
  border-right: none;
}

.icon-list-txt {
  font-weight: bold;
  font-size: 16px;
  padding: 20px 0;
}

.icon-list01,
.icon-list02,
.icon-list03,
.icon-list04 {
  width: 107px;
  height: 97px;
  background: url(/img/hss_content/com/icon_list01.png);
}

.icon-list02 {
  background: url(/img/hss_content/com/icon_list02.png);
}

.icon-list03 {
  background: url(/img/hss_content/com/icon_list03.png);
}

.icon-list04 {
  background: url(/img/hss_content/com/icon_list04.png);
}

.notice-list.nolist-type2 li {
  font-size: 14px;
}

.notice-list.sm li {
  padding-bottom: 10px;
  font-size: 14px;
}

.notice-list li:last-child {
  padding-bottom: 0;
}

@media (max-width:$wrapSize) {
  .m-none {
    display: none;
  }

  .w-none {
    display: block;
  }

  .content .m-gap-none {
    margin-top: 0;
  }

  .common-title {
    display: none;
  }

  .common-stit {
    margin-top: 21px;
    /*font-size:15px;*/
  }

  .common-text01,
  .common-text01 strong {
    font-size: 12px;
  }

  .common-text02 {
    font-size: 12px;
    line-height: 19px;
  }

  .common-list01 li {
    font-size: 14px;
    line-height: 21px;
  }

  .common-list01.dot > li {
    line-height: 21px;
  }

  .common-titlestyle01 {
    font-size: 19px;
  }

  /* 테이블 */
  .table-type04.style01 td,
  .table-type04.style01 th {
    width: auto !important;
  }

  /*버튼*/
  .btn-box-wrap {
    margin-top: 15px;
  }

  .btn-box {
    text-align: center;
  }

  /*테이블*/
  .tbl-style03 th {
    min-width: 80px;
  }

  /*링크탭*/
  .tab-basic-buttons.link {
    display: none;
  }

  .tab-select {
    display: block;
  }

  .tab-select .sel-basic {
    width: 100%;
  }

  .tab-select ~ .fin-infobox {
    margin-top: 30px;
  }

  /* fs_fsbdire_0100 _ 오시는길 */
  .fsb-bus-list > li {
    width: auto;
    padding-right: 20px;
  }

  .fsb-bus-list .bus-badge {
    width: 48px;
    margin-right: 5px;
    font-size: 12px;
  }

  th.fsb-transport img {
    width: 60px;
  }

  /* fs_fingold_0100 _ 상단 infobox */
  .fin-infobox {
    min-height: auto;
    margin: 0 -18px 0;
    padding: 18px 16px;
  }

  .fin-infobox::after {
    display: none;
  }

  .fin-infobox .title {
    font-size: 17px;
  }

  .fin-infobox .text {
    font-size: 16px;
    line-height: 23px;
  }

  /* fs_compoly_0101 (개인정보처리방침) */
  .version-download-box .sel-basic {
    width: 100%;
  }

  .version-download-box .btn-style01 {
    width: 100%;
    margin: 15px 0 0 0;
  }

  .version-download-box {
    &.flex {
      .btn-style01 {
        width: auto;
        margin: 0;
      }
    }
  }

  /* fs_fsbintresta_0100 _ 설립 및 주요업무 */
  .fsb-bg-textbox {
    background: transparent;
  }

  .fsb-msg01 {
    font-size: 15px;
    line-height: 22px;
  }

  /*주요목적리스트*/
  .fsb-purpose-list > li {
    float: none;
    width: 100%;
    min-height: 150px;
    padding: 15px 0 15px;
    border-left: none;
    border-top: 1px solid var(--line-clr3);
  }

  .fsb-purpose-list > li:first-child {
    border-top: none;
  }

  .fsb-purpose-list > li .title::before,
  .fsb-purpose-list li:nth-child(2) .title::before,
  .fsb-purpose-list li:nth-child(3) .title::before,
  .fsb-purpose-list li:nth-child(4) .title::before {
    width: 100%;
    height: 100px;
    background-size: 70px;
  }

  .fsb-purpose-list .title {
    float: left;
    width: 115px;
    text-align: center;
  }

  .fsb-purpose-list .common-list01 {
    float: left;
    width: calc(100% - 130px);
    margin: 0;
  }

  /*연력*/
  .history-box {
    width: 100%;
    padding: 17px;
  }

  .history-box li {
    width: calc(100% - 52px);
    margin-bottom: 10px;
  }

  .history-box li:nth-child(2n) {
    float: right;
  }

  .history-box li:nth-child(2n) > div::before {
    right: 100%;
    left: auto !important;
    border-left-color: transparent !important;
    border-right-color: var(--bg-clr2);
  }

  .history-box li:nth-child(2n) > div::before {
    right: 100%;
    border-right-color: var(--bg-clr2);
  }

  .history-box li:nth-child(2n) > div::after {
    left: unset;
    right: 100%;
    margin: 0 28px 0 0;
  }

  .history-box li:nth-child(2n):last-child::before {
    left: -42px;
    right: auto;
  }

  /* fs_fsbethicode_0100 _ 상단 infobox _윤리강령행동지침 */
  .fsb-infobox {
    min-height: auto;
    margin: 0 -18px 0;
    padding: 18px 16px;
  }

  .fsb-infobox::after {
    display: none;
  }

  .fsb-infobox .title {
    font-size: 17px;
  }

  .fsb-infobox .text {
    font-size: 16px;
    line-height: 23px;
  }



  .common-viewtitle .snsbox .tooltip-layerpopup {
    right: -3px;
    width: 193px;
    height: 62px;
    padding: 19px 0 40px 0;
  }

  .common-viewtitle .snsbox .tooltip-layerpopup::after {
    right: 20px
  }

  .common-viewtitle .snsbox .tooltip-layerpopup .kakao,
  .common-viewtitle .snsbox .tooltip-layerpopup .instagram,
  .common-viewtitle .snsbox .tooltip-layerpopup .facebook,
  .common-viewtitle .snsbox .tooltip-layerpopup .urlcopy {
    background-size: 80%;
    line-height: 0;
  }

  .common-viewtitle .snsbox .tooltip-layerpopup .sns {
    width: 32px;
    padding-top: 34px;
    font-size: 0;
  }

  /* [공통] 버튼정렬 */
  .btnalign-box {
    margin-top: 15px;
  }

  /* [공통] 상세 */
  .common-viewtitle .title {
    width: auto;
    padding-right: 10px;
    font-size: 18px;
    line-height: 1.5;
  }

  .common-viewtitle .date {
    margin-top: 5px;
    font-size: 12px;
  }

  .common-viewdetail {
    padding: 20px 10px;
    border-bottom: 1px solid var(--line-clr3);
    color: #666;
    font-size: 16px;
    line-height: 30px;
  }

  .common-viewdetail p {
    margin-top: 15px;
    color: #666;
    font-size: 16px;
    line-height: 30px;
  }

  /* [공통] 테이블 이전글,다음글 보기 */
  .common-previous-next {
    margin-top: 30px;
  }

  .common-previous-next li {
    height: 40px;
  }

  .common-previous-next li .link {
    height: 40px;
  }

  .common-previous-next li .text {
    display: none;
  }

  .common-previous-next li .type {
    top: 6px !important;
  }

  .common-previous-next li:first-child {
    border-top: none;
  }

  .common-previous-next .link.next::after {
    top: 7px;
  }

  .common-previous-next .link.prev::after {
    top: 7px;
  }

  .common-previous-next li:first-child .type {
    padding-top: 7px;
  }

  /* [공통] 검색영역 */
  .searchbox-wrap {
    display: inline-block;
    width: 100%;
    max-width: 480px;
    font-size: 0;
    line-height: 0;
  }

  .searchbox-wrap .input-text {
    height: 40px;
    padding: 0 11px;
    border: 1px solid var(--main-clr);
    border-radius: 10px 0 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    font-size: 16px;
    line-height: 1.6;
  }

  .searchbox-wrap .search-send {
    @include size(47px);
    min-width: 47px;
    display: inline-block;
    margin-left: -10px;
    border-radius: 0 10px 10px 0;
    background: var(--main-clr) url(/img/hss_common/button/btn_search.gif) no-repeat 50%;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
  }

  .searchbox-wrap.type01 input {
    width: 300px;
    font-weight: $regular;
  }

  .searchbox-wrap .input-text {
    width: 100%;
  }

  .common-tblinfobox.style01 .search-box {
    float: none;
    width: 30%;
  }

  .common-tblinfobox.style01 .select-basic {
    border: 1px solid var(--main-clr);
  }


  /*소식 _ 홍보자료실 _목록 */
  .pr-roomWp {
    margin: 0;
  }

  .pr-roomWp .tab-basic-buttons {
    margin: 15px 0 20px;
  }

  .searchbox-wrap.type01 {
    position: relative;
    width: 100%;
    padding-right: 41px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .searchbox-wrap.type01 input {
    width: 100%;
  }

  .searchbox-wrap.type01 button {
    position: absolute;
    right: 0;
    top: 0;
  }

  .pr-roomWp .common-tblinfobox {
    margin-top: 15px;
    padding: 0 16px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  ul.new-thumb-list {
    margin: 0;
    /*padding:0 10px;*/
  }

  ul.new-thumb-list li > a {
    border: 0;
  }

  ul.new-thumb-list li {
    width: 100%;
    margin: 0;
    padding: 20px 16px 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: 1px solid var(--disable-clr);
  }

  ul.new-thumb-list li:last-child {
    border-bottom: 0;
  }

  ul.new-thumb-list li .thumb-wp {
    text-align: center;
    width: inherit;
    height: inherit;
  }

  ul.new-thumb-list li .thumb-wp img {
    width: 100%;
    height: auto;
  }

  ul.new-thumb-list li .txt-wp {
    padding: 13px 0px 16px;
    min-height: inherit;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  ul.new-thumb-list li .tit {
    color: var(--body-clr);
    font-size: 14.6px;
    font-weight: $bold;
    line-height: 21px;
    letter-spacing: -.25px;
  }

  ul.new-thumb-list li .txt-wp .date {
    display: block;
    margin-top: 15px;
    color: #666;
    font-size: 12px;
    font-weight: $bold;
    letter-spacing: -.25px;
  }

  .new-thumbWp.tmb-type02 ul.new-thumb-list li .txt-wp {
    border-top: 0;
  }

  /* 2개노출
	ul.new-thumb-list {margin:0 -5px;/*padding:0 10px;*/
  /*}
	ul.new-thumb-list li {width:calc(50% - 10px);margin:0 5px;padding-top:20px;}
	ul.new-thumb-list li .thumb-wp {text-align:center;width:inherit;height:inherit;}
	ul.new-thumb-list li .thumb-wp img {width:100%;height:auto;}
	ul.new-thumb-list li .txt-wp {padding:10px 13px;min-height:80px;box-sizing:border-box;-webkit-box-sizing:border-box;}
	ul.new-thumb-list li .tit{color:var(--body-clr);font-size:14.6px;font-weight: $bold;line-height:22px;letter-spacing:-.25px;}
	ul.new-thumb-list li .txt-wp .date{display:block;color:#666;font-size:12px;font-weight: $bold;letter-spacing:-.25px;margin-top:15px;}
	*/

  /*저축은행 - 사회공헌활동 list */
  .social-cont {
    margin: 20px -16px;
  }

  .new-thumbWp.tmb-type02 ul.new-thumb-list li .thumb-wp {
    width: 100%;
    height: auto;
  }

  .new-thumbWp.tmb-type02 ul.new-thumb-list li .thumb-wp img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }

  .social-cont .common-tblinfobox {
    margin-top: 15px;
    padding: 0 16px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  /*소식 - 저축은행지 - 목록 */
  .bankNews-cont {
    margin: 15px -16px 0;
  }

  .new-thumbWp.tmb-type03 ul.new-thumb-list li {
    padding-bottom: 20px;
  }

  .new-thumbWp.tmb-type03 ul.new-thumb-list li .thumb-wp {
    width: 100%;
    height: auto;
  }

  .new-thumbWp.tmb-type03 ul.new-thumb-list li .thumb-wp img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }

  .new-thumbWp.tmb-type03 ul.new-thumb-list li .info .tit {
    padding: 11px 0 16px;
  }

  .new-thumbWp.tmb-type03 ul.new-thumb-list li .info .btn-wp {
    width: 100%;
  }

  .new-thumbWp.tmb-type03 ul.new-thumb-list li .info .btn-wp > button {
    width: inherit;
    min-width: inherit;
    height: 33px;
    width: calc(50% - 5px);
    margin: 0;
    vertical-align: top;
    font-size: 12px;
    line-height: 33px;
  }

  .new-thumbWp.tmb-type03 ul.new-thumb-list li .info .btn-wp .btn-style01 .down {
    margin: 0 3px;
    padding: 0 25px 0 0;
    background: url(../img/common/button/btn_down.png) no-repeat right center;
    background-size: 15px 15px;
  }

  .new-thumbWp.tmb-type03 ul.new-thumb-list li .info .btn-wp .qview {
    display: inline-block;
    padding: 0 24px 0 0;
    background: url(../img/common/button/btn_search.png) no-repeat right center;
    background-size: 15px 15px;
  }

  /*소식 - 홍보자료실 -영상 */
  .prroom-media {
    margin-top: 20px;
  }

  .media-wp {
    height: inherit;
    margin-bottom: 20px;
    padding: 20px 20px 18px 20px;
    background: #f3f9e8;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .media-area .media {
    float: inherit;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .media-area .media img {
    width: 100%;
    /* 임시 나중에 동영상 넣으면 삭제*/
  }

  .media-scroll-wrap {
    float: inherit;
    position: relative;
    width: 100%;
    height: 150px;
    padding: 0px 10px 0 10px;
    margin-left: 0px;
  }

  .media-scroll {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: 5px;
    bottom: 5px;
    right: 5px;
    left: 5px;
    text-align: left;
  }

  .media-scroll .scroller p {
    padding-right: 20px;
  }

  .media-scroll strong.mtit {
    display: block;
    padding-top: 5px;
    font-size: 17px;
  }

  .media-scroll .m-txt {
    padding-top: 20px;
  }

  .media-scroll .m-txt p {
    color: #666;
    font-size: 11px;
    letter-spacing: -.25px;
    line-height: 20px;
  }

  .media-wp .m-str {
    color: #666;
    font-size: 10px;
    letter-spacing: -.25px;
    margin-top: 10px;
  }

  .new-thumbWp.media ul.new-thumb-list li {
    padding-bottom: 6px;
  }

  .new-thumbWp.media ul.new-thumb-list li .t-media-wp {
    width: 100%;
    height: auto;
  }

  .new-thumbWp.media ul.new-thumb-list li .t-media-wp img {
    width: 100%;
    height: auto;
    vertical-align: top;
    /*임시 : 동영상 올리고나중에 삭제해야함 */
  }

  .new-thumbWp.media ul.new-thumb-list li .txt-wp {
    min-height: inherit;
    height: inherit;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 13.3px 0 6px;
  }

  /*저축은행찾기_빠른검색*/
  .store-searchWp {
    @include flex($direction: column-reverse, $gap: 20px);
    margin: 0;
    overflow: inherit;
  }

  .store-optWp {
    float: inherit;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .store-mapWp {
    float: none;
    width: 100%;
    height: initial;
    min-height: initial;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .map-area {
    width: 100%;
    height: 250px;
    min-height: initial;
    margin: 0;
    /*지도 영역 높이 임시*/
  }

  .map-area img {
    width: 100%;
    height: inherit;
  }

  /*나중에 지도 넣으면 삭제*/

  .s-inp-optWp {
    height: inherit;
    padding: 0 16px;
  }

  .store-optWp .check-optWp {
    padding: 26px 0 20px;
  }

  .store-optWp .check-optWp .chk-wrap {
    display: inline-block;
    width: 32.5%;
    margin-bottom: 13px;
  }

  .search-tabWp {
    width: 100%;
    height: inherit;
    padding: 0;
    border: 0;
    border-radius: 0;
  }

  .search-tabWp .tab-basic-buttons-item {
    padding: 9px 10px 8px;
    font-size: 13.3px;
  }

  .sr-listWp ul {
    padding: 0;
  }

  .ser-result {
    padding: 11px 16px 17px;
  }

  .ser-result p {
    font-size: 13.3px;
    letter-spacing: -.25px;
    line-height: 20px;
  }

  .ser-result p strong {
    color: #18a33e;
  }

  .sr-listWp :not(.on) + li {
    border-top: 1px solid var(--line-clr3);
  }

  .sr-listWp ul li a {
    padding: 20px 10px 20px 5px;
    background: none;
    // background: url("/img/hss_common/ico-map-m.png") no-repeat right 10px top 21px;
  }

  // .sr-listWp ul li a.active {
  //   background: url(/img/hss_common/ico-map-m02.png) no-repeat 100% 21px;
  //   background-size: 28px auto;
  // }

  .sr-listWp ul li .t-logo {
    width: 90px;
    border: 0;
  }

  .sr-listWp ul li .t-logo img {
    width: 100%;
  }

  .sr-listWp ul li .s-info {
    flex-grow: 1;
  }

  .sr-listWp ul li .s-info .tit {
    font-size: 14px;
    font-weight: $bold;
  }

  .sr-listWp ul li .s-info p.add,
  .sr-listWp ul li .s-info p.add02,
  .sr-listWp ul li .s-info p.add03 {
    margin-top: 3px;
    font-size: 12px;
    line-height: 20px;
  }

  /*저축은행찾기_지역검색*/

  .regionS .r-info {
    font-size: 13px;
    padding: 15px 10px;
  }

  .r-opt-wp {
    height: inherit;
    background: var(--bg-clr2);
  }

  .rlist-opt li button {
    height: 35px;
    padding: 7px 0;
    text-align: center;
  }

  .rlist-opt li button span {
    display: inline-block;
    font-size: 13px;
    letter-spacing: -.25px;
  }

  /* select box */
  .mobile-viewbox .search-box .select-basic {
    margin-bottom: -10px;
    border-color: var(--main-clr);
  }

  /* Q&A */
  /* .noitce-wrap li {padding: 0 10px 0 35px;;font-size:13px;line-height:21px;}
	.noitce-wrap li::before {margin-top:7px;}
	.notice-tit {margin-top:13px;font-size:12px;} */
  .noitce-wrap {
    display: none;
  }

  .notice-tit {
    display: none;
  }

  .essential-box {
    display: none;
  }

  .email-box .input-basic {
    width: 47% !important;
  }

  .email-box span {
    margin: 3px !important;
  }

  .phone-box .input-basic,
  .phone-box .select-basic {
    max-width: 31% !important;
  }

  .phone-box .select-basic {
    padding: 2px 40px 0 15px;
  }

  .phone-box .phone-mid {
    margin: 1px !important;
  }

  .form-box {
    display: table;
    position: relative;
    width: 100%;
    margin-top: 10px;
    table-layout: fixed;
  }

  .form-box select {
    width: 100%;
    height: 50px;
    padding: 2px 53px 0 15px;
    border: 1px solid var(--disable-clr);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: var(--bg-clr2) url(/img/common/bullet/bul_select.png) no-repeat right 50%;
    background-size: 38px 13px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--cap-clr);
    font-size: 17px;
  }

  .form-box input[type="text"],
  .form-box input[type="date"],
  .form-box input[type="month"],
  .form-box input[type="password"],
  .form-box input[type="tel"] {
    width: 100%;
    height: 50px;
    padding: 0 10px 0 10px;
    border: 1px solid var(--disable-clr);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: var(--body-clr);
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 10px;
    transition: border-color 0.15s ease;
  }

  .form-box input[type="text"]::-webkit-input-placeholder,
  .form-box input[type="date"]::-webkit-input-placeholder,
  .form-box input[type="month"]::-webkit-input-placeholder,
  .form-box input[type="password"]::-webkit-input-placeholder,
  .form-box input[type="tel"]::-webkit-input-placeholder {
    color: var(--cap-clr);
    font-size: 15px;
  }

  .form-box input[type="text"][disabled],
  .form-box input[type="date"][disabled],
  .form-box input[type="month"][disabled],
  .form-box input[type="password"][disabled],
  .form-box input[type="tel"][disabled] {
    background: #f5f5f5;
  }

  .form-box input[type="text"][readonly],
  .form-box input[type="date"][readonly],
  .form-box input[type="month"][readonly],
  .form-box input[type="password"][readonly],
  .form-box input[type="tel"][readonly] {
    color: var(--cap-clr);
    background: #ebebeb;
  }

  .form-box input[type="text"].readonly,
  .form-box input[type="date"].readonly,
  .form-box input[type="month"].readonly,
  .form-box input[type="password"].readonly,
  .form-box input[type="tel"].readonly {
    background: var(--bg-clr2) !important;
  }

  .form-box input[type="text"]:focus,
  .form-box input[type="date"]:focus,
  .form-box input[type="month"]:focus,
  .form-box input[type="password"]:focus,
  .form-box input[type="tel"]:focus {
    border-color: #215a8f;
  }

  .form-box .clearinput {
    padding-right: 40px !important;
  }

  .form-box .clearbtn {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 40px;
    height: 48px;
    border: 0;
    background-color: var(--bg-clr2);
    text-indent: -10000px;
    overflow: hidden;
  }

  .form-box .clearbtn::after {
    content: '';
    position: absolute;
    top: 17px;
    right: 13px;
    width: 13px;
    height: 13px;
    background: url(/img/has_common/bul_delete.png) no-repeat 0 0;
    background-size: auto 13px;
  }

  .form-box .division-box {
    display: table-cell;
    position: relative;
    vertical-align: top;
  }

  .form-box .division-box.calendar input[type="text"].readonly {
    background: url(/img/common/icon/icon_calendar.png) no-repeat right 12px center / 23px !important;
  }

  .form-box .letter-box {
    display: table-cell;
    width: 26px;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
  }

  /* 변경가능성때문에 별도 클래스분리 */
  .form-box .letter-box img {
    width: 26px;
  }

  .form-box .search-box {
    display: table-cell;
    width: 65px;
    padding-left: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
  }

  .form-box .search-box button {
    margin-bottom: 0;
  }

  .form-box .search-box02 {
    position: relative;
    display: table-cell;
    vertical-align: top;
  }

  .form-box .search-box02 input {
    padding-right: 70px !important;
  }

  .form-box .search-box02 .btn-search {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 18px;
    height: 20px;
    border: 0;
    background: url(/img/common/button/btn_search.png) no-repeat 0 0;
    background-size: 18px 20px;
    font-size: 0;
    line-height: 0;
  }

  .form-box .search-box02 .clearbtn {
    right: 35px;
  }

  .form-box .select-box {
    display: table-cell;
    width: 113px;
    padding-right: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
  }

  .form-box .license-box {
    display: table-cell;
    position: relative;
    width: calc(29%);
    padding-right: 5px;
    vertical-align: top;
  }

  .form-box .license-box .clearbtn {
    right: 6px;
  }

  .form-box .price-txt,
  .form-box .count-txt {
    margin-top: 10px;
    font-size: 17px;
    text-align: right;
  }

  .form-box .tel-box {
    display: table-cell;
    width: 109px;
    padding-right: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
  }

  .form-box .tel-box02 {
    display: table-cell;
    width: 95px;
    padding-right: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
  }

  .form-box .btn-box02 {
    display: table-cell;
    width: 115px;
    padding-left: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
  }

  .form-box .btn-box03 {
    display: table-cell;
    width: 110px;
    padding-left: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
  }

  .form-box .tax-box {
    display: table-cell;
    width: 120px;
    padding-right: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
  }

  .form-box.addr {
    box-sizing: border-box;
  }

  .form-box.addr .search-box {
    width: 110px;
  }

  .form-box .select,
  .form-box input[type="text"][readonly].select {
    display: block;
    width: 100%;
    height: 50px;
    padding: 15px 30px 13px 15px;
    border: 1px solid var(--disable-clr);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: var(--bg-clr2) url(/img/common/bullet/bul_select.png) no-repeat right 50%;
    background-size: 30px 10px;
    color: var(--cap-clr);
    font-size: 15px;
  }

  .form-box + .form-box {
    margin-top: 15px;
  }

  .form-box + .common-stit {
    margin-top: 20px;
  }

  /* [공통] 타이틀 */
  .sub-title01 {
    font-size: 17px;
    text-align: center;
  }

  .common-stit {
    position: relative;
    margin-top: 30px;
    font-size: 17px;
  }

  /* 바디 서브타이틀 */
  .common-stit .sub-sort {
    display: block;
    padding-bottom: 3px;
    color: #215a8f;
    font-size: 15px;
    font-weight: $medium;
  }

  .common-stit .sub-sort.none {
    margin-bottom: -3px;
    padding-bottom: 0;
    font-weight: $regular;
  }

  .common-stit .tip-text {
    position: absolute;
    top: -3px;
    right: 0;
  }

  .common-titmenu {
    margin-top: 30px;
    color: var(--body-clr);
    font-size: 15px;
    letter-spacing: 0;
  }

  /* 타이틀 메뉴 */
  .common-titmenu .number-txt {
    display: inline-block;
    padding: 0 2px 0 3px;
  }

  .common-stit + .common-stit {
    margin-top: 15px;
  }

  .common-stit + .common-noresult {
    margin-top: 7px;
  }

  .common-stit + .search-info {
    margin-top: 15px;
  }

  .common-stit + .common-btngroup {
    margin-top: 12px;
  }

  .common-stit + .common-btn {
    margin-top: 15px;
  }

  /* 더보기 버튼 */

  .btn-area {
    margin: 30px 0;
  }

  /* small accodion */
  .small-accordion-list.type01 {
    margin-top: 20px;
  }

  .terms-wrap {
    padding: 0
  }


  /* 검색결과 없을때 */
  .common-list-noresult {
    padding: 80px 0;
    border-top: 1px solid var(--line-clr3);
    border-bottom: 1px solid var(--line-clr3);
    text-align: center;
    font-size: 20px;
    font-weight: $regular;
  }

  /* 셀렉트 박스 글씨 크기 */
  .common-tblinfobox.style01 .select-basic {
    font-size: 14px;
  }

  .common-tblinfobox.style01 .totalbox-wrap {
    // padding-bottom: 10px;
    font-size: 14px;
  }

  /* otp */
  .bg-otpimg {
    margin-top: 20px;
    padding: 24px 16px;
    background-image: none;
  }

  .bg-otpimg .warn-title {
    width: 100%;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .bg-otpimg .warn-txt {
    width: 100%;
    font-size: 13px;
  }

  .bg-otpimg + .info-table-area {
    padding-top: 30px;
  }

  .icon-lobat {
    width: 100%;
    height: auto;
    padding-top: 58.92%;
    background-size: 100%;
  }

  .icon-otpex {
    width: 100%;
    height: auto;
    padding-top: 31.11%;
    background-size: auto 100%;
  }

  .icon-otpbg {
    background-size: 100%;
  }

  .icon-list-box > li {
    float: none;
    width: 100%;
    min-height: auto;
    padding: 30px 25px;
    border-top: 1px solid #efefef;
    border-right: 0;
  }

  .icon-list-box > li:first-child {
    border-top: 0;
  }

  .common-list02 li .snsbox .tooltip-layerpopup-close {
    right: 19px;
  }

  .common-list02 li .snsbox .tooltip-layerpopup::after {
    right: 31px;
  }

  // .notice-subtext {
  //   padding-top: 20px;
  // }

  .notice-subtext.topnone {
    padding-top: 20px !important;
  }

  // .content .info-table-area:first-child {
  //   padding-top: 20px;
  // }

  .common-text01.dot {
    margin-top: 20px;
  }


}

b {
  font-weight: $bold;
  vertical-align: baseline;
}

/*=====================================================
	guide_temp
=======================================================*/

/*==========================
	 가로형 테이블 추가
===========================*/
.table-type005 {
  width: 100%;
}

.table-type005 tr:last-child th,
.table-type005 tr:last-child td {
  border-bottom-width: 0px;
}

/*모바일*/
@media (max-width: $wrapSize) {
  html.responsive {
    .table-type03-w {
      display: none;
    }

    .table-type03-m {
      display: table;
    }
  }

  .table-type03-m,
  .table-type04 {
    width: 100%;
  }

  .table-type03-m tr:first-child {
    border-top: 1px solid var(--body-clr);
  }

  .table-type04 th,
  .table-type04 td {
    // padding: 22px 4.65%;
    font-size: 12px;
    border-bottom: 1px solid var(--disable-clr);
  }

  .table-type03-m th,
  .table-type04 th {
    background: #f7f7f7;
    // width: 39.35%;
    font-weight: $bold;
    border-right: 1px solid var(--disable-clr);
  }

  // .table-type03-m td,
  // .table-type04 td {
  //   width: 60.65%;
  // }

  .table-type005 {
    width: 100%;
  }

  .table-type005 tr:last-child th,
  .table-type005 tr:last-child td {
    border-bottom-width: 0px;
  }
}



/*=====================================
POPUP
DATE : 2019-02-11
WRITER : HJY
=======================================*/
/*POPUP 01********************************/
// .popup {
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100vh;
//   z-index: 100;
// }

// .dim-contents {
//   position: fixed;
//   width: 850px;
//   height: auto;
//   max-height: 820px;
//   margin-top: -32.5px !important;
//   top: 50% !important;
//   left: 50%;
//   -webkit-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
//   background: var(--bg-clr2);
//   z-index: 10000;
// }

.dim-contents .info-box {
  border-top: 1px dashed #d4d4d4;
}

.dim-contents .info-box {
  padding-top: 20px;
}

.dim-contents .info-box .bank-sign {
  vertical-align: middle;
  margin-left: 10px;
}

.dim-contents .content-foot {
  font-size: 0;
}

.dim-contents .footer-info {
  position: relative;
  display: inline-block;
  padding: 0 12px 0 10px;
  margin-top: 14px;
}

.dim-contents .footer-info:last-child::after {
  display: none;
}

.dim-contents .footer-info dt {
  margin-right: 8px;
}

/* 유경추가 - 테이블 마진 */
.popup .info-table-area.b0 {
  padding-bottom: 0;
}

.layer-wrap.scroll-type01 .dim-contents .btn-close-l {
  font-size: 0
}

.division-box {
  position: relative;
}


/* MOBILE ONLY */
@media (max-width: 768px) {

  .dim-contents .notice-list li::before {
    margin-top: 5px;
  }

  .dim-contents .content-wrap,
  .dim-contents .content-box-wrap,
  .dim-contents .content-box {
    padding-left: 2.78%;
    padding-right: 2.78%;
  }

  .dim-contents .content-table {
    font-size: 13px;
  }

  .dim-contents .content-table .th {
    width: 80px;
  }

  .dim-contents .content-table .td {
    width: calc(100% - 80px);
    width: -webkit-calc(100% - 80px);
    width: -moz-calc(100% - 80px);
    width: -o-calc(100% - 80px);
    width: -ms-calc(100% - 80px);
  }

  .layer-wrap.scroll-type01 .layer-container {
    min-width: 100%;
    padding: 0 20px;
    background: none;
  }

  .layer-wrap.scroll-type01 .dim-contents {
    width: auto;
    max-height: inherit;
  }

  .layer-wrap.scroll-type01 .popup-body02 {
    max-height: 800px;
    padding: 30px;
    overflow: auto;
  }
}

/*POPUP 02
  POPUP MD********************************/

.dim-contents .popup-body01 .notice-list {
  padding-bottom: 0;
  background: transparent;
}

.dim-contents .popup-body01 .notice-list li:last-child {
  padding-bottom: 0;
}

// .dim-contents .popup-foot01 {
//   padding: 30px 30px 40px;
//   text-align: center;
//   border-top: 1px solid var(--disable-clr);
// }

// .dim-contents .popup-foot01 .right {
//   margin-left: 8px;
// }

/* MOBILE ONLY */
@media (max-width: 768px) {
  // .dim-contents.md {
  //   /* 40px은 팝업창 좌우 여백만큼 */
  //   width: -webkit-calc(100% - 40px);
  //   width: -moz-calc(100% - 40px);
  //   width: -o-calc(100% - 40px);
  //   width: -ms-calc(100% - 40px);
  //   width: calc(100% - 40px);
  // }

  // .dim-contents .popup-foot01 .btn-small.left {
  //   margin-right: 0px;
  //   margin-bottom: 10px;
  // }

  .dim-contents .popup-body01 .notice-list li::before {
    margin-top: 4px;
  }
}

/*POPUP 03********************************/

.dim-contents .popup-body01 .body-msg.type01 {
  padding-top: 90px;
  background: url(/img/content/sbt/img_danger_msg.png) no-repeat top center / 60px;
}

.dim-contents .popup-body01 .body-msg span {
  font-weight: $bold;
}

/*POPUP 05*/
// .dim-contents .popup-body02 {
//   padding: 30px;
// }

/* MOBILE ONLY */
@media (max-width: 768px) {
  .dim-contents .popup-body01 .body-msg {
    font-size: 16px;
  }
}

/* 공과금 팝업 */
.dim-contents .document-area {
  border: 0;
  margin-bottom: 0;
  padding: 0;
}

/* 체크카드 팝업 */
// .dim-contents.card-layer {
//   position: fixed;
//   z-index: 1000;
//   width: 920px;
//   max-height: 820px;
//   margin-top: -32.5px !important;
//   top: 50% !important;
//   left: 50%;
//   -webkit-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
//   background: var(--bg-clr2);
// }

.dim-contents.card-layer .card-wrap {
  position: relative;
}

.dim-contents.card-layer .scroll-box {
  max-height: 690px;
  overflow-y: auto;
}

// .dim-contents.card-layer .popup-body02 {
//   max-height: inherit;
//   text-align: left;
//   padding: 30px 30px 60px;
//   min-height: 150px;
//   overflow-y: auto;
//   background: var(--bg-clr2);
// }

.dim-contents.card-layer .body-box {
  margin-top: 35px;
}

.dim-contents.card-layer .body-box:first-child {
  margin-top: 0;
}

.dim-contents.card-layer .tit-des {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: $medium;
}

.dim-contents.card-layer .sub-des {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: $medium;
  line-height: 1.3;
}

.dim-contents.card-layer .notice-list .font-088 {
  padding-top: 20px;
  line-height: 23px;
}

.dim-contents.card-layer .notice-list .notice-i-list {
  margin-top: 0;
}

.dim-contents.card-layer .notice-list li .notice-i-list {
  line-height: 23px;
}

.dim-contents.card-layer .notice-i-list {
  margin-top: 20px;
  color: var(--body-clr);
}

.dim-contents.card-layer .nostyle-list {
  max-width: $wrapSize;
  margin: 0 auto;
}

.dim-contents.card-layer .nostyle-list li {
  min-height: inherit;
  margin-top: 3px;
  padding: 0 0 0 20px;
  font-size: 14px;
  line-height: 22px;
  border-bottom: 0;
  background: none;
}

.dim-contents.card-layer .nostyle-list li:first-child {
  margin-top: 0;
}

.dim-contents.card-layer .notice-list {
  padding-bottom: 0;
  background: transparent;
}

.dim-contents.card-layer .notice-list li {
  font-size: 14px;
  text-align: left;
}

.dim-contents.card-layer .notice-box {
  margin-top: 30px;
  padding: 25px 20px;
  background: #f7f7f7;
}

.dim-contents.card-layer .notice-box .sub-des {
  margin-top: 0;
}

.dim-contents.card-layer .notice-box .notice-list {
  padding-top: 0;
}

.dim-contents.card-layer .notice-box .notice-list li {
  margin-top: 0;
  padding-left: 10px;
  font-size: 14px;
}

.dim-contents.card-layer .notice-box .notice-list li a {
  display: inline;
  font-weight: $medium;
}

.dim-contents.card-layer .notice-box .nostyle-list li {
  font-size: 12px;
}

.dim-contents.card-layer .notice-box .notice-i-list {
  margin-top: 0;
  font-size: 12px;
}

.dim-contents.card-layer .notice-box .notice-i-list::before {
  margin-left: -10px;
}

.dim-contents.card-layer .step-ux {
  margin-top: 30px;
  padding: 30px 60px;
  background: #f7f7f7;
}

.dim-contents.card-layer .step-ux ul {
  display: table;
  width: 100%;
}

.dim-contents.card-layer .step-ux ul li {
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 25%;
  text-align: center;
}

.dim-contents.card-layer .step-ux ul li::before {
  content: '';
  display: block;
  width: 16px;
  height: 29px;
  position: absolute;
  top: 40px;
  right: -8px;
  background: url(/img/mypage/card_check_arrow.png) no-repeat center;
}

.dim-contents.card-layer .step-ux ul li:last-child::before {
  display: none;
}

.dim-contents.card-layer .step-ux ul li p {
  padding-top: 128px;
  font-size: 14px;
  line-height: 25px;
  background-repeat: no-repeat;
  background-position: center top;
}

.dim-contents.card-layer .step-ux ul li p.step-t01 {
  background-image: url(/img/mypage/card_check_step01.png);
  padding-top: 120px;
}

.dim-contents.card-layer .step-ux ul li p.step-t02 {
  background-image: url(/img/mypage/card_check_step02.png);
}

.dim-contents.card-layer .step-ux ul li p.step-t03 {
  background-image: url(/img/mypage/card_check_step03.png);
}

.dim-contents.card-layer .step-ux ul li p.step-t04 {
  background-image: url(/img/mypage/card_check_step04.png);
}

.dim-contents.card-layer .table-wrap01 + .mt20 {
  margin-top: 20px;
}

.dim-contents.card-layer .table-type04 th {
  width: 160px;
}

.dim-contents.card-layer .table-type04 td {
  padding: 14px 10px 13px 10px;
}

.dim-contents.card-layer td .notice-list {
  margin-left: 10px;
}

.dim-contents .popup-foot01.foot-style01 {
  padding: 30px 0 40px;
}

.dim-contents .table-title + .table-wrap {
  margin-top: 15px;
}

.dim-contents .notice-list + .popup-top-number {
  margin-top: 20px;
}

.dim-contents.paybooc-layer .popup-body02 {
  max-height: inherit;
}

.dim-contents.paybooc-layer .popup-foot01 {
  padding: 40px 30px;
}

@media (max-height: 768px) {
  .dim-contents.card-layer {
    max-height: 560px;
  }

  .dim-contents.paybooc-layer .popup-body02 {
    max-height: 420px;
  }
}

.popup-close {
  height: 50px;
  padding: 15px 30px;
  background: #666;
}

i.chk-w {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url(/img/common/checkbox-w.png) no-repeat center / contain;
  display: table-cell;
}

.chk-basic.white:checked ~ i {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url(/img/common/check_card.png) no-repeat center / contain;
}

.chk-label.white {
  font-size: 14px;
  color: var(--bg-clr2);
  display: table-cell;
  padding-left: 10px;
  line-height: 1.2;
  text-align: left;
  vertical-align: middle;
}

.popup-close-btn {
  float: right;
  padding: 2px 20px 0 0;
  color: var(--bg-clr2);
  background: url(/img/common/icon_popup_close.png) no-repeat right center;
  font-size: 16px;
}



.btn-sm-wh.resize {
  max-width: none;
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.btn-sm-wh.small {
  max-width: 50px;
}

.btn-sm-wh.ico_down {
  padding-right: 50px;
  background: url(/img/hss_common/ico_down.png) no-repeat calc(100% - 15px) center;
}

.sec-box .code-img {
  width: 480px;
  border-radius: 10px;
  overflow: hidden;
}

.sec-box .code-img {
  width: 100%;
}

.wide-table td {
  padding: 20px 10px;
}

/*=====================================================
	박유경
	guide_item
=======================================================*/
/*mso-icon********************************/

.btn-mso-s {
  width: 16px;
  height: 16px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-mso {
  width: 54px;
  height: 54px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/*btn-close********************************/
.btn-close {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-close-l {
  width: 23px;
  height: 23px;
  background-image: url("/img/hss_common/btn_close_l.png");
}

.btn-close-s {
  width: 10px;
  height: 10px;
  background-image: url("/img/hss_common/btn_close_s.png");
}

/*notice i 모바일*/
@media (max-width: $wrapSize) {

  /*ovvi 조회 팝업 2*/
  .dim-contents .popup-foot01 .right {
    margin-left: 0;
  }
}

/*datepicker******************************/
.ui-widget-content {
  background: #f7f7f7;
  color: var(--body-clr);
}

.ui-widget.ui-widget-content {
  border: 1px solid #999;
}

.ui-datepicker {
  width: 307px !important;
  padding: 10px 24px;
  /**/
}

.ui-widget-header {
  background: none;
  border: 0;
}

.ui-datepicker .ui-datepicker-title {
  font-weight: $bold;
  width: 72%;
  margin: 0 auto;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 40%;
}

.ui-datepicker table {
  font-size: 14px;
}

.ui-datepicker select.ui-datepicker-year {
  margin-right: 20px;
  margin-left: 10px;
}

/*버튼*/
.ui-datepicker-next.ui-corner-all,
.ui-datepicker-prev.ui-corner-all {
  display: none;
}


/*요일*/
.ui-datepicker th span {
  color: var(--cap-clr) !important;
}

/*일*/
.ui-state-default,
.ui-widget-content .ui-state-default {
  background: none;
  border: 0;
}

/*오늘*/
.ui-widget-content .ui-state-highlight {
  border: 1px solid var(--neg-clr);
}

/*선택일*/
.ui-state-active,
.ui-widget-content .ui-state-active {
  color: var(--bg-clr2);
  position: relative;
}

.ui-state-active::after {
  content: '';
  width: 30px;
  height: 30px;
  background: var(--neg-clr);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: -5;
  border-radius: 50%;
}

.ui-datepicker td span,
.ui-datepicker td a {
  padding: 10px 5px;
  text-align: center;
  border-radius: 50%;
}

/*년*/

/*월*/
.ui-state-default,
.ui-widget-content .ui-state-default {
  padding: 10px 0px !important;
  cursor: pointer !important;
}

.mtz-monthpicker .ui-state-active {
  background: transparent;
  color: var(--bg-clr2);
  position: relative;
  z-index: 10;
}

.mtz-monthpicker .ui-state-active::after {
  content: '';
  width: 30px;
  height: 30px;
  background: var(--neg-clr);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: -5;
  border-radius: 50%;
}

/* tool 추가*/
.tool-number {
  text-decoration: underline;
}


/*=====================================================
	guide_item
	writer : PEY;
=======================================================*/
.h-title {
  font-size: 24px;
  margin: 20px 0;
  font-weight: $bold;
}

.h-sub {
  font-size: 14px;
  font-weight: $bold;
  margin: 10px 0;
  width: 100%;
}

/*---------input-style(은영)--------*/

.input-basic.xxxs,
.select-basic.xxxs {
  max-width: 75px;
}

.input-basic.xxss,
.select-basic.xxss {
  max-width: 90px;
}

.input-basic.xxs,
.select-basic.xxs {
  max-width: 125px;
}

.input-basic.xs,
.select-basic.xs {
  max-width: 150px;
}

.input-basic.sm,
.select-basic.sm {
  max-width: 200px;
}

.input-basic.md,
.select-basic.md {
  max-width: 300px;
}

.input-basic.w167,
.select-basic.w167 {
  max-width: 167px !important;
}

.input-basic.w270,
.select-basic.w270 {
  max-width: 270px !important;
}

.input-basic.w285,
.select-basic.w285 {
  max-width: 285px;
}

.input-basic.w340,
.select-basic.w340 {
  min-width: 340px;
}

.input-basic.w410,
.select-basic.w410 {
  max-width: 410px;
}

.input-basic.w195,
.select-basic.w195 {
  max-width: 195px;
}

.input-basic.w290,
.select-basic.w290 {
  max-width: 290px !important;
}

.input-basic.w525,
.select-basic.w525 {
  max-width: 525px !important;
}

.input-basic.w115,
.select-basic.w115 {
  max-width: 115px !important;
}

.input-basic.w930,
.select-basic.w930 {
  max-width: 930px !important;
}

.input-basic.w380,
.select-basic.w380 {
  max-width: 380px;
}

.input-basic.w400,
.select-basic.w400 {
  max-width: 400px !important;
}

.input-basic.w-jumin {
  max-width: 270px;
}

.input-basic.w-bnum {
  max-width: 175px;
}

/* // modified by HJY - 190220 */

.input-basic::placeholder {
  color: var(--body-clr);
}

.input-active {
  border-color: var(--body-clr);
}

/*.vitualKeyCheck { float: left; }*/

.input-www.input-basic {
  padding-right: 30px;
}

.input-basic::placeholder {
  color: var(--cap-clr);
}

.input-basic:-ms-input-placeholder {
  color: var(--cap-clr);
}

.input-basic::-ms-input-placeholder {
  color: var(--cap-clr);
}

.input-basic::-moz-placeholder {
  color: var(--cap-clr);
}

.select-active {
  color: var(--body-clr);
  border-color: var(--body-clr);
}

.date-box2::after {
  content: '';
  display: block;
  clear: both;
}

.date-box2 {
  width: 100%;
  max-width: 400px;
}

.date-box2 * {
  float: left;
  margin-right: 0 !important;
}

.text-basic.readonly,
.text-basic.disable,
.text-basic.error {
  resize: none;
}

/*---------button-style(은영)--------*/

@media (max-width: $wrapSize) {

  .input-basic,
  .select-basic {
    max-width: none;
    font-size: 13px;
    // padding: 7px 13px;
  }

  .text-basic {
    font-size: 14px;
    max-width: none;
    padding: 10px 3.7%;
    resize: none;
  }

  .radio-basic {
    width: 60px;
    font-size: 13px;
    padding: 8px 0;
    background-color: #eeeff1;
    border: 0;
    color: #363636;
  }

  .btn-radio:checked + .radio-basic {
    background-color: var(--bg-clr2);
  }

  .date-basic {
    background: var(--bg-clr2) url(/img/common/icon_date.png) no-repeat right 3.7% center / 19px;
  }

  .date-basic2 {
    position: relative;
    width: calc(50% - 12px);
    background: var(--bg-clr2) url(/img/common/icon_date.png) no-repeat right 8.7% center / 19px;
  }

  .datemid {
    display: inline-block;
    width: 24px;
    line-height: 40px;
    font-size: 14px;
  }

  .btn-show-list {
    display: inline-block;
    border: 1px solid var(--body-clr);
    padding: 8px 8.52%;
    color: var(--body-clr);
    font-size: 15px;
    width: auto;
    height: auto;
  }

  .btn-show-list.option {
    width: 100%;
    color: #363636;
    border: 1px solid #bbb;
  }

  .btn-show-list.option span {
    display: inline-block;
    vertical-align: middle;
  }

  .btn-show-list.option .icon-list {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    background: url(/img/common/icon_list.png) no-repeat center / contain;
  }

  .btn-show-list::before {
    display: none
  }

  .btn-show-list::after {
    display: none;
  }

  .chk-label {
    margin-left: 0;
    color: #666666;
    font-size: 12px;
    padding-left: 5px;
  }

  .trn-buttonwrap button {
    max-width: 60px;
    font-size: 13px;
    padding: 8px 0;
    background: #eeeff1;
    border: 0;
  }

  .tooltips {
    left: 68px;
  }

  .tooltips li {
    margin-bottom: 4px;
    line-height: 20px;
  }

  .tooltips li a {
    /*max-width: 120px;*/
    word-break: break-all;
    text-decoration: underline;
    text-underline-position: under;
    font-size: 10px;
  }

  .tooltips span {
    display: none
  }

  /* 190312 현정 h3 타이틀 설명폰트 크기추가 */
  .table-exp.fr {
    font-size: 12px;
    text-align: left;
    float: left !important;
  }

  /* 190527 추가 */
  .date-box2 .input-area {
    max-width: 83%;
  }

  .date-box2 .datemid + .input-area {
    display: block;
    // margin-top: 7px;
  }

  .icon-print,
  .icon-down,
  .icon-add {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    background-size: auto 25px;
  }
}

/*---------radio-style(은영)--------*/

.btn-radio {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}


.btn-radios {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.btn-radios:focus + label,
.btn-radio:focus + label {
  outline: -webkit-focus-ring-color auto 5px;
  outline: 1px dashed #000;
}

.btn-radios:focus + label,
.btn-radio:focus + label {
  outline: 1px dashed #000;
}

input[type="radio"].disable + label {
  color: var(--cap-clr);
  cursor: default;
}

input[type="radio"].disable + label::after {
  opacity: 0.5;
}

.btn-show-list::before {
  content: '';
  width: 24px;
  height: 2px;
  background: var(--body-clr);
  position: absolute;
  top: 46%;
  right: 20px;
  display: block;
}

.btn-show-list::after {
  content: '';
  width: 10px;
  height: 10px;
  border-bottom: 2px solid var(--body-clr);
  border-left: 2px solid var(--body-clr);
  position: absolute;
  top: 36%;
  right: 20px;
  transform: rotate(225deg);
  display: block;
}

.btn-show-list.option span {
  display: inline-block;
  vertical-align: middle;
}

.btn-show-list.option .icon-list {
  display: none;
}

.chk-wrap {
  display: inline-block;
  vertical-align: middle;
}

.label-wrap {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.chk-basic {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  /*20220310 접근성 개선 임시해제*/
  z-index: 5;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.chk-basic.type01 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  cursor: pointer;
  top: initial;
  left: initial;
  opacity: 1;
  z-index: 1;
  background: initial;
}

.chk-out {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}

.chk-out.type01 {
  font-size: 0;
}

.chk-basic:focus + .chk-out {
  outline: -webkit-focus-ring-color auto 5px;
}

i.icon-chk {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url(/img/hss_common/icon_chk_off.png) no-repeat center / contain;
  display: table-cell;
}

input[type="checkbox"].disable ~ i {
  opacity: 0.5;
}

input[type="checkbox"].disable ~ .chk-label {
  color: var(--cap-clr);
}

/*mso-icon********************************/

.btn-mso-s {
  width: 16px;
  height: 16px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-mso {
  width: 54px;
  height: 54px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/*btn-close********************************/
.btn-close {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-close-l {
  width: 23px;
  height: 23px;
  background-image: url("/img/hss_common/btn_close_l.png");
}

.btn-close-s {
  width: 10px;
  height: 10px;
  background-image: url("/img/hss_common/btn_close_s.png");
}

/*
.tool-under{ content: ''; position: absolute; bottom: -2px; left: 0; width:100%; height: 1px; border-bottom:1px solid var(--body-clr); }
.under-active{ border-bottom: 1.5px solid var(--body-clr); }
.tool-active{ font-weight: $bold; }*/

.trn-notice-i-list02 {
  line-height: 40px;
  white-space: nowrap;
}

.wide-table th {
  padding: 22px 10px;
}

.wide-table td {
  padding: 22px 10px;
}

.trn-buttonwrap {
  width: 100%;
  clear: both;
}

/*-------------미디어쿼리-------------*/

@media (max-width: $wrapSize) {
  .btn-sm-wh {
    max-width: none;
  }

  .btn-radio:checked + .radio-basic {
    background-color: var(--bg-clr2);
  }

  .btn-reset {
    display: none;
  }

  .btn-show-list.option {
    width: 100%;
    color: #363636;
    border: 1px solid #bbb;
  }

  .btn-show-list.option span {
    display: inline-block;
    vertical-align: middle;
  }

  .btn-show-list::before {
    display: none
  }

  .btn-show-list::after {
    display: none;
  }

  .tooltips {
    left: 68px;
  }

  .tooltips span {
    display: none
  }
}

/*===============================
	시영
================================*/
.btn-mso-txt {
  min-height: 20px;
}

/*===============================
	유경
	fullcalendar
=================================*/

/* wrap */
.calendar-content {
  border-top: 1px solid #000;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

.fc-header-toolbar .fc-center {
  vertical-align: bottom;
}

/*전체 텍스트 left*/
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: none;
  font-weight: bold;
}

.fc td,
.fc th {
  text-align: left;
  border: 0;
}

/*전체 border*/
th.fc-day-header,
td.fc-day {
  position: relative;
}

th.fc-day-header::after,
td.fc-day::after {
  content: '';
  width: 150px;
  height: 1px;
  background: var(--disable-clr);
  position: absolute;
  left: 0;
  bottom: 0;
}

/* top (년, 월) */
.fc-toolbar.fc-header-toolbar {
  background: #f7f7f7;
  padding: 15px 20px;
  position: relative;
}

/*<>버튼*/
.fc-state-default {
  border: 0;
  background-color: transparent;
  background-image: url("/img/hss_common/icon_select_333.png");
  background-position: center;
  background-repeat: no-repeat;
  border-color: none;
  box-shadow: none;
}

.fc-state-default span.fc-icon {
  display: none;
}

button.fc-prev-button.fc-button.fc-state-default {
  width: 30px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: 30%;
  transform: rotate(90deg) translateY(-50%);
}

button.fc-next-button.fc-button.fc-state-default {
  width: 30px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 30%;
  transform: rotate(-90deg) translateY(-50%);
}

/*요일*/
.fc-day-header.fc-widget-header {
  padding: 16px 7px 8px 0;
}

.fc-sun span {
  color: var(--neg-clr);
}

td.fc-day-top {
  padding: 10px 2px 0 0;
}

.fc-content {
  margin-bottom: 10px;
}

.fc-title.money {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-title > span {
  color: inherit;
}

.fc-event,
.fc-event-dot {
  background: transparent;
  border-color: transparent;
}

.fc-unthemed td.fc-today {
  background: none;
}

.fc-day.fc-widget-content.fc-thu.fc-today::before {
  content: '';
  display: block;
  width: 150px;
  height: 100%;
  background-color: #f7f7f7;
  position: absolute;
  left: 0;
  top: 0;
}

/*===============================
	유경
	guide_temp
	약관동의샘플
=================================*/

.radio-agree-wrap label {
  margin-right: 40px;
}

.radio-agree-wrap label:last-child {
  margin-right: 0;
}

@media (max-width: $wrapSize) {
  .radio-agree-wrap label {
    margin-right: 20px;
    font-weight: $bold;
  }

  .radio-agree-wrap {
    padding: 15px;
  }

  .radio-agree-wrap label:last-child {
    margin-right: 0;
  }

  .btn-slide {
    width: 50px;
    background-size: 17px;
  }
}

/*input max-170*/
.input-basic.max170 {
  max-width: 170px;
}


/* 2채널 인증 관련 스타일 추가 */
.list-style {
  color: #666;
}

.list-style li::before {
  content: '';
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #999;
}

.btn-sm-wh.btn-qrcode {
  margin-top: 10px;
  font-weight: $regular;
}

.tooltips.qrcode {
  display: none;
  bottom: inherit;
  top: 60px;
  left: -10px;
  transform: none;
  padding: 30px 30px 20px;
}

.tooltips.qrcode::after {
  bottom: inherit;
  top: -5px;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 0;
  border-right: 0;
}

.tooltips.qrcode img {
  width: 90px;
  height: auto;
}

.btn-sm-wh.btn-qrcode:hover ~ .tooltips.qrcode,
.btn-sm-wh.btn-qrcode:active ~ .tooltips.qrcode,
.btn-sm-wh.btn-qrcode:focus ~ .tooltips.qrcode {
  display: block;
}

.sub-box-wrap::after,
.sub-box::after {
  content: '';
  display: block;
  clear: both;
}

.sub-box-wrap {
  padding: 20px;
}

.sub-box {
  background-color: #f7f7f7;
  padding: 20px;
}

.sub-box-wrap.option-td {
  display: none;
  width: 680px;
  clear: both;
  padding: 15px 0 0;
}

.sub-box-wrap.option-td .sub-box {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.sub-box-num,
.sub-box-btn {
  display: table-cell;
  vertical-align: middle;
}

.sub-box-num {
  width: 410px;
  padding: 10px 0 10px 20px;
}

.sub-box-btn {
  width: 270px;
}

.doc-box02 {
  width: 50%;
  float: left;
  font-size: 0;
  text-align: center;
}

.doc-box02:first-child {
  border-right: 1px solid var(--disable-clr);
}

.doc-box02 button {
  vertical-align: middle;
  margin-left: 20px;
}

/*약관동의 추가하기*/
.slide-box-area.only-top .slide-box-top {
  border-bottom: 1px solid var(--disable-clr);
  border-top: 0 !important;
}

/*약관동의 네모체크박스*/
.check-agree-wrap {
  padding: 21px;
}

.check-agree-wrap .label-wrap:nth-of-type(5) {
  margin-right: 0;
}

.check-agree-wrap .label-wrap span.chk-label {
  font-weight: $bold;
  color: #666;
}

.check-agree-wrap .check-span {
  font-weight: $bold;
  color: #666;
  margin: 3px;
}

/*input max-185*/
.input-basic.max185 {
  max-width: 185px;
}

/*체크박스 여백*/
.check-box-wrap .label-wrap {
  margin-right: 40px;
}

/*190227 박은영 추가*/

.mr11 {
  margin-right: 12px;
}

.hyphen {
  float: left;
  margin-right: 10px;
  line-height: 40px;
}

/*190228 박은영 추가*/

.input-basic.xxxs,
.select-basic.xxxs {
  max-width: 70px;
}

.mln30 {
  margin-left: -30px
}

.info-subtxt {
  padding-top: 20px;
  line-height: 20px;
}

.info-address {
  text-decoration: underline;
  display: inline-block;
}

.table-clone .table-top {
  width: 250px;
  padding: 15px 20px;
  border-right: 1px solid var(--disable-clr);
}

.chk-out {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mymenu-box-top {
  width: 100%;
  background: var(--bg-clr2);
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);
}

.mymenu-box-wrap {
  width: 100%;
  padding-left: 20px;
  border-bottom: 1px solid #f7f7f7;
}

.list-left-wrap {
  padding: 22px 0;
}

.deg180 {
  transform: rotate(180deg);
}

.list-left-wrap li {
  float: left;
}

.list-left-wrap li:last-child {
  font-weight: bold;
}

.list-left-wrap li::after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-top: 2px solid var(--disable-clr);
  border-right: 2px solid var(--disable-clr);
  float: left;
  transform: rotate(45deg);
  margin: 3px 10px;
}

.list-left-wrap li:first-child::after {
  display: none;
}

.check-box-wrap02 .label-wrap {
  margin-right: 18px;
}

/* 페이징 pagination */
.pagination {
  text-align: center;
}

.pagination > li {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: 0 2px;
}

.pagination > li:last-child {
  margin-right: 0;
}

.pagination > li a {
  display: table;
  width: 100%;
  height: 100%;
}

.pagination .active-page .page {
  background: #959595;
  color: var(--bg-clr2);
  font-weight: $bold;
}

.pagination .prev a {
  background: url(/img/hss_common/page_left.png) no-repeat center;
}

.pagination .next a {
  background: url(/img/hss_common/page_right.png) no-repeat center;
}


/* 오류 페이지 / error_page02.html */
html.error {
  font-size: 20px;
}

html.error #_FSBcontainer {
  min-height: calc(100vh - 225px);
  min-height: -webkit-calc(100vh - 225px);
  min-height: -moz-calc(100vh - 225px);
  min-height: -o-calc(100vh - 225px);
  background: var(--bg-clr2);
}

.error-wrap.type02 {
  width: 100%;
  max-width: 700px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  text-align: center;
}

@media (max-width: $wrapSize) {
  html.error {
    font-size: 16px;
  }

  .error-wrap.type02 {
    width: calc(100% - 40px);
    margin: auto;
  }
}


/* 테이블 슬라이드 다운 - 반응형 190308 */
.table-type01 .child {
  /*display: none;*/
  background: #efefef;
}

/*잠시 개발로 display:none 주석*/
.table-type01 .child .td-wrap {
  padding: 40px 130px;
}

.table-type01 .child td {
  padding: 0;
}

.table-type01 .child .table {
  width: 100%;
  margin-bottom: 20px;
}

.table-type01 .child .table:last-child {
  margin-bottom: 0;
}

.table-type01 .child .table-cell {
  width: 50%;
  text-align: left;
}

.table-type01 .child .td-wrap {
  margin: auto;
}

.table-type01 .child .table-cell.left {
  padding-right: 20px;
}

.table-type01 .child .table-cell.right {
  padding-left: 20px;
}

.table-type01 .child .btn-area {
  border-top: 1px solid var(--line-clr3);
  padding-top: 20px;
  margin-top: 20px;
}

.table-type01 .child .table .input-basic {
  max-width: 100%;
}


.pc-viewbox {
  display: block !important;
}

.tablet-viewbox {
  display: none !important;
}

.mobile-viewbox {
  display: none !important;
}

.main-page-section-top-banner .pc-viewbox {
  display: flex !important;
}

.main-page-section-top-banner .tablet-viewbox {
  display: none !important;
}

.main-page-section-top-banner .mobile-viewbox {
  display: none !important;
}



@media (max-width: $wrapSize) {
  .page-title {
    margin-top: 20px;
    font-size: 0;
  }

  .pc-viewbox {
    display: none !important;
  }

  .tablet-viewbox {
    display: block !important;
  }

  .mobile-viewbox {
    display: none !important;
  }

  .main-page-section-top-banner .tablet-viewbox {
    display: flex !important;
  }

  .main-page-section-top-banner .pc-viewbox {
    display: none !important;
  }

  .main-page-section-top-banner .mobile-viewbox {
    display: none !important;
  }

  .table-type01 .child .table {
    display: block !important;
    margin-bottom: 0;
  }

  .table-type01 .child .td-wrap {
    padding: 32px 7.5%;
  }

  .table-type01 .child .table-cell {
    display: block !important;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }

  .table-type01 .child .btn-area {
    padding: 0;
    margin-top: 0;
    border-top: 0;
  }

  .table-type01 .child .btn-area button {
    margin: 0;
  }

  .tbl-style01 {
    border-top: 1px solid var(--body-clr);
  }

  .tbl-style01 li {
    border-bottom: 1px solid var(--disable-clr);
  }

  .tbl-style01 li .link {
    display: block;
    padding: 20px 18px;
  }

  .tbl-style01 li .link .thema {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
  }

  .tbl-style01 li .link .date,
  .tbl-style01 li .link .name {
    margin-top: 5px;
    color: #666;
    font-size: 12px;
  }

  .tbl-style01 li .link .btn {
    margin-top: 15px;
  }

  .event-info .title {
    width: 100%;
  }

  .event-info .text {
    width: 100%;
    margin-top: 5px;
    color: #666;
  }

  .event-info dd:first-of-type {
    margin-top: 5px;
  }

  .event-info .btn-sm-wh {
    margin: 0;
  }

  .ico-internet,
  .ico-smartphone,
  .ico-notice,
  .ico-event,
  .ico-etc,
  .ico-shop {
    display: inline-block;
    margin-top: 5px;
    ;
    padding: 3px 11px 1px;
  }

  .btn-support,
  .btn-download {
    width: 112px;
    height: 32px;
  }

  a.btn-support,
  a.btn-download {
    margin-left: 0;
  }

  a.btn-download.type01 {
    width: initial;
    height: initial
  }

  .table-type04.type03 th {
    padding: 0 12px;
    font-size: 13px;
    word-break: keep-all;
  }

  .table-type04.type03 td {
    font-size: 13px;
    font-weight: $regular;
  }

  .btn-area button {
    margin: 0;
  }

  .btn-area {
    padding: 0;
  }
}

@media (max-width:768px) {
  .pc-viewbox {
    display: none !important;
  }

  .tablet-viewbox {
    display: none !important;
  }

  .mobile-viewbox {
    display: block !important;
  }

  .main-page-section-top-banner .mobile-viewbox {
    display: flex !important;
  }

  .main-page-section-top-banner .pc-viewbox {
    display: none !important;
  }

  .main-page-section-top-banner .tablet-viewbox {
    display: none !important;
  }
}

/*190311 PEY 추가*/

.table-wrap01 .table-type04 .icon-list-wrap {
  padding: 0;
}

/*인증번호 input timer*/
.input-timer-wrap {
  position: relative;
  width: 525px;
  margin-right: 10px;
}

.input-timer-wrap .timer {
  position: absolute;
  top: 0;
  right: -10px;
}

.table-type03-w {
  table-layout: fixed;
}

.table-type04,
.table-type03-w {
  th label {
    font-weight: $bold;
  }
}


/* 190625 추가 */
@media (max-width: $wrapSize) {
  .product-box .product-text .btn-thin {
    max-width: 75px;
    margin-left: 20px;
  }
}

/* 링크 */
.terms-link {
  display: inline-block;
  color: var(--body-clr);
  text-decoration: underline;
}

/* 수수료 단위 */
.sub-title-area .unitdate {
  float: right;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 11px;
  color: var(--body-clr);
  font-weight: $regular;
}

/* .notice-subtext + .info-table-area {padding-top:30px} */
.table-title + .unitdate {
  margin-top: 0;
}

.unitdate {
  margin-top: 38px;
  margin-bottom: 20px;
  font-size: 11px;
  color: var(--body-clr);
  font-weight: $regular;
  text-align: right;
}

.notice-subtext.topnone {
  margin-top: 0;
}

.tts + .info-table-area .notice-subtext.topnone {
  margin-top: 20px;
}

.tts + .notice-subtext.topnone {
  margin-top: 30px;
}

.table-title {
  font-size: 19px;
  text-align: center;
  font-weight: $medium;
  color: var(--body-clr);
}

/* product-box */
.product-box {
  margin-top: 40px;
  border-top: 1px solid #000
}

.product-box .product-title {
  padding: 23px 20px;
  background: #f7f7f7;
  border-top: 1px solid var(--disable-clr);
  border-bottom: 1px solid var(--disable-clr);
  font-size: 16px;
  font-weight: $medium;
  line-height: 25px;
}

.product-box .product-text {
  padding: 18px 20px;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
  font-weight: $regular;
}

.product-box .product-text .btn-thin {
  margin-left: 20px;
  padding: 6px 0 4px;
}

.product-box .product-title + .product-text {
  border-bottom: 0;
}

@media (max-width: 768px) {
  .product-box {
    margin-top: 0;
  }
}

/* table-type04 */
/* .table-type04 {table-layout:fixed} */
.media-scroll-wrap * {
  word-break: break-all
}

/* 윤리경영 */
.ethics-list-box {
  margin: 47px 0 0 3px;
}

.ethics-list-box .ethics-list {
  margin-top: 20px;
  font-size: 16px;
  font-weight: $regular;
  color: var(--body-clr);
  line-height: 42px;
}

.ethics-list-box .ethics-list:first-child {
  margin-top: 0;
}

.ethics-list-box .ethics-list .title {
  margin-top: 47px;
  font-size: 22px;
  font-weight: $medium;
  color: var(--body-clr);
}

.ethics-list-box .ethics-list .title:first-child {
  margin-top: 0;
}

.ethics-list-box .ethics-list .title .point-title {
  color: var(--main-clr);
  font-weight: $medium;
}

.ethics-list-box .ethics-list .title02 {
  margin-top: 20px;
  font-size: 16px;
  font-weight: $medium;
}

.ethics-list-box .ethics-list .text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.4;
}

.ethics-list-box .ethics-list .termlist-box .ethics-list {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.4;
}

.ethics-sign {
  margin: 18px 0 0 3px;
  font-size: 22px;
  color: var(--main-clr);
  font-weight: $regular;
}

.ethics-sign .text {
  color: var(--main-clr);
  font-size: 16px;
  font-weight: $regular;
}

@media (max-width: 768px) {
  .ethics-list-box {
    margin-top: 25px;
  }

  .ethics-list-box .ethics-list {
    font-size: 14px;
    line-height: 22px;
  }

  .ethics-list-box .ethics-list:first-child {
    margin-top: 0;
  }

  .ethics-list-box .ethics-list .title {
    margin-bottom: 10px;
    font-size: 17px;
  }

  .ethics-sign {
    font-size: 18px;
  }

  .ethics-sign .text {
    font-size: 14px;
  }

  .table-title {
    margin-top: 20px;
  }

  .table-title + .unitdate {
    margin-top: 20px;
  }
}

/*부동산담보대출버튼*/
.btn-linebox {
  margin-top: 15px;
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid #d3d3d3;
  padding: 10px 28px;
}

.btn-linebox .btn-style01 {
  padding: 0 48px 0 19px;
  border-radius: 0;
  border-bottom-width: 2px;
  background: url(/img/hss_content/hps/ico_down.png) no-repeat right 20px top 10px;
  text-align: left;
}

.certification-agency-box {
  margin-top: 18px;
}

.certification-agency-box::after {
  content: '';
  display: block;
  clear: both;
}

.certification-agency-box .l-box {
  float: left;
  width: 50%;
}

.certification-agency-box .r-box {
  float: right;
  width: 50%;
  text-align: right;
}

.certification-agency-box .bank {
  height: 21px;
  font-size: 14px;
}

.certification-agency-box .text {
  margin-top: 5px;
  font-size: 14px;
  color: #666;
}

.certification-agency-box .logo-box {
  margin-top: -2px;
}

.certification-agency-box .logo-box img {
  width: 105px;
}

.btn-area02 {
  margin-top: 20px;
  text-align: right;
}

.btn-area02 .btn-style08 {
  display: inline-block;
  min-width: 120px;
  height: 40px;
  padding-left: 38px;
  border: 1px solid var(--disable-clr);
  border-bottom-width: 2px;
  background: url(/img/hss_content/bul_printer.png) no-repeat left 28px top 10px;
  font-size: 14px;
  text-align: center;
  color: #666;
}

/*골드바*/
.w-none {
  display: none;
}

@media (max-width: $wrapSize) {
  .m-none {
    display: none;
  }

  .w-none {
    display: block;
  }

  /* 모바일 더보기버튼 있는 테이블 */
  .table-wrap01 {
    position: relative;
  }

  .tbl-style04 {
    width: calc(100% - 30px);
    margin-left: 30px;
  }

  .tbl-style04 th,
  .tbl-style04 td {
    padding: 5px 10px;
    font-size: 15px;
    line-height: 28px;
  }

  .tbl-style04 th {
    font-weight: $bold;
    text-align: left;
  }

  .tbl-style04 td {
    color: #666;
    line-height: 28px;
  }

  .btn_tablemore {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 38px;
    height: 38px;
    background: url(/img/hss_common/button/btn_plus.png) no-repeat 50% 50%;
    background-size: 18px 18px;
  }

  .table-accordion-item.js-accordion-opened .btn_tablemore {
    background-image: url(/img/hss_common/button/btn_minus.png);
  }

  /* table accordion */
  .table-accordion-layer.js-ui-accordion-layer {
    position: relative;
    height: 228px;
    overflow: hidden;
  }

  .table-accordion-layer .component-layer-close-button {
    display: none;
    position: absolute;
    width: 38px;
    height: 38px;
    top: 0;
    left: 0;
  }

  .table-accordion-item.js-accordion-opened .table-accordion-layer .component-layer-close-button {
    display: block;
  }
}

@media (max-width: 768px) {

  .certification-agency-box .l-box,
  .certification-agency-box .r-box {
    float: none;
    width: 100%;
  }

  .certification-agency-box .r-box {
    text-align: left;
    margin-top: 20px;
  }

  .btn-area02 .btn-style08 {
    display: none;
  }
}

.notice-date {
  margin-top: 10px;
  text-align: right;
  font-size: 14px;
}

.common-titlestyle01 + .table-wrap01 {
  margin-top: 15px;
}

.notice-subtext.topnone + .table-wrap01 {
  margin-top: 35px;
}

/*대출 상품*/
.txt-total.loan {
  display: inline-block;
  margin-bottom: 15px;
}

.txt-total.loan::after {
  content: "";
  display: block;
  float: none;
  clear: both;
}

.txt-total.loan .item {
  float: left;
  font-weight: $medium;
  margin-right: 10px;
}

.table-type01.loan .se-td {
  border: 0;
}

.table-type01.loan th.w-44 {
  width: 44%;
}

.table-type01.loan th.w-21 {
  width: 21%;
}

.table-type01.loan th.w-130 {
  min-width: 130px;
}

.table-type01.loan .se-td:first-child {
  text-align: left;
  padding: 30px 35px;
}

.table-type01 td .td-wrap .link {
  display: inline-block;
}

.ico-wrap {
  font-size: 11px;
}

/*상품테이블 상품명*/
.product-title {
  font-size: 24px;
  font-weight: $medium;
  padding-top: 30px;
  padding-bottom: 15px;
}

.product-txt {
  font-size: 16px;
  line-height: 1.3;
}

.loan-p-wrap {
  padding: 20px 0;
  background: #f7f7f7;
  /* min-height: 112px; */
}

.loan-p-wrap.one {
  padding: 40px 0px;
}

.loan-p-wrap .loan-p-txt {
  font-size: 16px;
  word-break: keep-all;
  display: block;
  margin-bottom: 10px;
}

.loan-p-wrap .loan-p-txt.pc-none {
  display: none;
}

.loan-p-wrap .loan-p-txt:last-child {
  margin-bottom: 0;
}

.loan-p-wrap .loan-p-point {
  font-size: 30px;
  color: var(--neg-clr);
  font-weight: $medium;
  line-height: 1.2;
}

.loan-p-info {
  font-size: 20px;
  line-height: 1.3;
  padding-top: 10px;
  display: inline-block;
}

.product-list {
  overflow: hidden;
  width: 100%;
  border-top: 1px solid var(--body-clr);
}

.product-list > li {
  overflow: hidden;
  width: 100%;
  padding: 30px 3.7%;
  position: relative;
  border-bottom: 1px solid #efefef;
}

.product-list > li .product-list-l {
  float: left;
  width: 47%;
  height: 100%;
}

.product-list > li .product-list-tit {
  font-size: 24px;
  float: left;
  width: 380px;
  padding-top: 37px;
  font-weight: $bold;
  letter-spacing: -0.05em;
}

.product-list > li .product-list-exp {
  width: 100%;
  float: left;
  line-height: 1.5;
  font-size: 16px;
  padding-top: 15px;
}

.product-list > li .product-list-classi {
  position: absolute;
  top: 30px;
  left: 40px;
  font-size: 11px;
}

.product-list-btn.margin .btn-small {
  margin-bottom: 10px;
}

.product-list > li .product-list-irate {
  width: 38.2%;
  display: table;
  text-align: center;
  float: left;
  margin-top: 21px;
}

.product-list > li .product-list-irate > li {
  display: table-cell;
  width: 50%;
  border-left: 1px solid #efefef;
  margin-left: 40px;
  height: 90px;
  text-align: center;
  font-size: 14px;
  letter-spacing: -0.07em;
}

.product-list > li .product-list-irate > li:first-child {
  width: 125px;
  padding-left: 0;
  margin-left: 0;
  border-left: 0;
}

.product-list > li .product-list-irate > li em {
  display: block;
  font-size: 20px;
  font-style: normal;
  font-weight: $bold;
  padding: 10px 0;
}

.product-list > li .product-list-irate > li em i {
  display: inline;
  width: auto;
  color: var(--neg-clr);
  font-size: 30px;
  font-style: normal;
  font-weight: $bold;
}

.product-list > li .product-list-btn {
  float: left;
  /* top:30px; right:40px;*/
  width: 14.8%;
  margin-top: 17px;
}

.product-list > li .product-list-btn button {
  margin-bottom: 10px;
}

.no-item {
  min-height: 178px;
  text-align: center;
  font-weight: $medium;
  font-size: 22px;
  line-height: 117px;
}

.product-view {
  width: 100%;
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);
  padding-top: 25px
}

.product-view .product-list-l {
  position: relative;
  padding: 0 40px;
}

.product-view .ico-internet,
.product-view .ico-smartphone,
.product-view .ico-shop {
  display: inline-block;
}

.product-view .product-view-tit {
  font-size: 24px;
  display: inline-block;
  width: 100%;
  padding-top: 25px;
  font-weight: $bold;
  letter-spacing: -0.05em;
}

.product-view .product-view-exp {
  width: 100%;
  line-height: 1.5;
  font-size: 16px;
  display: inline-block;
  padding-top: 15px;
}

.product-view .product-view-sns {
  float: right
}

.product-view .product-view-sns a {
  float: left;
  margin-right: 5px;
}

.product-view .product-view-sns A:last-child {
  margin-right: 0;
}

.product-view-dl {
  overflow: hidden;
  width: 100%;
  margin-top: 35px;
  padding: 40px;
  border-top: 1px solid #efefef;
}

.product-view-dl > li {
  display: table;
  width: 100%;
  padding-top: 15px;
}

.product-view-dl > li:first-child {
  padding-top: 0px;
}

.product-view-dl .notice {
  padding-left: 15px;
  font-size: 16px;
  font-weight: $medium;
  line-height: 1.4;
}

.product-view-dl .notice::before {
  content: '';
  float: left;
  margin-left: -15px;
  margin-top: 4px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #999;
}

.product-view-dl .product-view-dt {
  font-weight: $medium;
  display: table-cell;
  width: 165px;
  padding-left: 15px;
  padding-right: 20px;
  vertical-align: top;
  font-size: 14px;
  line-height: 32px;
  word-break: keep-all;
}

.product-view-dl .product-view-dt::before {
  content: '';
  float: left;
  margin-left: -15px;
  margin-top: 13px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #999;
}

.product-view-dl .product-view-dt.type02 {
  width: 100%;
}

.product-view-dl .product-view-dt span {
  display: block;
  margin-top: -12px;
  font-weight: $medium;
}

.product-view-dl .list {
  overflow: hidden;
  padding-top: 20px;
}

.product-view-dl + .product-view-dl {
  margin-top: 0;
}

.product-view-dd .btn-thin.resize {
  display: inline-block;
  padding: 4px 8px 3px;
  vertical-align: 1px;
  line-height: 20px;
}

.product-view-dl .product-view-dd .notice-list-wrap {
  margin-top: 5px;
}

.product-view-dl .product-view-dd .notice-list-wrap + .notice-list-wrap {
  margin-top: 10px;
}

.product-view-dl .product-view-dd .table-wrap01 {
  margin-top: 10px;
}

.product-view-dl .product-view-dd .table-wrap01 + .notice-list-wrap {
  margin-top: 15px;
}

.product-view-dl .product-view-dd .table-type04 th {
  width: 145px;
}

.product-view-dl .product-view-dd .table-type04 td {
  width: auto;
  border-right: 1px solid #efefef;
}

.product-view-dl .product-view-dd .table-type04 td:last-child {
  border-right: 0;
}

.product-view-dl .product-view-dd .img-wrap {
  margin-top: 10px;
}

.product-view-dl .product-view-dd .img-wrap img {
  width: 100%;
}

.bbs-headinfo .snsbox,
.product-list-l .snsbox {
  position: relative;
  float: right;
  right: -22px;
  width: 64px;
}

.bbs-headinfo .snsbox .snslink-view,
.product-list-l .snsbox .snslink-view {
  display: block;
  overflow: hidden;
  width: 64px;
  height: 30px;
  margin-top: -3px;
  background: url(/img/common/icon_share.png) no-repeat right 20px top;
  font-size: 0;
  line-height: 0;
}

.bbs-headinfo .snsbox .tooltip-layerpopup,
.product-list-l .snsbox .tooltip-layerpopup {
  display: none;
  position: absolute;
  top: 50px;
  right: -48px;
  width: 300px;
  height: 148px;
  padding: 40px 0;
  border: 1px solid #000;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: var(--bg-clr2);
}

.bbs-headinfo .snsbox .tooltip-layerpopup-close,
.product-list-l .snsbox .tooltip-layerpopup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: #3399ff;
  z-index: 100;
}

.bbs-headinfo .snsbox .tooltip-layerpopup::after,
.product-list-l .snsbox .tooltip-layerpopup::after {
  position: absolute;
  top: -9px;
  right: 67px;
  content: " ";
  display: block;
  width: 15px;
  height: 9px;
  background: url(/img/common/bg_tooltip.gif) no-repeat 0 0;
}

.bbs-headinfo .bbs-head.style01::after,
.product-list-l .bbs-head.style01::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* tooltip 이미지 */
.bbs-headinfo .snsbox .tooltip-layerpopup .kakao,
.product-list-l .snsbox .tooltip-layerpopup .kakao {
  background: url(/img/common/bul_kakao_off.png) no-repeat 50% 0
}

.bbs-headinfo .snsbox .tooltip-layerpopup .instagram,
.product-list-l .snsbox .tooltip-layerpopup .instagram {
  background: url(/img/common/bul_insta_off.png) no-repeat 50% 0
}

.bbs-headinfo .snsbox .tooltip-layerpopup .facebook,
.product-list-l .snsbox .tooltip-layerpopup .facebook {
  background: url(/img/common/bul_facebook_off.png) no-repeat 50% 0
}

.bbs-headinfo .snsbox .tooltip-layerpopup .urlcopy,
.product-list-l .snsbox .tooltip-layerpopup .urlcopy {
  background: url(/img/common/bul_url_off.png) no-repeat 50% 0
}

.bbs-headinfo .snsbox .tooltip-layerpopup .sns,
.product-list-l .snsbox .tooltip-layerpopup .sns {
  display: inline-block;
  width: 79px;
  padding-top: 65px;
  text-align: center;
  font-size: 12px;
  font-weight: $bold;
  color: #666;
}

.bbs-headinfo .snsbox .tooltip-layerpopup .tooltip-layerpopup-close,
.product-list-l .snsbox .tooltip-layerpopup .tooltip-layerpopup-close {
  background: url(/img/common/bg_close.png) no-repeat center;
  font-size: 0;
}

.btn-area.type03 {
  margin-top: 20px !important;
  padding: 30px 20px !important;
  text-align: left;
  background: #f7f7f7;
}

.btn-sm-wh.ico_down {
  display: inline-block;
  min-width: 240px;
  height: 40px;
  padding: 0 40px 0 15px;
  background: var(--bg-clr2) url(/img/common/ico_down.png) no-repeat 95% center;
  text-align: left;
  line-height: 40px;
}

.btn-area + .notice-subtext {
  margin-top: 30px;
}

.product-list-l .common-text01 + .btn-thin {
  margin-top: 20px;
}

@media (max-width: $wrapSize) {
  .btn-area.type03.retouch-m {
    padding: 25px 16px !important;
  }

  .pv-kdic .pv-kdic-txt {
    font-size: 12px;
    line-height: 20px;
  }
}

@media (max-width: $wrapSize) {
  .product-list > li {
    padding: 20px 16px 35px;
  }

  .product-list > li .product-list-l {
    width: 100%;
  }

  .product-list > li .product-list-classi {
    left: 16px;
    top: 26px;
    font-size: 11px;
  }

  .product-list > li .product-list-exp {
    width: 100%;
    font-size: 13px;
    color: #666
  }

  .product-list .product-list-irate {
    position: relative;
    left: 0px;
    top: 10px;
    width: 100% !important;
    margin-top: 0;
    background: #efefef;
  }

  .product-list > li .product-list-tit {
    font-size: 16px;
  }

  .product-list .product-list-irate > li {
    height: auto;
    width: 50%;
    padding: 25px 0;
    margin: 0;
    font-size: 11px;
    border-left: 1px solid var(--disable-clr) !important;
  }

  .product-list .product-list-irate > li:first-child {
    border: 0 !important;
  }

  .product-list .product-list-irate > li .m-bold {
    font-size: 13px;
    font-weight: $medium;
  }

  .product-list .product-list-irate > li em {
    font-size: 18px;
  }

  .product-list .product-list-irate > li em i {
    font-size: 24px;
    vertical-align: middle;
  }

  .product-list > li .product-list-btn {
    position: relative;
    right: 0;
    top: 10px;
    width: 100%;
    display: inline-block;
    margin-top: 25px;
  }

  .product-list > li .product-list-btn button {
    width: 100%;
  }

  .no-item {
    padding-bottom: 16px !important;
    font-size: 19px;
    line-height: 138px;
  }

  .txt-total {
    display: inline-block;
    padding: 20px 0 0 16px;
    font-size: 11px;
    color: #666;
  }

  .product-view.mt50 {
    margin-top: 15px !important;
  }

  .product-view .product-view-tit {
    padding-top: 0;
    font-size: 20px;
  }

  .product-view .product-list-l {
    padding: 0 5px;
  }

  .product-view .product-view-dl {
    padding: 20px 5px;
    margin-top: 20px;
  }

  .product-view .product-view-exp {
    font-size: 14px;
  }

  .pv-kdic {
    padding: 16px;
  }

  .pv-kdic .pv-kdic-logo {
    display: inline-block;
    margin-top: 7px;
    margin-right: 20px;
    padding-right: 0;
    min-width: 100px;
  }

  .pv-kdic .pv-kdic-logo img {
    max-width: 100%;
  }

  .product-view-dl li {
    overflow: hidden;
  }

  .product-view-dl .product-view-dt {
    display: block;
    width: 100%;
    padding-left: 10px;
    line-height: 20px;
  }

  .product-view-dl .product-view-dt::before {
    margin-top: 8px;
    margin-left: -10px;
  }

  .product-view-dl .product-view-dt span {
    display: inline;
  }

  .product-view-dl .product-view-dd {
    width: 100%
  }

  .product-view-dl .product-view-dd .table-type03-w {
    display: table;
  }

  .product-view-dl .product-view-dd .img-wrap img {
    max-width: 100%;
  }

  .product-view-dl .product-view-dd .notice-list-wrap {
    padding: 0;
  }

  .notice-list-wrap + .tab-basic.pt50 {
    margin-top: 0;
  }

  .notice-list li,
  ul .common-liststyle01 {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 6px;
    padding-left: 8px;
  }

  .notice-list li {
    padding: 0;
  }

  .notice-list li::before,
  ul .common-liststyle01::before {
    margin-left: -8px;
    margin-top: 8px;
    width: 2px;
    height: 2px;
    background-color: var(--body-clr);
  }

  .notice-list-wrap + .tab-basic {
    margin-top: 0;
  }

  /*상세페이지*/
  .notice-list.bg {
    background: var(--bg-clr2);
    padding: 30px 20px;
    margin-bottom: -30px;
  }

  .btn-area.retouch-m .btn-sm-wh {
    width: 100%;
  }

  .ico-wrap {
    font-size: 11px;
  }

  .product-title {
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 10px;
  }

  .product-txt {
    font-size: 13px;
  }

  .loan-p-wrap {
    text-align: center;
    padding: 30px 0 0px;
  }

  .loan-p-info {
    width: 100%;
    background: #f7f7f7;
    text-align: center;
    font-size: 11px;
    padding-bottom: 20px;
  }

  .loan-p-wrap .loan-p-txt {
    font-size: 16px;
    font-weight: $bold;
  }

  .loan-p-wrap .loan-p-point {
    font-size: 24px;
  }

  .loan-p-wrap .loan-p-txt.pc-none {
    display: block;
  }

  .table-type01.loan .se-td .td-wrap button {
    margin-bottom: 10px;
  }

  .full-content {
    margin: 0 -18px !important;
  }

  .full-content .btn-area {
    padding: 0 18px !important;
  }

  /* 툴팁 미디어 쿼리 */
  .snsbox .tooltip-layerpopup {
    right: -3px;
    width: 155px;
    height: 62px;
    padding: 19px 0 40px 0;
  }

  .snsbox .tooltip-layerpopup::after {
    right: 20px
  }

  .snsbox .tooltip-layerpopup .kakao,
  .snsbox .tooltip-layerpopup .instagram,
  .snsbox .tooltip-layerpopup .facebook,
  .snsbox .tooltip-layerpopup .urlcopy {
    background-size: 80%;
    line-height: 0;
  }

  .snsbox .tooltip-layerpopup .sns {
    width: 32px;
    padding-top: 34px;
    font-size: 0;
  }

  /* 버튼 여백 */
  .btn-area {
    margin-top: 25px;
  }
}

/*대출 상품*/
.txt-total.loan {
  display: inline-block;
  margin-bottom: 15px;
}

.txt-total.loan::after {
  content: "";
  display: block;
  float: none;
  clear: both;
}

.txt-total.loan .item {
  float: left;
  font-weight: $medium;
  margin-right: 10px;
}

.table-type01.loan .se-td {
  border: 0;
}

.table-type01.loan th.w-44 {
  width: 44%;
}

.table-type01.loan th.w-21 {
  width: 21%;
}

.table-type01.loan th.w-130 {
  min-width: 130px;
}

.table-type01.loan .se-td:first-child {
  text-align: left;
  padding: 30px 35px;
}

.ico-wrap {
  font-size: 11px;
}

/*상품테이블 상품명*/
.product-txt {
  font-size: 16px;
  line-height: 1.3;
}

.loan-p-wrap {
  padding: 20px 0;
  background: #f7f7f7;
  /* min-height: 112px; */
}

.loan-p-wrap.one {
  padding: 40px 0px;
}

.loan-p-wrap .loan-p-txt {
  font-size: 16px;
  word-break: keep-all;
  display: block;
  margin-bottom: 10px;
}

.loan-p-wrap .loan-p-txt.pc-none {
  display: none;
}

.loan-p-wrap .loan-p-txt:last-child {
  margin-bottom: 0;
}

.loan-p-wrap .loan-p-point {
  font-size: 30px;
  color: var(--neg-clr);
  font-weight: $medium;
  line-height: 1.2;
}

.loan-p-info {
  font-size: 20px;
  line-height: 1.3;
  padding-top: 10px;
  display: inline-block;
}

.product-list-btn.margin .btn-small {
  margin-bottom: 10px;
}

@media (max-width: $wrapSize) {
  .ico-wrap {
    font-size: 11px;
  }

  .table-type01.loan th {
    display: none !important;
  }

  .table-type01.loan .se-td::before {
    display: none;
  }

  .table-type01.loan .se-td {
    display: block;
    border: 0;
    width: 100%;
  }

  .table-type01.loan .se-td:first-child {
    border-top: 1px solid var(--body-clr);
  }

  .table-type01.loan .se-td .td-wrap {
    padding-bottom: 0;
  }

  .table-type01.loan .se-td:last-child .td-wrap {
    padding-bottom: 20px;
  }

  .product-title {
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 10px;
  }

  .product-txt {
    font-size: 13px;
  }

  .loan-p-wrap {
    text-align: center;
    padding: 30px 0 0px;
  }

  .loan-p-info {
    width: 100%;
    background: #f7f7f7;
    text-align: center;
    font-size: 11px;
    padding-bottom: 20px;
  }

  .loan-p-wrap .loan-p-txt {
    font-size: 16px;
    font-weight: $bold;
  }

  .loan-p-wrap .loan-p-point {
    font-size: 24px;
  }

  .loan-p-wrap .loan-p-txt.pc-none {
    display: block;
  }

  .table-type01.loan .se-td .td-wrap button {
    margin-bottom: 10px;
  }
}


.ceo-info-wrap {
  padding: 0 100px;
}

.ceo-info {
  text-align: center;
}

.ceo-img-box {
  height: 450px;
  background: url(/img/hss_content/com/img_ceo.jpg);
}

.ceo-img-box + .ceo-info-wrap {
  margin-top: 70px;
}

.ceo-info .title {
  font-size: 30px;
  color: var(--body-clr);
  line-height: 38px;
  word-break: keep-all;
}

.ceo-info .text {
  margin-top: 30px;
  font-size: 18px;
  color: #666;
  text-align: left;
  line-height: 30px;
}

.ceo-info-wrap .ceo-text {
  margin-top: 74px;
  font-size: 18px;
  color: #666;
  text-align: right;
  line-height: 30px;
}

.ceo-info-wrap .ceo-text span {
  display: block;
  color: #666;
  line-height: 50px;
}

.ceo-info-wrap .ceo-sign {
  margin-top: 36px;
  text-align: right;
}

.ceo-img-wrap .ceo-sign.type2 {
  margin-top: 36px;
  text-align: right;
}

/*김재원*/
/* .ceo-info-wrap .ceo-sign img {margin:0 -21px -19px 21px} */
.ceo-info-wrap .ceo-sign span {
  font-size: 18px;
  color: var(--body-clr);
  line-height: 30px;
}

.ceo-info-wrap.anyang-bank {
  padding: 0 60px;
}

/* 안양 저축은행 */
.ceo-info-wrap.anyang-bank .text-point {
  color: #007b00;
}

/* 경영이념 신규스타일  */
.ethics-info {
  text-align: center;
}

.ethics-info .title {
  font-size: 30px;
  color: var(--body-clr);
  line-height: 38px;
  word-break: keep-all;
}

.ethics-info .title .title-comment {
  display: block;
  padding: 50px 0;
  font-size: 18px;
  color: #666;
  line-height: 30px;
}

/*장현철*/
.ethics-info .ethics-img img {
  width: 100%;
}

.ethics-info .ethics-img .ethics-img-pc {
  display: block;
}

.ethics-info .ethics-img .ethics-img-m {
  display: none;
}

.ethics-info .txt {
  font-size: 18px;
  letter-spacing: -.25px;
  color: #666;
  line-height: 30px;
  margin: 100px 0;
}

/* 20200901 이예빈 추가 */
/* 고려저축은행 */
.organize-box.goryeo-bank img {
  max-width: 760px;
}

/* 기업문화 인성 저축은행 */

.insung-bank .ethics-info .title .title-comment {
  display: block;
  margin: 25px 0;
  font-size: 30px;
  color: var(--body-clr);
  line-height: 42px;
  letter-spacing: -.5px;
}

.insung-bank .ethics-info .point-color01 {
  color: #0c6fb3 !important;
  font-weight: bold;
}

.insung-bank .ethics-info .point-color02 {
  color: #2eb1d2 !important;
  font-weight: bold;
}


/* [공통]  ceo 인사말 가로형 */
.ceo-img-box {
  display: table;
  width: 100%;
  padding: 0 0 0 49px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
}

.ceo-img-box .text {
  display: table-cell;
  position: relative;
  font-size: 30px;
  color: var(--bg-clr2);
  line-height: 52px;
  vertical-align: middle;
}

/* .ceo-img-box .text::before {display:block;content:'';position:absolute;top:134px;left:0;width:60px;height:4px;background:#008ed8;} */
.ceo-img-box .text span {
  display: block;
  color: var(--bg-clr2);
  line-height: 52px;
}

.ceo-img-box .text .text-point {
  display: inline-block;
}

.ceo-img-box.hanhwa-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/hanhwa_content/bg_ceo.jpg);
}

/* 한화저축은행  */
.ceo-img-box.koreatuja-bank {
  height: 451px;
  background-image: url(/img/hss_companycontent/koreatuja_content/bg_ceo.jpg);
}

/* 한국투자저축은행  */
.ceo-img-box.koreatuja-bank .text .text-point {
  color: #71a3ea;
}

.ceo-img-box.kiumyes-bank {
  height: 330px;
  background-image: url(/img/hss_companycontent/kiumyes_content/bg_ceo.jpg);
}

/* 키움예스저축은행  */
.ceo-img-box.kiumyes-bank .text::before {
  top: 50px;
  background: #008ed8;
}

.ceo-img-box.jinju-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/jinju_content/bg_ceo.jpg);
}

/* 진주저축은행  */
.ceo-img-box.jinju-bank .text .ty01 strong {
  color: var(--bg-clr2);
  font-weight: $medium;
}

.ceo-img-box.yungchang-bank {
  height: 330px;
  background-image: url(/img/hss_companycontent/yungchang_content/bg_ceo.jpg);
}

/* 융창저축은행  */
.ceo-img-box.yungchang-bank .text .text-point {
  color: #00a3d9;
}

.ceo-img-box.out-bank {
  height: 330px;
  background-image: url(/img/hss_companycontent/out_content/bg_ceo.jpg);
}

/* 오투저축은행  */
.ceo-img-box.out-bank .text .text-point {
  color: #00b4f1;
}

.ceo-img-box.ms-bank {
  height: 330px;
  background-image: url(/img/hss_companycontent/ms_content/bg_ceo.jpg);
}

/* 엠에스 저축은행  */
.ceo-img-box.ms-bank .text .text-point {
  font-weight: $bold;
  color: #008db2;
}

.ceo-img-box.anyang-bank {
  height: 332px;
  background-image: url(/img/hss_companycontent/anyang_content/bg_ceo.jpg);
}

/* 안양 저축은행  */
.ceo-img-box.baro-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/baro_content/bg_ceo.jpg);
}

/* 바로 저축은행  */
.ceo-info-wrap.baro-bank {
  padding: 0;
}

.ceo-img-box.sky-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/sky_content/bg_ceo.jpg);
}

/* 스카이	저축은행  */
.ceo-img-box.smart-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/smart_content/bg_ceo.jpg);
}

/* 스마트	저축은행  */
.ceo-img-box.central-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/central_content/bg_ceo.jpg);
}

/* 센트럴 저축은행  */
.ceo-img-box.central-bank .text strong {
  color: var(--bg-clr2);
}

.ceo-img-box.samho-bank {
  height: 449px;
  background-image: url(/img/hss_companycontent/samho_content/bg_ceo.jpg);
}

/* 삼호 저축은행  */
.ceo-img-box.burim-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/burim_content/bg_ceo.jpg);
}

/* 부림 저축은행  */
.ceo-info-wrap.burim-bank .text .ty01 {
  display: block;
  color: #ed1260;
  font-weight: $bold;
}

.ceo-img-box.minguk-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/minguk_content/bg_ceo.jpg);
}

/* 민국 저축은행  */
.ceo-img-box.raon-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/raon_content/bg_ceo.jpg);
}

/* 라온 저축은행  */
.ceo-img-box.dream-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/dream_content/bg_ceo.jpg);
}

/* 드림 저축은행  */
.ceo-img-box.dongyang-bank {
  height: 240px;
  background-image: url(/img/hss_companycontent/dongyang_content/bg_ceo.jpg);
}

/* 동양 저축은행  */
.ceo-img-box.dobble-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/dobble_content/bg_ceo.jpg);
}

/* 더블 저축은행  */
.ceo-img-box.daehan-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/daehan_content/bg_ceo.jpg);
}

/* 대한 저축은행  */
.ceo-img-box.daewon-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/daewon_content/bg_ceo.jpg);
}

/* 대원 저축은행  */
.ceo-img-box.daea-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/daea_content/bg_ceo.jpg);
}

/* 대아 저축은행  */
.ceo-img-box.daeback-bank {
  height: 240px;
  background-image: url(/img/hss_companycontent/daeback_content/bg_ceo.jpg);
}

/* 대백 저축은행  */
.ceo-img-box.goryeo-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/goryeo_content/bg_ceo.jpg);
}

/* 고려 저축은행  */
.ceo-img-box.gangwon-bank {
  height: 240px;
  background-image: url(/img/hss_companycontent/gangwon_content/bg_ceo.jpg);
}

/* 강원 저축은행  */
.ceo-img-box.dh-bank {
  height: 449px;
  background-image: url(/img/hss_companycontent/dh_content/bg_ceo.jpg);
}

/* dh 저축은행  */
.ceo-img-box.star-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/star_content/img_stceo.png);
}

/* 스타 저축은행  */
.ceo-img-box.yuanta-bank {
  height: 330px;
  background-image: url(/img/hss_companycontent/yuanta_content/bg_ceo.jpg);
}

/* 유안타 저축은행  */

.ceo-img-box.woolee-bank {
  height: 450px;
  background-image: url(/img/hss_companycontent/woolee_content/bg_ceo.jpg);
}

/* 우리저축은행  */
.ceo-img-box.woolee-bank > .text {
  text-align: center;
  font-family: 'NanumMyeongjo';
}

/* 우리저축은행  */
.ceo-img-box.woolee-bank > .text strong {
  font-size: 40px;
  color: var(--bg-clr2);
  font-family: 'NanumMyeongjo';
}

/* 우리저축은행  */
.ceo-info-wrap.woolee-bank {
  padding: 0;
}

/* 우리저축은행  */

.ceo-img-box.insung-bank {
  height: 340px;
  background-image: url(/img/hss_companycontent/insung_content/bg_ceo.jpg);
}

/* 인성저축은행  */
.ceo-info-wrap.insung-bank {
  padding: 0;
}

.ceo-info-wrap.insung-bank .ceo-sign {
  margin: 50px 0;
  text-align: left
}


/* [공통] ceo 인사말  세로형 */
.ceo-img-wrap {
  padding-left: 357px;
  background-repeat: no-repeat;
  background-position: 58px 132px;
}

.ceo-info-box02 .title {
  font-size: 30px;
  text-align: left;
  line-height: 46px;
}

.ceo-info-box02 .text,
.ceo-info-box02 .text-box li {
  color: #666;
  font-size: 18px;
  line-height: 29px;
}

.ceo-info-box02 .title + .text {
  margin-top: 39px;
}

.ceo-info-box02 .text,
.ceo-info-box02 .text-box {
  margin-top: 30px;
}

.ceo-img-wrap .ceo-info-sign {
  margin-top: 66px;
}

.ceo-info-box02 .title span {
  display: block;
  line-height: 46px;
}

.ceo-info-box02 .title .text-point {
  display: inline-block;
}

/* ceo 인사말 세로형(사진과 내용 순서 반대) 19.09.24 김재원 */
.ceo-img-wrap-type02 {
  padding-left: 0px;
  padding-right: 357px;
  background-position: 850px 132px;
}

.ceo-info-box03 .title {
  font-size: 30px;
  text-align: left;
  line-height: 46px;
}

.ceo-info-box03 .text,
.ceo-info-box03 .text-box li {
  color: #666;
  font-size: 18px;
  line-height: 29px;
}

.ceo-info-box03 .title + .text {
  margin-top: 39px;
}

.ceo-info-box03 .text,
.ceo-info-box03 .text-box {
  margin-top: 30px;
}

.ceo-img-wrap-type02 .ceo-info-sign {
  margin-top: 66px;
}

.ceo-info-box03 .title span {
  display: block;
  line-height: 46px;
}

.ceo-info-box03 .title .text-point {
  display: inline-block;
}

.ceo-img-wrap.pyeongtaek-bank {
  background-image: url(/img/hss_companycontent/pyeongtaek_content/img_pyeongtaekceo.jpg);
}

/* 평택 */
.ceo-img-wrap.cheongju-bank {
  background-image: url(/img/hss_companycontent/cheongju_content/bg_cheongjuceo.jpg);
}

/* 키움yes 저축은행 */
.ceo-img-wrap.incheon-bank {
  background-image: url(/img/hss_companycontent/incheon_content/bg_incheonceo.jpg);
}

/* 인천 저축은행 */
.ceo-img-wrap.osung-bank {
  background-image: url(/img/hss_companycontent/osung_content/bg_osungceo.jpg);
}

/* 오성 저축은행 */
.ceo-img-wrap.asan-bank {
  padding-left: 0;
  background-image: none;
}

/* 아산 저축은행 */
.ceo-img-wrap.samjung-bank {
  background-image: url(/img/hss_companycontent/samjung_content/bg_ceo.jpg);
}

/* 삼정 저축은행 */
.ceo-img-wrap.moa-bank {
  background-image: url(/img/hss_companycontent/moa_content/bg_ceo.jpg);
}

/* 모아 저축은행 */
.ceo-img-wrap.daehan-bank {
  background-image: url(/img/hss_companycontent/daehan_content/bg_ceo.jpg);
}

/* 대한 저축은행 */
.ceo-img-wrap.daemyung-bank {
  background-image: url(/img/hss_companycontent/daemyung_content/bg_ceo.jpg);
}

/* 대명 저축은행 */
.ceo-img-wrap.namyang-bank {
  padding-left: 427px;
  background-image: url(/img/hss_companycontent/namyang_content/bg_ceo.jpg);
  background-size: 409px;
  background-position: 0 0;
}

/* 남양저축은행 */
.ceo-img-wrap.namyang-bank .ceo-info-sign {
  margin-top: 49px;
}

.ceo-img-wrap.kuemhwa-bank {
  background-image: url(/img/hss_companycontent/kuemhwa_content/bg_ceo.jpg);
}

/* 금화 저축은행 */
.ceo-img-wrap.yungchang-bank {
  padding-left: 427px;
  background-image: url(/img/hss_companycontent/yungchang_content/bg_ceo.jpg);
  background-size: 409px;
  background-position: 0 0;
}

/* 융창저축은행 */
.ceo-img-wrap.yungchang-bank-ceo {
  background-image: url(/img/hss_companycontent/yungchang_content/bg_yungchang_ceo.jpg);
  background-size: 200px auto;
}

/* 조은 저축은행 */
.ceo-img-wrap.choeun-bank {
  background: url(/img/hss_companycontent/choeun_content/img_ceo.jpg) no-repeat top left / contain;
}

@media (max-width: $wrapSize) {

  /* CEO 인사말 */
  .ceo-img-wrap {
    margin-top: 20px;
    padding-top: 272px;
    padding-left: 0;
    background-position: 50% top;
  }

  .ceo-info-box02 .title {
    font-size: 20px;
    line-height: 27px;
  }

  .ceo-info-box02 .title + .text {
    margin-top: 14px;
  }

  .ceo-info-box02 .text,
  .ceo-info-box02 .text-box li {
    font-size: 15px;
    line-height: 22px;
  }

  /* CEO 인사말 13.09.24 김재원 */
  .ceo-img-wrap-type02 {
    margin-top: 20px;
    padding-top: 272px;
    padding-right: 0;
    background-position: 50% top;
  }

  .ceo-info-box03 .title {
    font-size: 20px;
    line-height: 27px;
  }

  .ceo-info-box03 .title + .text {
    margin-top: 14px;
  }

  .ceo-info-box03 .text,
  .ceo-info-box03 .text-box li {
    font-size: 15px;
    line-height: 22px;
  }

  /* CEO 인사말 13.09.24 김재원 */
  .ceo-img-wrap-type02 {
    margin-top: 20px;
    padding-top: 272px;
    padding-left: 0;
    background-position: 50% top;
  }

  .ceo-info-box03 .title {
    font-size: 20px;
    line-height: 27px;
  }

  .ceo-info-box03 .title + .text {
    margin-top: 14px;
  }

  .ceo-info-box03 .text,
  .ceo-info-box03 .text-box li {
    font-size: 15px;
    line-height: 22px;
  }

  /* CEO 인사말 */
  .ceo-img-box {
    height: 200px !important;
    padding-left: 0;
    background-size: 100% 100%;
  }

  /* .ceo-img-box .text {position:relative;top:0;left:0px;margin-top:10px;font-size:18px;text-align:center;line-height:34px;font-weight: $bold;color:var(--body-clr);} */
  .ceo-img-box .text {
    display: none;
  }

  .ceo-img-box .title span {
    display: inline-block;
  }

  .ceo-img-box .text::before {
    display: none;
  }

  .ceo-info-wrap {
    padding: 0;
  }

  .ceo-info-wrap.anyang-bank {
    padding: 0 18px;
  }

  .ceo-img-box + .ceo-info-wrap {
    margin-top: 15px;
    line-height: 40px
  }

  .ceo-info .title {
    font-size: 20px;
    line-height: 28px;
  }

  .ceo-info .text {
    margin-top: 20px;
    font-size: 13px;
    line-height: 26px;
  }

  .ceo-info-wrap .ceo-text {
    margin-top: 20px;
    font-size: 13px;
  }

  .ceo-img-wrap.namyang-bank {
    padding-top: 270px;
    padding-left: 0;
    background-size: 210px;
    background-position: 50% 20px;
  }

  .ceo-img-wrap.moa-bank {
    padding-top: 300px;
    background-size: 200px;
  }

  .ceo-img-wrap.choeun-bank {
    background-size: 185px;
  }

  /* 경영이념 신규스타일  */
  .ethics-info .title {
    font-size: 20px;
    line-height: 28px;
  }

  .ethics-info .title .title-comment {
    display: block;
    padding: 20px 0;
    font-size: 13px;
    line-height: 26px;
  }

  .ethics-info .ethics-img .ethics-img-pc {
    display: none;
  }

  .ethics-info .ethics-img .ethics-img-m {
    display: block;
  }

  .ethics-info .txt {
    font-size: 36px;
    line-height: 58px;
    margin: 50px 20px;
  }

  /* 20200901 이예빈 추가 */

  /* 우리은행  */
  .ceo-img-box.woolee-bank > .text {
    display: block;
    font-size: 13px;
    line-height: 26px;
  }

  /* 우리저축은행  */
  .ceo-img-box.woolee-bank > .text strong {
    font-size: 16px;
  }

  /* 우리저축은행  */
  .ceo-img-box.woolee-bank {
    padding-top: 55px;
  }

  /* 우리저축은행  */

  /* 기업문화 인성 저축은행 */

  .insung-bank .ethics-info .title .title-comment {
    font-size: 50px;
    line-height: 68px;
    margin: 25px 50px;
  }
}

@media (max-width: 768px) {

  /* 경영이념 신규스타일  */

  .ethics-info .txt {
    font-size: 18px;
    line-height: 29px;
    margin: 50px 0;
  }

  /* 20200901 이예빈 추가 */

  /* 기업문화 인성 저축은행 */

  .insung-bank .ethics-info .title .title-comment {
    font-size: 25px;
    line-height: 34px;
  }
}


/* 채권추심업무 */
.tts + .bond-infobox {
  margin-top: 40px;
}

.bond-infobox .bond-title {
  color: var(--main-clr);
  font-size: 22px;
  font-weight: $bold;
  line-height: 27px;
}

.bond-textstyle01 {
  padding-left: 16px;
  color: var(--body-clr);
  font-size: 16px;
  text-indent: -14px;
  line-height: 24px;
}

.bond-textstyle01::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 9px;
  border-radius: 50%;
  background: #999;
  vertical-align: 3px;
}

.info-boxstyle {
  padding: 20px 18px 9px;
  margin-top: 34px;
  background: #f7f7f7;
}

.info-boxstyle .title {
  color: var(--body-clr);
  font-size: 16px;
  font-weight: $bold;
}

.bond-list01 {
  margin-top: 16px;

  li {
    font-size: 14px;
    color: var(--cap-clr);
    text-indent: 14px;
  }

  .bond-textstyle01 {
    margin-top: 13px;
    font-size: 16px;
    text-indent: -14px;
    color: var(--body-clr);
  }
}

.bond-list01 .bond-textstyle01:first-child {
  margin-top: 0;
}

.bond-infobox .bond-title:first-child {
  margin-top: 0;
}

.bond-infobox .bond-title {
  margin-top: 36px;
}

.bond-list02 {
  margin-top: 14px;
}

.bond-list02 .bond-textstyle01 {
  line-height: 34px;
}

.bond-infobox .bond-title + .bond-textstyle01 {
  margin-top: 16px;
}

.bond-list03 {
  margin-top: 11px;
}

.bond-list03 .text {
  font-size: 14px;
  margin-top: 10px;
  padding-left: 17px;
  text-indent: -17px;
  line-height: 25px;
}

.bond-list03 .text:first-child {
  margin-top: 0;
}

.bond-textstyle01 + .notice-tit {
  margin-top: 32px;
}

@media (max-width: $wrapSize) {
  .bond-infobox .bond-title:first-child {
    margin-top: 20px;
  }
}

.gnb-depth2-opener {
  display: none;
}

/* 콘텐츠 이미지 감싸는 div */
.contents-image-wrap {
  padding: 40px 0 60px;
  font-size: 0;
  line-height: 0;
}

.contents-image-wrap img {
  width: 100%;
}

/* 한국투자 금융상품 */
.sub-infotxt-wrap {
  text-align: right;
}

.sub-title-area .sub-infotxt {
  float: right;
}

.sub-infotxt {
  font-size: 11px;
}

.sub-title-area .sub-text {
  margin-top: 13px;
  font-size: 14px;
  line-height: 23px;
}

.product-view-dl li + .notice-i-list {
  margin-top: 30px;
}

.loan-call-txt {
  display: inline-block;
  padding: 5px 10px;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
  line-height: 23px;
  border: 1px solid var(--disable-clr);
}

/*여백 추가*/
.common-titlestyle02 + .notice-list-wrap {
  margin-top: 10px;
}

.common-titlestyle02 + .common-text01 {
  margin-top: 10px;
}

.notice-list-wrap + .notice-i-list-wrap {
  margin-top: 10px;
}

.notice-i-list-wrap + .ui-accordion-list {
  margin-top: 30px;
}

.btn-area + .box-style02 {
  margin-top: 40px;
}

.btn-area.type04 {
  padding-top: 15px;
}

.ui-accordion-layer + .common-text01 {
  margin-top: 20px;
}

.content .common-titlestyle02:first-child {
  margin-top: 0;
}

.notice-i-list-wrap + .table-wrap01 {
  margin-top: 20px;
}

@media (max-width: $wrapSize) {
  .content .common-titlestyle02:first-child {
    margin-top: 30px;
  }

  .btn-area.type04 {
    padding-top: 0;
  }
}

/*약관 css*/
.termsconditions_textbox .termsconditions_thema01 {
  margin-top: 40px;
  font-size: 22px;
  font-weight: $medium;
  line-height: 1.6;
}

.tts + .termsconditions_textbox .termsconditions_thema01:first-child {
  margin-top: 40px;
}

.termsconditions_textbox .termsconditions_thema01:first-child {
  margin-top: 0;
}

.termsconditions_textbox .termsconditions_thema02 {
  margin-top: 50px;
  font-size: 18px;
  font-weight: $medium;
}

.termsconditions_textbox .termsconditions_title01 {
  margin-top: 20px;
  font-size: 16px;
  font-weight: $medium;
  line-height: 28px;
}

.termsconditions_textbox .termsconditions_title02 {
  font-size: 14px;
  font-weight: $medium;
}

.termsconditions_textbox .termsconditions_text01,
.termsconditions_textbox .termsconditions_caution {
  margin-top: 15px;
  font-size: 16px;
  line-height: 28px;
}

.termsconditions_textbox .termsconditions_text02 {
  font-size: 14px;
  line-height: 20px;
}

.termsconditions_textbox .termsconditions_list > li {
  margin-top: 5px;
  font-size: 16px;
  line-height: 28px;
}

.termsconditions_textbox .termsconditions_in_list li {
  font-size: 15px;
  line-height: 28px;
  padding-left: 15px;
}

.termsconditions_thema01 + .termsconditions_text01 {
  margin-top: 20px;
}

.termsconditions_textbox .termsconditions_title01 + .termsconditions_text01 {
  margin-top: 0;
}

.termsconditions_textbox .info-table-area {
  padding-top: 10px !important;
}

/* 190418 김유빈 에디터영역, 표 영역 추가 */
.editor {
  margin-top: 20px;
  padding: 23px 40px 0;
  border-top: 1px solid var(--disable-clr);
  font-size: 14px;
  line-height: 23px;
}

.editor * {
  font-size: 14px;
  line-height: 23px;
}

.editor th,
.editor td {
  border: 1px solid #000 !important;
}

.editor.type02 {
  margin-top: 35px;
  padding-top: 40px;
}

.editor.type02 th,
.editor.type02 td {
  border: 1px solid #000 !important;
}

/* 190829  */
.overview-img-box {
  margin-top: 30px;
  text-align: center;
}

.overview-img-box img {
  width: 100%;
  max-width: 800px;
}

/**/
.common-btn.type04 {
  margin-top: 20px;
}

.common-btn.type04 .common-text01 {
  display: inline-block;
  margin-right: 10px;
}

.common-btn.type04 .link {
  vertical-align: 3px;
}

/* 한화 상품 바로가기 */

.link-list {
  overflow: hidden;
  margin-top: 20px;
  /*background: url(/img/hss_common/bg_border.png);*/
}

.link-list li {
  display: table;
  float: left;
  width: 100px;
  height: 70px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid var(--disable-clr);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.link-list li a {
  display: table-cell;
  font-size: 14px;
  font-weight: $medium;
  vertical-align: middle;
  text-align: center;
}

.link-list li a:hover {
  background: #666;
  color: var(--bg-clr2);
}

.link-list li a span {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}

.link-list li a:hover span {
  color: var(--bg-clr2);
}

/* 금융소비자보호우수사례 */
.box-style04 {
  padding: 51px 214px 51px 40px;
  background: url(/img/hss_common/bg_consumer01.png) no-repeat right 40px top 50%;
  border: 1px solid var(--line-clr3);
}

.box-style04 .title {
  color: var(--body-clr);
  font-size: 22px;
  font-weight: $medium;
  line-height: 28px;
}

.box-style04 .text {
  display: inline-block;
  margin-top: 11px;
  color: #666;
  font-size: 16px;
  line-height: 24px;
}

.financial-list-wrap {
  position: relative;
  margin-top: 50px;
  padding-left: 124px;
}

.financial-list-wrap .title {
  position: absolute;
  top: 5px;
  left: 0;
  color: var(--main-clr);
  font-size: 28px;
  font-weight: $medium;
}

.financial-list-text {
  margin-top: 9px;
  padding-left: 17px;
  font-size: 16px;
  font-weight: $medium;
  text-indent: -17px;
  line-height: 28px;
}

.financial-list-text:first-child {
  margin-top: 0;
}

.financial-list-text .subtext {
  display: block;
  margin-top: 8px;
  padding-left: 13px;
  font-size: 16px;
  text-indent: -13px;
  line-height: 24px;
}

.financial-list-text::before {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  margin-right: 10px;
  background: #999;
  border-radius: 50%;
  vertical-align: 4px;
}


@media (max-width: $wrapSize) {
  .box-style04 {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .box-style04 {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .box-style04 {
    padding: 35px 32px;
    background: none;
  }

  .financial-list-wrap {
    margin-top: 30px;
    padding-left: 0;
  }

  .financial-list-wrap .title {
    position: relative;
    font-size: 22px;
  }

  .financial-list-wrap .financial-list {
    margin-top: 20px;
  }
}


/* 사회공헌활동 */
.common-title01 {
  margin-top: 32px;
  ;
  font-size: 22px;
  font-weight: $medium;
  line-height: 30px;
}

.common-title02 {
  margin-top: 30px;
  font-size: 20px;
  font-weight: $medium;
  line-height: 28px;
}

.common-text04 {
  margin-top: 8px;
  color: #666;
  font-size: 16px;
  line-height: 28px;
}

.social-contribution-list {
  width: 100%;
}

.social-contribution-list::after {
  content: '';
  clear: both;
  display: block;
}

.social-contribution-list .list {
  float: left;
  width: calc(50% - 10px);
  margin-top: 20px;
  background: red;
}

.social-contribution-list .list:nth-child(2n-1) {
  margin-right: 20px;
}

.social-contribution-list .list img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 330px;
}

@media (max-width: 768px) {
  .social-contribution-list .list {
    width: 100%;
    margin-right: 0;
  }
}

/* 약관 이미지 박스 */
.terms-img-box {
  width: 100%;
  margin-top: 10px;
}

.terms-img-box img {
  width: 100%;
  height: auto;
}

/* 중앙회 팝업 css 소스 */

.str-searPop {
  position: relative;
  width: 500px;
}

.store-popWp {
  padding: 75px 0px 60px;
  text-align: center;
}

.store-popWp strong.info {
  font-size: 22px;
  font-weight: $bold;
  line-height: 36px;
  letter-spacing: -.25px;
}

.store-popWp .pop-btn-wp {
  margin: 35px 0 0;
}

.store-popWp .pop-btn-wp button {
  width: 150px;
  height: 40px;
}

.store-popWp.type01 {
  padding: 0;
}

.popup-btn-wrap {
  height: 40px;
  background: #000;
}

.layer-popup {
  font-size: 0;
}

.layer-popup img {
  width: 100%;
  min-width: 360px;
}

.chk-wrap.style01 {
  float: left;
  margin: 9px 0 0 20px;
}

.chk-wrap.style01 .chk-label {
  color: var(--bg-clr2);
  font-size: 13px;
}

.layer-container .layer-close.type01 {
  position: static;
  width: auto;
  height: 100%;
  padding-right: 17px;
  background: none;
  color: var(--bg-clr2);
  font-size: 13px;
  text-indent: 0;
}


.window-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.window-popup img {
  display: block;
  width: 100% !important;
  height: 100% !important;
}

.popup-btn-wrap {
  position: fixed;
  width: 100%;
  bottom: 0;
}

.layer-close.type01 {
  float: right;
  position: static;
  width: auto;
  height: 100%;
  padding-right: 17px;
  background: none;
  color: var(--bg-clr2);
  font-size: 13px;
  text-indent: 0;
}

/* table border */
.br-ef {
  border-right: 1px solid #efefef !important;
}

/* 이벤트 팝업 css */
.event-window-popup {
  width: 988px;
  height: 814px;
  padding: 40px 0 0 44px;
  background: url(/img/hss_common/bg_windowpopup.jpg);
}

.event-window-wrap {
  width: 900px;
  height: 724px;
  padding: 40px 30px 40px 40px;
  background: url(/img/hss_common/bg_windowpopup02.jpg);
}

.event-window-wrap .event-box:first-child {
  margin-top: 0;
}

.event-window-wrap .event-box:nth-child(2) {
  margin-top: 0;
}

.event-window-wrap .info-text {
  margin-top: 10px;
  font-size: 16px;
}

.event-box {
  display: inline-block;
  width: 393px;
  margin-top: 69px;
  margin-right: 40px;
  text-align: center;
}

.event-box:nth-child(2n) {
  margin-right: 0;
}

.event-box .img01 {
  width: 145px;
  height: 58px;
}

.event-list {
  margin-top: 20px;
  border-top: 1px solid;
  border-bottom: 1px solid
}

.event-list .list {
  padding: 10px 0;
  border-top: 1px solid var(--disable-clr);
}

.event-list .list:first-child {
  border-top: 0;
}

.event-list .list .title {
  display: inline-block;
  width: 160px;
  font-size: 20px;
  font-weight: $medium;
  text-align: left;
}

.event-list .list .link01,
.event-list .list .link02 {
  display: inline-block;
  width: 110px;
  height: 45px;
  color: var(--bg-clr2);
  font-size: 16px;
  font-weight: $medium;
  text-align: center;
  line-height: 45px;
}

.event-list .list .link01 {
  margin-right: 7px;
  background: #587780;
}

.event-list .list .link02 {
  background: #0a97b9;
}



/* 19.09.18 김재원 */
.lifeplus-img-box.hanhwa-bank {
  display: block !important;
}

.lifeplus-img-box.hanhwa-bank img {
  display: block;
  width: 100% !important;
}

.lifeplus-btn-wrap {
  width: 100% !important;
}

.lifeplus-btn:first-child {
  margin-left: 50px !important;
}

.lifeplus-btn {
  display: inline-block;
  width: 115px;
  margin-left: 60px !important;
}

@media(max-width:400px) {
  .lifeplus-btn {
    display: inline-block;
    width: 115px;
    margin-left: 30px !important;
  }

  .lifeplus-btn:first-child {
    margin-left: 30px !important;
  }
}

/* 19.09.20 김재원 */
.table-text-align {
  text-align: left !important;
}

/* 파란색 버튼  */
.btn-thin.resize.type01 {
  border-color: rgb(0, 57, 123) !important;
  background-color: rgb(0, 57, 123) !important;
  color: var(--bg-clr2);
}

/*191011 김재원*/
@media (max-width: 768px) {
  // .dim-contents .popup-head01 .popup-title {
  //   padding-right: 25px !important;
  // }
}


.com-box01 {
  margin-top: 3px;
  padding: 10px 10px 9px;
  border: 1px solid var(--disable-clr);
  word-break: keep-all;
}

.com-box01 .tit {
  font-size: 14px;
  font-weight: $medium;
}


/* 190228 추가 - 체크카드 발급신청 */
.slide-box-area .sub-title,
.slide-box-area.type02 .sub-title {
  color: var(--body-clr);
  font-size: 22px;
}

.btn-slide-active .sub-title {
  color: var(--cap-clr) !important;
}

.slide-box-area.type02 .btn-right-wrap::after {
  content: '';
  clear: both;
  float: none;
  display: block;
}

.slide-box-area .btn-slide,
.slide-box-area .btn-slide-active .btn-slide,
.slide-box-area.type02 .btn-slide,
.slide-box-area.type02 .btn-slide-active .btn-slide {
  position: static;
  padding: 0;
  height: 60px;
  float: right;
  border-left: 1px solid var(--disable-clr);
  background: #b5b5b5 url("/img/common/icon_select_fff_up.png") no-repeat center;
}

.slide-box-area .btn-slide-active .btn-slide,
.slide-box-area.type02 .btn-slide-active .btn-slide {
  background: #f7f7f7 url("/img/common/icon_select_333.png") no-repeat center;
  transform: none;
}

.slide-box-txt,
.slide-box-txt p:last-child {
  margin-bottom: 0;
}

.table .table-cell {
  vertical-align: middle;
}

.slide-box-area.type02 .table-cell p {
  margin-bottom: 10px;
}

.slide-box-area.type02 .table-cell p:last-child {
  margin-bottom: 0;
}

.slide-box-area.type02 .label-wrap {
  margin-right: 20px;
  position: relative;
}

.slide-box-area.type02 .label-wrap:last-child {
  margin-right: 0;
  padding-right: 10px;
}

.slide-box-area.type02 .label-wrap:first-child::before {
  position: absolute;
  left: -12px;
  top: 3px;
  content: '( ';
}

.slide-box-area.type02 .label-wrap:last-child::after {
  position: absolute;
  right: 0px;
  top: 3px;
  content: ' )';
}

.slide-box-area.type02 .slide-box-top .label-wrap:first-child::before {
  display: none;
}

.slide-box-area.type02 .slide-box-top .label-wrap:last-child::after {
  display: none;
}

.slide-box-area.type02 .slide-box-top .label-wrap .chk-label {
  color: #666;
  font-weight: $medium;
}

.slide-box-area .btn-downlode,
.slide-box-area.type02 .btn-downlode {
  display: none;
  border-right: 0;
  padding: 23px 20px 23px 40px;
}

.phone-mark {
  display: block;
  float: left;
  margin-right: 10px;
  line-height: 43px;
}

.slide-box-area .table {
  display: table;
}

.slide-box-area .table .table-cell {
  display: table-cell;
}

.slide-box-area .table .table-cell .radio-box + .radio-box {
  margin-left: 40px;
}

.table-clone .table-cell:first-child {
  font-size: 16px;
  font-weight: $medium;
}


/* 율강복지재단 */
.cStyle .tab-basic-buttons-item.js-tabpanel-active,
.cStyle .tab-basic-buttons-item.is-tab-basic-active {
  background-color: #22b24c;
}

.cStyle .tab-basic-buttons-item.js-tabpanel-active,
.cStyle .tab-basic-buttons-item.js-tabpanel-active::before,
.cStyle .tab-basic-buttons-item.js-tabpanel-active + .tab-basic-buttons-item::before,
.cStyle .tab-basic-buttons-item.is-tab-basic-active,
.cStyle .tab-basic-buttons-item.is-tab-basic-active::before,
.cStyle .tab-basic-buttons-item.is-tab-basic-active + .tab-basic-buttons-item::before {
  border-color: #22b24c;
}

.cStyle .info-table-area::after {
  content: '';
  clear: both;
  display: block;
}

.cStyle .intro-yul {
  height: 168px;
  margin-bottom: 50px;
  background: url(/img/hss_companycontent/asan_content/bg_intro_yul.jpg) no-repeat center center;
}

.cStyle .img-yul01 {
  background-image: url(/img/hss_companycontent/asan_content/img_intro_yul01.jpg);
}

.cStyle .img-yul02 {
  background-image: url(/img/hss_companycontent/asan_content/img_intro_yul02.jpg);
}

.cStyle .box-yul {
  margin-top: 55px;
}

.cStyle .box-yul:first-of-type {
  margin-top: 0;
}

.cStyle .box-left {
  float: left;
  width: 50%;
  padding-right: 20px;
}

.cStyle .box-right {
  float: right;
  width: 50%;
  height: 560px;
  background-repeat: no-repeat;
  background-position: center center;
}

.cStyle .greeting-sTit {
  display: block;
  margin-top: 15px;
  font-size: 30px;
  color: var(--body-clr);
}

.cStyle .greeting-text {
  margin-top: 35px;
  font-size: 18px;
  color: #666;
  line-height: 1.5;
}

.cStyle .sub-title-area + .greeting-text {
  margin-top: 15px;
}

.cStyle .greeting-list {
  margin-top: 5px;
  padding-left: 10px;
  font-size: 18px;
  color: #666;
  line-height: 1.5;
  position: relative;
}

.cStyle .greeting-list:first-of-type {
  margin-top: 15px;
}

.cStyle .greeting-list::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 0px;
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #666;
}

.cStyle .box-history {
  position: relative;
  margin-top: 40px;
}

.cStyle .history-years {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 22px;
  color: #22b24c;
  font-weight: $bold;
}

.cStyle .history-lists {
  padding-left: 270px;
}

.cStyle .history-lists dl {
  position: relative;
  padding-left: 90px;
  padding-bottom: 40px;
}

.cStyle .history-lists dl::before {
  content: '';
  position: absolute;
  top: 5px;
  left: -3px;
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #22b24c;
}

.cStyle .history-lists dl::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  display: block;
  width: 1px;
  height: 100%;
  background: #22b24c;
}

.cStyle .history-lists dl:last-of-type {
  padding-bottom: 0;
}

.cStyle .history-lists dl:last-of-type::after {
  height: 90%;
}

.cStyle .history-lists .list-tit {
  font-size: 16px;
  color: var(--body-clr);
  font-weight: $bold;
}

.cStyle .history-lists .list-txt {
  margin-top: 20px;
  padding-left: 75px;
  font-size: 16px;
  color: var(--body-clr);
}

.cStyle .history-lists .list-txt + .list-txt {
  margin-top: 10px;
}


@media (max-width: $wrapSize) {

  .cStyle .tab-basic-buttons-item.js-tabpanel-active,
  .cStyle .tab-basic-buttons-item.is-tab-basic-active {
    background: none;
    color: #22b24c;
  }

  .cStyle .tab-basic-buttons-item::after {
    border-color: #22b24c;
  }

  .cStyle .intro-yul {
    height: 155px;
    margin-bottom: 25px;
  }

  .cStyle .box-yul {
    margin-top: 30px;
  }

  .cStyle .info-table-area {
    padding-bottom: 40px;
  }

  .cStyle .box-left {
    float: none;
    width: 100%;
    padding-right: 0;
    margin-top: 20px;
  }

  .cStyle .box-right {
    float: none;
    width: 100%;
    height: 350px;
  }

  .cStyle .greeting-sTit {
    font-size: 22px;
  }

  .cStyle .greeting-text {
    margin-top: 15px;
    font-size: 14px;
  }

  .cStyle .greeting-list {
    padding-left: 8px;
    font-size: 14px;
  }

  .cStyle .greeting-list:first-of-type {
    margin-top: 8px;
  }

  .cStyle .greeting-list::after {
    top: 8px;
  }

  .cStyle .box-history {
    margin-top: 25px;
  }

  .cStyle .history-years {
    position: relative;
    font-size: 18px;
  }

  .cStyle .history-lists {
    margin-top: 15px;
    padding-left: 5px;
  }

  .cStyle .history-lists dl {
    padding-left: 25px;
  }

  .cStyle .history-lists dl::before {
    top: 4px;
  }

  .cStyle .history-lists .list-tit {
    font-size: 14px;
  }

  .cStyle .history-lists .list-txt {
    padding-left: 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .cStyle .box-right {
    height: 220px;
    background-size: 100%;
  }

  .cStyle .history-lists dl {
    padding-left: 15px;
  }

  .cStyle .history-lists .list-txt {
    padding-left: 15px;
    margin-top: 15px;
  }

  .cStyle .history-lists .list-txt + .list-txt {
    margin-top: 7px;
  }
}

/* 김재원 추가 */
.margin-b {
  margin-bottom: 40px !important;
}

/* 상품설명 style 추가 */

.list-wrap01 {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid var(--body-clr);
}

.list-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 20%;
  border-bottom: 1px solid var(--disable-clr);
}

.list-item-tit {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  width: 100%;
  max-width: 20%;
  background: #f7f7f7;
  border-right: 1px solid var(--disable-clr);
  font-size: 16px;
  font-weight: $bold;
  text-align: center;
}

.list-item-txt {
  display: block;
  width: 100%;
  min-height: 52px;
  padding: 16px 18px;
  font-size: 16px;
}

.list-item-box {
  width: 100%;
  height: 100%;
  padding: 18px;
  border-bottom: 1px solid var(--disable-clr);
}

@media (max-width: 768px) {

  .list-item-tit,
  .list-item-txt {
    font-size: 14px;
  }
}

/* 스마트 간편한도조회 버튼 추가 */

.btn-smart-wrap {
  position: relative;
  float: left;
  width: 175px;
  margin-left: 10px;
  background: #ff9358;
  height: 45px;
  text-align: center;
  line-height: 48px;
  border-radius: 30px;
  transition: all .5s ease;
}

.btn-smart-wrap:hover {
  margin-left: 20px;
  box-shadow: -10px 0 0 #aaa;
}

.btn-smart {
  color: var(--bg-clr2);
  font-size: 19px;
}

@media (max-width: $wrapSize) {
  html.responsive .btn-smart-wrap {
    display: none;
  }

  html.responsive .result-info-area {
    padding: 155px 16px 60px;
  }
}

/* 접속 대기 화면 */

.dim-white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg-clr2);
  z-index: 900;
  opacity: .6;
}

.loading-wrap {
  width: 600px;
  position: fixed;
  top: 50% !important;
  left: 50%;
  padding: 30px 60px;
  background: var(--bg-clr2);
  border: 2px solid var(--disable-clr);
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.loading-bar-wrap {
  width: 100%;
  height: 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: var(--disable-clr2);
  overflow: hidden;
}

.loading-bar {
  height: 100%;
  background: var(--pos-clr);
}

.loading-wrap span img {
  vertical-align: middle;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .loading-wrap {
    width: 80%;
    padding: 30px 20px;
  }

  .loading-wrap .flex2 {
    width: 100% !important;
    flex-direction: column;
  }
}

.msb-basic {
  font-size: 14px;
  font-weight: $medium;
}

.msb-basic.dot::before {
  content: '';
  background: var(--disable-clr);
  width: 5px;
  height: 5px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
}

.ceo-info-wrap.star-bank .ceo-info .text {
  text-align: center;
}

.ceo-info-wrap.star-bank .ceo-sign img {
  margin: 0;
}

.ceo-info-wrap.star-bank .ceo-sign {
  margin-top: 75px;
}

.ethics-box.star-bank {
  width: 100%;
  padding: 55px 291px 56px 39px;
  background: #f7f7f7 url(/img/star_content/bg_ethics.png);
}

.ethics-box.star-bank .text {
  color: #333;
  font-size: 18px;
  line-height: 30px;
}

.ethics-box.star-bank .text strong {
  font-size: 18px;
}

.ethics-box.star-bank .text + .text {
  margin-top: 5px;
}

.ethics-box.star-bank + .ethics-list.star-bank {
  margin-top: 38px;
}

.ethics-list-text {
  margin-top: 10px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 18px;
  text-indent: -20px;
}

.ethics-list-text::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 15px;
  background: #999;
  border-radius: 50%;
  vertical-align: 3px;
}

.ethics-list-text:first-child {
  margin-top: 0;
}

.slide-box-area.star-bank .slide-box-top {
  padding: 23px 15px 20px;
}

.slide-box-area.star-bank .slide-box-top .sub-title {
  color: #333;
  font-size: 18px;
}

.slide-box-area.star-bank .slide-box-txt {
  height: 213px;
}

.slide-box-area.star-bank .slide-box-txt p {
  font-size: 14px;
}

.slide-box-area.star-bank .slide-box-txt .title {
  margin-bottom: 0;
  font-weight: 500;
}

.slide-box-area.star-bank .slide-box-txt .text {
  margin-top: 28px;
  margin-bottom: 0;
  line-height: 26px
}

.ethics-list.star-bank + .slide-box-area.star-bank {
  margin-top: 58px;
}

.management-philosophy-box.star-bank .management-philosophy dt {
  font-size: 14px;
  font-weight: 500;
}

.management-philosophy-box.star-bank dd.box-style01 {
  height: auto;
  padding: 30px 20px 29px;
}

.management-philosophy-box.star-bank dd.box-style01 .sub-title {
  margin-top: 0;
  line-height: 22px;
}

.management-philosophy-box.star-bank dd.box-style02 {
  height: auto;
  padding: 26px 20px 24px;
}

.management-philosophy-box.star-bank dd.box-style02 .sub-style01 {
  margin-top: 0;
  font-size: 14px;
  line-height: 23px;
}

.management-philosophy-box.star-bank dd.box-style03 {
  padding: 19px 20px 17px;
  background: #fff;
  border: 1px solid #014c9a;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  line-height: 18px;
}

.organize-box img {
  width: 100%;
}

.gnb-link:hover,
.gnb-link:focus {
  color: #339dd7
}

.stat-bank.pc-viewbox {
  display: block;
}

.stat-bank.tablet-viewbox {
  display: none;
}

.stat-bank.mobile-viewbox {
  display: none;
}

.header-tab-item.is-active .header-tab-link {
  color: #339dd7
}

/* .header-count-time-number {color:#229adc;} 230320 접근성으로 삭제*/

@media (max-width: $wrapSize) {

  .swiper-active-switch .main-page-service-tab-button {
    width: 53px;
    background-color: #339dd7;
  }

  @media (max-width: $wrapSize) {
    .stat-bank.tablet-viewbox {
      display: block;
    }

    .stat-bank.mobile-viewbox {
      display: none;
    }

    .stat-bank.pc-viewbox {
      display: none;
    }
  }

  @media (max-width: $wrapSize) {
    .ethics-box.star-bank {
      padding: 43px 238px 44px 39px;
    }
  }

  @media (max-width: 768px) {
    .ethics-box.star-bank {
      padding-right: 175px;
    }
  }

  @media (max-width: 768px) {
    .ethics-box.star-bank .text {
      font-size: 15px;
    }

    .ethics-box.star-bank .text strong {
      font-size: 15px;
    }

    .ethics-box.star-bank {
      padding: 33px 39px 34px;
      background-image: none;
    }

    .ethics-list-text::before {
      width: 3px;
      height: 3px;
      background: #000;
    }

    .ethics-box.star-bank + .ethics-list.star-bank {
      margin-top: 28px;
    }

    .ethics-list.star-bank + .slide-box-area.star-bank {
      margin-top: 38px;
    }
  }

  @media (max-width: 768px) {
    .stat-bank.tablet-viewbox {
      display: none;
    }

    .stat-bank.mobile-viewbox {
      display: block;
    }

    .stat-bank.pc-viewbox {
      display: none;
    }
  }

  @media (max-width: 480px) {

    .management-philosophy-box.star-bank dd.box-style01,
    .management-philosophy-box.star-bank dd.box-style03 {
      margin-top: 9px;
    }
  }
}

/* .search-select-area */


.txt-result01 {
  display: block;
  margin-top: 20px;
  font-size: 24px;
  font-weight: $bold;
  line-height: 28px;
  color: var(--main-clr);
}

.txt-result01 + .txt-result02 {
  margin-top: 10px;
}

.txt-result01 + .txt-result03 {
  margin-top: 15px;
}

.txt-result02 {
  display: block;
  margin-top: 20px;
  font-size: 16px;
  line-height: 19px;
  color: #666;
}

.txt-result02.type02 {
  margin-top: 30px;
}

.txt-result03 {
  font-size: 14px;
  line-height: 16px;
}

// .txt-result01 + .btn-area,
// .txt-result02 + .btn-area,
// .txt-result03 + .btn-area {
//   margin-top: 80px;
// }

/* 오뱅 추가*/

.radio-init {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

i.icon-radio {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background: url(/img/common/btn_radio_off.png) no-repeat center / contain;
  display: table-cell;
}

.radio-init:checked ~ i {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background: url(/img/common/btn_radio_click.png) no-repeat center / contain;
  filter: invert(46%) sepia(79%) saturate(4498%) hue-rotate(166deg) brightness(100%) contrast(97%);
}

.icn-alert::after {
  content: '';
  width: 18px;
  height: 13px;
  margin-top: 0;
  background: url("/img/common/notice_icon_m.png") no-repeat 100% center;
  background-size: 13px;
  display: inline-block;
  vertical-align: middle;
}

.icn-tooltip::after {
  content: '';
  width: 18px;
  height: 13px;
  margin-top: 0;
  background: url("/img/common/tooltip_icon_m.png") no-repeat 100% center;
  background-size: 13px;
  display: inline-block;
  vertical-align: middle;
}

.page-title.resize {
  max-width: 100%;
}

/* 이용안내 swiper */
.ob-guide-wrap {
  position: relative;
  margin-top: 50px
}

.ob-guide-wrap .swiper-wrapper,
.ob-guide-wrap .swiper-slide {
  height: auto !important;
}

.ob-guide-wrap .swiper-button-play-text,
.ob-guide-wrap .swiper-button-pause-text {
  display: block;
  position: absolute;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  color: transparent;
  font-size: 1px;
  line-height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

.ob-guide-wrap .swiper-button-next,
.ob-guide-wrap .swiper-button-prev {
  position: absolute;
  top: 270px;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  border: 1px solid #fff;
  opacity: 0.5;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  overflow: hidden;
}

.ob-guide-wrap .swiper-button-next::before,
.ob-guide-wrap .swiper-button-prev::before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  padding-left: 2px;
  padding-bottom: 2px;
  border: 0 solid #fff;
  border-top-width: 2px;
  border-right-width: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.ob-guide-wrap .swiper-button-next::before {
  -webkit-transform: translate(-75%, -50%) rotate(45deg);
  transform: translate(-75%, -50%) rotate(45deg);
}

.ob-guide-wrap .swiper-button-prev::before {
  -webkit-transform: translate(-25%, -50%) rotate(-135deg);
  transform: translate(-25%, -50%) rotate(-135deg);
}

.ob-guide-wrap .swiper-button-next:hover,
.ob-guide-wrap .swiper-button-prev:hover {
  opacity: 1;
}

.ob-guide-wrap .swiper-button-next {
  right: -60px;
}

.ob-guide-wrap .swiper-button-prev {
  left: -60px;
}

.ob-guide-wrap .swiper-button-next-text,
.ob-guide-wrap .swiper-button-prev-text {
  display: block;
  position: absolute;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  color: transparent;
  font-size: 1px;
  line-height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

.ob-guide-wrap .swiper-scrollbar {
  height: 4px;
  border-radius: 2px;
  background: #fbfbfb;
  overflow: hidden;
}

.ob-guide-wrap .swiper-scrollbar-drag {
  height: 100%;
  border-radius: 2px;
  background: var(--main-clr);
}

.ob-guide-wrap .ob-guideSwipe-control {
  position: absolute;
  top: 309px;
  left: 103px;
  z-index: 1;
}

.ob-guide-wrap .swiper-pagination {
  display: inline-block;
  vertical-align: top;
}

.ob-guide-wrap .swiper-pagination-switch {
  display: inline-block;
  vertical-align: top;
  width: 11px;
  height: 11px;
  border-radius: 8px;
  background: #888;
  margin-right: 10px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
  transition: all .5s ease;
}

.ob-guide-wrap .swiper-pagination-switch.swiper-active-switch {
  background-color: var(--main-clr);
  width: 20px;
}

.ob-guide-wrap .swiper-button-play,
.ob-guide-wrap .swiper-button-pause {
  position: relative;
  vertical-align: top;
  width: 18px;
  height: 16px;
  margin-top: -2px;
  box-sizing: border-box;
  border: 1px solid #666;
  overflow: hidden;
}

.ob-guide-wrap .swiper-button-play::before,
.ob-guide-wrap .swiper-button-pause::before {
  content: '';
  display: block;
  border: 0 solid #666;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ob-guide-wrap .swiper-button-play {
  margin-right: 5px;
}

.ob-guide-wrap .swiper-button-play::before {
  width: 5px;
  height: 5px;
  border-top-width: 1px;
  border-right-width: 1px;
  padding-bottom: 1px;
  padding-left: 1px;
  -webkit-transform: translate(-75%, -50%) rotate(45deg);
  transform: translate(-75%, -50%) rotate(45deg);
}

.ob-guide-wrap .swiper-button-pause::before {
  width: 2px;
  height: 8px;
  border-left-width: 1px;
  border-right-width: 1px;
}

.chk-wrap.acco .label-wrap .chk-label {
  color: #666;
  font-weight: 500;
}

/* 220106 css 추가 */
.tab-area {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 15px 0;
}

.tab-area > li {
  flex: 1;
  min-width: fit-content;
  padding-bottom: 0;
  border: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: var(--cap-clr);
  text-align: center;
  cursor: pointer;
}

.tab-area > li.selected {
  color: var(--main-clr);
}

.tab-area > li + li {
  border-left: 1px solid var(--line-clr3);
}

/* .tab-area > li:last-child {
  border-right: 1px solid #ccc;
}

.tab-area > li.selected {
  border: 1px solid #00397b;
  border-bottom: 0;
  color: #00397b;
} */

.tab-area > li > a {
  width: 100%;
  height: 100%;
  text-decoration: none;
}


/* 다른금융선택 */
.sel-finance {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #ddd;
  margin: 100px 0 70px 0;
}

.sel-finance > .dl {
  position: relative;
  width: calc(100% / 3);
  border-right: 1px solid #ddd;
  padding: 220px 20px 70px 20px;
  text-align: center;
}

.sel-finance > .dl:last-child {
  border: 0;
}

.sel-finance > .dl::before {
  content: '';
  position: absolute;
  top: 70px;
  left: 50%;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  background: var(--bg-clr1);
  transform: translate(-50%, 0);
}

.sel-finance > .dl:nth-of-type(1)::before {
  background: var(--bg-clr1) url(/img/openbank/icon_fin1.png) no-repeat center center;
  background-size: 80%;
}

.sel-finance > .dl:nth-of-type(2)::before {
  background: var(--bg-clr1) url(/img/openbank/icon_fin2.png) no-repeat center center;
  background-size: 75%;
}

.sel-finance > .dl:nth-of-type(3)::before {
  background: var(--bg-clr1) url(/img/openbank/icon_fin3.png) no-repeat 60% center;
  background-size: 80%;
}

.sel-finance > .dl > .dt {
  color: #666;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.sel-finance > .dl > .dd {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

/* DH 추가*/

.dh-bank .ceo-info-img-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.dh-bank .ceo-info-img-wrap .ceo-info-text-box {
  margin-bottom: 30px;
}

.dh-bank .ceo-info-img-wrap .ceo-info-img {
  width: 350px;
}

.dh-bank .ceo-info-img-wrap .title {
  margin-top: 30px;
  font-size: 30px;
  line-height: 1.5;
  color: #3ab54a;
}

.dh-bank .ceo-info-img-wrap .sub-title {
  margin-bottom: 5px;
  font-size: 26px;
}

.dh-bank .ceo-info-img-wrap .text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 30px;
  color: #666;
  text-align: left;
}

.dh-bank .ceo-info-img-wrap + .ceo-info .text:first-child {
  margin-top: 10px;
}

.dh-bank .ceo-info-sign {
  font-size: 18px;
}

.dh-bank .ceo-info-sign strong {
  margin-left: 10px;
  font-size: 20px;
  font-family: '궁서체', GungsuhChe, sans-serif;
}

@media (max-width: $wrapSize) {
  .dh-bank .ceo-info-img-wrap .ceo-info-text-box {
    margin-bottom: 20px;
  }

  .dh-bank .ceo-info-img-wrap .title {
    font-size: 26px;
    color: #3ab54a;
  }

  .dh-bank .ceo-info-img-wrap .sub-title {
    font-size: 20px;
  }

  .dh-bank .ceo-info-img-wrap .text {
    font-size: 13px;
    line-height: 26px;
  }
}

@media (max-width: 768px) {
  .dh-bank .ceo-info-img-wrap {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .dh-bank .ceo-info-img-wrap .ceo-info-img,
  .dh-bank .ceo-info-img-wrap .ceo-info-text-box {
    width: 100%;
  }

  .dh-bank .ceo-info-img-wrap .title {
    margin-top: 0;
    font-size: 18px;
    color: #3ab54a;
  }

  .dh-bank .ceo-info-img-wrap .sub-title {
    font-size: 16px;
  }

  .dh-bank .ceo-info-sign {
    font-size: 16px;
  }

  .dh-bank .ceo-info-sign strong {
    font-size: 18px;
  }

  .version-download-box {
    &.flex {
      .btn-style01 {
        width: 100%;
        margin: 0;
      }
    }
  }
}
