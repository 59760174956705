@charset "utf-8";

.footer-new {
  position: relative;
  background-color: var(--bg-clr2);
  border-top: 1px solid var(--line-clr3);
  z-index: 1;
}

.footer-box {
  width: $wrapSize;
  margin: auto;
  padding: 40px 0;
}

.footer-menu-list {
  @include flex($gap: 12px 16px, $wrap: wrap);

  .footer-menu-item {
    @include flex($gap: 16px);
    position: relative;

    &::after {
      @include size(1px, 14px);
      content: "";
      display: inline-block;
      background: var(--line-clr3);
      vertical-align: middle;
    }

    &:last-child::after {
      display: none;
    }

    a {
      display: inline-block;
      font-size: 14px;
      line-height: 16px;
      color: var(--body-clr);

      &.point {
        font-weight: $bold;
      }
    }
  }
}

.footer-top {
  @include flex($justify: space-between, $align: normal, $gap: 50px);
}

.footer-select-box {
  position: relative;
  margin-left: auto;
  z-index: 1;

  .footer-select-btn {
    @include size(260px, 40px);
    position: relative;
    padding: 0 20px;
    background: var(--bg-clr2);
    border: 1px solid var(--line-clr3);;
    border-radius: 100px;
    font-size: 14px;
    text-align: left;
    z-index: 1;

    &::after {
      @include size(7px, 11px);
      @include bgImg($url: "/img/common/arrow_icon_black.png");
      content: "";
      position: absolute;
      top: 50%;
      right: 20px;
      display: block;
      transform: translateY(-50%) rotate(-90deg);
      transition: all 0.5s ease;
    }

    &:hover {
      filter: brightness(0.97);
    }

    &.on {
      background: var(--bg-clr2);
      border-color: var(--main-clr);
      border-top-color: transparent;
      border-radius: 0 0 24px 24px;
      color: var(--cap-clr);
      text-decoration: none;
      box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
      filter: none;

      &::after {
        transform: translateY(-50%) rotate(-90deg) scaleX(-1);
      }
    }
  }
}

.footer-select-list-new {
  position: absolute;
  bottom: 100%;
  width: 100%;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  padding: 20px 6px 0 16px;
  background: var(--bg-clr2);
  border: 1px solid var(--main-clr);
  border-bottom: none;
  border-radius: 24px 24px 0 0;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
  overflow: hidden;
  transition: all 0.3s ease;

  &::before {
    @include size(calc(100% - 32px), 1px);
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    background: #d9d9d9;
  }

  @at-root .footer-select-btn.on + .footer-select-list-new {
    max-height: 350px;
    opacity: 1;
    visibility: visible;
  }
}

.footer-select-list-new-in {
  max-height: 330px;
  overflow: auto;

  .footer-select-item-new {
    .footer-select-name {
      margin-bottom: 14px;
      font-size: 14px;
      font-weight: $bold;
      line-height: 16px;
      color: var(--tit-clr);
    }

    & + li {
      margin-top: 14px;

      .footer-select-name {
        margin-top: 28px;
      }
    }

    &:last-child {
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      line-height: 16px;
      color: var(--cap-clr);
      outline-offset: -1px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;

    &-thumb {
      background: var(--main-clr);
    }

    &-track {
      background: transparent;
    }
  }
}

.footer-bottom {
  @include flex($justify: space-between);
  margin-top: 30px;

  .footer-address {
    font-size: 13px;
    line-height: 21px;
    color: var(--cap-clr);

    span {
      color: inherit;

      & + span {
        margin-left: 20px;
      }
    }
  }

  .footer-copyright {
    font-size: 13px;
    line-height: 21px;
    color: var(--cap-clr);
  }

  .footer-logo-list {
    @include flex($gap: 0 10px, $wrap: wrap);
  }
}

/*----------------------------------------*\
  || Responsive
\*----------------------------------------*/

@include respond-to ("huge") {
  .footer-box {
    @include responsiveHugeBox;
  }
}

@include respond-to ("medium") {
  // .footer-new {
  //   border-top: 1px solid #d9d9d9;
  // }

  .footer-box {
    padding: 30px 20px 40px;
  }

  .footer-menu-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 3fr));
    gap: 20px 10px;

    .footer-menu-item::after {
      display: none;
    }
  }

  .footer-top {
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
  }

  .footer-select-box {
    width: 100%;

    .footer-select-btn {
      width: 100%;
    }
  }

  .footer-bottom {
    display: block;
    margin-top: 30px;

    .footer-address {
      font-size: 12px;
      line-height: 1;

      span {
        display: block;
        line-height: 18px;

        & + span {
          margin: 0;
        }
      }
    }

    .footer-copyright {
      font-size: 12px;
      line-height: 18px;
    }

    .footer-logo-list {
      justify-content: flex-end;
    }
  }
}
