@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Area Gap / Area Margin
\*----------------------------------------*/

.notice-list-wrap + .search-select-area {
  margin-top: 20px;
}

.result-info-area {
  & + .info-table-area {
    margin-top: 56px;
  }

  & + .document-area {
    margin-top: 40px;
  }
}

.search-table-area,
.search-table-area02,
.info-table-area,
.info-table-area02 {
  width: 100%;
  height: auto;
  margin-top: 40px;
  margin-bottom: 0;
  background-color: var(--bg-clr2);
}

.search-table-area.first-area,
.search-table-area02.first-area,
.info-table-area.first-area,
.info-table-area02first-area {
  padding-top: 50px;
}

.search-table-area table td input,
.search-table-area table td select,
.search-table-area table td button,
.search-table-area02 table td input,
.search-table-area02 table td select,
.search-table-area02 table td button {
  float: left;
  margin-right: 10px;
}

.sub-title-area + .talent-icon-list {
  margin-top: 20px;
}

/*----------------------------------------*\
  || Result Info Area
\*----------------------------------------*/

.result-info-area {
  width: 100%;
  height: auto;
  margin-top: 50px;
  padding-top: 145px;
  text-align: center;
  background: #fff 50% 40px/auto no-repeat;

  &.success {
    background-image: url('/img/common/icon/icon_done.png');

    .list-result {
      margin-top: 36px;
    }
  }

  &.noresult {
    background-image: url('/img/common/icon/icon_error.png');
    background-size: 80px;

    .txt-result01 {
      color: var(--neg-clr);
    }
  }

  &.type02 {
    padding-bottom: 50px;

    .txt-result01 {
      line-height: 1;
      padding-bottom: 0;
    }
  }
}

/*----------------------------------------*\
  || Search Select Area
\*----------------------------------------*/

.search-select-area {
  @include flex($gap: 20px);
  width: 100%;
  height: auto;
  padding: 20px 40px;
  background-color: #f7f7f7;
  border-radius: 20px;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  form {
    @include flex($gap: 20px);
    width: 100%;
  }

  .btn-right-area {
    min-width: 150px;
  }
}

@include respond-to ("medium") {

  .search-select-area,
  .search-select-area form {
    flex-wrap: wrap;
    flex-direction: column;
    padding: 20px;

    .search-select {
      flex-direction: column;
      width: 100%;
    }

    dd,
    .btn-right-area {
      width: 100%;
    }
  }
}

.search-select {
  @include flex($gap: 10px);
  flex: 1;
  height: 100%;

  dd + dt {
    margin-left: 20px;
  }

  dt,
  dt label {
    min-width: 70px;
    font-size: 16px;
    font-weight: $medium;
    line-height: 40px;
  }

  dd {
    flex: 1;

    .select-basic {
      width: 100%;
    }
  }
}

/*----------------------------------------*\
  || Organ Area / 조직도
\*----------------------------------------*/

.organ-area {
  position: relative;
  padding: 50px 100px 90px;
  background: #efefef;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  h4 {
    position: relative;
    width: 115px;
    height: 115px;
    background: var(--main-clr);
    margin: 0 auto 30px;
    border-radius: 100%;
    font-size: 22px;
    line-height: 115px;
    white-space: nowrap;
    color: var(--bg-clr2);
    text-align: center;
    font-weight: $medium;

    strong {
      color: var(--bg-clr2);
      font-weight: $medium;
    }
  }

  h5 {
    margin-bottom: 90px;

    &::before {
      height: 90px;
    }

    strong {
      color: var(--bg-clr2);
      font-weight: $medium;
    }
  }
}

.organ-area h4::before,
.organ-area h5::before,
.organ-side strong::before,
.organ::before,
.organ::after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 1px;
  height: 30px;
  background: var(--disable-clr);
}

.organ-area h5,
.organ-side strong,
.organ-side .org,
.organ h6,
.organ li {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 180px;
  padding: 14px 15px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--bg-clr2);
  font-weight: $medium;
  border-radius: 5px;
  background: #1b569a;
  margin: 0 auto;
}

.organ-side {
  position: absolute;
  top: 195px;
  right: 190px;

  strong {
    margin-bottom: 20px;
  }

  .org {
    margin-bottom: 20px;
    padding: 13px 14px;
    border: 1px solid #1b569a;
    color: var(--body-clr);
    background-color: #e6e8ee;

    p {
      font-size: 18px;
      line-height: 22px;
      color: var(--body-clr);
    }
  }
}

.organ-wp {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.organ {
  width: 200px;
  position: relative;
  margin-top: 30px;

  & + & {
    margin-left: 33px;
  }

  &::before {
    top: auto;
    bottom: 100%;
  }

  &::after {
    top: -30px;
    left: -17px;
    right: -17px;
    width: auto;
    height: 1px;
    margin: 0;
  }

  &:first-child::after {
    left: 50%;
  }

  &:last-child::after {
    right: 50%;
  }

  h6 {
    width: auto;
    padding: 13px 14px;
    border: 1px solid #1b569a;
    color: var(--body-clr);
    background-color: #e6e8ee;
  }

  li {
    margin-top: 10px;
    width: auto;
    padding: 13px 14px;
    border: 1px solid #1b569a;
    font-size: 16px;
    letter-spacing: -1px;
    color: var(--body-clr);
    background-color: var(--bg-clr2);
  }
}

/* 스타저축은행 조직도 */
.organ-wrap.style02.star-bank .organ-area h4::before,
.organ-wrap.style02.star-bank .organ-area h5::before,
.organ-wrap.style02.star-bank .organ-side strong::before,
.organ-wrap.style02.star-bank .organ::before,
.organ-wrap.style02.star-bank .organ::after {
  background-color: #787878;
}

@media (max-width: $wrapSize) {
  .organ-wrap {
    margin-top: 30px;
  }

  .organ-area {
    padding: 20px 15px 20px;

    h4 {
      width: 90px;
      height: 90px;
      margin: 0 0 115px;
      font-size: 16px;
      line-height: 90px;

      &::before {
        height: 115px;
        right: auto;
        margin: 0;
        left: 45px;
      }
    }

    h5 {
      width: 90px;

      &::before {
        height: 10px;
        right: auto;
        margin: 0;
        left: 45px;
      }
    }
  }

  .organ-area h5,
  .organ-side strong,
  .organ-side .org,
  .organ h6,
  .organ li {
    width: auto;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 10px;
    padding: 10px 5px;
  }

  .organ-side {
    top: 130px;
    right: 15px;
    left: 150px;
    width: auto;

    strong::before {
      height: 10px;
    }

    .org p {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .organ {
    width: auto;
    float: none;
    margin: 0 0 0 135px;

    &::before {
      top: 0;
      left: -90px;
      right: auto;
      bottom: -10px;
      height: auto;
    }

    & + & {
      margin-left: 135px;
    }

    h6,
    li {
      line-height: 18px;
    }

    &:last-child::before {
      bottom: auto;
      height: 20px;
    }

    &::after,
    &:first-child::after,
    &:last-child::after {
      top: 20px;
      left: -90px;
      right: 100%;
    }
  }

  .organ-wp {
    display: block;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  // html.responsive {
  // }
}
