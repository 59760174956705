@charset "utf-8";

.header-new {
  position: relative;
  z-index: 2;

  &::before {
    @include size(100%, 1px);
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #f0f0f3;
    z-index: 1;
  }
}

html:has(.main-wrap) .header-new::before {
  display: none;
}

.header-top {
  @include flex($justify: space-between);
  width: $wrapSize;
  margin: auto;
  padding: 20px 0 12px;
}

.header-left {
  @include flex($align: self-end, $gap: 20px);
}

.header-right {
  @include flex($gap: 24px);

  .header-list {
    gap: 8px;

    li {
      @include flex($gap: 8px);

      & + li::before {
        content: '|';
        font-size: 14px;
        font-weight: $thin;
        line-height: 16px;
        color: var(--line-clr3);
      }
    }
  }

  .type-list {
    @include flex;
    @include size(100px, 28px);
    padding: 2px;
    background: var(--line-clr);
    border: 1px solid #ececec;
    border-radius: 100px;
    box-sizing: border-box;

    li {
      @include size(50%, 22px);
      font-size: 12px;
      font-weight: $bold;
      line-height: 22px;
      color: #587474;
      text-align: center;
      border-radius: 100px;

      &.on {
        background-color: var(--main-clr);
        color: var(--bg-clr2);
      }

      a {
        display: block;
      }
    }
  }
}

.header-list {
  @include flex($gap: 20px);

  .header-item a {
    font-size: 14px;
    color: var(--tit-clr);
  }
}

.header-bottom {
  @include flex;
  @include size($wrapSize, 72px);
  margin: auto;

  .header-logo {
    height: 36px;
    margin-right: 45px;

    a,
    img {
      height: 100%;
    }
  }

  .header-menu-list {
    margin-left: auto;
  }
}

.header-menu-list {
  @include flex($gap: 20px);


  .user {
    @include flex($gap: 8px);

    a {
      @include flex($gap: 8px);

      img {
        @include size(26px);
        border: 1px solid #00000014;
        border-radius: 50%;
        transition: all .15s ease;
      }

      .name {
        font-size: 12px;
        color: var(--tit-clr);

        span {
          font-weight: $bold;
          color: inherit;
        }
      }
    }

    .header-count-time-number {
      float: none;
      width: auto;
      min-width: 33px;
      background: none;
      font-size: 12px;
      font-weight: $semi;
      line-height: inherit;
      color: var(--main-clr);
    }
  }

  .search > button,
  .menu > button {
    @include size(36px);
    position: relative;
    display: block;
    transition: all 0.2s ease;

    span {
      transition: all 0.5s ease;
    }
  }

  .search > button {
    &:not(.on):hover {
      animation: bounce 1.2s cubic-bezier(0.22, 1, 0.86, 1.18) infinite;
    }

    .line01 {
      @include size(23px);
      position: absolute;
      right: 8px;
      bottom: 8px;
      display: block;
      background: var(--bg-clr2);
      border: 3px solid var(--tit-clr);
      border-radius: 50%;
    }

    .line02 {
      @include size(10px, 3px);
      position: absolute;
      right: 5px;
      bottom: 7px;
      display: block;
      background: var(--tit-clr);
      border-radius: 20px;
      transform: rotate(45deg);
    }

    &.on .line01 {
      @include size(36px);
      right: 0;
      bottom: 0;
      background: var(--tit-clr);
    }

    &.on .line02 {
      top: 50%;
      left: 50%;
      right: initial;
      bottom: initial;
      width: 18px;
      background: var(--bg-clr2);
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &.on .line03 {
      @include size(18px, 3px);
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      background: var(--bg-clr2);
      border-radius: 20px;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .menu > button {
    [class^=line] {
      @include size(25px, 3px);
      position: absolute;
      top: 25%;
      left: 50%;
      display: block;
      background: var(--tit-clr);
      border-radius: 20px;
      transform: translateX(-50%);
    }

    .line02 {
      top: 50%;
    }

    .line03 {
      top: 75%;
      left: calc(50% + 5px);
      width: 15px;
    }

    &:hover {
      .line01 {
        animation: menuLine .8s cubic-bezier(0.22, 1, 0.86, 1.18) infinite alternate-reverse;
      }

      .line02 {
        animation: menuLine .8s cubic-bezier(0.21, 1.6, 0.73, 0.64) infinite alternate-reverse;
      }

      .line03 {
        animation: menuLine .8s cubic-bezier(0.17, 0.76, 0.92, 1.19) infinite alternate-reverse;
      }
    }
  }
}

@keyframes menuLine {
  0% {
    transform: translateX(-180%);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  80% {
    transform: translateX(-50%);
  }

  100% {
    opacity: 1;
  }
}

/*----------------------------------------*\
  || Responsive
\*----------------------------------------*/

@include respond-to ("huge") {
  .header-top {
    width: 100%;
    padding: 10px 15px;
  }

  .header-bottom {
    width: 100%;
    padding: 0 15px;
  }

  .header-left .header-list,
  .header-right .type-list {
    display: none;
  }
}

 @include respond-to ("medium") {
  .header-new {
    @include flex($align: normal, $justify: space-between);
    width: 100%;
    padding: 10px 20px;

    &::before {
      display: none;
    }
  }

  .header-top {
    display: none
  }

  .header-bottom {
    height: auto;
    padding: 0;
  }

  .header-menu-list {
    .menu > button {
      @include size(32px);
    }

    .search {
      display: none;
    }
  }
}
