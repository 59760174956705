@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || Card Acco
\*----------------------------------------*/

.card-acco-dl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  dt,
  dd {
    font-size: 16px;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

// @include respond-to ("huge") {
//   html.responsive {}
// }
