@charset "utf-8";

/*================================================*/
/*==================== TYPE A ====================*/
/*================================================*/

/*----------------------------------------*\
  || Swiper
\*----------------------------------------*/

.swiper.one .swiper-slide {
  opacity: 1 !important;
}

/*----------------------------------------*\
  || Main Banner
\*----------------------------------------*/

.main-banner-wrap {
  @include size(100%, 447px);
  position: relative;

  .main-banner-list {
    position: absolute;
    inset: 0;
  }
}

.main-banner-item {
  @include size(100%);
  opacity: 0;
  transition: opacity .8s ease;

  &.swiper-slide-active {
    opacity: 1;
  }

  .wrap {
    @include size(100%);
    outline-offset: -1px;

    &:hover,
    &:focus {
      box-shadow: inset 0 0 20px rgb(0 0 0 / 3%);
    }
  }

  .inner {
    @include size($wrapSize, 100%);
    position: relative;
    margin: auto;
    padding-top: 80px;
  }

  span {
    @include ellipsis(2);
    @include slide('h', -20px, 0, .6s);
    max-width: 400px;
    color: var(--tit-clr);
    word-break: auto-phrase;
    letter-spacing: -0.05em;
    opacity: 0;
    transform: translateX(-20px);
  }

  .des {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 32px;
  }

  .title {
    font-size: 46px;
    font-weight: $bold;
    line-height: 56px;
    animation-delay: .2s;
    -webkit-line-clamp: 3;
  }

  img {
    @include slide('h', '20px', 0, .6s, ease, .2s);
    position: absolute;
    right: 335px;
    bottom: 0;
    width: 447px;
    height: 447px;
    opacity: 0;
    transform: translateX(20px);

    &[src*="zmainbnr"] {
      bottom: 0;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.swiper-controller {
  @include flex($gap: 8px);

  @at-root .main-banner-wrap.one & {
    display: none;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @include size(24px);
    @include bgImg($url: '/img/content/main/icon_prev.png');
  }

  .swiper-button-next {
    transform: rotate(180deg);
  }

  .swiper-pagination-bullets {
    @include flex($gap: 8px);
    padding: 0 4px;

    .swiper-pagination-bullet {
      @include size(8px);
      background-color: #8b95a1;
      border-radius: 50%;
      transition: all .3s ease;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);

      &.swiper-pagination-bullet-active {
        width: 24px;
        background-color: var(--main-clr);
        border-radius: 5px;
      }
    }
  }

  .swiper-button-play,
  .swiper-button-pause {
    @include size(24px);
    @include bgImg($url: '/img/content/main/icon_play.png');
    display: block;

    &[aria-pressed] {
      display: none;
    }
  }

  .swiper-button-pause {
    background-image: url('/img/content/main/icon_pause.png');
  }
}

.main-banner-inner-zone {
  @include flex($justify: flex-end);
  @include size($wrapSize, 100%);
  position: relative;
  margin: 0 auto;
  padding: 30px 0;

  .swiper-controller {
    position: absolute;
    left: 5px;
    bottom: 60px;
    z-index: 1;

    .swiper-pagination-bullets {
      .swiper-pagination-bullet-active {
        background-color: var(--body-clr);
      }
    }
  }

  .main-menu-list {
    @include flex($wrap: wrap);
    @include size(285px, 100%);
    position: relative;
    padding: 14px 16px;
    background-color: var(--bg-clr2);
    border-radius: 30px;
    text-align: center;
    z-index: 1;
  }
}

.main-menu-item {
  @include size(50%, calc(100% / 3));

  a {
    @include size(100%);
    @include bgImg($y: 20px, $size: 48px);
    margin: auto;
    padding-top: 80px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: $semi;
    line-height: 16px;
    color: var(--body-clr);
    letter-spacing: -0.05em;
    box-sizing: border-box;

    &:hover,
    &:focus {
      background-color: #f5f7f8;
      animation: mainMenuBounce2 1.2s ease infinite;
    }
  }
}

@keyframes mainMenuBounce2 {
  0%,
  20%,
  80%,
  100% {
    padding-top: 80px;
    background-position: center 20px;
  }

  30% {
    padding-top: 88px;
    background-position: center 12px;
  }

  50% {
    padding-top: 77px;
    background-position: center 23px;
  }

  60% {
    padding-top: 82px;
    background-position: center 18px;
  }
}

/*----------------------------------------*\
  || Priduct List
\*----------------------------------------*/

.main-product-wrap {
  @include flex($align: normal, $justify: space-between);
  @include size($wrapSize, 288px);
  position: relative;
  margin: 60px auto;
  padding: 40px 40px 40px 60px;
  background-color: #f4f7fc;
  border-radius: 30px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 270px;
    background: linear-gradient(90deg, #f4f7fc, #f4f7fcbf 50%, transparent);
    z-index: 2;
  }

  &.less,
  &.less:has(li:only-child) {
    .main-title::after {
      @include size(51px, 68px);
      content: '';
      position: absolute;
      left: 0;
      bottom: -90px;
      background: url('/img/content/main/img_product_point.png');
    }

    .swiper-controller {
      display: none;
    }
  }

  &:has(.main-product-item:nth-child(3)) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 270px;
      background: linear-gradient(-90deg, #f4f7fc, rgba(244, 247, 252, 0.7490196078) 50%, transparent);
      z-index: 2;
    }

    .main-product-item.swiper-slide-active,
    .main-product-item.swiper-slide-next {
      max-width: 520px;
    }
  }

  .main-title {
    @include size(fit-content);
    position: relative;
    margin-top: 20px;
    line-height: 1.7;
    z-index: 2;
    color: var(--tit-clr);
  }

  .swiper-controller {
    gap: 12px;
    position: absolute;
    left: 60px;
    bottom: 60px;
    z-index: 2;

    button {
      @include size(40px);
      background-image: url('/img/content/main/icon_prev_l.png');
    }

    .swiper-current {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      min-width: 35px;
      font-size: 16px;
      font-weight: $regular;
      line-height: 19px;
      color: #64727f;

      span {
        min-width: 10px;
        line-height: inherit;
      }
    }
  }
}

.main-product-list {
  width: 880px;
  height: auto;
}

.main-product-item {
  @include size(auto);
  max-width: 540px;
  opacity: 0;
  transition: all 0.4s ease;

  &:only-child .main-product {
    width: 540px;
  }

  &.swiper-slide-active,
  &.swiper-slide-next {
    max-width: 430px;
    opacity: 1;
  }
}

.main-product {
  @include flex($align: normal, $justify: space-between, $direction: column);
  @include size(100%, 208px);
  @include bgImg($url: '/img/content/main/img_product01.png', $x: right 30px, $y: bottom 30px, $size: 120px);
  position: relative;
  padding: 30px;
  background-color: var(--bg-clr2);
  border-radius: 20px;
  overflow: hidden;
  transition: all .3s ease;
  box-shadow: 0 10px 15px rgb(0 0 0 / 6%);
  box-sizing: border-box;

  &.type01 {
    background-image: url('/img/content/main/img_product01.png');
  }

  &.type02 {
    background-image: url('/img/content/main/img_product02.png');
  }

  &.type03 {
    background-image: url('/img/content/main/img_product03.png');
  }

  &.type04 {
    background-image: url('/img/content/main/img_product04.png');
  }

  &.type05 {
    background-image: url('/img/content/main/img_product05.png');
  }

  &.type06 {
    background-image: url('/img/content/main/img_product06.png');
  }

  &:hover,
  &:focus-within {
    box-shadow: 0 10px 15px rgb(0 0 0 / 12%);
  }

  .tag {
    @include flex($justify: center);
    position: absolute;
    top: 17px;
    right: 17px;
    padding: 7px 11px;
    background-color: #ef4b56;
    border-radius: 20px;
    font-size: 14px;
    font-weight: $bold;
    line-height: 16px;
    color: var(--bg-clr2);

    &.blue {
      background-color: #2e99f8;
    }
  }

  .title {
    @include ellipsis(2);
    max-width: 320px;
    font-size: 24px;
    font-weight: $bold;
    line-height: 30px;
    color: var(--body-clr);
    word-break: auto-phrase;
  }

  .box {
    .top {
      font-size: 14px;
      line-height: 16px;
      color: #64727f;
    }

    .bottom {
      @include flex($gap: 10px);
      margin-top: 4px;
      font-size: 20px;
      font-weight: $bold;
      line-height: 30px;
      color: #ef4b56;

      strong {
        font-size: 24px;
        line-height: inherit;
        color: inherit;
      }
    }
  }
}

/*----------------------------------------*\
  || Band Banner
\*----------------------------------------*/

.main-point-banner-wrap {
  width: $wrapSize;
  margin: 90px auto 60px;

  .main-point-banner {
    @include flex($justify: center);
    @include size(100%, auto);
    position: relative;
    min-height: 80px;
    padding: 25px 25% 30px;
    background-color: #edfbe3;
    border-radius: 20px;
    transition: all .2s ease;

    &::before {
      @include size(0, 100%);
      content: '';
      position: absolute;
      left: 0;
      display: block;
      border-radius: 20px;
      background-color: #fff6;
      opacity: 0;
    }

    &:hover,
    &:focus-within {
      box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
      transform: scale(1.01);
    }

    .txt {
      @include flex($justify: center,$gap: 10px 20px);
      color: #3f8576;
    }

    strong {
      font-size: 22px;
      font-weight: $bold;
      line-height: 26px;
      color: inherit;
      word-break: auto-phrase;
    }

    span {
      font-size: 20px;
      font-weight: $regular;
      line-height: 24px;
      color: inherit;
      word-break: auto-phrase;
    }

    img {
      @include size(133px, auto);
      position: absolute;
      right: 11%;
      bottom: 0;
      display: block;
    }
  }
}

/*----------------------------------------*\
  || Service List
\*----------------------------------------*/

.main-service-wrap {
  width: $wrapSize;
  margin: 60px auto;

  .main-service-list {
    @include flex($align: normal, $gap: 20px);
    margin-top: 40px;
  }
}

.main-service-item {
  position: relative;
  flex: 1;
  max-width: 590px;
}

.main-service {
  height: 118px;
  padding: 30px;
  border-radius: 30px;
  background-color: #f4f7fc;
  transition: all .2s ease;

  .sub {
    display: block;
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: $regular;
    line-height: 19px;
    text-align: center;
    color: #475668;
  }

  .title {
    @include flex($justify: center, $gap: 7px);
    font-size: 20px;
    font-weight: $bold;
    line-height: 24px;
    color: var(--body-clr);

    &::after {
      @include size(8px, 20px);
      @include bgImg($url: '/img/content/main/icon_right.png');
      content: '';
      display: block;
    }
  }

  &:hover,
  &:focus-within {
    box-shadow: 0 3px 5px rgb(229 232 237);
    transform: translateY(-5px);
  }
}

/*----------------------------------------*\
  || Notice Wrap
\*----------------------------------------*/

.main-notice-wrap {
  $color: var(--body-clr);

  @include flex($justify: space-between, $gap: 20px);
  width: $wrapSize;
  margin: 60px auto;

  .main-notice-left,
  .main-notice-right {
    @include size(50%, 264px);
    position: relative;
    border: 1px solid var(--line-clr3);
    border-radius: 30px;
  }

  .main-notice-left {
    @include flex($align: normal, $direction: column, $gap: 45px);
    padding: 40px;

    .main-title {
      color: $color;
    }
  }

  .main-notice-list {
    @include flex($align: normal, $direction: column, $justify: space-between);
    flex: 1;
  }

  .main-notice-item {
    @include flex($justify: space-between);
    line-height: 1;

    a {
      @include ellipsis;
      width: calc(100% - 100px);
      font-size: 16px;
      font-weight: $medium;
      color: $color;

      &:hover,
      &:focus {
        color: var(--tit-clr);
      }
    }

    .time {
      font-size: 16px;
      font-weight: $regular;
      color: #64727f;
    }
  }

  .main-notice-link {
    @include flex($gap: 10px);
    position: absolute;
    top: 45px;
    right: 40px;

    span {
      font-size: 16px;
      font-weight: $bold;
      color: #191f28;
    }

    &::after {
      @include size(20px, 20px);
      @include bgImg($url: '/img/content/main/icon_more01.png');
      content: '';
      display: block;
      transition: all .3s ease;
    }

    &:hover::after,
    &:focus::after {
      animation: noticeLink 1s cubic-bezier(0, 0.32, 0.37, 1.66) infinite;
    }
  }
}

@keyframes noticeLink {
  30% {
    transform: rotate(45deg);
  }

  60% {
    transform: rotate(0deg);
  }
}

/*----------------------------------------*\
  || Sub Banner
\*----------------------------------------*/

.main-notice-right {
  position: relative;

  .swiper-controller {
    position: absolute;
    top: initial;
    left: 40px;
    bottom: 40px;
    transform: none;
    z-index: 1;
  }
}

.main-sub-banner-list {
  position: relative;
  height: 100%;

  .main-sub-banner-item {
    position: relative;
    height: 264px;

    .inner {
      @include size(100%);
      padding: 50px 35px;
      border-radius: 30px;
      outline-offset: -3px;
    }

    span {
      @include ellipsis(2);
      position: relative;
      max-width: 300px;
      line-height: 1.2;
      letter-spacing: -0.01em;
      z-index: 1;
    }

    .title {
      font-size: 27px;
      font-weight: $bold;
      color: var(--body-clr);
    }

    .text {
      margin-top: 12px;
      font-size: 16px;
      color: var(--cap-clr);
    }

    img {
      @include size(170px);
      position: absolute;
      right: 65px;
      bottom: 55px;
    }
  }
}

/*----------------------------------------*\
  || Customer Center
\*----------------------------------------*/

.main-customer-wrap {
  position: relative;
  bottom: -1px;
  margin-top: 60px;
  padding: 20px 0;
  background-color: #f5f7f8;
  z-index: 1;
}

.main-customer-list {
  @include flex($justify: space-between);
  width: $wrapSize;
  margin: auto;

  .main-customer-item {
    flex: 1;
    padding-left: 33px;
    border-left: 1px solid #e5e8e9;

    &:first-child {
      padding-left: 0;
      border-left: none;
    }

    .title {
      font-size: 16px;
      font-weight: $regular;
      line-height: 19px;
      color: #64727f;
    }

    .num {
      display: block;
      margin-top: 6px;
      font-size: 20px;
      line-height: 24px;
      font-weight: $bold;
      color: var(--body-clr);
    }
  }
}

/*================================================*/
/*==================== TYPE B ====================*/
/*================================================*/

/*----------------------------------------*\
  || Main Banner
\*----------------------------------------*/

.type-B {
  $ease: .4s ease;

  .swiper-controller {
    justify-content: center;
    gap: 8px;
    margin-top: 20px;
    transform: none;

    @at-root .two .swiper-controller {
      display: none;
    }

    .swiper-button-play,
    .swiper-button-pause {
      @include size(18px);
      background-image: url('/img/content/main/icon_play_B.png');
    }

    .swiper-button-pause {
      background-image: url('/img/content/main/icon_pause_B.png');
    }

    .swiper-button-prev,
    .swiper-button-next {
      @include size(18px);
    }

    .swiper-pagination-bullets {
      .swiper-pagination-bullet {
        background-color: #aaa;

        &.swiper-pagination-bullet-active {
          background-color: var(--main-clr);
        }
      }
    }
  }

  .main-banner-wrap {
    @include size(auto, 498px);
    padding: 40px 0;

    &:has(.one),
    &:has(.two) {
      height: 460px;
    }

    .swiper-btn-wrap {
      button {
        @include size(75px, 102px);
        @include bgImg($url: '/img/content/main/icon_prev_B.png');
        position: absolute;
        top: 130px;
        left: -20px;
        z-index: 1;
        filter: invert(.6);

        &.swiper-button-next {
          left: initial;
          right: -20px;
          background-image: url('/img/content/main/icon_next_B.png');
        }
      }
    }
  }

  .main-banner-box {
    @include size(1280px, 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    animation: scaleUp $ease forwards;
    overflow: visible;

    &.two {
      .main-banner-item {
        width: 630px !important;
        min-width: initial;
        max-width: initial;
        height: initial !important;
        min-height: initial;
        max-height: initial;
        opacity: 0;

        &.swiper-slide span {
            width: 310px;
        }

        &.swiper-slide-visible {
          opacity: 1;
        }

        .inner {
          padding: 50px 60px;

          &::after {
            @include size(246px);
            top: initial;
            bottom: 0;
            opacity: 1;
          }
        }
      }
    }

    @media (min-width: 1200px) {
      &:not(.two) .less {
        .swiper-slide {
          position: absolute;
          left: 0;
          margin: 0 !important;
          transform: translateZ(0) translateY(-50%) !important;
          opacity: 1 !important;
          transition: none;
        }

        .swiper-slide:not(.swiper-slide-active) {
          width: 220px !important;
          min-width: 220px;
          max-width: 220px;
          height: 280px !important;
          min-height: 280px;
          max-height: 280px;
        }

        .swiper-slide-active {
          left: 240px;
          z-index: 2;
        }

        .swiper-slide-prev {
          left: 0;
        }

        .swiper-slide-next {
          left: 1060px;
          z-index: 1;
        }
      }
    }
  }

  .main-banner-list {
    position: static;
    height: 380px;
  }

  .main-banner-item {
    @include size(220px, 280px);
    position: relative;
    top: 190px;
    opacity: 0;
    transform: translateZ(0) translateY(-50%);
    transition: opacity $ease, height $ease, min-height $ease, max-height $ease;

    &.type01 .inner {
      background-color: #fee8e9;

      &::after {
        background-image: url('/img/content/main/img_main_banner_B01.png');
      }
    }

    &.type02 .inner {
      background-color: #d2e9f6;

      &::after {
        background-image: url('/img/content/main/img_main_banner_B06.png');
      }
    }

    &.type03 .inner {
      background-color: #fff4cd;

      &::after {
        background-image: url('/img/content/main/img_main_banner_B02.png');
      }
    }

    &.type04 .inner {
      background-color: #d4f5df;

      &::after {
        background-image: url('/img/content/main/img_main_banner_B03.png');
      }
    }

    &.type05 .inner {
      background-color: #ffe7cd;

      &::after {
        background-image: url('/img/content/main/img_main_banner_B05.png');
      }
    }

    &.type06 .inner {
      background-color: #efe1ff;

      &::after {
        background-image: url('/img/content/main/img_main_banner_B04.png');
      }
    }

    .inner {
      @include size(100%);
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 30px;
      background-color: #fee8e9;
      border-radius: 20px;
      transition: opacity $ease, height $ease, min-height $ease, max-height $ease;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 400px;
        height: 360px;
        background: no-repeat bottom right / contain;
        opacity: 0;
      }

      .tag {
        align-self: flex-start;
        max-width: fit-content;
        min-height: 24px;
        margin-bottom: 8px;
        padding: 4px 10px;
        background-color: #ffffff99;
        border-radius: 100px;
        font-size: 14px;
        font-weight: $regular;
        line-height: 16px;
        color: var(--tit-clr);
      }

      span {
        width: 160px;
      }

      .title {
        margin-bottom: 9px;
        font-size: 20px;
        font-weight: $bold;
        line-height: 24px;
        word-break: auto-phrase;
        color: var(--tit-clr);
      }

      .des,
      .top {
        @include ellipsis(2);
        font-size: 14px;
        font-weight: $regular;
        line-height: 18px;
        color: #666;
        z-index: 1;
      }

      .interest-box {
        display: block;
        margin-top: auto;

        .bottom {
          @include flex($gap: 6px);
          font-size: 20px;
          font-weight: $bold;
          line-height: 26px;
          color: var(--tit-clr);

          .num {
            font-size: 24px;
            font-weight: 800;
            line-height: 31px;
            color: var(--tit-clr);
          }
        }
      }
    }

    &.swiper-slide-prev,
    &.swiper-slide-next {
      width: 220px !important;
      min-width: 220px;
      max-width: 220px;
      height: 280px !important;
      min-height: 280px;
      max-height: 280px;
      opacity: 1;
    }

    &.swiper-slide-active {
      width: 800px !important;
      min-width: 800px;
      max-width: 800px;
      height: 380px !important;
      min-height: 380px;
      max-height: 380px;
      opacity: 1;
    }

    &:only-child .inner,
    &.swiper-slide-active .inner {
      padding: 50px;

      &::after {
        opacity: 1;
        transition: opacity 0.4s cubic-bezier(0.4, 0, 1, 1) 0.2s;
      }
    }

    @at-root .type-B .main-banner-box.two .main-banner-item,
    &:only-child,
    &.swiper-slide-active {
      .tag {
        @include slide('v', -20px, 0, .4s, ease);
        min-height: 31px;
        margin-bottom: 6px;
        padding: 6px 10px;
        font-size: 16px;
        line-height: 19px;
        opacity: 0;
        transform: translateX(-20px);
      }

      span {
        width: 320px;
      }

      .title {
        @include slide('v', -20px, 0, .4s, ease, .1s);
        font-size: 36px;
        line-height: 42px;
        opacity: 0;
        transform: translateX(-20px);
        -webkit-line-clamp: 3;
      }

      .des {
        @include slide('v', -20px, 0, .4s, ease, .2s);
        flex-shrink: 0;
        opacity: 0;
        transform: translateX(-20px);
      }

      .des,
      .top {
        font-size: 16px;
        line-height: 21px;
      }

      .interest-box {
        @include slide('v', -20px, 0, .4s, ease, .3s);
        opacity: 0;
        transform: translateX(-20px);

        .bottom {
          font-size: 24px;
          line-height: 31px;

          .num {
            font-size: 34px;
            line-height: 44px;
          }
        }
      }
    }

    &:only-child {
      width: 100% !important;
      height: initial;

      .inner {
        background-position: bottom right 100px;
      }

      .des {
        max-width: calc(100% - 500px);
      }
    }
  }

/*----------------------------------------*\
  || Main Menu
\*----------------------------------------*/

  .main-menu-wrap {
    padding: 22px 0 38px;
    background-color: var(--bg-clr1);
  }

  .main-menu-list {
    @include flex($justify: space-around);
    width: $wrapSize;
    margin: auto;
  }

  .main-menu-item {
    @include size(100%);
    @include slide('v', -15px, .3, 1.2s, cubic-bezier(0.17, 0.67, .3, 1.8));
    opacity: .3;
    transform: translateY(-15px);
  }

  @for $i from 1 through 5 {
    .main-menu-item:nth-child(#{$i + 1}) {
      animation-delay: 0.03 * $i + s;
    }
  }

  .main-menu-item a {
    @include size(110px, 96px);
    @include bgImg($url: '/img/content/main/icon_menu_B01.png', $y: top 8px, $size: 62px);
    padding-top: 82px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: $bold;
    text-align: center;
    letter-spacing: initial;
  }

  .main-menu-item a:hover,
  .main-menu-item a:focus {
    animation: mainMenuBounce 1.2s ease infinite;
  }

  @keyframes mainMenuBounce {
  0%,
  20%,
  80%,
  100% {
    padding-top: 82px;
    background-position: center top 8px;
  }

  30% {
    padding-top: 90px;
    background-position: center top;
  }

  50% {
    padding-top: 79px;
    background-position: center top 10px;
  }

  60% {
    padding-top: 86px;
    background-position: center top 4px;
  }
}

  /*----------------------------------------*\
  || Main Contents
\*----------------------------------------*/

  .main-content-wrap {
    padding: 40px 0;
  }

  .main-content-grid-box {
    @include flex($gap: 15px);
    @include size($wrapSize, 200px);
    margin: auto;
  }

  /*----------------------------------------*\
  || Sub Banner
\*----------------------------------------*/

  .main-sub-banner-box {
    @include size(355px, 200px);
    border-radius: 20px;

    .swiper-controller {
      position: absolute;
      justify-content: normal;
      bottom: 30px;
      left: 30px;
      z-index: 1;
    }

    .swiper-pagination-bullets {
      gap: 8px;

      .swiper-pagination-bullet {
        background-color: var(--cap-clr);
      }
    }
  }

  .main-sub-banner-item {
    height: 200px;

    .inner {
      padding: 30px !important;
      background-color: #efeeff !important;
      border-radius: 0 !important;

      span {
        max-width: 195px;
      }

      .title {
        font-size: 20px;
        font-weight: $bold;
        line-height: 24px;
        color: var(--tit-clr);
      }

      .text {
        margin-top: 15px;
        font-size: 16px;
        font-weight: $regular;
        line-height: 19px;
        color: #666;
      }

      img {
        position: absolute;
        top: initial;
        right: 30px;
        bottom: 25px;
        max-width: 90px;
        max-height: 90px;
      }
    }
  }

  /*----------------------------------------*\
  || Customer Center
\*----------------------------------------*/

  .main-customer-box {
    @include size(auto, 200px);
    @include flex($direction: column, $align: normal);
    @include bgImg($url: '/img/content/main/img_customer01.png', $x: right 20px, $y: bottom 25px, $size: 84px);
    flex-grow: 1;
    padding: 30px;
    border: 1px solid var(--line-clr3);
    border-radius: 20px;

    .title {
      @include flex($gap: 10px);
      margin-bottom: 6px;
      font-size: 18px;
      font-weight: $bold;
      line-height: 21px;
      color: var(--tit-clr);

      .bubble {
        position: relative;
        display: inline-block;
        padding: 5px 11px 5px 14px;
        background-color: var(--body-clr);
        border-radius: 20px;
        font-size: 14px;
        font-weight: $regular;
        line-height: 16px;
        color: var(--bg-clr2);

        &::before {
          @include size(0);
          content: '';
          position: absolute;
          top: 8px;
          left: -5px;
          border-style: solid;
          border-width: 5px 8.66px 5px 0;
          border-color: transparent var(--body-clr) transparent transparent;
        }
      }
    }

    .num {
      font-size: 30px;
      font-weight: $bold;
      line-height: 36px;
      color: var(--tit-clr);
    }

    .main-customer-list {
      align-items: flex-end;
      width: calc(100% - 75px);
      margin: auto 0 0;
    }

    .main-customer-item {
      padding: 0;
      border: 0;

      .customer-title {
        font-size: 15px;
        font-weight: $regular;
        line-height: 18px;
        color: #666;
      }

      .customer-num {
        display: block;
        margin-top: 6px;
        font-size: 18px;
        font-weight: $bold;
        line-height: 21px;
        color: var(--tit-clr);
      }
    }
  }

  /*----------------------------------------*\
  || Sub Menu
\*----------------------------------------*/

  .main-sub-menu-list {
    @include size(230px, 200px);
    @include flex($direction: column, $align: normal, $justify: center, $gap: 40px);
    padding: 30px;
    border: 1px solid var(--line-clr3);
    border-radius: 20px;
  }

  .main-sub-menu-item {
    a {
      @include ellipsis;
      @include bgImg($url: '/img/content/main/icon_right.png', $x: right, $size: 7px);
      width: 100%;
      padding-right: 15px;
      font-size: 16px;
      font-weight: $bold;
      line-height: 19px;
      color: #666;
      filter: invert(41%) saturate(1324%) hue-rotate(171deg) brightness(94%);

      &:hover,
      &:focus {
        filter: brightness(.6);
        transition: all .3s ease;
      }
    }
  }

  /*----------------------------------------*\
  || Sub Menu
\*----------------------------------------*/

  .main-notice-band-wrap {
    @include flex($justify: space-between, $gap: 60px);
    @include size($wrapSize, 72px);
    margin: 20px auto 0;
    padding: 0 30px;
    border: 1px solid var(--line-clr3);
    border-radius: 20px;

    .title {
      @include flex($gap: 8px);
      min-width: 155px;
      font-size: 20px;
      font-weight: $bold;
      line-height: 24px;
      color: var(--tit-clr);

      img {
        @include size(32px);
      }

      &::after {
        @include size(20px);
        @include bgImg($url: '/img/content/main/icon_more01.png');
        content: '';
        display: block;
      }
    }

    .swiper-controller {
      @include flex($gap: 4px);
      flex: 0  0 auto;
      position: static;
      margin: 0;

      .swiper-button-prev,
      .swiper-button-next {
        @include size(20px);
        @include bgImg($url: '/img/content/main/icon_left.png', $size: 7px);
      }

      .swiper-button-play,
      .swiper-button-pause {
        background-size: 35px;
        filter: invert(1) brightness(2);
      }

      .line {
        @include size(5px, 18px);
        font-size: 16px;
        line-height: 19px;
        color: var(--line-clr3);
      }
    }
  }

  .main-notice-band-list {
    flex: 1;
  }

  .one .main-notice-band-list {
    width: auto;
  }

  .main-notice-band-item {
    @include flex($justify: space-between);

    * {
      font-size: 16px;
      font-weight: $regular;
      line-height: 19px;
    }

    a {
      @include ellipsis;
      max-width: 720px;
      color: var(--tit-clr);
    }

    .time {
      color: var(--cap-clr);
      text-align: right;
    }
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  .type-B .main-banner-box.two {
    .main-banner-item.swiper-slide span {
      width: auto;
    }

    .swiper-controller {
      display: flex;
    }
  }

  .main-banner-wrap {
    height: auto;

    .main-banner-list {
      position: static;
      height: 382px;
    }
  }

  .main-banner-item {
    .inner {
      width: 100%;
      padding: 30px;
    }

    .des {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }

    .title {
      font-size: 30px;
      line-height: 36px;
      -webkit-line-clamp: 2;
    }

    span {
      max-width: 468px;
    }

    img {
      right: 30px;
      width: 230px;
      height: 230px;
    }

    img[src*=zmainbnr] {
      max-width: 230px;
      max-height: 230px;
    }
  }

  .main-banner-inner-zone {
    position: static;
    width: 100%;
    padding: 0;
    height: auto;

    .swiper-controller {
      top: 172px;
      left: 20px;
      height: 28px;
    }

    .main-menu-list {
      width: 100%;
      display: grid;
      grid-template-columns: 100px 100px 100px;
      gap: 20px calc((100% - 300px) / 2);
      padding: 24px 20px;
    }
  }

  .main-menu-item {
    @include size(100%, 63px);

    a {
      padding-top: 46px;
      background-size: 42px;
      background-position-y: top;

      &:hover,
      &:focus {
        background-color: transparent;
        animation: none;
      }
    }
  }

  .swiper-controller {

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }


  .main-product-wrap {
    @include size(100%, 342px);
    flex-direction: column;
    margin: 0 0 30px;
    padding: 30px;
    border-radius: 0;

    &::before,
    &::after {
      display: none;
    }

    &.less,
    &.less:has(li:only-child) {
      height: 288px;

      li:only-child {
        flex: 1;
        max-width: none;

        .main-product {
          width: 100%;
        }
      }
    }

    .main-title {
      width: auto;
      margin: 0 0 20px;
      line-height: 24px;

      &::after,
      br {
        display: none;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
  }

  .main-product-list {
    position: static;
    width: auto;
  }

  .main-product-item.swiper-slide-active,
  .main-product-item.swiper-slide-next {
    max-width: none;
  }

  .main-product {
    @include size(100%, 178px);
    padding: 20px;
    background-position: right 20px bottom 20px;
    background-size: 80px;

    .tag {
      top: 20px;
      right: 20px;
      padding: 6px 10px;
      font-size: 12px;
      line-height: 14px;
    }

    .title {
      max-width: calc(100% - 110px);
      font-size: 18px;
      line-height: 24px;
      -webkit-line-clamp: 3;
    }

    .box .bottom {
      font-size: 16px;
      line-height: normal;

      strong {
        font-size: 20px;
      }
    }
  }

  .main-product-wrap .swiper-controller {
    position: static;
    justify-content: center;
    margin-top: 20px;
  }

  .main-point-banner-wrap {
    width: 100%;
    margin: 60px 0;

    .main-point-banner {
      justify-content: space-between;
      gap: 20px;
      min-height: 120px;
      padding: 10px 10px 10px 20px;
      border-radius: 0;

      .txt {
        @include ellipsis(3);

        span {
          display: block;
          margin-top: 10px;
        }
      }

      strong {
        font-size: 18px;
        line-height: 21px;
      }

      span {
        font-size: 14px;
        line-height: 16px;
      }

      img {
        position: static;
        width: 27%;
        max-width: 133px;
        min-width: 100px;
      }
    }
  }

  .main-service-wrap {
    width: 100%;
    margin: 60px auto;
    padding: 0 20px;

    .main-service-list {
      gap: 15px;
    }

    .main-service-item {
      max-width: none;
    }
  }

  .main-service {
    padding: 30px 20px;
  }

  .main-service-link {
    padding: 30px 25px;
    border-radius: 20px;
    background-size: 180px;

    .title {
      font-size: 24px;
    }

    .img {
      max-width: 50%;
      min-width: 130px;
    }
  }

  .main-notice-wrap {
    width: 100%;
    margin: 60px auto;
    padding: 0 20px;

    .main-notice-left,
    .main-notice-right {
      height: 234px;
    }

    .main-notice-left {
      gap: 30px;
    }

    .main-notice-right {
      border-radius: 20px;
    }
  }

  .main-sub-banner-list {
    .main-sub-banner-item {
      height: 234px;

      .inner {
        padding: 30px 20px;
        border-radius: 20px;
      }

      .title {
        font-size: 24px;
      }

      span {
        max-width: calc(100% - 160px);
      }

      img {
        @include size(140px);
        top: initial;
        right: 10px;
        bottom: 30px;
        transform: none;
      }
    }
  }

  .main-notice-right .swiper-controller {
    left: 20px;
    bottom: 30px;
  }

  .main-customer-wrap {
    margin-top: 60px;
    padding: 20px;
  }

  .main-customer-list {
    width: auto;

    .main-customer-item {
      margin-right: 10px;
      padding-right: 10px;
    }
  }

  .type-B {
    $padding: 20px;

    .main-banner-wrap,
    .main-banner-wrap:has(.one),
    .main-banner-wrap:has(.two) {
      height: auto;
      padding: 30px 0 40px;

      .swiper-btn-wrap button {
        @include size(54px, 74px);
        top: 103px;
      }
    }

    .main-banner-box {
      width: 100%;
      padding: 0 $padding;
    }

    .main-banner-list {
      height: 300px;
    }

    .main-banner-item {
      position: static;
      transform: none;

      @at-root .type-B .main-banner-list.less .main-banner-item .inner,
      .type-B .main-banner-item .inner,
      &:only-child .inner,
      &.swiper-slide-active .inner {
        padding: 30px;

        &::after {
          @include size(200px);
          top: initial;
          bottom: 0;
          transition-delay: initial;
        }
      }

      &:only-child .inner {
        background-position: bottom right;
      }

      @at-root .type-B .main-banner-list.less .main-banner-item,
      &:only-child,
      &.swiper-slide-prev,
      &.swiper-slide-next,
      &.swiper-slide-active {
        width: 100% !important;
        min-width: auto;
        max-width: initial;
        height: 300px !important;
        min-height: auto;
        max-height: initial;

        .tag {
          min-height: 25px;
          margin-bottom: 8px;
          padding: 4px 10px;
          font-size: 14px;
          line-height: 16px;
        }

        .title {
          width: auto;
          max-width: 480px;
          margin-bottom: 13px;
          font-size: 26px;
          line-height: 31px;
        }

        .des,
        .top {
          width: auto;
          max-width: 480px;
          font-size: 14px;
          line-height: 16px;
        }

        .interest-box {
          width: auto;
        }

        .interest-box .bottom {
          font-size: 16px;
          line-height: 20px;

          .num {
            font-size: 24px;
            line-height: 31px;
          }
        }
      }
    }

    .main-menu-list {
      width: 100%;
    }

    .main-content-grid-box {
      @include size(auto);
      flex-wrap: wrap;
      padding: 0 $padding;
    }

    .main-sub-banner-box,
    .main-sub-menu-list {
      width: calc((100% - 15px) / 2);
    }

    .main-sub-banner-item {
      height: 200px;

      .inner span {
        max-width: calc(100% - 110px);
      }
    }

    .main-customer-box {
      grid-column: 1 / -1;
      order: 1;
      height: auto;
      min-height: 200px;

      .num {
        margin-bottom: 33px;
      }
    }

    .main-notice-band-wrap {
      gap: 30px;
      width: auto;
      margin: 20px $padding 0;

      .swiper-button-prev,
      .swiper-button-next {
        display: block;
      }
    }

    .main-notice-band-list {
      width: calc(100% - 270px);
    }
  }
}

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  .type-B .main-banner-list .main-banner-item.swiper-slide-active .title {
    -webkit-line-clamp: 2;
  }

  .main-product-wrap.less {
    height: 342px;

    .swiper-controller {
      display: flex;
    }
  }

  .main-product-item.swiper-slide-next {
    opacity: 0;
  }

  .main-point-banner-wrap {
    margin: 30px 0 40px;
  }

  .main-service-wrap {
    margin: 40px auto;

    .main-service-list {
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
    }
  }

  .main-service-item {
    width: 100%;
  }

  .main-service {
    height: 88px;
    padding: 20px;
    border-radius: 24px;

    .sub {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 16px;
    }

    .title {
      font-size: 18px;
      line-height: 21px;
    }

    &:hover,
    &:focus-within {
      transform: none;
    }
  }

  .main-notice-wrap {
    flex-direction: column;
    gap: 20px;
    margin: 40px auto;

    .main-notice-left,
    .main-notice-right {
      width: 100%;
      border-radius: 30px;
    }

    .main-notice-left {
      gap: 20px;
      order: 1;
      height: 196px;
      padding: 30px;
    }

    .main-notice-right {
      height: 170px;
    }

    .main-notice-link {
      @include size(24px);
      top: 30px;
      right: 30px;
      gap: 0;

      span {
        @include blind;
      }

      &::after {
        @include size(24px);
      }
    }
  }

  .main-sub-banner-list {
    .main-sub-banner-item {
      height: 170px;

      .inner {
        padding: 30px;
      }

      .title {
        font-size: 20px;
        line-height: 24px;
      }

      .text {
        margin-top: 4px;
        font-size: 14px;
        line-height: 16px;
      }

      img {
        @include size(80px);
        right: 20px;
        bottom: 40px;
      }
    }
  }

  .main-customer-wrap {
    margin-top: 40px;
    padding: 30px 20px;


  }

  .main-customer-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 20px;

    .main-customer-item {
      margin: 0;
      padding: 0;
      border: 0;

      .title {
        font-size: 14px;
        line-height: 16px;
      }

      .num {
        margin-top: 5px;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  .type-B {
    .main-menu-wrap {
      padding: 30px 20px;
    }

    .main-menu-list {
      display: grid;
      grid-template-columns: 100px 100px 100px;
      grid-gap: 32px calc((100% - 300px) / 2);
    }

    .main-menu-item {
      opacity: 1;
      transform: none;
      animation: none;

      a {
        padding-top: 66px;
        background-position-y: top;
        font-size: 13px;

        &:hover,
        &:focus {
          background-color: transparent;
          animation: none;
        }
      }
    }

    .main-content-wrap {
      padding: 30px 0;
    }

    .main-content-grid-box {
      display: flex;
      flex-direction: column;
    }

    .main-sub-banner-box,
    .main-sub-banner-list .main-sub-banner-item {
      width: 100%;
      height: 180px;
    }

    .main-sub-banner-item .inner span {
      max-width: 185px;

      &.text {
        -webkit-line-clamp: 1;
      }
    }

    .main-customer-box {
      width: 100%;
      padding: 25px 10px 25px 25px;
      order: initial;

      .title .bubble {
        padding: 5px 6px;
        font-size: 12px;
      }

      .main-customer-list {
        display: flex;
        flex-direction: column;
        align-items: normal;
        width: auto;
        margin: auto 0 0;
        gap: 20px;
      }
    }

    .main-sub-menu-list {
      width: 100%;
      padding: 25px;
    }

    .main-notice-band-wrap {
      flex-wrap: wrap;
      gap: 20px 0;
      height: 103px;
      padding: 20px 25px;

      .title img {
        @include size(24px);
      }
    }

    .main-notice-band-list {
      width: 100%;
      order: 1;
    }

    .main-notice-band-item {
      height: 19px !important;

      &.swiper-slide {
        opacity: 0;
        transition: all .3s ease;
      }

      &.swiper-slide-active {
        opacity: 1;
      }
    }
  }
}
