@charset "utf-8";

/*----------------------------------------*\
  || || Mixin
\*----------------------------------------*/

@mixin animation(
  $name,
  $duration: 1s,
  $function: ease,
  $delay: 0s,
  $count: 1,
  $direction: normal,
  $mode: forwards,
) {
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $function;
  animation-delay: $delay;
  animation-iteration-count: $count;
  animation-direction: $direction;
  animation-fill-mode: $mode;
}

@mixin fadeIn($duration: 1s, $delay: 0s) {
  @include animation(fadeIn, $duration, ease, $delay)
}

// h: 가로 슬라이드 / v: 세로 슬라이드
@mixin slide(
  $type: 'v',
  $distance: -20px,
  $opacity: 0,
  $duration: 1s,
  $function: ease,
  $delay: 0s,
  $count: 1,
  $direction: normal,
  $mode: forwards,
) {
  @if $type == 'h' {
    @keyframes slide#{$distance} {
      0% {
        transform: translateX($distance);
        opacity: $opacity;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
  } @else if $type == 'v' {
    @keyframes slide#{$distance} {
      0% {
        transform: translateY($distance);
        opacity: $opacity;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  animation-name: slide#{$distance};
  animation-duration: $duration;
  animation-timing-function: $function;
  animation-delay: $delay;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

/*----------------------------------------*\
  || || [공통] Keyframes
\*----------------------------------------*/

@keyframes bounce {

  0%,
  20%,
  80%,
  100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(2px);
  }

  60% {
    transform: translateY(-3px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes flip3D {
  0% {
    transform: perspective(1500px) rotateY(0);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(1500px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(1500px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(1500px) rotateY(360deg) scale(.95);
    animation-timing-function: ease-in;
  }

  100% {
    transform: perspective(1500px) scale(1);
    animation-timing-function: ease-in;
  }
}

/*----------------------------------------*\
  || Is Animating
\*----------------------------------------*/

.is-animating {
  > * {
    opacity: 0;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 0.15s;
        }
      }
  }

  &--vertical {
    > * {
    @include slide($distance: 10px);
    }
  }

  &__item {
    @include slide($distance: -5px);
    opacity: 0;
  }
}
