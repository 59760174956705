@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || My Page
\*----------------------------------------*/

.mypg-wrap {
  width: 100%;
  padding: 40px 0 0;
}

.mypg-picture {
  float: left;
  width: 160px;
  max-height: 233px;
  padding: 36px 28px;
  border: 1px solid var(--line-clr3);
  border-radius: 14px;
  text-align: center;
}

.bth-picture {
  @include size(100%, 104px);
  position: relative;
  max-width: 120px;
  // background: url(/img/common/icon_picture.png) no-repeat center center;
  border: 1px solid var(--line-clr3);
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 38px;
    height: 37px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: url(/img/common/icon_picture01.png) no-repeat center center;
  }
}

.picture-name {
  font-size: 16px;
  color: var(--body-clr);
  padding-top: 36px;
}

.mypg-info {
  float: right;
  width: calc(100% - 180px);
  padding: 30px 1.85%;
  background: #f7f7f7;
  border-radius: 14px;

  .info-list {
    @include flex($justify: space-between);
    width: 100%;
    background: var(--bg-clr2);

    li {
      flex: 1;
      padding: 35px 20px;
      border-right: 1px solid var(--line-clr3);
      display: table-cell;

      &:last-child {
        border-right: none;
      }

      .mb15 {
        font-size: 16px;
      }
    }
  }

  .btn-sm-wh {
    float: right;
  }
}

.info-wtitle {
  display: none;
}

.mypg-info-title {
  float: left;
  font-size: 16px;
}

.info-list {
  span {
    font-size: 16px;
  }

  .info-won {
    font-size: 18px;
    word-break: break-all;
  }
}

.service-content {
  width: 100%;
  border-top: 1px solid var(--line-clr3);
  border-bottom: 1px solid var(--line-clr3);

  li {
    padding: 13px 1.85%;
    width: 50%;
    float: left;
    border-bottom: 1px solid #efefef;
    display: table;

    &:nth-child(2n-1) {
      border-right: 1px solid #efefef;
    }

    .sev-title {
      font-size: 14px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .btn-thin.sm {
    max-width: 60px;
  }
}

.js-swiper-mypage-account-slide {
  padding: 30px 25px 15px;
  background: #efefef;
  border-radius: 14px;
  overflow: hidden;

  .mypg-slick {
    @include flex;
    flex-wrap: wrap;
  }

  .swiper-container {
    margin: 0 -8px;
    overflow: visible;
  }
}

.mypg-slick {
  display: none;
  max-width: 100% !important;
  height: auto !important;

  &.swiper-no-swiping {
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-transition: 0s !important;
    transition: 0s !important;
    margin: 0;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .mypg-slickli {
    position: relative;
    margin-bottom: 15px;
    text-align: center;
    min-height: 391px;
    box-sizing: border-box;
    padding: 0 8px;
    width: 25%;
    z-index: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 8px;
      right: 8px;
      bottom: 0;
      background: var(--bg-clr2);
      z-index: -1;
    }

    &:nth-child(4n+1) {
      clear: both;
    }
  }
}

.mypg-slicktop {
  padding: 30px 0 25px 0;
}

.mypg-title {
  font-size: 24px;
  font-weight: $medium;
}

.mypg-txt {
  font-size: 14px;
  font-weight: $medium;
  color: #666;
  margin-top: 15px;
  display: inline-block;
}

.mypgcontent-txt {
  background: #f7f7f7;
  width: 100%;
  display: table;
  margin-bottom: 20px;
  padding: 60px 0;
  height: 168px;
  max-height: 168px;
}

.mypgcont-title {
  display: table-cell;
  font-size: 36px;
  font-weight: $medium;
  vertical-align: middle;

  > span {
    font-size: 48px;
    color: var(--neg-clr);
    font-weight: $medium;
    letter-spacing: -2.25px;
  }
}

.mypg-subtxt {
  font-size: 14px;
  font-weight: $medium;
  color: var(--cap-clr);
  line-height: 21px;
}

.btn-slick {
  width: 50%;
  border-top: 1px solid var(--disable-clr);
  float: left;
}

.mypg-top {
  padding: 30px 5.79% 24px;
  min-height: 109px;
}

.mypg-middle {
  padding: 0 5.79%;
  min-height: 235px;

  .middle-info {
    li {
      background: #f7f7f7;
      padding: 18px 7.85%;
      width: 100%;
      text-align: left;
      display: block;
      min-height: 84px;

      &:last-child {
        border-top: 1px solid var(--line-clr2);
      }

      &:first-child .won {
        font-size: 18px;
      }

      > p {
        font-size: 16px;
      }
    }

    .won {
      font-size: 20px;
      font-weight: $medium;
      word-break: break-all;
    }
  }
}

.mypg-bottom button {
  width: 50%;
  float: left;
  padding: 15px 0;
  border-top: 1px solid var(--line-clr);
  border-right: 1px solid var(--line-clr);
  font-size: 16px;

  &:last-child {
    border-right: none;
  }
}

.mypg-middle .mypg-subtxt {
  display: block;
  margin: 23px 0 0 0;
}

.mypg-slick .slick-dots li button {
  opacity: 0;
  height: 3px;
}

/*----------------------------------------*\
  || 카드 슬라이더
\*----------------------------------------*/

.slide-section {
  &.table-clone {
    padding: 0 40px;
    position: relative;
  }

  &.is-min-length {

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }

    .card-slider-list {
      width: auto !important;
      font-size: 0;
      line-height: 0;
      text-align: center;
      white-space: nowrap;
    }

    .slide {
      display: inline-block;
      vertical-align: top;
      float: none;
      white-space: normal;
    }
  }

  .slide {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 40px 40px 0;
    border-bottom: 1px solid var(--line-clr2);

    &:last-child {
      border: 0;
    }
  }

  .img-area {
    width: 270px;
    height: 170px;
    margin-right: 30px;

    img {
      display: inline;
      width: 100%;
    }
  }

  .card-img {
    position: relative;
    width: 100%;
    height: 100%;
    -moz-box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.3);
    /*FF 3.5+*/
    -webkit-box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.3);
    /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
    box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.3);
    /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
    filter: progid:DXImageTransform.Microsoft.Shadow(Strength=45, Direction=135, Color=#000000);
    /*IE 5.5-7*/
    border-radius: 5px;
    overflow: hidden;
  }

  .title-area {
    margin-top: 10px;
    color: #7a8999;
    font-size: 18px;
    transition: color 0.25s;
    -webkit-transition: color 0.25s;

    .arrow-txt {
      position: relative;
      display: inline-block;
      padding: 0;
      padding-right: 15px;
      color: #7a8999;
      font-size: 24px;
      font-weight: $regular;
      margin-bottom: 20px;
      border: 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 50%;
        right: 0;
        width: 9px;
        height: 9px;
        box-sizing: content-box;
        border-bottom: 1px solid #7a8999;
        border-right: 1px solid #7a8999;
        margin: -7.5px 0 0 0;
        padding-top: 1px;
        padding-left: 1px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:hover {
        color: var(--body-clr);

        &:before {
          border-color: var(--body-clr);
        }
      }
    }
  }

  .card-over {
    display: table !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    opacity: 0;
    transition: opacity 0.25s;
    -webkit-transition: opacity 0.25s;

    * {
      color: var(--bg-clr2);
    }

    .card-title {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: $medium;
    }

    .card-des li {
      font-size: 12px;
      line-height: 22px;
    }
  }

  .slide:hover .card-over,
  .slide.is-focus .card-over,
  .slide.is-checked .card-over {
    opacity: 1;
  }

  .slide:hover .title-area,
  .slide.is-focus .title-area,
  .slide.is-checked .title-area {
    color: var(--body-clr);
  }

  .swiper-object {
    position: relative;
    padding: 40px 49px 60px;
    overflow: hidden;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-wrapper {
    height: auto !important;
  }

  .swiper-button-next-text,
  .swiper-button-prev-text {
    display: block;
    position: absolute;
    z-index: -1;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    color: transparent;
    font-size: 1px;
    line-height: 1px;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: block;
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    width: 80px;
    height: 100%;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      width: 24px;
      height: 24px;
      margin-top: -42px;
      border-radius: 2px;
      border: 2px solid transparent;
    }
  }

  .swiper-button-next {
    right: 0;

    &:before {
      right: 40px;
      margin-right: -12px;
      border-top-color: var(--body-clr);
      border-right-color: var(--body-clr);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .swiper-button-prev {
    left: 0;

    &:before {
      left: 40px;
      margin-left: -12px;
      border-top-color: var(--body-clr);
      border-left-color: var(--body-clr);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

.card-chk-wrap {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border: 1px solid var(--disable-clr);

  & + .img-area {
    margin-left: 60px;
  }

  .card-chk {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: transparent;
    opacity: 0;
    z-index: 5;
  }

  .card-chk:focus ~ .icon_chk {
    outline: -webkit-focus-ring-color auto 5px;
  }

  .card-chk:checked ~ .icon_chk {
    background: url('../../img/common/check_card_t1.png') no-repeat center;
    filter: hue-rotate(178deg) brightness(1.9);
  }

  .icon_chk {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: var(--bg-clr2);
    border-radius: 3px;
  }
}

.card-slider-list {
  .slide {
    display: block;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

/*----------------------------------------*\
  || 체크카드
\*----------------------------------------*/

.dim-contents {
  &.card-layer {
    width: 920px;

    .card-wrap {
      position: relative;

      .scroll-box {
        max-height: 690px;
        overflow-y: auto;
      }

      .popup-body02 {
        max-height: inherit;
        text-align: left;
        padding: 30px 30px 60px;
      }

      .body-box {
        margin-top: 35px;

        &:first-child {
          margin-top: 0;
        }
      }

      .tit-des {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: $bold;
      }

      .sub-des {
        margin-top: 20px;
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: $bold;
        line-height: 1.3;
      }

      .notice-list {
        .font-088 {
          padding-top: 20px;
          line-height: 23px;
        }

        .notice-i-list {
          margin-top: 0;
        }

        li .notice-i-list {
          padding-left: 0;
        }
      }

      .notice-i-list {
        margin-top: 20px;
        color: var(--body-clr);
      }

      .nostyle-list {
        max-width: $wrapSize;
        margin: 0 auto;

        li {
          margin-top: 3px;
          font-size: 14px;
          line-height: 22px;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .notice-box {
        margin-top: 30px;
        padding: 25px 20px;
        background: #f7f7f7;

        .sub-des {
          margin-top: 0;
        }

        .notice-list {
          padding-top: 0;

          li {
            margin-top: 0;
            padding-left: 10px;
            font-size: 12px;

            a {
              display: inline;
              font-weight: $bold;
            }
          }
        }

        .nostyle-list li {
          font-size: 12px;
        }

        .notice-i-list {
          margin-top: 0;
          font-size: 12px;

          &::before {
            margin-left: -10px;
          }
        }
      }

      .step-ux {
        margin-top: 30px;
        padding: 30px 60px;
        background: #f7f7f7;

        ul {
          display: table;
          width: 100%;

          li {
            position: relative;
            display: table-cell;
            vertical-align: top;
            width: 25%;
            text-align: center;

            &::before {
              content: '';
              display: block;
              width: 16px;
              height: 29px;
              position: absolute;
              top: 40px;
              right: -8px;
              background: url('/img/mypage/card_check_arrow.png') no-repeat center;
            }

            &:last-child::before {
              display: none;
            }

            p {
              padding-top: 128px;
              font-size: 14px;
              line-height: 25px;
              background-repeat: no-repeat;
              background-position: center top;

              &.step-t01 {
                background-image: url('/img/mypage/card_check_step01.png');
                padding-top: 120px;
              }

              &.step-t02 {
                background-image: url('/img/mypage/card_check_step02.png');
              }

              &.step-t03 {
                background-image: url('/img/mypage/card_check_step03.png');
              }

              &.step-t04 {
                background-image: url('/img/mypage/card_check_step04.png');
              }
            }
          }
        }
      }

      .table-type04 {
        th {
          width: 160px;
        }

        td {
          padding: 14px 10px 13px 10px;
        }
      }

      td .notice-list {
        margin-left: 10px;
      }
    }

    .popup-foot01.foot-style01 {
      padding: 30px 0 40px;
    }

    .table-title + .table-wrap {
      margin-top: 15px;

      &.clearfix {
        height: 201px;
      }
    }

    .notice-list + .popup-top-number {
      margin-top: 20px;
    }

    &.paybooc-layer .popup-body02 {
      max-height: inherit;
    }

    &.paybooc-layer .popup-foot01 {
      padding: 40px 30px;
    }
  }
}

/*----------------------------------------*\
  || 주소 찾기
\*----------------------------------------*/

.newaddrwss-search {
  display: table;
  width: 100%;
  margin: 20px 0 10px;
}

.cellb-wrap {
  display: table-cell;
  vertical-align: top;

  &.type01 {
    width: 140px;
    padding-right: 10px;
  }

  &.type03 {
    padding-left: 10px;
    width: 120px;

    button {
      width: 100%;
    }
  }

  select {
    width: 100%;
  }

  input {
    max-width: 100%;
  }
}

/*----------------------------------------*\
  || Kakao Box
\*----------------------------------------*/

.kakao-box {
  background: #f7f7f7;
  padding: 30px 20px;

  .kakao-box-content li {
    line-height: 2;
    font-size: 14px;
  }

}

.kakao-txt {
  font-size: 14px;
  line-height: 1.6;
}

.kakao-last-txt {
  padding: 30px 0 40px;

	span {
		display: block;
		font-size: 14px;

		&:first-child {
			margin-bottom: 12px;
		}
	}
}

/*----------------------------------------*\
  || 마이메뉴
\*----------------------------------------*/

.mymenu-box-top {
  width: 100%;
  background: var(--bg-clr2);
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);

  .mymenu-box-wrap:last-child {
    border-bottom: none;
  }
}

.mymenu-box-wrap {
  width: 100%;
  padding-left: 20px;
  border-bottom: 1px solid #f7f7f7;
}

.list-left-wrap {
  padding: 19px 0 16px;

  li {
    float: left;
    font-size: 14px;

    &:last-child {
      font-weight: $bold;
    }

    &::after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-top: 2px solid var(--disable-clr);
      border-right: 2px solid var(--disable-clr);
      float: left;
      transform: rotate(45deg);
      margin: 3px 10px;
    }

    &:first-child::after {
      display: none;
    }
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .service-content {
      border-color: #efefef;

      li {
        padding: 16px 3.4%;
        width: 100%;
        border-right: none;

        .sev-title {
          font-size: 14px;
        }
      }

      .btn-thin {
        font-size: 12px;
      }

      .btn-thin.sm {
        width: 60px;
        max-width: 80px;
      }
    }

    .mypg-wrap {
      background: var(--bg-clr2);
      padding: 0 16px;
    }

    .mypg-picture {
      padding: 0;
      width: 107px;
    }

    .mypg-info-title {
      display: block;
      width: 100%;
      font-size: 14px;
      margin-bottom: 20px;

      span {
        font-weight: 300 !important;
        color: var(--body-clr) !important;
      }
    }

    .mypg-info {
      background: var(--bg-clr2);
      float: left;
      padding: 0;
      margin-left: 28px;
      width: calc(100% - 135px);

      .info-list {
        display: none;
      }

      .btn-sm-wh {
        float: left;
        font-size: 14px;
      }
    }

    .picture-name {
      display: none;
    }

    .info-wtitle {
      display: block;
      font-size: 18px;
      font-weight: $medium;
      padding-bottom: 20px;
    }

    .mypg-title {
      font-size: 16px;
      color: var(--main-clr);
    }

    .mypg-txt {
      font-size: 13px;
      font-weight: $regular;
      color: var(--body-clr);
    }

    .mypgcont-title > span {
      font-size: 34px;
      font-weight: 800;
    }

    .mypg-subtxt {
      font-weight: $regular;
      font-size: 11px;
    }

    .middle-info p {
      font-size: 15px;
    }

    .middle-info span,
    .middle-info .won {
      font-size: 18px;
      font-weight: 500
    }

    .mypg-bottom button {
      font-size: 15px;
      font-weight: $medium;
      padding: 20px 0;
    }

    .mypg-middle {
      min-height: 226px;

      .middle-info li {
        padding: 18px 6%;
      }
    }

    .js-swiper-mypage-account-slide {
      padding: 20px 15px 20px;

      .swiper-container {
        margin: 0 -12px;
      }

      .swiper-scrollbar {
        position: relative;
        z-index: 10;
        margin-top: 5px;
        height: 2px;
        background: var(--bg-clr2);
        overflow: hidden;
      }

      .swiper-scrollbar-drag {
        height: 100%;
        background: var(--neg-clr);
      }
    }

    .mypg-slick {
      max-width: none !important;

      .mypg-slickli:before {
        left: 12px;
        right: 12px;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
      }

      .mypg-slickli:nth-child(4n+1) {
        clear: none;
      }

      .slick-track {
        padding-bottom: 40px;
      }

      .slick-dots {
        display: table;
        width: 100%;
        background: var(--bg-clr2);

        li {
          display: table-cell;
          font-size: 0;
        }

        .slick-active {
          background: var(--neg-clr);
        }
      }
    }

    .mypg-slickli {
      width: 230px;
      padding: 0 12px;
      box-sizing: content-box;
    }

  }
}

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  .mypg-picture {
    border: 0;
  }

  .dim-contents.paybooc-layer .popup-body02 {
    max-height: 420px;
  }
}
