@charset "utf-8";

/*----------------------------------------*\
  || Info Acco Group
\*----------------------------------------*/

.info-acco-group {
  @include flex($align: normal, $direction: column, $gap: 12px);

  .info-acco-group-item {
    @include size(100%);
    position: relative;
    background: var(--bg-clr2);
    border: 1px solid #d2d4d4;
    border-radius: 20px;

    &:has(.btn[aria-expanded="true"]) {
      border: 2px solid var(--main-clr);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

      .info-acco-list,
      .info-acco-box {
        display: block;
      }

      .info-acco-list {
        padding: 29px;
      }
    }
  }
}

.info-acco-head {
  width: 100%;

  .btn,
  .link {
    @include flex($justify: space-between);
    width: 100%;
    padding: 15px 30px;
    background: #f2f4f6;
    border-radius: 20px;
    transition: border-radius .3s ease;

    &:hover {
      filter: brightness(.98);
    }

    &[aria-expanded="true"] {
      padding: 14px 29px;
      border-radius: 20px 20px 0 0;
      transition: none;
    }

    * {
      font-size: 18px;
      line-height: 21px;
      color: var(--tit-clr);
    }
  }

  .arrow-link {
    font-weight: $medium;
  }
}

.arrow-span {
  @include flex;

  &::after {
    @include size(30px);
    @include bgImg($url: '/img/content/main/icon_down.png', $size: 8px);
    content: '';
    display: inline-block;
    margin-left: 30px;
    background-color: var(--bg-clr2);
    border: 1px solid var(--disable-clr);
    border-radius: 50%;
    box-sizing: border-box;
  }
}

[aria-expanded="true"] {
  .arrow-span::after {
    @include bgImg($url: '/img/content/main/icon_up_w.png', $size: 8px);
    background-color: var(--main-clr);
    border-color: var(--main-clr);
  }
}

@include respond-to ("medium") {
  .info-acco-group .info-acco-group-item {
    border-radius: 14px;

    &:has(.btn[aria-expanded="true"]) .info-acco-list {
      padding: 19px 14px;
    }
  }

  .info-acco-head {

    .btn,
    .link {
      padding: 10px 15px;
      border-radius: 14px;

      &[aria-expanded="true"] {
        padding: 9px 14px;
        border-radius: 14px 14px 0 0;
      }

      * {
        font-size: 16px;
      }
    }
  }

  .arrow-span::after {
    @include size(25px);
    margin-left: 10px;
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || 알아두세요
\*----------------------------------------*/

.slide-box-area.type03 {
  .ui-accordion-list {
    border-top: 0;
  }

  .ui-accordion-head {
    background: var(--bg-clr2);
    border-top: 0;
    border-bottom-color: #000;
  }

  .ui-accordion-opener {
    border: 0;
  }

  .ui-accordion-item.js-accordion-opened {
    .ui-accordion-opener {
      background: var(--bg-clr2);
      border-left: 0;
    }

    .ui-accordion-opener:before {
      border: 2px solid #000;
      border-left: 0;
      border-top: 0;
    }

    .ui-accordion-text {
      color: var(--body-clr);
    }
  }

  .ui-accordion-list.js-ui-accordion-wrap .ui-accordion-text {
    padding-left: 0;
    color: var(--body-clr);
  }

  .ui-accordion-layer {
    border-bottom: 0;
  }

  .slide-box-txt {
    height: auto;
    max-height: none;
    padding: 0 15px;
    overflow-y: initial;

    .payinfo-list {
      border: 0;
    }
  }
}

/*----------------------------------------*\
  || UI Accordion
\*----------------------------------------*/

.ui-accordion-list {
  // border-top: 1px solid var(--body-clr);

  > li:first-child {
    border-top: 1px solid #efefef;
  }

  & + .ui-accordion-list {
    border-top-color: #efefef;
    margin-top: 20px;
  }

  &.js-ui-accordion-wrap {
    .ui-accordion-text {
      color: #868686;
    }

    .ui-accordion-head-sub-agree .ui-accordion-text {
      color: var(--body-clr) !important;
    }
  }
}

.ui-accordion-text-small {
  display: inline-block;
  margin-top: 6px;
}

.ui-accordion-head {
  position: relative;
  padding-right: 61px;
  // border-bottom: 1px solid var(--disable-clr);
  background: #f7f7f7;

  &.last {
    margin-top: 50px;
    // border-top: 1px solid var(--disable-clr);
  }

  &.type02 {
    background: var(--line-clr3);
  }
}

.ui-accordion-text {
  display: block;
  padding: 17px 20px;
  font-size: 22px;
  line-height: 26px;
  font-weight: $bold;
}

.ui-accordion-opener {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 100%;
  box-sizing: content-box;
  border-left: 1px solid var(--disable-clr);
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border: 1px solid #000;
    border-width: 0 2px 2px 0;
    margin: -7px 0 0 -5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.ui-accordion-item.js-accordion-opened {
  .ui-accordion-text {
    color: var(--body-clr);
  }

  .ui-accordion-opener {
    background: var(--cap-clr);

    &::before {
      border-color: var(--bg-clr2);
      margin-top: -2px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
}

.ui-accordion-head-right {
  position: absolute;
  top: 0;
  right: 61px;

  .radio-box.radio-agree-wrap {
    padding: 20px 15px;

    .label-wrap:first-child {

      &::before,
      &::after {
        display: none;
      }
    }

    .chk-label {
      color: #666;
      font-weight: $bold;
    }
  }

  &:last-child {
    right: 0;
  }
}

.ui-accordion-layer {
  position: relative;
  border-bottom: 1px solid var(--disable-clr2);

  &.js-ui-accordion-layer {
    display: none;
    height: 0;
  }

  .component-layer-close-button {
    display: block;
    position: absolute;
    bottom: 100%;
    margin-bottom: 1px;
    right: 0;
    width: 60px;
    height: 60px;
  }

  .slide-box-txt {
    border: 0;
  }
}

.ui-accordion-head-sub-agree {
  background: none;

  .ui-accordion-text {
    font-size: 16px;
    line-height: 20px;
    color: var(--body-clr);
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.ui-accordion-head-right-checkebox {
  padding-bottom: 42px;

  .table {
    clear: both;
    border: 0;
  }
}

/*----------------------------------------*\
  || Small Accordion
\*----------------------------------------*/

.small-accordion-list {
  border: 1px solid var(--disable-clr);
  margin-bottom: 20px;
}

.small-accordion-item {
  & + & {
    border-top: 1px solid var(--disable-clr);
  }

  &.js-accordion-opened {
    .small-accordion-opener::before {
      margin-top: -3px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
}

.small-accordion-head {
  position: relative;
  padding-right: 50px;
}

.small-accordion-text {
  display: block;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 20px;
  color: #666;
  font-weight: $bold;
  text-align: left;
}

.small-accordion-opener {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 54px;
  height: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid #666;
    border-width: 0 2px 2px 0;
    margin: -8px 0 0 -6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.small-accordion-layer {
  position: relative;
  border-top: 1px solid var(--disable-clr);

  &.js-ui-accordion-layer {
    display: none;
    height: 0;
  }

  .component-layer-close-button {
    display: block;
    position: absolute;
    bottom: 100%;
    margin-bottom: 1px;
    right: 0;
    width: 54px;
    height: 50px;
  }

  .phone-slide-txt {
    display: block;
    border: 0;
  }

  .accordion-slide-txt {
    display: block;
    max-width: 100%;
    height: 100px;
    padding: 20px;
    overflow-y: auto;
    border: 0;

    p {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .ui-accordion-list {
      & + & {
        margin-top: 10px;
      }
    }

    .ui-accordion-head {
      padding-right: 51px;
      display: flex;
      justify-content: space-between;

      &.ui-accordion-head-sub-agree {
        padding-right: 0;
      }
    }

    .ui-accordion-text {
      padding: 15px 15px;
      font-size: 12px;
      line-height: 20px;
    }

    .ui-accordion-opener {
      width: 50px;
    }

    .ui-accordion-head-right {
      position: initial;
      right: 51px;

      &:last-child {
        right: 0;
      }

      .table {
        margin: 20px;
      }

      .radio-agree-wrap label {
        line-height: 20px;
      }
    }

    .ui-accordion-layer .component-layer-close-button {
      width: 50px;
      height: 50px;
    }

    .radio-agree-wrap.type02 {
      float: initial;
    }
  }
}
