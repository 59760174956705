@charset "utf-8";

/*----------------------------------------*\
  || Basic Checkbox
\*----------------------------------------*/

.basic-checkbox-wrap {
  position: relative;
  display: inline-block;
  min-width: fit-content;

  input {
    position: absolute;
    inset: 0;
    cursor: pointer;

    &:checked + label::before {
      background-color: var(--main-clr);
    }
  }
}

.basic-checkbox {
  @include flex($gap: 10px);
  font-size: 18px;
  font-weight: $medium;
  line-height: 21px;
  color: var(--tit-clr);

  &::before {
    @include size(24px);
    @include bgImg($url: '/img/common/icon/icon_check_white.png', $size: 10px);
    content: '';
    display: block;
    background-color: var(--disable-clr);
    border-radius: 50%;
    transition: all .15s ease;
  }
}

@include respond-to ("huge") {
  .basic-checkbox {
    gap: 10px;
    font-size: 16px;
    line-height: 19px;

    &::before {
      @include size(18px);
      background-size: 8px;
    }
  }
}

/*----------------------------------------*\
  || Toggle Checkbox
\*----------------------------------------*/

.toggle-checkbox-wrap {
  position: relative;
  display: inline-block;
  min-width: fit-content;

  input {
    @include size(100%);
    position: absolute;

    &:checked + label::before {
      right: 2px;
    }

    &:checked + label::after {
      background-color: var(--main-clr);
    }
  }
}

.toggle-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  font-size: 16px;
  font-weight: $bold;
  line-height: 19px;
  user-select: none;
  cursor: pointer;

  &::before {
    @include size(16px);
    content: '';
    position: absolute;
    top: 2px;
    right: 14px;
    display: block;
    background-color: var(--bg-clr2);
    border-radius: 50%;
    transition: all .2s ease;
    z-index: 2;
  }

  &::after {
    @include size(32px, 20px);
    content: '';
    display: block;
    background-color: var(--disable-clr);
    border-radius: 100px;
    transition: all .2s ease;
  }
}

/*----------------------------------------*\
  || Basic Agree Wrap
\*----------------------------------------*/

.basic-agree-wrap {
  padding: 16px 20px;
  background-color: #fafafb;
  border: 1px solid var(--line-clr3);
  border-radius: 10px;

  & + .basic-agree-list {
    margin-top: 6px;
  }

  .basic-checkbox-wrap {
    width: 100%;
  }

  .basic-checkbox {
    gap: 20px;
  }
}

.basic-agree-list {
  @include flex($direction: column,$align: normal);
  padding: 0 20px;
}

.basic-agree-item {
  .basic-checkbox-wrap {
    @include flex($justify: space-between);
    width: 100%;
    padding: 14px 0;
    border-bottom: 1px solid var(--bg-clr3);

    &.link::after {
      @include size(7px, 13px);
      @include bgImg($url: '/img/common/icon/icon_arrow_right.png');
      content: '';
    }
  }

  .basic-checkbox {
    gap: 20px;
  }
}

@include respond-to ("huge") {
  .basic-agree-wrap {
    padding: 10px 15px;
  }

  .basic-agree-item {
    .basic-checkbox-wrap {
      padding: 10px 0;
    }

    .basic-checkbox {
      gap: 10px;
    }
  }

  .basic-agree-list {
    padding: 0 15px;
  }
}

.search-type-wrap {
  .basic-checkbox {
    &::before {
      @include size(20px);
      @include bgImg($url: '/img/common/icon/icon_check_white.png', $size: 12px);
      border-radius: 4px;
      background-color: var(--disable-clr);
    }
  }

  .basic-inp-wrap {
    width: 100%;
    max-width: 510px;
  }
}

@include respond-to ("medium") {
  .search-type-wrap {
    flex-direction: column;
    align-items: start;
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Chk-basic
\*----------------------------------------*/

.chk-wrap {
  display: inline-block;
  vertical-align: middle;

  &.type01 .chk-basic:checked ~ i {
    background: url('/img/common/icon_chk2.png') no-repeat center / contain;
  }

  @at-root .nick-wrap .chk-wrap {
    height: 40px;
    line-height: 3;
    margin-right: 15px;
  }
}

.check-box-wrap {
  &.type02 {
    .label-wrap {
      width: 24.7%;
      margin-right: 0;
    }

    div {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .label-wrap {
    margin-right: 40px;
  }
}

.label-wrap {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.chk-basic {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;

  &:focus + .chk-out {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:checked ~ i {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background: url('/img/common/icon_chk2.png') no-repeat center / contain;
  }

  &:disabled ~ i {
    background-color: var(--bg-clr3);
  }

  &:checked ~ .icon-chk2 {
    background-image: url('/img/common/icon_chk_dis.png');
  }

  &.type01 {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    cursor: pointer;
    top: initial;
    left: initial;
    opacity: 1;
    z-index: 1;
    background: initial;
  }

  &.white:checked ~ i {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background: url(/img/common/check_card.png) no-repeat center / contain;
  }
}

.chk-out {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;

  &.type01 {
    font-size: 0;
  }
}

i.icon-chk {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url(/img/common/icon_chk_off.png) no-repeat center / contain;
  display: table-cell;
}

.chk-label {
  font-size: 14px;
  color: var(--body-clr);
  display: table-cell;
  padding-left: 10px;
  line-height: 1.2;
  text-align: left;
  vertical-align: middle;

  &.white {
    font-size: 14px;
    color: var(--bg-clr2);
    display: table-cell;
    padding-left: 10px;
    line-height: 1.2;
    text-align: left;
    vertical-align: middle;
  }

  &.phone {
    font-size: 16px;
    font-weight: $medium;
    padding-top: 3px;
  }

  &.phone02 {
    color: #666;
    font-size: 14px;
    font-weight: $medium;
    padding-top: 3px;
  }

  &.type02 {
    color: #666;
    font-size: 14px;
    font-weight: $medium;
    padding-top: 3px;
  }
}

.ods-chk-label {
  font-size: 18px;
  color: var(--body-clr);
  display: table-cell;
  padding-left: 10px;
  line-height: 1.2;
  text-align: left;
  vertical-align: middle;
  font-weight: $bold;
}

input[type="checkbox"].disable {
  & ~ i {
    opacity: 0.5;
  }

  & ~ .chk-label {
    color: var(--cap-clr);
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .chk-label {
      margin-left: 0;
      color: #666666;
      font-size: 12px;
      padding-left: 5px;
    }

    .ods-chk-label {
      margin-left: 0;
      color: var(--body-clr);
      font-size: 16px;
      padding-left: 5px;
      font-weight: $bold;
    }
  }
}
