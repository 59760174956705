@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.agreement-txt-point li {
  font-size: 12px;
  line-height: 18px;
}

.prev-term {
  padding: 8px 20px 20px 20px;
}

/*----------------------------------------*\
  || Slide Box Wrap
\*----------------------------------------*/

.slide-box-wrap {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

/*----------------------------------------*\
  || Slide Box Area
\*----------------------------------------*/

.slide-box-area {
  &.type01 {
    &:not(:first-child) {
      margin-top: 45px;
    }

    .slide-box-top {
      padding: 23px 15px 20px;

      .sub-title {
        color: var(--body-clr);
        font-size: 18px;
      }
    }

    .slide-box-txt {
      height: 213px;

      &.type01 {
        height: 300px;
      }

      p {
        font-size: 14px;
      }

      .title {
        margin-top: 20px;
        margin-bottom: 0;
        color: var(--body-clr);
        font-size: 14px;
        font-weight: $medium;

        &:first-child {
          margin-top: 0;
        }

        & + .termlist-box,
        & + .text {
          margin-top: 27px;
        }
      }

      .text {
        margin-bottom: 0;
      }

      .termlist-box {
        margin-top: 27px;
      }
    }

    .termlist-box li {
      color: var(--body-clr);
      font-size: 14px;
      line-height: 25px;
    }
  }

  &.type02 {
    margin-top: 40px;
    border-top: 1px solid var(--disable-clr);

    &:has(.ui-accordion-list) {
      border-top: none;
    }

    & + &.type02 {
      margin-top: 0;
      border: 0;
    }

    &.qna .slide-box-top .sub-title {
      font-size: 14px;
      margin: 23px 0;
    }

    .btn-right-wrap::after {
      content: '';
      clear: both;
      float: none;
      display: block;
    }

    .table {
      background: var(--bg-clr2);
      border-color: transparent !important;
    }

    .table-cell p {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .label-wrap {
      margin-right: 20px;
      position: relative;

      &:first-child::before {
        position: absolute;
        left: -12px;
        top: 3px;
        content: '( ';
      }

      &:last-child {
        margin-right: 0;
        padding-right: 10px;

        &::after {
          position: absolute;
          right: 0px;
          top: 3px;
          content: ' )';
        }
      }
    }

    .slide-box-top .label-wrap .chk-label {
      color: #666;
      font-weight: $medium;
    }

    .agree-n .label-wrap:first-child::before,
    .agree-n .label-wrap:last-child::after,
    .slide-box-top .label-wrap:first-child::before,
    .slide-box-top .label-wrap:last-child::after,
    .chk-agree .label-wrap:first-child::before,
    .chk-agree .label-wrap:first-child::after {
      display: none;
    }

  }

  &.type03 {
    margin-top: 40px;
  }

  &.agree {
    margin-top: 40px !important;
  }

  &.bottom {
    margin-bottom: 50px;

    h3 {
      margin: 20px 0;
      font-size: 16px;
      color: var(--cap-clr);
    }
  }

  &.only-top .slide-box-top {
    border-bottom: 1px solid var(--disable-clr);
    border-top: 0 !important;
  }

  /* 스타저축은행 */
  &.star-bank {
    .slide-box-top {
      padding: 23px 15px 20px;

      .sub-title {
        color: var(--body-clr);
        font-size: 18px;
      }
    }

    .slide-box-txt {
      height: 213px;

      p {
        font-size: 14px;
      }

      .title {
        margin-bottom: 0;
        font-weight: $medium;
      }

      .text {
        margin-top: 28px;
        margin-bottom: 0;
        line-height: 26px
      }
    }
  }

  &.qna {
    .sub-title {
      font-size: 14px;
      font-weight: $regular;
    }

    .slide-box-txt {
      position: relative;
      height: auto;
      max-height: 1000px;
      padding-left: 60px;
      font-weight: $regular;
      background: #f7f7f7;

      &::before {
        content: 'A';
        top: 30px;
        color: var(--neg-clr);
      }

      p {
        font-weight: $regular;
      }
    }

    .ui-accordion-text::before,
    .slide-box-txt::before {
      position: absolute;
      top: 20px;
      left: 25px;
      content: 'Q';
      color: var(--main-clr);
      font-weight: $medium;
      font-size: 16px;
      line-height: 20px;
    }

    .ui-accordion-list.js-ui-accordion-wrap .ui-accordion-text {
      position: relative;
      padding-left: 60px;
      font-size: 14px;
      line-height: 18px;
      padding-top: 21px;
      padding-bottom: 21px;
      color: var(--body-clr);
      font-weight: $regular;
    }

    .ui-accordion-head {
      background: none;
      border-bottom-color: #efefef;
    }

    .ui-accordion-layer {
      border-bottom-color: #efefef;
    }

    .ui-accordion-opener {
      border-left-color: #efefef;
    }
  }

  &.pay {
    margin-bottom: 60px;

    .slide-box-txt {
      height: auto;
      padding: 40px;
      background: #efefef;
    }

    .document-area {
      background: var(--bg-clr2);
      margin-bottom: 0;
      border: 0;
    }

    .ui-accordion-head {
      background: none;
    }

    .ui-accordion-text {
      position: relative;
      padding-left: 60px;
      font-size: 16px;
      font-weight: $regular;
      line-height: 20px;
      padding-top: 20px;
      padding-bottom: 20px;

      .b-number {
        display: block;
        position: absolute;
        top: 17px;
        left: 20px;
        font-size: 22px;
        line-height: 26px;
        font-weight: $medium;
        margin-right: 10px;
      }
    }

    .table-type03-w .table-th02 {
      background: #fcfcfc;
    }
  }

  .sub-title-area {
    margin-top: 20px;
  }

  .sub-title,
  &.type02 .sub-title {
    color: var(--body-clr);
    font-size: 22px;
  }

  .btn-downlode,
  &.type02 .btn-downlode {
    display: none;
    border-right: 0;
    padding: 23px 20px 23px 40px;
  }

  .btn-slide,
  .btn-slide-active .btn-slide,
  &.type02 .btn-slide,
  &.type02 .btn-slide-active .btn-slide {
    position: static;
    padding: 0;
    height: 60px;
    float: right;
    border-left: 1px solid var(--disable-clr);
    background: #b5b5b5 url("/img/common/icon_select_fff_up.png") no-repeat center;
  }

  .btn-slide-active .btn-slide,
  &.type02 .btn-slide-active .btn-slide {
    background: #f7f7f7 url("/img/common/icon_select_333.png") no-repeat center;
    transform: none;
  }
}

.tts + .slide-box-area {
  margin-top: 40px;
}

/*----------------------------------------*\
  || Slide Box Top
\*----------------------------------------*/

.slide-box-top {
  padding-left: 20px;
  background: #f7f7f7;
  border-top: 1px solid #efefef;

  & + .ui-accordion-list {
    border-top: none;
  }

  & + .notice-list-wrap {
    padding-top: 24px;
  }

  & + .slide-box-txt {
    border-bottom: 1px solid #efefef;
  }

  h3 {
    margin: 20px 0;
    color: var(--cap-clr);
  }

  .sub-title {
    margin: 19px 0;
  }

  .radio-agree-wrap {
    padding: 25px;
  }
}

.agree-title {
  display: inline-block;
  padding: 23px 0 21px;
  font-size: 16px;
  font-weight: $medium;
}

.radio-agree-wrap {
  float: left;
  padding: 22px;

  label {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
}

/*----------------------------------------*\
  || Slide Box TXT
\*----------------------------------------*/

.slide-box-txt {
  position: relative;
  max-width: 100%;
  max-height: 230px;
  overflow-y: auto;
  padding: 30px 20px;
  border-bottom: 1px solid var(--disable-clr);
  background: var(--bg-clr2);

  &.h-auto {
    height: auto;
    min-height: 520px;
  }

  &.radio-box-wrap {
    height: auto;
    padding: 30px 20px 25px 20px;
  }

  h4,
  > p {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.7;
  }

  a {
    display: inline;
  }

  .component-layer-close-button {
    display: block;
    position: absolute;
    top: -60px;
    right: 0;
    width: 60px;
    height: 60px;
  }

  .tit {
    font-size: 14px;
    line-height: 1.7;
    font-weight: $medium;
  }

  .agreement-subtit {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.7;
    font-weight: $medium;
  }

  .agreement-subtext {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.7;
    font-weight: $regular;
  }

  .agreement-num {
    padding-left: 27px;

    li {
      font-size: 13px;
      list-style: dash;
      line-height: 20px;
    }
  }

  .agreement-subtext + .agreement-subtit {
    margin-top: 20px;
  }

  .notice-list.nolist-type2.none li {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  .table-wrap01 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .agreement-subtext + .agreement-definition dl {
    margin-bottom: 10px;
  }

  .agreement-definition dt,
  .agreement-definition dd {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
  }

  .agreement-definition dd {
    width: 94%
  }

  .slide-txt-title {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: $medium;
    line-height: 1.4;
  }
}

.btn-slide-active .sub-title {
  color: var(--cap-clr) !important;
}

.slide-box-txt,
.slide-box-txt p:last-child {
  margin-bottom: 0;
}

.table .table-cell {
  vertical-align: middle;
}

.phone-mark {
  display: block;
  float: left;
  margin-right: 10px;
  line-height: 43px;
}

/*----------------------------------------*\
  || Check Agree
\*----------------------------------------*/

.check-agree-wrap {
  padding: 21px;

  .label-wrap {
    margin-right: 20px;
    font-size: 12px;

    &:nth-of-type(5) {
      margin-right: 0;
    }

    span.chk-label {
      font-weight: $bold;
      color: #666;
    }
  }

  .check-span {
    font-weight: $bold;
    color: #666;
    margin: 3px;
  }
}

.check-box-wrap02 .label-wrap {
  margin-right: 18px;
}

.img-wrap {
  margin-top: 40px;
}

/*----------------------------------------*\
  || Termsconditions
\*----------------------------------------*/

.termsconditions_box {
  font-size: 14px;
  line-height: 1.5;

  .termsconditions_thema01 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: $bold;
    line-height: 1.7;

    &:first-child {
      margin-top: 0;
    }
  }

  .termsconditions_thema02 {
    margin-top: 10px;
    font-size: 15px;
    font-weight: $bold;
  }

  .termsconditions_title01,
  .termsconditions_title02 {
    margin-top: 20px;
    font-size: 14px;
    font-weight: $bold;
    line-height: 1.7;

    & + .termsconditions_tablebox {
      margin-top: 5px;
    }
  }

  .termsconditions_thema01 + .termsconditions_title01,
  .termsconditions_title02 {
    margin-top: 30px;
  }

  .termsconditions_title01:first-child {
    margin-top: 0;
  }

  .termsconditions_text01 {
    font-size: 14px;
    line-height: 1.7;
  }

  .termsconditions_list {
    margin-top: 15px;

    li {
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .termsconditions_caution {
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.7;
  }

  .paymentinfo-wrap {
    margin-bottom: 15px;
  }
}

.termsconditions_tablebox {
  border-top: 1px solid var(--body-clr);
  border-bottom: 1px solid var(--disable-clr);

  & + & {
    margin-top: 15px;
  }

  td .label-wrap:first-child {
    margin-left: 10px;
  }
}

.chk-wrap + .termsconditions_tablebox {
  margin-top: 15px;
}

.termsconditions_table01 {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  color: var(--body-clr);
  border: 0;

  thead tr {
    width: 100%;
    background: #f7f7f7;
  }

  tbody tr:last-child > td {
    border-bottom: none;
  }

  th,
  td {
    vertical-align: middle;
    word-break: break-all;
    padding: 17px 10px 16px 10px;
    font-size: 14px;
    line-height: 20px;
    word-break: auto-phrase;

    &:last-child {
      border-right: none;
    }
  }

  th {
    height: 40px;
    padding: 9px 10px 8px 10px !important;
    font-weight: $bold;
    border-right: 1px solid var(--disable-clr);
    border-bottom: 1px solid var(--disable-clr);
  }

  td {
    height: 50px;
    padding: 14px 10px 13px 10px !important;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #efefef;

    .td-wrap {
      line-height: 20px;
    }
  }
}

.termsconditions_table02 {
  width: 100%;

  tr:last-child {

    th,
    td {
      border-bottom-width: 0px;
    }
  }

  th,
  td {
    border-bottom: 1px solid #efefef;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
    vertical-align: middle;
  }

  th {
    width: 249px;
    padding: 15px 20px 14px 20px;
    background: #f7f7f7;
    border-right: 1px solid var(--disable-clr);
    font-weight: $bold;
    font-size: 14px;
  }

  td {
    height: 50px;
    padding: 6px 20px 4px 20px;
    background: var(--bg-clr2);
  }
}

.termsconditions_box {
  input[type="checkbox"] + label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: url(/img/common/icon_chk_off.png) no-repeat center / contain;
  }

  input[type="checkbox"]:checked + label::after {
    background-image: url('/img/common/icon_chk2.png');
  }

  .chk-wrap .label-wrap {
    label {
      display: inline-block;
      margin: 3px 0 0 28px;
    }

    i {
      float: left;
      margin-top: 0;
    }

    &:first-child {
      margin-left: 10px;
    }
  }

  .label-wrap {
    &:first-child::before &:last-child::after {
      display: none;
    }
  }

  .popup-checkbox-wrap.agree.agree-n,
  .termsconditions_text01 {
    margin-top: 10px;
  }

  i.icon-chk {
    float: left;
  }
}

.termsconditions_textbox .termsconditions_in_list li {
  font-size: 15px;
  line-height: 28px;
  padding-left: 15px;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .termsconditions_box {
      padding-top: 20px;
      border-top: 1px solid #d5d5d5;

      .termsconditions_thema01 {
        margin-top: 20px;
        font-size: 16px;
        font-weight: $bold;

        &:first-child {
          margin-top: 0;
        }
      }

      .termsconditions_title01,
      .termsconditions_title02 {
        margin-top: 20px;
        font-size: 15px;
        font-weight: $bold;
      }

      .termsconditions_text01 {
        margin-top: 15px;
        font-size: 12px;
        line-height: 1.5;
      }

      .termsconditions_list {
        margin-top: 15px;

        li {
          font-size: 12px;
          line-height: 1.5;
        }
      }

      .termsconditions_caution {
        margin-top: 15px;
        font-size: 12px;
        line-height: 1.5;
      }

      .paymentinfo-wrap {
        margin-bottom: 15px;
      }
    }

    .slide-box-top h3 {
      margin: 15px 0;
    }

    .slide-box-txt h4,
    .slide-box-txt > p {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .radio-agree-wrap {
      padding: 15px !important;

      label {
        margin-right: 20px;
        font-weight: $bold;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .slide-box-area {
      &.type02.qna {
        .slide-box-top .sub-title {
          width: 80%;
        }

        .slide-box-txt {
          padding: 15px 20px 15px 60px;

          p {
            margin-bottom: 0;
          }
        }
      }

      &.agree {
        margin-top: 0px !important;
      }

      &.qna .slide-box-txt::before,
      &.qna .ui-accordion-text::before {
        top: 15px;
      }

      &.qna .ui-accordion-text {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    .slide-box-area .btn-slide,
    .slide-box-area .btn-slide-active .btn-slide,
    .slide-box-area.type02 .btn-slide,
    .slide-box-area.type02 .btn-slide-active .btn-slide {
      height: inherit;
    }

    .table-exp.fr {
      font-size: 12px;
      text-align: left;
      float: left !important;
    }
  }
}
