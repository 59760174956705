@charset "utf-8";

/*----------------------------------------*\
  || Basic Table
\*----------------------------------------*/

.basic-table {
  width: 100%;

  thead {
    border-bottom: 1px solid var(--tit-clr);

    th {
      position: relative;
      padding: 0 10px 10px;
      font-size: 15px;
      font-weight: $bold;
      line-height: 19px;
      color: var(--tit-clr);
      vertical-align: middle;
      word-break: keep-all;
      white-space: nowrap;

      & + th::before {
        @include size(1px, 12px);
        content: '';
        position: absolute;
        top: calc(7px / 2);
        left: 0;
        background-color: var(--line-clr3);
      }
    }
  }

  thead + tbody tr:first-child {

    th,
    td {
      padding-top: 20px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--line-clr3);

      &:first-child {

        th,
        td {
          padding-top: 0;
        }
      }
    }

    th {
      padding: 20px 5px;
      font-size: 14px;
      line-height: 18px;
      color: var(--tit-clr);
      text-align: left;
      vertical-align: middle;
    }

    th + td:last-child {
      text-align: right;
    }

    td {
      padding: 20px 5px;
      font-size: 14px;
      line-height: 18px;
      color: #666;
      text-align: center;
      vertical-align: middle;
      word-break: keep-all;

      div,
      span,
      strong {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }
    }
  }

  &.type-ani {
    padding: 0;
    animation: tableTdAni .6s ease forwards;

    @keyframes tableTdAni {
      0% {
        padding: 0;
      }

      100% {
        padding: 20px 15px;
      }
    }

    td div {
      opacity: 0;
      max-height: 0;
      animation: tableTdAni2 .6s ease forwards;

      @keyframes tableTdAni2 {
        0% {
          opacity: 0;
          max-height: 0;
        }

        100% {
          opacity: 1;
          max-height: 40px;
        }
      }
    }
  }

  .tooltip-wrap .btn-tooltip {
    @include size(18px);

    &::before {
      font-size: 12px;
    }
  }

  .notice-list-new li + li {
    margin-top: 5px;
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.table-type01,
.table-type02,
.table-type03,
.table-type04,
.table-type05,
.table-type03-m,
.table-type03-w,
.table-newstyle01 {

  th,
  td {
    border-color: #efefef !important;
  }
}

table.scroll {
  max-height: 400px;
  overflow-x: auto;
  display: block;
}

table th,
table td {
  > button:only-child,
  > a[class*=btn]:only-child {
    margin: auto;
  }
}

/* Hover 표시 행 */
tr.select {
  cursor: pointer;

  &:hover {
    background-color: #e5f5f5;
  }
}

tr.clearfix::after {
  display: none;
}

td .inviter {
  position: absolute;
  top: 40px;
  left: 0;
  display: inline;
  width: 400px;
  height: 40px;
  padding: 11px 10px;
  border: 1px solid var(--disable-clr);
  background: var(--bg-clr2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
}

.type-border {

  th,
  td {
    border: 1px solid var(--disable-clr) !important;
  }
}

.td-box {
  float: right;

  span {
    font-size: 16px;
    vertical-align: baseline;
  }

  .money {
    padding: 0 2px 0 20px;
    font-size: 22px;
    text-align: right;
  }
}

.col-style01 {
  width: 80px;
}

.col-style02 {
  width: 150px;
}

.wide-table td {
  padding: 20px 10px;
}

.scroll-table {
  height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}

.no-bb tr:last-child,
.no-bb tr:last-child th,
.no-bb tr:last-child td {
  border-bottom: none;
}

.txt-paragraph,
.table-infolist02 > li,
.table-numlist > li {
  font-size: 14px;
  color: var(--body-clr);
  line-height: 24px;
  word-break: keep-all;
}

.table-infolist02 > li {
  padding-left: 8px;

  &:before {
    content: '*';
  }
}

.table-numlist > li {
  padding-left: 8px;
  counter-increment: num;

  &:before {
    content: '('counter(num) ')';
  }
}

/*탭안 테이블 아래여백*/
.pc-tab-container.tab-table-bottom .search-table-area,
.pc-tab-container.tab-table-bottom .info-table-area {
  padding-bottom: 35px;
}

.table {
  display: table !important;
}

.table-fixed {
  table-layout: fixed !important;
}

.table-cell {
  display: table-cell !important;
}

/*----------------------------------------*\
  || Search Table Area
\*----------------------------------------*/

.search-table-area {
  &.stbl {
    .input-won ~ .txt {
      margin-left: -42px;
    }

    .list-style li {
      font-size: 16px;
    }
  }

  .vitualKeyCheck {
    float: left;
  }
}

/*----------------------------------------*\
  || Info Table Area
\*----------------------------------------*/

.table-area {
  .info-table-area:first-of-type {
    margin-top: 32px;
  }
}

.info-table-area {
  &.type02 {
    margin-top: 60px;
  }

  &.join-guidebox {
    .notice-list-wrap {
      padding-top: 19px
    }

    .table-wrap01 {
      margin-top: 15px;
    }

    .btn-area {
      padding-top: 35px;
    }
  }
}

/*----------------------------------------*\
  || Table Wrap
\*----------------------------------------*/

.table-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  li {
    width: calc(100% / 8);
    text-align: center;
    height: 50px;
    word-break: break-all;
    line-height: 16px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0 10px;
      font-size: 13px;
      border: 1px solid var(--line-clr);

      &:hover,
      &:focus,
      &.active {
        background-color: var(--main-clr);
        border-color: var(--main-clr);
        color: var(--bg-clr2);

        span {
          color: var(--bg-clr2);
        }
      }
    }
  }
}

.table-title {
  font-size: 18px;
  font-weight: $medium;
  margin-top: 40px;
  padding-bottom: 15px;
}

.pc-tab2 {
  .tab-list {
    color: #666;
    font-weight: $regular;

    a {
      padding: 15px 0;
      font-size: 16px;
    }
  }

  .active-tab {
    font-weight: $medium;
  }
}

/*----------------------------------------*\
  || Wrap 01 / 02
\*----------------------------------------*/

.table-wrap01 {
  // border-top: 1px solid var(--line-clr3);
  // border-bottom: 1px solid var(--line-clr3);

  &.style01 {
    .table-type04 {
      table-layout: fixed;
      // border-bottom: none;
    }
  }

  & + & {
    margin-top: 15px;

    &.type01 {
      margin-top: 27px;
    }
  }

  &:has(+.table-type01-foot) {
    border-radius: 20px 20px 0 0;
  }

  // & + .table-type01-foot {
  //   border-top: 0;
  // }

  & + .xflow {
    overflow-x: visible;
  }

  .common-text01 {
    margin-top: 0;

    & + .common-text01 {
      margin-top: 5px;
    }
  }

  .tag {
    position: relative;

    &::before {
      content: '전용계좌';
      position: absolute;
      top: 50%;
      left: -64px;
      transform: translateY(-50%);
      padding: 3px 6px;
      background: #fff;
      border: 1px solid var(--main-clr);
      border-radius: 17px;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      color: var(--main-clr);
    }

    &.blue::before {
      border-color: var(--pos-clr);
      color: var(--pos-clr);
    }
  }
}

.common-textstyle03 + .table-wrap01 {
  margin-top: 20px;
}

.table-wrap02 {
  margin-bottom: 20px;
  padding-top: 25px;
  background: url(/img/common/bg_logo.png) no-repeat center;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

/*----------------------------------------*\
  || Type 01
\*----------------------------------------*/

.table-type01 {
  width: 100%;
  table-layout: auto;
  text-align: center;
  color: var(--body-clr);

  &:not(.table-fixed) colgroup {
    display: none;
  }

  // &.i-tbl02 {
  //   width: inherit;
  // }

  &.large-table {
    td {
      padding: 30px 20px;
    }

    .td-line {
      line-height: 20px;
    }
  }

  &.loan {
    .se-td {
      border-right: none;

      &:first-child {
        text-align: left;
        padding: 40px 30px 0 !important;
        vertical-align: top;
      }

      &:nth-child(2),
      &:nth-child(3) {
        padding-top: 30px !important;
        padding-bottom: 27px !important;
      }
    }

    th.w-44 {
      width: 44%;
    }

    th.w-21 {
      width: 21%;
    }

    th.w-130 {
      min-width: 130px;
    }
  }

  &.agree-table tr td {
    line-height: 1.6;
    word-break: keep-all;

    &:last-child {
      text-align: left;
    }
  }

  &.table-bor-type02 {
    tbody tr:last-child > td {
      border-bottom: 1px solid #efefef;
    }

    // th:last-child,
    // td:last-child {
    //   border-right: 1px solid var(--disable-clr);
    // }
  }

  &.pad-type3 td {
    padding: 20px;
  }

  &.pad-type4 td {
    padding: 14px;
  }

  &.table-type02.table-pdxs tbody td,
  &.table-type02.table-pdxs tbody th {
    padding: 18px 0;
  }

  thead tr {
    width: 100%;
    background: #f7f7f7;
  }

  th,
  td {
    width: auto;
    border: 1px solid #efefef;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    word-break: keep-all;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  thead th {
    border-bottom: none;
  }

  th {
    height: 40px;
    padding: 9px 10px 8px 10px;
    font-weight: $bold;
    white-space: nowrap;
  }

  td {
    height: 50px;
    padding: 14px 10px 13px 10px;

    .td-wrap {
      line-height: 20px;
    }
  }

  .table-td {
    font-weight: $regular;
    background: #fcfcfc;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
  }

  .child {
    background: #efefef;

    td {
      padding: 0;
    }

    .td-wrap {
      margin: auto;
      padding: 40px 130px;
    }

    .table {
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .input-basic {
        max-width: 100%;
      }
    }

    .table-cell {
      width: 50%;
      text-align: left;

      &.left {
        padding-right: 20px;
      }

      &.right {
        padding-left: 20px;
      }
    }

    .ses-title {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: $bold;
    }

    .btn-area {
      border-top: 1px solid var(--line-clr3);
      padding-top: 20px;
      margin-top: 20px;
    }
  }

  &.wide-table-type01 td {
    padding: 22px 10px 21px;
    line-height: 28px;
  }
}

.table-type01-foot {
  padding: 16px 20px 16px 0;
  background: #f7f7f7;
  border: 1px solid #efefef;
  border-top: none;
  text-align: right;

  &::after {
    content: '';
    display: table;
    clear: both
  }
}

.td-point-txt {
  display: block;
  margin-top: 4px;
  line-height: 26px;
}

.td-wrap .td-point-txt {
  & + .link {
    margin-bottom: 3px;
    line-height: 20px;
  }

  & + .td-txt {
    margin-bottom: 3px;
  }

  .td-txt {
    display: inline-block;
    line-height: 20px;
  }
}

.se-td .td-point-txt + .td-wrap {
  margin-bottom: 3px;
}

.child.session .table .table-cell {
  vertical-align: top;
}

// .tbl-lineWp .table-type01 tr:last-child th:last-child {
//   border-right: 1px solid var(--disable-clr);
// }

.tbl-blineWp .table-type01 tr:last-child td {
  border-bottom: none;
}

@include respond-to ("huge") {
  .content .table-type01,
  html.responsive .table-type01 {
    table-layout: fixed;

    th,
    td {
      font-size: 12px;
      line-height: 16px;
      word-break: break-all;
      white-space: normal;
    }
  }
}

/*----------------------------------------*\
  || Type 02
\*----------------------------------------*/

.table-type02 {
  td {
    padding: 22px 10px;
  }

  tr:last-child > .table-th {
    border-bottom: 1px solid var(--disable-clr);
  }

  .table-th {
    font-weight: $medium;
    background: #f6f6f6;
    border-color: #ededed
  }
}

/*----------------------------------------*\
  || Type 03 / Type 04
\*----------------------------------------*/

.table-type03 td {
  width: 25%;
}

.table-type03-m {
  display: none;

  td {
    background: var(--bg-clr2);
  }
}

.table-type03-w,
.table-type04 {
  width: 100%;

  th,
  td {
    padding: 20px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
    vertical-align: middle;
  }

  th {
    width: 249px;
    padding: 15px 20px 14px 20px;
    background: #f7f7f7;
    // border-right: 1px solid var(--disable-clr);
    font-weight: $bold;
    font-size: 14px;

    label {
      font-weight: $bold;
    }
  }

  td {
    min-height: 50px;
    padding: 20px;
    background: var(--bg-clr2);
  }

  // tr:last-child {
  //   th,
  //   td {
  //     border-bottom-width: 0px;
  //   }
  // }

  .trn-notice-i-list04 {
    margin-top: 0;
    padding-top: 15px;
  }
}

.table-type03-w {

  th,
  td {
    width: 30.09%;
  }

  // th[rowspan] {
  //   border-right: 1px solid #efefef;
  // }

  td {
    width: 520px;
  }

  @at-root .document-area .table-type03-w .table-th02 {
    background: #fcfcfc;
  }
}

.table-type04 {
  &.pd-type0 td {
    padding: 0;
  }

  &.table-fixed.i-tbl {
    width: inherit;
  }

  &.style01 td,
  &.style01 th {
    width: auto;
  }

  &.set-table {
    tr {
      .td-title {
        font-size: 16px;
        font-weight: $medium;
      }

      .td-txt {
        font-size: 14px;
        margin-top: 20px;
      }

      .td-txt.type02 {
        margin-top: 15px;
      }

      .td-txt.type03 {
        margin-top: 0;
        line-height: 25px;
      }

      td:nth-child(3) {
        border-left: 1px solid var(--disable-clr);
        text-align: center;
        font-weight: $medium;
      }

      td:nth-child(3) .btn-sm-wh {
        font-weight: $regular;
      }
    }

    td {
      padding: 20px;
    }
  }

  td.dep {
    padding: 10px 20px 5px;
  }

  .bbs-head {
    width: 100%;
  }
}

.table-type04-foot {
  border-top: 1px solid #efefef;
  background: #f7f7f7;
  text-align: right;
  padding: 12px 20px;
  border-bottom: 1px solid #efefef;

  .btn-clickbox {
    width: 100%;
    display: inline-block;
    padding-top: 0;
    text-align: left;
    position: relative;
    left: 0px;
    top: 0px;
  }
}

/*----------------------------------------*\
  || Type 05
\*----------------------------------------*/

.table-type05 {

  th,
  td {
    padding: 0 0 25px 20px;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
    color: var(--body-clr);
  }

  th {
    font-weight: $bold;
    color: var(--tit-clr);
  }
}

/*----------------------------------------*\
  || Style 01
\*----------------------------------------*/

.table-style01 {
  position: relative;
  width: 100%;
  table-layout: fixed;
  text-align: center;
  color: var(--body-clr);

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    width: 1px;
    height: 101%;
    z-index: 20;
  }

  thead tr {
    width: 100%;
    background: #f7f7f7;

    // th:last-child,
    // td:last-child {
    //   border-right: none;
    // }
  }

  // tbody tr:last-child {
  //   th,
  //   td {
  //     border-bottom: none;
  //   }
  // }

  th,
  td {
    vertical-align: middle;
    word-break: break-all;
    padding: 17px 10px 16px 10px;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #eee;

    // .b-noline {
    //   border-bottom: 1px solid var(--bg-clr2);
    // }
  }

  th {
    border-bottom: none !important;
    font-weight: $bold;
    // border: 1px solid var(--disable-clr);
    // border-top: none;

    // &:first-child,
    // &:last-child {
    //   border-right: none;
    // }
  }

  td {
    border-color: #eee !important;
    // border: 1px solid #efefef;
    // border-top: none;

    // &:first-child {
    //   border-left: none;
    // }

    &.tal {
      text-align: left;
    }

    .td-wrap {
      line-height: 20px;
      font-weight: $regular;
    }
  }

  .pad-type4 {
    td {
      padding: 14px;
    }
  }

  .large-table {
    td {
      padding: 30px 20px;
    }

    .td-line {
      line-height: 20px;
    }
  }
}

/*----------------------------------------*\
  || Table New Style
\*----------------------------------------*/

.table-newstyle01 {
  width: 100%;

  thead th {
    height: 40px;
    border-bottom: 1px solid var(--disable-clr);
    border-left: 1px solid var(--disable-clr);
    background: #f7f7f7;
    color: var(--body-clr);
    font-size: 14px;
    font-weight: $bold;
    text-align: center;
    vertical-align: middle;

    &:first-child {
      border-left: none;
    }
  }

  tbody th {
    height: 50px;
    border-top: 1px solid #efefef;
    border-left: 1px solid #efefef;
    background: #f7f7f7;
    color: var(--body-clr);
    font-size: 14px;
    font-weight: $bold;
    text-align: center;
    vertical-align: middle;

    &:first-child {
      border-left: none;
    }

    &.thsub {
      background: #fcfcfc;
      font-size: 300;
    }
  }

  tbody td {
    height: 50px;
    border-top: 1px solid #efefef;
    border-left: 1px solid #efefef;
    color: var(--body-clr);
    font-size: 14px;
    font-weight: $regular;
    text-align: center;
    vertical-align: middle;

    &:first-child {
      border-left: none;
    }

    &.text-type01 {
      padding: 15px 5px 13px 42px;
      font-weight: $bold;
      text-align: left;
      line-height: 25px;
    }
  }
}


/*----------------------------------------*\
  || Table custom
\*----------------------------------------*/

.table-type02-foot {
  padding: 0;

  .td-box {
    &.width2 {
      width: 50%;
    }

    &.line {
      position: relative;

      &::after {
        content: '';
        width: 1px;
        height: 20px;
        background: var(--disable-clr);
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }
    }

    &.width4 {
      width: 33%;
      padding: 18px 13px;

      &:nth-child(2),
      &:nth-child(3) {
        width: 17%;
      }

      .tal {
        width: auto;
        float: left;
      }

      .td-box02 {
        width: auto;
        float: right;

        .money {
          padding: 0
        }
      }
    }
  }

  .td-box {
    width: calc(35.1% - 22px);
    float: left;
    padding: 18px 20px;
  }

  .td-box02 {
    float: right;
    width: 65%;
  }

  .tal {
    width: 34%;
    display: inline-block;
    text-align: left;
    padding-top: 4px;
  }
}

.table-wrap01:has(+.table-type03-foot) table {

  th,
  td {
    border: 1px solid #efefef;
  }
}

.table-type03-foot {
  @include flex;
  padding: 20px;
  position: relative;
  border-color: #efefef;

  &:has(.btn-clickbox) {
    padding: 10px 20px;
  }

  .btn-clickbox {
    padding-top: 0;
    text-align: left;
  }

  .td-box {
    @include flex($align: flex-end);
    min-width: fit-content;
    margin-left: auto;
  }

  .btn-sm-wh {
    width: fit-content;
    max-width: none;
    padding: 15px;
  }
}

.wide-table th {
  padding: 22px 10px;
}

.wide-table td {
  padding: 22px 10px;
}

.write {
  .table-type01 {
    td {
      height: 60px;

      input,
      select {
        margin-top: -1px;
      }
    }
  }

  .table-type04 {
    td {
      height: 60px;
      padding: 11px 20px 9px;
    }
  }
}

/*----------------------------------------*\
  || Table Clone
\*----------------------------------------*/

.table-clone {
  width: 100%;
  padding: 20px;
  background: #f7f7f7;
  border-top: 1px solid var(--line-clr3);
  border-radius: 20px;
  overflow: hidden;

  &.bg-f7 {
    background: #f7f7f7 !important;
    padding: 18px 20px;

    p {
      line-height: 1.4;
    }
  }

  .table-top {
    width: 250px;
    padding: 15px 20px;
    border-right: 1px solid var(--disable-clr);
  }

  .table-cell:first-child {
    font-size: 16px;
    font-weight: $medium;
  }

  .notice-subtxt {
    font-weight: $medium;
    font-size: 14px;
  }

  .s-txt {
    font-size: 14px;
    line-height: 1.5;
  }
}

/*----------------------------------------*\
  || Condensed
\*----------------------------------------*/

.condensed-head th,
.condensed td {
  padding: 15px 20px;
}

.condensed-thin td {
  padding: 10px !important;

  .btn-table {
    height: 40px;
  }
}

.condensed-type01 td {
  padding: 20px;
}

/*----------------------------------------*\
  || Code Table
\*----------------------------------------*/

.code-box {
  display: inline-block;
}

.code-table {
  width: 100%;
  padding: 40px 20px;

  .table-cell:first-child {
    width: 280px;
  }

  .otp-img {
    width: 247px;

    > img {
      width: 100%;
    }
  }

  &.type02 {
    padding: 21px 30px 0 15px;
    font-size: 0;

    .notice-list {
      display: inline-block;
      margin-left: 2px;
      vertical-align: 67px;
    }
  }
}

/*----------------------------------------*\
  || Gap
\*----------------------------------------*/

table th,
table td {

  .tab-basic,
  .search-select-area,
  .search-table-area,
  .cach-content-wrap,
  .info-table-area,
  .slide-box-area,
  .slide-box-area.type02,
  .ui-accordion-list,
  .notice-area-wrap,
  .document-area,
  .dot-list-wrap,
  .login-box-wrap,
  .notice-list-wrap {
    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

.table-pc {
  display: block;
}

.table-mobile {
  display: none;
}

@include respond-to ("huge") {
  html.responsive {
    .table-pc {
      display: none;
    }

    .table-mobile {
      display: block;
    }

    .search-table-area {
      &.table-areapd {
        padding-top: 0px !important;
      }

      &.respon {
        padding-bottom: 20px;
        margin-bottom: 0;
      }
    }

    .table-wrap01.wt {
      border: 0;
    }

    .table-type01 {
      background-color: #eeeff1;

      &.loan .se-td {
        border: 0;
        width: 100%;

        &::before {
          display: none;
        }

        &:first-child {
          border-top: 1px solid var(--body-clr);
        }

        &:last-child .td-wrap {
          padding-bottom: 20px;
        }

        .td-wrap {
          padding-bottom: 0;

          button {
            margin-bottom: 10px;
          }
        }
      }

      th,
      td {
        padding: 0;
        border: 0;
        font-size: 13px;
        background-color: var(--bg-clr2);
      }

      th.respon {
        display: table-cell;
      }

      // &::after,
      .session::before {
        content: '';
        display: block;
        float: none;
        clear: both;
        width: 100%;
        height: 10px;
        background: var(--line-clr3);
      }

      th[scope],
      .table-center::before,
      .session.child::before,
      .session:first-child::before,
      .no-data [data-cell-header]::before,
      .child [data-cell-header]::before {
        display: none;
      }

      .se-td {
        position: relative;
        display: table;
        width: 100%;
        table-layout: fixed;
        float: left;
        border-top: 1px solid var(--disable-clr);
        align-items: center;

        .td-wrap {
          display: table-cell;
          font-size: 13px;
          padding: 12px 16px;
          text-align: left;
        }
      }

      [data-cell-header]::before {
        content: attr(data-cell-header);
        display: table-cell;
        width: 145px;
        padding: 10px 16px;
        background: #f7f7f7;
        border-right: 1px solid var(--disable-clr);
        font-size: 13px;
        font-weight: $bold;
        line-height: 15px;
        text-align: left;
        box-sizing: border-box;
      }

      .child,
      .no-data,
      .table-center {
        .td-wrap {
          width: 100%;
          text-align: center;
        }
      }
    }

    .table-type01-foot {
      border-top: 2px solid var(--body-clr);
      border-bottom: 1px solid var(--disable-clr);
      background: var(--bg-clr2);
    }

    .td-box {
      width: 100%;
      display: table;
      table-layout: fixed;

      > span {
        font-size: 15px;
        display: table-cell;
        padding: 10px 0;
      }

      .tal {
        padding-left: 15px;
      }

      .won {
        width: 40px;
        padding-right: 15px;
      }

      .money {
        padding: 0;
        font-size: 20px;
      }
    }

    .table-type03-w {
      display: none;
    }

    .table-type03-m {
      display: table;

      th,
      td {
        padding: 10px 16px;
        font-size: 13px;
        line-height: 15px;
        text-align: left;
        vertical-align: middle;
      }
    }

    .table-type04 {

      &.style01 td,
      &.style01 th {
        width: auto;
      }

      th,
      td {
        padding: 10px 16px;
        font-size: 14px;
        border-bottom: 1px solid var(--disable-clr);
      }

      tbody tr:last-child {

        th,
        td {
          border-bottom: 0;
        }
      }
    }

    .table-type03-m,
    .table-type04 {
      width: 100%;

      th {
        background: #f7f7f7;
        width: 43%;
        font-weight: $bold;
      }

      td {
        width: 57%;
      }
    }

    .table-style01 td {
      padding-left: 10px !important;
    }

    .col-style02 {
      width: 115px;
    }

    .condensed th {
      padding: 13px 16px;
    }

    .condensed td {
      padding: 13px 16px;
    }

    .tbl-style01 {
      border-top: 1px solid var(--body-clr);

      li {
        border-bottom: 1px solid var(--disable-clr);

        .link {
          display: block;
          padding: 20px 18px;

          .thema {
            color: #666;
            font-size: 16px;
            line-height: 1.5;
          }

          .date {
            margin-top: 5px;
            color: #666;
            font-size: 12px;
          }

          .btn {
            margin-top: 15px;
          }
        }
      }
    }
  }

  .table-style01 {

    th,
    td {
      padding: 12px 5px;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .child {
    .table {
      display: block !important;
      margin-bottom: 0;
    }

    .td-wrap {
      padding: 32px 7.5%;
    }

    .table-cell {
      display: block !important;
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
    }

    .ses-title {
      font-size: 16px;
    }

    .btn-area {
      padding: 0;
      margin-top: 0;
      border-top: 0;

      button {
        margin: 0;
      }
    }
  }
}

/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  html.responsive {
    .table-type04.type-col {
      colgroup {
        display: none;
      }

      th {
        display: block;
        width: 100% !important;
        height: auto;
        padding: 15px;
        border-right: none;
        border-bottom: 1px solid var(--line-clr);
      }

      td {
        display: block;
        width: 100% !important;
        height: auto;
        padding: 15px;

        &::after {
          content: '';
          display: block;
          clear: both;
        }
      }
    }

  }
}
