@charset "utf-8";

html {
  overflow: auto;
  min-width: $wrapSize;

  &.responsive {
    min-width: initial;
    width: auto;
  }
}

html,
body {
  height: 100%;
  font-size: 10px;
  overscroll-behavior: none;
}

html.js-scroll-blocking {
  &,
  body,
  .layout-wrap {
    overflow: hidden !important;
    touch-action: none;
    -webkit-overflow-scrolling: auto;
  }

  // &:has(.dim-contents) .layout-wrap {
  //   pointer-events: none;
  // }

  // #loading-box,
  // [role=dialog],
  // [aria-modal=true],
  // [aria-hidden=false],
  // .dim-contents {
  //   pointer-events: auto;
  // }
}

.layout-wrap.type-A {

  .typeBcont,
  #typeBmenu {
    display: none !important;
  }
}

.layout-wrap.type-B {

  .typeAcont,
  #typeAmenu {
    display: none !important;
  }
}

.layout-wrap {
  height: 100%;
}

.layout-container {
  position: relative;
  max-width: 100vw;
  min-height: 100%;
  margin: 0;
  padding: 0 !important; /* 임시 important */
  overflow: hidden;
  box-sizing: border-box;
}

.js-content-zoom-wrap {
  width: $wrapSize;
  max-width: $wrapSize;
  margin: 0 auto;
  transition: all ease .3s;

  .content,
  .container,
  .title-top {
    max-width: none;
    margin: 0;
  }
}

.content {
  padding: 0 0 40px;
}

.content,
.container {
  position: relative;
  max-width: $wrapSize;
  min-height: calc(100vh - 470px);
  margin: 0 auto;
  padding-bottom: 60px;
  box-sizing: border-box;

  &:has(#breadcrumb) {
    min-height: calc(100vh - 530px);
  }

  &:has(.dep-title-wrap) {
    padding-top: 60px;
  }
}

.co-size {
  width: $wrapSize;
  margin: auto;
}

/*----------------------------------------*\
  || [공통] TTS
\*----------------------------------------*/

.for-a11y,
caption.for-a11y,
.component-layer-close-button-text,
.layout-page-title {
  @include blind;
}

/*----------------------------------------*\
  || [공통] Skip Navigation
\*----------------------------------------*/

#skipnavigation {
  position: absolute;
  top: 0;
  z-index: 5000;

  a {
    display: block;
    position: fixed;
    z-index: 5000;
    top: -40px;
    left: 0;
    width: 100%;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: var(--bg-clr2);
    background: #1d60a7;

    &:focus {
      top: 0;
    }
  }
}

/*----------------------------------------*\
  || Print
\*----------------------------------------*/

@media print {

  .popup-body01,
  .popup-body02 {
    overflow: visible !important;
    min-height: 0 !important;
    max-height: none !important;
  }
}

/*----------------------------------------*\
  || Gap
\*----------------------------------------*/

.select-wrap + .basic-inp-wrap,
.basic-inp-wrap + .select-wrap {
  margin-top: 15px;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html {
    overflow-x: hidden;
    min-width: auto;

    #_FSBcontainer {
      zoom: 1 !important;
    }
  }

  html.responsive,
  html:has(.content) {
    .js-content-zoom-wrap {
      width: auto !important;
      height: auto !important;

      .container,
      .title-top {
        width: auto !important;
        -webkit-transform: none !important;
        transform: none !important;
        padding: 15px 15px 50px;
      }
    }

    .container > .info-table-area:first-child {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }

    .content {
      margin: 0 auto;
      padding: 20px;
    }

    .content,
    .container {
      &:has(.dep-title-wrap) {
        padding-top: 30px;
      }
    }
  }

  .container {
    padding: 20px;
  }
}


/*----------------------------------------*\
  || Medium
\*----------------------------------------*/

@include respond-to ("medium") {
  .content {
    padding-top: 0;
  }
}
