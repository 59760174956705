@charset "utf-8";

/*----------------------------------------*\
  || 즉시이체 정보입력 레이아웃
\*----------------------------------------*/

.transfer {
  display: flex;
  gap: 80px;

  &__form {
    width: 820px;
  }

  &__summary {
    width: 300px;
    padding-top: 44px;

    .basic-btn-wrap {
      margin: 20px auto;
    }

    .basic-btn {
      width: 100%;
    }
  }

  &__banner {
    width: 100%;
    padding: 24px;
    margin-bottom: 20px;
    background-color: #fcfefe;
    border: 1px solid var(--line-clr2);
    border-radius: 10px;

    .title {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 24px;
      font-weight: $bold;
      color: var(--tit-clr);
    }

    .text {
      font-size: 12px;
      line-height: 150%;
      color: var(--body-clr);
    }

    &--alert {
      background: #fef6f6 url(/img/content/trn/img_banner_alert.png) no-repeat center right 12px / 80px 60px;
      border-color: #fef6f6;

      .title {
        color: var(--neg-clr);
      }
    }
  }
}

.transfer-list {
  display: flex;
  flex-direction: column-reverse;
  counter-reset: list-counter;

  &__item {
    $transfer-speed: 0.6s;
    $transfer-bezier: cubic-bezier(0.74, -0.01, 0.49, 0.9);

    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--line-clr2);
    opacity: 1;
    overflow: hidden;
    counter-increment: list-counter;
    animation: transferExpand $transfer-speed $transfer-bezier;

    @keyframes transferExpand {
      from {
        max-height: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        border-width: 0;
        opacity: 1;
      }

      to {
        max-height: 260px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-width: 1px;
        opacity: 1;
      }
    }

    &::before {
      @include flex($justify: center);
      content: counter(list-counter);
      width: 18px;
      min-height: 18px;
      aspect-ratio: 1;
      margin-bottom: 8px;
      background-color: var(--bg-clr1);
      border: 1px solid var(--line-clr2);
      border-radius: 7px;
      font-size: 12px;
      font-weight: $bold;
      color: var(--body-clr2);
    }

    &:first-child {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      border-bottom: 0 !important;
      animation: none;
    }

    &--collapse {
      max-height: 0;
      opacity: 0;

        &,
        &:first-child + li {
          margin-bottom: 0;
          padding-bottom: 0;
          border-width: 0;
          transition:
            max-height $transfer-speed $transfer-bezier,
            opacity $transfer-speed $transfer-bezier,
            margin-bottom $transfer-speed $transfer-bezier,
            padding-bottom $transfer-speed $transfer-bezier,
            border-width $transfer-speed $transfer-bezier;
        }
    }

    .details-list {
      margin-top: 4px;
    }

    .btn--chevron-down {
      order: 1;
      margin-top: 12px;
      margin-left: auto;

      & + .details-list {
        max-height: 0;
        overflow: hidden;
        transition: max-height .4s ease;
      }

      &[aria-expanded="true"] + .details-list {
        max-height: 180px;
      }
    }

    .del-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}


/*----------------------------------------*\
  || Sign Wrap 납부영수증
\*----------------------------------------*/

.sign-wrap {
  $clr: #ef4b56;
  @include flex($justify: center, $direction: column);
  @include size(100px);
  border: 3px solid $clr;
  border-radius: 50%;
  text-align: center;

  .title {
    font-size: 14px;
    font-weight: $bold;
    line-height: 16px;
    color: $clr;
  }

  .date {
    display: block;
    width: 100%;
    margin: 7px 0 4px;
    padding: 7px 0;
    border-top: 2px solid $clr;
    border-bottom: 2px solid $clr;
    font-size: 13px;
    line-height: 15px;
    color: var(--tit-clr);
  }

  .bank {
    font-size: 10px;
    line-height: 12px;
    color: #666;
  }
}

/*----------------------------------------*\
  || Card List
\*----------------------------------------*/

.card-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  max-height: 260px;
  padding-bottom: 12px;
  margin-bottom: 18px;
  overflow: auto;

  // &:has(+ .card-more-btn) {
  //   max-height: 230px;
  //   padding-bottom: 0;
  //   margin-bottom: 90px;
  //   overflow: hidden;
  //   transition: max-height .3s ease;

  //   &.on {
  //     max-height: 590px;
  //     transition-duration: .6s;
  //   }
  // }

  &::-webkit-scrollbar {
    width: 32px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--line-clr);
    background-clip: padding-box;
    border: 12px solid transparent;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #f6fafa;
    background-clip: padding-box;
    border: 12px solid transparent;
    border-radius: 16px;
  }
}

.card-item {
  @include size(100%, auto);
  position: relative;
  max-height: 110px;

  input {
    @include size(100%);
    position: absolute;

    &:checked + label {
      border-color: var(--main-clr);
      box-shadow: 0 0 0 1px var(--main-clr) inset, 0 6px 12px rgba(0, 162, 156, .06);
      background-color: #e5f5f5;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.empty {
    background: #f2f3f8 url(/img/common/logo_sb_gray.png) no-repeat 50%;
    border: 1px solid var(--line-clr2);
    border-radius: 10px;
  }
}

.card-item-label {
  @include flex;
  @include size(100%);
  min-height: 80px;
  padding: 12px 16px;
  background: var(--bg-clr2);
  border: 1px solid var(--line-clr2);
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .04);
  transition: all .2s ease;

  &:has(.sub) .ico-bi {
    top: 12px;
  }

  .ico-bi {
    position: absolute;
    right: 16px;
    width: 32px;
    height: 32px;
  }

  .wrap {
    @include flex($direction: column, $align: normal);

    .name {
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      color: var(--tit-clr);
    }

    .bank,
    .sub {
      margin-top: 4px;
      font-size: 12px;
      line-height: 1;
      color: var(--body-clr2);
    }

    .sub {
      margin-top: 12px;
    }
  }
}

.page-main-txt {
  .ico-bi-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, 40px);
    justify-content: center;
    margin-left: -24px;
  }

  .ico-bi {
    @include size(64px);
    display: block;
    margin: 0 auto 24px;

    &:not(.total)::before {
      box-shadow: 0 0 0 1px inset var(--line-clr2);
      border-radius: 50%;
    }

    &.total {
      font-size: 18px;
      line-height: 64px;
      font-weight: 700;
      background: var(--bg-clr2);
      color: var(--main-clr);
      border: 1px solid var(--main-clr);
      border-radius: 50%;
    }
  }
}

// .card-more-btn {
//   @include flex($justify: center, $gap: 8px);
//   @include size(100%, 60px);
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: var(--bg-clr2);
//   border-top: 1px solid var(--line-clr3);
//   border-radius: 0 0 14px 14px;
//   font-size: 16px;
//   line-height: 19px;
//   color: var(--tit-clr);

//   &::after {
//     @include size(16px);
//     @include bgImg($url: '/img/common/icon/icon_arrow_down.png', $size: 12px);
//     content: '';
//     display: inline-block;
//     transition: all .3s ease;
//   }

//   &[aria-pressed="true"]::after {
//     transform: scaleY(-1);
//   }
// }

/*----------------------------------------*\
  || Amount Wrap
\*----------------------------------------*/

.amount-wrap {
  @include flex($gap: 8px);

  .basic-btn {
    min-width: 71px;
    height: 38px;
    border-radius: 10px;
  }
}

.amount-list {
  @include flex($justify: space-between);
  width: 100%;
  background-color: #f2f4f6;
  border: 1px solid var(--line-clr3);
  border-radius: 10px;
  overflow: hidden;
}

.amount-item {
  @include flex($justify: center);
  flex: 1;
  position: relative;

  & + &::before {
    @include size(1px, 17px);
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    background-color: var(--line-clr3);
    transform: translateY(-50%);
  }
}

.amount-btn {
  @include size(100%);
  padding: 10px;
  font-size: 14px;
  font-weight: $bold;
  line-height: 16px;
  color: var(--tit-clr);
  transition: all .15s ease;
  outline-offset: -2px;

  &:active {
    background-color: #e6e8e9;
  }
}

/*----------------------------------------*\
  || 혼합식적금 입금구분
\*----------------------------------------*/

.acco-mixed-wrap {
  @include flex($gap: 40px);

  .basic-radio-wrap {
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;
  }

  li.on ~ .basic-radio-wrap {
    opacity: 1;
    visibility: visible;
  }
}

/*----------------------------------------*\
  || Transfer Result
\*----------------------------------------*/

.transfer-result-wrap {
  @include flex($justify: space-between, $gap: 20px);
  @include borderRadius(s);
  width: 100%;
  margin-top: 20px;
  padding: 12px 50px;
  background-color: #f1f4f6;

  .list {
    @include flex($gap: 8px);
    min-width: fit-content;

    &:first-child {
      gap: 17px;
    }
  }

  .title {
    @include font(s);
    color: var(--cap-clr);
  }

  .txt {
    @include font(s, $bold);
    color: var(--cap-clr);
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

.trn-buttonwrap {
  width: 100%;
  clear: both;
}

.trn-notice {
  @include flex;
  margin-top: 5px;

  .date-box2 {
    margin-right: 10px;
  }
}

/* 즉시이체 : 받는분 추가표시*/
.table-name {
  font-size: 11px;
  float: left;
  margin-top: 7px;
  margin-bottom: -7px;
}

/* 즉시이체 : 삭제버튼*/
.thin-center {
  float: none !important;
  margin-right: 0 !important;
}

/*----------------------------------------*\
  || PayInfo : 자동이체 통합관리
\*----------------------------------------*/

.box-style01 {
  width: 100%;
  padding: 20px;
  margin-top: 35px;
  background: #efefef;
  border-radius: 14px;

	.text {
		color: #e60012;
		font-size: 16px;
		line-height: 22px;
	}
}

.box-style03 {
  width: 100%;
  margin-top: 50px;
  padding: 46px 470px 109px 45px;
  background: #efefef url("/img/common/bg_payinfo.png") no-repeat right 60px top 50%;

  .title {
    font-size: 22px;
    font-weight: $medium;
    line-height: 27px;
  }

  .text {
    display: inline-block;
    margin-top: 23px;
    font-size: 16px;
    line-height: 24px;
  }
}

.payinfo-wrap {
  display: table;
  width: 100%;
  margin-top: 43px;
  border: 1px solid var(--line-clr3);
  border-radius: 20px;

  li {
    padding: 46px 46px 50px 46px;
    border-right: 1px solid var(--line-clr3);

    &:last-child {
      border-right: 0;
    }
  }
}

.payinfo-box {
  display: table-cell;
  width: 50%;

  .title {
    font-size: 22px;
    font-weight: $medium;
    line-height: 25px;
  }

  .text {
    margin-top: 10px;
    font-size: 16px;
    line-height: 27px;
  }

  .sub-text {
    display: block;
    margin-top: 2px;
    color: #949494;
  }
}

.payinfo-img {
  margin-top: 60px;
}

.slide-payinfobox {
  display: table;
  width: 100%;
  padding: 25px 0;

  .payinfo-list:first-child {
    padding-right: 20px;
  }

  .payinfo-list:nth-child(2n) {
    padding-left: 24px;
  }
}

.payinfo-list {
  display: table-cell;
  width: 50%;

  .title {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: $medium;

    & + .payinfo-notice {
      margin-top: 15px;

      li:first-child {
        padding-top: 15px;
      }
    }
  }
}

.payinfo-notice .list {
  margin-bottom: 10px;
  padding-left: 14px;
  font-size: 14px;
  line-height: 1.5;
  text-indent: -14px;
  word-break: keep-all;

  &:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 10px;
    background: #999;
    border-radius: 50%;
    vertical-align: 3px;
  }
}

/*----------------------------------------*\
  || Trn Row Box
\*----------------------------------------*/

.trn-rowbox {
  clear: both;
  width: 100%;

  .input-basic {
    max-width: 250px;
  }

  .input-www {
    max-width: 280px;
  }

  .btn-sm-wh {
    max-width: 120px;
  }

  label:not(.vitualKeyCheck) {
    display: inline-block;
    vertical-align: middle;
    line-height: 40px;
  }
}

.trn-rowbox .chk-wrap,
.phone-wrap .chk-wrap {
  line-height: 40px;
  margin-left: 5px;
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .trn-buttonwrap button {
      max-width: 60px;
      font-size: 13px;
      padding: 8px 0;
      background: #eeeff1;
      border: 0;
    }
  }
}
