@charset "utf-8";

/*----------------------------------------*\
  || Tooltip New
\*----------------------------------------*/

.tooltip-wrap {
  position: relative;
  vertical-align: middle;

  &.type-click {
    @at-root .tooltip-wrap:focus-within .btn-tooltip .btn-tooltip :hover {
      background-color: var(--bg-clr2);
      border: 1px solid var(--disable-clr);

      &::before {
        color: var(--cap-clr);
      }

      .tooltip-box {
        padding-right: 25px;
      }
    }
  }

  .btn-tooltip {
    $tooltip-error: var(--cap-clr);

    @include size(22px);
    background-color: var(--bg-clr2);
    border: 1px solid var(--disable-clr);
    border-radius: 50%;
    color: $tooltip-error;

    &::before {
      content: "?";
      display: block;
      font-size: 14px;
      font-weight: $medium;
      line-height: 16px;
      color: inherit;
      text-align: center;
    }

    @at-root .tooltip-wrap:focus-within .btn-tooltip,
    &:hover {
      background-color: $tooltip-error;
      border-color: $tooltip-error;

      &::before {
        color: var(--bg-clr2);
      }
    }

    &.type-error {
      $tooltip-error: var(--neg-clr);

      border-color: $tooltip-error;
      color: $tooltip-error;

      @at-root .tooltip-wrap:focus-within .btn-tooltip.type-error,
      &:hover {
        background-color: $tooltip-error;
        border-color: $tooltip-error;

        &::before {
          color: var(--bg-clr2);
        }
      }
    }

  }

  .tooltip-box {
    display: none;
    position: absolute;
    left: 50%;
    top: calc(100% + 10px);
    width: max-content;
    min-width: 89px;
    max-width: 160px;
    padding: 12px;
    background-color: var(--bg-clr2);
    border: 1px solid #000;
    border-radius: 7px;
    font-size: 14px;
    line-height: 16px;
    color: var(--cap-clr);
    text-align: left;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%);
    z-index: 1;

    &:focus-visible {
      padding: 11px;
      border-width: 2px;

      .close {
        top: 7px;
        right: 7px;
      }
    }

    &:focus-visible::before {
      top: calc(-15px / 2);
      border-width: 2px;
    }

    &::before {
      @include size(10px);
      content: "";
      position: absolute;
      top: calc(-13px / 2);
      left: 50%;
      background-color: var(--bg-clr2);
      border-left: 1px solid #000;
      border-top: 1px solid #000;
      transform: translateX(-50%) rotate(45deg);
    }

    .close {
      @include size(14px);
      position: absolute;
      top: 8px;
      right: 8px;
      background: none;

      &::before,
      &::after {
        @include size(11px, calc(3px / 2));
        content: '';
        position: absolute;
        top: 6px;
        left: calc(3px / 2);
        display: block;
        background-color: var(--tit-clr);
        border-radius: 10px;
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &:not(.type-click):hover .tooltip-box,
  &:not(.type-click):focus-within .tooltip-box {
    display: block;
  }
}

@include respond-to ("medium") {
  .tooltip-wrap.type-click .tooltip-box {
    position: fixed;
    inset: 0;
    top: initial;
    width: 100%;
    max-width: none;
    max-height: 50vh;
    padding: 40px 20px 20px;
    border: 0;
    border-radius: 14px 14px 0 0;
    transform: none;
    z-index: 2;

    &:before {
      display: none;
    }

    .close {
      @include size(20px);
      top: 15px;
      right: 20px;

      &::before,
      &::after {
        width: 20px;
      }
    }

    .mini-link-list li a {
      font-size: 16px;
      line-height: 19px;
    }

    .mini-link-list li + li {
      margin-top: 20px;
    }
  }

  body:has(.tooltip-box[style*=block])::after {
    content: '';
    position: fixed;
    inset: 0;
    background: #00000033;
    animation: fadeIn .3s ease forwards;
    z-index: 1;
  }
}

/*----------------------------------------*\
  || Basic Tooltip
\*----------------------------------------*/

.basic-tooltip-wrap {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 320px;
  min-width: fit-content;
  min-height: 40px;
  padding: 9px 16px;
  background-color: var(--bg-clr2);
  border: 1px solid var(--tit-clr);
  border-radius: 20px;
  z-index: 1;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background: url(/img/common/icon/icon_tooltip_error.png) no-repeat center / contain;
  }

  &::after {
    content: "";
    position: absolute;
    top: -6px;
    left: 19px;
    display: block;
    width: 10px;
    height: 10px;
    background-color: var(--bg-clr2);
    border-radius: 1px;
    border: 1px solid var(--tit-clr);
    border-color: inherit;
    border-right-color: var(--bg-clr2);
    border-bottom-color: var(--bg-clr2);
    transform: rotate(45deg);
    will-change: transform;
  }

  &.type-right {
    left: initial;
    right: 0;
  }

  &.type-neg {
    border-color: var(--neg-clr);
  }

  &.type-pos {
    border-color: var(--pos-clr);
  }

  .basic-tooltip-txt {
    font-size: 14px;
    color: var(--tit-clr);
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Tooltip box
\*----------------------------------------*/

.tool-box {
  position: relative;

  .btn-close-box {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .icon-question:hover ~ .tooltips.bottom,
  .icon-question:focus ~ .tooltips.bottom {
    display: block;
  }
}

.tooltips {
  display: none;
  min-width: 150px;
  position: absolute;
  padding: 10px;
  background: var(--bg-clr2);
  -webkit-box-shadow: 15px 12px 46px -1px rgba(127, 127, 127, 0.68);
  box-shadow: 15px 12px 46px -1px rgba(127, 127, 127, 0.68);
  border: 1px solid #000;
  z-index: 5;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -5px;
    left: 45%;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    background: var(--bg-clr2);
    transform: rotate(45deg);
    z-index: 10;
  }

  &.bottom {
    display: none;
    bottom: 0;
    left: 55%;
  }

  &.qrcode {
    display: none;
    bottom: inherit;
    top: 60px;
    left: -10px;
    transform: none;
    padding: 30px 30px 20px;

    &::after {
      bottom: inherit;
      top: -5px;
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      border-bottom: none;
      border-right: 0;
    }

    img {
      width: 90px;
      height: auto;
    }

    .qr-txt {
      font-size: 12px;
      padding-top: 5px;
      text-align: center;
    }
  }
}

.tool-box-type3,
.tool-box-type4 {
  font-size: 14px;

  .tool-subtxt {
    text-align: left;
    line-height: 24px;
  }

  .tooltips::after {
    left: 28%;
  }
}

.tool-box-type4 .tooltips {
  left: 181.3%;
  min-width: 274px;
  bottom: 47px;
}

.tool-box-type3 .tooltips {
  left: 170.3%;
  transform: translateX(-50.3%);
  min-width: 250px;
  bottom: 47px;
}

.tool-line {
  width: 30px;
  height: 1px;
  background: var(--line-clr3);
  display: block;
  margin: 10px 0;
}

.tooltips-ul {
  padding: 20px 0 10px;

  li {
    text-align: left;
    font-size: 12px;
    color: var(--body-clr);
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      position: relative;
      display: inline;
      text-align: left;
      text-decoration: underline;

      &:active,
      &:focus {
        font-weight: $bold;
      }
    }
  }
}

.tooltips-txt {
  font-size: 12px;
  line-height: 20px;
  word-break: keep-all;
}

.btn-close {
  float: right;
}

.icon-close {
  width: 10px;
  height: 10px;
  background: url(/img/common/btn_close.png) no-repeat center;
}


/* //mark:  tooltip */

[class^=aui-tooltip] {
  @include size(22px);
  background-color: var(--bg-clr2);
  border: 1px solid var(--disable-clr);
  border-radius: 50%;
  vertical-align: middle;

  &::before {
    content: "?";
    display: block;
    font-size: 14px;
    font-weight: $medium;
    line-height: 16px;
    color: var(--cap-clr);
    text-align: center;
  }

  &:hover,
  &:focus {
    background-color: var(--cap-clr);
    border-color: var(--cap-clr);

    &::before {
      color: var(--bg-clr2);
    }
  }

  > span {
    @include blind;
  }
}

[class^=tooltipContent] {
  position: absolute;
  width: max-content;
  min-width: 89px;
  max-width: 400px;
  padding: 12px;
  background-color: var(--bg-clr2);
  border: 1px solid #000;
  border-radius: 7px;
  font-size: 14px;
  line-height: 16px;
  color: var(--cap-clr);
  text-align: left;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease;
}

.tooltipContent--close.active {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.tooltipContent.active {
  opacity: 1;
  visibility: visible;
}

.right[class^=tooltipContent]::after {
  top: 50%;
  transform: translateY(-50%);
  left: -7px;
  border-color: transparent var(--body-clr);
  border-width: 10px 10px 10px 0;
}

.left[class^=tooltipContent]::after {
  top: 50%;
  transform: translateY(-50%);
  right: -7px;
  border-color: transparent var(--body-clr);
  border-width: 10px 0 10px 10px;
}

.top[class^=tooltipContent]::after {
  bottom: -7px;
  left: 50%;
  border-color: var(--body-clr) transparent;
  border-width: 7px 7px 0;
  transform: translateX(-50%);
}

/* mini버전 tooltip */

.tooltip-btn {
  @include size(22px);
  background-color: var(--bg-clr2);
  border: 1px solid var(--disable-clr);
  border-radius: 50%;

  &::before {
    content: "?";
    display: block;
    font-size: 14px;
    font-weight: $medium;
    line-height: 16px;
    color: var(--cap-clr);
    text-align: center;
  }

  @at-root .tooltip-wrap:focus-within .tooltip-btn,
  &:hover {
    background-color: var(--cap-clr);
    border-color: var(--cap-clr);

    &::before {
      color: var(--bg-clr2);
    }
  }
}

.tooltip-item {
  position: absolute;
  width: max-content;
  min-width: 89px;
  max-width: 400px;
  padding: 12px;
  background-color: var(--bg-clr2);
  border: 1px solid #000;
  border-radius: 7px;
  font-size: 14px;
  line-height: 16px;
  color: var(--cap-clr);
  text-align: left;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease;

  &.on {
    opacity: 1;
    visibility: visible;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .tooltips {
      left: 68px;

      li {
        margin-bottom: 4px;
        line-height: 20px;

        a {
          word-break: break-all;
          text-decoration: underline;
          text-underline-position: under;
          font-size: 10px;
        }
      }

      span {
        display: none
      }
    }

    .snsbox {
      .tooltip-layerpopup {
        right: -3px;
        width: 155px;
        height: 62px;
        padding: 19px 0 40px 0;

        &::after {
          right: 20px
        }

        .sns {
          width: 32px;
          padding-top: 34px;
          font-size: 0;
        }
      }
    }
  }

  html.responsive .snsbox .tooltip-layerpopup .kakao,
  .snsbox .tooltip-layerpopup .instagram,
  .snsbox .tooltip-layerpopup .facebook,
  .snsbox .tooltip-layerpopup .urlcopy {
    background-size: 80%;
    line-height: 0;
  }
}
