@charset "utf-8";

$font: "Pretendard", "NanumBarun", "돋움", Dotum, sans-serif;
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$semi: 600;
$bold: 700;

$mainClr: #00a29c;
$bodyClr: #333;
$bodyClr2 : #555;
$titClr: #111;
$capClr: #888;
$disableClr: #ccc;
$disableClr2: #e2e2e2;
$lineClr: #f2f2f2;
$lineClr2: #e8e8e8;
$lineClr3: #ddd;
$bgClr1: #f9f9f9;
$bgClr2: #ffffff;
$bgClr3: #e9e9e9;
$bgClr4: #f7f8fa;
$posClr: #3f81db;
$negClr: #ff4040;
$ingClr: #52bc3d;
$mintClr: #dff5f1;

$color: (
  --main-clr: $mainClr,
  --body-clr: $bodyClr,
  --body-clr2: $bodyClr2,
  --tit-clr: $titClr,
  --cap-clr: $capClr,
  --disable-clr: $disableClr,
  --disable-clr2: $disableClr2,
  --bg-clr1: $bgClr1,
  --bg-clr2: $bgClr2,
  --bg-clr3: $bgClr3,
  --bg-clr4: $bgClr4,
  --line-clr: $lineClr,
  --line-clr2: $lineClr2,
  --line-clr3: $lineClr3,
  --pos-clr: $posClr,
  --neg-clr: $negClr,
  --ing-clr: $ingClr,
  --mint-clr: $mintClr,

);

$colorName: (
  main: --main-clr,
  'black': --tit-clr,
  'white': --bg-clr2,
  'green': --ing-clr,
  danger: --neg-clr,
  success: --pos-clr,
  'mint': --mint-clr,
  grey01: --body-clr,
  body02: --body-clr2,
  grey02: --cap-clr,
  grey03: --disable-clr,
  grey04: --disable-clr2,
  grey05: --line-clr2,
  grey06: --line-clr,
  grey07: --bg-clr1,
  grey08: --line-clr3,
  grey09: --bg-clr3,
);

$fontSize: (
  body: (
    xxs: 12px,
    xs: 13px,
    s: 14px,
    m: 16px,
    l: 18px
  ),
  title: (
    s: 16px,
    m: 18px,
    l: 20px,
    xl: 25px,
    xxl: 36px
  )
);

$borderRadius: (
  xs: 7px,
  s: 10px,
  s2: 12px,
  m: 16px,
  l: 20px,
  xl: 32px
);

$wrapSize: 1200px;
