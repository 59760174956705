@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*----------------------------------------*\
  || Pagination
\*----------------------------------------*/

.pagination {
  width: 100%;
  padding: 40px 0;
  text-align: center;

  > li {
    display: inline-block;
    width: 35px;
    height: 35px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: table;
      width: 100%;
      height: 100%;
    }

    .page {
      display: block;
      text-align: center;
      line-height: 35px;
      color: #767676;
      background: var(--bg-clr2);
      border: 1px solid #efefef;
      font-size: 14px;
    }
  }

  .active-page .page {
    background: var(--cap-clr);
    color: var(--bg-clr2);
    font-weight: $bold;
  }

  .prev a {
    background: url('/img/common/page_left.png') no-repeat center;
  }

  .next a {
    background: url('/img/common/page_right.png') no-repeat center;
  }
}

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  html.responsive {
    .pagination {
      padding-bottom: 15px;
    }
  }
}
