@charset "utf-8";

html,body,div,span,applet,object,iframe,
h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,
big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,
strong,sub,sup,tt,var,b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,
label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,
aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,
menu,nav,output,section,summary,time,mark,audio,video,select, option {
  margin: 0;
  padding: 0;
  border: 0;
  color: var(--body-clr);
  font: {
    family: $font;
    weight: $regular;
    style: normal;
  }
  line-height: 1em;
  box-sizing: border-box;
  vertical-align: baseline;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: antialiased;
}

a, article, aside, details, figcaption,
figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

strong {
  font-weight: $bold !important;
}

b {
  font-weight: $bold !important;
  vertical-align: baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

select::-ms-expand {
  display: none;
}

a, button, input, textarea, select {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font: inherit;
  color: inherit;
  text-decoration: none;
  letter-spacing: inherit;
  vertical-align: baseline;
  appearance: none;
}

button,
select {
  overflow: visible;
  text-transform: none;
  line-height: 1em;
}

abbr,
a:hover,
a:focus,
button:focus,
button:hover {
  text-decoration: none;
}

input:autofill {
  background-color: transparent;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
}

input[readonly],
input[disabled],
button[disabled] {
  cursor: default;
}

/*
  Chrome의 증가/감소 버튼의 커서 모양을 수정.
  `input` 요소의 특정 `font-size` 값에 대해
  감소 버튼의 커서 모양이 `default`에서 `text`로 변한다.
*/
input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}

/**
  OS X의 Safari, Chrome에서 내부(inner) `padding`과 검색 취소 버튼을 제거.
*/

input[type="search"] {
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

/*
  input[type="text"], input[type="password"] placeholder속성 추가
*/

input[type="text"] {
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::-moz-placeholder {
    color: var(--cap-clr);
    font-family: $font;
  }
}

input[type="password"] {
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::-moz-placeholder {
    color: var(--cap-clr);
    font-family: $font;
  }
}

input.ie-clear::-ms-clear {
  @include size(0);
  display:none !important;
}

i {
  @include size(100%);
  @include bgImg($url: null, $size: 100%);
  display: inline-block;
}

pre {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f3f3f3;
  border: 1px solid var(--disable-clr);
  border-radius: 10px;
  font-size: 14px;
}

code {
  padding: 3px 5px;
  background-color: #000;
  border-radius: 5px;
  font-size: 14px;
  color: #efef00;
}

caption {
  @include blind;
}

[hidden] {
  display: none !important;
}
