@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Full Canlendar
\*----------------------------------------*/

.calendar-content {
  border-top: 1px solid #000;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

.fc-header-toolbar .fc-center {
  vertical-align: bottom;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: none;
  font-weight: $bold;
}

.fc {

  td,
  th {
    text-align: left;
    border: 0;
  }
}

th.fc-day-header,
td.fc-day-header,
td.fc-day {
  position: relative;
}

th.fc-day-header::after,
td.fc-day-header::after,
td.fc-day::after {
  content: '';
  width: 150px;
  height: 1px;
  background: var(--disable-clr);
  position: absolute;
  left: 0;
  bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  background: #f7f7f7;
  padding: 15px 20px;
  position: relative;
}

.fc-center {

  p,
  h5 {
    font-size: 22px;
    font-weight: $bold;
  }
}

.fc-state-default {
  border: 0;
  background-color: transparent;
  background-image: url("/img/common/icon_select_333.png");
  background-position: center;
  background-repeat: no-repeat;
  border-color: none;
  box-shadow: none;

  span.fc-icon {
    display: none;
  }
}

button {
  .fc-prev-button.fc-button.fc-state-default {
    width: 30px;
    height: 20px;
    position: absolute;
    left: 20px;
    top: 30%;
    transform: rotate(90deg) translateY(-50%);
  }

  .fc-next-button.fc-button.fc-state-default {
    width: 30px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 30%;
    transform: rotate(-90deg) translateY(-50%);
  }
}

.fc-day-header {
  &.fc-widget-header {
    padding: 16px 7px 8px 0;
  }

  span {
    color: var(--cap-clr);
    font-size: 13px;
  }
}

.fc-sun span {
  color: var(--neg-clr);
}

td.fc-day-top {
  padding: 10px 2px 0 0;
}

.fc-content {
  margin-bottom: 10px;
}

.fc-title {
  font-size: 14px;
  line-height: 1.3;
  display: block;
  text-align: right;
  width: 130px;
  margin-right: 20px;

  &.money {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span {
    color: inherit;
  }
}

.fc-event,
.fc-event-dot {
  background: transparent;
  border-color: transparent;
}

.fc-unthemed td.fc-today {
  background: none;
}

.fc-day.fc-widget-content.fc-thu.fc-today::before {
  content: '';
  display: block;
  width: 150px;
  height: 100%;
  background-color: #f7f7f7;
  position: absolute;
  left: 0;
  top: 0;
}
