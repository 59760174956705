@charset "utf-8";

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Loading 01
\*----------------------------------------*/

#loading-dim {
  @include size(100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.5);
}

#loading-box {
  @include size(300px, 200px);
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  z-index: 9999;
}

#loading {
  @include size(100%);
  position: relative;
  text-align: center;
}

.loader {
  @include size(150px);
  position: absolute;
  top: 0;
  left: 74px;
  z-index: 1;
  border: 25px solid var(--line-clr3);
  border-radius: 50%;
  border-top: 25px solid #ee0589;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/*----------------------------------------*\
  || Loading 02 접속 대기 화면
\*----------------------------------------*/

.dim-white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg-clr2);
  z-index: 900;
  opacity: .6;
}

.loading-wrap {
  width: 600px;
  position: fixed;
  top: 50% !important;
  left: 50%;
  padding: 30px 60px;
  background: var(--bg-clr2);
  border: 2px solid var(--disable-clr);
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10000;

  span img {
    vertical-align: middle;
    margin-right: 5px;
  }
}

.loading-bar-wrap {
  width: 100%;
  height: 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: var(--disable-clr2);
  overflow: hidden;
}

.loading-bar {
  height: 100%;
  background: var(--pos-clr);
}

@include respond-to ("medium") {
  .loading-wrap {
    width: 80%;
    padding: 30px 20px;

    .flex2 {
      width: 100% !important;
      flex-direction: column;
    }
  }
}
