@charset "utf-8";

/*----------------------------------------*\
  || Basic Radio
\*----------------------------------------*/

.basic-radio-wrap {
  position: relative;
  min-width: fit-content;

  input {
    @include size(100%);
    position: absolute;

    &:checked + label {
      &::before {
        border-color: var(--main-clr);
      }
      &::after {
        background-color: var(--main-clr);
      }
    }
  }
}

.basic-radio {
  @include flex($gap: 12px);
  position: relative;
  font-size: 16px;
  line-height: 19px;
  color: var(--tit-clr);

  &::before {
    @include size(22px);
    content: '';
    display: block;
    background-color: var(--bg-clr2);
    border: 1px solid var(--disable-clr);
    border-radius: 50%;
    transition: all .15s ease;
  }

  &::after {
    @include size(14px);
    content: '';
    position: absolute;
    top: 50%;
    left: 5px;
    display: block;
    background-color: var(--bg-clr2);
    border-radius: 50%;
    transform: translateY(-50%);
    transition: all .15s ease;
  }
}

/*----------------------------------------*\
  || Basic Full Radio
\*----------------------------------------*/

.basic-full-radio-list {
  @include flex;
  width: 100%;

  .basic-full-radio {
    flex: 1;
    position: relative;

    &:has(:checked) {
      z-index: 1;
    }

    &:first-child label {
      border-radius: 10px 0 0 10px;
    }

    &:last-child label {
      border-radius: 0 10px 10px 0;
    }

    & + .basic-full-radio {
      margin-left: -1px;
    }

    input {
      @include size(100%);
      position: absolute;
      cursor: pointer;

      &:checked + label {
        background-color: #f6fffc;
        border-color: var(--main-clr);
        font-weight: $bold;
        color: var(--main-clr);
      }
    }

    label {
      @include flex($justify: center);
      @include size(100%, 45px);
      background-color: var(--bg-clr2);
      border: 1px solid var(--line-clr3);
      border-radius: 0;
      font-size: 14px;
      line-height: 16px;
      color: #666;
      transition: all .3s ease;
    }
  }

  &--s {
    width: auto;

    .basic-full-radio {
      flex: none;

      label {
        padding: 0 15px;
      }
    }
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

input[type="radio"].disable + label {
  color: var(--cap-clr);
  cursor: default;

  &::after {
    opacity: 0.5;
  }
}

.priod-box1 {
  position: relative;
  display: inline-block;
  margin-bottom: 6px;
}

.log-radio {
  @include flex($gap: 15px);
}

/*----------------------------------------*\
  || Form Box
\*----------------------------------------*/

.form-box {
  margin-top: 10px;

  &.type01 .radio-box {
    width: 50%;

    .phone-radios02 + label {
      width: 100%;
      font-size: 12px;
    }
  }

  &.type02 .radio-box {
    width: 33.333%;

    .phone-radios02 + label {
      width: 100%;
      font-size: 12px;
    }
  }
}

/*----------------------------------------*\
  || Btn Radio
\*----------------------------------------*/

.btn-radio {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:checked + .radio-basic {
    background-color: var(--bg-clr2);
  }
}

.btn-radios {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: transparent;

  & + label {
    position: relative;
    padding-left: 25px;
    font-weight: $bold;
    color: #666;
    font-size: 16px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 16px;
      height: 16px;
      background: url(/img/common/btn_radio_off.png) no-repeat center / 16px;
    }
  }

  &:checked + label::after {
    content: '';
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    background: url(/img/common/btn_radio_click.png) no-repeat center / 16px;
    filter: invert(46%) sepia(79%) saturate(4498%) hue-rotate(166deg) brightness(100%) contrast(97%);
  }
}

.btn-radio,
.btn-radios {
  &:focus + label {
    outline: -webkit-focusring-color auto 5px;
    outline: 1px dashed #000;
  }
}

/*----------------------------------------*\
  || Radio Basic
\*----------------------------------------*/

.radio-basic {
  display: inline-block;
  color: var(--body-clr);
  vertical-align: middle;
  font-size: 12px;
  width: 80px;
  text-align: center;
  padding: 5px 0;
  border: 1px solid var(--disable-clr);
  border-bottom: 2px solid var(--disable-clr);
  background-color: var(--bg-clr2);
}

/*----------------------------------------*\
  || Radio Box Wrap
\*----------------------------------------*/

.radio-box-wrap {
  @include flex($gap: 10px 20px, $wrap: wrap);

  // label {
  //   margin-right: 40px;

  //   &:last-child {
  //     margin-right: 0;
  //   }
  // }

  .radio-box {
    min-width: fit-content;

    // &:last-child {
    //   margin-right: 0;
    // }

    // & + .radio-box {
    //   margin-left: 55px;
    // }

    // &.ty01 {
    //   margin-right: 30px;
    // }

    &.type01,
    &.type03 {
      height: 20px;
    }

    &.type01,
    &.type02,
    &.type03 {
      .btn-radios + label::after {
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        margin-top: -11px;
        margin-left: -10px;
        background: url(/img/hss_common/btn_radio_off.png) no-repeat center / 20px;
      }
    }

    &.type01 .btn-radios + label {
      top: 2px;
    }

    &.type02 .btn-radios + label::after {
      left: 0;
      margin-left: 0;
      background: url(/img/hss_common/btn_radio_off.png) no-repeat center / 20px;
    }

    &.type03 .btn-radios + label::after {
      left: 0;
      margin-left: 0;
    }
  }

  :has(.t-margin) {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

td.radio-box-wrap {
  min-width: 100%;

  .clearfix {
    width: 100%;
  }
}

/*----------------------------------------*\
  || Radio Box
\*----------------------------------------*/

.radio-box,
.radio-box2 {
  position: relative;
  display: inline-block;
}

.radio-box {

  &.type01,
  &.type02,
  &.type03 {
    .btn-radios + label::after {
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      margin-top: -11px;
      margin-left: -10px;
      background: url(/img/common/btn_radio_off.png) no-repeat center / 20px;
    }

    .btn-radios:checked + label::after {
      background: url(/img/hss_common/btn_radio_click.png) no-repeat center / 20px;
      filter: invert(46%) sepia(79%) saturate(4498%) hue-rotate(166deg) brightness(100%) contrast(97%);
    }
  }

  &.type01,
  &.type03 {
    height: 20px;
  }

  &.type02,
  &.type03 {
    .btn-radios + label {
      top: 2px;
      padding-left: 30px;
      font-size: 22px;
      font-weight: $bold;
      color: var(--body-clr);
    }
  }

  &.type01 {
    .btn-radios + label {
      top: 2px;
    }
  }

  &.type02 {
    .btn-radios + label::after {
      left: 0;
      margin-left: 0;
      background: url(/img/common/btn_radio_off.png) no-repeat center / 20px;
    }
  }

  &.type03 {
    .btn-radios + label {
      font-size: 14px;

      &::after {
        left: 0;
        margin-left: 0;
      }
    }
  }

  .phone-radios + label,
  .phone-radios02 + label {
    line-height: 47px;
  }
}

.radio-box2 .btn-radios + label {
  line-height: 47px;
}

/*----------------------------------------*\
  || Btn-show-list
\*----------------------------------------*/

.btn-show-list {
  display: inline-block;
  position: relative;
  width: 300px;
  height: 50px;
  padding: 0;
  background-color: var(--bg-clr2);
  color: #363636;
  font-size: 16px;
  border: 1px solid var(--body-clr);

  &::before {
    content: '';
    width: 24px;
    height: 2px;
    background: var(--body-clr);
    position: absolute;
    top: 46%;
    right: 20px;
    display: block;
  }

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    border-bottom: 2px solid var(--body-clr);
    border-left: 2px solid var(--body-clr);
    position: absolute;
    top: 36%;
    right: 20px;
    transform: rotate(225deg);
    display: block;
  }

  &.option {
    span {
      display: inline-block;
      vertical-align: middle;
    }

    .icon-list {
      display: none;
    }
  }
}


/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

@include respond-to ("huge") {
  .radio-box-wrap {
    &.bottom .radio-box {
      min-width: fit-content;
    }
  }

  .radio-basic {
    width: 60px;
    font-size: 13px;
    padding: 8px 0;
    background-color: #eeeff1;
    border: 0;
    color: #363636;
  }

  .radio-box-wrap label {
    margin-right: 20px;
    float: left;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn-radio:checked + .radio-basic {
    background-color: var(--bg-clr2);
  }

  .btn-radios {
    & + label {
      font-weight: $regular;
      padding-left: 25px;
      color: #363636;
      font-size: 14px;

      &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        width: 16px;
        height: 16px;
        background: url(/img/common/btn_radio_off.png) no-repeat center / 16px;
      }
    }

    &:checked + label::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      width: 16px;
      height: 16px;
      background: url(/img/common/btn_radio_click.png) no-repeat center / 16px;
      filter: invert(46%) sepia(79%) saturate(4498%) hue-rotate(166deg) brightness(100%) contrast(97%);
    }
  }
}
