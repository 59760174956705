@charset "utf-8";

/*----------------------------------------*\
  || 조은저축은행 대주주소개
\*----------------------------------------*/

.choeun-major-shareholder-wrap {
  .slogan {
    font-size: 32px;
    font-weight: $bold;
    line-height: 40px;
    color: var(--body-clr);
    text-align: center;

    strong {
      display: block;
      color: #db3a3a;
    }
  }

  .img-map {
    display: block;
    margin: 40px auto;
    max-width: 100%;
  }

  .office-map-list {
    @include flex($wrap: wrap);
    border: 1px solid var(--line-clr2);
  }

  .office-map-item {
    flex: 1;

    .box {
      aspect-ratio: 1;
      padding: 50px;
      border-bottom: 1px solid var(--line-clr2);

      .title {
        display: block;
        margin-bottom: 15px;
        font-size: 24px;
        line-height: 24px;
        color: var(--body-clr);
      }

      .text {
        display: block;
        margin-bottom: 25px;
        font-size: 15px;
        line-height: 25px;
        color: #666;
      }

      .phone,
      .email {
        @include bgImg($url: '/img/hss_companycontent/choeun_content/icon_phone.png', $x: left, $size: auto);
        display: block;
        height: 36px;
        padding-left: 36px;
        font-size: 15px;
        line-height: 36px;
        color: #666;
      }

      .email {
        background-image: url(/img/hss_companycontent/choeun_content/icon_mail.png);
      }
    }

    .map {
      aspect-ratio: 1;
    }

    &:nth-of-type(2) {
      border-left: 1px solid var(--line-clr2);
      border-right: 1px solid var(--line-clr2);

      .box {
        border-top: 1px solid var(--line-clr2);
        border-bottom: 0;
      }
    }
  }
}

@include respond-to ("huge") {
  .choeun-major-shareholder-wrap {
    .office-map-list {
      display: block;
    }

    .office-map-item {
      @include flex($align: stretch);

      &:nth-of-type(2) {
        border: 0;
        border-top: 1px solid var(--line-clr2);
        border-bottom: 1px solid var(--line-clr2);

        .box {
          border-top: 0;
          border-left: 1px solid var(--line-clr2);
        }

        .map {
          border-left: 0;
        }
      }

      .box {
        min-width: 336px;
        border: 0;
      }

      .map {
        flex-grow: 1;
        border-left: 1px solid var(--line-clr2);
        aspect-ratio: auto;
      }
    }
  }
}

@include respond-to ("medium") {
  .choeun-major-shareholder-wrap {
    .office-map-item {
      flex-direction: column;

      &:nth-of-type(2) .box {
        border-left: 0;
        border-bottom: 1px solid var(--line-clr2);
      }

      .box {
        min-width: auto;
        border-bottom: 1px solid var(--line-clr2);
        aspect-ratio: auto;
      }

      .map {
        min-height: 300px;
        border-left: 0;
        order: 1;
      }
    }
  }
}

/*----------------------------------------*\
  || 채권추심자 증표
\*----------------------------------------*/

.column-line-list {
  .column-line-item {
    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }

    &:nth-child(4n) {
      border-left: 0;
    }

    .img.h-auto {
      height: auto;
      max-width: 250px;
    }
  }
}

@include respond-to ("huge") {
  html.responsive,
  html:has(.content) {
    .column-line-list {
      .column-line-item {
        &:nth-child(2n-1) {
          border-left: 0;
        }
  
        &:nth-child(4n) {
          border-left: 1px solid var(--line-clr3);
        }
      }
    }
  }
}

@include respond-to ("medium") {
  .column-line-list {
    .column-line-item {
      .img.h-auto {
        max-width: 100%;
      }
    }
  }
}

/*=============================================*/
/*==================== OLD ====================*/
/*=============================================*/

/*----------------------------------------*\
  || Uncategorized
\*----------------------------------------*/

/*====================================================*/
/*==================== RESPONSIVE ====================*/
/*====================================================*/

/*----------------------------------------*\
  || Huge
\*----------------------------------------*/

// @include respond-to ("huge") {
//   // html.responsive {}
// }
